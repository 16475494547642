import React, { useEffect } from 'react';
import WindowFrame from './WindowFrame';
import { styled } from 'styled-components';
import utils from 'modules/utils';

const TopSection = styled.div`
  width: 100%;
  height: 61px;
  display: flex;
  flex-direction: column;

  .account-text {
    font-size: 22px;
    font-weight: bold;
    color: #5affe2;
  }
  .inform-text {
    font-size: 20px;
    font-weight: normal;
    color: #fff;
  }
`;

const Main = styled.div`
  margin-top: 26px;
  width: 100%;
  height: calc(100% - 86px);

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .lists {
      width: 100%;
      display: flex;
      align-items: center;

      .title-text {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        width: 163px;
      }

      input {
        width: calc(100% - 163px);
        border-radius: 5px;
        border: solid 1px #8a8a8a;
        background-color: #212838;
        height: 35px;
        font-size: 20px;
        font-weight: normal;
        color: #fff;
        padding: 0 20px 3px;
      }
    }
  }
`;

const WithdrawListModal = ({ open, close, title, data }) => {
  const type = data.monyGubn === '원천징수영수증' ? 1 : 0;
  // 출금 신청 내역 모달 스타일
  const modalHeaderStyle = {
    padding: '30px 50px',
  };
  const modalBodyStyle = {
    main_section: { padding: '30px 20px 0 30px' },
    modal_main: { padding: 0 },
    fixed_button: { padding: 0 },
  };

  const showData1 = [
    {
      title: '신청일',
      data: data.date,
    },
    { title: '정산유형', data: data.monyGubn },
    {
      title: '계좌번호',
      data: `[${data.bank}] ${data.account} ${data.holder}`,
    },
    {
      title: '신청금액',
      data: `${utils.format.number(data.atm_mony)}원`,
    },
    {
      title: '공급가액',
      data: `${utils.format.number(data.price)}원`,
    },
    { title: '부가세', data: `${utils.format.number(data.vat)}원` },
    { title: '실지급액', data: `${utils.format.number(data.mony)}원` },
    { title: '비고', data: data.statusName },
  ];
  const showData2 = [
    {
      title: '신청일',
      data: data.date,
    },
    { title: '정산유형', data: data.monyGubn },
    {
      title: '계좌번호',
      data: `[${data.bank}] ${data.account} ${data.holder}`,
    },
    {
      title: '신청금액',
      data: `${utils.format.number(data.atm_mony)}원`,
    },
    {
      title: '공급가액',
      data: `${utils.format.number(data.price)}원`,
    },
    {
      title: '부가세',
      data: `${utils.format.number(data.vat)}원`,
    },
    { title: '원천징수세', data: `${utils.format.number(data.tax)}원` },
    { title: '실지급액', data: `${utils.format.number(data.mony)}원` },
    { title: '비고', data: data.statusName },
  ];

  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <WindowFrame open={open} close={close} title={title} width={'746px'} height={type === 0 ? '679px' : '735px'} modalBodyStyle={modalBodyStyle} modalHeaderStyle={modalHeaderStyle}>
      <TopSection>
        <p className="account-text">입금계좌 : {`[${data.bank}] ${data.account} ${data.holder}`}</p>
        <p className="inform-text">매주 화요일 16시 전 신청건에 한 해, 영업일 기준 2~3일 내로 출금됩니다.</p>
      </TopSection>
      <Main>
        <form action="#none">
          {type === 0
            ? showData1.map((item, index) => (
                <div className="lists" key={`${item.title}`}>
                  <div className="title-text">{item.title}</div>
                  <input type="text" value={item.data} readOnly />
                </div>
              ))
            : showData2.map((item, index) => (
                <div className="lists" key={`${item.title}`}>
                  <div className="title-text">{item.title}</div>
                  <input type="text" value={item.data} readOnly />
                </div>
              ))}
        </form>
      </Main>
    </WindowFrame>
  );
};

export default WithdrawListModal;

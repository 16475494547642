import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

const CustomMuiSelect = ({ selected, setSelected, label, optionList }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <FormControl
      sx={{
        minWidth: 150,
        '& .MuiInputBase-root': {
          backgroundColor: '#fff',
          height: isTablet ? '28px' : 'auto',
          fontSize: '20px',
          color: '##10141c',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--violet-color)',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          display: 'none',
        },
        '& .MuiInputLabel-root': {
          display: selected !== '' ? 'none' : 'block',
          fontSize: '16px',
          lineHeight: '16px',
          alignContent: 'center',
          alignItems: 'center',
          left: '4px',
        },
        '& .MuiInputBase-inputSizeSmall': {
          padding: '2px 17px',
          height: '32px',
        },
      }}
      size="small"
      fullWidth
    >
      <InputLabel
        id="demo-select-small-label"
        sx={{
          fontSize: '20px',
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selected || ''}
        onChange={handleChange}
        MenuProps={{
          getContentAnchorEl: null,
          // 높이
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
      >
        {optionList.length > 0 &&
          optionList.map((item, index) => (
            <MenuItem key={index} value={item?.value}>
              {item?.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CustomMuiSelect;

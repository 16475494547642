import utils from 'modules/utils';
import { useEffect } from 'react';
import styled from 'styled-components';
import WindowFrame from './WindowFrame';

const BlockForm = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .info-comment {
    line-height: 1;
    font-size: 24px;
    color: #fff;
    &:nth-child(1) {
      margin-bottom: 7px;
    }
    &:nth-child(2) {
      margin-bottom: 32px;
    }
    &:nth-child(3) {
      font-size: 22px;
      margin-bottom: 35px;
    }
  }
  .modal-button {
    border-radius: 4px;
  }
`;

const UserBlockModal = ({ open, close, title, userId, nick, afterAction }) => {
  const userBlock = async () => {
    if (await utils.dialog.confirm('차단하기', nick + '님을 차단하시겠습니까?', 'question', '', '차단')) {
      utils.http.setMode('api');
      let res = await utils.http.get(`/v1/SP_a_block_I1/${userId}`);

      if (res.data?.success) {
      } else {
        await utils.dialog.alert('차단오류', '사용자 차단 중 오류가 발생하였습니다. <br/> 잠시 후 다시 시도해주세요.', 'error');
      }
      utils.isFunction(afterAction) && afterAction();
      utils.isFunction(close) && close();
    }
  };
  useEffect(() => {}, [open]);
  return (
    <WindowFrame open={open} close={close} title={title} width={'790px'} height={'294px'}>
      <BlockForm>
        <div className={'info-comment'}>{/* 차단 대상에 해당되는지 다시 한번 확인하여 주시기 바랍니다. */}</div>
        <div className={'info-comment'}>
          차단 시 상대방의 게시글, 채팅 등 작성 글을 확인할 수 없습니다.
          <br />
          차단하시겠습니까?
        </div>
        <div className={'info-comment'}>{/* 차단 대상 : {nick} */}</div>

        <button type={'button'} className={'modal-button action'} onClick={userBlock}>
          차단
        </button>
      </BlockForm>
    </WindowFrame>
  );
};

export default UserBlockModal;

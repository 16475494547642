import { styled } from 'styled-components';
import checkIcon from 'assets/checkbox-icon/colorCheck.png';

export const Box = styled.div`
  width: 100%;
  height: calc(100% - 30px);
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const CategoryBox = styled.div`
  width: calc(100% - 207px);

  height: 100%;
  padding: 31px 30px;
  border-radius: 10px;
  background-color: #212838;
`;

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;
export const TitleText = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #fff;

  @media all and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const SubTitleText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ccc;
`;

export const BtnWrap = styled.div`
  width: 207px;
  height: auto;
  margin-top: 22px;
`;

export const BtnMenu = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Menu = styled.div`
  width: 100%;
  height: auto;
  padding: 1px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-auto-rows: 55px;
`;

export const Item = styled.div`
  background: transparent;
  text-align: left;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  border: 1px solid #fff;
  padding: 12px 15px;
  cursor: pointer;

  &:hover,
  &.selected {
    color: #5affe2;
    border: 1px solid #5affe2;
  }
`;

export const StyledLabel = styled.label`
  cursor: pointer;
  & > section {
    position: relative;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    padding: 0 20px;
    justify-content: space-between;
    margin: -1px -1px 0 0;
  }
  &:hover > section,
  &.selected > section {
    border: 1px solid #5affe2;
    margin: -1px -1px 0 0;
    z-index: 1;
  }
  &:hover span {
    color: #5affe2;
  }
  &.selected span {
    color: #5affe2;
  }
  span {
    padding: 0;
    color: #fff;
    font-size: 20px;
  }
`;

export const StyledCheckbox = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  appearance: none;
  width: 21px;
  height: 15px;

  &:hover {
    cursor: pointer;
  }

  &:checked {
    background: center url(${checkIcon}) no-repeat;
    border: none;
  }

  &:checked ~ span {
    color: #fff;
  }
`;

export const ShowChecked = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #212838;
  padding: 20px 30px 30px 30px;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
`;

export const SelectedBtn = styled.span`
  width: auto;
  height: 31px;
  padding: 0px 14px;
  border-radius: 15px;
  border: solid 1px #5affe2;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  cursor: pointer;
  margin: 0 20px 20px 0;
  display: inline-flex;
  align-items: center;
  gap: 3px;
`;

export const CloseBtn = styled.button`
  background: transparent;
  cursor: pointer;
  line-height: 1;
  padding-top: 4px;

  .icon {
    line-height: 1;
    font-size: 23px;
  }
`;

export const ResetBtn = styled.button`
  position: absolute;
  bottom: 10px;
  right: 30px;
  width: auto;
  height: auto;
  background-color: transparent;
  color: #fff;
  font-size: 17.5px;
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    font-size: 24px;
    line-height: 1;
    color: #fff;
  }
`;

export const NoneChecked = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #212838;
  font-size: 20px;
  font-weight: bold;
  color: #8a8a8a;
`;

import React, { useEffect, useState } from 'react';
import { Container, Form, InputList, InputTitle, TextInput, TextArea, InputWrap, SearchBtn, StyledSpan } from './InfoFormElements';
import { BdWhiteText, WtNomalSpan } from 'style';
import FindAddressModal from '../../Modal/FindAddressModal';
import { useMediaQuery } from 'react-responsive';
import { numberRegex } from 'modules/regex/regex';
import utils from 'modules/utils';
import CustomMuiSelect from './../../CustomSelect/CustomMuiSelect';

const InfoForm = ({ inputLists, inputVal, setInputVal, inputTime }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const [timeList, setTimeList] = useState([]);
  const { startTime, setStartTime, endTime, setEndTime } = inputTime;

  const [openPostModal, setOpenPostModal] = useState(false);
  const openFindAddress = () => {
    setOpenPostModal(true);
  };
  const closeFindAddress = () => {
    setOpenPostModal(false);
  };

  const getTimeList = () => {
    const startEndUrl = `/v1/SP_b_Common_Code_SI/60`;
    utils.http.setMode('api');
    utils.http.get(startEndUrl).then((res) => {
      if (res.data.success) {
        const timeList = res.data.data.map((item) => {
          return {
            value: item.cd,
            label: item.nm,
          };
        });
        setTimeList(timeList);
      }
    });
  };

  useEffect(() => {
    getTimeList();
  }, []);

  return (
    <Container>
      <Form action="#none">
        <InputList>
          <InputTitle>{inputLists[0].title}</InputTitle>
          <TextInput
            placeholder={inputLists[0].placeholder}
            onInput={(e) => {
              setInputVal({ ...inputVal, partName: e.target.value });
            }}
            value={inputVal.partName}
            maxLength={20}
          />
        </InputList>
        <InputList>
          <InputTitle>{inputLists[1].title}</InputTitle>
          <TextArea
            placeholder={inputLists[1].placeholder}
            maxLength="30"
            onInput={(e) => {
              setInputVal({ ...inputVal, partIntroduce: e.target.value });
            }}
            value={inputVal.partIntroduce}
          />
        </InputList>
        <InputList>
          <InputTitle>{inputLists[2].title}</InputTitle>
          <TextInput
            placeholder={inputLists[2].placeholder}
            onInput={(e) => {
              setInputVal({ ...inputVal, partHash: e.target.value });
            }}
            value={inputVal.partHash}
            maxLength={20}
          />
        </InputList>
        <InputList>
          <InputTitle>{inputLists[3].title}</InputTitle>
          <InputWrap>
            <div style={{ display: 'flex', gap: '25px' }}>
              <TextInput placeholder={inputLists[3].placeholder[0]} style={{ width: 'calc(100% - 127px)' }} readOnly={true} onClick={openFindAddress} value={inputVal.addr1} />
              <SearchBtn type="button" onClick={openFindAddress}>
                주소 검색
              </SearchBtn>
            </div>
            <TextInput placeholder={inputLists[3].placeholder[1]} style={{ width: '100%' }} readOnly={true} onClick={openFindAddress} value={inputVal.addr2} />
            <TextInput
              placeholder={inputLists[3].placeholder[2]}
              style={{ width: '100%' }}
              onInput={(e) => {
                setInputVal({ ...inputVal, addr3: e.target.value });
              }}
              value={inputVal.addr3}
            />
          </InputWrap>
        </InputList>
        <InputList>
          <InputTitle>{inputLists[4].title}</InputTitle>
          <TextInput
            placeholder={inputLists[4].placeholder}
            maxLength="30"
            onInput={(e) => {
              setInputVal({ ...inputVal, partTime: e.target.value });
            }}
            value={inputVal.partTime}
          />
          {/* TODO: 시간 select로 바꾸기 */}
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'calc(100% - 170px)',
            }}
          >
            <CustomMuiSelect selected={startTime} setSelected={setStartTime} label="시작시간" optionList={timeList} />
            <WtNomalSpan style={{ width: '50px', textAlign: 'center', fontSize: '20px' }}>~</WtNomalSpan>
            <CustomMuiSelect selected={endTime} setSelected={setEndTime} label="종료시간" optionList={timeList} />
          </div> */}
        </InputList>
        <InputList>
          <InputTitle>경력</InputTitle>
          <TextInput
            placeholder="경력"
            style={{ width: '115px', textAlign: 'center', marginRight: '16px' }}
            onInput={(e) => {
              if (numberRegex.test(e.target.value) || e.target.value === '') {
                setInputVal({ ...inputVal, partCareer: e.target.value.trim() });
              }
            }}
            value={inputVal.partCareer}
            maxLength={3}
          />
          <StyledSpan>년</StyledSpan>
          <BdWhiteText style={{ margin: isTablet ? '0 20px 0 140px' : '0 40px 0 184px' }}>사원수</BdWhiteText>
          <TextInput
            placeholder="사원수"
            style={{ width: '115px', textAlign: 'center', marginRight: '16px' }}
            onInput={(e) => {
              if (numberRegex.test(e.target.value) || e.target.value === '') {
                setInputVal({ ...inputVal, partManCount: e.target.value.trim() });
              }
            }}
            value={inputVal.partManCount}
            maxLength={4}
          />
          <StyledSpan>명</StyledSpan>
        </InputList>
      </Form>
      <FindAddressModal
        open={openPostModal}
        close={closeFindAddress}
        title={'주소 검색'}
        afterAction={(address, zonecode) => {
          console.log(address, zonecode);
          setInputVal({ ...inputVal, addr1: zonecode, addr2: address });
        }}
      />
    </Container>
  );
};

export default InfoForm;

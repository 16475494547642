import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const CustomPassword = ({ name, control, sx, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <TextField
          required
          type="password"
          placeholder="신규 비밀번호 입력"
          size="small"
          error={!!error}
          helperText={error?.message}
          fullWidth
          {...field}
          // input 오른쪽 아이콘 추가
          InputProps={{
            endAdornment: (
              <>
                {!error && field.value.length >= 8 && <CheckRoundedIcon style={{ color: '#7e6eff' }} />}
                {!!error && field.value.length !== 0 && <PriorityHighIcon style={{ color: '#eb4141' }} />}
              </>
            ),
          }}
          sx={{
            // input 배경 #fff helperText font-size 14px
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#fff',
            },
            '& .MuiFormHelperText-root': {
              fontWeight: 'bold',
              fontSize: '14px',
            },

            ...sx,
          }}
          {...other}
        />
      )}
    />
  );
};

export default CustomPassword;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnMenu } from 'components/Manage/Info/InfoElements';
import Icon from 'components/Parts/Common/Icon';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const Btn = styled.button`
  width: 100%;
  height: 81px;
  border-radius: 20px;
  border: solid 1px transparent;
  background-color: #37465b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #fff;
  padding: 30px 30px 30px 34px;

  &:hover,
  &.selected {
    border: 1px solid #5affe2;
    color: #5affe2;
  }
  &:hover .icon {
    color: #5affe2;
  }
  &.selected .icon {
    color: #5affe2;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 50px;
  }
`;

const ArrowIcon = styled.div`
  width: 39px;
  height: 39px;

  svg {
    font-size: 39px;
  }
`;

const TabMenu = ({ tabList, selected, size, path }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  const navigate = useNavigate();
  return (
    <BtnMenu>
      {Object.keys(tabList).map((kind) => (
        <Btn
          key={'tab-menu-' + kind}
          onClick={() => {
            navigate(path + kind);
          }}
          className={selected === kind ? 'selected' : ''}
          style={
            size === 'big'
              ? {}
              : {
                  height: '56px',
                  padding: '16px 20px',
                  borderRadius: '10px',
                }
          }
        >
          <span style={{ fontSize: isTablet ? '18px' : '24px', fontWeight: 'bold' }}>{tabList[kind]}</span>
          <ArrowIcon style={size === 'small' ? { width: '15px', height: '15px' } : {}}>
            <ArrowForwardIosRoundedIcon sx={size === 'small' ? { fontSize: '15px' } : {}} />
          </ArrowIcon>
        </Btn>
      ))}
    </BtnMenu>
  );
};

export default TabMenu;

import utils from 'modules/utils';
import { useEffect, useState } from 'react';

import styled from 'styled-components';
import Lists from '../../../Parts/Common/TabLists/Lists';
import { Container, Contents, Item, NickName, TextWrap } from '../../../Parts/Common/TabLists/ViewListsElements';

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { CommentBtn, EditDeleteBtn } from 'components/Manage/History/SoodalBoard/SoodalBoard';
import BigInfoModal from 'components/Parts/Common/MenuInfo/BiginfoModal';
import AutoEstimateModal from 'components/Parts/Common/Modal/AutoEstimateModal';
import DetailContent from '../../../Parts/Common/TabLists/DetailContent';
import GrabImageList from '../../../Parts/Common/Uploader/GrabImageList';
import CustomSwitch from './../../../Switch/CustomSwitch';
import { WriteButton } from './../../News/QuestionList/Results';

const ReviewBase = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  > * {
    flex: 0 0 auto;
  }
  .review-section {
    height: 100%;
    width: 100%;
    flex: 1 1 100%;

    .review-item {
      position: relative;
      .review-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        div {
          line-height: 1;
        }
        .nick {
          margin-bottom: 9px;
          flex-shrink: 0;
        }
        .content {
          flex-shrink: 0;
        }
        .bottom {
          margin-top: 10px;
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      & > svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 24px;
        transition: 0.5s;
        color: #fff;
      }
      &:hover svg,
      &.selected svg {
        color: #5affe2;
      }
    }
  }
  .view-container {
    .view-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 30px;
      height: 80px;
      border-bottom: solid 1px #10141c;
      font-size: 24px;
      font-weight: bold;
      .cate-info {
        width: 70%;
      }
      .estimate-money {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        strong {
          font-size: 28px;
          color: var(--violet-color);
        }
      }
    }
    .view-main {
      padding: 30px;
      .review-content {
        color: #10141c;
        font-size: 22px;
        white-space: pre-wrap;
      }
      .review-images {
        margin-top: 30px;
      }
    }
    .view-footer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      border-top: solid 1px #10141c;
      padding: 30px;
      .user-nick {
        font-size: 16px;
        font-weight: bold;
      }
      .comment-text {
        font-size: 16px;
        color: #10141c;
      }
    }
  }
`;

const RightSide = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  height: 100%;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
`;

const Money = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const AutoEstimateList = () => {
  const [reviewList, setReviewList] = useState([]);
  const [reviewView, setReviewView] = useState({});
  const [reviewImages, setReviewImages] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [autoEstimateModal, setAutoEstimateModal] = useState(false);
  const [prevReview, setPrevReview] = useState({
    workDate: '',
    sqenNumb: '',
  });

  const [checked, setChecked] = useState(false);

  const modalStyle = {
    width: '501px',
    height: 'auto',
  };
  let infoModalData = '자동견적서는 고객이 선택한 카테고리에 맞춰 자동으로 작성되는 견적서입니다.';

  const getReviewList = function () {
    const url = `/v2/SP_p_partCategorySub_s1/2`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data.success) {
        setChecked(!!res.data.data[0]?.part_cate_sub_gubn);
        setReviewList(res.data.data);
      }
    });
  };
  const updateImagesList = function (review) {
    const url = `/v2/SP_p_partCategorySubImage_s1/${review.work_date}/${review.sqen_numb}`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data.success) {
        setReviewImages(res.data.data);
      } else {
        setReviewImages([]);
      }
    });
  };

  const handleOpenModal = async () => {
    setIsEdit(false);
    setAutoEstimateModal(true);
  };

  const deleteDetail = async () => {
    await utils.dialog.confirm('정말 삭제하시겠습니까?', '', 'question').then((result) => {
      if (result) {
        const url = `/v2/SP_p_partCategorySub_D1`;
        const data = {
          workDate: reviewView.work_date,
          sqenNumb: reviewView.sqen_numb,
        };
        utils.http.setMode('api');
        utils.http.post(url, null, data).then(({ data }) => {
          console.log('deleteDetail', data);
          if (data?.success) {
            utils.dialog.alert('자동견적서 삭제 완료', '자동견적서가 삭제되었습니다.', 'success').then(() => {
              getReviewList();
              setReviewView({});
            });
          } else {
            utils.dialog.alert('자동견적서 삭제 실패', '자동견적서 삭제에 실패했습니다.', 'error');
          }
        });
      }
    });
  };

  const autoEstimateToggle = (e) => {
    const url = `/v2/SP_p_partCategorySubAuto_i1`;
    const data = {
      partCateSubGubn: e.target.checked ? 1 : 0,
    };
    utils.http.setMode('api');
    utils.http.post(url, null, data).then(({ data }) => {
      console.log('autoEstimateToggle', data);
      if (!data?.success) {
        console.log('자동견적 전송 설정 실패', data?.message);
      }
      getReviewList();
    });
  };

  const bindingEditData = () => {
    setIsEdit(true);
    setAutoEstimateModal(true);
  };

  useEffect(() => {
    if (prevReview.work_date !== reviewView.work_date || prevReview.sqen_numb !== reviewView.sqen_numb) {
      setPrevReview({ work_date: reviewView.work_date, sqen_numb: reviewView.sqen_numb });
    }
  }, [reviewView]);

  useEffect(() => {
    getReviewList();
  }, []);

  useEffect(() => {
    if (prevReview.work_date !== '' && prevReview.sqen_numb !== '') {
      reviewList.forEach((review) => {
        if (prevReview.work_date === review.work_date && prevReview.sqen_numb === review.sqen_numb) {
          setReviewView(review);
          updateImagesList(review);
        }
      });
    }
  }, [reviewList]);

  return (
    <ReviewBase>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <BigInfoModal
          infoModalData={[
            {
              title: '자동견적서',
              descript: `고객에게 발송할 자동견적을 등록해주세요.`,
            },
          ]}
          menu="자동견적 목록"
          style={modalStyle}
        />
        <WriteButton onClick={handleOpenModal}>
          <EditNoteOutlinedIcon />
          자동견적
        </WriteButton>
      </div>
      <Container className={'review-section'}>
        <Lists
          title={''}
          empty={reviewList.length === 0}
          infoModalData={infoModalData}
          slotFilter={reviewList.length > 0 && <CustomSwitch sx={{ paddingBottom: '10px' }} label="자동견적 전송 설정" checked={checked} onChange={autoEstimateToggle} />}
          emptyMessage={
            <>
              등록 된 자동견적이 없습니다.
              <br />
              자동견적을 등록해보세요.
            </>
          }
          slotList={reviewList.map((review, index) => (
            <Item
              key={'reviewlist' + review.work_date + review.sqen_numb}
              className={'review-item ' + (reviewView.workDate + reviewView.sqenNumb === review.workDate + review.sqenNumb && 'selected')}
              onClick={() => {
                setReviewView(review);
                updateImagesList(review);
              }}
            >
              <TextWrap className={'review-info'}>
                <NickName className={'nick'}>{review.cate_name + ' > ' + review.cate_sub_name}</NickName>
                <Contents className={'content'}>{review.part_sub_text}</Contents>
              </TextWrap>
              <RightSide>
                <Money>
                  {review?.part_sub_mony?.toLocaleString()}
                  <span style={{ fontSize: '14px' }}>원 부터~</span>
                </Money>
                <ArrowForwardIosRoundedIcon />
              </RightSide>
            </Item>
          ))}
        />
        {reviewList.length > 0 && (
          <DetailContent
            title="상세 내용"
            empty={utils.isNull(reviewView.work_date) || utils.isNull(reviewView.sqen_numb)}
            emptyMessage="목록에서 자동견적서를 선택해 주세요."
            adFitCode="DAN-DfHD2dTO6NKYuQGN"
            slotContent={
              <section
                className={'view-container scrollbar-container2'}
                style={{
                  // 500px 넘어가면 스크롤 생기게
                  maxHeight: '480px',
                  overflowY: 'auto',
                }}
              >
                <header className={'view-header'}>
                  <section className={'cate-info'}>{reviewView.cate_name + ' > ' + reviewView.cate_sub_name}</section>
                  <section className={'estimate-money'}>
                    <strong>{reviewView?.part_sub_mony?.toLocaleString()}</strong>원 부터~
                  </section>
                </header>
                <main className={'view-main'}>
                  <div className={'review-content'}>{reviewView.part_sub_text}</div>
                  {reviewImages.length > 0 && <GrabImageList className={'review-images'} imageList={reviewImages} />}
                  <div>
                    <EditDeleteBtn>
                      <CommentBtn onClick={bindingEditData}>수정</CommentBtn>
                      <span>|</span>
                      <CommentBtn onClick={deleteDetail}>삭제</CommentBtn>
                    </EditDeleteBtn>
                  </div>
                </main>
              </section>
            }
          />
        )}
      </Container>
      <AutoEstimateModal
        open={autoEstimateModal}
        close={() => setAutoEstimateModal(false)}
        title="자동견적서"
        nickName={utils.user('nick') ?? ''}
        afterAction={() => {
          getReviewList();
        }}
        isEdit={isEdit}
        modalData={reviewView}
        imageList={reviewImages}
      />
    </ReviewBase>
  );
};

export default AutoEstimateList;

import styled from 'styled-components';
import { Base } from '../FrameElements';

export const HomeBase = styled(Base)`
  gap: 29px;
  min-height: 700px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px auto;

  // 1024
  @media all and (max-width: 1024px) {
    gap: 10px;
    min-height: 0;
  }
`;

export const HomeHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: #fff;
`;

export const TicketCount = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #fff;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const TicketIcon = styled.div`
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  color: #fff;

  @media all and (max-width: 1024px) {
    font-size: 18px;
  }
`;

export const MainSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  // 1024
  @media all and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 4fr 6fr;
  gap: 20px;

  @media all and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
  }
`;

export const RightSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media all and (max-width: 1024px) {
    flex-direction: row;
  }
`;

export const SmallContentBox = styled.div`
  background-color: #37465b;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  position: relative;

  @media all and (max-width: 1024px) {
    width: 100%;
    height: 200px;
    font-size: 16px;
    padding: 14px 20px;
  }
`;

export const BigContentBox = styled.div`
  background-color: #37465b;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 25px 30px;
  position: relative;

  @media all and (max-width: 1024px) {
    padding: 14px 20px;
  }
`;

export const ContentBoxHeading = styled.div`
  display: flex;
  background-color: transparent;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ContentBoxHeadingText = styled.p`
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  position: relative;
  line-height: 1;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    left: -7px;
    bottom: -4px;
    width: 100%;
    height: 10px;
    padding: 0 7px;
    background: #7f6eff;
    z-index: -1;
  }

  /* 1024px */
  @media all and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const ContentBoxMainText = styled.p`
  font-size: 22px;
  color: #fff;
  font-weight: normal;
  margin: 20px 0 30px;
  line-height: 1.2em;
  position: relative;
  z-index: 2;
  /* 1024px */
  @media all and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const SmallBoxImg = styled.img`
  max-height: ${(props) => props.max};
`;

export const DetailBtn = styled.button`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 3px;
  color: #5affe2;
  font-size: 18px;
  background: transparent;
  cursor: pointer;

  @media all and (max-width: 1024px) {
    font-size: 14px;
    img {
      width: 14px;
      height: 14px;
    }
  }
`;

import WindowFrame from './WindowFrame';
import styled from 'styled-components';
import Icon from '../Icon';
import { useEffect, useState } from 'react';
import { imgFormat } from './../Uploader/GrabImageList';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DownloadIcon from '@mui/icons-material/Download';

const ImageViewer = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .image-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .image-view {
      max-height: 100%;
      max-width: 100%;
      border-radius: 10px;
      object-fit: contain;
    }
    .download-btn {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 10px;
      right: 40px;
      bottom: 20px;
      width: 100px;
      height: 40px;
      border-radius: 10px;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        color: var(--mint-color);
      }
    }
  }
  .button-slide {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #000;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: #5affe2;
      font-size: 32px;
    }

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.prev {
      left: 15px;
    }
    &.next {
      right: 15px;
    }
  }
  .image-page {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid #eee;
      background: #fff;
      cursor: pointer;
      &.current {
        border: none;
        background: #5affe2;
      }
    }
  }
`;

const ImageViewModal = ({ open, close, title, imageList, imageStart, useDot }) => {
  const [curIdx, setCurIdx] = new useState(0);
  const reverseImageList = [...imageList].reverse();

  console.log('이미지 모달', reverseImageList, imageStart);

  useEffect(() => {
    setCurIdx(() => imageList.indexOf(imageStart));
  }, [imageList, imageStart, setCurIdx]);

  return (
    <WindowFrame open={open} close={close} title={title} width={'70vw'} height={'90vh'} scroll={false}>
      <ImageViewer>
        <div className="image-box">
          <img className={'image-view'} alt={''} src={process.env.REACT_APP_IMAGE_VIEW_URL + imgFormat(imageList[curIdx])} />
          <button
            className="download-btn"
            onClick={() => {
              // javascript download
              const a = document.createElement('a');
              a.href = process.env.REACT_APP_IMAGE_VIEW_URL + imgFormat(imageList[curIdx]);
              a.download = imageList[curIdx];
              a.click();
            }}
          >
            <DownloadIcon />
            다운로드
          </button>
        </div>
        <button
          type={'button'}
          className={'button-slide prev'}
          onClick={() => {
            setCurIdx((curIdx) => {
              if (curIdx === 0) {
                return imageList.length - 1;
              } else {
                return curIdx - 1;
              }
            });
          }}
        >
          <ArrowBackIosRoundedIcon />
        </button>
        <button
          type={'button'}
          className={'button-slide next'}
          onClick={() => {
            setCurIdx((curIdx) => {
              if (curIdx === imageList.length - 1) {
                return 0;
              } else {
                return curIdx + 1;
              }
            });
          }}
        >
          <ArrowForwardIosRoundedIcon />
        </button>
        {useDot && (
          <section className={'image-page'}>
            {imageList.map((image, idx) => (
              <button
                type={'button'}
                className={'dot ' + (curIdx === idx ? 'current' : '')}
                key={'dot-' + image}
                onClick={() => {
                  setCurIdx(idx);
                }}
              ></button>
            ))}
          </section>
        )}
      </ImageViewer>
    </WindowFrame>
  );
};

export default ImageViewModal;

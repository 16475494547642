import React from 'react';
import { ListsWrap } from './ViewListsElements';
import { TitleText } from 'style';
import { Scrollbars } from 'react-custom-scrollbars-2';
import EmptyBox from '../EmptyBox';
import SmallInfoModal2 from './../MenuInfo/SmallInfoModal2';

const Lists = (props) => {
  const { title, slotFilter, slotList, empty, emptyMessage, noSearch, noSearchMessage, infoModalData } = props;

  return (
    <ListsWrap className={'list-wrap'}>
      {empty ? (
        <EmptyBox message={emptyMessage}></EmptyBox>
      ) : (
        <>
          {title && (
            <div
              style={
                infoModalData && infoModalData !== ''
                  ? {
                      display: 'flex',
                      alignItems: 'flex-start',
                    }
                  : {}
              }
            >
              <TitleText style={{ marginBottom: '10px' }}>{title}</TitleText>
              {infoModalData && infoModalData !== '' && (
                <SmallInfoModal2
                  infoModalData={infoModalData}
                  style={{
                    width: '500px',
                    height: '80px',
                  }}
                />
              )}
            </div>
          )}
          {slotFilter && slotFilter}
          {noSearch ? (
            <EmptyBox message={noSearchMessage}></EmptyBox>
          ) : (
            <Scrollbars className="scrollbar-container outside-scrollbar" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
              {slotList && slotList}
            </Scrollbars>
          )}
        </>
      )}
    </ListsWrap>
  );
};

export default Lists;

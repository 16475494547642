import React, { useState } from 'react';
import utils from 'modules/utils';
import { OpenModal, Section, ModalHeader, CloseBtn, Main, InputList, LightInput, TextArea, SubmitBtn, DarkInput } from './ModalElements';
import { TitleText, BdWhiteText, NormalWhiteSpan } from 'style';
import { IoMdClose } from 'react-icons/io';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useMediaQuery } from 'react-responsive';

const DeleteAccountModal = (props) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, title, userId } = props;
  const [text, setText] = useState('');

  const onSubmit = async () => {
    try {
      const data = { text: text };
      utils.http.setMode('api');
      const res = await utils.http.post('/v1/SP_m_UserLeft_i1', null, data);
      if (res.data.success) {
        utils.dialog.alert('탈퇴 완료!', '정상적으로 탈퇴되었습니다.<br />이용해주셔서 감사합니다.', 'success');
        utils.user.requestLogout();
      } else {
        utils.dialog.alert('탈퇴 실패!', '오류가 발생했습니다.<br />관리자에게 문의해주세요.', 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {open && (
        <OpenModal>
          <Section style={{ width: '863px', height: isTablet ? '600px' : '650px' }}>
            <ModalHeader
              style={{
                borderBottom: '0',
                padding: '35px 50px 30px 50px',
                fontSize: '30px',
                fontWeight: 'bold',
                color: '#5affe2',
              }}
            >
              {title}
              <CloseBtn onClick={close}>
                <IoMdClose size="40" style={{ color: '#fff' }} />
              </CloseBtn>
            </ModalHeader>
            <section style={{ width: '100%', height: '100%' }}>
              <Scrollbars className="modal-main modal-main-scrollbar scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
                <Main
                  style={{
                    padding: '0 20px 35px 20px',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <TitleText style={{ fontWeight: 'normal', marginBottom: '30px' }}>
                    회원 탈퇴 시, 계정 정보 및 이용중인 서비스는 해지됩니다.
                    <br />
                    재가입 시 동일 아이디 사용이 불가하며, 모든 정보를 다시 등록해야 합니다.
                  </TitleText>
                  <InputList style={{ flexDirection: 'row', marginBottom: '30px' }}>
                    <BdWhiteText style={{ width: '168px' }}>아이디</BdWhiteText>
                    <DarkInput value={userId && userId} readOnly />
                  </InputList>
                  <BdWhiteText style={{ display: 'block', marginBottom: '17px' }}>회원 탈퇴 사유</BdWhiteText>
                  <NormalWhiteSpan style={{ marginBottom: '15px', display: 'block' }}>
                    이용하면서 불편하셨던 점을 알려주시면 적극 반영하여 개선하도록 하겠습니다.
                    <br />
                    그동안 수리의 달인을 이용해 주셔서 감사드립니다.
                  </NormalWhiteSpan>
                  <div className="custom-scroll-text-area" style={{ height: '149px' }}>
                    <TextArea
                      style={{
                        height: '100%',
                        backgroundColor: '#fff',
                        borderRadius: '5px',
                      }}
                      onChange={(e) => {
                        setText(e.target.value);
                      }}
                    />
                  </div>

                  <SubmitBtn type="button" onClick={onSubmit}>
                    회원탈퇴
                  </SubmitBtn>
                </Main>
              </Scrollbars>
            </section>
          </Section>
        </OpenModal>
      )}
    </>
  );
};

export default DeleteAccountModal;

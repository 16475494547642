import React from 'react';
import { styled } from 'styled-components';
import MobileHeader from 'components/Parts/Top/TopNav/MobileHeader';
import { Route, Routes, useParams } from 'react-router-dom';
import TicketPaymentMobileModal from 'components/Parts/Common/Modal/TicketPaymentMobileModal';

const MobileModal = () => {
  return (
    <Routes>
      <Route path="ticketPayment/:title" element={<TicketPaymentMobileModal />} />
    </Routes>
  );
};

export default MobileModal;

import utils from '.';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase 구성 정보
const config = {
  apiKey: 'AIzaSyBDzfrbr_6Ny2ZrSDhHvaFZ8ZHfxufZ888',
  authDomain: 'sudal-bf725.firebaseapp.com',
  //databaseURL: "https://sudal-bf725-default-rtdb.firebaseio.com",
  projectId: 'sudal-bf725',
  storageBucket: 'sudal-bf725.appspot.com',
  messagingSenderId: '640208917219',
  appId: '1:640208917219:web:0cb631b5dcf8d37443abd6',
  //measurementId: "G-KKFV3G8PPC",
};

// VAPID (Web Push) 키
const vapidKey = 'BOmzYbNKxk3nmc-fKpSudrI8lY_VKIuHniG6Rt98dA74Whvju3z3FEXHznoWSKyyPlAlSRWQ2k7ZojHAaRuxvFs';

let app = null;
let messaging = null;
let token = null;
let onMessageHandler = null;

// 앞뒤 공백 제거 함수
const removeSpace = (str) => str.replace(/(^\s*)|(\s*$)/gi, '');

// 웹 브라우저에서 Notification 및 Service Worker 지원 여부 확인
const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

// 알림 권한 요청 함수
function requestPermission() {
  console.log('알림 권한 요청 중...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('알림 권한이 부여되었습니다.');
      try {
        console.log('try');

        // Firebase 앱 초기화
        app = initializeApp(config);
        // Firebase Messaging 인스턴스 가져오기
        messaging = getMessaging();
        console.log('messaging', messaging);

        // 푸시 토큰 가져오기
        getToken(messaging, { vapidKey }).then((curToken) => {
          console.log('푸시 토큰 가져오기 성공', curToken);
          token = removeSpace(curToken);
          return token;
        });
        // 푸시 메시지 수신 이벤트 핸들링
        onMessage(messaging, function (payload) {
          console.log(payload);
          utils.isFunction(onMessageHandler) && onMessageHandler(payload);
        });
      } catch (e) {
        console.log('FCM 초기화 오류');
      }
    }
  });
}

// 푸시 알림 지원 여부 확인 후 권한 요청
if (isSupported()) requestPermission();

// 푸시 알림 관련 함수들을 담은 객체
let push = {
  // 초기화 여부 확인
  initialize() {
    return app !== null && messaging !== null;
  },
  // 푸시 토큰 가져오기
  async token() {
    if (this.initialize()) {
      if (token) {
        return removeSpace(token);
      } else {
        let token = await getToken(messaging, { vapidKey });
        return token;
      }
    }
  },
  // 푸시 메시지 핸들러 등록
  onMessage(next) {
    // onMessageHandler = next;
    // 초기화 시 핸들러 다시 등록
    if (this.initialize()) {
      onMessageHandler = next;
    }
  },
};

// push 객체를 변경 불가능하게 만듦
Object.freeze(push);
export default push;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

export const SearchBtn = styled.button`
  width: 127px;
  height: 41px;
  background-color: #7e6eff;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    width: 100px;
    height: 30px;
    font-size: 14px;
  }
`;

export const InputList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InputTitle = styled.span`
  width: 170px;
  font-size: 22px;
  font-weight: bold;
  color: #e9e9e9;

  @media screen and (max-width: 1024px) {
    width: 150px;
    font-size: 16px;
  }
`;

export const TextInput = styled.input.attrs(() => ({
  type: 'text',
}))`
  width: calc(100% - 170px);
  height: 40px;
  padding: 10px 15px;
  font-size: 20px;
  color: #10141c;
  border-radius: 3px;
  border: solid 1px #8a8a8a;
  background-color: #fff;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% - 150px);
    height: 30px;
    padding: 5px 10px;
    font-size: 14px;
  }
`;

export const TextArea = styled.textarea`
  width: calc(100% - 170px);
  height: 80px;
  padding: 10px 15px;
  font-size: 20px;
  color: #10141c;
  border-radius: 3px;
  border: solid 1px #8a8a8a;
  background-color: #fff;
  resize: none;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% - 150px);
    height: 60px;
    padding: 5px 10px;
    font-size: 14px;
  }
`;

export const InputWrap = styled.div`
  width: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  gap: 13px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 150px);
    gap: 7px;
  }
`;

export const StyledSpan = styled.span`
  font-size: 22px;
  color: #e9e9e9;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

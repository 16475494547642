import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button } from '@mui/material';
import MultiImageUpload from 'components/FileUpload/MultiImageUpload';
import { imageToImageArr } from 'modules/Upload/imageUpload';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { BdWhiteText } from 'style';
import { styled } from 'styled-components';
import CustomMuiSelect from '../CustomSelect/CustomMuiSelect';
import { FixedText, Form, Input, InputCateList, InputClearBtn, InputList, InputMoney, SubmitBtn } from './ModalElements';
import WindowFrame from './WindowFrame';
const InputBox = styled.div`
  width: 100%;
  min-width: 200px;
  height: 36px;
  border-radius: 5px;
  padding: 0 10px;
  border: solid 1px #8a8a8a;
  background-color: #fff;
  display: flex;
  align-items: center;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const GrayText = styled.div`
  font-size: 14px;
  color: #8a8a8a;
`;

const InputText = styled.input`
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: normal;
  border: none;
  background-color: transparent;
  position: relative;
  &::placeholder {
    color: #8a8a8a;
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;
const InputTextTitle = styled.input`
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: normal;
  border: none;
  background-color: transparent;
  position: relative;
  &::placeholder {
    color: #8a8a8a;
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const RecommendMsg = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
  color: var(--violet-color);
  padding-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
`;

const ContentArea = styled.textarea`
  width: 100%;
  font-size: 16px;
  &::placeholder {
    color: #8a8a8a;
    font-size: 16px;
  }
`;

/*
workDate: 일자키
sqenNumb: 순번키
direcSubject: 제목
direcContent: 내용
delKey: 이미지 삭제 키
files: 파일
dir = ‘direct’ 
 */

const placeholder = `상품 내용, 가격, 진행방법 등
상품에 대한 상세 정보를 입력해 주세요.

예시) 도배 1롤=5평 가능(시공비 제외)
- 화이트 천장지 : 25,000원~
- 버터 실크 벽지 : 38,500원~`;

export const BtnCommponent = (props) => {
  const { item, selectedItem, setSelectedItem, type, setDataObj } = props;
  return (
    <Button
      onClick={() => {
        setSelectedItem(item.nm);
        if (type === 'area') {
          setDataObj((prevDataObj) => ({
            ...prevDataObj,
            area: ''
          }));
        } else if (type === 'period') {
          setDataObj((prevDataObj) => ({
            ...prevDataObj,
            period: ''
          }));
        }
      }}
      fullWidth
      color={selectedItem === item?.nm ? 'primary' : !!type ? 'warning' : 'secondary'}
      sx={{
        fontSize: '16px',
        fontWeight: 'normal',
        height: '36px',
        borderRadius: '5px'
      }}
      variant="contained"
    >
      {item?.nm}
    </Button>
  );
};

const SoodalModal = ({ open, close, title, afterAction, refreshList, editModalData }) => {
  const formData = new FormData();
  const [isEdit, setIsEdit] = useState(false); // 수정인지 등록인지
  const [isCommunicating, setIsCommunicating] = useState(false); // 등록중인지

  //수리상품 금액
  const [inputMoney, setInputMoney] = useState('');

  //용도
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [areaTypes, setAreaTypes] = useState([]);
  const [periodTypes, setPeriodTypes] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  //카테고리 select box
  const [cateOption, setCateOption] = useState([]);
  const [selected, setSelected] = useState('');
  // 모든 필수 입력값이 채워졌는지 확인하는 상태
  const [isFormComplete, setIsFormComplete] = useState(false);

  //면적
  // x 버튼 클릭 시 견적 금액 초기화
  const onClearValue = () => {
    setInputMoney('');
  };

  const getCateList2 = () => {
    const url = `/v2/SP_p_partCategoryMySub_s1`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data.success) {
        res.data.data.forEach((item) => {
          item.value = item.partCategory2;
          item.label = item.partCateName;
        });
        setCateOption(res.data.data);
        console.log('getCateList2', res.data.data);
      }
    });
  };

  const getCommonCode = async () => {
    const propertyTypesUrl = '/v1/SP_b_Common_Code_SI/65';
    const areaTypesUrl = '/v1/SP_b_Common_Code_SI/66';
    const periodTypesUrl = '/v1/SP_b_Common_Code_SI/67';
    try {
      utils.http.setMode('api');
      const [propertyTypesRes, areaTypesRes, periodTypesRes] = await Promise.all([utils.http.get(propertyTypesUrl), utils.http.get(areaTypesUrl), utils.http.get(periodTypesUrl)]);
      setPropertyTypes(propertyTypesRes.data.data);
      setAreaTypes(areaTypesRes.data.data);
      setPeriodTypes(periodTypesRes.data.data);
    } catch (error) {
      console.error('Error fetching common codes:', error);
    }
  };

  const [dataObj, setDataObj] = useState({
    sqenNumb: '',
    workDate: '',
    title: '',
    content: '',
    area: '',
    period: ''
  });

  // 달인 카테고리 조회
  const [partnerInfoCateType, setPartnerInfoCateType] = useState([]);
  const getInfo = async () => {
    try {
      const url = `/v1/SP_p_partCompInfo_S1`;
      utils.http.setMode('api');
      const res = await utils.http.get(url);
      if (res.data.data && res.data.success) {
        console.log('getInfo', res.data.data);
        setPartnerInfoCateType(res.data.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //화면에 출력되는 이미지
  const [showImages, setShowImages] = useState([]);
  const [delKey, setDelKey] = useState([]); // 삭제할 이미지 이름

  // 등록하기 버튼 클릭
  const submitSoodalBoard = async (e) => {
    if (isCommunicating) return;
    e.preventDefault();

    console.log('partnerInfoCateType', partnerInfoCateType);

    if (showImages.length === 0 && showImages.length === 0) {
      utils.dialog.alert('이미지 업로드', '최소 이미지 1장이 필요합니다.', 'warning');
      return;
    }

    if (selected === '') {
      utils.dialog.alert('카테고리 선택', '카테고리를 선택해주세요.', 'warning');
      return;
    }

    if (partnerInfoCateType.partCategory === 'A' && selectedProperty === '') {
      utils.dialog.alert('수리상품 용도 선택', '수리상품 용도를 선택해주세요.', 'warning');
      return;
    }

    if (partnerInfoCateType.partCategory === 'A' && selectedArea === '' && dataObj.area?.trim() === '') {
      utils.dialog.alert('수리상품 면적 입력', '수리상품 면적을 입력해주세요.', 'warning');
      return;
    }

    if (selectedPeriod === '' && dataObj.period?.trim() === '') {
      utils.dialog.alert('수리상품 소요기간 입력', '수리상품 소요기간을 입력해주세요.', 'warning');
      return;
    }

    if (inputMoney === '') {
      utils.dialog.alert('수리상품 금액 입력', '수리상품 금액을 입력해주세요.', 'warning');
      return;
    }

    if (dataObj.title?.trim() === '') {
      utils.dialog.alert('수리상품 제목 입력', '수리상품 제목을 입력해주세요.', 'warning');
      return;
    }

    if (dataObj.content?.trim() === '') {
      utils.dialog.alert('수리상품 내용 입력', '수리상품 내용을 입력해주세요.', 'warning');
      return;
    }

    let stringDelKey = '';
    if (delKey.length > 0) {
      delKey.forEach((item) => {
        stringDelKey += item.serverFileName + '_//';
      });
    }
    // 이미지 파일
    showImages.forEach((image) => {
      if (image.localFlag) {
        formData.append('files', image.file);
      }
    });

    // cateOption에서 selected와 동일한 value를 가진 항목을 찾습니다.
    const selectedCateOption = cateOption.find((item) => item.value === selected);
    // selected와 동일한 value를 가진 항목이 있는 경우 해당 항목의 label을 가져옵니다.
    const cinfo1Value = selectedCateOption ? selectedCateOption.label : '';
    console.log('cinfo1Value', cinfo1Value);
    formData.append(`direcSubject`, dataObj.title); // 제목
    formData.append(`direcContent`, dataObj.content); // 내용
    formData.append('Ainfo1', selectedProperty); // 용도
    formData.append('Ainfo2', selectedArea === '' ? dataObj.area : selectedArea); // 면적
    formData.append('Ainfo3', selectedPeriod === '' ? dataObj.period : selectedPeriod); // 소요기간
    formData.append('Binfo1', partnerInfoCateType.partCategory); // 카테고리 대분류
    formData.append('Binfo2', selected); // 카테고리 중분류
    formData.append('Binfo3', ''); // 카테고리 소분류
    formData.append('Cinfo1', cinfo1Value);
    formData.append('direcMoney', utils.format.extractNumber(inputMoney)); // 금액
    formData.append('direcMoneyGubn', '0'); // 금액 협의
    formData.append('sqenNumb', dataObj?.sqenNumb ?? '');
    formData.append('workDate', dataObj?.workDate ?? '');
    formData.append('delKey', stringDelKey);
    utils.http.setMode('api');

    window.xxx = formData;

    const url = '/v2/SP_d_directV2_I2';
    setIsCommunicating(true);
    const res = await utils.http.postFile(url, formData);
    console.log('submitSoodalBoard', res);

    if (res.data.success && res.data.data.length > 0) {
      console.log(res.data);
      utils.dialog.alert(`${isEdit ? '수리상품 수정' : '수리상품 등록'} 완료!`, `작성하신 수리상품이 ${isEdit ? '수정' : '등록'}되었습니다!`, 'success').then(() => {
        refreshList();
        setIsCommunicating(false);
        utils.isFunction(close) && close();
        utils.isFunction(afterAction) && afterAction(res.data);
      });
    } else {
      setIsCommunicating(false);
      if (res?.data?.message.includes('욕설')) {
        utils.dialog.alert('비속어 금지!', res.data.message, 'error');
        return;
      }
      if (res?.data?.message.includes('승인대기')) {
        utils.dialog.alert('승인대기!', res.data.message, 'error');
        return;
      }
      utils.dialog.alert('수리상품 등록 실패!', '수리상품 등록에 실패했습니다!<br />관리자에게 문의해 주세요.', 'error');
    }
  };
  useEffect(() => {
    setPropertyTypes([]);
    getCommonCode();
    getInfo();
    getCateList2();
    // 등록일때
    if (open && Object.keys(editModalData).length === 0) {
      setIsEdit(false);
      setDataObj({
        sqenNumb: '',
        workDate: '',
        title: '',
        content: '',
        area: '',
        period: ''
      });
      setInputMoney('');
      setSelectedProperty('');
      setSelectedArea('');
      setSelectedPeriod('');
      setShowImages([]);
      setSelected('');
    }
    // 수정일때
    if (open && Object.keys(editModalData).length > 0) {
      setIsEdit(true);
      setDataObj({
        sqenNumb: editModalData.sqenNumb,
        workDate: editModalData.workDate,
        content: editModalData.direcContent,
        title: editModalData.direcSubject,
        area: areaTypes.find((item) => item.nm === editModalData.Ainfo2) ? '' : editModalData.Ainfo2,
        period: periodTypes.find((item) => item.nm === editModalData.Ainfo3) ? '' : editModalData.Ainfo3
      });
      setSelected(editModalData?.Binfo2 ?? '');
      setInputMoney(editModalData.direcMoney);
      setSelected(editModalData?.Binfo2 ?? '');
      setSelectedProperty(editModalData?.Ainfo1 ?? '');
      setSelectedArea(editModalData?.Ainfo2 ?? '');
      setSelectedPeriod(editModalData?.Ainfo3 ?? '');
      setShowImages(imageToImageArr(editModalData.detailDataImage));
    }
    if (!open) {
      setShowImages([]);
      setDelKey([]);
    }
  }, [open, editModalData]);
  useEffect(() => {
    // 모든 필수 입력값이 채워졌는지 확인
    const checkFormComplete = () => {
      const isComplete = inputMoney && selected && dataObj.title.trim() && dataObj.content.trim() && showImages.length > 0;
      if (partnerInfoCateType.partCategory === 'A') {
        return isComplete && selectedProperty && (selectedArea || dataObj.area.trim()) && (selectedPeriod || dataObj.period.trim());
      }
      return isComplete;
    };
    setIsFormComplete(checkFormComplete());
  }, [inputMoney, selected, dataObj.title, dataObj.content, showImages, selectedProperty, selectedArea, dataObj.area, selectedPeriod, dataObj.period, partnerInfoCateType.partCategory]);
  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'787px'}
      height={'761px'}
      slotFixedButton={
        isEdit ? (
          <SubmitBtn type="button" onClick={submitSoodalBoard} disabled={!isFormComplete}>
            수정
          </SubmitBtn>
        ) : (
          <SubmitBtn type="button" onClick={submitSoodalBoard} disabled={!isFormComplete}>
            등록
          </SubmitBtn>
        )
      }
    >
      <Form action="#none">
        <BdWhiteText style={{ lineHeight: '1.2' }}>
          진행 상품을 등록하고
          <br />
          더욱 빠른 상담으로 고객과 만나요!
        </BdWhiteText>
        <RecommendMsg>
          <ErrorOutlineIcon />
          이렇게 등록해 보세요
        </RecommendMsg>
        <InputCateList>
          <FlexBox>
            <BdWhiteText>카테고리</BdWhiteText>
            <GrayText>자동견적에 등록할 상품를 선택해주세요.</GrayText>
          </FlexBox>
          <CustomMuiSelect selected={selected} setSelected={setSelected} label="카테고리를 선택해 주세요." optionList={cateOption} />
        </InputCateList>
        <InputList>
          <BdWhiteText>
            사진<span>(필수)</span>
          </BdWhiteText>
          <MultiImageUpload accept={'image/jpg, image/jpeg, image/png'} name="qnaModal" imageArr={showImages} setImageArr={setShowImages} setDeleteImageArr={setDelKey} isWhite={true} maxImage={5} width={'80px'} height={'80px'} />
          <div
            style={{
              fontSize: '14px',
              color: '#fff'
            }}
          >
            <div>※ 이미지는 최소 1장, 최대 5장 등록 가능합니다.</div>
            <div>※ 첫번째 이미지는 썸네일 이미지로 사용됩니다.</div>
          </div>
        </InputList>
        {partnerInfoCateType.partCategory === 'A' ? (
          <>
            <InputList>
              <BdWhiteText>용도</BdWhiteText>
              <FlexBox>
                {propertyTypes.map((item, index) => (
                  <BtnCommponent selectedItem={selectedProperty} setSelectedItem={setSelectedProperty} key={index} item={item} />
                ))}
              </FlexBox>
            </InputList>
            <InputList>
              <BdWhiteText>면적</BdWhiteText>
              <FlexBox>
                {areaTypes.map((item, index) => (
                  <BtnCommponent selectedItem={selectedArea} setSelectedItem={setSelectedArea} type="area" setDataObj={setDataObj} key={index} item={item} />
                ))}
                <InputBox>
                  <InputText
                    type="text"
                    placeholder="직접입력"
                    onChange={(e) => {
                      const periodValue = e.target.value.trim();

                      setDataObj({
                        ...dataObj,
                        area: periodValue
                      });
                      selectedArea !== '' && setSelectedArea('');
                    }}
                    value={dataObj.area}
                    maxLength={10}
                  />
                </InputBox>
              </FlexBox>
            </InputList>
          </>
        ) : null}
        <InputList>
          <BdWhiteText>소요기간</BdWhiteText>
          <FlexBox>
            {periodTypes.map((item, index) => {
              return <BtnCommponent selectedItem={selectedPeriod} setSelectedItem={setSelectedPeriod} type="period" setDataObj={setDataObj} key={index} item={item} />;
            })}
            <InputBox>
              <InputText
                type="text"
                placeholder="직접입력"
                onChange={(e) => {
                  const periodValue = e.target.value.trim();
                  setDataObj({
                    ...dataObj,
                    period: periodValue
                  });
                  selectedPeriod !== '' && setSelectedPeriod('');
                  // inputMoney를 0000을 제거하고 저장
                  // const moneyValue = parseInt(inputMoney.replace(/,/g, ''));
                  // formData.append('direcMoney', moneyValue);
                }}
                value={dataObj.period}
                maxLength={10}
              />
            </InputBox>
          </FlexBox>
        </InputList>
        <InputList>
          <FlexBox>
            <BdWhiteText>금액</BdWhiteText>
            {/* <GrayText>상품 최소 금액과 방문상담시, 출장비를 입력해주세요.</GrayText> */}
          </FlexBox>
          <InputMoney>
            <Input
              type="text"
              onInput={(e) => {
                const 최대1억 = 100000000;
                e.preventDefault();
                if (parseInt(e.target.value.replace(/,/g, '')) <= 최대1억) {
                  setInputMoney(utils.format.extractNumber(e.target.value, ['format']));
                } else if (e.target.value === '') {
                  setInputMoney('');
                } else if (parseInt(e.target.value.replace(/,/g, '')) > 최대1억) {
                  return;
                }
              }}
              value={inputMoney}
              required
              placeholder="최대 9,999만 원 금액(원)을 입력해 주세요."
            />
            {inputMoney && (
              <>
                <div style={{ width: '90px', paddingLeft: '4px' }}>
                  <FixedText>원~</FixedText>
                </div>
                <div style={{ width: '5%', textAlign: 'center' }}>
                  <InputClearBtn type="button" onClick={onClearValue}>
                    <IoCloseOutline color="#fff" />
                  </InputClearBtn>
                </div>
              </>
            )}
          </InputMoney>
        </InputList>
        <InputList>
          <BdWhiteText style={{ width: '100px' }}>제목</BdWhiteText>
          <InputBox>
            <InputTextTitle
              type="text"
              placeholder="제목을 입력해 주세요."
              onChange={(e) => {
                setDataObj({
                  ...dataObj,
                  title: e.target.value
                });
              }}
              value={dataObj.title}
              maxLength={40}
            />
          </InputBox>
        </InputList>
        <InputList>
          <BdWhiteText>내용</BdWhiteText>
          <div className={'custom-scroll-text-area'} style={{ height: '240px' }}>
            <ContentArea
              placeholder={placeholder}
              required
              onChange={(e) => {
                setDataObj({
                  ...dataObj,
                  content: e.target.value
                });
              }}
              value={dataObj.content}
            />
          </div>
        </InputList>
      </Form>
    </WindowFrame>
  );
};

export default SoodalModal;

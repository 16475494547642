import React, { useState, useEffect, useRef } from 'react';
import { DropDown, Mark, AlertButton, AlertSubmenu, TopTitle, AlertMenuList, ListWrap, AlertContent, AlertDate, NoneAlertMessage } from './DropDownElements';
import Icon from 'components/Parts/Common/Icon';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from 'react-router-dom';
import JobPosting from './../../../Manage/Intern/JobPosting/JobPosting';
import utils from 'modules/utils';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { alarmStore } from './../../../../modules/store/store';

const AlertDropDown = () => {
  const navigate = useNavigate();
  const { alarms, getAlarms } = alarmStore();

  // 알림보관함 드롭다운 state, function
  const [isToggle, setIsToggle] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [showDropdown, setShowDropdown] = useState('none');
  const alertIconRef = useRef();
  const handleClickOutside = (e) => {
    if (alertIconRef && !alertIconRef.current.contains(e.target)) {
      setIsToggle(false);
    }
  };

  // 알림 조회 통신
  const removeAlarm = async (alarm) => {
    const url = `/v1/SP_m_notificationBell_U2`;
    const data = {
      notib_gubn: alarm.gubn,
      notib_sqen_numb: alarm.screenSqenNumb,
      notib_work_date: alarm.screenWorkDate,
    };

    //클릭시 클릭된 알림 항목 삭제(공지사항은 제외)
    // if (alarm.gubn !== 'notice') {
    //   setAlarms((alarms) => {
    //     let targetIdx = alarms.findIndex((x) => {
    //       for (let k in alarm) {
    //         if (alarm[k] !== x[k]) return false;
    //       }
    //       return true;
    //     });
    //     alarms.splice(targetIdx, 1);
    //     return [...alarms];
    //   });
    // }

    utils.http.setMode('api');
    utils.http
      .post(url, null, data)
      .then((res) => {
        console.log('removeAlarm', res);
        getAlarms();
      })
      .catch((err) => {
        console.log('removeAlarm', err);
      });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    if (isToggle) {
      setShowDropdown('grid');
    } else {
      setShowDropdown('none');
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isToggle]);

  useEffect(() => {
    setIsNew(alarms.filter((x) => x.screenRead === 1).length > 0);
  }, [alarms]);

  return (
    <DropDown ref={alertIconRef}>
      <AlertButton type={'button'} className={'alert-button'} onClick={() => setIsToggle(!isToggle)}>
        <NotificationsNoneOutlinedIcon />
        {isNew && <Mark />}
      </AlertButton>
      <AlertSubmenu display={showDropdown} height={alarms.length * 76 + alarms.length * 15 - 15 + 109}>
        <TopTitle>알림 보관함</TopTitle>
        <Scrollbars className="scrollbar-container outside-scrollbar" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
          <ListWrap>
            {alarms.map((alarm, index) => (
              <AlertMenuList
                key={alarm.sqenNumb + index}
                onClick={() => {
                  console.log('alarm', alarm);

                  let url = '';
                  let validCheck = null;
                  let deletedMsg = { title: '삭제된 항목', text: '' };
                  utils.http.setMode('api');
                  const workData = alarm.screenWorkDate === '' ? alarm.workDate : alarm.screenWorkDate;
                  const sqenNumb = alarm.screenSqenNumb === '' ? alarm.sqenNumb : alarm.screenSqenNumb;

                  switch (alarm.gubn) {
                    case 'notice': //공지사항
                      url = '/manage/news/notice';
                      validCheck = utils.http.get(`/v1/SP_m_Notice_S1/1`);
                      deletedMsg.text = '해당 공지사항이 존재하지 않습니다.';
                      break;
                    case 'qna': //문의사항
                      url = '/manage/news/faqList/qna';
                      validCheck = utils.http.get(`/v2/SP_m_PersonalInquiry_S4/0/0/partner`);
                      deletedMsg.text = '해당 문의사항이 존재하지 않습니다.';
                      break;
                    case 'partRequestView': //요청목록 견적받기
                      url = `/manage/history/estimateList/received`;
                      validCheck = utils.http.get(`/v1/SP_r_Request_s1/${workData}/${sqenNumb}`);
                      deletedMsg.text = '해당 견적사항이 존재하지 않습니다.';
                      break;
                    case 'partEmergencyRecive': //요청목록 긴급출동 채택
                      url = `/manage/history/emergencyList/sent`;
                      validCheck = utils.http.get(`/v1/SP_p_partEmergencyView_S2/${workData}/${sqenNumb}`);
                      deletedMsg.text = '해당 긴급출동 사항이 존재하지 않습니다.';
                      break;
                    case 'partEmergencyView': //요청목록 긴급출동 등록
                      url = `/manage/history/emergencyList/received`;
                      validCheck = utils.http.get(`/v1/SP_p_partEmergencyView_S2/${workData}/${sqenNumb}`);
                      deletedMsg.text = '해당 긴급출동 사항이 존재하지 않습니다.';
                      break;
                    case 'questionItem': // 질문해요
                      url = `/manage/news/questionList/question`;
                      validCheck = utils.http.get(`/v1/SP_c_questionItem_S1/${workData}/${sqenNumb}/0`);
                      deletedMsg.text = '해당 질문 게시글이 존재하지 않습니다.';
                      break;
                    case 'JobPosting': //인턴관리 구인내역
                    case 'internPostingRevice': //인턴관리 구인내역 수정
                      url = `/manage/intern/jobPosting`;
                      validCheck = utils.http.get(`/v1/SP_I_internJobPostingItem_s1/${workData}/${sqenNumb}`);
                      deletedMsg.text = '해당 구인광고가 존재하지 않습니다.';
                      break;
                    default:
                      console.log(`이동 페이지 없음 ${alarm.gubn}`);
                      break;
                  }

                  console.log('workData : ', workData, 'sqenNumb : ', sqenNumb);

                  //삭제 여부 체크 후 이동
                  if (validCheck !== null) {
                    validCheck
                      .then(({ data }) => {
                        console.log(data.data, workData, sqenNumb);
                        if (data.data.length > 0 && data.data.findIndex((x) => (x.workDate || x.work_date) === workData && (x.sqenNumb || x.sqen_numb) === sqenNumb) !== -1) {
                          navigate(`${url}/${workData}/${sqenNumb}`);
                        } else {
                          utils.dialog.alert(deletedMsg.title, deletedMsg.text, 'warning');
                        }
                      })
                      .catch(() => {});
                  }
                  // 클릭시 닫힘
                  // setIsToggle(!isToggle);
                  removeAlarm(alarm);
                }}
              >
                <AlertContent>
                  {alarm.gubn === 'notice' ? <CampaignOutlinedIcon className={'notice-icon'} /> : <></>}{' '}
                  <span title={alarm.notiSubject} style={alarm.gubn === 'notice' ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>
                    {alarm.notiSubject}
                  </span>
                  {alarm.screenRead === 1 && <i className={'new-icon'}>N</i>}
                </AlertContent>
                {/* <div>{alarm.gubn}</div> */}
                <AlertDate>{alarm.ftDt}</AlertDate>
              </AlertMenuList>
            ))}
            {alarms.length === 0 && (
              <AlertMenuList>
                <NoneAlertMessage>새로운 알림이 없습니다.</NoneAlertMessage>
              </AlertMenuList>
            )}
          </ListWrap>
        </Scrollbars>
      </AlertSubmenu>
    </DropDown>
  );
};

export default AlertDropDown;

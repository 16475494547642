
import React, { useState } from 'react';
import { OpenModalBg, Section, CloseBtn, LineHeaderText, Main, MainContent } from './ModalElements';
import { TitleText, BdWhiteText } from 'style';
import Icon from 'components/Parts/Common/Icon';
import InputPwModal from './InputPwModal';
import utils from 'modules/utils';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const InputIdModal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, certInfo } = props;

  const modalStyle = {
    height: '492px',
    padding: '60px 70px',
    position: 'relative',
  };

  const [id, setId] = useState('');

  const [pwModal, setPwModal] = useState(false);
  const openModal = () => {
    setPwModal(true);
  };
  const closeModal = () => {
    setPwModal(false);
    close();
  };

  const idCheck = async () => {
    try {
      if (id === '') {
        utils.dialog.alert('아이디 입력!', '아이디를 입력해 주세요.', 'warning');
      } else {
        utils.http.setMode('api');
        const res = await utils.http.get(`/v1/SP_m_searchUserpw_S1/${id}/${certInfo.name}/${certInfo.phone}`);

        if (res.data.data && res.data.data.length === 0) {
          utils.dialog.alert('아이디 확인!', '인증된 정보로 가입된 아이디가 아닙니다.', 'error');
        } else {
          openModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {open && (
        <OpenModalBg>
          <InputPwModal open={pwModal} close={closeModal} id={id} />
          <Section style={modalStyle}>
            <CloseBtn onClick={close} type="button" style={{ position: 'absolute', right: '7%', top: '9%' }}>
              <CloseRoundedIcon sx={{ color: '#fff', fontSize: '45px' }} />
            </CloseBtn>
            <LineHeaderText>비밀번호 찾기</LineHeaderText>
            <Main style={{ height: 'calc(100% - 101px)' }}>
              <MainContent>
                <TitleText style={{ fontSize: '30px' }}>아이디를 입력해 주세요.</TitleText>
                <div className="input-box">
                  <BdWhiteText>아이디</BdWhiteText>
                  <input
                    type="text"
                    placeholder="아이디 입력"
                    className="id-input"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        idCheck();
                      }
                    }}
                  />
                  <button className="next-btn" type="button" onClick={idCheck}>
                    다음
                  </button>
                </div>
              </MainContent>
            </Main>
          </Section>
        </OpenModalBg>
      )}
    </>
  );
};

export default InputIdModal;

import CustomSwitch from 'components/Switch/CustomSwitch';
import { partInfoStore } from 'modules/store/store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { infoModalData } from '../../../../Data/data';
import utils from '../../../../modules/utils';
import BigInfoModal from '../../../Parts/Common/MenuInfo/BiginfoModal';
import EstimateModal from '../../../Parts/Common/Modal/EstimateModal';
import ResultTop from '../../../Parts/Common/ResultTop';
import SearchInput from '../../../Parts/Common/SearchInput/SearchInput';
import TabButton from '../../../Parts/Common/TabButton/TabButton';
import DetailContent from '../../../Parts/Common/TabLists/DetailContent';
import Lists from '../../../Parts/Common/TabLists/Lists';
import { Container } from '../../../Parts/Common/TabLists/ViewListsElements';
import ReceivedEstimateContent from './ReceivedEstimateContent';
import ReceivedEstimateList from './ReceivedEstimateList';
import SentEstimateList from './SentEstimateList';

const ContainerWrap = styled(Container)`
  padding-top: 20px;
  height: calc(100% - 37px);
  .list-wrap {
    width: 37%;
  }
  .detail-content-wrap {
    width: 63%;
  }
`;

const EstimateMain = () => {
  const navigate = useNavigate();
  const { kind, workDate, sqenNumb } = useParams();
  const { partInfo } = partInfoStore();

  const modalStyle = {
    width: '659px',
    height: '140px',
  };

  // 버튼 state
  const [tabMenu, setTabMenu] = useState(kind);
  const [estimateList, setEstimateList] = useState([]);
  const [estimateView, setEstimateView] = useState({});
  const [searchWord, setSearchWord] = useState('');

  // 견적 제출 modal 창 열기/닫기
  const [estimateModal, setEstimateModal] = useState(false);

  const openEstimateModal = () => {
    setEstimateModal(true);
  };
  const closeEstimateModal = () => {
    setEstimateModal(false);
  };

  const menuName = function () {
    return kind === 'received' ? '견적 요청' : '보낸 견적';
  };

  const updateSentEstimateList = (searchWord) => {
    const url = `/v1/SP_p_partChatRequestList_S1/0`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data && res.data.data) {
          updateSearchList(searchWord, res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateSearchList = function (searchWord, searchList) {
    if (searchWord === '') {
      setEstimateList(searchList);
    } else {
      setEstimateList(searchList.filter((x) => x.subject.includes(searchWord) || x.content.includes(searchWord)));
    }
  };

  const updateView = function (list) {
    if (workDate && sqenNumb) {
      let item = list.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
      if (item.length > 0) setEstimateView(item[0]);
      else setEstimateView({});
    } else {
      setEstimateView({});
    }
  };

  const updateList = function (searchWord) {
    if (kind === 'received') {
      updateDeadBoardList(searchWord);
    } else {
      updateSentEstimateList(searchWord);
    }
  };

  const refreshList = function () {
    updateList(searchWord);
  };

  // 견적 마감 제외
  const [deadLine, setDeadLine] = useState(false);
  const updateDeadBoardList = function () {
    const url = `/v1/SP_p_partRequetList_S2`;
    const data = {
      gubn: deadLine ? 1 : 0,
      value: searchWord,
    };
    utils.http.setMode('api');
    utils.http.post(url, null, data).then(({ data }) => {
      setEstimateList(() => data?.data);
      updateView(data.data);
    });
  };
  // 견적 마감 toggle

  const toggleChecked = () => {
    setDeadLine((prev) => !prev);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (tabMenu === kind) {
      updateView(estimateList);
    }
  }, [workDate, sqenNumb]);

  useEffect(() => {
    setSearchWord('');
    setTabMenu(kind);
    updateList('');
  }, [kind]);

  useEffect(() => {
    updateDeadBoardList();
    console.log('토글', deadLine);
  }, [deadLine]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <BigInfoModal infoModalData={infoModalData} menu="요청목록" style={modalStyle} />
      </div>
      <ContainerWrap>
        <Lists
          noSearch={estimateList.length === 0}
          noSearchMessage={searchWord ? `검색된 ${menuName()} 목록이 없습니다.` : `새로운 ${menuName()} 목록이 없습니다.`}
          slotFilter={
            <>
              <TabButton
                leftBtn="견적 요청"
                rightBtn="보낸 견적"
                tabMenu={tabMenu}
                setTabMenu={(tabMenu) => {
                  navigate(`/manage/history/estimateList/${tabMenu}`);
                }}
              />
              <SearchInput
                inputVal={searchWord}
                setInputVal={setSearchWord}
                onSearch={() => {
                  updateList(searchWord);
                }}
              />
              {tabMenu === 'received' && (
                <ResultTop total={utils.format.number(estimateList.length)}>
                  <CustomSwitch label="견적마감제외" checked={deadLine} onChange={toggleChecked} />
                </ResultTop>
              )}
            </>
          }
          slotList={
            tabMenu === 'received' ? (
              <ReceivedEstimateList
                estimateView={estimateView}
                handleClickItem={(estimateView) => {
                  navigate(`/manage/history/estimateList/received/${estimateView.workDate}/${estimateView.sqenNumb}`);
                }}
                estimateList={estimateList}
                openEstimateModal={openEstimateModal}
              />
            ) : (
              <SentEstimateList
                estimateView={estimateView}
                handleClickItem={(estimateView) => {
                  navigate(`/manage/history/estimateList/sent/${estimateView.workDate}/${estimateView.sqenNumb}`);
                }}
                estimateList={estimateList}
              />
            )
          }
        />
        <DetailContent title={'견적 내용'} scrollBar={true} adFitCode="DAN-UdkAeHw0qlmAz0bd" empty={utils.isEmpty(estimateView)} emptyMessage={menuName() + ' 목록에서 견적을 선택해 주세요.'} slotContent={<ReceivedEstimateContent estimateView={estimateView} refreshList={refreshList} />} />
        <EstimateModal open={estimateModal} close={closeEstimateModal} title="견적서 작성" nickName={partInfo?.partName ?? ''} post={estimateView} afterAction={() => {}} kind="estimate" />
      </ContainerWrap>
    </>
  );
};

export default EstimateMain;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, TopSection, SaveBtn } from 'components/Manage/Info/InfoElements';
import BigInfoModal from 'components/Parts/Common/MenuInfo/BiginfoModal';
import TabMenu from 'components/Parts/Common/TabMenu/TabMenu';
import TabBusiness from './Business/Business';
import TabCalculate from './Calculate/TabCalculate';
import Icon from 'components/Parts/Common/Icon';
import utils from 'modules/utils';
import portOne from 'modules/portOne';
import { useMediaQuery } from 'react-responsive';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';

window.portOne = portOne;

const menuInfo = [
  {
    id: 'business-menu1',
    title: '사업자 정보',
    descript: `사업자 등록증에 기재된 정보를 입력 후, "저장"버튼을 눌러주세요.`,
  },
  // {
  //   id: 'business-menu2',
  //   title: '정산관리',
  //   descript: `정산 받을 계좌 정보를 올바르게 입력 후, "저장"버튼을 눌러주세요.`,
  // },
];
const tabList = {
  business: '사업자정보',
  // calculate: '정산관리'
};

const modalStyle = {
  width: '547px',
  height: '239px',
};
const BusinessList = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const [save, setSave] = useState([]);
  const [data, setData] = useState([]);
  const [isCommunicating, setIsCommunicating] = useState(false);

  // 사업자정보 조회
  const getInfo = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get('/v1/SP_p_partBusiInfo_S1');
      if (res.data.data && res.data.data.length > 0) {
        setData(res.data.data);
      }
      //console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // 본인인증
  const verification = function (kind, next) {
    portOne['verify' + utils.capitalize(kind)](
      async (rsp) => {
        let certInfo = await portOne.certInfo(rsp.imp_uid);
        if (utils.isEmpty(certInfo)) {
          utils.dialog.alert('인증정보 없음', '인증정보를 가져올 수 없습니다.<br>다시 시도해 주세요.', 'error');
        } else {
          // 로그인한 사용자의 이름과 certInfo의 name이 같으면 인증 성공 // local
          const userInfo = JSON.parse(localStorage.getItem('user'));
          if (userInfo.userName === certInfo.name) {
            utils.isFunction(next) && next(certInfo);
            return;
          }
          utils.dialog.alert('인증실패', '계정과 일치하지 않습니다.<br>고객센터로 문의해 주세요.', 'error');
        }
      },
      (rsp) => {}
    );
  };

  const verifyMove = function (kind, save) {
    // TODO: 본인인증 후 저장 주석처리 23.10.30
    // verification(kind, (certInfo) => {
    //   portOne.setCertInfo(certInfo);
    //   save();
    // });
    save();
  };

  const { info } = useParams();
  let kind = info ?? 'business';
  let TabSection = '';
  switch (kind) {
    case 'business':
      TabSection = <TabBusiness setSave={setSave} data={data} kind={kind} refresh={getInfo} />;
      break;
    case 'calculate':
      TabSection = <TabCalculate setSave={setSave} data={data} setData={setData} kind={kind} />;
      break;
  }

  const handleSave = () => {
    if (isCommunicating) return;
    setIsCommunicating(true);
    utils.isFunction(save?.onSave) && save.onSave().then(() => setIsCommunicating(false));
  };

  const saveData = async () => {
    if (kind === 'calculate') {
      const userInfo = data[0];
      console.log('userInfo', userInfo);

      if (userInfo?.busiBankName === '') {
        utils.dialog.alert('은행명 없음', '은행명이 없습니다</br>은행명을 입력해 주세요.', 'warning');
        return;
      } else if (userInfo?.busiAccount === '') {
        utils.dialog.alert('계좌번호 없음', '계좌번호가 없습니다</br>계좌번호를 입력해 주세요.', 'warning');
        return;
      } else if (userInfo?.busiBankAccoutHolder === '') {
        utils.dialog.alert('예금주명 없음', '예금주명이 없습니다</br>예금주명을 입력해 주세요.', 'warning');
        return;
      }
      // TODO: 본인인증 후 저장 주석처리 23.10.30
      // utils.dialog.confirm('본인 인증 필요', '계좌 변경은 본인 인증이 필요합니다.', 'warning', '', '본인 인증').then((res) => {
      //   res && verifyMove('danal', handleSave);
      // });
      utils.dialog.confirm('저장 확인', '계좌 변경을 하시겠습니까?', 'warning', '', '저장하기').then((res) => {
        res && verifyMove('danal', handleSave);
      });

      return;
    }
    handleSave();
  };
  useEffect(() => {
    getInfo();
    //console.log(info);
  }, [kind]);
  return (
    <>
      <TopSection>
        <BigInfoModal infoModalData={menuInfo} menu="사업자정보" style={modalStyle} />
        <SaveBtn type="button" onClick={saveData}>
          <BookmarkAddOutlinedIcon />
          저장
        </SaveBtn>
      </TopSection>
      <Container>
        <TabMenu tabList={tabList} selected={kind} size={isTablet ? 'small' : 'big'} path="/manage/info/businessInfo/" />
        {TabSection}
      </Container>
    </>
  );
};

export default BusinessList;

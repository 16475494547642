import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { BdWhiteText } from 'style';
import { styled } from 'styled-components';
import WindowFrame from './WindowFrame';

const Form = styled.form`
  width: 100%;
  height: 100%;
  padding: 30px 0 0px;
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  .title-text {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    width: 163px;
  }

  .data-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 36px;
  }

  .calc-type-btn {
    width: 241px;
    height: 41px;
    border-radius: 5px;
    border: solid 1px #8a8a8a;
    background-color: #212838;
    font-size: 18px;
    font-weight: bold;
    color: #8a8a8a;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: #7e6eff;
      color: #fff;
    }
  }

  .selected-type-btn {
    width: 241px;
    height: 41px;
    border-radius: 5px;
    border: solid 1px #8a8a8a;
    background-color: #7e6eff;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }

  .dark-input {
    width: calc(100% - 163px);
    height: 41px;
    border-radius: 5px;
    border: solid 1px #8a8a8a;
    background-color: #212838;
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    padding: 5px 12px;
  }

  .light-input-box {
    width: calc(100% - 163px);
    height: 41px;
    border-radius: 5px;
    border: solid 1px #8a8a8a;
    background-color: #fff;
    display: flex;
    align-items: center;

    .light-input {
      background: transparent;
      height: 100%;
      width: calc(100% - 43px);
      font-size: 18px;
      font-weight: bold;
      color: #10141c;
      padding: 5px 0 5px 19px;
      text-align: right;

      &::placeholder {
        color: #8a8a8a;
        font-weight: normal;
        text-align: left;
      }

      &:focus::placeholder {
        visibility: hidden;
      }
    }

    .fix-text {
      display: none;
      width: 20px;
      height: 100%;
      align-items: center;
      margin: 0 19px 0 2px;
      font-size: 18px;
      font-weight: bold;
      color: #10141c;
    }
  }

  .result-box {
    height: 165px;
    width: calc(100% - 163px);
    border-radius: 5px;
    border: solid 1px #8a8a8a;
    background-color: #212838;
    display: flex;
    flex-direction: column;
    padding: 12px 21px;

    hr {
      margin: 18px 0 7px;
      height: 1px;
      background-color: #fff;
    }

    div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .item-title {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        width: 130px;
      }

      .addition-text-type1 {
        margin-left: 6px;
        font-size: 18px;
        font-weight: normal;
        color: #ddd;
      }

      .addition-text-type2 {
        margin-left: 6px;
        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
        color: #ddd;
      }

      .item {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
`;

const SubmitBtn = styled.button`
  width: calc(100% - 60px);
  border-radius: 5px;
  background-color: #7e6eff;
  height: 45px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 30px;

  &:hover {
    background-color: #6959de;
  }
`;

// 출금 신청 모달 스타일
const modalHeaderStyle = {
  padding: '30px 50px',
};
const modalBodyStyle = {
  main_section: { padding: '30px 20px 35px' },
  modal_main: { padding: 0 },
  fixed_button: { padding: 0 },
};

const ApplyModal = ({ open, close, title, possibleMoney, account, getStatus }) => {
  // 정산유형 state
  const [calcType, setCalcType] = useState(null);
  const [reqResult1, setReqResult1] = useState({
    paySupply: '-',
    payVat: '-',
    resultMoney: '-',
  });

  const [reqResult2, setReqResult2] = useState({
    paySupply: '-',
    payVat: '-',
    payTax: '-',
    resultMoney: '-',
  });

  // 신청 금액 state
  const [applyMoney, setApplyMoney] = useState(0);
  const [showApplyMoney, setShowApplyMoney] = useState('');

  // 신청 금액 입력 전 정산 유형 선택 확인
  const [btnSelected, setBtnSelected] = useState(false);
  const isSelected = () => {
    if (!btnSelected) {
      utils.dialog.alert('정산유형 선택!', '정산유형 선택 후 신청금액을<br />입력해 주세요.', 'warning');
      setApplyMoney(0);
      setShowApplyMoney('');
      return 0;
    }
  };

  // 신청 금액 10000원 단위로 변환
  const onChangeMoney = (money) => {
    // 신청가능 금액
    // money = parseInt(money);
    // if (money % 10000 === 0) {
    //   setApplyMoney(Math.floor(money / 10000) * 10000);
    // }

    // 10000원 이상일 때 10000원단위 버림
    money = parseInt(money);
    if (money >= 1000) {
      setApplyMoney(Math.floor(money / 1000) * 1000);
    } else {
      setApplyMoney(0);
    }
  };

  // 출금 내용 조회
  const getWithdraw = async () => {
    try {
      if (calcType !== null) {
        utils.http.setMode('api');
        const res = await utils.http.get(`/v1/SP_p_partnerSalePrice_S1/${calcType}/${applyMoney}`);
        if (res.data.data && res.data.data.length > 0) {
          if (calcType === 0) {
            setReqResult1({
              ...reqResult1,
              paySupply: res.data.data[0].paySupply,
              payVat: res.data.data[0].payVat,
              resultMoney: res.data.data[0].mony,
            });
          } else {
            setReqResult2({
              ...reqResult2,
              paySupply: res.data.data[0].paySupply,
              payVat: res.data.data[0].payVat,
              payTax: res.data.data[0].payTax,
              resultMoney: res.data.data[0].mony,
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 출금하기 버튼 클릭
  const onSubmit = async (e) => {
    e.preventDefault();

    // 금액이 0원일 경우
    if (applyMoney === 0) {
      utils.dialog.alert('신청 금액 입력!', '출금 신청 금액을 입력해 주세요.', 'warning');
      return;
    }

    utils.dialog.confirm(`출금 신청!`, `${applyMoney}원을 출금하시겠습니까?<br/>[1,000원 단위]`, 'question').then(async (res) => {
      if (res) {
        try {
          const data = { atmGubn: calcType, atmMony: applyMoney };
          utils.http.setMode('api');
          const res = await utils.http.post('/v1/SP_p_partnerSaleATM_I1', null, data);
          // 정상 신청 됐을 경우
          if (res.data.data && res.data.data.length > 0) {
            //console.log(res.data.data);
            utils.dialog.alert('신청 완료!', '영업일 기준 2~3일내로 출금되며,<br/>기간 내 미출금시 고객센터로 문의바랍니다.', 'success');
            getStatus();
            close();
          }
          // 신청 시 에러 났을 경우(팝빌 가입 X)
          else if (!res.data.success) {
            utils.dialog.alert('출금 신청 거절', res.data.message, 'error');
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  useEffect(() => {
    setCalcType(1);
    setBtnSelected(true);
  }, []);

  useEffect(() => {
    setReqResult1({
      ...reqResult1,
      paySupply: '-',
      payVat: '-',
      resultMoney: '-',
    });
    setReqResult2({
      ...reqResult2,
      paySupply: '-',
      payVat: '-',
      payTax: '-',
      resultMoney: '-',
    });
    setApplyMoney(0);
    setShowApplyMoney('');
  }, [open]);

  useEffect(() => {
    getWithdraw();
  }, [applyMoney, calcType]);

  return (
    <>
      {open && account !== '' && (
        <WindowFrame
          open={open}
          close={close}
          title={title}
          width={'781px'}
          height={'761px'}
          modalBodyStyle={modalBodyStyle}
          modalHeaderStyle={modalHeaderStyle}
          slotFixedButton={
            <SubmitBtn type="button" onClick={(e) => onSubmit(e)}>
              신청
            </SubmitBtn>
          }
        >
          <BdWhiteText style={{ fontWeight: 'normal' }}>
            매주 <strong style={{ color: '#5affe2' }}>화요일 16시 전</strong> 신청건에 한 해, 영업일 2~3일 내로 출금됩니다.
            <br />
            출금 신청 시, 입력 정보를 꼭 확인해 주세요.
          </BdWhiteText>
          <Form action="#none">
            <Box style={{ alignItems: 'flex-start' }}>
              <div className="title-text" style={{ marginTop: '4px' }}>
                정산유형
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'calc(100% - 163px)',
                  gap: '6px',
                }}
              >
                <div className="data-wrap">
                  <button
                    type="button"
                    className={calcType === 0 ? 'selected-type-btn' : 'calc-type-btn'}
                    onClick={() => {
                      setCalcType(0);
                      setBtnSelected(true);
                      utils.dialog.alert('세금계산서 발행!', '세금계산서 발행은 추후 오픈 예정입니다.<br/>현재 원천징수 출금만 가능합니다.', 'warning').then((res) => {
                        setCalcType(1);
                      });
                    }}
                  >
                    세금계산서 발행
                  </button>
                  <button
                    type="button"
                    className={calcType === 1 ? 'selected-type-btn' : 'calc-type-btn'}
                    onClick={() => {
                      setCalcType(1);
                      setBtnSelected(true);
                    }}
                  >
                    원천징수
                  </button>
                </div>
              </div>
            </Box>
            <Box>
              <div className="title-text">계좌</div>
              <input type="text" value={account} className="dark-input" readOnly />
            </Box>
            <Box>
              <div className="title-text">출금가능금액</div>
              <div
                className="data-wrap"
                style={{
                  gap: '15px',
                  width: 'calc(100% - 163px)',
                  alignItems: 'center',
                }}
              >
                <input
                  type="text"
                  className="dark-input"
                  style={{
                    width: 'calc(100% - 210px)',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                  value={utils.format.extractNumber(possibleMoney, ['format'])}
                  readOnly
                />
                <p
                  style={{
                    fontSize: '17px',
                    fontWeight: 'bold',
                    color: '#fff',
                    width: '195px',
                  }}
                >
                  원까지 신청할 수 있습니다.
                </p>
              </div>
            </Box>
            <Box>
              <div className="title-text">신청금액입력</div>
              <div className="light-input-box">
                <input
                  className="light-input"
                  type="text"
                  placeholder="신청 금액을 넣어주세요.[1,000원 단위]"
                  onChange={(e) => {
                    if (parseInt(e.target.value) <= parseInt(possibleMoney)) {
                      setShowApplyMoney(e.target.value);
                      onChangeMoney(e.target.value);
                    } else if (e.target.value === '') {
                      setShowApplyMoney('');
                      onChangeMoney(0);
                    } else {
                      setShowApplyMoney(possibleMoney);
                      onChangeMoney(possibleMoney);
                    }
                  }}
                  onClick={isSelected}
                  value={showApplyMoney}
                />
                <div className="fix-text" style={{ display: btnSelected && 'flex' }}>
                  원
                </div>
              </div>
            </Box>
            <Box style={{ alignItems: 'flex-start', marginTop: '26px' }}>
              <div className="title-text">출금내용</div>
              <div className="result-box">
                <div>
                  <p className="item-title">신청금액</p>
                  <p className="item">{applyMoney}원</p>
                </div>
                {calcType === 0 || calcType === null ? (
                  <>
                    <div>
                      <p className="addition-text-type1">ㄴ공급가액</p>
                      <p className="addition-text-type1">{reqResult1.paySupply}원</p>
                    </div>
                    <div>
                      <p className="addition-text-type1">ㄴ부가세</p>
                      <p className="addition-text-type1">{reqResult1.payVat}원</p>
                    </div>
                    <hr />
                    <div>
                      <p className="item-title">총 출금금액</p>
                      <p className="item">{reqResult1.resultMoney}원</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <p className="addition-text-type2">ㄴ공급가액</p>
                      <p className="addition-text-type2">{reqResult2.paySupply}원</p>
                    </div>
                    <div>
                      <p className="addition-text-type2">ㄴ부가세</p>
                      <p className="addition-text-type2">{reqResult2.payVat}원</p>
                    </div>
                    <div>
                      <p className="addition-text-type2">ㄴ원천징수세</p>
                      <p className="addition-text-type2">{reqResult2.payTax}원</p>
                    </div>
                    <hr style={{ margin: '2px 0 3px' }} />
                    <div>
                      <p className="item-title">총 출금금액</p>
                      <p className="item">{reqResult2.resultMoney}원</p>
                    </div>
                  </>
                )}
              </div>
            </Box>
          </Form>
        </WindowFrame>
      )}
    </>
  );
};

export default ApplyModal;

import { Scrollbars } from 'react-custom-scrollbars-2';
import { TitleText } from 'style';
import EmptyBox from '../EmptyBox';
import KakaoAdFit from './../../../Adfit/KakaoAdfit';
import { DetailContentWrap, DialogWrap } from './ViewListsElements';

const DetailContent = ({ title, slotTitleRight, scrollBar, slotContent, empty, emptyMessage, inputBox, userId, adFitCode }) => {
  return (
    <div
      style={{
        width: '66%',
        height: '100%'
      }}
    >
      <KakaoAdFit
        style={{
          height: '90px',
          backgroundColor: '#f5f5f5',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          marginBottom: '10px',
          overflowX: 'hidden'
        }}
        unit={adFitCode}
        width="728"
        height="90"
      />
      <DetailContentWrap
        style={{
          height: 'calc(100% - 100px)'
        }}
      >
        {empty ? (
          <EmptyBox message={emptyMessage}></EmptyBox>
        ) : (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden'
            }}
          >
            <div className="detail-content-top">
              <div className="chat-title">
                <TitleText>{title}</TitleText>
              </div>
              {slotTitleRight && slotTitleRight}
            </div>
            <DialogWrap>
              {slotContent &&
                (scrollBar ? (
                  <Scrollbars className="detail-content scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
                    {slotContent}
                  </Scrollbars>
                ) : (
                  slotContent
                ))}
            </DialogWrap>
            {inputBox}
          </div>
        )}
      </DetailContentWrap>
    </div>
  );
};

export default DetailContent;

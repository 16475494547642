import { styled } from 'styled-components';
const GrabScrollSection = styled.section`
  cursor: grab;
  overflow: auto;
  user-select: none;
  img {
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
  }
`;
const GrabScroll = (props) => {
  let pos = { top: 0, left: 0, x: 0, y: 0 };
  let grab = null;
  const mouseDownHandler = function (e) {
    if (e.currentTarget.className.includes('grab-scroll')) {
      pos = {
        // The current scroll
        left: e.currentTarget.scrollLeft,
        top: e.currentTarget.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };
      grab = e.currentTarget;
      e.currentTarget.style.cursor = 'grabbing';

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  };
  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    //console.log(grab, dx, dy);

    // Scroll the element
    grab.scrollTop = pos.top - dy;
    grab.scrollLeft = pos.left - dx;
  };
  const mouseUpHandler = function (e) {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    grab.style.cursor = 'grab';
    grab = null;
  };

  return (
    <GrabScrollSection
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      className="grab-scroll scrollbar-container2"
      onMouseDown={mouseDownHandler}
    >
      {props.children}
    </GrabScrollSection>
  );
};

export default GrabScroll;

import React, { useState, useEffect } from 'react';
import { Div, ProfileBox, InfoBox, IntroduceBox, Introduce, IntroduceContent, IntroduceLength, TitleBox, SubTitleText } from './ProfileElements';
import ProfileImg from 'components/Parts/Common/ProfileImg/ProfileImg';
import { ContentWrap, SaveText } from 'components/Manage/Info/InfoElements';
import { TitleText } from 'style';
import utils from 'modules/utils';
import { partInfoStore } from 'modules/store/store';

const TabProfile = ({ setSave }) => {
  const [placeHolder, setPlaceHolder] = useState('');
  const [introduce, setIntroduce] = useState('');
  const [introduceLength, setIntroduceLength] = useState(0);
  const [profileCount, setProfileCount] = useState(0);
  const { setPartInfo } = partInfoStore();

  const [partnerInfo, setPartnerInfo] = useState([]);
  const getInfo = async () => {
    try {
      const url = `/v1/SP_p_partner_S1/${utils.user('id')}`;
      utils.http.setMode('api');
      const res = await utils.http.get(url);
      if (res.data.data && res.data.success) {
        setPartnerInfo(res.data.data);
        setPartInfo(res.data.data[0]);
        setProfileCount(res.data.data[0]?.partProfile ?? 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 서버에 전송할 이미지 파일
  const [selectedFile, setSelectedFile] = useState('');

  const getIntroduce = () => {
    if (partnerInfo[0].partIntroduce2 !== '' && partnerInfo[0].partIntroduce2 !== undefined) {
      setPlaceHolder('');
      setIntroduce(partnerInfo[0].partIntroduce2);
      setIntroduceLength(partnerInfo[0].partIntroduce2.replace(/(\n)/g, '').length);
    } else {
      setPlaceHolder(`소개글을 입력해 주세요.\n\nex) 인테리어 전문 업체 OO인테리어 입니다.\n도배, 조명, 리모델링 등 시공을 하고 있습니다.`);
      setIntroduce('');
    }
  };

  // 저장 버튼 클릭했을 때 api 요청
  const onSave = async () => {
    try {
      const formData = new FormData();
      formData.append('partId', utils.user('id'));
      formData.append('files', selectedFile);
      formData.append('partList', introduce);
      console.log(selectedFile);
      formData.append('delKey', '');
      console.log(formData.get('delKey'));
      formData.append('profileCount', profileCount);

      // 달인 프로필 저장
      const res = await utils.http.postFile('/v2/SP_p_partCompInfoV2_wi1', formData);
      console.log(res.data);
      if (res.data.success) {
        utils.dialog.alert('저장 완료!', '입력하신 내용이 저장되었습니다.<br />주기적으로 관리하여<br />우리 업체를 홍보해요!', 'success');
        let topProfile = window.document.querySelector('#topProfileImage');
        topProfile.src = topProfile.src + '?' + new Date().getTime();
        getInfo();
      } else {
        if (res?.data?.message.includes('욕설')) {
          utils.dialog.alert('비속어 금지!', res.data.message, 'error');
          return;
        }
        console.log('저장 실패!', res.data.message);
        utils.dialog.alert('저장 실패!', '오류가 발생했습니다. 관리자에게 문의해 주세요.', 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (partnerInfo.length > 0) {
      getIntroduce();
    } else {
      setPlaceHolder(`소개글을 입력해 주세요.\n\nex) 인테리어 전문 업체 OO인테리어 입니다.\n도배, 조명, 리모델링 등 시공을 하고 있습니다.`);
      setIntroduce('');
    }
  }, [partnerInfo]);
  useEffect(() => {
    if (setSave) {
      setSave({ onSave });
    }
  }, [selectedFile, introduce]);

  return (
    <ContentWrap>
      <TitleBox>
        <TitleText>프로필</TitleText>
        <SubTitleText>[프로필 사진 변경 시 저장을 해주세요.]</SubTitleText>
      </TitleBox>
      <Div>
        <ProfileBox>
          <ProfileImg profileCount={profileCount} setProfileCount={setProfileCount} userGubn={'partner'} setSelectedFile={setSelectedFile} />
          <InfoBox>
            <div className="info-list">
              <p className="info-title-text">업체명</p>
              <p className="info-data-text">{partnerInfo.length > 0 && partnerInfo[0].partName !== undefined ? partnerInfo[0].partName : '영업정보에서 업체명을 입력해 주세요.'}</p>
            </div>
            <div className="info-list">
              <p className="info-title-text">카테고리</p>
              <p className="info-data-text">{partnerInfo.length > 0 && partnerInfo[0].partCategoryName !== undefined ? partnerInfo[0].partCategoryName : '카테고리에서 1,2차 카테고리를 설정해 주세요.'}</p>
            </div>
          </InfoBox>
        </ProfileBox>
        <IntroduceBox>
          <TitleText>서비스 상세설명</TitleText>
          <Introduce>
            <IntroduceContent
              placeholder={placeHolder}
              value={introduce}
              onChange={(e) => {
                setIntroduce(e.target.value);
                setIntroduceLength(e.target.value.length);
              }}
              maxLength={2000}
            />
          </Introduce>
          <IntroduceLength>[{introduceLength}/2000]</IntroduceLength>
        </IntroduceBox>
      </Div>
      <SaveText>
        <div>※ 정보 입력 및 수정 시, 저장버튼을 눌러주세요</div>
      </SaveText>
    </ContentWrap>
  );
};

export default TabProfile;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
`;

const HeaderBtn = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileHeader = (props) => {
  const { title } = props;
  const navegate = useNavigate();
  const handleGoBack = () => {
    navegate(-1);
  };

  console.log('title!!!!!', title);
  return (
    <div
      style={{
        height: '50px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px',
        color: '#fff',
        backgroundColor: '#10141c',
      }}
    >
      <HeaderBtn onClick={handleGoBack}>
        <ArrowBackRoundedIcon />
      </HeaderBtn>
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderBtn></HeaderBtn>
    </div>
  );
};

export default MobileHeader;

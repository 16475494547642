import { Box, Checkbox, Divider, FormControlLabel } from '@mui/material';
import { checkSubscribed } from 'components/Manage/Ticket/TicketList/TicketList';
import { Card } from 'components/Manage/Ticket/TicketList/TicketListElements';
import Modal from 'components/RegisterForm/ShowLawModal/ShowLawModal';
import dayjs from 'dayjs';
import { partInfoStore, reservationPaymentStore } from 'modules/store/store';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { BdWhiteText } from 'style';
import { styled } from 'styled-components';
import PaymentSelect from './../../../Payment/PaymentSelect';
import CheckBox from './../forms/CheckBox/CheckBox';
import { CustomTicket, partnerAreaSave } from './AreaAndTicketModal';
import { Form, SubmitBtn } from './ModalElements';
import WindowFrame from './WindowFrame';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const termsAndConsitions = ['모두 동의합니다.', '(필수)개인정보 수집 및 이용 동의', '(필수)정기 결제 서비스 이용 약관 동의'];

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const CardLabel = styled.div`
  width: 200px;
  font-size: 22px;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    width: 100px;
    font-size: 14px;
  }
`;

export const CardLabelSub = styled.div`
  width: 200px;
  font-size: 18px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 100px;
    font-size: 12px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;

  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`;

export const TitleIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

const WhiteBox = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 10px;
`;

const CustomDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-bottom: 20px;
`;

const SubTitle = styled.div`
  color: #8a8a8a;
  font-size: 14px;
  font-weight: bold;
`;

const SpaceBetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GrayLabel = styled.span`
  color: #8a8a8a;
`;

const IMP = window.IMP;

const KAKAO_ONE_PAY_PG = process.env.REACT_APP_KAKAO_ONE_PAY_PG;
const KAKAO_AUTO_PAY_PG = process.env.REACT_APP_KAKAO_AUTO_PAY_PG;
const TOSS_ONE_PAY_PG = process.env.REACT_APP_TOSS_ONE_PAY_PG;
const TOSS_AUTO_PAY_PG = process.env.REACT_APP_TOSS_AUTO_PAY_PG;

const TicketPaymentModal = (props) => {
  const USER_ID = utils.user('id');
  const USER_NAME = utils.user('userName');
  const USER_HP = utils.user('userHp');
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, title, ticket, reflashTicketList, parentsModalClose, chipData } = props;

  const [paymentEndDate, setPaymentEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [eventEndDate, setEventEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  // 모달창
  const [개인정보수집이용, set개인정보수집이용] = useState(false);
  const [정기결제서비스약관, set정기결제서비스약관] = useState(false);

  const [selectedPayment, setSelectedPayment] = useState(0);

  // 월 정기결제
  const [autoPayment, setAutoPayment] = useState(true); // 정기결제

  // 오늘 날짜
  const today = dayjs();
  // checkbox state
  const [checkList, setCheckList] = useState([]);

  // checkbox 개별 체크
  const onCheckedElement = (e) => {
    e.target.checked ? setCheckList([...checkList, e.target.name]) : setCheckList(checkList.filter((choice) => choice !== e.target.name));
    console.log(checkList);
  };

  const { getTicketList } = reservationPaymentStore();
  const { selectedTicket, partInfo, getPartInfo } = partInfoStore();

  const saveOnePaymentLog = async (res, pg) => {
    const url = `/v1/SP_b_user_ticket_payment_I1`;
    const data = {
      ticketCode: res.name ?? '',
      price: pg === TOSS_ONE_PAY_PG ? res.amount ?? '' : res.paid_amount ?? '',
      ticketGubn: 'common',
      googleToken: '',
      imp_uid: res.imp_uid ?? '',
      merchant_uid: res.merchant_uid ?? ''
    };

    try {
      utils.http.setMode('api');
      const response = await utils.http.post(url, { appleToken: '' }, data);
      if (response.data.success) {
        console.log('결제 로그 저장 성공');
        setTimeout(() => {
          partnerAreaSave(chipData);
          parentsModalClose();
          reflashTicketList && reflashTicketList();
        }, 1000);
      } else {
        console.log('결제 로그 저장 실패', response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveAutoPaymentLog = async (res, pg) => {
    const autoPaymentUrl = pg === TOSS_AUTO_PAY_PG ? `/v2/SP_b_user_CardV2_I2` : `/v2/SP_b_user_CardV2_I1`;
    const data = {
      apply_num: res.apply_num ?? '',
      bank_name: res.bank_name ?? '',
      buyer_addr: res.buyer_addr ?? '',
      buyer_email: res.buyer_email ?? '',
      buyer_name: res.buyer_name ?? '',
      buyer_postcode: res.buyer_postcode ?? '',
      buyer_tel: res.buyer_tel ?? '',
      card_name: res.card_name ?? '',
      card_number: res.card_number ?? '',
      card_quota: res.card_quota ?? '',
      currency: res.currency ?? '',
      custom_data: res.custom_data ?? '',
      customer_uid: res.customer_uid ?? '',
      imp_uid: res.imp_uid ?? '',
      merchant_uid: res.merchant_uid ?? '',
      name: res.name ?? '',
      paid_amount: pg === TOSS_AUTO_PAY_PG ? (partInfo.partnerFirstTicket === 'N' ? ticket?.sale_price : ticket?.first_sale_price) : res.paid_amount ?? '',
      paid_at: res.paid_at ?? '',
      partLevel: ticket?.level_name ?? '', // partLevel 구독권 코드(bronze, silver, gold, platinum, diamond)
      pay_method: res.pay_method ?? '',
      pg_provider: res.pg_provider ?? '',
      pg_tid: res.pg_tid ?? '',
      pg_type: res.pg_type ?? '',
      receipt_url: res.receipt_url ?? '',
      status: res.status ?? '',
      success: res.success ?? ''
    };

    try {
      utils.http.setMode('api');
      const response = await utils.http.post(autoPaymentUrl, null, data);
      if (response.data.success) {
        console.log('결제 로그 저장 성공');
        setTimeout(() => {
          partnerAreaSave(chipData);
          parentsModalClose();
          reflashTicketList && reflashTicketList();
        }, 1000);
      } else {
        console.log('결제 로그 저장 실패', response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 결제 방법
  const paymentMethod = () => {
    switch (selectedPayment) {
      case 1:
        return autoPayment ? kakaoAutoPay() : kakaoOnePay();
      case 2:
        return autoPayment ? tossAutoPay() : tossOnePay();
      default:
        console.error('잘못된 결제 방법');
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // 필수 항목 체크
    if (selectedPayment === 0) {
      utils.dialog.alert('결제 수단', '결제 수단을 선택해 주세요.', 'warning');
      return;
    }

    // autoPayment가 true면 정기결제서비스약관도 필수
    if (!checkList.includes('개인정보수집이용')) {
      utils.dialog.alert('개인정보 수집 이용', '개인정보 수집 이용에 동의해 주세요.', 'warning');
      return;
    }
    if (autoPayment && !checkList?.includes('정기결제서비스약관')) {
      utils.dialog.alert('정기 결제 서비스 이용 약관 동의', '정기 결제 체크 시 정기 결제 서비스 이용 약관 동의가 필요합니다.', 'warning');
      return;
    }

    await getTicketList().then((res) => {
      if (res.part_level === selectedTicket?.ticket_level) {
        utils.dialog.alert('같은 구독권!', '구독중인 구독권과 같은 구독권으로 변경이 불가능합니다.', 'warning');
      }
    });

    if (autoPayment) {
      checkSubscribed().then((res) => {
        if (res) {
          utils.dialog.alert('구독중', '이미 구독중인 구독권이 있습니다.', 'warning');
        } else {
          paymentMethod();
        }
      });
    } else {
      paymentMethod();
    }
  };

  const paymentOption = (pg) => {
    const isTosspay = pg.includes('tosspay_v2');
    console.log('문자열 포함 유무', isTosspay, autoPayment);

    return {
      pg,
      pay_method: isTosspay ? 'tosspay' : 'card', // 토스인지 카카오인지
      merchant_uid: `${USER_ID}_${ticket?.level_name}_${dayjs().format('YYYYMMDDHHmmss')}`,
      name: ticket?.level_name,
      amount: partInfo.partnerFirstTicket === 'N' ? ticket?.sale_price : ticket?.first_sale_price,
      customer_uid: autoPayment ? `${USER_ID}_billing_${dayjs().format('YYYYMMDDHHmmss')}` : null, // 정기결제시 필수
      buyer_name: USER_NAME,
      buyer_tel: USER_HP,
      m_redirect_url: isTosspay ? 'https://localhost:3000/manage/ticket/purchaseHistory' : null, // 토스결제시 필수
      customer_id: isTosspay && autoPayment ? `${USER_ID}_${dayjs().format('YYYYMMDDHHmmss')}` : null // 토스 정기결제시 필수
    };
  };

  // 카카오페이 일반결제
  const kakaoOnePay = () => {
    IMP.request_pay(paymentOption(KAKAO_ONE_PAY_PG), function (rsp) {
      if (!rsp || rsp.error_msg) {
        utils.dialog.alert('결제 실패', rsp.error_msg, 'warning');
        return;
      }
      saveOnePaymentLog(rsp, KAKAO_ONE_PAY_PG);
      utils.dialog.alert('결제 성공', '결제가 완료되었습니다.', 'success').then((res) => {
        res && close();
      });
    });
  };

  // 카카오페이 정기결제
  const kakaoAutoPay = () => {
    IMP.request_pay(paymentOption(KAKAO_AUTO_PAY_PG), function (rsp) {
      if (!rsp || rsp.error_msg) {
        utils.dialog.alert('결제 실패', rsp.error_msg, 'warning');
        return;
      }
      saveAutoPaymentLog(rsp, KAKAO_AUTO_PAY_PG);
      utils.dialog.alert('결제 성공', '결제가 완료되었습니다.', 'success').then((res) => {
        res && close();
      });
    });
  };

  //토스 일반결제
  const tossOnePay = () => {
    IMP.request_pay(paymentOption(TOSS_ONE_PAY_PG), function (rsp) {
      if (!rsp || rsp.error_msg) {
        utils.dialog.alert('결제 실패', rsp.error_msg, 'warning');
        return;
      }
      getPaymentDetail(rsp.imp_uid, 'tossOnePay');
    });
  };

  //토스 정기결제
  const tossAutoPay = () => {
    IMP.request_pay(paymentOption(TOSS_AUTO_PAY_PG), function (rsp) {
      if (!rsp || rsp.error_msg) {
        utils.dialog.alert('결제 실패', rsp.error_msg, 'warning');
        return;
      }
      getPaymentDetail(rsp.imp_uid, 'tossAutoPay');
    });
  };

  // portone 결제 상세내역 조회
  const getPaymentDetail = async (imp_uid, payFlag) => {
    utils.http.setMode('api');

    try {
      const res = await utils.http.post(`/v1/getPayments`, null, { imp_uid });
      const paymentInfo = res.data;

      if (!paymentInfo.success) {
        utils.dialog.alert('결제 실패', paymentInfo.message, 'warning');
        console.log('결제 실패', paymentInfo.message);
        return;
      }

      const payment = paymentInfo.data[0];

      if (!payment || payment.status === 'failed' || payment.status === 'ready') {
        utils.dialog.alert('결제 실패', payment.fail_reason, 'warning');
        return;
      }

      if (payFlag === 'tossOnePay') {
        saveOnePaymentLog(payment, TOSS_ONE_PAY_PG);
      } else {
        saveAutoPaymentLog(payment, TOSS_AUTO_PAY_PG);
      }
      utils.dialog.alert('결제 성공', '결제가 완료되었습니다.', 'success').then((res) => {
        res && close();
      });
    } catch (error) {
      console.error('결제 상세내역 조회 실패', error);
    }
  };
  const getPaymentEndDate = () => {
    const url = '/v1/SP_p_partnerNameHp_s1';
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      console.log('결제 예정일', res.data.data[0].firstPaymentEndDt);
      setPaymentEndDate(res.data.data[0].paymentDate);
      setEventEndDate(res.data.data[0].firstPaymentEndDt);
    });
  };
  useEffect(() => {
    getPartInfo();
    getTicketList();
    getPaymentEndDate();
  }, []);
  useEffect(() => {
    // 카드 check와 약관 check 초기화
    setSelectedPayment(0);
    setCheckList([]);
    setAutoPayment(true);
  }, [open]);
  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'700px'}
      height={'100vh'}
      slotFixedButton={
        <SubmitBtn style={{ height: '45px', padding: '0' }} type="button" onClick={onSubmit} disabled={selectedPayment === 0 || (autoPayment ? checkList.length < 2 : !checkList.includes('개인정보수집이용'))}>
          결제
        </SubmitBtn>
      }
    >
      <Form action="#none" style={{ gap: 0 }}>
        <div className="qna-scroll-area">
          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">선택 상품</BdWhiteText>
            {/* <Card>
              <CardRow>
                <CardLabel>선택구독권</CardLabel>
                <CardContent>
                  <TitleIcon
                    style={{
                      backgroundColor: ticket?.color,
                    }}
                  >
                    {ticket?.icon}
                  </TitleIcon>
                  <div
                    style={{
                      fontSize: isMobile ? '14px' : '22px',
                    }}
                  >
                    {ticket?.level_name}
                  </div>
                </CardContent>
              </CardRow>
              <CardRow
                style={{
                  alignItems: 'flex-start',
                }}
              >
                <CardLabelSub>
                  <SubdirectoryArrowRightIcon />
                  상세내용
                </CardLabelSub>
                <div
                  style={{
                    fontSize: isMobile ? '12px' : '18px',
                  }}
                >
                  <div>{ticket?.content1}</div>
                  <div>{ticket?.content2}</div>
                </div>
              </CardRow>
              <CardRow>
                <CardLabel>이용 기간</CardLabel>
                <CardContent>
                  <div>
                    {dayjs(ticket?.st_dt).format(`YYYY년 MM월 DD일`)} ~ {dayjs(ticket?.et_dt).format(`YYYY년 MM월 DD일`)}
                  </div>
                </CardContent>
              </CardRow>
              <CardRow>
                <CardLabel>결제 금액</CardLabel>
                <CardContent
                  style={{
                    flexDirection: isMobile ? 'column' : 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        fontSize: isMobile ? '14px' : '22px',
                        fontWeight: 'bold',
                        color: 'var(--mint-color)',
                      }}
                    >
                      {partInfo?.partnerFirstTicket === 'N' ? <>{ticket?.sale_price?.toLocaleString()}원</> : <>{ticket?.first_sale_price?.toLocaleString()}원</>}
                    </div>
                    <div>(한달 기준)</div>
                  </div>
                  {partInfo?.partLevel > 1 && (
                    <div
                      style={{
                        fontSize: isMobile ? '10px' : '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      [결제예정일 : {dayjs(ticket?.st_dt).format(`YYYY년 MM월 DD일`)}]
                    </div>
                  )}
                </CardContent>
              </CardRow>
              <CardRow>
                <CardLabel>정기 결제 유무</CardLabel>
                <CardContent>
                  <SingleCheckBox
                    name="정기결제"
                    checked={!!autoPayment}
                    onChangeHandle={(e) => {
                      setAutoPayment(e.target.checked ? 1 : 0);
                    }}
                    text="(체크 시, 매 월 자동 결제 됩니다.)"
                  />
                </CardContent>
              </CardRow>
            </Card> */}
            <CustomTicket ticket={ticket} partInfo={partInfo} disabled />
          </div>
          <CustomDivider />
          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">구독 정보</BdWhiteText>
            <WhiteBox>
              <SpaceBetweenBox>
                <FormControlLabel
                  control={<Checkbox onChange={(e) => setAutoPayment(e.target.checked)} checked={autoPayment} size="large" />}
                  label="정기구독"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 'bold',
                      color: '#10141c'
                    }
                  }}
                />
                <SubTitle>매 월 자동 결제</SubTitle>
              </SpaceBetweenBox>
            </WhiteBox>
            <SubTitle>
              <div>※ 정기구독 체크 해지 시, 첫 달 구독료만 결제되며 기간 만료 시 달인 활동에 대한 서비스가 제한됩 니다.</div>
            </SubTitle>
          </div>
          <CustomDivider />
          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">결제 수단</BdWhiteText>
            <PaymentSelect selectedPayment={selectedPayment} setSelectedPayment={setSelectedPayment} />
          </div>
          <CustomDivider />
          <div className="scrollbar-innerContent">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <BdWhiteText className="input-title">결제 금액</BdWhiteText>
              <Box
                sx={{
                  color: 'var(--primary-color)',
                  fontSize: '22px',
                  fontWeight: 'bold'
                }}
              >
                {partInfo?.partnerFirstTicket === 'N' ? ticket?.sale_price?.toLocaleString() : ticket?.first_sale_price?.toLocaleString()}원
                {autoPayment && (
                  <span
                    style={{
                      fontSize: '16px'
                    }}
                  >
                    /개월
                  </span>
                )}
              </Box>
            </Box>
            <WhiteBox>
              {partInfo.partnerFirstTicket === 'Y' && autoPayment ? (
                <Box
                  sx={{
                    fontSize: '18px'
                  }}
                >
                  {autoPayment && (
                    <SpaceBetweenBox>
                      <GrayLabel>다음 결제 예정일</GrayLabel>
                      <Box>{paymentEndDate}</Box>
                    </SpaceBetweenBox>
                  )}
                  <SpaceBetweenBox>
                    <GrayLabel>결제 금액</GrayLabel>
                    <Box>
                      {partInfo?.partnerFirstTicket === 'N' ? ticket?.sale_price?.toLocaleString() : ticket?.first_sale_price?.toLocaleString()}원<GrayLabel>(VAT포함)</GrayLabel>
                    </Box>
                  </SpaceBetweenBox>
                  <Divider
                    sx={{
                      width: '100%',
                      backgroundColor: '#999999',
                      margin: '14px 0'
                    }}
                  />
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0
                      },
                      '& .MuiTimelineDot-root': {
                        borderStyle: 'none',
                        margin: '10px 0'
                      },
                      '& .MuiTypography-root': {
                        padding: '0 8px',
                        height: '30px'
                      },
                      '& .MuiTimelineItem-root': {
                        minHeight: '30px'
                      },
                      '& .MuiTimelineConnector-root': {
                        height: '20px'
                      },
                      padding: '0px 16px'
                    }}
                  >
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color="primary" />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: '18px'
                        }}
                      >
                        <Box>시작일 : {today.format('YYYY년 MM월 DD일')}</Box>
                        <Box>{ticket?.first_sale_price?.toLocaleString()}/개월</Box>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color="primary" />
                      </TimelineSeparator>
                      <TimelineContent
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: '18px'
                        }}
                      >
                        <Box>시작일 : {eventEndDate}</Box>
                        <Box>{ticket?.sale_price?.toLocaleString()}/개월</Box>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </Box>
              ) : (
                <Box
                  sx={{
                    fontSize: '18px'
                  }}
                >
                  <SpaceBetweenBox>
                    <GrayLabel>이용 기간</GrayLabel>
                    <Box>
                      {dayjs(ticket?.st_dt).format(`YYYY년 MM월 DD일`)} ~ {autoPayment ? '해지 시' : paymentEndDate}
                    </Box>
                  </SpaceBetweenBox>
                  {autoPayment && (
                    <SpaceBetweenBox>
                      <GrayLabel>다음 결제 예정일</GrayLabel>
                      <Box>{paymentEndDate}</Box>
                    </SpaceBetweenBox>
                  )}
                  <SpaceBetweenBox>
                    <GrayLabel>결제 금액</GrayLabel>
                    <Box>
                      {partInfo?.partnerFirstTicket === 'N' ? ticket?.sale_price?.toLocaleString() : ticket?.first_sale_price?.toLocaleString()}원<GrayLabel>(VAT포함)</GrayLabel>
                    </Box>
                  </SpaceBetweenBox>
                </Box>
              )}
            </WhiteBox>
            {partInfo.partnerFirstTicket === 'Y' && autoPayment && (
              <SubTitle>
                - 3개월 뒤에는 기존 할인가 ￦{ticket?.sale_price?.toLocaleString()}의 요금이 매월 자동으로 청구됩니다.(이벤트 종료일까지)
                <br />
                - 해당 구독권은 정기 구독 시, 할인가로 적용 가능합니다.
                <br />- [구독권] &gt; [구독내역]에서 정기 구독을 언제든지 취소할 수 있습니다.
              </SubTitle>
            )}
          </div>
          <CustomDivider />
          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">구매 동의</BdWhiteText>
            <Card>
              <div className="qna-checkBox">
                <div style={{ marginBottom: '8px' }}>
                  <CheckBox text={termsAndConsitions[1]} name="개인정보수집이용" showBtn={true} btnText="[보기]" modalOpen={() => set개인정보수집이용(true)} changeHandle={(e) => onCheckedElement(e)} checkList={checkList} between />
                  {!!autoPayment && <CheckBox text={termsAndConsitions[2]} name="정기결제서비스약관" showBtn={true} btnText="[보기]" modalOpen={() => set정기결제서비스약관(true)} changeHandle={(e) => onCheckedElement(e)} checkList={checkList} between />}
                </div>
              </div>
            </Card>
          </div>
        </div>
      </Form>
      <Modal open={개인정보수집이용} close={() => set개인정보수집이용(false)} header="개인정보 수집 이용" gubn={parseInt('13')} />
      <Modal open={정기결제서비스약관} close={() => set정기결제서비스약관(false)} header="정기 결제 서비스 이용 약관 동의" gubn={parseInt('8')} />
    </WindowFrame>
  );
};

export default TicketPaymentModal;

import WindowFrame from './WindowFrame';
import React, { useEffect, useState } from 'react';
import { Form, SubmitBtn, TextArea } from './ModalElements';
import { TitleText } from 'style';
import utils from 'modules/utils';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import FavoritePhraseModal from './FavoritePhraseModal';

const QNA_CODE = '20';

const CancleReservationModal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, title, modalInfo, refresh } = props;
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  const [radioList, setRadioList] = useState([
    { label: '예약하신 시간에 수리/시공이 어려움', value: 0 },
    { label: '부품 재고 없음', value: 1 },
    { label: '직접 입력', value: 2 },
  ]);

  const [value, setValue] = useState(0);
  const [customText, setCustomText] = useState(radioList[0].label);
  const [favoriteOpen, setFavoriteOpen] = useState(false);

  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
    if (event.target.value === '2') {
      setCustomText('');
    } else {
      radioList.map((item) => {
        if (item.value === parseInt(event.target.value)) {
          setCustomText(item.label);
        }
      });
    }
  };

  /*
  workDate: 채팅방일자키
  sqenNumb: 채팅방순번키
  reserWorkDate,reserSqenNumb:스케쥴키
  reserGubn: 0:예약, 1:취소
  reser_addr주소
  reserCancelReason 일정,취소사유 쭉 ~~
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('customText', customText);
    if (utils.isEmpty(customText)) {
      utils.dialog.alert('취소사유를 입력해 주세요.', '', 'warning');
    }
    console.log('onSubmit', modalInfo);
    const url = `/v2/SP_r_ChatReservation_I1`;
    const dataObj = {
      workDate: modalInfo.chatWorkDate,
      sqenNumb: modalInfo.chatSqenNumb,
      reserWorkDate: modalInfo.work_date,
      reserSqenNumb: modalInfo.sqen_numb,
      reserGubn: 1,
      reserAddr: modalInfo.reser_addr,
      reserCancelReason: customText,
    };
    utils.http.setMode('api');
    utils.http.post(url, null, dataObj).then((res) => {
      if (res.data.success) {
        utils.dialog.alert('예약이 취소되었습니다.', '', 'success');
        refresh();
        close();
      } else {
        utils.dialog.alert('예약취소에 실패하였습니다.', res.data.message, 'warning');
      }
    });
  };

  // 공통코드 조회 옵션가져오기
  const getCommonCode = async () => {
    const url = `/v1/SP_b_Common_Code_SI/${QNA_CODE}`;
    utils.http.setMode('api');
  };

  const favoriteModalClose = () => {
    setFavoriteOpen(false);
  };

  useEffect(() => {
    getCommonCode();
  }, []);

  useEffect(() => {}, [open]);

  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'500px'}
      height={value === 2 ? '720px' : '500px'}
      slotFixedButton={
        <SubmitBtn style={{ height: '45px', padding: '0' }} type="button" onClick={onSubmit}>
          예약취소
        </SubmitBtn>
      }
    >
      <Form style={{ gap: 0 }}>
        <TitleText style={{ fontWeight: 'normal' }}>
          정말 <strong style={{ color: 'var(--mint-color)' }}>예약</strong>을 취소하시겠어요?
        </TitleText>
        <div className="qna-scroll-area" style={{ height: 'auto' }}>
          <div>취소사유</div>
          <div>고객에게 전달할 사유를 선택해 주세요.</div>

          <FormControl>
            <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
              {radioList.map((item, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={
                      <Radio
                        sx={{
                          color: '#fff',
                          '&.Mui-checked': {
                            color: '#7e6eff',
                          },
                        }}
                      />
                    }
                    label={item.label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          {value === 2 && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <button
                style={{
                  color: 'var(--mint-color)',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
                type="button"
                onClick={() => setFavoriteOpen(true)}
              >
                자주 쓰는 문구
              </button>
              <TextArea
                placeholder={`취소사유를 직접 입력해 주세요.`}
                style={{
                  height: '188px',
                  fontSize: isTablet ? '16px' : '18px',
                  padding: '15px 14px',
                  borderRadius: '5px',
                }}
                value={customText}
                onChange={(e) => setCustomText(e.target.value)}
              />
            </div>
          )}
        </div>
      </Form>
      <FavoritePhraseModal open={favoriteOpen} close={favoriteModalClose} title={'자주 쓰는 문구'} setCustomText={setCustomText} />
    </WindowFrame>
  );
};

export default CancleReservationModal;

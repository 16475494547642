import React, { useState, useEffect, useRef } from 'react';
import { DropDown, Menu, Profile, ProfileSubmenu, SubmenuList } from './DropDownElements';
import { useNavigate } from 'react-router-dom';
import { profileDropDown, profileDropDownMobile } from 'Data/data';
import QnAModal from 'components/Parts/Common/Modal/QnAModal';
import Icon from 'components/Parts/Common/Icon';
import ProfileImage from 'components/Parts/Common/ProfileImage';
import utils from 'modules/utils';
import { useMediaQuery } from 'react-responsive';
import { partInfoStore } from 'modules/store/store';

const ProfileDropDown = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const [profileDropDownArr, setProfileDropDownArr] = useState([]);

  const [isToggle, setIsToggle] = useState(false);
  const [showDropdown, setShowDropdown] = useState('none');
  const [openQnA, setOpenQnA] = useState(false);

  const { partInfo } = partInfoStore();
  const profileRef = useRef();
  const handleClickOutside = (e) => {
    if (profileRef && !profileRef.current.contains(e.target)) {
      setIsToggle(false);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    if (isToggle) {
      setShowDropdown('block');
    } else {
      setShowDropdown('none');
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isToggle]);

  useEffect(() => {
    isMobile ? setProfileDropDownArr(profileDropDownMobile) : setProfileDropDownArr(profileDropDown);
  }, [isMobile]);

  return (
    <DropDown ref={profileRef}>
      <Profile onClick={() => setIsToggle(!isToggle)}>
        <ProfileImage kind={'partner'} htmlId={'topProfileImage'} id={utils.user('id')} width={isTablet ? '32px' : '55px'} height={isTablet ? '32px' : '55px'} alwaysRefresh={true} partProfile={partInfo.partProfile}></ProfileImage>
      </Profile>
      <ProfileSubmenu display={showDropdown}>
        {profileDropDownArr.map((item, index) => (
          <SubmenuList
            key={item.title}
            onClick={() => {
              switch (item.action) {
                //달인정보수정
                case 'infoPartner':
                  setIsToggle(!isToggle);
                  navigate('/manage/info/partnerInfo');
                  break;
                //개인정보수정
                case 'infoPrivate':
                  setIsToggle(!isToggle);
                  navigate('/manage/info/privacy/modifyPrivacy');
                  break;
                //환경설정
                case 'ticket':
                  setIsToggle(!isToggle);
                  navigate('/manage/ticket/ticketList');
                  break;
                //1:1문의
                case 'qna':
                  setIsToggle(!isToggle);
                  setOpenQnA(true);
                  break;
                case 'calendar':
                  setIsToggle(!isToggle);
                  navigate('/manage/calendar');
                  break;
                //로그아웃
                case 'logout':
                  setIsToggle(!isToggle);
                  utils.user.requestLogout();
                  break;
                default:
                  break;
              }
            }}
          >
            <item.icon className="profile-submenu" />
            <Menu>{item.title}</Menu>
          </SubmenuList>
        ))}
      </ProfileSubmenu>
      <QnAModal
        open={openQnA}
        close={() => {
          setOpenQnA(false);
        }}
        title="1:1 문의하기"
      />
    </DropDown>
  );
};

export default ProfileDropDown;

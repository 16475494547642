import styled from 'styled-components';
import PreviewImage from '../../../Parts/Common/PreviewImage';
import { ContentText, ContentWrap, Item, PreviewImg, SentDate, Subject, SubmitBtn } from './EmergencyListElements';

const RightSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  color: #fff;
`;

const FooterText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ddd;
  font-size: 14px;
  line-height: 18px;
`;

const ReceivedEmergencyList = ({ emergencyView, setEmergencyView, emergencyList, openEmergencyModal, lat, lon }) => {
  return (
    <>
      {emergencyList.map((item, index) => (
        <Item
          key={`emergency-${item.sqenNumb}${item.workDate}`}
          onClick={() => {
            setEmergencyView(item);
          }}
          className={emergencyView.workDate === item.workDate && emergencyView.sqenNumb === item.sqenNumb && 'selected'}
        >
          <PreviewImg>
            <PreviewImage width={'80px'} height={'80px'} image={item.fileName} shape={'rect'} />
          </PreviewImg>
          <ContentWrap>
            <Subject>{item.emerSubject}</Subject>
            <ContentText>{item.emerContent}</ContentText>
            <FooterText>
              <SentDate
                style={{
                  width: 'inherit',
                }}
              >
                {item.ftDt}
              </SentDate>
              <>
                •<div>{lat !== '0.0' && lon !== '0.0' ? item?.distance : '-'}km</div>
              </>
            </FooterText>
          </ContentWrap>
          <RightSection>
            <SubmitBtn type="button" onClick={openEmergencyModal}>
              출동
            </SubmitBtn>
          </RightSection>
        </Item>
      ))}
    </>
  );
};

export default ReceivedEmergencyList;

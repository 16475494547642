import ModalComponent from 'components/Parts/Common/Modal/ModalComponent';
import { ViewDetail, NoticeTitle, ContentImg, NoneContentText, ContentText } from 'components/Parts/Table/TableElements';
import utils from 'modules/utils';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { BdWhiteText, NormalWhiteSpan } from 'style';
import styled from 'styled-components';

const EventContent = styled.section`
  width: 100%;
  border-radius: 10px;
  height: 100%;
  background: white;
  overflow-y: hidden !important;
  padding: 10px 22px 10px 30px;
  & .eventFrame {
    width: 100%;
    height: 100%;
    border: none;
    overflow-y: hidden !important;
  }
`;

const EventDetail = ({ clickedItem }) => {
  const [webImage, setWebImage] = useState([]);
  const getImageUrl = (clickedItem) => {
    if (!clickedItem) return;
    const url = `/v2/SP_a_bannerV2_S2/${clickedItem.workDate}/${clickedItem.sqenNumb}`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      console.log('결과', res.data.data[0]);
      setWebImage(res.data.data);
    });
  };
  const imageAddr = process.env.REACT_APP_IMAGE_VIEW_URL;
  const [open, setOpen] = React.useState(false);
  const CountFile = (obj) => {
    let imgArr = [];
    let files = [];
    files = Object.keys(obj).filter((key) => key.includes('file'));
    for (let i = 1; i <= files.length; i++) {
      imgArr.push(<ContentImg src={`${imageAddr}${obj['file' + i]}`} alt="" key={`file-${obj['file' + i]}`} />);
    }

    return imgArr;
  };

  useEffect(() => {
    console.log('eventDetail', clickedItem);
    // getImageUrl(clickedItem);
  }, [clickedItem]);

  return (
    <>
      {clickedItem !== undefined && clickedItem !== null ? (
        <ViewDetail>
          <NoticeTitle>
            <BdWhiteText>{clickedItem.eventName}</BdWhiteText>
            <NormalWhiteSpan>{`${clickedItem.startDt} ~ ${clickedItem.endDt}`}</NormalWhiteSpan>
          </NoticeTitle>
          <Scrollbars
            className="scrollbar-container"
            style={{
              width: '100%',
              height: 'calc(100% - 62px)',
              borderRadius: '10px',
              background: '#fff'
            }}
            renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
          >
            <EventContent>
              <iframe class="eventFrame" src={'https://sooudal.com/event/' + clickedItem.bann_url}></iframe>
            </EventContent>
          </Scrollbars>
        </ViewDetail>
      ) : (
        <ViewDetail>
          <NoneContentText>이벤트를 선택하여 내용을 확인해 주세요.</NoneContentText>
        </ViewDetail>
      )}
      <ModalComponent open={open} setOpen={setOpen} width={'500px'}>
        {clickedItem ? webImage.map((item) => <ContentImg onClick={() => setOpen(true)} src={`${imageAddr}${item.bann_url}`} alt="" key={`file-${item.bann_url}`} />) : <div />}
      </ModalComponent>
    </>
  );
};

export default EventDetail;

import styled from 'styled-components';

export const BusinessContent = styled.div`
  width: calc(100% - 34.5% - 30px);
  height: 100%;
  background-color: #37465b;
  border-radius: 20px;
  padding: 29px 30px;
  transition: all 0.3s ease;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 20% - 20px);
  }
`;

export const SettlementContent = styled.div`
  width: calc(100% - 34.5% - 30px);
  height: 460px;
  background-color: #37465b;
  border-radius: 20px;
  padding: 29px 30px;
  transition: all 0.3s ease;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 20% - 20px);
  }
`;

import { IconButton, InputAdornment, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/system';
import React from 'react';

/**
 * 모달 컴포넌트
 * @param open 모달 열림 여부
 * @param setOpen 모달 열림 여부 변경 함수
 * @param title 모달 타이틀
 * @param children 모달 내용
 * @param width 모달 너비
 */
export default function ModalComponent({ open, setOpen, title, children, width, ModalFooter }) {
  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width ?? '70%',
    maxHeight: '87vh',
    bgcolor: '#212838',
    color: '#fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    // focus 없애기
    outline: 'none'
  };
  console.log('ModalComponent', open);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          color="error"
          sx={{
            position: 'absolute',
            top: '0',
            right: '0'
          }}
        >
          <Close />
        </IconButton>
        {title && (
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              //  타이틀이 있을 때 아래에 연한 색으로 밑줄 긋기 위아래 margin 추가
              '&:after': {
                content: '""',
                display: 'block',
                width: '100%',
                height: '1px',
                backgroundColor: 'var(--mint-color)',
                marginBottom: '20px',
                overflowY: 'hidden'
              },
              fontWeight: 'bold'
            }}
          >
            {title}
          </Typography>
        )}
        <Box
          className="scrollbar-container2"
          sx={{
            height: '100%',
            maxHeight: `calc(87vh - ${ModalFooter ? '140px' : '100px'})`,
            overflowY: 'auto'
          }}
        >
          {children}
        </Box>
        {ModalFooter && (
          <Box
            sx={{
              display: 'flex',
              color: '#fff',
              width: '100%'
            }}
          >
            {ModalFooter}
          </Box>
        )}
      </Box>
    </Modal>
  );
}

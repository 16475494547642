import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Notice from './Notice/Notice';
import Faq from './Faq/Faq';
import QnA from './QnA/QnA';
import Event from './Event/Event';
import { Base } from '../FrameElements';
import QuestionList from './QuestionList/Results';
import TotalFaq from './TotalFaq/TotalFaq';

const News = () => {
  return (
    <Base>
      <Routes>
        <Route path="notice" element={<Notice />} />
        <Route path="notice/:workDate/:sqenNumb" element={<Notice />} />
        <Route path="faqList/:kind" element={<TotalFaq />} />
        <Route path="faqList/:kind/:workDate/:sqenNumb" element={<TotalFaq />} />
        <Route path="event" element={<Event />} />
        <Route path="event/:workDate/:sqenNumb" element={<Event />} />
        <Route path="questionList/:kind" element={<QuestionList />} />
        <Route path="questionList/:kind/:workDate/:sqenNumb" element={<QuestionList />} />
      </Routes>
    </Base>
  );
};

export default News;

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { menuStore } from 'modules/store/store';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import logo from '../../../../assets/뉴수달로고.png';
import AlertDropDown from '../DropDown/AlertDropDown';
import ProfileDropDown from '../DropDown/ProfileDropDown';
import { alarmStore } from './../../../../modules/store/store';
import PartnerLevel from './PartnerLevel';
import { LeftSection, Navbar, RightSection } from './TopNavElements';

const TopNav = () => {
  const isSmallPC = useMediaQuery({ query: '(max-width: 1440px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const { setMenuOpen } = menuStore();
  // 알림 조회
  const { getAlarms } = alarmStore();

  const navigate = useNavigate();

  useEffect(() => {
    getAlarms();
    setInterval(getAlarms, 60 * 1000);
  }, []);

  return (
    <Navbar>
      <LeftSection
        onClick={() => {
          setMenuOpen(false);
        }}
      >
        <img
          src={logo}
          alt=""
          width={isMobile ? '80' : '140'}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/manage/home');
          }}
        />
      </LeftSection>

      <RightSection>
        <PartnerLevel />
        {!isMobile && <AlertDropDown getAlarms={getAlarms} />}
        <ProfileDropDown />
        {isSmallPC && (
          <>
            <MenuRoundedIcon
              sx={{
                color: 'white',
                fontSize: '38px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setMenuOpen(true);
              }}
            />
          </>
        )}
      </RightSection>
    </Navbar>
  );
};

export default TopNav;

import React, { useState, useEffect } from 'react';
import Lists from '../../../Parts/Common/TabLists/Lists';
import DetailContent2 from '../../../Parts/Common/TabLists/DetailContent2';
import SearchInput from '../../../Parts/Common/SearchInput/SearchInput';
import utils from '../../../../modules/utils';
import { Container } from '../../../Parts/Common/TabLists/ViewListsElements';
import { comumnicationInfo } from '../../../../Data/data';
import BigInfoModal from '../../../Parts/Common/MenuInfo/BiginfoModal';
import styled from 'styled-components';
import QuestionContent from './QuestionContent';
import { useNavigate, useParams } from 'react-router-dom';
import QuestionList from './QuestionList';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const ContainerWrap = styled(Container)`
  padding-top: 20px;
  height: calc(100% - 30px);
  .list-wrap {
    width: 43%;
  }
  .detail-content-wrap {
    width: 57%;
  }
`;

const DropDownBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  top: 10;
  right: 0;
  z-index: 10;
  background-color: #10141c;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
`;

export const WriteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 4px 10px;
  background-color: var(--mint-color);
  width: fit-content;
  font-size: 20px;
  font-weight: bold;
  border-radius: 4px;

  svg {
    font-size: 26px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;

    span {
      font-size: 20px;
    }
  }
`;

const OrderByBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  gap: 10px;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    svg {
      font-size: 18px;
    }
  }
`;

const Result = () => {
  const navigate = useNavigate();
  const { workDate, sqenNumb } = useParams();
  const [openDropdown, setOpenDropdown] = useState(false);

  const modalStyle = {
    width: '659px',
    height: '100px',
  };

  // 버튼 state
  const [questionList, setQuestionList] = useState([]);
  const [questionView, setQuestionView] = useState({});
  const [searchWord, setSearchWord] = useState('');

  const [orderByList, setOrderByList] = useState([]); // ['최신순', '조회순', '댓글순', '좋아요순']
  const [selectedOrderBy, setSelectedOrderBy] = useState('0');

  const getOrderByList = () => {
    const url = `/v1/SP_b_Common_Code_SI/22`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data && res.data.data) {
          console.log('정렬순서 : ', res.data.data);
          setOrderByList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateQuestionList = (searchWord) => {
    const url = `/v1/SP_c_question_S1`;
    utils.http.setMode('api');
    utils.http
      .post(url, null, { value: searchWord, orderBy: selectedOrderBy })
      .then((res) => {
        if (res.data && res.data.data) {
          setQuestionList(res.data.data);
          updateView(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateView = function (list) {
    if (workDate && sqenNumb) {
      let item = list.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
      console.log('item : ', item);

      if (item.length > 0) setQuestionView(item[0]);
      else setQuestionView({});
    } else {
      setQuestionView({});
    }
  };
  const updateList = function (searchWord) {
    updateQuestionList(searchWord);
  };

  const refreshList = function () {
    updateList(searchWord);
  };
  useEffect(() => {
    getOrderByList();
  }, []);

  useEffect(() => {
    setSearchWord('');
    updateList('');
  }, [selectedOrderBy]);

  useEffect(() => {
    updateView(questionList);
  }, [workDate, sqenNumb]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <BigInfoModal infoModalData={comumnicationInfo} menu="질문해요" style={modalStyle} />
      </div>
      <ContainerWrap>
        <Lists
          noSearch={questionList.length === 0}
          noSearchMessage={searchWord ? `검색된 질문해요 목록이 없습니다.` : `새로운 질문해요 목록이 없습니다.`}
          slotFilter={
            <>
              <SearchInput
                inputVal={searchWord}
                setInputVal={setSearchWord}
                onSearch={() => {
                  updateList(searchWord);
                }}
              />
              <div
                style={{
                  position: 'relative',
                  marginBottom: '14px',
                }}
              >
                <OrderByBtn
                  onClick={() => {
                    setOpenDropdown(!openDropdown);
                  }}
                >
                  <EditNoteOutlinedIcon />
                  <div>
                    {/* orderbylist에서 cd와 selectedorderby와 같은 nm */}
                    {orderByList.length > 0 && orderByList.filter((x) => x.cd === selectedOrderBy)[0].nm}
                  </div>
                </OrderByBtn>
                {openDropdown && (
                  <DropDownBox>
                    {orderByList.map((item, index) => (
                      <div
                        key={item.cd}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#fff',
                          gap: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSelectedOrderBy(item.cd);
                          setOpenDropdown(!openDropdown);
                        }}
                      >
                        {item.nm}
                      </div>
                    ))}
                  </DropDownBox>
                )}
              </div>
            </>
          }
          slotList={
            <QuestionList
              questionView={questionView}
              setQuestionView={(questionView) => {
                navigate(`/manage/news/questionList/question/${questionView.workDate}/${questionView.sqenNumb}`);
              }}
              questionList={questionList}
            />
          }
        />
        <DetailContent2 title={'견적 내용'} questionView={questionView} scrollBar={true} refreshList={refreshList} slotContent={<QuestionContent questionView={questionView} refreshList={refreshList} />} />
      </ContainerWrap>
    </>
  );
};

export default Result;

import utils from '../utils';
import crypto from '../crypto';

const IMP = window.IMP;
IMP.init('imp22166141');
let portOneCore = {
  verifyDanal(nextSuccess, nextFail) {
    IMP.certification(
      {
        // param
        pg: 'danal.B010008177',
        merchant_uid: 'soodal_danal_' + new Date().getTime(),
      },
      function (rsp) {
        // callback
        if (rsp.success) {
          utils.isFunction(nextSuccess) && nextSuccess(rsp);
        } else {
          utils.isFunction(nextFail) && nextFail(rsp);
        }
      }
    );
  },
  verifyInicis(nextSuccess, nextFail) {
    IMP.certification(
      {
        // param
        pg: 'inicis_unified.MIIsoodal1',
        merchant_uid: 'soodal_inicis_' + new Date().getTime(),
      },
      function (rsp) {
        // callback
        if (rsp.success) {
          utils.isFunction(nextSuccess) && nextSuccess(rsp);
        } else {
          utils.isFunction(nextFail) && nextFail(rsp);
        }
      }
    );
  },

  async certInfo(imp_uid) {
    try {
      utils.http.setMode('api');
      return (await utils.http.post('/v1/getIamportToken', null, { imp_uid })).data.data[0];
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  setCertInfo(certInfo) {
    delete certInfo['unique_in_site'];
    delete certInfo['unique_key'];

    utils.storage.local('certInfo', crypto.encryptObject(certInfo));
  },
  getCertInfo() {
    try {
      return crypto.decryptObject(utils.storage.local('certInfo'));
    } catch (e) {
      return {};
    }
  },
  delCertInfo() {
    utils.storage.local('certInfo', null);
  },
};
Object.freeze(portOneCore);
export default portOneCore;

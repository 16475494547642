import React from 'react';
import { Btn, ArrowIcon } from './SelectBtnElements';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
const SelectBtn = ({ size, menu, select, setSelect, idx }) => {
  return (
    <Btn
      onClick={() => {
        setSelect(idx);
      }}
      className={select === idx && 'selected'}
      style={
        size === 'big'
          ? {}
          : {
              height: '56px',
              padding: '16px 20px',
              borderRadius: '10px',
              backgroundColor: '#212838',
            }
      }
    >
      <span style={size === 'big' ? { fontSize: '24px', fontWeight: 'bold' } : { fontSize: '22px' }}>{menu}</span>
      <ArrowIcon style={size === 'small' ? { width: '15px', height: '15px' } : {}}>
        <ArrowForwardIosRoundedIcon sx={size === 'small' ? { fontSize: '15px' } : {}} />
      </ArrowIcon>
    </Btn>
  );
};

export default SelectBtn;

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import utils from 'modules/utils';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { alarmStore } from './../../../modules/store/store';

const PushBox = styled.section`
  position: fixed;
  bottom: 50px;
  right: 29px;
  width: 500px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .push-box {
    height: 140px;
    user-select: none;

    background-color: rgba(16, 20, 28, 0.9);
    padding: 20px;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 20px;

    .profile-image {
      width: 70px;
      height: 70px;
      margin-right: 22px;
      flex: 0 0 70px;
    }
    .push-message {
      flex: 1 1 100%;
      overflow: hidden;
      cursor: pointer;
      .title {
        font-weight: bold;
        margin-bottom: 10px;
      }
      .title,
      .message {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        line-height: 1;
        color: #fff;
        font-size: 20px;
      }
    }
    .push-close {
      position: absolute;
      top: 27px;
      right: 27px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      background-color: transparent;
      svg {
        font-size: 20px;
        color: #fff;
      }
    }
  }
`;
const WebPush = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getAlarms } = alarmStore();

  utils.push.token().then((token) => {
    utils.http.get(`/v1/SP_a_AgetMyWebToken/${utils.user('id')}`).then(({ data }) => {
      if (data.data[0]?.user_web_fcm_token !== token) {
        utils.user.setPushToken(token);
      }
    });
    utils.push.onMessage(async (payload) => {
      console.log('payload@@@@@@@@@@@@@@@@@@@@@@@@@@@@', payload);
      let pushView = payload?.data && !(location.pathname === `/manage/history/chat/${payload.data.workDate}/${payload.data.sqenNumb}` || location.pathname === `/manage/intern/intern/${payload.data.workDate}/${payload.data.sqenNumb}`);
      getAlarms();
      if (pushView) {
        let chatKind = '';
        if (payload.data.dir === 'SearchChat') {
          utils.http.setMode('api');
          const res = await utils.http.get(`/v1/SP_r_ChatCheck_s1/${payload.data.workDate}/${payload.data.sqenNumb}`);
          if (res.data.data && res.data.data.length > 0) {
            if (res.data.data[0].user_Auth === '0') {
              chatKind = 'chat';
              utils.event.emit('pushChat', payload.data.workDate, payload.data.sqenNumb, payload.data.message);
            } else if (res.data.data[0].user_Auth === '1') {
              chatKind = 'intern';
              utils.event.emit('pushInternChat', payload.data.workDate, payload.data.sqenNumb, payload.data.message);
            }
            //채팅 카운팅 이벤트 발생
            utils.event.emit('loadChatCount', chatKind);
          }
        }

        const pushId = utils.unique();
        const keepTime = 6 * 1000;
        payload.data.pushId = pushId;
        payload.data.pushClose = () => {
          setPushList((pushList) => pushList.filter((x) => x.pushId !== pushId));
          clearTimeout(payload.data.keepTimer);
        };
        payload.data.pushClick = () => {
          console.log('payload.data', payload.data);
          switch (payload.data.dir) {
            case 'notice': //공지사항
              navigate(`/manage/news/notice/${payload.data.workDate}/${payload.data.sqenNumb}`);
              break;
            case 'qna': //문의사항
              navigate('/manage/news/faqList/qna');
              break;
            case 'SearchChat': //채팅
              if (chatKind === 'chat') {
                navigate(`/manage/history/${chatKind}/${payload.data.workDate}/${payload.data.sqenNumb}`);
              } else if (chatKind === 'intern') {
                navigate(`/manage/intern/${chatKind}/${payload.data.workDate}/${payload.data.sqenNumb}`);
              } else {
                utils.dialog.alert('채팅 알림 오류', '채팅 알림에 문제가 발생하였습니다.<br>관리자에게 문의하세요.', 'warning');
              }
              break;
            case 'requestView': //요청목록 견적받기
              navigate(`/manage/history/estimateList/received/${payload.data.workDate}/${payload.data.sqenNumb}`);
              break;
            case 'emergencyView': //요청목록 긴급출동
              navigate(`/manage/history/emergencyList/received/${payload.data.workDate}/${payload.data.sqenNumb}`);
              break;
            case 'questionItem': //소통해요 질문해요
              navigate(`/manage/news/questionList/question/${payload.data.workDate}/${payload.data.sqenNumb}`);
              break;
            case 'JobPosting': //인턴관리 구인내역
              navigate(`/manage/intern/jobPosting/${payload.data.workDate}/${payload.data.sqenNumb}`);
              break;
            case 'internPostingRevice': //인턴관리 구인내역 수정
              navigate(`/manage/intern/jobPosting/${payload.data.workDate}/${payload.data.sqenNumb}`);
              break;
            default:
              console.log(`이동 페이지 없음 ${payload.data.dir}`);
              break;
          }
          payload.data.pushClose();
        };
        payload.data.keepTimer = setTimeout(() => {
          payload.data.pushClose();
        }, keepTime);

        setPushList((pushList) => pushList.concat([payload.data]));
      }
    });
  });
  const [pushList, setPushList] = useState([]);
  return (
    <PushBox>
      {pushList.map((push, idx) => (
        <div className={'push-box'} key={push.pushId}>
          {/* <ProfileImage width={'70px'} height={'70px'} kind={'user'} id={push.userId} /> */}
          <div
            style={{
              minWidth: '60px',
              height: '60px',
              backgroundColor: '#7f6eff',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <NotificationsActiveIcon
              className="push-alert__bell"
              sx={{
                fontSize: '40px',
                color: '#fff',
              }}
            />
          </div>
          <div className={'push-message'} onClick={push.pushClick}>
            <div className={'title'}>{push.title}</div>
            <div className={'message'}>{push.message}</div>
          </div>
          <button className={'push-close'} type={'button'} onClick={push.pushClose}>
            <CloseRoundedIcon />
          </button>
        </div>
      ))}
    </PushBox>
  );
};

export default WebPush;

import styled from 'styled-components';

export const Div = styled.div`
  width: 100%;
  height: calc(100% - 85px);
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  //1024px
  @media screen and (max-width: 1024px) {
    margin-top: 10px;
    gap: 20px;
  }
`;

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

export const SubTitleText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ccc;
`;

export const ProfileBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  gap: 30px;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ProfileImg = styled.div`
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
  }
`;

export const EditText = styled.span`
  width: 138px;
  height: 45px;
  color: #fff;
  padding-top: 2px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: -3px;
  left: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
`;

export const InfoBox = styled.div`
  border: solid 1px #8a8a8a;
  background-color: #212838;
  width: calc(100% - 151px);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  span {
    font-size: 22px;
  }

  .info-list {
    width: 100%;
    display: flex;

    .info-title-text {
      width: 15%;
      font-size: 20px;
      font-weight: normal;
      color: #fff;
    }
    .info-data-text {
      width: 85%;
      color: #8a8a8a;
      font-size: 20px;
      font-weight: normal;
    }
  }

  //1024px
  @media screen and (max-width: 1024px) {
    height: auto;
    padding: 10px 20px;
    width: 100%;
    gap: 20px;
    span {
      font-size: 16px;
    }
    .info-list {
      .info-title-text {
        font-size: 16px;
      }
      .info-data-text {
        font-size: 16px;
      }
    }
  }
`;

export const IntroduceBox = styled.div`
  width: 100%;
  height: calc(100% - 220px);
  position: relative;
`;

export const Introduce = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  font-size: 20px;
  font-weight: normal;
  padding: 20px;

  @media screen and (max-width: 1024px) {
    margin-top: 10px;
    padding: 20px;
  }
`;

export const IntroduceLength = styled.div`
  position: absolute;
  bottom: -40px;
  right: 36px;
  font-size: 18px;
  color: #8a8a8a;
`;

export const IntroduceContent = styled.textarea`
  width: 100%;
  height: 100%;
  color: #10141c;
  font-size: 20px;
  resize: none;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  &::-webkit-scrollbar {
    width: 8px; /* 스크롤바의 너비 */
  }

  &::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: #b4b4b487; /* 스크롤바의 색상 */

    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #e4e4e4a3; /*스크롤바 뒷 배경 색상*/
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

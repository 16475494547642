import React from 'react';
import { Contents, Item, NickName, SentDate, TextWrap } from '../../../Parts/Common/TabLists/ViewListsElements';
import PreviewImage from '../../../Parts/Common/PreviewImage';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import styled from 'styled-components';

const ItemBox = styled('div')`
  .item {
    display: grid;
    padding: 16px 28px 16px 16px;
    gap: 19px;
    grid-template-columns: 80px calc(100% - 109px);
    position: relative;
    .preview-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .info {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      div {
        line-height: 1;
      }
      .nick {
        margin-bottom: 9px;
        flex-shrink: 0;
      }
      .content {
        flex-shrink: 0;
      }
      .bottom {
        margin-top: 10px;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & > span {
          justify-content: flex-start;
        }
      }
    }
    & > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      font-size: 24px;
      transition: 0.5s;
      color: #fff;
    }
    &:hover > svg,
    &.selected > svg {
      color: #5affe2;
    }
  }
  .view-container {
  }
`;
const SentEmergencyList = ({ emergencyView, setEmergencyView, emergencyList }) => {
  console.log('emergencyList', emergencyList);

  return (
    <ItemBox>
      {emergencyList.map((item, index) => (
        <Item
          key={'list' + item.workDate + item.sqenNumb}
          className={'item ' + (item.workDate + item.sqenNumb === emergencyView.workDate + emergencyView.sqenNumb && 'selected')}
          onClick={() => {
            console.log('item', item);
            setEmergencyView(item);
          }}
        >
          <PreviewImage width={'80px'} height={'70px'} image={item.fileName} shape={'rect'} />
          <TextWrap className={'info'}>
            <NickName className={'nick'}>{item.subject}</NickName>
            <Contents className={'content'}>{item.content}</Contents>
            <div className={'bottom'}>
              <SentDate>{item.ftDt}</SentDate>
            </div>
          </TextWrap>
          <ArrowForwardIosRoundedIcon />
        </Item>
      ))}
    </ItemBox>
  );
};

export default SentEmergencyList;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 93px);
  box-sizing: border-box;
  margin-top: 56px;
  display: flex;
  gap: 30px;
`;

export const NoneResult = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8a8a8a;
  font-size: 20px;
  font-weight: bold;
`;

/************ Lists Section ************/
export const ListsWrap = styled.div`
  background-color: #37465b;
  width: 42%;
  height: 100%;
  border-radius: 20px;
  padding: 30px;
`;

export const Results = styled.div`
  width: 100%;
  height: calc(100% - 198px);
`;

export const Item = styled.div`
  width: 100%;
  height: 110px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  background-color: #212838;
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 14px;
  transition: border 0.2s ease;

  &:hover,
  &.selected {
    border: solid 1px #5affe2;
  }
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    height: 100px;
    padding: 10px;
  }
`;

export const PreviewImg = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
`;

export const ContentWrap = styled.div`
  width: calc(100% - 230px);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Subject = styled.p`
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;

export const ContentText = styled.p`
  font-size: 16px;
  line-height: 25px;
  font-weight: normal;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
`;

export const SentDate = styled.span`
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  color: #ddd;
`;

export const SubmitBtn = styled.button`
  width: 100px;
  height: 35px;
  border-radius: 5px;
  background-color: #7e6eff;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;

  /* disabled */
  &:disabled {
    background-color: #e8e8e8;
    color: #787878;
  }
`;

/************ Detail Content Section ************/
export const DetailContentWrap = styled.div`
  background-color: #37465b;
  width: 58%;
  height: 100%;
  padding: 30px;
  border-radius: 20px;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 65px);
  background: #fff;
  border-radius: 10px;
`;

export const ContentHeader = styled.div`
  width: 100%;
  height: 128px;
  padding: 20px 30px;
  border-bottom: 1px solid #10141c;
`;

export const HeaderLeft = styled.div`
  width: 80%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const HeaderRight = styled.div`
  width: 20%;
  height: 100%;
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ProfileImg = styled.div`
  width: 30px;
  height: 30px;
`;

export const ContentTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #10141c;
`;

export const ContentSubInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Category = styled.span`
  font-size: 18px;
  font-weight: normal;
  color: #10141c;
  margin-right: 3px;
`;

export const WritedDate = styled.span`
  font-size: 18px;
  font-weight: normal;
  color: #10141c;
`;

export const Stat = styled.div`
  border-radius: 20px;
  //background-color: #7e6eff;
  width: 91px;
  padding: 3px 0;
  line-height: 25px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;

export const ContentMain = styled.div`
  padding: 25px 30px;
  height: calc(100% - 128px);
`;

export const Post = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ImageWrap = styled.div`
  width: 100%;
  height: fit-content;
`;

export const MainText = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: normal;
  color: #10141c;
  white-space: pre-wrap;
`;

export const AuthorInfoWrap = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 10px;
  background-color: #e8e8e8;
  padding: 20px 30px;
`;

export const AuthorInfo = styled.div`
  width: 100%;
`;

export const InfoTitle = styled.span`
  display: inline-block;
  width: 150px;
  color: #212838;
  font-size: 20px;
`;

export const InfoContent = styled.span`
  width: 70%;
  display: inline-block;
  color: #212838;
  font-size: 20px;
`;

export const Report = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #8a8a8a;
`;

export const ReportBtn = styled(Link)`
  color: #8a8a8a;
  font-size: 20px;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Divide = styled.span`
  font-size: 18px;
  padding: 0 5px;
  margin-top: -2px;
`;

export const Line = styled.p`
  background-color: #10141c;
  width: calc(100% + 60px);
  margin-left: -30px;
  height: 1px;
`;

export const ContentBottom = styled.div`
  width: 100%;
`;

export const SubmitedList = styled.div`
  width: 100%;
  height: 182px;
  border-radius: 10px;
  border: solid 1px #ddd;
  padding: 25px 25px 20px 25px;
  margin-bottom: 25px;
`;

export const SubmitedListHead = styled.div`
  width: 100%;
  height: 70%;
`;

export const PartnerProfile = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
`;

export const PartnerInfo = styled.div`
  float: left;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const RatingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const Amount = styled.div`
  float: right;
`;

export const DisableBtn = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #ddd;
  background-color: #e8e8e8;
  font-size: 24px;
  color: #8a8a8a;
  text-align: center;
`;

export const ShowEstimate = styled.button`
  width: 50%;
  height: 40px;
  border: solid 1px #7e6eff;
  background-color: #7e6eff;
  border-radius: 5px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`;

export const DeleteEstimate = styled.button`
  width: 50%;
  height: 40px;
  background-color: #e8e8e8;
  color: #787878;
  border-radius: 5px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`;

/************ 내용 없을 경우 보여줄 NoneContent ************/
export const NoneContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #8a8a8a;
`;

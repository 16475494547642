import styled, { keyframes } from 'styled-components';

export const modalShow = keyframes`
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
`;

export const modalBgShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const OpenModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgb(31 31 31 / 30%);
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: ${modalBgShow} 0.4s;
`;

export const CloseModal = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Section = styled.section`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-height: 90vh;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #070c15;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: ${modalShow} 0.4s;
  overflow: hidden;

  display: grid;
  grid-template-rows: 95px calc(100% - 95px);
  .main-section {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;

    .modal-main {
      flex: 1 1 100%;
      padding: 0 30px;
      max-height: 100%;
    }
  }
  .modal-main-scrollbar {
    & > div:nth-child(1) {
      padding: 0 30px;
    }
    .thumb-vertical {
    }
  }
  .fixed-button {
    flex: 0 0 40px;
    padding: 0 30px;
  }

  @media all and (max-width: 1024px) {
    grid-template-rows: 64px calc(100% - 95px);
    max-width: 700px;
  }

  // 모바일
  @media all and (max-width: 480px) {
    width: 100vw !important;
    height: 80vh !important;
    max-width: 100vw !important;
    max-height: 80vh !important;
    border-radius: 0;
    .main-section {
      padding: 20px 14px;
    }
    .modal-main-scrollbar {
      & > div:nth-child(1) {
        padding: 0;
      }
      .thumb-vertical {
      }
    }
    .fixed-button {
      flex: auto;
      padding: 0 14px;
    }
  }
`;

export const UploaderText = styled.div`
  color: #fff;
`;

export const Section2 = styled.section`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-height: 90vh;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #070c15;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: ${modalShow} 0.4s;
  overflow: hidden;

  display: grid;
  .main-section {
    display: flex;
    flex-direction: column;
    .modal-main {
      flex: 1 1 100%;
      padding: 0 30px;
      max-height: 100%;
    }
  }
  .modal-main-scrollbar {
    & > div:nth-child(1) {
      padding: 0 30px;
    }
    .thumb-vertical {
    }
  }
  .fixed-button {
    flex: 0 0 40px;
    padding: 0 30px;
  }

  // 모바일
  @media all and (max-width: 480px) {
    max-height: 70vh;
    .main-section {
      padding: 20px 14px;
    }
    .modal-main-scrollbar {
      & > div:nth-child(1) {
        padding: 0;
      }
      .thumb-vertical {
      }
    }
    .fixed-button {
      padding: 0 14px;
    }
  }
`;

export const ModalHeader = styled.header`
  position: relative;
  padding: 25px 40px;
  background-color: #070c15;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;

  @media all and (max-width: 1024px) {
    font-size: 20px;
    padding: 10px 40px;
  }

  // 모바일
  @media all and (max-width: 480px) {
    padding: 20px 14px;
    font-size: 20px;
    height: 60px;
  }
`;

export const ModalHeader2 = styled.header`
  position: relative;
  background-color: #070c15;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 25px;
  right: 40px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
  cursor: pointer;

  @media all and (max-width: 1024px) {
    top: 10px;
    right: 40px;
    font-size: 18px;
  }

  // 모바일
  @media all and (max-width: 480px) {
    top: 10px;
    right: 14px;
    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

export const Main = styled.main`
  width: 100%;
  // 모바일
  @media all and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
`;
export const ReviewInputList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
`;
export const InputList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
`;
export const InputCateList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
`;

export const InputMoney = styled.div`
  width: 100%;
  height: 36px;
  padding: 0 10px;
  border-radius: 5px;
  border: solid 1px #8a8a8a;
  background-color: #fff;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  text-align: right;
  font-size: 20px;
  color: #10141c;
  position: relative;
  &::placeholder {
    color: #8a8a8a;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    text-align: left;
    position: absolute;
    top: 50%; /* 상단 위치 */
    transform: translateY(-50%); /* 세로 중앙 정렬 */
  }

  &:focus::placeholder {
    visibility: hidden;
  }
`;

export const LightInput = styled.input`
  background: #fff;
  width: 100%;
  color: #10141c;
  padding: 6px 16px;
  border-radius: 5px;
  border: solid 1px #8a8a8a;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }
`;

export const DarkInput = styled.input`
  width: 100%;
  color: #fff;
  padding: 6px 16px;
  border-radius: 5px;
  border: solid 1px #8a8a8a;
  background-color: #212838;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    height: 30px;
    font-size: 14px !important;
  }
`;

export const FixedText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #8a8a8a;
`;

export const InputClearBtn = styled.button`
  width: 18px;
  height: 18px;
  object-fit: contain;
  background-color: #8a8a8a;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 160px;
  font-family: 'Noto Sans KR';
  font-size: 16px;
  font-weight: normal;
  color: #10141c;
  padding: 10px;
  border-radius: 10px;
  resize: none;

  &::placeholder {
    font-size: 19px;
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    &::placeholder {
      font-size: 16px;
      color: #8a8a8a;
    }
  }
`;

export const SubmitBtn = styled.button`
  width: 100%;
  border-radius: 5px;
  background-color: #7e6eff;
  font-size: 24px;
  height: 40px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  margin-top: 23px;

  &:hover {
    background-color: #6959de;
  }
  /* disable */
  &:disabled {
    background-color: #c4c4c4;
    cursor: default;
  }

  @media all and (max-width: 1024px) {
    height: 30px;
    font-size: 18px;
  }

  // 모바일
  @media all and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const InfoWrap = styled.ul`
  width: 100%;
`;

export const InfoMessage = styled.li`
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  display: block;
`;

export const WhiteDot = styled.div`
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
  margin: 0px 5px 5px 0;
`;

export const TemrsBox = styled.div`
  width: 100%;
  height: 123px;
  border-radius: 5px;
  border: solid 1px #8a8a8a;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  padding: 10px 14px;
`;

export const TermsText = styled.div`
  font-size: 18px;
  font-weight: normal;
  color: #8a8a8a;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  white-space: pre-wrap;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const ShowContent = styled.div`
  width: 100%;
  height: 161px;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #8a8a8a;
  background-color: #fff;
  font-size: 16px;
  font-weight: normal;
  color: #10141c;
`;

export const ShowImageBox = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: 100px;
  gap: 15px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

export const SlideWrap = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

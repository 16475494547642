import utils from 'modules/utils';
import { styled } from 'styled-components';
import WindowFrame from './WindowFrame';

export const SubmitBtn = styled.button`
  width: 100%;
  border-radius: 5px;
  background-color: #7e6eff;
  height: 51px;
  color: #fff;
  font-size: 23px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #6959de;
  }

  // 모바일
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 50px;
    font-size: 18px;
  }
`;

export const Div = styled.div`
  width: 100%;
  height: calc(100% - 174px);
  display: flex;
  flex-direction: column;
  p {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 30px;
  }
  span {
    font-size: 22px;
    font-weight: normal;
    color: #fff;
    margin-bottom: 30px;
  }

  input {
    background: #fff;
    width: 100%;
    height: 40px;
    color: #10141c;
    font-size: 22px;
    padding: 3px 14px;
    border-radius: 5px;
    border: solid 1px #8a8a8a;

    &::placeholder {
      color: #8a8a8a;
    }

    &:focus::placeholder {
      visibility: hidden;
    }
  }
`;

const CheckPwModal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, title, setIsCheked, onSubmit, setPw, pw } = props;
  const modalHeaderStyle = {
    borderBottom: 'none',
    padding: '50px 50px 0 50px',
  };
  const modalBodyStyle = {
    main_section: { padding: '30px 50px 50px 50px' },
    modal_main: { padding: 0 },
    fixed_button: { padding: 0 },
  };

  const onKeyEnter = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'789px'}
      height={'520px'}
      scroll={0}
      modalBodyStyle={modalBodyStyle}
      modalHeaderStyle={modalHeaderStyle}
      slotFixedButton={
        <SubmitBtn
          type="button"
          onClick={() => {
            onSubmit();
          }}
        >
          확인
        </SubmitBtn>
      }
    >
      <Div>
        <p>
          회원님의 개인정보를 더 안전하게 보호하기 위해
          <br />
          비밀번호를 한번 더 입력해 주세요.
        </p>
        <span>현재 아이디: {utils.user('id')}</span>
        <span style={{ marginBottom: '15px' }}>비밀번호 입력</span>
        <input type="password" value={pw} onInput={(e) => setPw(e.target.value)} onKeyDown={onKeyEnter} />
      </Div>
    </WindowFrame>
  );
};

export default CheckPwModal;

import MultiImageUpload from 'components/FileUpload/MultiImageUpload';
import { TitleSubText, TitleText } from 'style';
import { styled } from 'styled-components';

const Div = styled.div`
  width: 100%;
  height: auto;
`;
const ImageUpload = ({ title, subTitle, setImgDelKey, setImgFile, images, kind }) => {
  // 달인정보 이미지 조회
  return (
    <Div>
      <TitleText>{title}</TitleText>
      <TitleSubText style={{ marginBottom: '20px' }}>{subTitle}</TitleSubText>
      <MultiImageUpload name={kind} maxImage={5} imageArr={images} setImageArr={setImgFile} setDeleteImageArr={setImgDelKey} disabledButton={false} accept="image/jpg, image/jpeg, image/png" uploadText={[]} />
    </Div>
  );
};

export default ImageUpload;

import React, { useState } from 'react';
import { styled } from 'styled-components';
import utils from 'modules/utils';
import dialog from './../../../../modules/utils/utils.dialog';
import { useMediaQuery } from 'react-responsive';
import { Avatar } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DEFAULT_PROFILE from './../../../../assets/default_profile.jpg';

const TABLET_WIDTH = '100px';
const PROFILE_WIDTH = '120px';

export const Img = styled.div`
  width: ${(props) => (props.isTablet ? TABLET_WIDTH : PROFILE_WIDTH)};
  height: ${(props) => (props.isTablet ? TABLET_WIDTH : PROFILE_WIDTH)};
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditText = styled.span`
  /* width: 138px;
  height: 45px; */
  width: ${(props) => (props.isTablet ? '88px' : '108px')};
  height: ${(props) => (props.isTablet ? '30px' : '40px')};
  color: #fff;
  padding-top: 2px;
  font-size: ${(props) => (props.isTablet ? '12px' : '18px')};
  line-height: 24px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: -3px;
  left: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
`;

const ProfileDiv = styled.div`
  position: relative;
  width: calc(${(props) => (props.isTablet ? TABLET_WIDTH : PROFILE_WIDTH)} + 10px);
  height: calc(${(props) => (props.isTablet ? TABLET_WIDTH : PROFILE_WIDTH)} + 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const RemoveBtn = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  /* 붉은색 배경 */
  background-color: #ff585e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;

  svg {
    color: #fff;
    font-size: 20px;
  }
`;

const ProfileImg = ({ setSelectedFile, userGubn, profileCount, setProfileCount }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  // 웹 페이지에 보여줄 이미지 파일
  const [imageUrl, setImageUrl] = useState('');

  const uploadImage = (e) => {
    //이미지가 jpg, jpeg, png가 아니면 알림창 후 return
    if (!e.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
      utils.dialog.alert('업로드 실패!', '이미지: 20MB이하의 JPG, JPEG, PNG 파일만 등록 가능합니다.', 'warning');
      return;
    }
    setSelectedFile(e.target.files[0]);
    setProfileCount(1);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleRemove = () => {
    setSelectedFile(null);
    setImageUrl('');
    setProfileCount(0);
  };

  return (
    <ProfileDiv isTablet={isTablet}>
      {!!profileCount && (
        <RemoveBtn onClick={handleRemove}>
          <ClearIcon />
        </RemoveBtn>
      )}
      <Img isTablet={isTablet}>
        <label htmlFor="image-file" onChange={uploadImage}>
          <input
            type="file"
            id="image-file"
            // jpg, jpeg, png 만 허용
            accept="image/jpg, image/jpeg, image/png"
            hidden
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <Avatar
            alt={utils.user('id')}
            src={!profileCount ? DEFAULT_PROFILE : imageUrl === '' ? process.env.REACT_APP_IMAGE_VIEW_URL + `${userGubn}/${utils.user('id')}?` + Date.now() : imageUrl}
            sx={{
              width: isTablet ? 100 : 120,
              height: isTablet ? 100 : 120,
            }}
          />
          <EditText isTablet={isTablet} type="button">
            프로필 편집
          </EditText>
        </label>
      </Img>
    </ProfileDiv>
  );
};

export default ProfileImg;

import React, { useState, useEffect } from 'react';
import { BusinessContent } from '../BusinessElements';
import { styled } from 'styled-components';
import { WhiteDot } from 'style';
import utils from 'modules/utils';
import { koreanRegex, numberRegex, hyphenNumberRegex } from './../../../../../../modules/regex/regex';
import ImageUpload from '../../Partner/Reference/ImageUpload';
import { imageToImageArr } from 'modules/Upload/imageUpload';

// 업태, 업좀 정규식 추가
// 한글과 쉼표 공백만 입력 가능 영어나 특수문자 입력 불가
const itemRegex = /^[ㄱ-ㅎ|가-힣|,| ]*$/;

const Form = styled.form`
  width: 100%;
  height: 100%;

  .input-lists {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .input-lists span {
    width: 236px;
    font-size: 24px;
    font-weight: bold;
    color: #e9e9e9;
  }
  .business-type-button {
    width: calc(100% - 236px);
    height: 40px;
    display: flex;
    gap: 30px;
  }
  .input-lists .business-type-button button {
    height: 100%;
    width: 50%;
    border-radius: 5px;
    border: solid 1px #8a8a8a;
    background-color: #fff;
    color: #8a8a8a;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover,
    &.selected {
      background-color: #7e6eff;
      color: #fff;
    }
  }

  .business-bottom-text {
    margin-top: 10px;
    & > div {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    .input-lists span {
      width: 150px;
      font-size: 16px;
    }
    .input-lists .business-type-button button {
      font-size: 16px;
    }
    .business-bottom-text {
      & > div {
        font-size: 14px;
      }
    }
  }
`;

const TextInput = styled.input.attrs(() => ({
  type: 'text',
}))`
  width: calc(100% - 236px);
  height: 40px;
  padding: 10px 15px;
  font-size: 20px;
  color: #10141c;
  border-radius: 5px;
  border: solid 1px #8a8a8a;
  background-color: #fff;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% - 150px);
    height: 30px;
    font-size: 14px;
  }
`;

const StyleDiv = styled.div`
  font-size: 20px;
  line-height: 25px;
  font-weight: normal;
  color: #e9e9e9;
`;

export const formatTelNumber = (telNumber) => {
  const digitsOnly = telNumber.replace(/[^\d]/g, '');
  const { length } = digitsOnly;
  // 02-xxx-xxxx, 010-xxxx-xxxx, 011-xxx-xxxx ....
  if (length <= 2) return digitsOnly;
  if (length <= 5) return `${digitsOnly.slice(0, 2)}-${digitsOnly.slice(2)}`;
  if (length <= 9) return `${digitsOnly.slice(0, 2)}-${digitsOnly.slice(2, 5)}-${digitsOnly.slice(5)}`;
  if (length <= 10) return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
  if (length <= 11) return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 7)}-${digitsOnly.slice(7)}`;
  return `${digitsOnly.slice(0, 2)}-${digitsOnly.slice(2, 6)}-${digitsOnly.slice(6, 10)}`;
};

export const formatBusiNumber = (busiNumber) => {
  const digitsOnly = busiNumber.replace(/[^\d]/g, '');
  const { length } = digitsOnly;
  if (length <= 3) return digitsOnly;
  if (length <= 5) return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3)}`;
  if (length <= 10) return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 5)}-${digitsOnly.slice(5)}`;
  return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 5)}-${digitsOnly.slice(5, 10)}`;
};

const TabBusiness = ({ setSave, data, refresh }) => {
  const [imgDelKey, setImgDelKey] = useState([]);
  const [images, setImages] = useState([]);

  const [inputVal, setInputVal] = useState({
    busiType: '',
    busiItem: '',
    busiCondition: '',
    busiName: '',
    busiCeo: '',
    busiTel: '',
    busiBusino: '',
  });

  const FormText = [
    {
      bottom: ['개인 사업자의 경우, 신분증 사진으로 등록해 주세요.', '사진은 확인용으로 사용되며 등록번호, 상호 등 내용이 가려지지 않은 선명한 사진으로 등록해 주세요.'],
      imageName: '사업자등록증/신분증',
    },
  ];

  // 저장 버튼 클릭했을 때 api 요청
  const onSave = async () => {
    if (utils.isEmpty(inputVal.busiName)) {
      utils.dialog.alert('상호명 입력!', '상호명을 입력해 주세요.', 'warning');
      return;
    } else if (utils.isEmpty(inputVal.busiCeo)) {
      utils.dialog.alert('대표자명 입력!', '대표자를 입력해 주세요.', 'warning');
      return;
    } else if (utils.isEmpty(inputVal.busiBusino)) {
      utils.dialog.alert('사업자 등록번호 입력!', '사업자 등록번호를 입력해 주세요.', 'warning');
      return;
    } else if (utils.isEmpty(inputVal.busiTel)) {
      utils.dialog.alert('대표전화 입력!', '대표자 전화번호를 입력해 주세요.', 'warning');
      return;
    }

    try {
      const formData = new FormData();
      console.log('@@@', images);

      images.forEach((image) => {
        if (image.localFlag) {
          formData.append('files', image.file);
        }
      });
      let delKey = '';
      imgDelKey.forEach((image) => {
        delKey += image.serverFileName + '_//';
      });
      formData.append('delKey', delKey);
      formData.append('busiType', 0);
      formData.append('busiItem', inputVal.busiItem);
      formData.append('busiCondition', inputVal.busiCondition);
      formData.append('busiName', inputVal.busiName);
      formData.append('busiCeo', inputVal.busiCeo);
      formData.append('busiTel', inputVal.busiTel);
      formData.append('busiBusino', inputVal.busiBusino);

      utils.http.setMode('api');
      const res = await utils.http.postFile('/v1/SP_p_partBusiInfo_wi1', formData);
      console.log(res.data);
      if (res.data.success && res.data.message === '성공') {
        utils.dialog.alert('저장 완료!', '입력하신 내용이 다를 경우,<br />출금 시 제한이 생길 수 있습니다.', 'success');
        getImages();
        setImgDelKey([]);
        setImages([]);
        refresh();
      } else {
        utils.dialog.alert('저장 실패!', '오류가 발생했습니다. 관리자에게 문의해 주세요.', 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 저장된 사업자등록증 이미지 state
  // 달인정보 이미지 조회
  const getImages = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_p_partCompInfo_S2/${utils.user('id')}`);
      if (res.data.data && res.data.data.length > 0) {
        let images = { partnerBusiness: [] };
        for (let kind in images) {
          images[kind] = res.data.data.filter((obj) => obj.dir === kind).slice(0, 30);
        }
        setImages(imageToImageArr(images.partnerBusiness));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getImages();
  }, []);
  useEffect(() => {
    if (setSave) {
      setSave({ onSave });
    }
  }, [images, inputVal]);

  useEffect(() => {
    if (data.length > 0) {
      setInputVal({
        ...inputVal,
        busiType: data[0].busiType,
        busiItem: data[0].busiItem.trim(),
        busiCondition: data[0].busiCondition.trim(),
        busiName: data[0].busiName.trim(),
        busiCeo: data[0].busiCeo,
        busiTel: data[0].busiTel,
        busiBusino: data[0].busiNo,
      });
    }
  }, [data]);

  return (
    <BusinessContent>
      <Form>
        <div className="input-lists">
          <span>상호명</span>
          <TextInput placeholder="신고된 상호명을 입력해 주세요." onChange={(e) => setInputVal({ ...inputVal, busiName: e.target.value })} value={inputVal.busiName} />
        </div>
        <div className="input-lists">
          <span>대표자명</span>
          <TextInput
            placeholder="등록된 대표자명을 입력해 주세요."
            onChange={(e) => {
              if (koreanRegex.test(e.target.value) || e.target.value === '') {
                setInputVal((prevDate) => ({
                  ...prevDate,
                  busiCeo: e.target.value,
                }));
              }
            }}
            value={inputVal.busiCeo}
            maxLength={10}
          />
        </div>
        <div className="input-lists">
          <span>사업자 등록번호</span>
          <TextInput
            placeholder="'-'없이 입력해 주세요."
            onChange={(e) => {
              if (hyphenNumberRegex.test(e.target.value) || e.target.value === '') {
                setInputVal({ ...inputVal, busiBusino: formatBusiNumber(e.target.value) });
              }
            }}
            value={inputVal.busiBusino}
            maxLength={12}
          />
        </div>
        <div className="input-lists">
          <span>대표전화</span>
          <TextInput
            placeholder="'-'없이 입력해 주세요."
            onChange={(e) => {
              if (hyphenNumberRegex.test(e.target.value) || e.target.value === '') {
                setInputVal({ ...inputVal, busiTel: formatTelNumber(e.target.value) });
              }
            }}
            value={inputVal.busiTel}
            maxLength={13}
          />
        </div>
        <div className="input-lists">
          <span>업태</span>
          <TextInput
            placeholder="업태명을 입력해 주세요."
            onChange={(e) => {
              if (itemRegex.test(e.target.value) || e.target.value === '') {
                setInputVal({ ...inputVal, busiItem: e.target.value });
              }
            }}
            value={inputVal.busiItem}
          />
        </div>
        <div className="input-lists">
          <span>업종</span>
          <TextInput
            placeholder="업종명을 입력해 주세요."
            onChange={(e) => {
              if (itemRegex.test(e.target.value) || e.target.value === '') {
                setInputVal({ ...inputVal, busiCondition: e.target.value });
              }
            }}
            value={inputVal.busiCondition}
          />
        </div>
        <div className="input-lists" style={{ marginBottom: '25px' }}>
          <span>사업자등록증</span>
        </div>
        <ImageUpload title="" setImgDelKey={setImgDelKey} setImages={setImages} images={images} kind="partnerBusiness" maxImage={1} />
        <div className="business-bottom-text">
          <StyleDiv>
            <WhiteDot style={{ margin: '0 5px 5px' }} />
            {FormText[0].bottom[0]}
          </StyleDiv>
          <StyleDiv>
            <WhiteDot style={{ margin: '0 5px 5px' }} />
            {FormText[0].bottom[1]}
          </StyleDiv>
        </div>
      </Form>
    </BusinessContent>
  );
};

export default TabBusiness;

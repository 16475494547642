import styled from 'styled-components';

export const TableWrap = styled.div`
  /* width: 100%;
  height: calc(100% - 50px);
  display: flex;
  margin-top: 20px;
  gap: 20px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  gap: 30px;
`;

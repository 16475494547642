import { ContentImg, ContentText, NoneContentText, NoticeContent, NoticeTitle, ViewDetail } from 'components/Parts/Table/TableElements';
import utils from 'modules/utils';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { BdWhiteText, NormalWhiteSpan } from 'style';
import ModalComponent from './../../../Parts/Common/Modal/ModalComponent';

const NoticeDetail = ({ clickedItem }) => {
  const [webImage, setWebImage] = useState([]);
  const getImageUrl = (clickedItem) => {
    if (!clickedItem) return;
    const url = `/v2/SP_m_NoticeDetailV2_S1/${clickedItem.workDate}/${clickedItem.sqenNumb}`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      console.log('결과', res.data.data[0]);
      setWebImage(res.data.data);
    });
  };
  const imageAddr = process.env.REACT_APP_IMAGE_VIEW_URL;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    console.log(clickedItem);
    getImageUrl(clickedItem);
  }, [clickedItem]);

  return (
    <>
      {clickedItem !== undefined && clickedItem !== null ? (
        <ViewDetail>
          <NoticeTitle>
            <BdWhiteText>{clickedItem.notiSubject}</BdWhiteText>
            <NormalWhiteSpan>{clickedItem.ftDt}</NormalWhiteSpan>
          </NoticeTitle>
          <Scrollbars
            className="scrollbar-container"
            style={{
              width: '100%',
              height: 'calc(100% - 62px)',
              borderRadius: '10px',
              background: '#fff'
            }}
            renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
          >
            <NoticeContent>
              {Object.keys(clickedItem).includes('file1') ? (
                <>
                  {clickedItem[0]}
                  {webImage.map((item) => (
                    <ContentImg onClick={() => setOpen(true)} src={`${imageAddr}${item.file1}`} alt="" key={`file-${item.file1}`} />
                  ))}
                  <ContentText>{clickedItem.notiContent}</ContentText>
                </>
              ) : (
                <>
                  <ContentText>{clickedItem.notiContent}</ContentText>
                </>
              )}
            </NoticeContent>
          </Scrollbars>
        </ViewDetail>
      ) : (
        <ViewDetail>
          <NoneContentText>공지사항을 선택하여 내용을 확인해 주세요.</NoneContentText>
        </ViewDetail>
      )}
      <ModalComponent open={open} setOpen={setOpen} width={'500px'}>
        {clickedItem ? webImage.map((item) => <ContentImg onClick={() => setOpen(true)} src={`${imageAddr}${item.file1}`} alt="" key={`file-${item.file1}`} />) : <div />}
      </ModalComponent>
    </>
  );
};

export default NoticeDetail;

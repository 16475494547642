import React, { useEffect, useState } from 'react';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import EventSlide from 'components/Parts/Slide/EventSlide';
import EventTable from './EventTable';
import EventDetail from './EventDetail';
import { Content, TableWrap } from './EventElements';
import { styled } from 'styled-components';
import utils from 'modules/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { ImgLink } from 'components/Parts/Slide/SlideElements';
import { EventSlideImg } from '../Notice/Notice';
import { ContentWrap } from '../NewsElements';

const Event = () => {
  const [eventData, setEventData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [clickedItem, setClickedItem] = useState(null);

  const imageAddr = process.env.REACT_APP_IMAGE_VIEW_URL;
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: true,
    width: '100%',
    customPaging: function (i, a) {
      return (
        <div>
          {i + 1}/{eventData.length}
        </div>
      );
    }
  };
  const menuInfo = '수달에서 진행 중인 이벤트에 참여하여 다양한 혜택을 만나보세요!';
  const modalStyle = {
    width: '410px',
    height: '82px'
  };
  // Thead title
  const theadTitles = ['상태', '제목', '기간'];

  // 이벤트 배너 이미지 저장 state
  const [banner, setBanner] = useState([]);
  // 이벤트 배너 이미지 조회
  const getImage = async () => {
    try {
      utils.http.setMode('api');
      // FIXME: /v2/SP_a_bannerV2_S3/{location}/{fileGubn} API 로 변환필요 2024-03-04 기준으로 이벤트 주석처리
      const res = await utils.http.get(`/v1/SP_m_event_S1`);
      // const res = await utils.http.get(`/v2/SP_a_bannerV2_S3/${location}/${fileGubn}`);
      if (res.data.data.length > 0) {
        setEventData(res.data.data);
        // file(?)의 값만 추출하여 배열로 저장
        const imgList = res.data.data.map((item) => item.file4);
        // 추출한 배열에서 null 값 제거
        const imgList2 = imgList.filter((item) => item);

        // 이미지 주소 앞에 붙인 배열을 state에 저장
        setBanner(imgList2);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 클릭한 이벤트에 대한 state
  const handleClickEvent = (item) => {
    const event = eventData.find((event) => event.file4 === item);
    navigate(`/manage/news/event/${event.workDate}/${event.sqenNumb}`);
  };

  useEffect(() => {
    getImage();
  }, []);
  return (
    <>
      <SmallInfoModal menu="이벤트" infoModalData={menuInfo} style={modalStyle} />
      <ContentWrap
        style={{
          marginTop: '20px'
        }}
      >
        <Content>
          <div
            style={{
              width: '45%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px'
            }}
          >
            <div
              style={{
                backgroundColor: '#37465b',
                borderRadius: '10px',
                height: '150px',
                maxWidth: '180%',
                overflow: 'hidden',
                width: '100%'
              }}
            >
              {/* {banner.length > 0 ? <EventSlide slideImgList={banner} eventData={eventData} /> : <></>} */}
              {/* <EventSlide slideImgList={banner} eventData={eventData} /> */}
              {banner.length > 0 ? (
                <Slider className="eventSlide" {...settings}>
                  {banner.map((item, index) => (
                    <ImgLink key={index}>
                      <EventSlideImg src={`${imageAddr}${item}`} alt="" onClick={() => handleClickEvent(item)} />
                    </ImgLink>
                  ))}
                </Slider>
              ) : (
                <></>
              )}
            </div>
            <EventTable theadTitles={theadTitles} setClickedItem={setClickedItem} clickedItem={clickedItem} />
          </div>

          <div
            style={{
              width: '55%',
              height: '100%'
            }}
          >
            <EventDetail clickedItem={clickedItem} />
          </div>
        </Content>
      </ContentWrap>
    </>
  );
};

export default Event;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  margin-bottom: 83px;
  img {
    max-width: 412px;
  }

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    margin-bottom: 53px;
    img {
      width: 228px;
      height: 32px;
    }
  }

  /* 테블릿 가로 (해상도 768px ~ 1024px) */
  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 56px;
    img {
      width: 216px;
      height: 30px;
    }
  }
`;

export const Form = styled.form`
  background-color: #2b2f44;
  max-width: 730px;
  height: 752px;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 75px 80px 70px;
  border: 1px solid #717378f0;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 4px #504f4f96 inset;
  position: relative;

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    max-width: 536px;
    height: 547px;
    padding: 54px 56px 50px;
  }

  /* 테블릿 가로 (해상도 768px ~ 1024px) */
  @media all and (min-width: 768px) and (max-width: 1024px) {
    max-width: 501px;
    height: 515px;
    padding: 48px 56px 50px;
  }

  /* 모바일 가로 (해상도 430px >) */
  @media all and (max-width: 480px) {
    padding: 50px;
    max-width: 100%;
  }
`;

export const FormInput = styled.input`
  width: 100%;
  height: 70px;
  padding: 0 27px;
  margin-bottom: 30px;
  border: none;
  border-radius: 4px;
  color: #222;
  font-size: 24px;
  font-weight: normal;
  font-family: 'Noto Sans KR';

  &::placeholder {
    color: #646464;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    height: 51px;
    padding: 0 20px;
    font-size: 18px;
    margin-bottom: 22px;
    &.password-input {
      margin-bottom: 22px;
    }
  }

  /* 테블릿 가로 (해상도 768px ~ 1024px)*/
  @media all and (min-width: 768px) and (max-width: 1024px) {
    height: 49px;
    padding: 0 20px;
    font-size: 16px;
    margin-bottom: 20px;
    &.password-input {
      margin-bottom: 22px;
    }
  }

  /* 모바일 가로 (해상도 430px >) */
  @media all and (max-width: 480px) {
    height: 52px;
    padding: 0 20px;
    font-size: 14px;
    margin-bottom: 20px;
    &.password-input {
      margin-bottom: 22px;
    }
  }
`;

export const Options = styled.div`
  background-color: transparent;
  width: 100%;
  margin-bottom: 70px;
  display: flex;
  justify-content: ${(props) => (props.isMobile ? 'flex-end' : 'space-between')};
  align-items: center;

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    margin-bottom: 60px;
  }

  /* 테블릿 가로 (해상도 768px ~ 1024px)*/
  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 53px;
  }
`;

export const CheckBoxWrap = styled.div`
  float: left;
`;

export const DivideBox = styled.span`
  color: #fff;
  font-size: ${(props) => (props.isMobile ? '14px' : '22px')};
  padding: 0 10px;

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    font-size: 16px;
  }

  /* 테블릿 가로 (해상도 768px ~ 1024px)*/
  @media all and (min-width: 768px) and (max-width: 1024px) {
    font-size: 15px;
  }
`;

export const ForgotBtn = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;

export const ForgotBtnLink = styled(Link)`
  color: #fff;
  /* isMobile :boolean 을 props로 받아서 true면 14px 아니면 22px*/
  font-size: ${(props) => (props.isMobile ? '14px' : '22px')};
  font-weight: normal;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    font-size: 16px;
  }
  /* 테블릿 가로 (해상도 768px ~ 1024px)*/
  @media all and (min-width: 768px) and (max-width: 1024px) {
    font-size: 15px;
  }
`;

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const LoginButton = styled.button`
  width: 100%;
  background-color: #7e6eff;
  height: 70px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #6959de;
  }

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    height: 53px;
    font-size: 22px;
  }

  /* 테블릿 가로 (해상도 768px ~ 1024px)*/
  @media all and (min-width: 768px) and (max-width: 1024px) {
    height: 49px;
    font-size: 20px;
  }

  /* 모바일 가로 (해상도 430px >) */
  @media all and (max-width: 480px) {
    height: 52px;
    font-size: 14px;
  }
`;

export const RegisterButton = styled.button`
  background-color: transparent;
  border: 1px solid #7e6eff;
  height: 70px;
  border-radius: 10px;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    height: 53px;
    font-size: 22px;
  }

  /* 테블릿 가로 (해상도 768px ~ 1024px)*/
  @media all and (min-width: 768px) and (max-width: 1024px) {
    height: 49px;
    font-size: 20px;
  }

  /* 모바일 가로 (해상도 430px >) */
  @media all and (max-width: 480px) {
    height: 52px;
    font-size: 14px;
  }
`;

export const Image = styled.img`
  z-index: 99;
  position: absolute;
  left: 80%;
  top: 48%;

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    width: 239px;
    height: 354px;
    top: 47%;
    left: 80%;
  }
  /* 테블릿 가로 (해상도 768px ~ 1024px)*/
  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 222px;
    height: 329px;
    top: 48%;
    left: 78.5%;
  }
`;

import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import { styled } from 'styled-components';
import Icon from 'components/Parts/Common/Icon';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const Box = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  border: solid 1px #8a8a8a;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;

  .calendar_month-icon {
    height: 100%;
    display: flex;
    align-items: center;
  }

  svg {
    color: #10141c;
    font-size: 24px;
  }

  .example-custom-input {
    width: 100%;
    color: #10141c;
    height: 100%;
    text-align: left;
    font-size: 20px;
    line-height: 20px;
    font-weight: normal;
  }
  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;

    .react-datepicker__input-container {
      width: 100%;
      height: 100%;
    }
  }
  .react-datepicker-popper {
    z-index: 100;
  }
  .react-datepicker {
    .react-datepicker__month-wrapper {
      //padding: 4.5px 0;
      padding: 3px 0;

      .react-datepicker__month-text {
        height: 32px;
        line-height: 30px;
        .month-picker {
          color: #10141c;
          font-size: 16px;
        }
      }
      .react-datepicker__month-text--selected {
        background-color: #7e6eff;
        .month-picker {
          color: #ededed;
        }
      }
    }
  }
  &.year-picker-box {
    .react-datepicker {
      height: 270px;
    }
  }

  .react-datepicker__year--container {
    height: 100%;
    width: 230px;
    .react-datepicker__year {
      width: 100%;
      margin-left: 30px;
      .react-datepicker__year-wrapper {
        .react-datepicker__year-text {
          width: 5rem;
          height: 32px;
          line-height: 32px;
          color: #10141c;
          font-size: 16px;
        }
        .react-datepicker__year-text--today {
          font-weight: normal;
        }
        .react-datepicker__year-text--selected {
          background-color: #7e6eff;
          .year-picker {
            color: #ededed;
            font-weight: bold;
          }
        }
      }
    }
  }
`;
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <button className="example-custom-input" onClick={onClick} ref={ref} type="button">
    {value}
  </button>
));
const renderYearContent = (year) => {
  const tooltipText = `Tooltip for year: ${year}`;
  return (
    <span title={tooltipText} className="year-picker">
      {year}
    </span>
  );
};
const renderMonthContent = (month, shortMonth, longMonth) => {
  const tooltipText = `Tooltip for month: ${longMonth}`;
  return (
    <span title={tooltipText} className="month-picker">
      {shortMonth}
    </span>
  );
};
const DateInput = ({ dateFormat, value, onChange, maxDate, minDate }) => {
  return (
    <Box className={dateFormat === 'yyyy' ? 'year-picker-box' : dateFormat === 'yyyy-MM' ? 'month-picker-box' : 'date-picker-box'}>
      <div className="calendar_month-icon">
        <CalendarMonthOutlinedIcon />
      </div>
      <DatePicker selected={value} onChange={(date) => onChange(date)} locale={ko} renderYearContent={renderYearContent} showYearPicker={dateFormat === 'yyyy'} renderMonthContent={renderMonthContent} showMonthYearPicker={dateFormat === 'yyyy-MM'} dateFormat={dateFormat} customInput={<ExampleCustomInput />} minDate={minDate} maxDate={maxDate} />
    </Box>
  );
};

export default DateInput;

import common from "./utils.common"
import format from "./utils.format"
import moment from "moment";
let date = {
    moment(date){
        return moment(date)
    },
    fillZero: common.fillZero,
    toDate(date, defaultDate=new Date()){
        date = this.moment(date);
        if(date._isValid){
            return date._d;
        } else {
            return defaultDate;
        }
    },
    today(){
        let date = new Date();

        let year  = date.getFullYear();
        let month = date.getMonth() + 1; // 0부터 시작하므로 1더함 더함
        let day   = date.getDate();

        return year+"-"+this.fillZero(month,2)+"-"+this.fillZero(day,2);
    },
    strCalc(num1, num2){
        if(num2.substring(0,1) === "+")
            return num1 + Number(num2.substring(1));
        else
        if(num2.substring(0,1) === "-")
            return num1 - Number(num2.substring(1));
        else
        if(num2.substring(0,1) === "*")
            return num1 * Number(num2.substring(1));
        else
        if(num2.substring(0,1) === "/")
            return num1 / Number(num2.substring(1));
        else
            return num1;
    },
    calc(dateStr, part, num){
        dateStr = this.moment(dateStr);
        let c_date = new Date(dateStr);

        switch(part.substring(0,1).toLowerCase()){
            case "y":
                c_date.setFullYear(date.strCalc(c_date.getFullYear(),num));
                break;
            case "m":
                c_date.setMonth(date.strCalc(c_date.getMonth(),num));
                break;
            case "d":
                c_date.setDate(date.strCalc(c_date.getDate(),num));
                break;
            case "h":
                c_date.setHours(date.strCalc(c_date.getHours(),num));
                break;
            case "i":
                c_date.setMinutes(date.strCalc(c_date.getMinutes(),num));
                break;
            case "s":
                c_date.setSeconds(date.strCalc(c_date.getSeconds(),num));
                break;
        }

        return c_date.getFullYear()+"-"+this.fillZero(c_date.getMonth() + 1,2)+"-"+this.fillZero(c_date.getDate(),2);
    },
    getDate(day){
        let today = new Date();

        switch(day){
            case "mFirst":
                return this.today().substr(0,8)+this.fillZero(1,2);
            case "mLast":
                return date.calc(date.calc(date.getDate("mFirst"), "M", "+1"),"D","-1");
            case "wFirst":
                return date.calc(this.today(), "D", "-"+(today.getDay()-1));
            case "wLast":
                return date.calc(this.today(), "D", "+"+(7-today.getDay()));
            case "opStart":
                return date.calc(new Date(), "H", "-12");
            case "opEnd":
                return date.calc(new Date(), "H", "+12");
            case "Today":
                return this.today();
            case "planDay":
                return this.today().replaceAll("-", ".");
            case "planMonth":
                return today.getFullYear()+"."+this.fillZero((today.getMonth()+1),2);
            case "planYear":
                return today.getFullYear();
        }
    },
    move(dateStr, part, num, sep){
        let d = new Date(date.calc(dateStr, part, num));

        if(sep === undefined) sep = ".";
        if(part.toLowerCase() === "y") return d.getFullYear();
        if(part.toLowerCase() === "m") return d.getFullYear()+sep+this.fillZero(d.getMonth()+1,2);
        if(part.toLowerCase() === "d") return d.getFullYear()+sep+this.fillZero(d.getMonth()+1,2)+sep+this.fillZero(d.getDate(),2);

    },
    format(form, date){
        if (common.isNull(date)) return "";
        date = this.moment(date);
        let d = new Date(date);
        switch (form){
            case "korDate":
                return d.getFullYear()+"년 "+this.fillZero(d.getMonth()+1,2)+"월 "+this.fillZero(d.getDate(),2)+"일";
            case "korDatetimeM":
                return d.getFullYear()+"년 "+this.fillZero(d.getMonth()+1,2)+"월 "+this.fillZero(d.getDate(),2)+"일 "+this.fillZero(d.getHours(),2)+"시 "+this.fillZero(d.getMinutes(),2)+"분";
            case "korDatetimeS":
                return d.getFullYear()+"년 "+this.fillZero(d.getMonth()+1,2)+"월 "+this.fillZero(d.getDate(),2)+"일 "+this.fillZero(d.getHours(),2)+"시 "+this.fillZero(d.getMinutes(),2)+"분 "+this.fillZero(d.getSeconds(),2)+"초";
            case "Y-M-D":
                return d.getFullYear()+"-"+this.fillZero(d.getMonth()+1,2)+"-"+this.fillZero(d.getDate(),2);
            case "Y-M-D H:m:s": case "datetime":
                return d.getFullYear()+"-"+this.fillZero(d.getMonth()+1,2)+"-"+this.fillZero(d.getDate(),2)+" "+this.fillZero(d.getHours(),2)+":"+this.fillZero(d.getMinutes(),2)+":"+this.fillZero(d.getSeconds(),2);
            default:
                return date;
        }
    },
    now(){
        return this.format("Y-M-D H:m:s", new Date());
    },
    nearTime(){
        let time = new Date();
        time.setMinutes(Math.ceil(time.getMinutes() / 10) * 10);
        return format.time_std(time);
    },
    compare(d1, d2){
        d1 = this.moment(d1);
        d2 = this.moment(d2);
        return (((new Date(d2)).getTime() - (new Date(d1)).getTime()) / 1000 / 60 / 60 / 24);
    },
    calcAge(birthday){
        const today = new Date();
        const birthDate = new Date(birthday);

        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }
}
Object.freeze(date);
export default date;
import utils from '.';
import crypto from '../crypto';
import moment from 'moment/moment';

let tokenTool = {
  decode(token) {
    let base64Payload = token.split('.')[1]; //value 0 -> header, 1 -> payload, 2 -> VERIFY SIGNATURE
    let payload = atob(base64Payload); //Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
  },
  expired(token) {
    let payload = this.decode(token);
    return utils.isNull(payload?.exp) || payload?.exp * 1000 < new Date();
  },
};

let user = function (kind) {
  return (utils.storage.session('user') ?? {})[kind];
};

const postUrl = (url, data) => {
  // url + ? + data
  let params = '';
  for (let key in data) {
    params += `${key}=${data[key]}&`;
  }
  params = params.slice(0, -1);
  return url + '?' + params;
};

Object.assign(user, {
  expired(token) {
    return tokenTool.expired(token);
  },
  async login(userID, userPW, isChecked) {
    try {
      utils.http.setMode('api');
      const data = {
        userId: userID,
        pw: userPW,
      };

      const res = await utils.http.post(`/v1/SP_m_loginCheckPost_s1`, null, data);
      console.log('로그인', res.data.data[0]);

      // 로그인 성공 & 로그인 상태 유지하기 체크 안했을 경우
      //isChecked
      const resData = res.data.data[0];
      if (res.data.data.length > 0) {
        const useCalendar = !!resData?.partChulTime && !!resData?.partToeTime && !!resData?.partReserUnit;
        console.log('로그인 resData', resData);
        utils.storage.session('user', {
          auth: resData.userAuth,
          id: resData.userId,
          nick: resData.userNick,
          token: resData.userToken,
          userName: resData.userName,
          refreshToken: resData.refreshToken,
          userHp: resData.userHp,
          accessTime: new Date(),
          accessIssue: new Date(),
          useCalendar,
        });
        if (resData.userAuthLog !== '1') {
          let flag = false;

          await utils.dialog.confirm('달인으로 가입하시겠습니까?', '달인 가입시, 인턴 활동이 제한됩니다.', 'error').then(async (result) => {
            if (result) {
              await utils.http.post(`/v2/SP_m_UserAuthChangeV2_i1`, null, { auth: '1' }).then((res) => {
                if (res.data.success) {
                  utils.dialog.alert('달인 가입 완료', '달인 가입이 완료되었습니다.', 'success');
                  flag = true;
                } else {
                  flag = false;
                }
              });
            } else {
              flag = false;
            }
          });
          if (flag) {
            if (isChecked) {
              utils.user.setAutoLogin(userID, userPW);
            } else {
              utils.user.clearAutoLogin();
            }
            const pushToken = await utils.push.token();
            await this.setPushToken(pushToken);
            window.location.href = '/manage/info/partnerInfo';
            return flag;
          }
          return flag;
        }

        if (isChecked) {
          utils.user.setAutoLogin(userID, userPW);
        } else {
          utils.user.clearAutoLogin();
        }

        const pushToken = await utils.push.token();
        await this.setPushToken(pushToken);

        return true;
      }
      // 로그인에 실패했을 경우
      else {
        await utils.dialog.alert('로그인 실패!', '로그인에 실패하였습니다.<br>입력한 정보를 다시 확인해주세요.', 'error');
        return false;
      }
    } catch (error) {
      await utils.dialog.alert('로그인 실패!', '오류가 발생했습니다. 관리자에게 문의해주세요.', 'error');
      return false;
    }
  },
  update(key, data) {
    utils.storage.session('user', Object.assign({}, utils.storage.session('user'), { [key]: data }));
  },
  isLogin() {
    return utils.isNotEmpty(user('id'));
  },

  async initLoginCheck() {
    if (this.isLogin()) {
      if (utils.isNotEmpty(user('refreshToken'))) {
        return await this.refreshToken();
      } else {
        return false;
      }
    } else return false;
  },
  async refreshToken() {
    if (this.isLogin() && utils.isNotEmpty(user('refreshToken'))) {
      let res = await utils.http.refreshToken(user('refreshToken'));
      if (res.data.success && res.data?.data[0] && user('id') === res.data?.data[0].user_id) {
        this.update('token', res.data.data[0].userToken);
        this.update('refreshToken', res.data.data[0].refreshToken);
        this.update('accessIssue', new Date());

        //리프레시 이후 fcm 업데이트
        utils.push.token().then((token) => {
          utils.user.setPushToken(token);
        });

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  logout() {
    const url = '/v2/SP_m_logoutLogV2_i1';
    const data = {
      userDevice: 'web',
    };

    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      console.log('로그아웃', res);
    });

    utils.storage.session('user', null);
    window.location.href = '/login';
    if (this.isAutoLogin()) this.clearAutoLogin();
  },
  requestLogout() {
    utils.dialog.confirm('로그아웃 하시겠습니까?', '', 'question').then((res) => {
      if (res) {
        this.logout();
      }
    });
  },
  setAutoLogin(id, pass) {
    utils.storage.local('autoLogin', crypto.encryptObject({ id, pass }));
  },
  isAutoLogin() {
    return utils.isNotEmpty(utils.storage.local('autoLogin'));
  },
  storedInfo(kind) {
    return crypto.decryptObject(utils.storage.local('autoLogin'))[kind];
  },
  clearAutoLogin() {
    utils.storage.local('autoLogin', null);
  },
  accessTimeText() {
    return moment(user('accessTime')).format('YYYY.MM.DD (dddd) a h:mm:ss').replace('요일', '');
  },
  async setPushToken(pushToken) {
    utils.storage.local('pToken', pushToken);

    //로그인 로그 남기기
    utils.http.setMode('api');

    return await utils.http.post(`/v1/SP_m_loginLog_i1`, null, {
      userDevice: 'web',
      userToken: pushToken,
    });
  },
  getPushToken() {
    return utils.storage.local('pToken');
  },
});

Object.freeze(user);
export default user;

import React, { useState, useEffect } from 'react';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import { Container, TopSection, SaveBtn } from '../../InfoElements';
import TabMenu from 'components/Parts/Common/TabMenu/TabMenu';
import TabProfile from 'components/Manage/Info/InfoList/Partner/Profile/Profile';
import TabCategory from 'components/Manage/Info/InfoList/Partner/Category/Category';
import TabSales from 'components/Manage/Info/InfoList/Partner/Sales/Sales';
import TabPromotion from 'components/Manage/Info/InfoList/Partner/Promotion/Promotion';
import TabReference from 'components/Manage/Info/InfoList/Partner/Reference/Reference';
import TabReservation from 'components/Manage/Info/InfoList/Partner/Reservation/Reservation';
import { useParams } from 'react-router-dom';
import Icon from 'components/Parts/Common/Icon';
import utils from 'modules/utils';
import { useMediaQuery } from 'react-responsive';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';

const PartnerList = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const { info } = useParams();
  const [isCommunicating, setIsCommunicating] = useState(false);

  const tabList = {
    profile: '프로필',
    category: '카테고리',
    salesInfo: '영업정보',
    promotion: '홍보사진',
    reference: '참고사진',
    // reservation: '예약설정',
  };

  let infoKind = info ?? 'profile';
  const menuInfo = '달인의 정보를 입력하여 우리 업체를 홍보할 수 있습니다. 프로필 ~ 참고사진까지 버튼을 눌러 내용을 입력해 주세요.';
  const modalStyle = {
    width: '473px',
    height: '135px',
  };

  const [partnerInfo, setPartnerInfo] = useState([]);
  const getInfo = async () => {
    try {
      const url = `/v1/SP_p_partCompInfo_S1`;
      utils.http.setMode('api');
      const res = await utils.http.get(url);
      if (res.data.data && res.data.success) {
        setPartnerInfo(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [save, setSave] = useState([]);

  const handleSave = () => {
    if (isCommunicating) return;
    setIsCommunicating(true);
    utils.isFunction(save?.onSave) && save.onSave(getInfo).then(() => setIsCommunicating(false));
  };

  useEffect(() => {
    getInfo();
  }, []);

  let TabSection = '';
  switch (infoKind) {
    case 'profile':
      TabSection = <TabProfile partnerInfo={partnerInfo} setSave={setSave} />;
      break;
    case 'category':
      TabSection = <TabCategory setSave={setSave} partnerInfo={partnerInfo} setPartnerInfo={setPartnerInfo} />;
      break;
    case 'salesInfo':
      TabSection = <TabSales setSave={setSave} />;
      break;
    case 'promotion':
      TabSection = <TabPromotion setSave={setSave} />;
      break;
    case 'reference':
      TabSection = <TabReference setSave={setSave} />;
      break;
    // case 'reservation':
    //   TabSection = <TabReservation setSave={setSave} />;
    //   break;
    default:
      break;
  }
  return (
    <>
      <TopSection>
        <SmallInfoModal menu="달인정보" infoModalData={menuInfo} style={modalStyle} />
        <SaveBtn type="button" onClick={handleSave}>
          <BookmarkAddOutlinedIcon />
          저장
        </SaveBtn>
      </TopSection>

      <Container>
        <TabMenu tabList={tabList} selected={infoKind} size={isTablet ? 'small' : 'big'} path="/manage/info/partnerInfo/" />
        {TabSection}
      </Container>
    </>
  );
};

export default PartnerList;

import React, { useState, useEffect } from 'react';
import { ContentWrap } from 'components/Manage/Info/InfoElements';
import InfoForm from 'components/Parts/Common/forms/InfoForm/InfoForm';
import utils from 'modules/utils';
import { SaveText } from './../../../InfoElements';
import { TitleBox, TitleText } from './../Category/CategoryElements';

const TabSales = ({ setSave }) => {
  const inputLists = [
    {
      id: 'input-li1',
      title: '업체명',
      placeholder: '업체명 입력 ex) 수리의 달인',
    },
    {
      id: 'input-li2',
      title: '한줄소개',
      placeholder: `20글자 이내로 작성해 주세요.\nex) 고객님의 요청에 따라 꼼꼼하게 작업해 드립니다.`,
    },
    {
      id: 'input-li3',
      title: '#해시태그',
      placeholder: '해시태그 입력 ex) #인테리어, #인테리어 달인, #꼼꼼 시공...',
    },
    {
      id: 'input-li4',
      title: '주소',
      placeholder: ['우편번호 입력', '주소 입력', '상세 주소 입력'],
    },
    // {
    //   id: 'input-li5',
    //   title: '수리지역',
    //   placeholder: '수리 가능 지역을 입력해 주세요. ex) 대구 지역! 어디든 갑니다!',
    // },
    {
      id: 'input-li6',
      title: '운영시간',
      placeholder: '20글자 이내로 작성해 주세요. ex) 평일 오전 9:00 ~ 오후 6:00',
    },
  ];
  const [inputVal, setInputVal] = useState({
    partName: '',
    partIntroduce: '',
    partHash: '',
    addr1: '',
    addr2: '',
    addr3: '',
    partArea: '',
    partTime: '',
    partCareer: '',
    partManCount: '',
  });
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const getPartnerInfo = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_p_partner_S1/${utils.user('id')}`);
      if (res.data.data && res.data.data.length > 0) {
        //console.log(res.data);
        setInputVal({
          ...inputVal,
          partName: res.data.data[0].partName || '',
          partIntroduce: res.data.data[0].partIntroduce || '',
          partHash: res.data.data[0].partHashtag || '',
          addr1: res.data.data[0].partAddr1 || '',
          addr2: res.data.data[0].partAddr2 || '',
          addr3: res.data.data[0].partAddr3 || '',
          partArea: res.data.data[0].partArea || '',
          partTime: res.data.data[0].partOperationTime || '',
          partCareer: res.data.data[0].partCareer || '',
          partManCount: res.data.data[0].partManCount || '',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // 저장 버튼 클릭했을 때 api 요청
  const onSave = async () => {
    console.log('onSave', inputVal);

    // 정규식 표현하기 모두 입력했는지 입력 없으면 utils.dialog.alert 띄우기
    if (utils.isEmpty(inputVal.partName)) {
      utils.dialog.alert('업체명 입력!', '업체명을 입력해 주세요.', 'warning');
      return;
    }
    if (utils.isEmpty(inputVal.partIntroduce)) {
      utils.dialog.alert('한줄소개 입력!', '한줄소개를 입력해 주세요.', 'warning');
      return;
    }
    if (utils.isEmpty(inputVal.partHash)) {
      utils.dialog.alert('해시태그 입력!', '해시태그를 입력해 주세요.', 'warning');
      return;
    }
    if (utils.isEmpty(inputVal.addr1)) {
      utils.dialog.alert('주소 입력!', '주소를 입력해 주세요.', 'warning');
      return;
    }
    if (utils.isEmpty(inputVal.addr2)) {
      utils.dialog.alert('주소 입력!', '주소를 입력해 주세요.', 'warning');
      return;
    }
    if (utils.isEmpty(inputVal.addr3)) {
      utils.dialog.alert('주소 입력!', '주소를 입력해 주세요.', 'warning');
      return;
    }
    // if (utils.isEmpty(inputVal.partArea)) {
    //   utils.dialog.alert('수리지역 입력!', '수리지역을 입력해 주세요.', 'warning');
    //   return;
    // }
    if (utils.isEmpty(inputVal.partTime)) {
      utils.dialog.alert('운영시간 입력!', '운영시간을 입력해 주세요.', 'warning');
      return;
    }
    if (utils.isEmpty(inputVal.partCareer)) {
      utils.dialog.alert('경력 입력!', '경력을 입력해 주세요.', 'warning');
      return;
    }
    if (utils.isEmpty(inputVal.partManCount)) {
      utils.dialog.alert('인원 입력!', '인원을 입력해 주세요.', 'warning');
      return;
    }
    try {
      const data = {
        addr1: inputVal.addr1,
        addr2: inputVal.addr2,
        addr3: inputVal.addr3,
        partArea: inputVal?.partArea ?? '',
        partCareer: inputVal.partCareer,
        partHash: inputVal.partHash,
        partIntroduce: inputVal.partIntroduce,
        partManCount: inputVal.partManCount,
        partName: inputVal.partName?.trim(),
        partTime: inputVal.partTime?.trim(),
      };
      utils.http.setMode('api');
      const res = await utils.http.post('/v1/SP_p_partCompInfo_wi3', null, data);
      if (res.data.success && res.data.message === '성공') {
        utils.dialog.alert('저장 완료!', '입력하신 내용이 저장되었습니다.', 'success');
      } else {
        utils.dialog.alert('저장 실패!', res.data.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPartnerInfo();
  }, []);
  useEffect(() => {
    if (setSave) {
      setSave({ onSave });
    }
  }, [inputVal]);

  return (
    <ContentWrap>
      <TitleBox>
        <TitleText>영업정보</TitleText>
      </TitleBox>
      <InfoForm
        inputLists={inputLists}
        inputVal={inputVal}
        setInputVal={setInputVal}
        inputTime={{
          startTime,
          setStartTime,
          endTime,
          setEndTime,
        }}
      />
      <SaveText>
        <div>※ 정보 입력 및 수정 시, 저장버튼을 눌러주세요</div>
      </SaveText>
    </ContentWrap>
  );
};

export default TabSales;

import { Box, Button, styled } from '@mui/material';

export const MoreBtn = styled(Button)(() => ({
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  gap: '10px'
}));
export const TicketBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  padding: '30px 20px 10px 20px',
  borderRadius: '10px',
  cursor: 'pointer',
  color: '#000',
  fontWeight: 'bold',
  position: 'relative',
  backgroundColor: '#fff',
  border: '3px solid #fff',
  '&:hover': {
    position: 'relative',
    border: '3px solid var(--primary-color)',
    opacity: 1
  }
}));
export const TicketTitle = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px'
}));
export const PrimaryColorBox = styled(Box)(() => ({
  color: 'var(--primary-color)'
}));
export const TicketFooter = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px'
}));
export const TicketIcon = styled(Box)(() => ({
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  fontWeight: 'bold',
  fontSize: '24px'
}));
export const TicketDiscount = styled(Box)(() => ({
  display: 'flex',
  width: '100px',
  backgroundColor: 'var(--primary-color)',
  color: '#fff',
  fontSize: '14px',
  justifyContent: 'center',
  borderRadius: '5px'
}));
export const MainTitle = styled(Box)(() => ({
  color: '#fff',
  fontSize: '20px',
  fontWeight: 'bold'
}));
export const SubTitle = styled(Box)(() => ({
  color: '#999999',
  fontSize: '16px'
}));

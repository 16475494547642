import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Review from './Review/Review';
import { Base } from '../FrameElements';
import TabChat from './WorkList/Tabs/Chat';
import TabIntern from './WorkList/Tabs/Intern';
import EstimateList from './EstimateList/EstimateMain';
import SoodalBoard from './SoodalBoard/SoodalBoard';
import AutoEstimateList from './AutoEstimateList/AutoEstimateList';
import EmergencyMain from './EmergencyList/EmergencyMain';

const History = () => {
  return (
    <Base>
      <Routes>
        <Route path="estimateList/:kind" element={<EstimateList />} />
        <Route path="estimateList/:kind/:workDate/:sqenNumb" element={<EstimateList />} />
        <Route path="emergencyList/:kind" element={<EmergencyMain />} />
        <Route path="emergencyList/:kind/:workDate/:sqenNumb" element={<EmergencyMain />} />
        <Route path="chat" element={<TabChat />} />
        <Route path="chat/:workDate/:sqenNumb" element={<TabChat />} />
        <Route path="intern" element={<TabIntern />} />
        <Route path="intern/:internId" element={<TabIntern />} />
        <Route path="intern/:workDate/:sqenNumb" element={<TabIntern />} />
        <Route path="soodalBoard" element={<SoodalBoard />} />
        <Route path="review" element={<Review />} />
        <Route path="autoEstimateList" element={<AutoEstimateList />} />
        <Route path="autoEstimateList/:workDate/:sqenNumb" element={<AutoEstimateList />} />
      </Routes>
    </Base>
  );
};

export default History;

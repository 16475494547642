import React, { useState, useEffect } from 'react';
import ImageUpload from './ImageUpload';
import { ContentWrap, SaveText } from 'components/Manage/Info/InfoElements';
import { styled } from 'styled-components';
import { WhiteDot } from 'style';
import utils from 'modules/utils';
import { imageToImageArr } from 'modules/Upload/imageUpload';

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 22px auto 45px;
  gap: 30px;
  .image-box-scroll {
    margin-right: -15px;
    max-height: calc(max(525px, calc(100vh - 360px)) - 187px);
    overflow-y: auto;
    /* 스크롤바의 폭 너비 */
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      transition: all 0.3s;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eee; /* 스크롤바 색상 */
      border-radius: 5px; /* 스크롤바 둥근 테두리 */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #8a8a8a;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
`;

const StyleDiv = styled.div`
  font-size: 20px;
  line-height: 1;
  font-weight: normal;
  color: #e9e9e9;

  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

const TabReference = ({ setSave }) => {
  const [images, setImages] = useState([]);
  const [imgDelKey, setImgDelKey] = useState([]);

  const getImages = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_p_partCompInfo_S2/${utils.user('id')}`);
      console.log('res.data.data', res.data.data);
      if (res.data.data && res.data.data.length > 0) {
        let images = { partnerPortfolio: [] };
        for (let kind in images) {
          images[kind] = res.data.data.filter((obj) => obj.dir === kind).slice(0, 30);
        }
        setImages(imageToImageArr(images.partnerPortfolio));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 저장 버튼 클릭했을 때 api 요청
  const onSave = async () => {
    try {
      const formData = new FormData();
      images.forEach((image) => {
        if (image.localFlag) {
          formData.append('files', image.file);
        }
      });

      let delKey = '';
      imgDelKey.forEach((image) => {
        delKey += image.serverFileName + '_//';
      });
      formData.append('delKey', delKey);

      utils.http.setMode('api');
      utils.http.postFile('/v1/SP_p_partCompInfo_wi4', formData).then((res) => {
        if (res.status === 200) {
          utils.dialog.alert('저장 완료!', '입력하신 내용이 저장되었습니다.<br />주기적으로 관리하여<br /> 우리 업체를 홍보해요!', 'success');
        } else {
          utils.dialog.alert('저장 실패!', '오류가 발생했습니다. 관리자에게 문의해 주세요.', 'error');
        }
        setImages([]);
        setImgDelKey([]);
        getImages();
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (setSave) {
      setSave({ onSave });
    }
    //console.log("reference", imgDelKey);
  }, [images, imgDelKey]);

  useEffect(() => {
    getImages();
  }, []);

  return (
    <ContentWrap className={'reference-image-box'}>
      <Div>
        <ImageUpload title="참고사진" tabMenu="reference" setImgDelKey={setImgDelKey} setImages={setImages} images={images} kind="partnerPortfolio" maxImage={30} />
        <div>
          <StyleDiv>
            <WhiteDot style={{ margin: '0 5px 5px' }} />
            이미지: 20MB이하의 JPG, JPEG, PNG 파일 등록 가능
          </StyleDiv>
          <StyleDiv>
            <WhiteDot style={{ margin: '0 5px 5px' }} />
            최대 30개 업로드 가능
          </StyleDiv>
        </div>
      </Div>
      <SaveText>
        <div>※ 정보 입력 및 수정 시, 저장버튼을 눌러주세요</div>
      </SaveText>
    </ContentWrap>
  );
};

export default TabReference;

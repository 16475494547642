import React, { useState, useEffect } from 'react';
import { Content, ContentHeader, ContentTitle, ContentMain, Post, MainText, Line, ContentBottom } from './QuestionListElements';
import utils from 'modules/utils';
import ReportBlockButtons from '../../../Parts/Common/ReportBlockButtons';
import ProfileImage from 'components/Parts/Common/ProfileImage';
import { styled } from 'styled-components';
import { commentStore } from 'modules/store/store';
import { useMediaQuery } from 'react-responsive';
import { Chip } from '@mui/material';
import Dalin from './../../../../assets/comment/dalin.png';

export const CommentBtn = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const CommentNick = styled.div`
  font-weight: bold;
  font-size: 16px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const CommentAnswer = styled.div`
  font-size: 16px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const CommentFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  color: #8a8a8a;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const EditDivider = styled.div`
  font-size: 16px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const EditDeleteBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-size: 20px;
  color: #8a8a8a;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

const DalinIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const dalinChipStyle = {
  backgroundColor: 'var(--primary-color)',
  color: '#fff',
  fontSize: '16px',
  padding: '4px',
  borderRadius: '4px',
};

const DetailFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const QuestionContent = ({ questionView, refreshList }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const [detailData, setDetailData] = useState({});
  const [detailData2, setDetailData2] = useState([]);
  const [detailDataImage, setDetailDataImage] = useState([]);
  const { setCommentParent } = commentStore();
  const userId = utils.user('id');

  // 질문해요 상세 보기 조회
  const getDetailQuestion = () => {
    const url = `/v1/SP_c_questionItem_S1/${questionView.workDate}/${questionView.sqenNumb}/0`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data && res.data.data) {
          setDetailData(Object.assign({}, questionView, res.data.data[0]));
        } else {
          setDetailData({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDetailQuestion2 = () => {
    const url = `/v1/SP_c_questionItem_S2/${questionView.workDate}/${questionView.sqenNumb}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data && res.data.data) {
          setDetailData2(res.data.data);
        } else {
          setDetailData2({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDetailQuestionImage = () => {
    const url = `/v1/SP_c_questionItem_S3/${questionView.workDate}/${questionView.sqenNumb}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data && res.data.data) {
          console.log('질문해요 상세보기3 : ', res.data.data);
          setDetailDataImage(res.data.data);
        } else {
          setDetailDataImage([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const imgURL = process.env.REACT_APP_IMAGE_VIEW_URL;

  // 댓글 삭제 버튼 클릭 했을 때 api로 삭제 요청
  const deleteComment = async (comment) => {
    utils.dialog.confirm('댓글을 삭제하시겠습니까?', '삭제 후 복구가 불가능합니다.', 'question').then(async (res) => {
      if (res) {
        try {
          const url = `/v1/SP_c_questionItem_d1`;
          const data = {
            workDate: comment.workDate,
            sqenNumb: comment.sqenNumb,
            headNumb: comment.headNumb,
          };
          utils.http.setMode('api');
          const res = await utils.http.post(url, null, data);
          if (res.data.success) {
            utils.dialog.alert('삭제 완료!', '댓글 삭제가 완료되었습니다.', 'success');
            refreshList();
          } else {
            utils.dialog.alert('삭제 실패!', res.data.message, 'error');
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  // 게시글 삭제 버튼 클릭 했을 때 api로 삭제 요청
  const deleteQuestion = async () => {
    try {
      const url = `/v1/SP_c_question_D1`;
      const data = {
        workDate: questionView.workDate,
        sqenNumb: questionView.sqenNumb,
      };
      utils.http.setMode('api');
      const res = await utils.http.post(url, null, data);
      if (res.data.success) {
        utils.dialog.alert('삭제 완료!', '삭제가 완료되었습니다.', 'success');
      } else {
        utils.dialog.alert('삭제 실패!', res.data.message, 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDetail = async () => {
    if (await utils.dialog.confirm('정말 삭제하시겠습니까?', '', 'question')) {
      await deleteQuestion();
      refreshList();
    }
  };

  //대댓글달기
  const handleAddComment = (item) => {
    console.log('item : ', item);
    setCommentParent(item);
  };

  useEffect(() => {
    // isFileName이 true인 경우에만 이미지 조회
    getDetailQuestion();
    getDetailQuestion2();
    getDetailQuestionImage();
  }, [questionView]);

  return (
    <>
      <Content>
        <ContentHeader>
          <div>
            <ContentTitle>{detailData.quesSubject}</ContentTitle>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <ProfileImage partProfile={1} width={isTablet ? '20px' : '30px'} height={isTablet ? '20px' : '30px'} kind={detailData.userGubn} id={detailData.userId} alwaysRefresh={true} />
                <div>{detailData.userNick}</div>
              </div>
              <div>{detailData.dateValue}</div>
            </div>
          </div>
        </ContentHeader>
        <ContentMain>
          <Post>
            <MainText>{detailData.quesContent}</MainText>
            {/* 상세 이미지 */}
            <div>{detailDataImage.length > 0 && detailDataImage.map((item, index) => <img key={`detailImage${index}`} src={`${imgURL}${item.fileName}`} alt="" style={{ maxWidth: '100%' }} />)}</div>
            <DetailFooter>
              <div style={{ display: 'flex', gap: '8px', color: '#8a8a8a', fontSize: '20px' }}>
                <span>댓글 {detailData.quesComment ?? 0}</span> <span>조회수 {detailData.quesHit ?? 0}</span>{' '}
              </div>
              {userId === detailData.userId ? (
                detailData.userGubn === 'partner' && (
                  <EditDeleteBtn>
                    <CommentBtn onClick={deleteDetail}>삭제</CommentBtn>
                  </EditDeleteBtn>
                )
              ) : (
                <ReportBlockButtons
                  gubn={'QuestionItem'}
                  userId={detailData.userId}
                  sqenNumb={detailData.sqenNumb}
                  workDate={detailData.workDate}
                  headNumb={''}
                  userNick={detailData.userNick}
                  conTent={detailData.quesComment}
                  quesHit={detailData.quesHit}
                  afterAction={() => {
                    refreshList();
                  }}
                />
              )}
            </DetailFooter>
            <Line />
            <ContentBottom
              style={{
                paddingBottom: '100px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              {detailData2.length > 0 &&
                detailData2.map((item, index) => (
                  <div
                    key={`detailData2${index}`}
                    style={{
                      display: 'flex',
                      gap: '10px',
                      marginLeft: item.parentNumb !== '' ? '48px' : '0px',
                    }}
                  >
                    <div>
                      <ProfileImage partProfile={1} width={isTablet ? '24px' : '40px'} height={isTablet ? '24px' : '40px'} kind={item.userGubn} id={item.userId} shape={'circle'} alwaysRefresh={true} />
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                        }}
                      >
                        <CommentNick>{item.userNick}</CommentNick>
                        {item.userGubn === 'partner' && <Chip icon={<DalinIcon src={Dalin} alt="달인" />} label="달인" style={dalinChipStyle} size="small" />}
                      </div>
                      <CommentAnswer>{item.block === '1' ? '차단된 사용자 입니다.' : item.declaration === '1' ? '신고된 댓글입니다.' : item.quesAnswer}</CommentAnswer>
                      <CommentFooter>
                        <div>{item.dateValue}</div>
                        <span>•</span>
                        <CommentBtn onClick={() => handleAddComment(item)}>댓글달기</CommentBtn>
                        <span>{userId === item.userId && item?.userGubn === 'user' ? '' : '•'}</span>
                        <div>
                          {userId === item.userId ? (
                            item?.userGubn === 'partner' && <CommentBtn onClick={() => deleteComment(item)}>삭제</CommentBtn>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <ReportBlockButtons
                                small={true}
                                gubn={'QuestionItem2'}
                                userId={item.userId}
                                sqenNumb={item.sqenNumb}
                                workDate={item.workDate}
                                headNumb={item.headNumb}
                                partNumb={item?.partNumb ?? ''}
                                userNick={item.userNick}
                                afterAction={() => {
                                  refreshList();
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </CommentFooter>
                    </div>
                  </div>
                ))}
            </ContentBottom>
          </Post>
        </ContentMain>
      </Content>
    </>
  );
};

export default QuestionContent;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Wrapper } from '../../style';
import { Content, LogoWrap, Form, FormInput, Options, CheckBoxWrap, DivideBox, ForgotBtn, ForgotBtnLink, BtnContainer, LoginButton, RegisterButton, Image } from './LoginElements';
import logo from 'assets/뉴수달로고.png';
import character from 'assets/form/soodal_login.png';
import SingleCheckBox from '../Parts/Common/forms/CheckBox/SingleCheckBox';
import Modal from './Modal/AuthModal';
import 'moment/locale/ko';
import utils from 'modules/utils';
import { useMediaQuery } from 'react-responsive';
import KakaoLogin from 'react-kakao-login';
import axios from 'axios';
import { useEffect } from 'react';

const JAVASCRIPT_KEY = process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY;
const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
const NAVER_REDIRECT_URI = process.env.REACT_APP_NAVER_REDIRECT_URI;

const { naver } = window;

const Login = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  // 아이디, 패스워드 state
  const [userID, setUserID] = useState('');
  const [userPW, setUserPW] = useState('');

  const onChangeUserID = (e) => {
    setUserID(e.target.value);
  };
  const onChangeUserPW = (e) => {
    setUserPW(e.target.value);
  };

  // modal state
  const [modalOpen, setModalOpen] = useState(false);
  // modal 열기/닫기
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  // modal 모드 state
  const [modalMode, setModalMode] = useState('register');

  // 페이지 이동을 위해 useNavigate() 사용
  const navigate = useNavigate();

  // 로그인상태 유지하기 체크박스
  const [isChecked, setIsChecked] = useState(false);
  const onClickRemember = () => {
    setIsChecked(!isChecked);
  };

  /*================== 로그인 버튼 클릭 ==================*/
  const onSubmit = async (e) => {
    e.preventDefault();

    if (utils.isEmpty(userID)) {
      utils.dialog.alert('아이디 입력', '아이디를 입력해주세요.', 'warning');
    } else if (utils.isEmpty(userPW)) {
      utils.dialog.alert('비밀번호 입력', '비밀번호를 입력해주세요.', 'warning');
    } else if (await utils.user.login(userID, userPW, isChecked)) {
      isMobile ? navigate('/manage/ticket/ticketList') : navigate('/manage/home');
    }
  };

  //---------------- 네이버 로그인 ----------------//
  // const initializeNaverLogin = () => {
  //   const naverLogin = new naver.LoginWithNaverId({
  //     clientId: NAVER_CLIENT_ID,
  //     callbackUrl: NAVER_REDIRECT_URI,
  //     isPopup: true, // popup 형식으로 띄울것인지 설정
  //     loginButton: { color: 'white', type: 1, height: '47' }, //버튼의 스타일, 타입, 크기를 지정
  //   });
  //   naverLogin.init();
  //   try {
  //     naverLogin.getLoginStatus((status) => {
  //       if (status) {
  //         console.log(naverLogin.user);
  //       }
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  //---------------- 카카오 로그인 ----------------//
  // const kakaoOnSuccess = async (data) => {
  //   const response = data.response; // 엑세스 토큰 백엔드로 전달
  //   getKakaoUserInfo(response);
  // };

  // const kakaoOnFailure = (error) => {
  //   console.log(error);
  // };

  // const getKakaoUserInfo = async (response) => {
  //   const url = 'https://kapi.kakao.com/v2/user/me';
  //   const header = {
  //     'Content-type': 'Content-type: application/x-www-form-urlencoded;charset=utf-8',
  //     Authorization: `Bearer ${response.access_token}`,
  //   };

  //   axios.get(url, { headers: header }).then((res) => {
  //     console.log('카카오 로그인 성공', res.data);
  //   });
  // };

  // useEffect(() => {
  //   initializeNaverLogin();
  // }, []);

  return (
    <Container>
      <Wrapper isMobile={isMobile}>
        {!isMobile && (
          <div
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '30px',
              color: '#8a8a8a',
              fontSize: '18px',
            }}
          >
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <div id="naverIdLogin" />
              <KakaoLogin
                token={JAVASCRIPT_KEY}
                onSuccess={kakaoOnSuccess}
                onFail={kakaoOnFailure}
                render={({ onClick }) => (
                  <button
                    style={{ color: '#ffeb00' }}
                    onClick={(e) => {
                      onClick();
                    }}
                  >
                    카카오
                  </button>
                )}
              />
              <button style={{ color: '#fff' }}>구글</button>
              <button style={{ color: '#fff' }}>애플</button>
            </div> */}
            <div>이 사이트는 달인 전용 프로그램입니다.</div>
            <div>고객센터 1566-4921 (상담시간:평일 10:00~18:00)</div>
          </div>
        )}
        <Content>
          {modalOpen ? (
            <Modal open={modalOpen} close={closeModal} mode={modalMode}>
              {modalMode === 'register' ? '회원가입을 위해 본인 인증이 필요합니다.' : modalMode === 'findId' ? '아이디 찾는 방법을 선택해 주세요.' : '비밀번호 찾는 방법을 선택해 주세요.'}
            </Modal>
          ) : (
            <>
              <Form
                name="loginForm"
                style={
                  isMobile
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: '#10141c',
                      }
                    : {}
                }
              >
                <LogoWrap>
                  <img
                    src={logo}
                    alt=""
                    style={{
                      width: isMobile ? '180px' : '260px',
                    }}
                  />
                </LogoWrap>
                <FormInput type="text" placeholder="아이디 입력" required value={userID} onChange={onChangeUserID} className="id-input" />
                <FormInput type="password" placeholder="비밀번호 입력" value={userPW} onChange={onChangeUserPW} className="password-input" required />
                <Options isMobile={isMobile}>
                  {!isMobile && (
                    <CheckBoxWrap>
                      <SingleCheckBox text="로그인 상태 유지" name="saveLoginState" checked={isChecked} onChangeHandle={onClickRemember} isChecked={isChecked} />
                    </CheckBoxWrap>
                  )}
                  <ForgotBtn>
                    <ForgotBtnLink
                      isMobile={isMobile}
                      onClick={() => {
                        setModalMode('findId');
                        openModal();
                      }}
                    >
                      아이디 찾기
                    </ForgotBtnLink>
                    <DivideBox isMobile={isMobile}>|</DivideBox>
                    <ForgotBtnLink
                      isMobile={isMobile}
                      onClick={() => {
                        setModalMode('findPw');
                        openModal();
                      }}
                    >
                      비밀번호 찾기
                    </ForgotBtnLink>
                  </ForgotBtn>
                </Options>
                <BtnContainer>
                  <LoginButton onClick={onSubmit}>로그인</LoginButton>
                  <RegisterButton
                    onClick={() => {
                      setModalMode('register');
                      openModal();
                    }}
                  >
                    회원가입
                  </RegisterButton>
                </BtnContainer>
                {isMobile && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                      position: 'absolute',
                      // 중앙 정렬
                      bottom: '20px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      color: '#8a8a8a',
                      fontSize: '12px',
                    }}
                  >
                    <div>이 사이트는 달인 전용 프로그램입니다.</div>
                    <div>고객센터 1566-4921 (상담시간:평일 10:00~18:00)</div>
                  </div>
                )}
              </Form>
            </>
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Login;

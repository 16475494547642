import styled from 'styled-components';
export const SearchBox = styled.section`
  width: 100%;
  padding: 0 15px;
  border-radius: 5px;
  border: solid 1px #8a8a8a;
  background-color: #fff;
  height: 40px;
  margin-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    height: 30px;
  }
`;
export const Input = styled.input`
  outline: none;
  color: #222;
  font-size: 18px;

  flex: 1 1 100%;

  &::placeholder {
    color: #8a8a8a;
  }
  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const SearchBtn = styled.button`
  background: transparent;
  cursor: pointer;
  height: 24px;
  flex: 0 0 24px;
  svg {
    font-size: 24px;
    color: #8a8a8a;
  }
`;

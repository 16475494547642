import { styled } from 'styled-components';
import basicProfile from 'assets/previewBasic.png';
import { useMediaQuery } from 'react-responsive';

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PreviewFrame = styled.div`
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '100%'};

  overflow: hidden;
  background-color: #fff;
  &.circle {
    border-radius: 50%;
  }
  &.rect {
    border-radius: 10px;
  }
  &.error {
    padding: 15%;
    img {
      object-fit: contain;
    }
  }
`;
const baseUrl = process.env.REACT_APP_IMAGE_VIEW_URL;

const PreviewImage = ({ image, width, height, shape }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  shape = shape ?? 'circle';
  return (
    <PreviewFrame className={`preview-image ${shape} ${image ? '' : 'error'}`} width={width} height={height}>
      {image ? (
        <Image
          src={`${baseUrl}${image}`}
          onError={(e) => {
            e.target.src = basicProfile;
            if (e.target.parentNode.className.includes('error') === false) {
              e.target.parentNode.className += ' error';
            }
          }}
          onLoad={(e) => {
            if (e.target.src.startsWith(baseUrl)) {
              e.target.parentNode.className = e.target.parentNode.className.replaceAll('error', '').trim();
            }
          }}
        />
      ) : (
        <Image src={basicProfile} />
      )}
    </PreviewFrame>
  );
};

export default PreviewImage;

import React from 'react';
import { Question, QuestionList, QuestionSubject, ArrowIcon, AnswerWrap, Answer, NoneResultWrap } from './FaqElements';
import EmptyBox from 'components/Parts/Common/EmptyBox';

const FaqTable = ({ allLists, searchResult, noneResult }) => {
  return (
    <>
      {/* 검색하지 않았을 때 */}
      {!noneResult && searchResult.length === 0 ? (
        <>
          {allLists.map((list, index) => (
            <Question bg={index % 2 === 0 ? '#212838' : '#37465b'} key={`faq-${list.faqId}:${list.faqOrder}`}>
              <QuestionList>
                <QuestionSubject>{list.faqSubject}</QuestionSubject>
                <ArrowIcon type="button" className="arrow-btn" onClick={(e) => e.target.parentNode.click()} />
              </QuestionList>
              <AnswerWrap>
                <Answer>{list.faqContent}</Answer>
              </AnswerWrap>
            </Question>
          ))}
        </>
      ) : (
        <>
          {/* 검색 결과가 존재할 경우 */}
          {!noneResult && searchResult.length > 0 ? (
            <>
              {searchResult.map((list, index) => (
                <Question key={`faq-${list.faqId}:${list.faqOrder}`}>
                  <QuestionList>
                    <QuestionSubject>{list.faqSubject}</QuestionSubject>
                    <ArrowIcon className="arrow-btn" onClick={(e) => e.target.parentNode.click()} />
                  </QuestionList>
                  <AnswerWrap>
                    <Answer>{list.faqContent}</Answer>
                  </AnswerWrap>
                </Question>
              ))}
            </>
          ) : (
            <>
              {/* 검색 결과가 존재하지 않을 경우 */}
              <NoneResultWrap>
                <EmptyBox>검색 결과가 존재하지 않습니다.</EmptyBox>
              </NoneResultWrap>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FaqTable;

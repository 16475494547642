import styled from 'styled-components';

export const BtnContainer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: #212838;
  display: flex;
  justify-content: stretch;
  overflow: hidden;
  margin-bottom: 14px;
`;

export const TabBtn = styled.button`
  background-color: transparent;
  height: 50px;
  transition: all 0.3s;
  border-bottom: 3px solid transparent;
  flex: 1 1 100%;
  font-weight: bold;
  font-size: 22px;
  color: ${(props) => props.btnstyle.color};

  &:hover {
    color: #5affe2;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    height: 40px;
    font-size: 18px;
  }
`;

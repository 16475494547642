import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { Box } from '@mui/material';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import AreaAndTicketModal from 'components/Parts/Common/Modal/AreaAndTicketModal';
import { partInfoStore, reservationPaymentStore } from 'modules/store/store';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Card, CardButton, CardContent, CardHeader, CardIcon, CardList, CardMain, CardTitle, CostPrice, Discount, Divider, Price, Title } from './TicketListElements';
export const ticketInfo = [
  {
    title: 'DIAMOND',
    icon: 'D',
    content1: '지역 설정',
    content2: '무제한',
    color: '#161377'
  },
  {
    title: 'PLATINUM',
    icon: 'P',
    content1: '지역 설정',
    content2: '25개',
    color: '#e01949'
  },
  {
    title: 'GOLD',
    icon: 'G',
    content1: '지역 설정',
    content2: '14개',
    color: '#fdc23e'
  },
  {
    title: 'SILVER',
    icon: 'S',
    content1: '지역 설정',
    content2: '7개',
    color: '#aeaeae'
  },
  {
    title: 'BRONZE',
    icon: 'B',
    content1: '지역 설정',
    content2: '4개',
    color: '#b29d91'
  },
  {
    title: 'IRON',
    icon: 'I',
    content1: '지역 설정',
    content2: '1개',
    color: '#10141c'
  }
];

const modalStyle = {
  width: '536px',
  height: '120px'
};
const infoModalData = (
  <>
    <div>달인께 맞는 구독권을 선택하여 구매 시 더 많은 수달 고객을 만날 수 있습니다.</div>
  </>
);

export const TicketListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    width: 80px;
    height: 30px;
    background-color: var(--mint-color);
    border-radius: 10px;
    border: none;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
`;
const CardRow = styled.div`
  color: #fff;
  padding: 2px 0px;
  //모바일
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const SubscribeButton = styled.button`
  width: 120px;
  height: 40px;
  background-color: var(--mint-color);
  border-radius: 5px;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
`;

const testUrl = process.env.REACT_APP_BACKEND_URL_HTTP;
export const postUrl = (url, obj) => {
  let postUrl = testUrl + url + '?';
  for (let key in obj) {
    postUrl += key + '=' + encodeURIComponent(obj[key]) + '&';
  }
  postUrl = postUrl.slice(0, -1);
  return postUrl;
};

/**
 * 구독 여부 확인
 */
export const checkSubscribed = async () => {
  const url = `/v1/SP_b_user_ticket_schedule_S4`;
  utils.http.setMode('api');
  const getData = await utils.http.get(url);
  return getData.data.data.length > 0;
};

const TicketList = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const navigate = useNavigate();
  const { partInfo, getPartInfo } = partInfoStore();

  const [chipData, setChipData] = useState([]);

  const { ticketList, getTicketList } = reservationPaymentStore();

  const [open, setOpen] = useState(false);

  const handleShowPaymentModal = () => {
    console.log('partInfo', partInfo);

    if (partInfo?.partLevel === 0) {
      utils.dialog.alert('승인대기!', '승인대기 상태입니다.</br> 필수 정보입력 후 승인이 필요합니다.', 'error');
      return;
    }
    if (partInfo?.partLevel === 1) {
      setOpen(true);
      return;
    }
    if (partInfo?.partLevel > 1) {
      utils.dialog.confirm('', '이미 구독권을 구독하셨습니다. </br> 구독내역을 확인하시겠습니까?', 'question', '', '구독내역 확인').then((res) => {
        if (res) return navigate('/manage/ticket/purchaseHistory');
      });
      return;
    }
  };

  useEffect(() => {
    getPartInfo();
    getTicketList();
  }, []);

  const firstTicketCheck = (partInfo, ticket) => {
    if (partInfo.partnerFirstTicket === 'Y') {
      return ticket.first_sale_price;
    } else {
      return ticket.sale_price;
    }
  };
  //
  return (
    <div
      style={
        isMobile
          ? {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }
          : {}
      }
    >
      <TicketListHeader>
        <SmallInfoModal menu="구독하기" style={modalStyle} infoModalData={infoModalData} />
        {isMobile && <button onClick={() => navigate('/manage/ticket/purchaseHistory')}>구독내역</button>}
      </TicketListHeader>

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center' }}>
        <Title>지역 설정 개수에 따라 구독권을 구매해보세요.</Title>
        {partInfo?.partLevel <= 1 && (
          <SubscribeButton onClick={handleShowPaymentModal}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', alignContent: 'center', gap: '10px' }}>
              <ConfirmationNumberOutlinedIcon />
              구독하기
            </Box>
          </SubscribeButton>
        )}
      </Box>
      <CardList>
        {ticketList.map((ticket) => {
          const ticketInfoDetail = ticketInfo.find((item) => {
            return item?.title === ticket?.level_name;
          });

          return (
            <CardMain key={ticket?.ticket_level} color={ticket?.color} className={ticket?.ticket_level === ticket?.part_level ? 'active' : ''}>
              <CardHeader>
                <CardTitle>
                  <CardIcon
                    style={{
                      backgroundColor: ticketInfoDetail.color,
                      borderColor: ticket?.ticket_level === ticket?.part_level ? 'var(--mint-color)' : ''
                    }}
                  >
                    {ticketInfoDetail.icon}
                  </CardIcon>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px'
                    }}
                  >
                    {ticket?.level_name}
                    <Box sx={{ fontSize: '16px', color: 'var(--mint-color)' }}>{ticket?.ticket_level === ticket?.part_level ? '· 구독중' : ''}</Box>
                  </Box>
                </CardTitle>
              </CardHeader>
              <Divider />
              <CardContent>{ticketInfoDetail.content1}</CardContent>
              <CardContent className="content2">{ticketInfoDetail.content2}</CardContent>
              {partInfo.partnerFirstTicket === 'N' ? (
                <CardButton className={ticket?.ticket_level === ticket?.part_level ? 'active' : ''}>
                  {partInfo.partnerFirstTicket === 'N' && ticket?.discount > 0 && <Discount>{ticket?.discount + '%↓'}</Discount>}
                  {partInfo.partnerFirstTicket === 'Y' && ticket?.first_discount > 0 && <Discount>{ticket?.first_discount + '%↓'}</Discount>}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '10px'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end'
                        }}
                      >
                        {ticket?.price !== firstTicketCheck(partInfo, ticket) && (
                          <CostPrice>
                            <p>{ticket?.price?.toLocaleString()}원</p>
                            <Divider className="content2" />
                            <ArrowDownwardIcon
                              sx={{
                                position: 'absolute',
                                top: '11px',
                                right: '-8px',
                                transform: 'rotate(15deg)',
                                fontSize: '20px'
                              }}
                            />
                          </CostPrice>
                        )}
                        <Price noDiscountPrice={ticket?.price !== firstTicketCheck(partInfo, ticket)}>{firstTicketCheck(partInfo, ticket)?.toLocaleString()}원</Price>
                      </Box>
                    </Box>
                  </Box>
                </CardButton>
              ) : (
                <CardButton className={ticket?.ticket_level === ticket?.part_level ? 'active' : ''}>
                  {partInfo.partnerFirstTicket === 'N' && ticket?.discount > 0 && <Discount>{ticket?.discount + '%↓'}</Discount>}
                  {partInfo.partnerFirstTicket === 'Y' && ticket?.first_discount > 0 && <Discount>{ticket?.first_discount + '%↓'}</Discount>}
                  <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', gap: '5px' }}>
                    {ticket?.first_discount > 0 && <Box sx={{ color: 'var(--mint-color)', fontSize: '14px' }}>첫결제 이벤트</Box>}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '10px'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                          }}
                        >
                          {ticket?.price !== firstTicketCheck(partInfo, ticket) && (
                            <CostPrice>
                              <p>{ticket?.price?.toLocaleString()}원</p>
                              <Divider className="content2" />
                              <ArrowForwardIcon
                                sx={{
                                  position: 'absolute',
                                  // top: 0,
                                  right: '-5px',
                                  fontSize: '20px'
                                }}
                              />
                            </CostPrice>
                          )}
                          <Price noDiscountPrice={ticket?.price !== firstTicketCheck(partInfo, ticket)}>{firstTicketCheck(partInfo, ticket)?.toLocaleString()}원</Price>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardButton>
              )}
            </CardMain>
          );
        })}
      </CardList>
      <Card>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', gap: '4px', fontSize: '20px', fontWeight: 'bold' }}>
          전 구독권 <p>견적제출 무제한</p>
        </Box>
      </Card>
      <Card>
        <div style={{ fontSize: '22px', fontWeight: 'bold', marginBottom: '12px' }}>유의사항</div>
        <CardRow>- 브론즈, 실버, 골드, 플래티넘, 다이아몬드 구독권을 중복 구매하실 수 없습니다.</CardRow>
        <CardRow>- 모든 결제 금액에는 부가세(VAT)가 포함되어 있으며, 해외 원화 결제(DOC)에 따른 추가 수수료가 발생할 수 있습니다.</CardRow>
        <CardRow>- 미사용 상품은 유료 결제 후 7일 이내에 고객센터를 통해 이용 신청의 철회가 가능하며 7일이 경과한 이후에는 환불이 어렵습니다.</CardRow>
        <CardRow>- 자동결제 진행 시, 구독권은 매달 결제한 일자에 자동 결제됩니다.</CardRow>
        <CardRow>- 자동 결제해지는 결제 예정일 최소 24시간 전에 해지 신청해야 합니다. 결제 예정일 전 24시간 이내에는 자동 결제를 해지해도 구독권이 결제될 수 있습니다.</CardRow>
        <CardRow>- 자동 결제 예정일 전 24시간 이내에 결제가 이루어지며, 자동 결제 예정일과 실제 구독권 구매일/만료일 표기는 결제 시점 및 시차 등의 이유로 다르게 표기될 수 있습니다.</CardRow>
      </Card>
      <AreaAndTicketModal
        open={open}
        close={() => {
          setOpen(false);
        }}
        title="구독하기"
        chipData={chipData}
        setChipData={setChipData}
        reflashTicketList={getTicketList}
      />
    </div>
  );
};

export default TicketList;

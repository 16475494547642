import React from 'react';
import utils from 'modules/utils';
import { BtnWrap, Btn } from './FilterBtnElements';

const FilterBtn = ({ setSelect, select, list, style }) => {
  return (
    <BtnWrap style={style && style}>
      {list.map((item) => {
        return (
          <Btn
            key={`btn-${item.cd}`}
            onClick={() => {
              setSelect(~~item?.cd);
            }}
            bg_color={select === ~~item.cd ? '#7e6eff' : 'transparent'}
          >
            {item?.nm}
          </Btn>
        );
      })}
    </BtnWrap>
  );
};

export default FilterBtn;

import styled from 'styled-components';

export const TopSection = styled.section`
  width: 100%;
  height: 100px;
  border-radius: 20px;
  background-color: #37465b;
  margin-top: 30px;
  padding: 30px;
`;

export const Top = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TopLists = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 24% auto 24%;
  gap: 30px;
  grid-template-rows: 40px;
`;

export const ReqBtn = styled.button`
  width: 100%;
  color: #fff;
  border-radius: 5px;
  background-color: #7e6eff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #6959de;
  }
`;

export const Main = styled.main`
  height: calc(100% - 227px);
  display: flex;
  padding: 35px 30px 30px;
  margin-top: 30px;
  background-color: #37465b;
  border-radius: 20px;
`;

export const WithdrawReqBtn = styled.button`
  width: 170px;
  height: 40px;
  border-radius: 5px;
  background-color: #5affe2;
  color: #10141c;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  padding: 0px 12px 5px 12px;
`;

/* WithdrawManage */
export const Content = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 57px;
  display: flex;
  flex-direction: gap;
  gap: 30px;
`;

// Bill part
export const BillBox = styled.div`
  width: 45%;
  height: 100%;
  border-radius: 20px;
  background-color: #37465b;
  padding: 30px;
`;

export const Header = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 200px auto 154px;
  grid-template-rows: 50px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-align: center;

  .bill-header-item {
    background-color: #212838;
    border-right: 1px solid #37465b;
    padding: 6px 0;

    &:last-child {
      border: none;
    }
  }
`;

export const MainBox = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  border-radius: 10px;
  background-color: #212838;
  margin-top: 30px;
  overflow: hidden;
  .current-money {
    background-color: #456086;
  }

  .total-possible-money {
    background-color: #7e6eff;
  }
`;

export const DateBox = styled.div`
  width: 50%;
  .react-datepicker {
    width: 100%;
  }
`;

export const Lists = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 200px auto 154px;
  grid-template-rows: 62.5px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-bottom: solid 1px #37465b;
  &:last-child {
    border: none;
  }

  .icon {
    font-size: 22px;
    margin-top: 5px;
    margin-right: 5px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: solid 1px #37465b;
    &:last-child {
      border: none;
    }
  }

  .data-money {
    font-size: 22px;
    font-weight: normal;
    color: #fff;
  }
`;

// Details part
export const DetailBox = styled.div`
  width: 55%;
  border-radius: 20px;
  background-color: #37465b;
  padding: 29px 30px;
`;

export const SearchOption = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;

  .select-box {
    width: 165px;
    font-size: 20px;
    font-weight: normal;
    margin-right: 37px;
  }

  .select-date {
    min-width: 210px;
    width: auto;
    height: 40px;
  }
  .react-datepicker {
    transform: translateX(-50px);
    height: 100%;
  }

  .inquiry-btn {
    width: 91px;
    height: 100%;
    border-radius: 5px;
    background-color: #7e6eff;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const WithdrawListBox = styled.div`
  width: 100%;
  height: calc(100% - 191px);
  margin-top: 30px;
`;

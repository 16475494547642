import utils from 'modules/utils';
import styled from 'styled-components';
import WindowFrame from './WindowFrame';
//import Icon from "../Icon";
import { useEffect, useState } from 'react';

const ReportForm = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .info-comment {
    line-height: 1;
    font-size: 22px;
    color: #fff;
    &:nth-child(1) {
      margin-bottom: 31px;
      font-size: 24px;
    }
    &:nth-child(2) {
      margin-bottom: 28px;
    }
    &:nth-child(3) {
      margin-bottom: 14px;
    }
  }
  .custom-scroll-text-area {
    height: 150px;
    margin-bottom: 36px;
  }
  .modal-button {
    border-radius: 4px;
  }
`;

const UserReportModal = ({ open, close, title, gubn, head, part, sqen, work, nick, afterAction }) => {
  const [reportMsg, setReportMsg] = useState('');
  const userReport = async () => {
    if (reportMsg === '') {
      await utils.dialog.alert('신고 내용 없음', '신고 내용을 입력해주세요.', 'warning');
    } else if (await utils.dialog.confirm('신고하기', nick + '님을 신고하시겠습니까?', 'question', '', '신고')) {
      utils.http.setMode('api');
      let res = await utils.http.post('/v1/SP_a_declaration_I1', null, {
        gubn,
        head: head ?? '',
        part: part ?? '',
        sqen,
        work,
      });

      if (res.data?.success) {
      } else {
        await utils.dialog.alert('신고오류', '신고 중 오류가 발생하였습니다. <br/> 잠시 후 다시 시도해주세요.', 'error');
      }
      utils.isFunction(afterAction) && afterAction();
      utils.isFunction(close) && close();
    }
  };
  useEffect(() => {
    setReportMsg('');
  }, [open]);
  return (
    <WindowFrame open={open} close={close} title={title} width={'790px'} height={'480px'}>
      <ReportForm>
        <div className={'info-comment'}>신고 대상에 해당되는지 다시 한번 확인하여 주시기 바랍니다.</div>
        <div className={'info-comment'}>{/* 신고 대상 : {nick} */}</div>
        <div className={'info-comment'}>신고 내용</div>
        <div className={'custom-scroll-text-area'}>
          <textarea
            placeholder={'신고 내용을 입력해 주세요.'}
            onInput={(e) => {
              setReportMsg(e.target.value);
            }}
            value={reportMsg}
          />
        </div>

        <button type={'button'} className={'modal-button action'} onClick={userReport}>
          신고
        </button>
      </ReportForm>
    </WindowFrame>
  );
};

export default UserReportModal;

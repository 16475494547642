import React, { useEffect, useState } from 'react';
import { Nav, OptionBtn } from './ResultTopElements';
import { WtNomalSpan } from 'style';
import BtnIcon from 'assets/637.png';
import { ChildCare } from '@mui/icons-material';

const ResultTop = (props) => {
  const { total, orderList, setOrder, order, children } = props;
  const toggleBtn = () => {
    setOrder((order) => {
      let idx = orderList.indexOf(order) + 1;
      if (orderList.length === idx) idx = 0;

      return orderList[idx];
    });
  };

  useEffect(() => {}, [order]);
  return (
    <Nav>
      <WtNomalSpan>전체 {total}건</WtNomalSpan>
      {children}
      {/* {orderList && (
        <OptionBtn onClick={toggleBtn}>
          <img src={BtnIcon} alt="" />
          <WtNomalSpan>{order}</WtNomalSpan>
        </OptionBtn>
      )} */}
    </Nav>
  );
};

export default ResultTop;

import React, { useEffect, useState } from 'react';
import FaqTable from './FaqTable';
import { Banner, ContentWrap } from '../NewsElements';
import { RequsetTable, TableHeader, Label, Input, SearchBtn, TableBody } from './FaqElements';
import { Scrollbars } from 'react-custom-scrollbars-2';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { BdWhiteText } from 'style';
import bannerImg from 'assets/1400.png';
import searchIcon from 'assets/searchWhite.png';
import utils from 'modules/utils';
import { styled } from 'styled-components';

const InputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Faq = ({ refreshToggle }) => {
  // 검색 값 state
  const [searchValue, setSearchValue] = useState('');

  // 검색 결과 없을 시 보여줄 화면 state
  const [noneResult, setNoneResult] = useState(false);

  // faq 전체 목록 state
  const [faqAllLists, setFaqAllLists] = useState([]);
  const getAllFaqLists = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.post(`/v1/SP_m_faq_S1`, null, {
        category: '2',
        faq: '',
      });
      // console.log(res.data.data[0].faqContent);
      if (res.data.data) {
        setFaqLists(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // faq 검색 목록 state
  const [faqLists, setFaqLists] = useState([]);
  const getFaqLists = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.post(`/v1/SP_m_faq_S1`, null, {
        category: '2',
        faq: searchValue,
      });
      console.log(res.data);
      if (res.data.data) {
        if (res.data.data.length > 0) {
          setFaqLists(res.data.data);
          setNoneResult(false);
        } else {
          setNoneResult(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSearchEnter = (e) => {
    if (e.keyCode === 13) {
      getFaqLists();
    }
  };

  useEffect(() => {
    getAllFaqLists();
  }, []);

  useEffect(() => {
    setSearchValue('');
    getAllFaqLists();
  }, [refreshToggle]);

  return (
    <>
      <ContentWrap>
        <RequsetTable>
          <TableHeader>
            <BdWhiteText>평소 수달에 궁금했던 점을 자주 묻는 질문에서 확인해요.</BdWhiteText>
            <InputBox>
              <Label>
                <Input placeholder="어떤 점이 궁금하신가요?" type="text" id="FaqInput" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => onSearchEnter(e)} />
                <SearchBtn
                  type="button"
                  onClick={() => {
                    getFaqLists();
                  }}
                >
                  <img src={searchIcon} alt="" />
                </SearchBtn>
              </Label>
              <RestartAltIcon
                sx={{
                  fontSize: '28px',
                  cursor: 'pointer',
                  color: '#fff',
                }}
                onClick={() => {
                  setSearchValue('');
                  getAllFaqLists();
                }}
              />
            </InputBox>
          </TableHeader>
          <TableBody>
            <div className="qna-content-box">
              <Scrollbars className="scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
                <FaqTable allLists={faqAllLists} searchResult={faqLists} noneResult={noneResult} />
              </Scrollbars>
            </div>
          </TableBody>
        </RequsetTable>
      </ContentWrap>
    </>
  );
};

export default Faq;

import React, { useEffect, useRef, useState } from 'react';
import { BdWhiteText, WtNomalSpan } from 'style';
import { QnATable, TableHeader, TableBody, Detail, StatBtn, Summary, ArrowIcon, AnswerWrap, Content, ContentImage, Answer, NoneAnswer, DateBox, Wrap } from './QnAElements';
import EmptyBox from 'components/Parts/Common/EmptyBox';
import ImageViewModal from 'components/Parts/Common/Modal/ImageViewModal';
import { Scrollbars } from 'react-custom-scrollbars-2';
import utils from 'modules/utils';
import { qnaStore } from '../../../../modules/store/store';
import styled from 'styled-components';
import { async } from 'q';
import { useNavigate, useParams } from 'react-router-dom';

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ContentBody = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
`;
const DeleteBtn = styled.button`
  position: absolute;
  top: -10px;
  right: 0;
  width: 70px;
  height: 32px;
  border-radius: 20px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: #fff;
    color: #000;
  }

  @media screen and (max-width: 1024px) {
    width: 60px;
    height: 24px;
    font-size: 14px;
  }
`;

const imgURL = process.env.REACT_APP_IMAGE_VIEW_URL;
const QnA = () => {
  const QnABox = useRef();
  const navigate = useNavigate();
  const { workDate, sqenNumb } = useParams();

  const { qnaRefreshToogle } = qnaStore();

  // 문의 내역 데이터 state
  const [qnaLists, setQnALists] = useState([]);
  const [noneResult, setNoneResult] = useState(false);

  const [imageList, setImageList] = useState([]);
  const [imageStart, setImageStart] = useState(null);

  const getQnALists = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v2/SP_m_PersonalInquiry_S4/0/0/partner`);
      if (res.data.data) {
        if (res.data.data.length > 0) {
          setQnALists(res.data.data.slice(0, res.data.data.length));
          if (utils.isEmpty(clickedList)) {
            let item = res.data.data.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
            if (item.length > 0) setClickedList(item[0]);
          }
          setNoneResult(false);
        } else {
          setNoneResult(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [clickedList, setClickedList] = useState({});

  // 1:1 문의 이미지 state
  const [qnaImages, setQnaImages] = useState({});
  const getQnAImages = async (workDate, sqenNumb) => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_m_PersonalInquiry_S2/${workDate}/${sqenNumb}`);

      if (res.data.data.length > 0) {
        if (res.data.data[0].fileName !== undefined) {
          setQnaImages(
            Object.assign({}, qnaImages, {
              [workDate + sqenNumb]: res.data.data,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQnALists();
  }, []);

  useEffect(() => {
    getQnALists();
  }, [qnaRefreshToogle]);

  useEffect(() => {
    if (utils.isEmpty(clickedList)) {
      navigate(`/manage/news/faqList/qna`);
    } else {
      navigate(`/manage/news/faqList/qna/${clickedList.workDate}/${clickedList.sqenNumb}`);
      getQnAImages(clickedList.workDate, clickedList.sqenNumb);
      //
      // setTimeout(() => {
      //   QnABox.current.view.querySelector("details[open] .detail-bottom").scrollIntoView()
      // }, 300);
    }
  }, [clickedList]);
  useEffect(() => {
    let item = qnaLists.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
    if (item.length > 0) setClickedList(item[0]);

    if (!workDate || !sqenNumb) setClickedList({});
  }, [workDate, sqenNumb]);

  const handleDeleteQnA = async (workDate, sqenNumb) => {
    //utils confirm
    await utils.dialog.confirm('정말 삭제하시겠습니까?', '', 'question').then((res) => {
      if (res) {
        const url = `/v1/SP_m_PersonalInquiry_d1`;
        const data = {
          workDate: workDate,
          sqenNumb: sqenNumb,
        };

        utils.http.setMode('api');
        utils.http
          .post(url, null, data)
          .then((res) => {
            if (res.data.success === true) {
              utils.dialog.alert('삭제 완료!', '삭제가 완료되었습니다.', 'success');
              getQnALists();
            } else {
              utils.dialog.alert('삭제 실패!', res.data.message, 'error');
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });
  };

  return (
    <>
      {!noneResult && qnaLists.length > 0 ? (
        <QnATable>
          {/* 문의 내역이 존재할 경우 */}
          <TableHeader>
            <BdWhiteText>작성한 1:1 문의 내역과 답변을 확인할 수 있어요.</BdWhiteText>
            <WtNomalSpan>전체 {qnaLists.length}건</WtNomalSpan>
          </TableHeader>
          <TableBody>
            <Scrollbars className="scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />} ref={QnABox}>
              {qnaLists.map((qna, index) => (
                <Detail key={`qna-${qna.sqenNumb}${index}`} open={qna.workDate === clickedList.workDate && qna.sqenNumb === clickedList.sqenNumb}>
                  <Summary
                    onClick={(e) => {
                      e.preventDefault();
                      if (clickedList.workDate === qna.workDate && clickedList.sqenNumb === qna.sqenNumb) {
                        setClickedList({});
                      } else {
                        setClickedList(qna);
                      }
                    }}
                  >
                    <StatBtn className={qna.answerGubn === '답변대기' ? 'stat-waiting' : 'stat-complete'}>{qna.answerGubn}</StatBtn>
                    <div style={{ width: 'calc(100% - 300px)' }}>{qna.qaSubject}</div>
                    <Wrap>
                      <DateBox>{qna.qaDatetime}</DateBox>
                      <ArrowIcon
                        type="button"
                        onClick={(e) => {
                          e.target.parentNode.click();
                        }}
                      />
                    </Wrap>
                  </Summary>
                  <AnswerWrap>
                    <Content>
                      <ContentHeader>
                        <DeleteBtn onClick={() => handleDeleteQnA(qna.workDate, qna.sqenNumb)}>삭제</DeleteBtn>
                        <ContentBody>Q. {qna.qaContent}</ContentBody>
                      </ContentHeader>
                      {qnaImages[qna.workDate + qna.sqenNumb]?.length > 0 && (
                        <ContentImage>
                          {qnaImages[qna.workDate + qna.sqenNumb].map((img, index) => (
                            <img
                              src={`${imgURL}${img.fileName}`}
                              alt=""
                              key={`qna-img${index}`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setImageList(qnaImages[qna.workDate + qna.sqenNumb].map((x) => x.fileName));
                                setImageStart(img.fileName);
                              }}
                            />
                          ))}
                        </ContentImage>
                      )}
                    </Content>
                    <Answer>
                      {qna.qnaAnswer === undefined ? (
                        <NoneAnswer>
                          작성된 답변이 없습니다.
                          <br />
                          조금만 기다려주세요. 신속히 답변드리겠습니다.
                        </NoneAnswer>
                      ) : (
                        <div className="answer-wrap">{qna.qnaAnswer}</div>
                      )}
                    </Answer>
                  </AnswerWrap>
                  <div className={'detail-bottom'}></div>
                </Detail>
              ))}
            </Scrollbars>
          </TableBody>
        </QnATable>
      ) : (
        <>
          {/* 문의 내역이 존재하지 않을 경우 */}
          <QnATable>
            <EmptyBox>1:1 문의 내역이 존재하지 않습니다.</EmptyBox>
          </QnATable>
        </>
      )}
      <ImageViewModal
        open={imageList.length > 0 && imageStart !== null}
        close={() => {
          setImageList([]);
          setImageStart(null);
        }}
        title="사진"
        imageList={imageList}
        imageStart={imageStart}
        useDot
      />
    </>
  );
};

export default QnA;

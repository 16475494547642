import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ProfileImage from 'components/Parts/Common/ProfileImage';
import { useMediaQuery } from 'react-responsive';
import { styled } from 'styled-components';

export const Item2 = styled.div`
  width: 100%;
  height: 110px;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  background-color: #212838;
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 14px;
  transition: border 0.2s ease;
  color: #fff;
  &:hover,
  &.selected {
    border: solid 1px #5affe2;
  }
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    height: 90px;
    padding: 10px 20px;
  }
`;

export const ItemRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    gap: 5px;
  }
`;

export const ItemRowTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: bold;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const ItemRowFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #bebebe;

  span {
    font-size: 16px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    span {
      font-size: 14px;
    }
  }
`;

const RowLeftBox = styled.div`
  width: 100%;
  max-width: calc(100% - 80px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const baseUrl = process.env.REACT_APP_IMAGE_VIEW_URL;

const QuestionList = ({ questionView, setQuestionView, questionList }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <>
      {questionList.map((item, index) => (
        <Item2
          key={`list-${item.sqenNumb}${item.workDate}`}
          onClick={() => {
            setQuestionView(item);
          }}
          className={questionView.workDate === item.workDate && questionView.sqenNumb === item.sqenNumb && 'selected'}
        >
          <RowLeftBox>
            <ItemRowTitle>{item.quesSubject}</ItemRowTitle>
            <ItemRow>
              <div>
                <ProfileImage partProfile={1} width={isTablet ? '20px' : '30px'} height={isTablet ? '20px' : '30px'} kind={item.userGubn} id={item.userId} shape={'circle'} alwaysRefresh={true} />
              </div>
              <div>{item.userNick}</div>
            </ItemRow>
            <ItemRowFooter>
              <div>{item.dateValue}</div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px', marginLeft: '10px' }}>
                <ChatOutlinedIcon />
                <div>{item?.quesComment ?? 0}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <VisibilityOutlinedIcon />
                <div>{item?.quesHit ?? 0}</div>
              </div>
            </ItemRowFooter>
          </RowLeftBox>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {item.fileName && (
              <img
                src={`${baseUrl}${item.fileName}`}
                alt={item.fileName}
                style={{
                  width: isTablet ? '60px' : '80px',
                  height: isTablet ? '60px' : '80px',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
              />
            )}
          </div>
        </Item2>
      ))}
    </>
  );
};

export default QuestionList;

import PreviewImage from '../../../Parts/Common/PreviewImage';
import { ContentText, ContentWrap, Item, PreviewImg, SentDate, Subject, SubmitBtn } from './EstimateListElements';

const ReceivedEstimateList = ({ estimateView, handleClickItem, estimateList, openEstimateModal }) => {
  return (
    <>
      {estimateList.map((item, index) => (
        <Item
          key={`list-${item.sqenNumb}${item.workDate}`}
          onClick={() => {
            handleClickItem(item);
          }}
          className={estimateView.workDate === item.workDate && estimateView.sqenNumb === item.sqenNumb && 'selected'}
        >
          <PreviewImg>
            <PreviewImage width={'80px'} height={'80px'} image={item.fileName} shape={'rect'} />
          </PreviewImg>
          <ContentWrap>
            <Subject>{item.reqSubject}</Subject>
            <ContentText>{item.reqContent}</ContentText>
            <SentDate>{item.ftDt}</SentDate>
          </ContentWrap>
          <SubmitBtn type="button" onClick={openEstimateModal} disabled={item?.reqGubn === 2}>
            {item?.reqGubn === 2 ? '견적마감' : '견적제출'}
          </SubmitBtn>
        </Item>
      ))}
    </>
  );
};

export default ReceivedEstimateList;

import React, { useEffect } from 'react';
import WindowFrame from './WindowFrame';
import { styled } from 'styled-components';
import utils from 'modules/utils';
import { useMediaQuery } from 'react-responsive';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import CustomPassword from './../../../ControllerInput/CustomPassword';

export const SubmitBtn = styled.button`
  width: 100%;
  border-radius: 5px;
  background-color: #7e6eff;
  height: 40px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #6959de;
  }
`;

export const Div = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px soild red;

  span {
    font-size: 24px;
    font-weight: normal;
    color: #fff;
    margin-bottom: 20px;
  }
  .pw-label {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    margin: 10px 0px;
  }

  .valid-message {
    height: 16px;
    color: #eb4141;
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    span {
      font-size: 18px;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .valid-message {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
`;

//비밀번호는 영문자, 숫자, 특수문자를 조합한 최소 8자로 입력해 주세요.
//입력하신 비밀번호가 일치하지 않습니다.

const schema = yup.object().shape({
  newPw: yup
    .string()
    .required('신규 비밀번호를 입력해 주세요.')
    .matches(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/, '비밀번호는 영문자, 숫자, 특수문자를 조합한 최소 8자로 입력해 주세요.'),
  checkPw: yup
    .string()
    .required('신규 비밀번호를 입력해 주세요.')
    .oneOf([yup.ref('newPw'), null], '비밀번호가 일치하지 않습니다.'),
});

const ModifyPwModal = (props) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, title, newPw, setNewPw, checkPw, setCheckPw, isModify, setIsModify } = props;
  const modalHeaderStyle = {
    padding: '36px 50px',
  };
  const modalBodyStyle = {
    main_section: { padding: '30px 50px 36px 50px' },
    modal_main: { padding: 0 },
    fixed_button: { padding: 0 },
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      newPw: newPw,
      checkPw: checkPw,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const { newPw, checkPw } = data;

    if (!utils.valid.isNullPassword(newPw)) {
      utils.dialog.alert('비밀번호 입력!', '비밀번호를 입력해 주세요.', 'warning');
    } else if (!utils.valid.isNullPassword(checkPw)) {
      utils.dialog.alert('비밀번호 확인 입력!', '비밀번호 확인란에 비밀번호를<br />입력해 주세요.', 'warning');
    } else if (!utils.valid.isPasswordValid(newPw)) {
      utils.dialog.alert('비밀번호 입력 확인!', '비밀번호는 영문자, 숫자, 특수문자를 조합한<br />최소 8자로 입력해 주세요.', 'warning');
    } else if (newPw !== checkPw) {
      utils.dialog.alert('비밀번호 입력 확인!', '비밀번호 확인란이 일치하지 않습니다.', 'warning');
    } else {
      setNewPw(newPw);
      setCheckPw(checkPw);
      setIsModify(true);
      utils.dialog.alert('', '비밀번호 변경을 위해 상단에 저장 버튼을 눌러 주세요.', 'success').then(() => {
        close();
      });
      close();
    }
  };
  useEffect(() => {
    if (open) {
      setCheckPw('');
      setNewPw('');
      reset();
      setIsModify(false);
    }
    if (!isModify && !open) {
      setCheckPw('');
      setNewPw('');
      setIsModify(false);
    }
  }, [open]);

  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'790px'}
      height={isTablet ? '470px' : '510px'}
      scroll={false}
      modalBodyStyle={modalBodyStyle}
      modalHeaderStyle={modalHeaderStyle}
      slotFixedButton={
        <SubmitBtn type="button" onClick={handleSubmit(onSubmit)}>
          비밀번호 변경
        </SubmitBtn>
      }
    >
      <Div onSubmit={handleSubmit(onSubmit)} noValidate>
        <span>주기적으로 변경하여 내 정보를 안전하게 보호하세요.</span>
        <p className="pw-label">신규 비밀번호</p>
        <CustomPassword
          name="newPw"
          control={control}
          required
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              top: '-8px',
            },
          }}
        />
        <p className="pw-label">신규 비밀번호 확인</p>
        <CustomPassword
          name="checkPw"
          control={control}
          required
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              top: '-8px',
            },
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(onSubmit)(e);
            }
          }}
        />
      </Div>
    </WindowFrame>
  );
};

export default ModifyPwModal;

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import { Box, Button, Chip, Divider } from '@mui/material';
import EmptyBox from 'components/Parts/Common/EmptyBox';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import ResultTop from 'components/Parts/Common/ResultTop';
import CustomSwitch from 'components/Switch/CustomSwitch';
import dayjs from 'dayjs';
import utils from 'modules/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReportBlockButtons from '../../../Parts/Common/ReportBlockButtons';
import DetailContent from '../../../Parts/Common/TabLists/DetailContent';
import Lists from '../../../Parts/Common/TabLists/Lists';
import { Container, Contents, Item, NickName } from '../../../Parts/Common/TabLists/ViewListsElements';
import useIntersectionObserver from './../../../../hooks/useObserver';
import SoodalModal from './../../../Parts/Common/Modal/SoodalModal';
import ProfileImage from './../../../Parts/Common/ProfileImage';
import SearchInput from './../../../Parts/Common/SearchInput/SearchInput';
import { ImageWrap } from './../../News/QuestionList/QuestionListElements';
import { WriteButton } from './../../News/QuestionList/Results';
import { CustomTextWrap, DeniedContent, DeniedHeader, DeniedSection, DeniedTitle, DetailHeader, HeaderTitle, IconSection, ItemImageBox, ItemRow, MintText, ReviewBase, SectionBox, ViewCount, ViewCountBox } from './SoodalBoardElements';

export const CommentBtn = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const EditDeleteBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-size: 20px;
  color: #8a8a8a;
`;

const ImageFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: ${(props) => (props.directGubn === '0' ? 'var(--primary-color)' : '#eb0b0b')};
  font-weight: bold;
`;

const baseUrl = process.env.REACT_APP_IMAGE_VIEW_URL;
const THUMBNAIL_URL = process.env.REACT_APP_IMAGE_THUMBNAIL_URL;

const modalStyle = {
  width: '501px',
  height: 'auto',
};
let infoModalData = '진행 상품 등록하고 더욱 빠른 상담으로 고객과 만날 수 있습니다.';

const IconBox = ({ title, content, Icon, bInfo1 }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px', width: bInfo1 !== 'A' ? '100%' : 'fit-content' }}>
      <Box sx={{ display: 'flex', width: '40px', height: '40px', backgroundColor: '#7e6eff', borderRadius: '50%', alignItems: 'center', justifyContent: 'center', color: '#fff' }}>
        <Icon />
      </Box>
      <Box>
        <Box sx={{ fontSize: '20px', color: '#8a8a8a' }}>{title}</Box>
        <Box sx={{ fontSize: '18px', color: '#10141c' }}>{content === '' ? '-' : content}</Box>
      </Box>
    </Box>
  );
};

const ApproveChip = ({ row, sx, ...other }) => {
  if (row.direct_gubn === '1') {
    return null;
  }
  return (
    <Chip
      sx={{
        position: 'absolute',
        top: '0',
        right: '0',
        lineHeight: '1rem',
        ...sx,
      }}
      color={row?.direct_gubn === '0' ? 'primary' : 'error'}
      label={row?.direct_gubn_name}
      {...other}
    />
  );
};

const DeniedBox = ({ boardView }) => {
  if (boardView?.direct_gubn !== '2') {
    return null;
  }
  return (
    <DeniedSection>
      <DeniedHeader>
        <DeniedTitle>
          <InfoOutlinedIcon />
          <div>거부 사유</div>
        </DeniedTitle>
        <div>거부 일자 : {dayjs(boardView?.ft_dt).format('YYYY-MM-DD')}</div>
      </DeniedHeader>
      <DeniedContent>{boardView?.direct_reject ?? ''}</DeniedContent>
      <div>이러한 사유로 작성하신 글이 반려되었습니다.</div>
      <div>아래 [수정] 버튼을 눌러 수정 후, 다시 등록해주세요.</div>
    </DeniedSection>
  );
};

const SoodalBoard = () => {
  const userId = utils.user('id');
  // 스크롤 게시글
  const [viewList, setViewList] = useState([]); // 보여지는 목록 상태
  const [startIndex, setStartIndex] = useState(0); // 시작 인덱스 상태

  const pageEnd = useRef(null); // 페이지 끝 요소 참조

  const [boardList, setBoardList] = useState([]);
  const [boardView, setBoardView] = useState({});
  const [boardImages, setBoardImages] = useState([]);
  const [searchWord, setSearchWord] = useState('');
  const [openSoodalModal, setOpenSoodalModal] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const [myBoard, setMyBoard] = useState(true);

  const toggleChecked = () => {
    setMyBoard((prev) => !prev);
  };

  const handleOpenModal = () => {
    setOpenSoodalModal(true);
    setEditModalData({});
  };

  const handleCloseModal = () => {
    setOpenSoodalModal(false);
  };

  const refreshList = () => {
    updateBoardList(searchWord ?? '');
    setBoardView({});
  };

  const bindingEditData = (boardView) => {
    setEditModalData({
      ...boardView,
      detailDataImage: boardImages,
    });
    setOpenSoodalModal(true);
  };

  const deleteDetail = async () => {
    await utils.dialog.confirm('정말 삭제하시겠습니까?', '', 'question').then((result) => {
      if (result) {
        const url = `/v1/SP_d_direct_D1`;
        const data = {
          workDate: boardView.workDate,
          sqenNumb: boardView.sqenNumb,
        };
        utils.http.setMode('api');
        utils.http.post(url, null, data).then(({ data }) => {
          console.log('deleteDetail', data);
          if (data?.success) {
            utils.dialog.alert('수리상품 글 삭제 완료', '수리상품 글이 삭제되었습니다.', 'success').then(() => {
              refreshList();
            });
          } else {
            utils.dialog.alert('수리상품 글 삭제 실패', '수리상품 글 삭제에 실패했습니다.', 'error');
          }
        });
      }
    });
  };

  const getDetailBoard = (row) => {
    const url = `/v2/SP_d_directItemV2_S1/${row.workDate}/${row.sqenNumb}`;
    utils.http.setMode('api');
    utils.http.get(url).then(({ data }) => {
      setBoardView({ ...row, ...data?.data[0] });
    });
    console.log('row', row);
  };
  const getDetailBoardImage = (row) => {
    const url = `/v1/SP_d_directItem_S2/${row.workDate}/${row.sqenNumb}`;
    utils.http.setMode('api');
    utils.http.get(url).then(({ data }) => {
      setBoardImages(data?.data);
    });
  };

  const updateBoardList = function (searchWord) {
    const url = `/v1/SP_d_direct_S3`;
    const data = {
      myDirect: myBoard ? 1 : 0,
      sort: 1,
      value: searchWord,
    };
    utils.http.setMode('api');
    utils.http.post(url, null, data).then(({ data }) => {
      setViewList([]); // 데이터 업데이트 후 보여지는 목록 초기화
      setStartIndex(0); // 시작 인덱스 초기화
      setBoardList(data?.data ?? []);
    });
  };

  const loadMore = useCallback(() => {
    const endIndex = startIndex + 20;
    const sliceList = boardList.slice(startIndex, endIndex);
    const endCount = startIndex + sliceList.length;
    if (endCount > boardList.length) {
      return;
    }

    setViewList((prevList) => [...prevList, ...sliceList]);
    setStartIndex(endIndex);
  }, [startIndex, boardList, boardView]);

  const { observe, unobserve } = useIntersectionObserver(loadMore); // useIntersectionObserver 훅을 사용하여 IntersectionObserver 설정
  useEffect(() => {
    if (pageEnd.current) {
      observe(pageEnd.current); // 페이지 끝 요소를 감시
    }

    return () => {
      if (pageEnd.current) {
        unobserve(pageEnd.current); // 컴포넌트 언마운트 시 옵저버 연결 해제
      }
    };
  }, [boardList, startIndex, loadMore, boardView]);

  useEffect(() => {
    updateBoardList(searchWord ?? '');
  }, [myBoard]);

  return (
    <ReviewBase>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <SmallInfoModal menu="수리상품" style={modalStyle} infoModalData={infoModalData} />
        <WriteButton onClick={handleOpenModal}>
          <EditNoteOutlinedIcon />
          상품등록
        </WriteButton>
      </div>
      <Container className={'review-section'}>
        <Lists
          title={
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>목록</div>
            </div>
          }
          empty={false}
          infoModalData={''}
          emptyMessage={
            <>
              등록 된 수리상품 글이 없습니다.
              <br />
              진행중인 상품를 등록해주세요!
            </>
          }
          slotFilter={
            <>
              <SearchInput
                inputVal={searchWord}
                setInputVal={setSearchWord}
                onSearch={() => updateBoardList(searchWord ?? '')} // 검색 시 보드 리스트 업데이트
              />
              <ResultTop total={utils.format.number(boardList.length)}>
                <CustomSwitch label="내 게시글" checked={myBoard} onChange={toggleChecked} />
              </ResultTop>
            </>
          }
          slotList={
            boardList?.length === 0 ? (
              <EmptyBox>
                등록 된 수리상품 글이 없습니다.
                <br />
                진행중인 상품를 등록해주세요!
              </EmptyBox>
            ) : (
              <>
                {viewList.map((row, index) => (
                  <Item
                    key={'boardlist' + row.workDate + row.sqenNumb + index}
                    className={'review-item ' + (boardView.workDate + boardView.sqenNumb === row.workDate + row.sqenNumb && 'selected')}
                    onClick={() => {
                      getDetailBoard(row);
                      getDetailBoardImage(row);
                    }}
                  >
                    <ItemImageBox>
                      {row.fileName && <img src={`${THUMBNAIL_URL}${row.fileName}`} alt={row.fileName} />}
                      {row.direct_gubn !== '1' && <ImageFooter directGubn={row.direct_gubn}>{row.direct_gubn_name}</ImageFooter>}
                    </ItemImageBox>
                    <CustomTextWrap>
                      <SectionBox>
                        <NickName className={'nick'}>{row.partName}</NickName>
                        <Contents className={'content'}>{row.direcSubject}</Contents>
                        <ItemRow>
                          <MintText>{row?.direcMoneyGubn === '1' ? '금액협의' : `${row?.direcMoney?.toLocaleString()}원 부터~`}</MintText>
                          <ViewCountBox>
                            <VisibilityOutlinedIcon sx={{ fontSize: '20px' }} />
                            <div>{row?.direcHit ?? 0}</div>
                          </ViewCountBox>
                        </ItemRow>
                      </SectionBox>
                    </CustomTextWrap>
                  </Item>
                ))}
                {/* 페이지 끝 요소 */}
                <Button
                  ref={pageEnd}
                  color="info"
                  onClick={loadMore}
                  variant="outlined"
                  fullWidth
                  size="small"
                  endIcon={<ArrowDropDownIcon />}
                  sx={{
                    visibility: viewList.length > 0 ? 'visible' : 'hidden',
                  }}
                  disabled={boardList.length === viewList.length}
                >
                  더보기
                </Button>
              </>
            )
          }
        />
        <DetailContent
          title={'상세 내용'}
          empty={utils.isNull(boardView.workDate) || utils.isNull(boardView.sqenNumb)}
          emptyMessage={'목록에서 후기글을 선택해 주세요.'}
          scrollBar={true}
          adFitCode="DAN-3gLMQWoBbCGtUID6"
          slotContent={
            <section className={'view-container'}>
              <DetailHeader>
                <ApproveChip
                  row={boardView}
                  sx={{
                    top: '20px',
                    right: '30px',
                    fontSize: '20px',
                    padding: '0 10px',
                    height: '40px',
                    borderRadius: '20px',
                    lineHeight: '1.5rem',
                  }}
                />
                {boardView?.cateName && <div>{boardView?.cateName}</div>}
                <HeaderTitle>{boardView.direcSubject}</HeaderTitle>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                  }}
                >
                  <section style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <ProfileImage width={'40px'} height={'40px'} kind={'partner'} id={boardView.partId} alwaysRefresh={true} partProfile={1} />
                    <div>
                      <div
                        style={{
                          fontSize: '20px',
                          color: '#10141C',
                          fontWeight: 'bold',
                        }}
                      >
                        {boardView.partName}
                      </div>
                      <div
                        style={{
                          fontSize: '14px',
                          color: '#8a8a8a',
                        }}
                      >
                        {boardView.dateValue}
                      </div>
                    </div>
                  </section>
                  <ViewCount>조회 {boardView.direcHit}</ViewCount>
                </div>
              </DetailHeader>
              <Divider
                sx={{
                  backgroundColor: '#8a8a8a',
                }}
              />
              <DeniedBox boardView={boardView} />
              <Box sx={{ margin: '20px' }}>
                <IconSection>
                  {boardView?.Binfo1 === 'A' && (
                    <>
                      <IconBox title="용도" bInfo1={boardView?.Binfo1} content={boardView?.Ainfo1 ?? '-'} Icon={BusinessOutlinedIcon} />
                      <IconBox title="면적" bInfo1={boardView?.Binfo1} content={boardView?.Ainfo2 ?? '-'} Icon={ZoomOutMapOutlinedIcon} />
                    </>
                  )}
                  <IconBox title="소요기간" bInfo1={boardView?.Binfo1} content={boardView?.Ainfo3 ?? '-'} Icon={AccessTimeOutlinedIcon} />
                  <IconBox title="금액" bInfo1={boardView?.Binfo1} content={boardView.direcMoney?.toLocaleString() + '원~'} Icon={PaidOutlinedIcon} />
                </IconSection>
              </Box>
              <main className={'view-main'}>
                <p
                  className={'review-content'}
                  style={{
                    whiteSpace: 'pre-line',
                    marginBottom: '20px',
                  }}
                >
                  {boardView.direcContent}
                </p>
                {boardImages.length > 0 &&
                  boardImages.map((img, index) => (
                    <ImageWrap key={`file${index}-${img.sqenNumb}${img.workDate}`}>
                      <img src={`${baseUrl}${img.fileName}`} alt="" style={{ maxWidth: '100%' }} />
                    </ImageWrap>
                  ))}
                {userId === boardView.partId ? (
                  <EditDeleteBtn>
                    <CommentBtn onClick={() => bindingEditData(boardView)}>수정</CommentBtn>
                    <span>|</span>
                    <CommentBtn onClick={deleteDetail}>삭제</CommentBtn>
                  </EditDeleteBtn>
                ) : (
                  <ReportBlockButtons
                    gubn={'수리상품'}
                    userId={boardView.partId}
                    sqenNumb={boardView.sqenNumb}
                    workDate={boardView.workDate}
                    headNumb={''}
                    userNick={boardView.partName}
                    afterAction={() => {
                      setBoardView({});
                    }}
                  />
                )}
              </main>
            </section>
          }
        />
      </Container>
      <SoodalModal open={openSoodalModal} editModalData={editModalData} close={handleCloseModal} title="수리상품" refreshList={refreshList} />
    </ReviewBase>
  );
};

export default SoodalBoard;

import React from 'react';
import { styled } from 'styled-components';

export const Label = styled.label`
  width: 80px;
  height: 41px;
  display: inline-block;
  position: relative;
  border-radius: 20px;
  background-color: #8a8a8a;
  box-shadow: 0 0 16px 3px rgba(0 0 0 / 15%);
  cursor: pointer;

  span {
    /* 버튼은 토글보다 작아야함  */
    width: 31px;
    height: 31px;
    background-color: #fff;
    position: absolute;
    top: 10%;
    left: 4px;
    border-radius: 50%;
  }

  &.toggleActiveSwitch {
    background: #7e6eff;
  }

  &.toggleActiveSwitch span {
    transform: translateX(41px);
    border-radius: 50%;
  }

  &.toggleSwitch,
  .toggleButton {
    transition: all 0.2s ease-in;
  }
`;

const ToggleSwitch = ({ isChecked, setIsChecked, id, style }) => {
  return (
    <>
      <input
        type="checkbox"
        id={id}
        hidden
        onChange={() => {
          setIsChecked(Number(!isChecked));
          //console.log(isChecked);
        }}
        checked={isChecked}
      />

      <Label htmlFor={id} className={isChecked ? 'toggleActiveSwitch' : 'toggleSwitch'} style={style && style.label}>
        <span className="toggleButton" style={style && style.span}></span>
      </Label>
    </>
  );
};

export default ToggleSwitch;

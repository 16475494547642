import React, { useState } from 'react';
import Faq from '../Faq/Faq';
import QnA from '../QnA/QnA';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import { styled } from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { WriteButton } from './../QuestionList/Results';
import QnAModal from 'components/Parts/Common/Modal/QnAModal';
const menuInfo = '수달의 달인 이용에 필요한 정보를 쉽게 찾을 수 있습니다.';
const modalStyle = {
  width: '428px',
  height: '104px',
};

const TabBox = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #37465b;
  display: flex;
  color: #fff;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    height: 40px;
  }
`;

const TabBtn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.1s ease-in-out;
  &:hover {
    color: var(--mint-color) !important;
  }

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
`;

const FaqHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TotalFaq = () => {
  // 검색창 입력 값 state
  const { kind } = useParams();
  const navigate = useNavigate();
  const [openQnA, setOpenQnA] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);

  const handleShowQnAModal = () => {
    setOpenQnA(true);
  };

  const refresh = () => {
    setRefreshToggle(!refreshToggle);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <FaqHeader>
        <SmallInfoModal menu="고객센터" infoModalData={menuInfo} style={modalStyle} />
        <WriteButton
          style={{
            width: '140px',
          }}
          onClick={handleShowQnAModal}
        >
          1:1 문의하기
        </WriteButton>
      </FaqHeader>
      <TabBox>
        {/* 탭 만들기 */}
        <TabBtn
          onClick={() => {
            navigate(`/manage/news/faqList/faq`);
            refresh();
          }}
          // 선택된 탭은 밑줄이 있도록
          style={{
            borderBottom: kind === 'faq' ? '2px solid var(--mint-color)' : 'none',
          }}
        >
          FAQ
        </TabBtn>
        <TabBtn
          onClick={() => navigate(`/manage/news/faqList/qna`)}
          style={{
            borderBottom: kind !== 'faq' ? '2px solid var(--mint-color)' : 'none',
          }}
        >
          1:1 문의
        </TabBtn>
      </TabBox>
      <div
        style={{
          marginTop: '20px',
          height: '100%',
          maxHeight: 'calc(100% - 120px)',
        }}
      >
        {kind === 'faq' ? <Faq refreshToggle={refreshToggle} /> : <QnA />}
      </div>
      <QnAModal
        open={openQnA}
        close={() => {
          setOpenQnA(false);
        }}
        title="1:1문의"
      />
    </div>
  );
};

export default TotalFaq;

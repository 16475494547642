import styled from 'styled-components';

export const AppBase = styled.div`
  height: 100%;
  width: 100%;
`;
export const Base = styled.div`
  height: 100%;
  width: 100%;
  //min-height: 765px;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 270px auto;
  grid-template-rows: 100%;

  @media all and (max-width: 1440px) {
    grid-template-columns: 0 100%;
    grid-template-rows: 100%;
  }
`;

export const MainWrap = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  padding: 24px 30px 0 30px;
  grid-template-columns: 100%;
  grid-template-rows: auto 60px;
  /* min-width: 1400px; */
  min-height: 700px;

  //480
  @media all and (max-width: 1440px) {
    grid-template-rows: auto 20px;
    width: 100vw;
    padding: 20px 20px 0 20px;
    min-width: 0;
    min-height: 0;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 10px;
`;

export const ConnectDate = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: normal;
`;

import React from 'react';
import { ContentTop, IconWrap, SmallModal, InfoText, HeadingText } from './MenuInfoElements';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import { Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const SmallInfoModal = ({ infoModalData, menu, style }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <ContentTop>
          <HeadingText style={{ fontSize: isTablet ? '18px' : '26px' }}>{menu}</HeadingText>
          <Tooltip
            title={infoModalData}
            placement={isMobile ? 'bottom' : 'right-start'}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.black',
                  '& .MuiTooltip-arrow': {
                    color: 'common.black',
                  },
                  minWidth: style.width,
                  padding: isMobile ? '10px 4px' : '20px 30px',
                  borderRadius: '10px',
                  backgroundColor: '#10141c',
                  fontSize: isMobile ? '10px' : '22px',
                  color: '#fff',
                  fontFamily: 'Noto Sans KR',
                  fontWeight: 'normal',
                },
              },
            }}
            onClose={handleTooltipClose}
            open={open}
          >
            <IconWrap onClick={handleTooltipOpen} onMouseEnter={handleTooltipOpen}>
              <AiOutlineExclamationCircle color="#7e6eff" style={{ cursor: 'pointer' }} />
            </IconWrap>
          </Tooltip>
        </ContentTop>
      </ClickAwayListener>
    </>
  );
};

export default SmallInfoModal;

import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  min-width: 1700px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 1440px) {
    min-width: 0;
  }

  @media screen and (max-width: 480px) {
    padding: 0px;
    min-width: 0px;
  }
`;

export const LeftSection = styled.section`
  height: 100%;
  /* flex: 0 0 693px; */
  padding: 60px;
`;

export const HeadingWrap = styled.div`
  position: fixed;
  background-color: transparent;
  width: 400px;
`;

export const HeadingText = styled.p`
  display: inline-block;
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: -5px;
    bottom: 4px;
    width: 100%;
    padding: 6px 5px;
    background-color: #7e6eff;
    z-index: -1;
  }
`;

export const LogoWrap = styled.div`
  height: 100%;
  width: 100%;
  background-color: transparent;
`;

export const ImgWrap = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightSection = styled.section`
  height: 100%;
  flex: 1 1 100%;

  @media screen and (max-width: 1440px) {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const FormWrap = styled.div`
  display: flex;
  align-items: center;
  background-color: #1b2335;
  height: 100%;
  padding: 50px 45px;

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const Logo = styled.img`
  width: 280px;
  height: auto;
  @media screen and (max-width: 1650px) {
    width: 100%;
    height: auto;
  }
`;

export const Form = styled.form`
  border-radius: 20px;
  background-color: #37465b;
  box-sizing: border-box;
  height: 100%;
  max-height: 858px;
  width: 100%;
  padding: 50px;
  z-index: 1;
  margin: 0 auto;

  .main-form-scroll {
    height: calc(100% - 60px) !important;
  }

  /* 모바일 세로 */
  @media screen and (max-width: 480px) {
    padding: 20px;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    background-color: #10141c;
  }
`;

export const InputWrap = styled.div`
  width: 100%;
  display: flex;
  height: 70px;

  @media screen and (max-width: 1024px) {
    margin-top: 10px;
    flex-direction: column;
    height: auto;
  }
`;

export const PwWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const PwInputBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

export const Label = styled.label`
  position: relative;
  width: 100%;

  input {
    border: none;
    height: 40px;
    width: 93%;
    padding: 6px 12px;
    color: #222;
    font-size: 20px;
    font-weight: normal;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &::placeholder {
      color: #8a8a8a;
    }

    &:focus::placeholder {
      visibility: hidden;
    }
    @media screen and (max-width: 1024px) {
      height: 30px;
      font-size: 14px !important;
    }
  }

  span {
    width: 7%;
    height: 100%;
    position: absolute;
    padding: 3px 2px 3px 0;
    top: 0;
    right: 0px;
    background: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: right;

    svg {
      color: tomato;
      font-size: 34px;
    }
  }
`;

export const WarningMessage = styled.span`
  font-size: 15px;
  font-weight: normal;
  color: #eb4141;
  margin-left: 17px;
  //display: none;
`;

export const InputTitle = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  width: 22%;
  flex: 0 0 22%;
  min-width: 170px;
  max-width: 230px;
  display: flex;
  height: 40px;
  align-self: flex-start;
  align-items: center;

  @media screen and (max-width: 1024px) {
    font-size: 20px;
    margin-bottom: 4px;
  }

  @media screen and (max-width: 480px) {
    height: 30px;
    font-size: 14px !important;
    margin-top: 10px;
  }
`;

export const LightInput = styled.input`
  width: 100%;
  flex: 1 1 100%;
  height: 40px;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  color: #222;
  font-size: 20px;
  font-weight: normal;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    height: 30px;
    font-size: 14px !important;
  }
`;

export const DarkInput = styled.input`
  flex: 1 1 100%;
  height: 40px;
  padding: 6px 12px;
  border: 1px solid #8b8b8b;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  background-color: #212838;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    height: 30px;
    font-size: 14px !important;
  }
`;

export const EmailInput = styled.input`
  padding: 0px 12px;
  height: 40px;
  border: none;
  border-radius: 5px;
  color: #222;
  font-size: 20px;
  font-weight: normal;

  &.email-id {
    width: 20%;
    flex: 0 0 20%;
  }
  &.email-domain {
    width: 100%;
    flex: 1 1 100%;
  }

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }
  @media screen and (max-width: 1024px) {
    height: 30px;
    font-size: 14px !important;
  }
`;

export const StyledSpan = styled.span`
  color: #fff;
  font-size: 22px;
  width: 38px;
  flex: 0 0 38px;
  height: 40px;
  text-align: center;
`;

export const SelectBox = styled.div`
  width: 170px;
  flex: 0 0 170px;
  margin-left: 15px;
  font-size: 20px;

  @media screen and (max-width: 480px) {
    width: 100%;
    flex: auto;
    margin-left: 0;
    margin-top: 10px;
    font-size: 14px !important;
  }
`;

export const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const HalfDiv = styled.div`
  width: 50%;
`;

export const CheckIdBtn = styled.button`
  outline: none;
  border: none;
  background-color: #7e6eff;
  margin-left: 25px;
  width: 175px;
  flex: 0 0 175px;
  height: 40px;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  border-radius: 5px;
  padding: 3.5px 0;
  cursor: pointer;

  line-height: 1;

  @media screen and (max-width: 1024px) {
    height: 30px;
    font-size: 20px !important;
  }

  @media screen and (max-width: 480px) {
    width: 100px;
    margin-left: 0;
    margin-top: 10px;
    font-size: 14px !important;
    height: 30px;
  }
`;

export const RegisterBtn = styled.button`
  background-color: #7e6eff;
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  line-height: 1;

  &:hover {
    background-color: #6959de;
  }

  @media screen and (max-width: 480px) {
    height: 50px;
    font-size: 18px !important;
  }
`;

import utils from 'modules/utils';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const commentStore = create(
  persist(
    (set) => ({
      myLike: {},
      setMyLike: (myLike) => set({ myLike }),
      commentParent: {},
      setCommentParent: (commentParent) => set({ commentParent }),
    }),
    {
      name: 'comment-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const qnaStore = create(
  persist(
    (set) => ({
      qnaRefreshToogle: false,
      setQnaRefreshToogle: (qnaRefreshToogle) => set({ qnaRefreshToogle }),
    }),
    {
      name: 'qna-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const partInfoStore = create(
  persist(
    (set) => ({
      partInfo: {},
      selectedTicket: null,
      setPartInfo: (partInfo) => set({ partInfo }),
      setSelectedTicket: (selectedTicket) => set({ selectedTicket }),
      getPartInfo: async () => {
        const url = `/v1/SP_p_partner_S1/${utils.user('id')}`;
        utils.http.setMode('api');
        const res = await utils.http.get(url);
        if (res.data.data && res.data.success) {
          set({ partInfo: res.data.data[0] });
        }
      },
    }),
    {
      name: 'part-info-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
export const reservationPaymentStore = create(
  persist(
    (set) => ({
      nextPartInfo: {},
      ticketList: [],
      setNextPartInfo: (nextPartInfo) => set({ nextPartInfo }),
      getTicketList: async () => {
        const url = `/v1/SP_b_user_ticket_s1`;
        utils.http.setMode('api');
        try {
          const response = await utils.http.get(url);
          set({ ticketList: response.data.data, nextPartInfo: response.data.data[0] });
          return response.data.data[0];
        } catch (error) {
          console.error('티켓 목록을 가져오는데 실패:', error);
          throw error; // 오류를 던짐
        }
      },
    }),
    {
      name: 'next-part-info-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const JobPostingStore = create(
  persist(
    (set) => ({
      internId: '',
      setInternId: (internId) => set({ internId }),
    }),
    {
      name: 'job-posting-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const menuStore = create(
  persist(
    (set) => ({
      menuOpen: false,
      setMenuOpen: (menuOpen) => set({ menuOpen }),
    }),
    {
      name: 'menu-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const authStore = create(
  persist(
    (set) => ({
      auth: {},
      setAuth: (auth) => set({ auth }),
    }),
    {
      name: 'auth-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

// 알람스토어
export const alarmStore = create(
  persist(
    (set) => ({
      alarms: [],
      setAlarms: (alarms) => set({ alarms }),
      getAlarms: async () => {
        try {
          utils.http.setMode('api');
          const response = await utils.http.get(`/v2/SP_m_notificationBellPart_S1`);

          if (response.data.data.length > 0) {
            set({ alarms: response.data.data });
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
    {
      name: 'alarm-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const soodalPlusStore = create(
  persist(
    (set) => ({
      partSoodalPlus: {
        partArea: '',
        partGubn: 0,
      },
      setPartSoodalPlus: (partSoodalPlus) => set({ partSoodalPlus }),
    }),
    {
      name: 'soodal-plus-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

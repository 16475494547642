import styled from 'styled-components';
import arrowDownIcon from 'assets/select-icon/down-icon.png';

export const SelectBox = styled.div`
  position: relative;
  padding: 1px 14px;
  height: 100%;
  border-radius: 5px;
  border: solid 1px #8a8a8a;
  background-color: #fff;
  &::before {
    content: '';
    width: 20px;
    height: 12px;
    background: url(${arrowDownIcon}) no-repeat;
    position: absolute;
    top: 14px;
    right: 14px;
    color: #8a8a8a;
    font-size: 20px;
    font-weight: bold;
  }
`;
export const Label = styled.label`
  font-size: inherit;
  line-height: 35px;
  font-weight: normal;
  color: ${(props) => (props.color === 'true' ? '#10141c' : '#8a8a8a')};
`;
export const SelectOptions = styled.ul`
  position: absolute;
  list-style: none;
  top: 32px;
  left: 0;
  width: 100%;
  height: 110px;
  overflow: hidden;
  max-height: ${(props) => (props.show === 'true' ? 'none' : '0')};
  padding: 0;
  border-radius: 3px;
  border: ${(props) => props.border};
  background-color: #fff;
  color: #10141c;
  font-size: inherit;
  z-index: 10;
`;
export const Option = styled.li`
  font-size: inherit;
  padding: 0 14px;
  height: 31px;
  &:hover {
    background-color: #212838;
    color: #fff;
  }
`;

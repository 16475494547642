import { useEffect, useState } from 'react';
import { BigContentBox, ContentBoxHeading, ContentBoxHeadingText, DetailBtn, HomeBase, HomeHeader, LeftSection, MainSection, RightSection, SmallBoxImg, SmallContentBox, TopTitle } from './HomeElemnts';

import KakaoAdFit from 'components/Adfit/KakaoAdfit';
import CustomCalendar from 'components/CalendarPage';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { styled } from 'styled-components';
import IDCardIcon from '../../../assets/16.png';
import ReportIcon from '../../../assets/17.png';
import PlusIcon from '../../../assets/542.png';
import utils from '../../../modules/utils';
import EventPopup from '../../Parts/Common/Modal/EventPopup';
import ModalComponent from '../../Parts/Common/Modal/ModalComponent';
import { ImgLink } from '../../Parts/Slide/SlideElements';
import HomeEstimateTable from '../../Parts/Table/HomeEstimateTable';
import HomeNoticeTable from '../../Parts/Table/HomeNoticeTable';
import { EventSlideImg } from '../News/Notice/Notice';

/**
 * userAuth 0:일반,1:달인,2:인턴,9:전체
 */
const PARTNER_OUTH = 1;
const BASE_IMAGE_URL = process.env.REACT_APP_IMAGE_VIEW_URL;

const MintColor = styled.span`
  color: var(--mint-color);
`;
const NewChatView = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  height: 24px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  padding: 0 10px 2px;
  color: #fff;

  background: #ff585e;
  margin-left: 10px;
`;

const Home = () => {
  const isSmallPC = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: true,
    dots: true,
    width: '100%',
    customPaging: function (i, a) {
      return (
        <div>
          {i + 1}/{eventData.length}
        </div>
      );
    }
  };

  // 공지사항 목록 조회
  const [notices, setNotices] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [open, setOpen] = useState(false);

  // 캘린더
  const [events, setEvents] = useState([]);
  const getMonthSchedule = () => {
    const url = `/v2/SP_r_ChatReservation_S2/${utils.user('id')}/${dayjs().format('YYYY-MM')}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data.data && res.data.success) {
          console.log('getMonthSchedule :', res.data.data);
          const data = res.data.data.map((item) => {
            return {
              title: item.user_id === 'soodal' ? '마감' : '예약',
              date: item.reser_day,
              backgroundColor: item.user_id === 'soodal' ? '#8a8a8a' : 'var(--mint-color)',
              extendedProps: {
                ...item
              }
            };
          });
          console.log('data', data);
          setEvents(data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    getMonthSchedule();
  }, [open]);

  const getNotices = async () => {
    try {
      utils.http.setMode('api');
      const response = await utils.http.get(`/v1/SP_m_Notice_S1/${PARTNER_OUTH}`);
      if (response.data.data.length > 0) {
        setNotices(response.data.data.slice(0, 10));
      }
    } catch (error) {
      console.log(error);
    }
  };
  //견적보내기 탭
  const [activeBtn, setActiveBtn] = useState('left');

  // 견적받기 목록 조회
  const [estimateList, setEstimateList] = useState([]);
  const getEstimateList = async () => {
    try {
      const url = `/v1/SP_p_partRequetList_S1`;
      utils.http.setMode('api');
      const response = await utils.http.post(url, null, { value: '' });

      if (response.data.data && response.data.data.length > 0) {
        // 10개만 넘겨줌
        setEstimateList(response.data.data.slice(0, 10));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 긴급출동 조회
  const [emergencyList, setEmergencyList] = useState([]);
  const getEmergency = async () => {
    try {
      const url = `/v1/SP_p_partEmergencyList_S1`;
      utils.http.setMode('api');
      const response = await utils.http.post(url, null, { value: '' });

      if (response.data.data && response.data.data.length > 0) {
        // 10개만 넘겨줌
        setEmergencyList(response.data.data.slice(0, 10));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 배너 이미지 조회
  const getBanner = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v2/SP_m_NoticeV2_S1/1/webbanner`);
      if (res.data.data.length > 0) {
        setEventData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [partInfo, setPartInfo] = useState({});
  const getTicketCount = async () => {
    const url = `/v1/SP_p_partner_S1/${utils.user('id')}`;
    utils.http.setMode('api');
    const res = await utils.http.get(url);
    if (res.data.data && res.data.success) {
      setPartInfo(res.data.data[0]);
    }
  };

  const handleClickNotice = (item) => {
    navigate(`/manage/news/notice/${item.workDate}/${item.sqenNumb}`);
  };

  // mobile사이즈로 들어오면 /manage/ticket/ticketList 라우터 이동
  const moveTicketList = () => {
    navigate('/manage/ticket/ticketList');
  };

  useEffect(() => {
    getNotices();
    getBanner();
    getTicketCount();
    isMobile && moveTicketList();
    utils.event.emit('loadChatCount', 'home');
  }, []);

  useEffect(() => {
    getEmergency();
    getEstimateList();
  }, [activeBtn]);

  const [viewNewChat, setViewNewChat] = useState(false);
  //새채팅 표시 이벤트
  utils.event.on('homeNewChat', (newChat) => {
    setViewNewChat(newChat);
  });

  return (
    <HomeBase>
      <HomeHeader>
        <TopTitle>
          <MintColor>{partInfo?.partName ?? ''}</MintColor> 달인님, 안녕하세요.
          <br />
          오늘도 행복한 하루되세요!
        </TopTitle>
      </HomeHeader>
      <MainSection>
        <LeftSection>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '300px 1fr',
              gridTemplateRows: '1fr 1fr',
              gap: '20px',
              width: '100%',
              height: '100%'
            }}
          >
            <KakaoAdFit
              style={{
                gridRow: '1 / 3',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                borderRadius: '10px',
                overflow: 'hidden'
              }}
              unit="DAN-RhPu8vVVdTfa2mNB"
              width="300"
              height="250"
            />
            <Link to={`/manage/history/estimateList/received`}>
              <SmallContentBox>
                <ContentBoxHeading>
                  <ContentBoxHeadingText>달인 내역</ContentBoxHeadingText>
                  {viewNewChat && <NewChatView>New Chat</NewChatView>}
                </ContentBoxHeading>
                <SmallBoxImg src={ReportIcon} alt="" max="71px" />
              </SmallContentBox>
            </Link>
            <Link to={`/manage/info/partnerInfo`}>
              <SmallContentBox>
                <ContentBoxHeadingText>달인 관리</ContentBoxHeadingText>
                <SmallBoxImg src={IDCardIcon} alt="" max="54px" />
              </SmallContentBox>
            </Link>
          </div>
          <BigContentBox>
            <ContentBoxHeading>
              <ContentBoxHeadingText>견적보내기</ContentBoxHeadingText>
              <DetailBtn
                type="button"
                onClick={() => {
                  navigate('/manage/history/estimateList/received');
                }}
              >
                <img src={PlusIcon} alt="" />
                더보기
              </DetailBtn>
            </ContentBoxHeading>

            {/* 견적 받기 */}
            <HomeEstimateTable head1="견적 요청" head2="출동 요청" rows1={estimateList} rows2={emergencyList} activeBtn={activeBtn} setActiveBtn={setActiveBtn} />
          </BigContentBox>
        </LeftSection>
        <RightSection>
          <div
            style={{
              backgroundColor: '#37465b',
              borderRadius: '10px',
              height: '150px',
              maxWidth: '100%',
              overflow: 'hidden',
              width: '100%'
            }}
          >
            <Slider className="eventSlide" {...settings}>
              {eventData.map((item, index) => (
                <ImgLink key={index}>
                  <EventSlideImg src={`${BASE_IMAGE_URL}${item?.file1 ?? ''}`} alt="" onClick={() => handleClickNotice(item)} />
                </ImgLink>
              ))}
            </Slider>
          </div>
          <BigContentBox>
            <ContentBoxHeading>
              <ContentBoxHeadingText>공지사항</ContentBoxHeadingText>
              <DetailBtn
                type="button"
                onClick={() => {
                  navigate('/manage/news/notice');
                }}
              >
                <img src={PlusIcon} alt="" />
                더보기
              </DetailBtn>
            </ContentBoxHeading>
            {/* 공지사항 테이블 */}
            <HomeNoticeTable notices={notices} />
          </BigContentBox>
        </RightSection>
      </MainSection>
      <ModalComponent open={open} setOpen={setOpen}>
        <CustomCalendar />
      </ModalComponent>
      <EventPopup />
    </HomeBase>
  );
};

export default Home;

import common from './utils.common';

/**
 * 배열 관련 함수를 제공하는 객체입니다.
 * @type {Object}
 */
let array = {
  /**
   * 배열의 모든 요소를 더합니다.
   * @param {Array} arr - 숫자 배열
   * @returns {number} 배열의 모든 요소의 합, 배열이 아닌 경우 0을 반환
   */
  sum(arr) {
    if (common.isArray(arr)) {
      return arr.reduce((a, b) => a + b);
    } else {
      return 0;
    }
  },
  /**
   * 배열의 평균을 계산합니다.
   * @param {Array} arr - 숫자 배열
   * @returns {number} 배열의 평균, 배열이 아닌 경우 0을 반환
   */
  average(arr) {
    if (common.isArray(arr)) {
      return this.sum(arr) / arr.length;
    } else {
      return 0;
    }
  },
  /**
   * 배열에서 중복된 요소를 제거합니다.
   * @param {Array} array - 중복 요소가 포함될 수 있는 배열
   * @returns {Array} 중복 요소가 제거된 새 배열
   */
  distinct(array) {
    const set = new Set(array);
    return [...set];
  },
};

Object.freeze(array);
export default array;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Base } from '../FrameElements';
import PartnerList from './InfoList/Partner/PartnerList';
import BusinessList from './InfoList/Business/BusinessList';
import SoodalPlus from './InfoList/SoodalPlus/SoodalPlus';
import PrivacyList from './InfoList/Privacy/PrivacyList';

const Info = () => {
  return (
    <Base>
      <Routes>
        <Route path="partnerInfo" element={<PartnerList />} />
        <Route path="partnerInfo/:info" element={<PartnerList />} />
        <Route path="businessInfo" element={<BusinessList />} />
        <Route path="businessInfo/:info" element={<BusinessList />} />
        <Route path="soodalPlus" element={<SoodalPlus />} />
        <Route path="privacy" element={<PrivacyList />} />
        <Route path="privacy/:info" element={<PrivacyList />} />
      </Routes>
    </Base>
  );
};

export default Info;

import { styled } from 'styled-components';
import basicProfile from 'assets/soodalProfile.png';
import { Avatar } from '@mui/material';
import DEFAULT_PROFILE from './../../../assets/default_profile.jpg';

const ProfileFrame = styled.div`
  width: ${(props) => props.width ?? '100%'} !important;
  height: ${(props) => props.height ?? '100%'} !important;
  border-radius: 50%;

  border: 1px solid #ddd;
  overflow: hidden;
  background-color: #fff;
  &.error {
    img {
      object-fit: contain;
    }
  }
`;
const baseUrl = process.env.REACT_APP_IMAGE_VIEW_URL;

const ProfileImage = ({ kind, id, width, height, htmlId, alwaysRefresh, partProfile }) => {
  return (
    <ProfileFrame className={'profile-image'} width={width} height={height}>
      {id && (
        <Avatar
          src={!!partProfile ? `${baseUrl}${kind}/${id}${alwaysRefresh ? '?' + Date.now() : ''}` : DEFAULT_PROFILE}
          id={htmlId}
          onError={(e) => {
            e.target.src = basicProfile;
            if (e.target.parentNode.className.includes('error') === false) {
              e.target.parentNode.className += ' error';
            }
          }}
          onLoad={(e) => {
            if (e.target.src.startsWith(baseUrl)) {
              e.target.parentNode.className = e.target.parentNode.className.replaceAll('error', '').trim();
            }
          }}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}
    </ProfileFrame>
  );
};

export default ProfileImage;

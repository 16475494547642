import events from 'events';
let ev = new events.EventEmitter();

let event = {
    on(eventName, listener){
        this.off(eventName)
        ev.on(eventName, listener);
    },
    once(eventName, listener){
        ev.once(eventName, listener);
    },
    off(eventName){
        ev.removeAllListeners(eventName);
    },
    emit(eventName, ...data){
        ev.emit(eventName, ...data);
    },
}
Object.freeze(event);
export default event;
import checkIcon from 'assets/checkbox-icon/5-4.png';
import styled from 'styled-components';

export const StyledInput = styled.input`
  appearance: none;
  width: 25px;
  height: 25px;
  background-image: url(${checkIcon});
  background-size: 20px 15px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #8a8a8a;
  border-radius: 3px;
  border: solid 1px #8a8a8a;
  cursor: pointer;

  &:checked {
    background-color: #7f6eff;
  }

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    width: 20px;
    height: 20px;
  }

  /* 테블릿 가로 (해상도 768px ~ 1024px)*/
  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 18px;
    height: 18px;
  }

  /* 모바일 가로 (해상도 480px) */
  @media all and (max-width: 480px) {
    width: 18px;
    height: 18px;
    background-size: 12px 9px;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.label`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
`;

export const StyledP = styled.label`
  margin-left: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: normal;

  /* 해상도 1025px ~ 1440px */
  @media all and (min-width: 1025px) and (max-width: 1440px) {
    font-size: 16px;
  }

  /* 테블릿 가로 (해상도 768px ~ 1024px)*/
  @media all and (min-width: 768px) and (max-width: 1024px) {
    font-size: 15px;
  }

  /* 모바일 가로 (해상도 480px)*/
  @media all and (max-width: 480px) {
    width: 64%;
    font-size: 12px;
  }
`;

export const StyledBtn = styled.button`
  outline: none;
  border: none;
  color: #8a8a8a;
  font-size: 20px;
  font-weight: normal;
  background: transparent;
  cursor: pointer;
  margin-left: 5px;

  @media all and (max-width: 1024px) {
    font-size: 16px;
  }

  //모바일
  @media all and (max-width: 480px) {
    font-size: 12px;
  }
`;

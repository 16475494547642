import utils from 'modules/utils';
import styled from 'styled-components';
import InternProfileItemBox from './Parts/InternProfileItemBox';
import WindowFrame from './WindowFrame';
//import Icon from "../Icon";
import { useEffect, useState } from 'react';
import { SubmitBtn } from './ModalElements';

const ProfileBox = styled.section``;

const InternProfileModal = ({ open, close, title, userId, select, disabled }) => {
  const defaultInfo = [
    { title: '희망직종', infoList: [] },
    { title: '경력사항', infoList: [] },
    { title: '보유 자격 면허', infoList: [] },
    { title: '직업훈련 이수이력', infoList: [] },
    { title: '한줄소개', infoText: [] },
  ];
  const [profileInfo, setProfileInfo] = useState(defaultInfo);
  useEffect(() => {
    if (open === false) return;
    utils.http.setMode('api');
    let internProfile = [];
    for (let pNo = 1; pNo <= 6; pNo++) {
      internProfile.push(utils.http.get(`/v1/SP_I_internProfile_s${pNo}/${userId}`));
    }

    Promise.allSettled(internProfile).then((resList) => {
      setProfileInfo((profileInfo) => {
        profileInfo = [...defaultInfo];
        resList.forEach(({ value: { data } }, idx) => {
          if (data?.data.length) {
            let pIdx = idx + 1;

            if (pIdx === 1) {
              profileInfo[0].infoList.push([]);
              profileInfo[0].infoList[0].push({ title: '희망직무', content: data.data[0].internContent ?? '' });
              profileInfo[4].infoText.push(data.data[0].internIntroduce ?? '');
            } else if (pIdx === 2) {
              profileInfo[1].infoList = data.data.map((x) => {
                let period = utils.date.compare(x.interStartDt, x.interEndDt);
                return [
                  { title: '회사명', content: x.interCompName ?? '' },
                  {
                    title: '근무기간',
                    content: (x.interStartDt ?? '').substring(0, 7).replace('-', '.') + ' ~ ' + (x.interEndDt ?? '').substring(0, 7).replace('-', '.') + (isFinite(period) ? ` (${Math.floor(isFinite(period) / 31) + 1}개월)` : ''),
                  },
                  { title: '주요업무', content: x.interContent ?? '' },
                ];
              });
            } else if (pIdx === 3) {
              profileInfo[2].infoList =
                data?.data.length > 0 &&
                data.data.map((x) => {
                  return [
                    { title: '자격면허', content: x.intelLicense ?? '' },
                    { title: '발급기관', content: x.intelLicenseComp ?? '' },
                    { title: '취득일자', content: (x.intelDt ?? '').substring(0, 7).replace('-', '.') },
                  ];
                });
            } else if (pIdx === 4) {
              profileInfo[3].infoList =
                data?.data.length > 0 &&
                data.data.map((x) => {
                  return [
                    { title: '훈련과정', content: x.intetName ?? '' },
                    {
                      title: '훈련기간',
                      content: (x.intetStartDt ?? '').substring(0, 7).replace('-', '.') + ' ~ ' + (x.intetEndDt ?? '').substring(0, 7).replace('-', '.'),
                    },
                    { title: '훈련기관', content: x.intetComp ?? '' },
                    { title: '주요활동', content: x.intetContent ?? '' },
                  ];
                });
            } else if (pIdx === 5) {
              profileInfo[0].infoList[0].unshift({ title: '희망직종', content: data.data.map((x) => x.cateName).join(', ') });
            } else if (pIdx === 6) {
              profileInfo[0].infoList[0].splice(1, 0, { title: '희망지역', content: data.data.map((x) => x.city + '>' + x.regionName).join(', ') });
            }
          }
        });
        console.log(profileInfo);
        return [...profileInfo];
      });
    });
  }, [open]);
  return (
    <WindowFrame
      open={open}
      close={() => {
        setProfileInfo(defaultInfo);
        utils.isFunction(close) && close();
      }}
      title={title}
      width={'866px'}
      height={'90vh'}
      slotFixedButton={
        select && (
          <SubmitBtn
            type="button"
            disabled={disabled}
            onClick={async () => {
              if (await utils.dialog.confirm('인턴 선택', title + '님을 선택하시겠습니까?', 'question')) {
                utils.isFunction(select) && select();
                utils.isFunction(close) && close();
              }
            }}
          >
            선택
          </SubmitBtn>
        )
      }
    >
      <ProfileBox>{profileInfo.map((profile, idx) => (profile.infoList?.length > 0 || profile.infoText?.length > 0) && <InternProfileItemBox title={profile.title} infoList={profile.infoList} infoText={profile.infoText} key={utils.toJSON(profile) + idx} />)}</ProfileBox>
    </WindowFrame>
  );
};

export default InternProfileModal;

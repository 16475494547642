import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Base } from '../FrameElements';
import TicketList from './TicketList/TicketList';
import PurchaseHistory from './PurchaseHistory/PurchaseHistory';

const Ticket = () => {
  return (
    <Base>
      <Routes>
        <Route path="ticketList" element={<TicketList />} />
        <Route path="purchaseHistory" element={<PurchaseHistory />} />
      </Routes>
    </Base>
  );
};

export default Ticket;

import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

export const menuItem = [
  {
    title: '홈',
    icon: HomeOutlinedIcon,
    to: 'home'
  },
  {
    title: '수달소식',
    icon: EventNoteOutlinedIcon,
    // dropMenu: ['공지사항', 'FAQ', '이벤트', '소통해요'],
    // subMenuLink: ['notice', 'faqList/faq', 'event', 'questionList/question'],
    dropMenu: ['공지사항', '고객센터', '이벤트', '질문해요'],
    subMenuLink: ['notice', 'faqList/faq', 'event', 'questionList/question'],
    to: 'news'
  },
  {
    title: '필수정보',
    icon: SettingsOutlinedIcon,
    dropMenu: ['달인정보', '사업자정보', '내노출지역', '개인정보'],
    subMenuLink: ['partnerInfo', 'businessInfo', 'soodalPlus', 'privacy'],
    to: 'info'
  },
  {
    title: '달인내역',
    icon: ReceiptLongOutlinedIcon,
    dropMenu: ['요청목록', '긴급출동', '자동견적', '견적상담', '수리상품', '리뷰관리'],
    subMenuLink: ['estimateList/received', 'emergencyList/received', 'autoEstimateList', 'chat', 'soodalBoard', 'review'],
    to: 'history'
  },
  // {
  //   title: '거래내역',
  //   icon: 'receipt_long',
  //   dropMenu: ['매출조회', '출금관리'],
  //   subMenuLink: ['salesHistory', 'withdrawManage'],
  //   to: 'transaction',
  // },
  {
    title: '달인인턴',
    icon: BadgeOutlinedIcon,
    dropMenu: ['채팅목록', '구인내역'],
    subMenuLink: ['intern', 'jobPosting'],
    to: 'intern'
  },
  {
    title: '구독권',
    icon: CardMembershipOutlinedIcon,
    dropMenu: ['구독하기', '구독내역'],
    subMenuLink: ['ticketList', 'purchaseHistory'],
    to: 'ticket'
  }
];

export const profileDropDown = [
  { title: '달인정보수정', icon: StorefrontOutlinedIcon, action: 'infoPartner' },
  { title: '개인정보수정', icon: ManageAccountsOutlinedIcon, action: 'infoPrivate' },
  { title: '구독권', icon: CardMembershipOutlinedIcon, action: 'ticket' },
  { title: '1:1문의', icon: QuestionAnswerOutlinedIcon, action: 'qna' },
  { title: '로그아웃', icon: LogoutOutlinedIcon, action: 'logout' }
];

export const profileDropDownMobile = [
  { title: '구독권', icon: CardMembershipOutlinedIcon, action: 'ticket' },
  { title: '로그아웃', icon: LogoutOutlinedIcon, action: 'logout' }
];

export const homeEstimate = [
  { title: '견적문의 드립니다.', date: '2023.05.20' },
  { title: '전체 리모델링 금액 얼마인가요?', date: '2023.02.20' }
];

export const infoModalData = [
  {
    title: '요청목록',
    descript: '고객들의 견적 요청을 확인하고, "견적제출" 버튼을 눌러 견적서를 제출할 수 있습니다.'
  }
];

export const infoModalEmergency = [
  {
    title: '긴급출동',
    descript: '긴급으로 수리가 필요한 고객이 요청한 글입니다. "출동하기"을 눌러 출장비, 도착예상시간을 입력 후, 매칭을 기다려보세요.'
  }
];

export const comumnicationInfo = [
  {
    title: '질문해요',
    descript: '고객이 등록한 궁금한 질문에 답변을 할 수 있습니다.'
  }
];

export const estimateModal = [
  {
    id: 1,
    title: '견적금액'
  },
  {
    id: 2,
    title: '견적 내용'
  },
  {
    id: 3,
    title: '사진 첨부'
  }
];

export const questionModal = [
  {
    id: 1,
    title: '유형'
  },
  {
    id: 2,
    title: '카테고리'
  },
  {
    id: 3,
    title: '제목'
  },
  {
    id: 4,
    title: '내용'
  },
  {
    id: 5,
    title: '사진'
  }
];

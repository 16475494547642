import React, { useState, useEffect } from "react";
import {
    TableWrap,
    TableHeader,
    Head,
    TableBody,
} from "./HistoryTableElements";
import { Scrollbars } from "react-custom-scrollbars-2";
import utils from "modules/utils";

const HistoryTable = ({ result }) => {
    const [showTable, setShowTable] = useState(false);
    const headerText = [
        "날짜",
        "거래수(건)",
        "카드수수료(원)",
        "매출액(원)",
        "공급가(원)",
        "부가세(원)",
    ];

    useEffect(() => {
        if (result.length > 0) {
            setShowTable(true);
        } else {
            setShowTable(false);
        }
    }, [result]);
    return (
        <>
            {showTable && (
                <TableWrap>
                    <TableHeader>
                        {headerText.map((el, index) => (
                            <Head key={`${el}-${index}`}>{el}</Head>
                        ))}
                    </TableHeader>
                    <Scrollbars
                        className="scrollbar-container"
                        renderThumbVertical={(props) => (
                            <div {...props} className="thumb-vertical" />
                        )}
                    >
                        <TableBody>
                            {result.map((li, index) => (
                                <React.Fragment key={`sales-${index}`}>
                                    <div className="data">{li.date}</div>
                                    <div className="data">{li.row}</div>
                                    <div className="data">
                                        {utils.format.extractNumber(li.payChargeMony, ["format"])}
                                    </div>
                                    <div className="data">
                                        {utils.format.extractNumber(li.payTakeMony, ["format"])}
                                    </div>
                                    <div className="data">
                                        {utils.format.extractNumber(li.paySupply, ["format"])}
                                    </div>
                                    <div className="data" style={{ borderRight: "none" }}>
                                        {utils.format.extractNumber(li.payVat, ["format"])}
                                    </div>
                                </React.Fragment>
                            ))}
                            {result.length < 7 &&
                                utils.range(7 - result.length).map((li, index) => (
                                    <React.Fragment key={`sales-none-${index}`}>
                                        <div className="data">{""}</div>
                                        <div className="data">{""}</div>
                                        <div className="data">{""}</div>
                                        <div className="data">{""}</div>
                                        <div className="data">{""}</div>
                                        <div className="data" style={{ borderRight: "none" }}>
                                            {""}
                                        </div>
                                    </React.Fragment>
                                ))}
                        </TableBody>
                    </Scrollbars>
                </TableWrap>
            )}
        </>
    );
};

export default HistoryTable;

import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import WithdrawListModal from 'components/Parts/Common/Modal/WithdrawListModal';
import Icon from 'components/Parts/Common/Icon';
import utils from 'modules/utils';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
const Container = styled.div`
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }

  .content-box {
    width: calc(100% - 40px);
  }

  .btn-section {
    width: 40px;
    height: inherit;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      font-size: 29px;
    }
  }
`;
const Wrap = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  background-color: #212838;
  border-bottom: 1px solid #37465b;
  &:last-child {
    border-bottom: none;
  }

  .title-section {
    width: 131px;
    height: 100%;
    color: #fff;
    font-size: 20px;
    font-weight: normal;
    border-right: 1px solid #37465b;
    text-align: center;
    padding: 7px 15px;
  }

  .content-section {
    width: calc(100% - 131px);
    padding: 7px 17px 5px 27px;
    display: flex;
    justify-content: space-between;
    align-item: center;
    height: 100%;
    color: #fff;
    font-size: 20px;
    font-weight: normal;
  }

  .progress-stat-bar {
    width: 70px;
    height: 28px;
    padding: 0px 10px;
    border-radius: 12.5px;
    background-color: #7e6eff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .refuse-stat-bar {
    width: 70px;
    height: 28px;
    padding: 0px 10px;
    border-radius: 12.5px;
    background-color: #ff4b4b;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .complete-stat-bar {
    width: 70px;
    height: 28px;
    padding: 0px 10px;
    border-radius: 12.5px;
    background-color: #8a8a8a;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
`;

const WithdrawApplyList = ({ applyList }) => {
  const [showList, setShowList] = useState(false);
  const titleList = ['신청일자', '정산유형', '신청금액', '실지급액', '비고'];
  const dataList = ['date', 'monyGubn', 'atm_mony', 'mony', 'statusName'];

  // 출금 신청 내역 모달 state
  const [withdrawModal, setWithdrawModal] = useState({
    open: false,
    data: {},
  });

  useEffect(() => {
    if (applyList !== undefined && applyList.length > 0) {
      setShowList(true);
    } else {
      setShowList(false);
    }
  }, [applyList]);
  return (
    <>
      {showList &&
        applyList.map((el, index) => (
          <Container key={`apply-list-${index}`}>
            <div className="content-box">
              {utils.range(5).map((item, index) => (
                <Wrap key={`wrap-${item}-${index}`}>
                  <div className="title-section">{titleList[item]}</div>
                  <div className="content-section">
                    {titleList[item] === '신청금액' || titleList[item] === '실지급액' ? `${utils.format.number(el[dataList[item]])}원` : el[dataList[item]]}
                    {titleList[item] === '신청일자' && <div className={el.statusName === '진행중' ? 'progress-stat-bar' : el.statusName === '거절' ? 'refuse-stat-bar' : 'complete-stat-bar'}>{el.statusName}</div>}
                  </div>
                </Wrap>
              ))}
            </div>
            <button
              className="btn-section"
              type="button"
              onClick={() => {
                setWithdrawModal({
                  open: true,
                  data: el,
                });
              }}
            >
              <ArrowForwardIosRoundedIcon />
            </button>
          </Container>
        ))}
      <WithdrawListModal open={withdrawModal.open} close={() => setWithdrawModal({ open: false, data: {} })} title="출금 신청 내역" data={withdrawModal.data} />
    </>
  );
};

export default WithdrawApplyList;

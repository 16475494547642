import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import ApplyModal from 'components/Parts/Common/Modal/ApplyModal';
import dayjs from 'dayjs';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TopSection } from '../Info/InfoElements';
import Bill from './Bill';
import Details from './Details';
import { Content, WithdrawReqBtn } from './TransactionElements';
const modalStyle = {
  width: '575px',
  height: '105px',
};
const infoModalData = <>거래 완료 후, "출금 신청하기"버튼을 눌러 1,000원 이상 금액을 등록한 계좌로 정산을 받을 수 있습니다.</>;
const WithdrawManage = () => {
  const today = dayjs();
  const navigate = useNavigate();

  const [statusData, setStatusData] = useState([]);
  const getStatus = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.post('/v1/SP_p_partnerSale_S2', null, null);
      //console.log(res.data);
      if (res.data.data && res.data.data.length > 0) {
        setStatusData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [possibleMoney, setPossibleMoney] = useState('');

  // 출금 신청 모달
  const [applyModal, setApplyModal] = useState(false);

  const openModal = () => {
    setApplyModal(true);
  };

  const closeModal = () => {
    setApplyModal(false);
  };

  // 계좌번호 state
  const [account, setAccount] = useState('');

  // 계좌 조회
  const getAccount = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get('/v1/SP_p_partBusiInfo_S1');
      if (res.data.data && res.data.data.length > 0) {
        setAccount(`(${res.data.data[0].busiBankName}) ${res.data.data[0].busiAccount}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 10월 1일 이후 출금관리 페이지 이용가능
  const [isAvailable, setIsAvailable] = useState(false);

  const handleGoBack = () => {
    // 현재 날짜가 10월 1일 이전이면 알림창 띄우고 이전 페이지로 이동
    if (today.isBefore('2023-10-01')) {
      utils.dialog.alert('', '출금관리 페이지는 2023년 10월 1일 이후</br> 이용 가능합니다.', 'warning').then((res) => {
        navigate(-1);
      });
    } else {
      console.log('today', today.isBefore('2021-10-01'));

      setIsAvailable(true);
    }
  };

  useEffect(() => {
    // handleGoBack();
    getStatus();
    getAccount();
  }, []);

  useEffect(() => {
    if (statusData.length > 0) {
      setPossibleMoney(statusData[0].posibleMony);
    }
  }, [statusData]);

  return (
    <>
      <TopSection>
        <ApplyModal open={applyModal} close={closeModal} title="출금 신청" possibleMoney={possibleMoney} account={account} getStatus={getStatus} />
        <SmallInfoModal menu="출금관리" style={modalStyle} infoModalData={infoModalData} />
        <WithdrawReqBtn type="button" onClick={openModal}>
          출금 신청
        </WithdrawReqBtn>
      </TopSection>

      <Content>
        <Bill statusData={statusData} />
        <Details getStatus={getStatus} />
      </Content>
    </>
  );
};

export default WithdrawManage;

import React from "react";
import styled from "styled-components";
import InternProfileItemEach from "./InternProfileItemEach";
import utils from "modules/utils";
const ItemBox = styled.section`
  margin-bottom: 30px;
  &:last-child{
    margin-bottom: 0;
  }
  .box-partition{
    background: #fff;
    padding: 16px 30px;
    border-radius: 15px;

    font-size: 18px;
    text-align: left;
    color: #10141c;
  }
  .item-title{
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    margin-bottom: 14px;
  }
`
const InternProfileItemBox = ({title, infoList, infoText}) => {
    return (
        <ItemBox>
            <div className={"item-title"}>{title}</div>
            {infoList ? (
                <div className={"item-info-box"}>
                    {infoList.map((info)=>(
                        <InternProfileItemEach info={info} key={utils.toJSON(info)}/>
                    ))}
                </div>
            ) : infoText && (
                <div className={"box-partition"}>
                    {infoText.map(info=>(
                        <p key={info}>{info}</p>
                    ))}
                </div>
            ) }
        </ItemBox>
    );
};

export default InternProfileItemBox;

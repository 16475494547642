import React, { useState, useEffect } from 'react';
import { Form, SubmitBtn } from './ModalElements';
import { BdWhiteText } from 'style';
import utils from 'modules/utils';
import CheckBox from '../forms/CheckBox/CheckBox';
import { Card } from 'components/Manage/Ticket/TicketList/TicketListElements';
import { styled } from 'styled-components';
import dayjs from 'dayjs';
import SingleCheckBox from '../forms/CheckBox/SingleCheckBox';
import Modal from 'components/RegisterForm/ShowLawModal/ShowLawModal';
import { useMediaQuery } from 'react-responsive';
import MobileModalBasic from 'components/Manage/MobileModal/MobileComponents/MobileModalBasic';
import { partInfoStore } from 'modules/store/store';
import { useNavigate } from 'react-router-dom';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const termsAndConsitions = ['모두 동의합니다.', '(필수)개인정보 수집 및 이용 동의', '(필수)정기 결제 서비스 이용 약관 동의'];

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const CardLabel = styled.div`
  width: 200px;
  font-size: 22px;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    width: 100px;
    font-size: 14px;
  }
`;

export const CardLabelSub = styled.div`
  width: 200px;
  font-size: 18px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 100px;
    font-size: 12px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;

  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`;

export const TitleIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  @media screen and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

const CardListRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: #212838;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
`;

const DeleteBtn = styled.div`
  font-size: 14px;
  color: #8a8a8a;
  cursor: pointer;
  padding: 4px 10px;
  /* hover */
  &:hover {
    color: var(--mint-color);
  }
`;

const MobilePage = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
`;

//env 에서 REACT_APP_BACKEND_URL_HTTP 가져오기
// const testUrl = process.env.REACT_APP_BACKEND_URL_HTTP;
// const postUrl = (url, obj) => {
//   let postUrl = testUrl + url + '?';
//   for (let key in obj) {
//     postUrl += key + '=' + encodeURIComponent(obj[key]) + '&';
//   }
//   postUrl = postUrl.slice(0, -1);
//   return postUrl;
// };

const IMP = window.IMP;

const JobPostingMobileModal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, title } = props;
  const { selectedTicket } = partInfoStore();
  const [partInfo, setPartInfo] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(false); // 구독권 구독중인지 확인

  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const userId = utils.user('id');

  const [cardList, setCardList] = useState([]); // 카드 리스트
  const [selectedCard, setSelectedCard] = useState({}); // 선택된 카드

  // 모달창
  const [개인정보수집이용, set개인정보수집이용] = useState(false);
  const [정기결제서비스약관, set정기결제서비스약관] = useState(false);

  // 월 정기결제
  const [autoPayment, setAutoPayment] = useState(true); // 정기결제

  // checkbox state
  const [checkList, setCheckList] = useState([]);

  // checkbox 개별 체크
  const onCheckedElement = (e) => {
    e.target.checked ? setCheckList([...checkList, e.target.name]) : setCheckList(checkList.filter((choice) => choice !== e.target.name));
    console.log(checkList);
  };

  const handleGoTicketList = () => {
    navigate('/manage/ticket/ticketList');
  };

  const savePaymentLog = async (res) => {
    const monthlyPaymentUrl = `/v1/SP_b_user_ticket_log_I1`;
    const autoPaymentUrl = `/v1/SP_b_user_Card_I1`;
    const data = {
      apply_num: res.apply_num ?? '',
      bank_name: res.bank_name ?? '',
      buyer_addr: res.buyer_addr ?? '',
      buyer_email: res.buyer_email ?? '',
      buyer_name: res.buyer_name ?? '',
      buyer_postcode: res.buyer_postcode ?? '',
      buyer_tel: res.buyer_tel ?? '',
      card_name: res.card_name ?? '',
      card_number: res.card_number ?? '',
      card_quota: res.card_quota ?? '',
      currency: res.currency ?? '',
      custom_data: res.custom_data ?? '',
      customer_uid: res.customer_uid ?? '',
      imp_uid: res.imp_uid ?? '',
      merchant_uid: res.merchant_uid ?? '',
      name: res.name ?? '',
      paid_amount: res.paid_amount ?? '',
      paid_at: res.paid_at ?? '',
      partLevel: selectedTicket?.level_name ?? '', //partLevel 구독권 코드(bronze, silver, gold, platinum, diamond)
      pay_method: res.pay_method ?? '',
      pg_provider: res.pg_provider ?? '',
      pg_tid: res.pg_tid ?? '',
      pg_type: res.pg_type ?? '',
      receipt_url: res.receipt_url ?? '',
      status: res.status ?? '',
      success: res.success ?? '',
      ticket_gubn: autoPayment ? 'routine' : 'common', // common승인, routine정기결제, cancel해지
    };
    try {
      utils.http.setMode('api');
      const res = await utils.http.post(autoPayment ? autoPaymentUrl : monthlyPaymentUrl, null, data);
      if (res.data.success) {
        console.log('결제 로그 저장 성공');
      } else {
        console.log('결제 로그 저장 실패', res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 파트너 정보 조회
  const getPartnerInfo = async () => {
    const url = `/v1/SP_p_partner_S1/${userId}`;
    utils.http.setMode('api');
    const getInfo = await utils.http.get(url);
    return (await getInfo.data?.data[0]) ?? {};
  };

  // 구독권 구독중인지 확인
  const checkSubscribed = async () => {
    const url = `/v1/SP_b_user_ticket_schedule_S4`;
    utils.http.setMode('api');
    const getData = await utils.http.get(url);
    return getData.data.data?.length > 0;
  };

  // 카드 리스트 조회
  const getCardList = () => {
    const url = `/v1/SP_b_user_card_s1`;
    utils.http.get(url).then((response) => {
      console.log('cardList', response.data.data);
      setCardList(response.data.data);
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // 필수 항목 체크
    // autoPayment가 true면 정기결제서비스약관도 필수
    if (!checkList.includes('개인정보수집이용')) {
      utils.dialog.alert('개인정보 수집 이용', '개인정보 수집 이용에 동의해 주세요.', 'warning');
      return;
    }
    if (autoPayment && !checkList.includes('정기결제서비스약관')) {
      utils.dialog.alert('정기 결제 서비스 이용 약관 동의', '정기 결제 체크 시 정기 결제 서비스 이용 약관 동의가 필요합니다.', 'warning');
      return;
    }

    // 예약중 가져오기
    const checkSubscribedRes = await checkSubscribed();
    const getPartnerInfoRes = await getPartnerInfo();

    if (getPartnerInfoRes.partLevel <= 1 && checkSubscribedRes) {
      utils.dialog.alert('예약중', '이미 예약중인 구독권이 있습니다.', 'warning');
      setIsSubscribed(true);
      return;
    }

    // 구독권 사용중이면 구독권 변경
    if (getPartnerInfoRes.partLevel > 1) {
      changeTicket();
      return;
    }
    // 정기결제 유무
    autoPayment ? autoPaymentSubmit() : showPortOneModal();
  };

  const handleDeleteCard = (card) => {
    // confirm 창 띄우고 확인 누르면 삭제

    utils.dialog.confirm('카드 삭제', '카드를 삭제하시겠습니까?', 'question', '', '삭제').then((res) => {
      if (res) {
        const url = `/v1/delBillingCard`;
        const data = {
          customer_uid: card.customer_uid,
        };
        utils.http.setMode('api');
        utils.http.post(url, null, data).then((res) => {
          if (res.data.success) {
            utils.dialog.alert('카드 삭제', '카드가 삭제되었습니다.', 'success');
          } else {
            utils.dialog.alert('카드 삭제', '카드 삭제가 실패되었습니다.', 'warning');
          }
          getCardList();
        });
      }
    });
  };

  const autoPaymentSubmit = () => {
    //selectCard 가 빈객체면 알림창 띄우고 리턴
    if (Object.keys(selectedCard).length === 0) {
      utils.dialog.alert('카드 선택', '카드를 선택해 주세요.', 'warning');
      return;
    }

    const url = `/v1/setImportAgain`;
    const data = {
      amount: selectedTicket?.sale_price,
      customer_uid: selectedCard?.customer_uid,
      merchant_uid: `${utils.user('id')}_${selectedTicket?.level_name}_${dayjs().format('YYYYMMDDHHmmss')}`,
      name: selectedTicket?.level_name,
    };

    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      console.log('res', res);

      if (res.data.success) {
        utils.dialog.alert('결제 성공', '결제가 완료되었습니다.', 'success').then((res) => {
          res && handleGoTicketList();
        });
        console.log('결제성공', res);
      } else {
        utils.dialog.alert('결제 실패', '결제가 실패되었습니다.', 'warning');
        console.log('결제실패', res);
      }
    });
  };

  // 결제창 또는 카드등록창 호출
  const showPortOneModal = () => {
    // TODO: 포트원 식별코드
    const userCode = 'imp22166141';
    const userId = utils.user('id');

    IMP.init(userCode);

    const onePayObj = {
      // pg: 'kicc.{이니시스연동}', // 실제 계약 후에는 실제 상점아이디로 변경
      pg: 'html5_inicis.MOIsoobill', // 실제 계약 후에는 실제 상점아이디로 변경
      buyer_name: userId, // 구매자 아이디
      pay_method: 'card', // 'card'만 지원됩니다.
      merchant_uid: `${userId}_${selectedTicket?.level_name}_${dayjs().format('YYYYMMDDHHmmss')}`, // 우리가 관리하는 주문번호 soodal_GOLD_202309_타임스템프
      name: selectedTicket?.level_name, // 결제창에서 보여질 상품 이름
      amount: selectedTicket?.sale_price, // 가격
    };

    const autoPayObj = {
      ...onePayObj,
      customer_uid: `${userId}_billing_${dayjs().format('YYYYMMDDHHmmss')}`, // 우리가 관리하는 카드번호 ID_billing_202308 체크박스 유무에 따라
      name: '카드등록',
      amount: 0,
    };

    IMP.request_pay(autoPayment ? autoPayObj : onePayObj, function (res) {
      console.log('res', res);

      if (res.success) {
        utils.dialog.alert(autoPayment ? '카드 등록 성공' : '결제 성공', autoPayment ? '카드 등록이 완료되었습니다.' : '결제가 완료되었습니다.', 'success').then((res) => {
          if (res && !autoPayment) handleGoTicketList();
        });
        console.log('성공', res);
        savePaymentLog(res);
      } else {
        utils.dialog.alert(autoPayment ? '카드 등록 실패' : '결제 실패', autoPayment ? '카드 등록이 실패되었습니다.' : '결제가 실패되었습니다.', 'warning');
        console.log('실패', res);
      }
      setTimeout(() => {
        getCardList();
      }, 1000);
    });
  };

  const changeTicket = () => {
    const url = `/v1/setIamportScheduleChange`;
    const data = {
      part_level: selectedTicket?.level_name,
      customer_uid: selectedCard.customer_uid,
    };
    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      if (res.data.success) {
        utils.dialog.alert('구독권 변경', '구독권 변경이 완료되었습니다.', 'success').then((res) => {
          res && handleGoTicketList();
        });
        console.log('구독권 변경 성공', res);
      } else {
        utils.dialog.alert('구독권 변경', '구독권 변경이 실패되었습니다.', 'warning');
        console.log('구독권 변경 실패', res);
      }
    });
  };

  useEffect(() => {
    // getLaw();
    getCardList();
    getPartnerInfo().then((res) => {
      setPartInfo(res);
    });
    checkSubscribed().then((res) => {
      setIsSubscribed(res);
    });
  }, []);

  useEffect(() => {
    // 카드 check와 약관 check 초기화
    setCheckList([]);
    setSelectedCard({});
    setAutoPayment(true);
  }, [open]);

  return (
    <MobileModalBasic
      title={partInfo?.partLevel > 1 ? '구독권 변경' : '결제요청'}
      footer={
        <SubmitBtn style={{ height: '45px', padding: '0' }} type="button" onClick={onSubmit} disabled={selectedTicket?.ticket_gubn === 'common' || selectedTicket?.ticket_level === partInfo?.partLevel || (partInfo?.partLevel <= 1 && isSubscribed)}>
          {partInfo?.partLevel > 1 ? '구독권 변경' : isSubscribed ? '예약중' : '결제요청'}
        </SubmitBtn>
      }
    >
      <Form action="#none" style={{ gap: 0 }}>
        <div className="qna-scroll-area">
          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">선택 상품</BdWhiteText>
            <Card>
              <CardRow>
                <CardLabel>선택구독권</CardLabel>
                <CardContent>
                  <TitleIcon
                    style={{
                      backgroundColor: selectedTicket?.color,
                    }}
                  >
                    {selectedTicket?.icon}
                  </TitleIcon>
                  <div
                    style={{
                      fontSize: isMobile ? '14px' : '22px',
                    }}
                  >
                    {selectedTicket?.level_name}
                  </div>
                </CardContent>
              </CardRow>
              <CardRow
                style={{
                  alignItems: 'flex-start',
                }}
              >
                <CardLabelSub>
                  <SubdirectoryArrowRightIcon />
                  상세내용
                </CardLabelSub>
                <div
                  style={{
                    fontSize: isMobile ? '12px' : '18px',
                  }}
                >
                  <div>{selectedTicket?.content1}</div>
                  <div>{selectedTicket?.content2}</div>
                </div>
              </CardRow>
              <CardRow>
                <CardLabel>이용 기간</CardLabel>
                {/* 현재일에서 한달뒤 까지 */}
                <CardContent>
                  <div>
                    {dayjs(selectedTicket?.st_dt).format(`YYYY년 MM월 DD일`)} ~ {dayjs(selectedTicket?.et_dt).format(`YYYY년 MM월 DD일`)}
                  </div>
                </CardContent>
              </CardRow>
              <CardRow>
                <CardLabel>결제 금액</CardLabel>
                <CardContent
                  style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        fontSize: isMobile ? '14px' : '22px',
                        fontWeight: 'bold',
                        color: 'var(--mint-color)',
                      }}
                    >
                      {selectedTicket?.sale_price.toLocaleString()}원
                    </div>
                    <div>(한달 기준)</div>
                  </div>
                  {partInfo?.partLevel > 1 && (
                    <div
                      style={{
                        fontSize: isMobile ? '10px' : '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      [결제예정일 : {dayjs(selectedTicket?.st_dt).format(`YYYY년 MM월 DD일`)}]
                    </div>
                  )}
                </CardContent>
              </CardRow>
              <CardRow>
                <CardLabel>정기 결제 유무</CardLabel>
                <CardContent>
                  <SingleCheckBox
                    name="정기결제"
                    checked={!!autoPayment}
                    onChangeHandle={(e) => {
                      setAutoPayment(e.target.checked ? 1 : 0);
                    }}
                    text="(체크 시, 매 월 자동 결제 됩니다.)"
                  />
                </CardContent>
              </CardRow>
            </Card>
          </div>
          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">결제 방법</BdWhiteText>
            <Card>
              <div>
                <label htmlFor="credit-card">신용카드 결제</label>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: isMobile ? '4px' : '10px',
                }}
              >
                <div>· 모든 국내 신용카드로 사용 가능합니다.</div>
                <div>· 본인 명의 카드로 결제 가능합니다.</div>
              </div>
            </Card>
          </div>

          {!!autoPayment && (
            <div className="scrollbar-innerContent">
              <BdWhiteText className="input-title">결제 수단</BdWhiteText>
              <Card>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '8px',
                    fontSize: '14px',
                  }}
                >
                  <div>등록 카드 {cardList.length}개</div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      showPortOneModal();
                    }}
                  >
                    <AddRoundedIcon />
                    결제 수단 등록
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  {cardList.map((card, index) => (
                    <CardListRow
                      key={card.customer_uid}
                      onClick={(e) => {
                        setSelectedCard(card);
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          fontSize: isMobile ? '14px' : '18px',
                        }}
                      >
                        <SingleCheckBox
                          name={card.customer_uid}
                          checked={selectedCard.customer_uid === card.customer_uid}
                          onChangeHandle={(e) => {
                            setSelectedCard(e.target.checked ? card : '');
                          }}
                          text=""
                        />
                        <div>
                          <div
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {card.card_name}
                          </div>
                          <div>{card.card_number}</div>
                        </div>
                      </div>
                      <DeleteBtn
                        onClick={() => {
                          handleDeleteCard(card);
                        }}
                      >
                        삭제
                      </DeleteBtn>
                    </CardListRow>
                  ))}
                </div>
              </Card>
            </div>
          )}

          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">구매 동의</BdWhiteText>
            <Card>
              <div style={{ marginBottom: '8px' }}>결제하실 구독권, 금액, 이용 기간 등 내용을 확인하였으며, 구매에 동의하시겠습니까?</div>
              <div className="qna-checkBox">
                <div style={{ marginBottom: '8px' }}>
                  <CheckBox text={termsAndConsitions[1]} name="개인정보수집이용" showBtn={true} btnText="[보기]" modalOpen={() => set개인정보수집이용(true)} changeHandle={(e) => onCheckedElement(e)} checkList={checkList} between />
                  {!!autoPayment && <CheckBox text={termsAndConsitions[2]} name="정기결제서비스약관" showBtn={true} btnText="[보기]" modalOpen={() => set정기결제서비스약관(true)} changeHandle={(e) => onCheckedElement(e)} checkList={checkList} between />}
                </div>
              </div>
              <div
                style={{
                  fontSize: isMobile ? '11px' : '14px',
                }}
              >
                <div>· 신용카드 정보 변경 및 잔액 부족시에는 결제 실패로 정기 결제가 이루어지지 않아 서비스 이용에 제한이 있습니다.</div>
                <div>· 정기 결제 서비스 이용약관 미동의 시, 매월 정기 결제 이용에 제한이 있습니다.</div>
                <div>· 정기 결제 유무 체크 시, 매월 정기 결제가 이루어지며, 다음 달에 자동 결제됩니다.</div>
              </div>
            </Card>
          </div>
        </div>
      </Form>
      <Modal open={개인정보수집이용} close={() => set개인정보수집이용(false)} header="개인정보 수집 이용" gubn={parseInt('13')} />
      <Modal open={정기결제서비스약관} close={() => set정기결제서비스약관(false)} header="정기 결제 서비스 이용 약관 동의" gubn={parseInt('8')} />
    </MobileModalBasic>
  );
};

export default JobPostingMobileModal;

import styled from 'styled-components';

export const Nav = styled.nav`
  height: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const OptionBtn = styled.button`
  background-color: transparent;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
`;

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from 'styled-components';
import utils from '../../../../modules/utils';
import { BdWhiteText, WhiteDot, WtNomalSpan } from '../../../../style';
import EmptyBox from '../EmptyBox';
import GrabImageList from '../Uploader/GrabImageList';
import { FixedText, Form, Header, Input, InputList, InputMoney, ShowContent } from './ModalElements';
import WindowFrame from './WindowFrame';

const ImageViewSection = styled(InputList)``;
const StyleDiv = styled.div`
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
  color: #e9e9e9;

  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const EstimateViewModal = ({ open, close, title, estimateInfo, kind }) => {
  let prefix = kind === 'estimate' ? 'reqd' : 'reqd';
  const [estimateData, setEstimateData] = useState([]);
  const [estimateImages, setEstimateImages] = useState([]);

  const getEstimate = async (dataKind, setEstimate) => {
    console.log('estimateInfo', estimateInfo);

    try {
      let url = dataKind === 'data' ? (kind === 'estimate' ? '/v1/SP_r_Request_s3/' : '/v1/SP_r_ChatEstimate_S1/') : kind === 'estimate' ? '/v1/SP_r_Request_s5/' : '/v1/SP_r_ChatEstimate_S2/';

      url += `${estimateInfo.workDate}/${estimateInfo.sqenNumb}/${utils.fillZero(estimateInfo.headNumb, 20)}`;

      utils.http.setMode('api');
      const res = await utils.http.get(url);
      console.log('모달', res.data.data);
      if (res.data.data && res.data.data.length > 0) {
        setEstimate(dataKind === 'data' ? res.data.data[0] : res.data.data.map((x) => x.fileName));
      } else {
        setEstimate(dataKind === 'data' ? {} : []);
      }
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
      setEstimate(dataKind === 'data' ? {} : []);
    }
  };

  useEffect(() => {
    if (open) {
      getEstimate('data', setEstimateData);
      getEstimate('image', setEstimateImages);
    }
  }, [open]);

  return (
    <WindowFrame open={open} close={close} title={title} width={'789px'} height={kind === 'estimate' ? '819px' : '687px'}>
      <Form action="#none">
        <Header>
          <BdWhiteText style={{ fontSize: '24px' }}> {estimateData.partName}</BdWhiteText>
          <WtNomalSpan style={{ float: 'right' }}>{dayjs(estimateData.workDate).format('YYYY-MM-DD')}</WtNomalSpan>
        </Header>
        <InputList>
          <BdWhiteText>견적 금액</BdWhiteText>
          <InputMoney>
            <Input type="text" readOnly value={utils.format.number(estimateData[prefix + 'Mony'])} />
            <div style={{ width: '80px', textAlign: 'center' }}>
              <FixedText>원 부터~</FixedText>
            </div>
          </InputMoney>
        </InputList>
        <InputList>
          <BdWhiteText>견적 내용</BdWhiteText>
          <ShowContent>
            <Scrollbars className="chat-photo-box scrollbar-container outside-scrollbar" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
              <div
                dangerouslySetInnerHTML={{
                  __html: utils.nl2br(estimateData[prefix + 'Content']),
                }}
              ></div>
            </Scrollbars>
          </ShowContent>
        </InputList>
        <ImageViewSection>
          <BdWhiteText>사진 첨부</BdWhiteText>
          {estimateImages.length > 0 ? <GrabImageList imageList={estimateImages} height={'80px'}></GrabImageList> : <EmptyBox style={{ height: '100px' }}>첨부된 사진이 없습니다.</EmptyBox>}
        </ImageViewSection>
        {kind === 'estimate' && (
          <div>
            <StyleDiv style={{ marginBottom: '14px' }}>유의사항</StyleDiv>
            <StyleDiv>
              <WhiteDot style={{ margin: '0 5px 5px 0' }} />
              상세내용에 누락된 내용이 있는지 확인해 주세요
            </StyleDiv>
            <StyleDiv>
              <WhiteDot style={{ margin: '0 5px 5px 0' }} />
              견적금액에 대해 실제 수리 진행 시 비용이 달라질 수 있는 점 참고해주세요.
            </StyleDiv>
          </div>
        )}
      </Form>
    </WindowFrame>
  );
};

export default EstimateViewModal;

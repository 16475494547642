import WindowFrame from './WindowFrame';
import React, { useEffect, useState } from 'react';
import { Form, Header, InputMoney, Input, InputClearBtn, InputList, FixedText, SubmitBtn } from './ModalElements';
import Uploader from '../Uploader/Uploader';
import moment from 'moment';
import { BdWhiteText, WtNomalSpan } from 'style';
import { IoCloseOutline } from 'react-icons/io5';
import { estimateModal } from 'Data/data';
import utils from 'modules/utils';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import MultiImageUpload from 'components/FileUpload/MultiImageUpload';

const EstimateModal = ({ open, close, title, post, nickName, kind, afterAction }) => {
  const formData = new FormData();
  const navigate = useNavigate();
  const location = useLocation();

  let prefix = kind === 'estimate' ? 'reqd' : 'rcde';

  //  견적서 보낸 유무
  const [estimateSend, setEstimateSend] = useState(false);

  // 견적 금액 state
  const [inputMoney, setInputMoney] = useState('');

  // x 버튼 클릭 시 견적 금액 초기화
  const onClearValue = () => {
    setInputMoney('');
  };

  // 견적 내용 state
  const [estimateContent, setEstimateContent] = useState('');

  //화면에 출력되는 이미지
  const [showImages, setShowImages] = useState([]);
  const [delShowImages, setDelShowImages] = useState([]);

  // 견적 제출 버튼 클릭
  const submitEstimate = async (e) => {
    if (estimateSend) return;
    e.preventDefault();
    setEstimateSend(true);
    // 입력 안된 값이 있으면 alert로 안내
    if (inputMoney === '' || parseInt(inputMoney) === 0 || estimateContent?.trim() === '') {
      utils.dialog.alert('견적서 제출 실패!', '입력되지 않은 내용이 있습니다.<br />견적서를 다시 확인해 주세요.', 'warning');
      setEstimateSend(false);
    } else {
      // 이미지 파일
      showImages.forEach((image) => {
        if (image.localFlag) {
          formData.append('files', image.file);
        }
      });

      formData.append(prefix + `Mony`, utils.format.extractNumber(inputMoney)); // 견적 금액
      formData.append(prefix + `Content`, estimateContent); // 견적 내용
      kind !== 'estimate' && formData.append(prefix + `Type`, 'estimate');
      formData.append('imageCount', showImages.length); // 이미지 개수
      formData.append('sqenNumb', post.sqenNumb);
      formData.append('workDate', post.workDate);
      formData.append('delKey', '');
      utils.http.setMode('api');

      window.xxx = formData;

      let url = kind === 'estimate' ? '/v1/SP_p_partRequestView_i1' : '/v1/SP_r_Chat_i2';
      const res = await utils.http.postFile(url, formData);
      console.log('submitEstimate', res);

      if (res.data.success && res.data.data.length > 0) {
        console.log(res.data);
        utils.dialog.alert('견적서 제출 완료!', '작성하신 견적서가 제출되었습니다!', 'success').then(() => {
          setEstimateSend(false);
          utils.isFunction(close) && close();
          utils.isFunction(afterAction) && afterAction(res.data);
          // 채팅목록 페이지가 아닐때만 이동 location.pathname에 chat이라는 문자열이 있으면 채팅목록 페이지임
          if (location.pathname.includes('chat') === false) navigate('/manage/history/estimateList/sent');
        });
      } else {
        console.log(res.data);
        utils.dialog.alert('견적서 제출 실패!', res.data.message, 'error');
      }
    }
  };

  useEffect(() => {
    if (open) {
      setInputMoney('');
      setShowImages([]);
      setEstimateContent('');
    }
    setEstimateSend(false);
  }, [open]);

  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'787px'}
      height={'761px'}
      slotFixedButton={
        <SubmitBtn type="button" onClick={submitEstimate}>
          견적보내기
        </SubmitBtn>
      }
    >
      <Form action="#none">
        <Header>
          <BdWhiteText style={{ fontSize: '24px' }}>{nickName}</BdWhiteText>
          <WtNomalSpan style={{ float: 'right' }}>{moment().format('YYYY-MM-DD')}</WtNomalSpan>
        </Header>
        <InputList>
          <BdWhiteText>{estimateModal[0].title}</BdWhiteText>
          <InputMoney>
            <Input
              type="text"
              onInput={(e) => {
                const 최대1억 = 100000000;
                e.preventDefault();
                if (parseInt(e.target.value.replace(/,/g, '')) <= 최대1억) {
                  setInputMoney(utils.format.extractNumber(e.target.value, ['format']));
                } else if (e.target.value === '') {
                  setInputMoney('');
                } else if (parseInt(e.target.value.replace(/,/g, '')) > 최대1억) {
                  setInputMoney(utils.format.extractNumber(최대1억, ['format']));
                }
              }}
              value={inputMoney}
              required
              placeholder="견적금액(원)을 입력해 주세요. ex)1,000,000"
            />
            <div style={{ width: '5%', paddingLeft: '4px' }}>
              <FixedText>원</FixedText>
            </div>
            <div style={{ width: '5%', textAlign: 'center' }}>
              <InputClearBtn type="button" onClick={onClearValue}>
                <IoCloseOutline color="#fff" />
              </InputClearBtn>
            </div>
          </InputMoney>
        </InputList>
        <InputList>
          <BdWhiteText>{estimateModal[1].title}</BdWhiteText>
          <div className={'custom-scroll-text-area'} style={{ height: '160px' }}>
            <textarea
              placeholder={`견적 내용을 간략하게 작성해 주세요.\n\n- 주요 서비스\n- 예상 추가 비용\n- 소요 시간`}
              required
              onInput={(e) => {
                setEstimateContent(e.target.value);
              }}
              value={estimateContent}
            ></textarea>
          </div>
        </InputList>
        <InputList>
          <BdWhiteText>{estimateModal[2].title}</BdWhiteText>
          {/* <Uploader maxImage={5} showImages={showImages} setShowImages={setShowImages} setFile={setFile} file={file} /> */}
          <MultiImageUpload accept={'image/jpg, image/jpeg, image/png'} name="qnaModal" imageArr={showImages} setImageArr={setShowImages} setDeleteImageArr={setDelShowImages} isWhite={true} maxImage={5} width={'80px'} height={'80px'} />
          <div
            style={{
              fontSize: '14px',
              color: '#fff',
            }}
          >
            <div>※ 이미지: 20MB이하의 JPG, JPEG, PNG 파일 등록 가능</div>
            <div>※ 최대 5장 등록 가능합니다.</div>
          </div>
        </InputList>
      </Form>
    </WindowFrame>
  );
};

export default EstimateModal;

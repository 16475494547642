import React, { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CallIcon from '@mui/icons-material/Call';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import utils from 'modules/utils';

const SafePhoneNumb = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [userPhone, setUserPhone] = useState('');

  const handleTooltipOpen = () => {
    setOpen(true);
    getUserPhone(userId);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const getUserPhone = (userId) => {
    if (!userId) return;
    const url = `/v1/getsafeNumber/${userId}`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data.success) {
        setUserPhone(res.data.data[0]?.safeNumber);
      }
    });
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={userPhone}
          placement="right"
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '18px',
                backgroundColor: '#1b2335',
                padding: '10px',
                '& .MuiTooltip-popper[data-popper-placement*="right"]': {
                  marginLeft: '6px',
                },
              },
            },
          }}
        >
          <IconButton
            onClick={open ? handleTooltipClose : handleTooltipOpen}
            sx={{
              color: 'white',
            }}
          >
            <CallIcon />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default SafePhoneNumb;

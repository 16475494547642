import styled from 'styled-components';
import arrowUpIcon from 'assets/arrowUp.png';
import arrowDownIcon from 'assets/arrowDown.png';

export const RequsetTable = styled.div`
  background-color: #37465b;
  width: 100%;
  height: 100%;
  padding: 30px 0 28px;
  border-radius: 20px;
  overflow: hidden;
`;

export const TableHeader = styled.div`
  width: 100%;
  padding: 0 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: fit-content;
`;

export const Input = styled.input`
  width: 504px;
  height: 41px;
  padding: 12px 19px;
  outline: none;
  border-radius: 10px;
  border: solid 1px #fff;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-family: 'Noto Sans KR';
  z-index: 1;

  &::placeholder {
    color: #fff;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    height: 36px;
    font-size: 16px;
  }
`;

export const SearchBtn = styled.button`
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 14px;
`;

export const TableBody = styled.div`
  height: calc(100% - 145px);
  width: 100%;

  .qna-content-box {
    width: 100%;
    height: 100%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
`;

/* Faq Table */
export const ArrowIcon = styled.button`
  width: 24px;
  height: 14px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s;
  background-image: url(${arrowDownIcon});

  @media screen and (max-width: 1024px) {
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    /* 바둑판이 아닌 1개만 보이기 */
  }
`;

export const Question = styled.details`
  width: 100%;
  &:nth-child(odd) {
    background-color: #37465b;
  }

  &:nth-child(even) {
    background-color: #212838;
  }

  &[open] ${ArrowIcon} {
    background-image: url(${arrowUpIcon});
  }
`;

export const QuestionList = styled.summary`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 55px;
  padding: 15px 30px;

  &::marker {
    font-size: 0;
  }

  @media screen and (max-width: 1024px) {
    height: 40px;
  }
`;

export const QuestionSubject = styled.span`
  font-size: 20px;
  font-weight: normal;
  color: #fff;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const AnswerWrap = styled.div`
  width: 100%;
  background-color: #10141c;
  padding: 34px 30px 35px 30px;

  @media screen and (max-width: 1024px) {
    padding: 16px 30px;
  }
`;

export const Answer = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  white-space: pre-line;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

// 검색 결과가 없을 시 보여줄 styled component
export const NoneResultWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

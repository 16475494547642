import { Box, Link, Tooltip } from '@mui/material';
import { TicketCount, TicketIcon } from 'components/Manage/Home/HomeElemnts';
import { ticketInfo } from 'components/Manage/Ticket/TicketList/TicketList';
import { partInfoStore } from 'modules/store/store';
import { useEffect } from 'react';
import styled from 'styled-components';

const LevelSection = styled.div`
  min-width: 150px;
  display: flex;
  justify-content: flex-end;
  color: #fff;
`;

const PartnerLevel = () => {
  const { partInfo, getPartInfo } = partInfoStore();
  const ticketInfoDetail = ticketInfo.find((item) => item.title === partInfo.levelName?.toUpperCase());

  useEffect(() => {
    getPartInfo();
  }, []);
  // 구독했을때
  if (partInfo?.partLevel > 1)
    return (
      <LevelSection>
        <TicketCount>
          <TicketIcon
            style={{
              backgroundColor: ticketInfoDetail.color,
              border: '2px solid #fff',
            }}
          >
            {ticketInfoDetail.icon}
          </TicketIcon>
          {ticketInfoDetail.title}
        </TicketCount>
      </LevelSection>
    );

  // 체험판
  if (partInfo?.partLevel === 1)
    return (
      <LevelSection>
        {partInfo.partCount > 0 ? (
          <div>
            <div>무료 구독중</div>
            <Box
              sx={{
                fontSize: '14px',
              }}
            >
              {partInfo.partCount}개
            </Box>
          </div>
        ) : (
          <div>
            <div>무료 구독 만료</div>
            <Link
              href="/manage/ticket/ticketList"
              sx={{
                color: 'var(--mint-color)',
                textDecoration: 'none',
                fontSize: '14px',
              }}
            >
              구독권 보기
            </Link>
          </div>
        )}
      </LevelSection>
    );

  // 승인대기
  return (
    <Tooltip title="⚠️ 필수정보를 입력해주세요." placement="bottom-end">
      <LevelSection>승인이 필요합니다.</LevelSection>
    </Tooltip>
  );
};

export default PartnerLevel;

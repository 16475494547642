import NoticeTable from 'components/Manage/News/Notice/NoticeTable';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { styled } from 'styled-components';
import { ContentWrap } from '../NewsElements';
import { ImgLink } from './../../../Parts/Slide/SlideElements';
import NoticeDetail from './NoticeDetail';

const Content = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  gap: 30px;
`;

export const EventSlideImg = styled.img`
  width: 100%;
  height: 120px;
  border-radius: 10px;
  cursor: pointer;
`;

const Notice = () => {
  const navigate = useNavigate();

  const menuInfo = '수달에서 알려드리는 공지사항 내용입니다.';
  const modalStyle = {
    width: '447px',
    height: '82px'
  };

  // Thead title
  const theadTitles = ['분류', '제목', '등록일'];
  // 선택한 공지사항 글이 없을 때 보여줄 메시지

  // 공지사항 목록 중 클릭한 항목에 대한 state
  const [clickedItem, setClickedItem] = useState(null);

  const [eventData, setEventData] = useState([]);

  const imageAddr = process.env.REACT_APP_IMAGE_VIEW_URL;
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: true,
    customPaging: function (i, a) {
      return (
        <a>
          {i + 1}/{eventData.length}
        </a>
      );
    }
  };

  // 공지사항 목록 조회
  const getNotices = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v2/SP_m_NoticeV2_S2/1/webbanner`);
      //console.log(res.data);
      if (res.data.data.length > 0) {
        setEventData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickNotice = (item) => {
    navigate(`/manage/news/notice/${item.workDate}/${item.sqenNumb}`);
  };
  useEffect(() => {
    getNotices();
  }, []);

  return (
    <>
      <SmallInfoModal menu="공지사항" infoModalData={menuInfo} style={modalStyle} />
      <ContentWrap
        style={{
          marginTop: '20px'
        }}
      >
        <Content>
          <div
            style={{
              width: '50%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px'
            }}
          >
            <div
              style={{
                backgroundColor: '#37465b',
                borderRadius: '10px',
                height: '150px',
                maxWidth: '100%',
                overflow: 'hidden',
                width: '100%'
              }}
            >
              {/* <Banner src={bannerImg} alt="" /> */}
              <Slider className="eventSlide" {...settings}>
                {eventData.map((item, index) => (
                  <ImgLink key={index}>
                    <EventSlideImg src={`${imageAddr}${item?.file1 ?? ''}`} alt="" onClick={() => handleClickNotice(item)} />
                  </ImgLink>
                ))}
              </Slider>
            </div>
            <NoticeTable theadTitles={theadTitles} setClickedItem={setClickedItem} clickedItem={clickedItem} />
          </div>
          <NoticeDetail clickedItem={clickedItem} />
        </Content>
      </ContentWrap>
    </>
  );
};

export default Notice;

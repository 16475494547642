//env REACT_APP_IMAGE_VIEW_URL
const IMAGE_URL = process.env.REACT_APP_IMAGE_VIEW_URL;
/**
 * 이미지 조회 url
 * @param fileName 파일 이름
 */
export const getImageUrl = (fileName) => {
  const srcUrl = `${IMAGE_URL}${fileName}`;
  return srcUrl;
};

export const imageToImageArr = (dataArr) => {
  const imageArr = [];
  dataArr.forEach((data) => {
    const serverFileName = data?.fileName;
    const previewUrl = getImageUrl(serverFileName);
    const localFlag = false;
    const file = null;
    imageArr.push({ serverFileName, previewUrl, localFlag, file });
  });
  return imageArr;
};

export const imageToImageArr2 = (dataArr) => {
  const imageArr = [];
  dataArr.forEach((data) => {
    const serverFileName = data;
    const previewUrl = getImageUrl(serverFileName);
    const localFlag = false;
    const file = null;
    imageArr.push({ serverFileName, previewUrl, localFlag, file });
  });
  return imageArr;
};

/**
 * 이미지 배열에서 local에서 올린 파일만 추출
 * @param imageArr 이미지 배열
 */
export const onlyUploadImageArr = (imageArr) => {
  // localFlag가 true인 경우만 해당객체의 file을 추출
  const uploadImageArr = imageArr.filter((image) => image.localFlag);
  const uploadFileArr = uploadImageArr.map((image) => image.file);
  return uploadFileArr;
};

/**
 * 폼데이터에 이미지 파일 추가
 * @param formData 폼데이터
 * @param imageArr 이미지 배열
 * @returns 폼데이터
 */
export const addImageToFormData = (formData, imageArr) => {
  const uploadImageArr = onlyUploadImageArr(imageArr);
  uploadImageArr.forEach((file) => {
    formData.append('files', file);
  });
  return formData;
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
`;

/************ Lists Section ************/
export const ListsWrap = styled.div`
  background-color: #37465b;
  width: 37%;
  height: 100%;
  border-radius: 20px;
  padding: 29px 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  > * {
    flex: 0 0 auto;
  }
  .scrollbar-container,
  .empty-box {
    flex: 1 1 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 20px;
  }
`;

export const Results = styled.div`
  width: 100%;
  height: calc(100% - 55px - ${(props) => props.sub_height});
  margin-top: ${(props) => props.margin_top};
`;

export const Item = styled.div`
  width: 100%;
  height: 100px;
  padding: 16px 16px;
  display: flex;
  gap: 15px;
  background-color: #212838;
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 14px;
  transition: border 0.2s ease;
  position: relative;

  &:hover,
  &.selected {
    border: solid 1px #5affe2;
  }

  &:last-child {
    margin-bottom: 0;
  }
  .profile-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .profile-image {
      width: 60px;
      height: 60px;
    }
  }
`;
export const ChatItem = styled(Item)`
  display: grid;
  grid-template-columns: 80px auto 72px;
  gap: 13px 19px;
  .profile-box {
    grid-row: 1 / span 2;
    .profile-image {
    }
  }
`;

export const Profile = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
`;

export const TextWrap = styled.div`
  width: calc(100% - 230px);
  height: 100%;
`;

export const NickName = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
  display: flex;
  align-items: center;
`;

export const Contents = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const ChatPreview = styled(Contents)`
  display: flex;
  grid-column: 2 / span 2;
  white-space: normal;
  line-height: 1.1;
  .preview {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .no-read-section {
    flex: 0 0 60px;
    margin-left: 10px;
    text-align: right;
    .no-read-count {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      height: 26px;
      min-width: 26px;
      font-size: 18.5px;
      border-radius: 13px;
      background: #ff585e;
    }
  }
`;

export const SentDate = styled.span`
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  color: #bebebe;
  line-height: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

/************ Detail Content Section ************/
export const DetailContentWrap = styled.div`
  background-color: #37465b;
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  .detail-content-top {
    margin-bottom: 30px;
    flex: 0 0 auto;
  }
`;

export const SendBtn = styled.button`
  width: 148px;
  height: 36px;
  border-radius: 5px;
  background-color: #7e6eff;
  color: #fffefe;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  // disabled

  &:disabled {
    background-color: #8a8a8a;
    cursor: default;
  }
`;

export const DialogWrap = styled.div`
  width: 100%;
  flex: 1 1 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  .thumb-vertical {
    background-color: #8a8a8a !important;
  }
`;

/************ 내용 없을 경우 보여줄 NoneContent ************/
export const NoneContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #8a8a8a;
`;

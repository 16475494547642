import React, { useEffect, useState } from 'react';
import { ViewTable, TableContainer, Table, Thead, BorderTheadRow, Tbody, TbodyRow, Subject, NoneContentText } from 'components/Parts/Table/TableElements';
import { BdWhiteText } from 'style';
import { Scrollbars } from 'react-custom-scrollbars-2';
import utils from 'modules/utils';
import { styled } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

const TheadCol = styled.th`
  width: ${(props) => props.width};
  height: 50px;
`;

const TbodyCol = styled.td`
  height: 50px;
  text-align: center;
`;
const EventTable = ({ theadTitles, setClickedItem, clickedItem }) => {
  const navigate = useNavigate();
  const { workDate, sqenNumb } = useParams();

  const onClickItem = (value) => {
    setClickedItem(value);
  };

  // 이벤트 목록 저장할 state
  const [eventLists, setEventLists] = useState([]);
  // 이벤트 목록 조회
  const getEvents = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_m_event_S1`);
      if (res.data.data.length > 0) {
        console.log(res.data.data);
        setEventLists(res.data.data);
        if (utils.isEmpty(clickedItem)) {
          let item = res.data.data.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
          if (item.length > 0) setClickedItem(item[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (utils.isEmpty(clickedItem)) {
      navigate(`/manage/news/event`);
    } else {
      navigate(`/manage/news/event/${clickedItem.workDate}/${clickedItem.sqenNumb}`);
    }
  }, [clickedItem]);
  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    let item = eventLists.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
    if (item.length > 0) setClickedItem(item[0]);

    if (!workDate || !sqenNumb) setClickedItem(null);
  }, [workDate, sqenNumb]);

  return (
    <ViewTable>
      {eventLists.length > 0 ? (
        <>
          <BdWhiteText style={{ display: 'block', marginBottom: '30px' }}>수달에서 준비한 다양한 이벤트에 참여해 보세요.</BdWhiteText>
          <Scrollbars
            className="scrollbar-container"
            style={{
              width: 'calc(100% + 60px)',
              height: 'calc(100% - 62px)',
              marginLeft: '-30px',
            }}
            renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
          >
            <TableContainer>
              <Table>
                <Thead>
                  <BorderTheadRow>
                    <TheadCol className="fixedHeader" width="18%">
                      <span className="th-border">{theadTitles[0]}</span>
                    </TheadCol>
                    <TheadCol className="fixedHeader" width="57%">
                      <span className="th-border">{theadTitles[1]}</span>
                    </TheadCol>
                    <TheadCol className="fixedHeader" width="25%">
                      <span className="th-border">{theadTitles[2]}</span>
                    </TheadCol>
                  </BorderTheadRow>
                </Thead>
                <Tbody>
                  {eventLists.map((row, index) => (
                    <TbodyRow
                      style={{
                        fontSize: '20px',
                        borderBottom: index === eventLists.length - 1 ? '1px solid #ddd' : 'none',
                      }}
                      key={`event-${row.sqenNumb}${row.workDate}`}
                    >
                      <TbodyCol>진행중</TbodyCol>
                      <TbodyCol>
                        <Subject type="button" onClick={() => onClickItem(row)}>
                          {row.eventName}
                        </Subject>
                      </TbodyCol>
                      <TbodyCol>{`${row.startDt} ~ ${row.endDt}`}</TbodyCol>
                    </TbodyRow>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Scrollbars>
        </>
      ) : (
        <NoneContentText>현재 진행중인 이벤트가 없습니다.</NoneContentText>
      )}
    </ViewTable>
  );
};

export default EventTable;

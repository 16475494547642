import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { Box, Button, Chip, Stack } from '@mui/material';
import { ticketInfo } from 'components/Manage/Ticket/TicketList/TicketList';
import EmptyBox from 'components/Parts/Common/EmptyBox';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import AreaAndTicketModal from 'components/Parts/Common/Modal/AreaAndTicketModal';
import { partInfoStore, reservationPaymentStore } from 'modules/store/store';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useMediaQuery } from 'react-responsive';
import { MyTicket, NormalWhiteSpan, TitleText } from 'style';
import { SaveBtn, TopSection } from '../../InfoElements';
import CustomSwitch from './../../../../Switch/CustomSwitch';
import CityLists from './CityLists';
import RegionLists from './RegionLists';
import { Bottom, CustomBox, ListBox, MyTicketBox, RegionBox, TicketIcon } from './SoodalPlusElements';
const modalStyle = {
  width: '517px',
  height: '135px',
};

const infoModalData = (
  <>
    달인이 고객에게 노출 될 지역을 설정합니다.
    <br />
    시/도, 시/군/구 지역을 선택 후 "저장" 버튼을 눌러주세요.
  </>
);
const SoodalPlus = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  const [open, setOpen] = useState(false);

  const [town, setTown] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [checkedAlert, setCheckedAlert] = useState(Number(false));
  const [groupedChipData, setGroupedChipData] = useState([]);

  // 전체 시/도 list
  const [regionList, setRegionList] = useState([]);

  const { ticketList, getTicketList } = reservationPaymentStore();
  const { partInfo, getPartInfo } = partInfoStore();

  const reflashTicketList = () => {
    getPartInfo();
    getTicketList();
  };

  // 전체 시/군 데이터 가져오기
  const getRegionLists = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get('/v2/SP_b_city_s2');
      if (res.data.data && res.data.data.length > 0) {
        setRegionList(res.data.data);
      }
      // console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [district, setDistrict] = useState([]);

  // 설정 초기화
  const resetCheckList = () => {
    setDistrict([]);
  };

  const onSearch = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_b_city_s1/${selectedRegion?.nm}`);
      if (res.data.data && res.data.data.length > 1) {
        const List = res.data.data.map((item) => {
          return { region: selectedRegion?.nm, ...item };
        });
        setTown(List);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 저장된 노출 지역 불러오기
  const getSavedCity = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get('/v1/SP_p_partPlus_s1');
      console.log('파트너 지역 불러오기', res.data.data);
      if (res.data.data.length > 0) {
        const regionList = res.data.data?.map((item) => {
          return { region: item.city, cd: item.regionCode, nm: item.regionName };
        });

        setDistrict(regionList);

        setSelectedRegion(res.data.data[0].city);
        console.log('res.data.data[0].partAreaPlus', res.data.data[0].partAreaPlus);

        setCheckedAlert(Number(res.data.data[0].partAreaPlus) === 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 선택 지역 저장하기
  const onSave = async (areaArr, checkedAlert) => {
    try {
      const data = {
        partArea: areaArr.map((x) => x.cd).join('_//'),
        partGubn: checkedAlert ? '1' : '0',
      };
      utils.http.setMode('api');
      const res = await utils.http.post('/v1/SP_p_partPlus_i1', null, data);
      if (res.data.success && res.data.data) {
        utils.dialog.alert('저장 완료!', '노출 지역이 설정되었습니다!<br />설정된 지역으로 달인이 노출됩니다.', 'success');
      } else {
        utils.dialog
          .confirm(
            '저장 실패!',
            `${res.data.message}<br />
          구독권을 구매하시겠습니까?`,
            'error',
            '',
            '구독하기',
            '취소'
          )
          .then((res) => {
            res && setOpen(true);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeSwitch = () => {
    setCheckedAlert(Number(!checkedAlert));
  };

  const removeAllRegion = (region) => {
    setDistrict(district.filter((item) => item.region !== region));
  };

  useEffect(() => {
    getPartInfo();
    getTicketList();
    getRegionLists();
    reflashTicketList();
  }, []);

  const ticketInfoDetail = ticketInfo.find((item) => item.title === partInfo?.levelName);

  useEffect(() => {
    if (regionList.length > 0) {
      getSavedCity();
    }
  }, [regionList]);

  useEffect(() => {
    if (selectedRegion !== '') {
      onSearch();
    }
  }, [selectedRegion]);

  useEffect(() => {
    // chipData에서 region이 같은 것만 배열로 만들어서 하나씩 표시하기 reduce 사용하기
    const grouped = district.reduce((acc, cur) => {
      const region = cur.region;
      if (!acc[region]) {
        acc[region] = [];
      }
      acc[region].push(cur);
      return acc;
    }, {});
    setGroupedChipData(Object.values(grouped));
  }, [district]);

  ticketList.filter((ticket) => ticket.ticket_code === partInfo?.ticketCode);
  return (
    <>
      <TopSection>
        <SmallInfoModal menu="내노출지역" style={modalStyle} infoModalData={infoModalData} />
        <SaveBtn
          type="button"
          onClick={() => {
            onSave(district, checkedAlert);
          }}
        >
          <BookmarkAddOutlinedIcon />
          저장
        </SaveBtn>
      </TopSection>

      <CustomBox>
        <TitleText>노출지역</TitleText>
        <NormalWhiteSpan>설정한 지역이 넓을수록 고객을 찾는 범위가 넓어집니다.</NormalWhiteSpan>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '16px',
          }}
        >
          <MyTicket>구독하기</MyTicket>
          <MyTicket>
            선택한 지역 : <span style={{ marginLeft: '5px', color: '#5affe2' }}>{district.length}개</span>
          </MyTicket>
        </div>
        {/* 구독하기 */}
        <MyTicketBox>
          {ticketInfoDetail ? (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', color: '#fff', gap: '25px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TicketIcon style={{ backgroundColor: ticketInfoDetail.color }}>{ticketInfoDetail.icon}</TicketIcon>
                <div style={{ fontWeight: 'bold', fontSize: '20px', marginLeft: '10px' }}>{ticketInfoDetail.title}</div>
              </div>
              <div>|</div>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}>
                견적 무제한 + <span style={{ marginLeft: '5px', color: '#5affe2' }}>지역 설정 {ticketInfoDetail.content2}</span>
              </div>
              <div>|</div>
              <div>
                이용기간 : {partInfo.st_dt} ~ {partInfo.et_dt}
              </div>
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', color: '#fff', gap: '25px' }}>{partInfo?.partLevel === 0 ? <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}>승인 대기 중</div> : <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}>{partInfo?.partCount > 0 ? <div>무료 구독중 · {partInfo.partCount}개</div> : <div>무료 구독 만료</div>}</div>}</div>
            </>
          )}
        </MyTicketBox>

        <ListBox>
          <RegionBox>
            <RegionLists selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion} list={regionList} />
            <CityLists town={town} district={district} setDistrict={setDistrict} selectedRegion={selectedRegion} setOpen={setOpen} />
          </RegionBox>
          <Bottom>
            {district.length === 0 ? (
              <EmptyBox>노출 지역을 설정해 주세요.</EmptyBox>
            ) : (
              <div>
                <div className="btn-box">
                  <Button color="info" type="button" onClick={resetCheckList} sx={{ fontSize: '16px' }}>
                    설정초기화
                    <RefreshRoundedIcon />
                  </Button>
                </div>
                <Scrollbars
                  className="scrollbar-container"
                  renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
                  style={{
                    width: '100%',
                    height: '120px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px',
                      padding: '10px',
                    }}
                  >
                    {groupedChipData.map((group, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: '6px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                          }}
                        >
                          <Chip
                            label={group[0].region}
                            color="info"
                            onDelete={() => {
                              removeAllRegion(group[0].region);
                            }}
                            sx={{ fontWeight: 'bold', fontSize: '16px' }}
                          />
                          <ArrowForwardIosIcon
                            sx={{
                              color: 'var(--mint-color)',
                              fontSize: '16px',
                            }}
                          />
                        </Box>
                        {group.length === regionList.find((region) => region.nm === group[0].region)?.count ? (
                          <Chip
                            label="전체"
                            onDelete={() => {
                              removeAllRegion(group[0].region);
                            }}
                            color="info"
                            variant="outlined"
                            sx={{
                              color: '#fff',
                              fontSize: '16px',
                            }}
                          />
                        ) : (
                          <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '6px' }}>
                            {group.map((item) => {
                              return (
                                <Chip
                                  key={item.cd}
                                  label={item.nm}
                                  onDelete={() => {
                                    setDistrict(district.filter((region) => region.cd !== item.cd));
                                  }}
                                  color="info"
                                  variant="outlined"
                                  sx={{
                                    color: '#fff',
                                    fontSize: '16px',
                                  }}
                                />
                              );
                            })}
                          </Stack>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Scrollbars>
              </div>
            )}
          </Bottom>
        </ListBox>
        <AreaAndTicketModal
          open={open}
          close={() => {
            setOpen(false);
          }}
          title="구독하기"
          chipData={district}
          setChipData={setDistrict}
          reflashTicketList={getSavedCity}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <CustomSwitch label="긴급출동 PUSH 알림받기" checked={checkedAlert} onChange={changeSwitch} />
        </div>
      </CustomBox>
    </>
  );
};

export default SoodalPlus;

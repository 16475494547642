import styled from "styled-components";

const EmptyContain = styled.section `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: #8a8a8a;
`
const EmptyBox = ({message, children, style}) => {
    return (
        <EmptyContain className={"empty-box"} style={style}>
            {message || children}
        </EmptyContain>
    );
};

export default EmptyBox;

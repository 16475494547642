import React, { useState } from 'react';
import GrabScroll from '../GrabScroll';
import styled from 'styled-components';
import ImageViewModal from 'components/Parts/Common/Modal/ImageViewModal';
import utils from 'modules/utils';

const GrabImageListBox = styled.section`
  height: ${(props) => props.height};
  .image-list-container {
    height: 100%;

    display: flex;
    flex-wrap: nowrap;
    gap: 30px;

    .image-wrap {
      width: ${(props) => props.height};
      height: ${(props) => props.height};
      cursor: pointer;
      flex: 0 0 ${(props) => props.height};
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 10px;

        object-fit: cover;
      }
    }
  }
`;

export const imgFormat = (image) => {
  // image가 string이면 return image 그대로 image가 object면 return image.fileName
  if (utils.isObject(image)) {
    return image.fileName;
  }
  return image;
};

const GrabImageList = ({ imageList, height, className }) => {
  console.log('####################', imageList);
  const [isImageDrag, setIsImageDrag] = useState(true);
  let imageServer = process.env.REACT_APP_IMAGE_VIEW_URL;
  height = height ?? '150px';

  if (utils.isArray(imageList) === false) imageList = [];

  const [imageStart, setImageStart] = useState(null);

  console.log('imageList', imageList);

  return (
    <>
      <GrabImageListBox className={className} height={height}>
        <GrabScroll>
          <section className={'image-list-container'}>
            {imageList.map((image) => (
              <div
                key={image}
                className={'image-wrap'}
                onMouseDown={(e) => {
                  setIsImageDrag(false);
                }}
                onMouseMove={(e) => {
                  setIsImageDrag(true);
                }}
                onMouseUp={(e) => {
                  if (isImageDrag === false) {
                    setImageStart(image);
                  }
                }}
              >
                <img src={imageServer + imgFormat(image)} alt={''} />
              </div>
            ))}
          </section>
        </GrabScroll>
      </GrabImageListBox>
      <ImageViewModal
        open={imageList.length > 0 && imageStart !== null}
        close={() => {
          setImageStart(null);
        }}
        title="사진"
        imageList={imageList}
        imageStart={imageStart}
        useDot
      />
    </>
  );
};

export default GrabImageList;

import common from "./utils.common";

let file = {
    isFile(file){
        return common.isDefine(file) && common.isNotEmpty(this.name(file)) && common.isNotEmpty(this.type(file)) && this.size(file) > 0;
    },
    isBase64(file){
        return common.isString(file) && file.indexOf("data:") === 0;
    },
    isImage(file){
        if(this.isFile(file)) {
            return this.type(file).indexOf("image/") === 0;
        } else if(this.isBase64(file)) {
            return file.indexOf("data:image/") === 0;
        } else {
            return false;
        }

    },
    toImageURL(file){
        if(this.isImage(file)) {
            if (this.isFile(file)) {
                return URL.createObjectURL(file);
            } else if (this.isBase64(file)) {
                return file;
            }
        }
        return ""
    },
    dataType(file){
        if(this.isFile(file)) return "file";
        else if(this.isBase64(file)) return "base64";
        else return "";
    },
    type(file){
        return file.type;
    },
    name(file){
        return file.name;
    },
    size(file){
        return file.size;
    }
}
Object.freeze(file);
export default file;
import React, { useState, useEffect } from 'react';
import { DeleteBtn } from './UploaderElements';
import { IoMdClose } from 'react-icons/io';
import GrabScroll from 'components/Parts/Common/GrabScroll';
import utils from 'modules/utils';
import { useMediaQuery } from 'react-responsive';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { Box, Card, styled } from '@mui/material';

const ImageUploadCard = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  cursor: 'pointer',
  color: '#a1a1a1',
  outline: 'none',
  fontSize: '20px',
  fontWeight: 'bold',
  transition: 'border .1s ease-in-out',
  border: '2px dashed #c4c4c4',
  '&:hover': {
    border: '2px dashed var(--mint-color)',
  },
});
const imgUrl = process.env.REACT_APP_IMAGE_VIEW_URL;
const InfoImageUploader = ({ prevImages, setDelKey, setFile, kind, maxImage }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  // 이미지 업로드시 보여질 이미지 목록
  const [showImages, setShowImages] = useState([]);

  const maxImagesCheck = function (e) {
    if (maxImage && showImages.length >= maxImage) {
      e.preventDefault();
      utils.dialog.alert('업로드 제한', `최대 ${maxImage}개 까지 업로드가 가능합니다.`, 'warning');
    }
  };
  // 이미지 상대경로 저장
  const handleAddImages = (event) => {
    console.log('size', event.target.files[0].size);
    if (!event.target.files[0].name.match(/.(jpg|jpeg|png)$/i) || event.target.files[0].size > 20000000) {
      utils.dialog.alert('업로드 실패!', '이미지: 20MB이하의 JPG, JPEG, PNG 파일만 등록 가능합니다.', 'warning');
      return;
    }

    const imageLists = event.target.files;

    for (let i = 0; i < imageLists.length; i++) {
      const currentImageUrl = URL.createObjectURL(event.target.files[i]);
      setShowImages((showImages) => [...showImages, currentImageUrl]);
      setFile((file) => {
        if (file[kind] === undefined) file[kind] = [];
        file[kind].push(event.target.files[0]);
        return Object.assign({}, file);
      });
    }
  };

  // X버튼 클릭 시 이미지 삭제
  const handleDeleteImage = (id) => {
    if (showImages[id].startsWith(imgUrl)) {
      const fileName = showImages[id].split('/');
      setDelKey((delKey) => {
        if (delKey[kind] === undefined) delKey[kind] = [];
        delKey[kind].push(fileName.at(-1));
        return Object.assign({}, delKey);
      });
    } else {
      setFile((file) => {
        if (file[kind] === undefined) file[kind] = [];
        let fileIdx = showImages.filter((img) => !img.startsWith(imgUrl)).indexOf(showImages[id]);

        file[kind] = file[kind].filter((_, index) => index !== fileIdx);

        return Object.assign({}, file);
      });
    }
    setShowImages(showImages.filter((_, index) => index !== id));
  };

  useEffect(() => {
    if (prevImages) {
      setShowImages(prevImages.map((x) => `${imgUrl}${x.fileName}`));
    }
  }, [prevImages]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '50px',
        }}
      >
        <label htmlFor={'file' + kind} onClick={maxImagesCheck} onChange={handleAddImages}>
          <ImageUploadCard
            sx={{
              backgroundColor: '#fff',
              width: '120px',
              height: '120px',
            }}
          >
            <input
              type="file"
              id={'file' + kind}
              accept="image/jpg, image/jpeg, image/png"
              hidden
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            <CameraAltOutlinedIcon sx={{ fontSize: '40px' }} />
            {prevImages.length + ' / ' + (maxImage ?? 1)}
          </ImageUploadCard>
        </label>
        <GrabScroll>
          <section className="dark-file-list">
            {utils.range(kind === 'partnerBusiness' || kind === 'partnerBank' ? 1 : 5).map((idx) => (
              <div key={`image-uploader${idx}`} className="dark-image-wrapper">
                {showImages[idx] !== undefined && (
                  <>
                    <img src={showImages[idx]} alt={`${showImages[idx]}-${idx}`} width={isTablet ? '80' : '120'} height={isTablet ? '80' : '121'} />
                    <DeleteBtn type="button" onClick={() => handleDeleteImage(idx)}>
                      <IoMdClose color="#fff" />
                    </DeleteBtn>
                  </>
                )}
              </div>
            ))}
          </section>
        </GrabScroll>
      </Box>
    </Box>
  );
};

export default InfoImageUploader;

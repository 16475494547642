import React, { useState, useEffect } from 'react';
import BigInfoModal from 'components/Parts/Common/MenuInfo/BiginfoModal';
import { useParams, useNavigate } from 'react-router-dom';
import TabMenu from 'components/Parts/Common/TabMenu/TabMenu';
import { Container, TopSection, SaveBtn } from '../../InfoElements';
import TabPrivacy from './TabPrivacy';
import TabTerms from './TabTerms';
import CheckPwModal from 'components/Parts/Common/Modal/CheckPwModal';
import utils from 'modules/utils';
import { useMediaQuery } from 'react-responsive';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';

const PrivacyList = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const { info } = useParams();
  const navigate = useNavigate();

  const modalStyle = {
    width: isTablet ? '500px' : '645px',
    height: isTablet ? '140px' : '200px',
  };
  const menuInfo = [
    {
      id: 'privacy-info1',
      title: '개인정보수정',
      descript: `비밀번호 수정 후 "저장"버튼을 눌러주세요.`,
    },
    {
      id: 'privacy-info12',
      title: '이용약관',
      descript: `이용약관 확인이 가능합니다.`,
    },
    // {
    //   id: 'privacy-info3',
    //   title: '알림설정',
    //   descript: `마케팅정보, 견적도착아알림, 채팅알림, 공지사항알림, 카카오톡 알림 설정을 합니다.`,
    // },
  ];

  const [pwModal, setPwModal] = useState(true);
  const [pw, setPw] = useState('');
  const [isChecked, setIsCheked] = useState(false);
  const closePwModal = () => {
    setPwModal(false);
  };

  const goBack = () => {
    //이전페이지로 이동
    navigate(-1);
  };

  const tabList = {
    modifyPrivacy: '개인정보수정',
    terms: '이용약관',
    // alerts: '알림설정',
  };

  const termsLists = [
    { title: '개인정보처리방침', lawGubn: 1 },
    { title: '달인등록 약관', lawGubn: 2 },
    { title: '서비스 약관', lawGubn: 3 },
    { title: '위치기반서비스 약관', lawGubn: 4 },
    { title: '이용약관', lawGubn: 5 },
    { title: '정책', lawGubn: 6 },
    { title: '전자금융거래 이용약관', lawGubn: 7 },
    { title: '제3자 정보제공 동의', lawGubn: 16 },
  ];

  const [userInfo, setUserInfo] = useState([]);

  const onSubmit = async () => {
    if (utils.isEmpty(pw)) {
      utils.dialog.alert('비밀번호 입력!', '비밀번호를 입력해 주세요.', 'warning');
    } else {
      try {
        utils.http.setMode('api');
        const res = await utils.http.get(`/v1/SP_m_loginCheck_s1/${utils.user('id')}/${pw}`);
        if (res.data.data && res.data.data.length > 0) {
          setUserInfo(res.data.data);
          setIsCheked(true);
          closePwModal();
        } else {
          setIsCheked(false);
          utils.dialog.alert('비밀번호 확인!', '비밀번호가 일치하지 않습니다.<br />비밀번호를 다시 확인해주세요.', 'error');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const [save, setSave] = useState([]);

  let TabSection = '';
  let kind = info ?? 'modifyPrivacy';

  switch (kind) {
    case 'modifyPrivacy':
      TabSection = <TabPrivacy setSave={setSave} userInfo={userInfo} pw={pw} setPw={setPw} onSubmit={onSubmit} />;
      break;
    case 'terms':
      TabSection = <TabTerms setSave={setSave} termsLists={termsLists} />;
      break;
    // case 'alerts':
    //   TabSection = <TabAlerts setSave={setSave} termsLists={termsLists} />;
    //   break;
    default:
      TabSection = <TabPrivacy setSave={setSave} userInfo={userInfo} pw={pw} setPw={setPw} onSubmit={onSubmit} />;
  }

  useEffect(() => {
    if (!isChecked && !pwModal) {
      goBack();
    }
  }, [isChecked, pwModal]);

  useEffect(() => {
    //console.log(userInfo);
  }, [userInfo]);

  return (
    <>
      {pwModal ? (
        <CheckPwModal open={pwModal} close={closePwModal} title="비밀번호를 한번 더 입력해 주세요." setIsCheked={setIsCheked} onSubmit={onSubmit} setPw={setPw} pw={pw} />
      ) : (
        !pwModal &&
        isChecked && (
          <>
            <TopSection>
              <BigInfoModal infoModalData={menuInfo} menu="개인정보" style={modalStyle} />
              <SaveBtn
                type="button"
                onClick={() => {
                  utils.isFunction(save?.onSave) && save.onSave();
                }}
              >
                <BookmarkAddOutlinedIcon />
                저장
              </SaveBtn>
            </TopSection>
            <Container style={{ height: 'auto' }}>
              <TabMenu tabList={tabList} selected={kind} size={isTablet ? 'small' : 'big'} path="/manage/info/privacy/" />
              {TabSection}
            </Container>
          </>
        )
      )}
    </>
  );
};

export default PrivacyList;

import React from 'react';
import { BillBox, Header, MainBox, Lists } from './TransactionElements';
import EmptyBox from 'components/Parts/Common/EmptyBox';
import Icon from 'components/Parts/Common/Icon';
import utils from 'modules/utils';

const Bill = ({ statusData }) => {
  const lists = [
    {
      data: 'payMony',
      subject: '총 판매액',
      icon: 'counter_1',
      note: '',
    },
    {
      data: 'payChargeMony',
      subject: '총 수수료',
      icon: 'counter_2',
      note: '',
    },
    {
      data: 'payTakeMony',
      subject: '총 매출액',
      icon: 'counter_3',
      note: (
        <>
          <Icon name="counter_1" />
          <span style={{ marginRight: '3px' }}>-</span>
          <Icon name="counter_2" />
        </>
      ),
    },
    {
      data: 'giveMony',
      subject: '총 지급액',
      icon: 'counter_4',
      note: '신청금액 기준',
    },
    {
      data: 'requestMony',
      subject: '지급 요청',
      icon: 'counter_5',
      note: '신청금액 기준',
    },
    {
      data: 'leftMony',
      subject: '현재 잔액',
      icon: 'counter_6',
      note: '',
    },
    {
      data: '1000',
      subject: '출금 기준',
      icon: 'counter_7',
      note: '이상 잔액',
    },
    {
      data: 'posibleMony',
      subject: '출금 가능',
      icon: 'counter_8',
      note: (
        <>
          <Icon name="counter_6" />
          <span style={{ marginRight: '3px' }}>~</span>
          <Icon name="counter_7" />
        </>
      ),
    },
  ];

  // useEffect(() => {
  //  console.log(statusData);
  // }, [statusData]);
  return (
    <BillBox>
      {statusData.length > 0 ? (
        <>
          <Header>
            <div
              className="bill-header-item"
              style={{
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              }}
            >
              구분
            </div>
            <div className="bill-header-item">금액(원)</div>
            <div
              className="bill-header-item"
              style={{
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              }}
            >
              비고
            </div>
          </Header>
          {statusData.length > 0 && (
            <>
              <MainBox>
                {lists.map((li, index) => (
                  <Lists key={`bill-list-${index}`} className={index === 5 ? 'current-money' : index === 7 ? 'total-possible-money' : ''}>
                    <div>
                      <Icon name={li.icon} />
                      {li.subject}
                    </div>
                    <div className="data-money">{li.data === '10000' ? '10,000' : utils.format.extractNumber(statusData[0][li.data], ['format'])}</div>
                    <div>{li.note}</div>
                  </Lists>
                ))}
              </MainBox>
            </>
          )}
        </>
      ) : (
        <EmptyBox>정산현황 내역이 없습니다.</EmptyBox>
      )}
    </BillBox>
  );
};

export default Bill;

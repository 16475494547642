import axios from 'axios';
import utils from 'modules/utils';
import constant from './utils.const';
import { postUrl } from 'App';

let URLOption = {
  baseURL: '',
  withBase: true,
};
let refreshToken = null;
const http = {
  statusCode: constant.statusCode,
  ERR_NOT_DEFINE_METHOD: 101,
  setBaseURL(url) {
    URLOption.baseURL = url;
  },
  getBaseURL() {
    return URLOption.baseURL;
  },
  withoutBaseURL() {
    URLOption.withBase = false;
  },
  setMode(mode) {
    //if(mode === "api") this.setBaseURL("");
    if (mode === 'api') this.setBaseURL(process.env.REACT_APP_BACKEND_URL);
    else if (mode === 'test') this.setBaseURL(process.env.REACT_APP_BACKEND_URL_HTTP);
    else if (mode === 'img') this.setBaseURL(process.env.REACT_APP_IMAGE_VIEW_URL);
  },
  send(method, url, data, params, headers, resBefore) {
    return new Promise((resolve, reject) => {
      if (URLOption.withBase) url = URLOption.baseURL + url;

      // headers에 Authorization이 없을 경우에만 추가
      headers = Object.assign({}, headers, {
        Authorization: `Bearer ${utils.user('token')}`,
      });

      let sendPromise = null;
      if (method.toLowerCase() === 'get') {
        params = Object.assign({}, params, data);
        sendPromise = axios.get(url, { params, headers });
      } else if (method.toLowerCase() === 'post') {
        sendPromise = axios.post(url, data, { params, headers });
      } else if (method.toLowerCase() === 'put') {
        sendPromise = axios.put(url, data, { params, headers });
      } else if (method.toLowerCase() === 'patch') {
        sendPromise = axios.patch(url, data, { params, headers });
      } else if (method.toLowerCase() === 'delete') {
        params = Object.assign({}, params, data);
        sendPromise = axios.delete(url, { params, headers });
      }

      if (URLOption.withBase === false) URLOption.withBase = true;

      if (sendPromise === null) {
        reject({
          error: true,
          errCode: http.ERR_NOT_DEFINE_METHOD,
        });
      } else {
        sendPromise
          .then(async (res) => {
            if (utils.isFunction(resBefore)) resBefore(res);
            if (res.data.code === -2) {
              let refresher = false;
              if (refreshToken === null) {
                refreshToken = utils.user.refreshToken();
                refresher = true;
              }
              await refreshToken
                .then((issued) => {
                  if (issued) {
                    http.setBaseURL('');
                    http
                      .send(method, url, data, params, headers, resBefore)
                      .then((res) => {
                        resolve(res);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  } else if (refresher) {
                    utils.dialog.alert('로그인 토큰 오류', '로그인 토큰 유지에 문제가 발생했습니다.<br/>다시 로그인해주세요.', 'error').then(() => {
                      utils.user.logout();
                    });
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              if (res.data.success === false) {
                // res.data.message 에 'userToken' 이라는 문자열이 없을 경우에만 에러메시지 출력
                if (res.data.message && res.data.message.indexOf('userToken') === -1) {
                  this.errorLog(res.data.message);
                }
              }
              resolve(res);
            }
          })
          .catch((error) => {
            if (typeof error === 'string') this.errorLog('catch' + error);
            reject(error);
          });
      }
    });
  },
  get(url, data, params, headers, resBefore) {
    return this.send('get', url, data, params, headers, resBefore);
  },
  post(url, data, params, headers, resBefore) {
    return this.send('post', url, data, params, headers, resBefore);
  },
  put(url, data, params, headers, resBefore) {
    return this.send('put', url, data, params, headers, resBefore);
  },
  patch(url, data, params, headers, resBefore) {
    return this.send('patch', url, data, params, headers, resBefore);
  },
  delete(url, data, params, headers, resBefore) {
    return this.send('delete', url, data, params, headers, resBefore);
  },
  fileUpload(method, url, data, params, headers, resBefore) {
    let formData = {};
    if (data instanceof FormData) {
      formData = data;
    } else {
      formData = new FormData();
      for (let name in data) {
        if (utils.isArray(data[name])) {
          for (let idx in data[name]) {
            formData.append(name, data[name][idx]);
          }
        } else {
          formData.append(name, data[name]);
        }
      }
    }
    headers = Object.assign(
      {
        'Content-Type': 'multipart/form-data',
      },
      headers
    );
    return this.send(method, url, data, params, headers, resBefore);
  },
  postFile(url, data, params, headers, resBefore) {
    return this.fileUpload('post', url, data, params, headers, resBefore);
  },
  putFile(url, data, params, headers, resBefore) {
    return this.fileUpload('put', url, data, params, headers, resBefore);
  },
  patchFile(url, data, params, headers, resBefore) {
    return this.fileUpload('patch', url, data, params, headers, resBefore);
  },

  setDefaultHeaders(headers) {
    Object.assign(axios.defaults.headers.common, headers);
  },
  delDefaultHeaders(...headerKeys) {
    headerKeys.forEach((key) => {
      if (utils.isString(key)) {
        delete axios.defaults.headers.common[key];
      }
    });
  },

  refreshToken(token) {
    const getTokens = axios.get(process.env.REACT_APP_BACKEND_URL + '/v1/getRefreshToken/' + token);
    return getTokens;
  },
  loginInfo() {
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/v1/SP_m_loginInfo_s1', {
      headers: { Authorization: `Bearer ${utils.user('token')}` },
    });
  },
  errorLog(msg) {
    const url = process.env.REACT_APP_BACKEND_URL + '/v2/SP_b_error_log_I1';
    const data = {
      userId: utils.user('id') ?? '',
      error_log: msg ?? '',
      error_page: window.location.href,
    };
    return axios.post(postUrl(url, data), []);
  },
};
Object.freeze(http);

export default http;

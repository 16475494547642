import styled from 'styled-components';

export const ContentWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Banner = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  flex: 0 0 auto;
`;

import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { AppBase, Container, MainWrap, Bottom, ConnectDate } from './FrameElements';
import Sidebar from '../Parts/Sidebar/Sidebar';
import TopNav from '../Parts/Top/TopNav/TopNav';
import Home3 from './Home/Home';
import News from './News/News';
import Info from './Info/Info';
import History from './History/History';
import Intern from './Intern/Intern';
import Transaction from './Transaction/Transaction';
import { Scrollbars } from 'react-custom-scrollbars-2';
import WebPush from 'components/Parts/Common/WebPush';

import utils from 'modules/utils';
import Ticket from './Ticket/Ticket';
import { useMediaQuery } from 'react-responsive';
import { partInfoStore, menuStore } from './../../modules/store/store';
import MobileModal from 'components/Manage/MobileModal/MobileModal';
import CustomCalendar from 'components/CalendarPage';
import Drawer from '@mui/material/Drawer';

const Frame = () => {
  const isSmallPC = useMediaQuery({ query: '(max-width: 1440px)' });
  const location = useLocation();
  const { menuOpen, setMenuOpen } = menuStore();

  const [accessTime, setAccessTime] = useState(null);

  useEffect(() => {
    setAccessTime(utils.user.accessTimeText() ?? '접속시간 표기오류');
  }, []);

  const { getPartInfo } = partInfoStore();

  useEffect(() => {
    getPartInfo();
  }, []);

  return (
    <AppBase>
      {!location.pathname.includes('mobileModal') && <TopNav />}

      <Container className="container">
        {!isSmallPC ? (
          <Sidebar />
        ) : (
          <Drawer
            anchor="right"
            open={menuOpen}
            variant="persistent"
            sx={{
              width: '300px',
              height: '100%',
              '& .MuiDrawer-paper': { bgcolor: '#10141c' },
            }}
            onClose={() => {
              // 외부 클릭시 닫기
              setMenuOpen(false);
            }}
          >
            <Sidebar />
          </Drawer>
        )}

        <Scrollbars className="scrollbar-container main-content-scroll" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />} renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" />}>
          <MainWrap
            className="main-wrap"
            onClick={() => {
              // 외부 클릭시 닫기
              setMenuOpen(false);
            }}
          >
            <Routes>
              {/* <Route path="home" element={<Home />} /> */}
              <Route path="home" element={<Home3 />} />
              <Route path="news/*" element={<News />} />
              <Route path="history/*" element={<History />} />
              <Route path="info/*" element={<Info />} />
              <Route path="transaction/*" element={<Transaction />} />
              <Route path="intern/*" element={<Intern />} />
              <Route path="ticket/*" element={<Ticket />} />
              <Route path="mobileModal/*" element={<MobileModal />} />
              <Route path="calendar/*" element={<CustomCalendar />} />
            </Routes>
            <Bottom></Bottom>
          </MainWrap>
        </Scrollbars>
      </Container>
      <WebPush />
    </AppBase>
  );
};

export default Frame;

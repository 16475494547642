import { styled } from 'styled-components';

export const TableWrap = styled.div`
  width: 100%;
  height: 100%;
  //border: 1px solid red;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 50px 1fr;
  margin-bottom: 20px;
`;

export const Head = styled.div`
  width: 100%;
  height: 100%;
  padding: 6px 0;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background: #212838;
  border-right: solid 1px #37465b;
  text-align: center;

  &:last-child {
    border: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const TableBody = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  //border: 1px solid blue;
  background: #212838;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(7, 54.5px);

  .data {
    width: 100%;
    height: 100%;
    padding: 8px 0;
    font-size: 22px;
    font-weight: normal;
    color: #fff;
    //background: #212838;
    border-right: solid 1px #37465b;
    border-bottom: solid 1px #37465b;
    text-align: center;

    &:last-child {
      border-right: none;
    }
  }
`;

export const Lists = styled.div`
  width: 100%;
  height: 100%;
  padding: 6px 0;
  font-size: 22px;
  font-weight: normal;
  color: #fff;
  background: #212838;
  border-right: solid 1px #37465b;
  border-bottom: solid 1px #37465b;
  text-align: center;

  &:last-child {
    border-right: none;
  }
`;

import React, { useState, useEffect } from 'react';
import { SettlementContent } from '../BusinessElements';
import { styled } from 'styled-components';
import { WhiteDot } from 'style';
import InfoImageUploader from 'components/Parts/Common/Uploader/InfoImageUploader';
import utils from 'modules/utils';

const Form = styled.form`
  width: 100%;
  height: auto;
  max-height: 473px;

  .input-lists {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .input-lists span {
    width: 200px;
    font-size: 24px;
    font-weight: bold;
    color: #e9e9e9;
  }
  .business-type-button {
    width: calc(100% - 200px);
    height: 40px;
    display: flex;
    gap: 30px;
  }
  .input-lists .business-type-button button {
    height: 100%;
    width: 50%;
    border-radius: 5px;
    border: solid 1px #8a8a8a;
    background-color: #fff;
    color: #8a8a8a;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover,
    &.selected {
      background-color: #7e6eff;
      color: #fff;
    }
  }
  .calc-bottom-text {
    margin-top: 25px;
  }

  @media screen and (max-width: 1024px) {
    .input-lists span {
      width: 150px;
      font-size: 16px;
    }
    .input-lists .business-type-button button {
      font-size: 16px;
    }
  }
`;

const TextInput = styled.input.attrs(() => ({
  type: 'text',
}))`
  width: calc(100% - 200px);
  height: 40px;
  padding: 10px 15px;
  font-size: 20px;
  color: #10141c;
  border-radius: 5px;
  border: solid 1px #8a8a8a;
  background-color: #fff;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus::placeholder {
    visibility: hidden;
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% - 150px);
    font-size: 14px;
    height: 30px;
  }
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 25px;

  .white-dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #fff;
    border-radius: 50%;
    margin: 0 5px;
  }

  .bottom-text {
    font-size: 16px;
    font-weight: normal;
    color: #e9e9e9;
  }
`;

const TabCalculate = ({ setSave, data, setData }) => {
  const [imgDelKey, setImgDelKey] = useState({});
  const [imgFile, setImgFile] = useState({});
  const [inputVal, setInputVal] = useState({
    busiBank: '',
    busiBankAccount: '',
    busiBankAccountHolder: '',
  });

  const FormText = [
    {
      bottom: ['추후 출금 신청 시 이용되오니 계좌정보를 꼭 확인해주세요'],
      imageName: '통장사본',
    },
  ];

  // 저장된 사업자등록증 이미지 state
  const [savedImg, setSavedImg] = useState([]);
  // 달인정보 이미지 조회
  const getImages = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_p_partCompInfo_S2/${utils.user('id')}`);
      //console.log(res.data);
      if (res.data.data && res.data.data.length > 0) {
        setSavedImg(res.data.data.filter((obj) => obj.dir === 'partnerBank').slice(0, 1));
      }
    } catch (error) {
      console.log(error);
    }
  };
  //TODO: 저장시 본인인증 후 저장가능 하도록
  // 저장 버튼 클릭했을 때 api 요청
  const onSave = async () => {
    try {
      const formData = new FormData();

      formData.append('delKey', utils.isArray(imgDelKey['partnerBank']) ? imgDelKey['partnerBank'].join('_//') : '');
      formData.append('busiBank', inputVal.busiBank);
      formData.append('busiBankAccount', inputVal.busiBankAccount);
      formData.append('busiBankAccountHolder', inputVal.busiBankAccountHolder);

      if (imgFile.partnerBank?.length > 0) {
        formData.append(
          'files',
          new File([imgFile.partnerBank[0]], 'partnerBank_' + imgFile.partnerBank[0].name, {
            type: imgFile.partnerBank[0].type,
          })
        );
      }

      // console.log(formData.get("files"));
      // console.log(formData.get("delKey"));
      utils.http.setMode('api');
      const res = await utils.http.postFile('/v1/SP_p_partBusiInfo_wi2', formData);
      if (res.data.success && res.data.message === '성공') {
        utils.dialog.alert('저장 완료!', '입력하신 내용이 다를 경우,<br />출금 시 제한이 생길 수 있습니다.', 'success');
        getImages();
        setImgDelKey([]);
        setImgFile({});
      } else {
        utils.dialog.alert('저장 실패!', '오류가 발생했습니다. 관리자에게 문의해 주세요.', 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  useEffect(() => {
    if (setSave) {
      setSave({ onSave });
    }
  }, [imgFile, inputVal]);

  useEffect(() => {
    if (data.length > 0) {
      setInputVal({
        ...inputVal,
        busiBank: data[0].busiBankName,
        busiBankAccount: data[0].busiAccount,
        busiBankAccountHolder: data[0].busiBankAccoutHolder,
      });
    }
  }, [data]);
  return (
    <SettlementContent>
      <Form>
        <div className="input-lists">
          <span>은행명</span>
          <TextInput
            placeholder="은행명을 입력해 주세요."
            onChange={(e) => {
              setInputVal({ ...inputVal, busiBank: e.target.value.trim() });
              setData([{ ...data[0], busiBankName: e.target.value.trim() }]);
            }}
            value={inputVal.busiBank}
          />
        </div>
        <div className="input-lists">
          <span>계좌번호</span>
          <TextInput
            placeholder="'-'없이 입력해 주세요."
            onChange={(e) => {
              setInputVal({ ...inputVal, busiBankAccount: e.target.value.trim() });
              setData([{ ...data[0], busiAccount: e.target.value.trim() }]);
            }}
            value={inputVal.busiBankAccount}
          />
        </div>
        <div className="input-lists">
          <span>예금주명</span>
          <TextInput
            placeholder="예금주명을 입력해 주세요."
            onChange={(e) => {
              setInputVal({
                ...inputVal,
                busiBankAccountHolder: e.target.value.trim(),
              });
              setData([{ ...data[0], busiBankAccoutHolder: e.target.value.trim() }]);
            }}
            value={inputVal.busiBankAccountHolder}
          />
        </div>
        <div className="input-lists" style={{ marginBottom: '25px' }}>
          <span>통장사본</span>
        </div>
        {/* Image Upload */}
        <InfoImageUploader prevImages={savedImg} setFile={setImgFile} setDelKey={setImgDelKey} kind="partnerBank" maxImage={1} />
        <div className="calc-bottom-text">
          <Div>
            <div className="white-dot" />
            <span className="bottom-text">{FormText[0].bottom[0]}</span>
          </Div>
        </div>
      </Form>
    </SettlementContent>
  );
};

export default TabCalculate;

import React from 'react';
import Login from './components/Login/Login';
import RegisterForm from './components/RegisterForm/RegisterForm';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Frame from './components/Manage/Frame';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ko';
import { ThemeProvider, createTheme } from '@mui/material';
import chat from 'modules/chat';
import utils from 'modules/utils';
import './App.scss';

window.utils = utils;
window.chat = chat;

export const postUrl = (url, data) => {
  // url + ? + data
  let params = '';
  for (let key in data) {
    params += `${key}=${data[key]}&`;
  }
  params = params.slice(0, -1);
  return url + '?' + params;
};

function App() {
  const logoutLog = () => {
    const BASE_URL = process.env.REACT_APP_BACKEND_URL;
    const headers = {
      Authorization: `Bearer ${utils.user('token')}`,
    };
    const url = BASE_URL + '/v2/SP_m_logoutLogV2_i1';
    const data = {
      userDevice: 'web',
    };

    fetch(postUrl(url, data), {
      method: 'POST',
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const darkTheme = createTheme({
    //fontFamily: 'Noto Sans KR',

    palette: {
      mode: 'light',
      primary: {
        light: '#b39eff',
        main: '#7e6eff',
        dark: '#6959de',
        contrastText: '#fff',
      },
      secondary: {
        light: '#bdbdbd',
        main: '#8f8e8c',
        dark: '#5f5f5f',
        contrastText: '#fff',
      },

      success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: '#fff',
      },
      info: {
        // main : '5affe2'
        light: '#5affe2',
        main: '#5affe2',
        dark: '#5affe2',
        contrastText: '#000',
      },
      warning: {
        light: '#ffffff',
        main: '#ffffff',
        dark: '#cccccc',
        contrastText: '#8a8a8a',
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderWidth: '2px !important',
          },
        },
      },
      MuiTextField: {
        // disable 일때 background color 회색
        styleOverrides: {
          root: {
            // outlined background color
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#fff',
            },
            '& .Mui-disabled': {
              backgroundColor: '#f6f6f6',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            // outlined background color
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#fff',
            },
            '& .Mui-disabled': {
              backgroundColor: '#f6f6f6',
            },
            '& .MuiInputBase-input': {
              backgroundColor: '#fff',
            },
          },
        },
      },

      // tooltip
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '1rem',
          },
        },
      },
    },
    typography: {
      fontFamily: 'Noto Sans KR',
    },
  });

  window.onbeforeunload = function () {
    // storage에 user 정보가 있으면 로그아웃 로그 남기기
    if (utils.isNotEmpty(utils.storage.session('user'))) {
      logoutLog();
    }
  };

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
        <BrowserRouter>
          <ThemeProvider theme={darkTheme}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<RegisterForm />} />
              <Route path="/manage/*" element={<Frame />} />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </div>
  );
}

export default App;

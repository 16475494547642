import utils from '../utils';

// 디버그 모드 설정
const debug = false;

// 채팅 목록을 관리하는 객체
const chatList = {};

// 채팅 객체 생성자 함수
const chat = function (chatCode, onMessage, onClose) {
  // 인스턴스 변수 초기화
  this.chatCode = null;
  this.socket = null;
  this.tryClose = false;

  // 초기화 함수
  let init = () => {
    this.socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL + chatCode);
    debug && console.log(process.env.REACT_APP_WEBSOCKET_URL + chatCode);
    this.chatCode = chatCode;

    // WebSocket 이벤트 핸들러 설정
    this.socket.onopen = () => {
      debug && console.log(`[Chat ${chatCode}] connected`);
    };
    this.socket.onmessage = (message) => {
      debug && console.log(message);
      utils.isFunction(onMessage) && onMessage(message);
    };

    this.socket.onclose = (e) => {
      if (e.wasClean) {
        debug && console.log(`[Chat ${chatCode}] disconnected (code=${e.code} reason=${e.reason})`);
      } else {
        debug && console.log(`[Chat ${chatCode}] disconnected with error`, e);
      }

      if (this.tryClose) {
        if (utils.isFunction(onClose)) onClose();
        delete chatList[this.chatCode];
      } else {
        init();
      }
    };

    this.socket.onerror = function (error) {
      console.log(`[Chat ${chatCode}] error:`, error);
    };
  };

  // WebSocket 연결 종료 메서드
  this.close = () => {
    if (utils.isDefine(this.socket) && this.socket instanceof WebSocket) {
      this.tryClose = true;
      this.socket.close();
    }
  };

  // 메시지 전송 메서드
  this.send = (message) => {
    if (utils.isDefine(this.socket) && this.socket instanceof WebSocket) {
      this.socket.send(utils.toJSON(message));
    } else {
      console.log(`[Chat ${chatCode}] socket is not connected`);
    }
  };

  // 초기화 함수 호출
  init();
};

// 채팅 관련 기능을 제공하는 객체
let chatCore = {
  // chatCode에 해당하는 WebSocket 반환
  socket(chatCode) {
    return chatList[chatCode];
  },

  // 현재 열린 채팅 목록 반환
  codeList() {
    return Object.keys(chatList);
  },

  // 새로운 채팅 연결
  connect(chatCode, onMessage, onClose) {
    this.closeAll();
    chatList[chatCode] = new chat(chatCode, onMessage, onClose);
  },

  // 채팅 메시지 전송
  send(chatCode, message) {
    chatList[chatCode]?.send(message);
  },

  // 특정 채팅 연결 종료
  close(chatCode) {
    chatList[chatCode]?.close();
  },

  // 모든 채팅 연결 종료
  closeAll() {
    for (let chatCode in chatList) {
      chatList[chatCode].close();
    }
  }
};

// chatCore 객체를 불변으로 설정
Object.freeze(chatCore);

// chatCore 객체 내보내기
export default chatCore;

import styled from 'styled-components';

export const Navbar = styled.nav`
  width: 100%;
  background: #10141c;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 20px 30px;
  border-bottom: 1px solid #5affe2;

  @media screen and (max-width: 1024px) {
    height: 60px;
  }

  //480px
  @media screen and (max-width: 480px) {
    height: 50px;
    padding: 10px 20px;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  /* padding-left: 15px; */

  img {
    margin-left: 30px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: flex-end;
  gap: 10px;

  //480px
  @media screen and (max-width: 480px) {
    width: 80px;
    justify-content: flex-end;
  }
`;

import React, { useEffect, useState } from 'react';
import { ContentWrap } from '../../InfoElements';
import { ProfileImgBox, InfoBox, InputLists } from './PrivacyElements';
import ProfileImg from 'components/Parts/Common/ProfileImg/ProfileImg';
import ModifyPwModal from 'components/Parts/Common/Modal/ModifyPwModal';
import DeleteAccountModal from 'components/Parts/Common/Modal/DeleteAccountModal';
import utils from 'modules/utils';
// import portOne from "modules/portOne";
import { koreanRegex } from './../../../../../modules/regex/regex';

const TabPrivacy = ({ setSave, userInfo, pw, setPw, onSubmit }) => {
  const [partnerInfo, setPartnerInfo] = useState([]);

  // 서버에 전송할 이미지 파일
  const [selectedFile, setSelectedFile] = useState([]);

  // 개인정보 저장
  const onSave = async () => {
    try {
      const formData = new FormData();
      console.log(newPw);
      if (selectedFile.name) {
        let fileNameLen = selectedFile.name.length;
        let lastDot = selectedFile.name.lastIndexOf('.');
        formData.append('extension', selectedFile.name.substring(lastDot, fileNameLen).toLowerCase());
      } else if (!selectedFile.name && partnerInfo[0].partProfileExtension) {
        formData.append('extension', partnerInfo[0].partProfileExtension);
      } else {
        formData.append('extension', '');
      }

      if (inputVal.name === '') {
        utils.dialog.alert('이름을 입력해 주세요.', '이름을 입력해 주세요.', 'warning');
        return;
      } else if (inputVal.nick === '') {
        utils.dialog.alert('닉네임을 입력해 주세요.', '닉네임을 입력해 주세요.', 'warning');
        return;
      }

      formData.append('files', selectedFile);
      formData.append('gubn', 1);
      formData.append('hp', inputVal.phone);
      formData.append('email', inputVal.email);
      formData.append('id', inputVal.id);
      formData.append('name', inputVal.name);
      formData.append('newPassword', newPw || pw);
      formData.append('password', pw);
      formData.append('nickName', inputVal.nick);
      if (partnerInfo[0].partProfile === 1 && selectedFile.name) {
        formData.append('profile', '1');
      } else if (partnerInfo[0].partProfile === 0 && selectedFile.name) {
        formData.append('profile', '1');
      } else if (partnerInfo[0].partProfile === 1 && !selectedFile.name) {
        formData.append('profile', '1');
      } else {
        formData.append('profile', '0');
      }

      const res = await utils.http.postFile('/v1/SP_m_user_modify_i1', formData);
      //console.log(res.data);
      if (res.data.success) {
        utils.dialog.alert('저장 완료!', '개인정보가 수정되었습니다.', 'success');
        let topProfile = window.document.querySelector('#topProfileImage');
        topProfile.src = topProfile.src + '?' + new Date().getTime();

        // 비밀번호 변경 했을 경우
        if (newPw !== '') {
          setPw(newPw);
        } else {
          onSubmit();
        }
      } else {
        utils.dialog.alert('저장 실패!', '오류가 발생했습니다. 관리자에게 문의해 주세요.', 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 비밀번호 변경 모달
  const [modifyPw, setModifyPw] = useState(false);

  const openModifyPw = () => {
    setModifyPw(true);
  };

  const closeModifyPw = () => {
    setModifyPw(false);
  };

  // 비밀번호 변경 버튼을 클릭 유무 state
  const [isModify, setIsModify] = useState(false);

  // 비밀번호 state
  const [newPw, setNewPw] = useState('');
  const [checkPw, setCheckPw] = useState('');

  // 회원탈퇴 모달
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const [inputVal, setInputVal] = useState({
    name: '',
    id: '',
    nick: '',
    phone: '',
    password: '',
    email: '',
  });

  const getInfo = async () => {
    try {
      const url = `/v1/SP_p_partner_S1/${utils.user('id')}`;
      utils.http.setMode('api');
      const res = await utils.http.get(url);
      //console.log(res.data);
      if (res.data.data && res.data.data.length > 0) {
        setPartnerInfo(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
    console.log('current pw: ', pw);
  }, []);

  useEffect(() => {
    if (setSave) {
      setSave({ onSave });
    }
  }, [selectedFile, newPw, partnerInfo, inputVal]);

  useEffect(() => {
    if (userInfo.length > 0) {
      setInputVal({
        ...inputVal,
        name: userInfo[0].userName,
        id: userInfo[0].userId,
        nick: userInfo[0].userNick,
        phone: userInfo[0].userHp,
        email: userInfo[0].userEmail,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    // pw가 newPw로 업데이트 되었을 경우
    if (pw !== '' && newPw === pw) {
      console.log('pw = ' + pw, 'newPw = ' + newPw);
      onSubmit();
    }
  }, [pw]);

  return (
    <ContentWrap>
      <ModifyPwModal open={modifyPw} close={closeModifyPw} title="비밀번호 변경" userInfo={inputVal} newPw={newPw} setNewPw={setNewPw} checkPw={checkPw} setCheckPw={setCheckPw} isModify={isModify} setIsModify={setIsModify} />
      <DeleteAccountModal open={deleteModal} close={closeDeleteModal} title="정말 수리의 달인을 탈퇴하시나요?" userId={inputVal.id} />
      <ProfileImgBox>
        <ProfileImg userGubn={'partner'} setSelectedFile={setSelectedFile} />
      </ProfileImgBox>
      <InfoBox>
        <form action="#none">
          <InputLists>
            <span>이름</span>
            <input
              type="text"
              placeholder="김수달"
              className={'dark-input'}
              value={inputVal.name}
              onChange={(e) => {
                if (koreanRegex.test(e.target.value) || e.target.value === '') setInputVal({ ...inputVal, name: e.target.value.trim() });
              }}
              maxLength={10}
              readOnly
            />
          </InputLists>
          <InputLists>
            <span>아이디</span>
            <input type="text" className="dark-input" value={inputVal.id} readOnly />
          </InputLists>
          <InputLists style={{ display: 'none' }}>
            <span>닉네임</span>
            <input type="text" className="light-input" value={inputVal.nick} onChange={(e) => setInputVal({ ...inputVal, nick: e.target.value.trim() })} />
          </InputLists>

          <InputLists>
            <span>비밀번호</span>
            <button className="modify-privacy-btn" type="button" onClick={openModifyPw}>
              비밀번호 변경
            </button>
          </InputLists>
          <InputLists>
            <span>휴대폰번호</span>
            <input type="text" className="dark-input" value={inputVal.phone} readOnly />
          </InputLists>
          <InputLists>
            <span>이메일</span>
            <input type="email" className="dark-input" value={inputVal.email} readOnly />
          </InputLists>
          <div>
            <button type="button" className="delete-account-btn" onClick={openDeleteModal}>
              회원탈퇴
            </button>
          </div>
        </form>
      </InfoBox>
    </ContentWrap>
  );
};

export default TabPrivacy;

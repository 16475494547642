import Swal from 'sweetalert2';
import utils from 'modules/utils';

const confirmButtonColor = '#7f6eff';
const confirmButtonText = '확인';

const denyButtonColor = '#3F51B5';
const denyButtonText = '저장 안함';

const cancelButtonColor = '#fff';
const cancelButtonText = '취소';

const showCloseButton = true;
const focusConfirm = false;

let opened = 0;

function runConfirm(event) {
  if (event.keyCode === utils.const.keyCode.ENTER) {
    let confirmButtons = document.querySelectorAll('.swal2-confirm');
    confirmButtons[confirmButtons.length - 1].click();
  } else if (event.keyCode === utils.const.keyCode.ESC) {
    let cancelButtons = document.querySelectorAll('.swal2-cancel');
    cancelButtons[cancelButtons.length - 1].click();
  }
}
function activateEnter() {
  document.body.addEventListener('keydown', runConfirm);
  opened++;
}
function deActivateEnter() {
  document.body.removeEventListener('keydown', runConfirm);

  opened--;
}

function iconInfo(icon) {
  if (['success', 'question', 'info', 'error', 'warning'].includes(icon)) {
    return {
      icon,
    };
  } else {
    return {};
  }
}

let dialog = {
  CONFIRM: 1,
  DENY: 2,
  CANCEL: 3,
  opened() {
    return opened;
  },
  async alert(title, text, icon, footer, confirmText) {
    title = title?.trim();
    text = text?.trim();
    icon = icon?.trim();
    activateEnter();
    let res = (
      await Swal.fire(
        Object.assign(iconInfo(icon), {
          title,
          html: text,
          background: 'rgb(0 0 0 / 80%)',
          width: '500px',
          color: '#fff',
          footer,
          showCloseButton,
          focusConfirm,
          confirmButtonColor,
          confirmButtonText: confirmText ?? confirmButtonText,
          allowOutsideClick: false,
          allowEscapeKey: true,
          allowEnterKey: false,
        })
      )
    ).isConfirmed;
    deActivateEnter();

    return res;
  },
  async confirm(title, text, icon, footer, confirmText, cancelText) {
    title = title?.trim();
    text = text?.trim();
    icon = icon?.trim();
    activateEnter();
    let res = (
      await Swal.fire(
        Object.assign(iconInfo(icon), {
          title,
          html: text,
          background: 'rgb(0 0 0 / 80%)',
          width: '500px',
          color: '#fff',
          footer,
          showCloseButton,
          focusConfirm,
          showCancelButton: true,
          confirmButtonColor,
          cancelButtonColor,
          confirmButtonText: confirmText ?? confirmButtonText,
          cancelButtonText: cancelText ?? cancelButtonText,
          allowOutsideClick: false,
          allowEscapeKey: true,
          allowEnterKey: false,
        })
      )
    ).isConfirmed;
    deActivateEnter();

    return res;
  },
  async confirmMore(title, text, icon, footer, confirmText, denyText, cancelText) {
    title = title?.trim();
    text = text?.trim();
    icon = icon?.trim();
    activateEnter();
    let res = await Swal.fire(
      Object.assign(iconInfo(icon), {
        title,
        html: text,
        footer,
        showCloseButton,
        focusConfirm,
        showDenyButton: true,
        showCancelButton: true,
        denyButtonColor,
        confirmButtonColor,
        cancelButtonColor,
        confirmButtonText: confirmText ?? '저장',
        denyButtonText: denyText ?? denyButtonText,
        cancelButtonText: cancelText ?? cancelButtonText,
        allowOutsideClick: false,
        allowEscapeKey: true,
        allowEnterKey: false,
      })
    );
    deActivateEnter();

    if (res.isConfirmed) return this.CONFIRM;
    else if (res.isDenied) return this.DENY;
    else return this.CANCEL;
  },
};
Object.freeze(dialog);
export default dialog;

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Divider, styled } from '@mui/material';
import SelectRegions2 from 'components/Area/SelectRegions2';
import { ticketInfo } from 'components/Manage/Ticket/TicketList/TicketList';
import { partInfoStore, reservationPaymentStore } from 'modules/store/store';
import { useEffect, useState } from 'react';
import utils from './../../../../modules/utils/index';
import { Form, SubmitBtn } from './ModalElements';
import { MainTitle, MoreBtn, PrimaryColorBox, SubTitle, TicketBox, TicketDiscount, TicketFooter, TicketIcon, TicketTitle } from './TicketModalElements';
import TicketPaymentModal from './TicketPaymentModal';
import WindowFrame from './WindowFrame';

export const partnerAreaSave = async (areaArr) => {
  try {
    const data = {
      partArea: areaArr?.map((x) => x.cd).join('_//'),
      partGubn: '2'
    };
    utils.http.setMode('api');
    const res = await utils.http.post('/v1/SP_p_partPlus_i1', null, data);
    if (res.data.success && res.data.data) {
      console.log('파트너 지역 설정 완료', res.data?.data);
    } else {
      utils.dialog.alert('저장 실패!', `${res.data?.message}`, 'error');
    }
  } catch (error) {
    console.log(error);
  }
};

export const MintText = styled('span')(() => ({
  color: 'var(--mint-color)'
}));

export const CustomTicket = ({ ticket, selectedTicket, setSelectedTicket, chipDataLength, disabled }) => {
  const { partInfo } = partInfoStore();

  const handleClick = () => {
    // 선택한 지역이 지역설정 보다 크면 error alert
    if (chipDataLength > ticket.area_count) {
      utils.dialog.alert('구독권을 다시 선택해주세요.', '선택한 지역이 구독권의 지역설정 개수보다 많습니다.', 'error');
      return;
    }
    const selectedTicketOption = ticketInfo.filter((item) => item?.title === ticket?.level_name)[0];
    setSelectedTicket({
      ...ticket,
      ...selectedTicketOption
    });
  };

  // 첫 구독권 가격 체크
  const checkFirstPrice = () => {
    if (partInfo.partnerFirstTicket === 'Y') {
      return ticket.first_sale_price;
    } else {
      return ticket.sale_price;
    }
  };

  const checkFirstDiscount = () => {
    if (partInfo.partnerFirstTicket === 'Y') {
      return ticket.first_discount;
    } else {
      return ticket.discount;
    }
  };

  return (
    <TicketBox
      onClick={disabled ? undefined : handleClick}
      sx={{
        opacity: selectedTicket?.ticket_level === undefined ? 1 : selectedTicket?.ticket_level === ticket?.ticket_level ? 1 : 0.5
      }}
    >
      {partInfo?.partnerFirstTicket === 'Y' && <Box sx={{ position: 'absolute', backgroundColor: 'var(--primary-color)', top: 0, left: 0, fontSize: '16px', color: '#fff', padding: '2px 20px', borderRadius: '10px 0 8px 0' }}>첫결제 이벤트</Box>}
      <TicketTitle>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            fontSize: '20px'
          }}
        >
          {ticketInfo
            .filter((item) => item.title === ticket?.level_name)
            .map((param, index) => (
              <TicketIcon key={index} sx={{ backgroundColor: param.color }}>
                {param?.icon}
              </TicketIcon>
            ))}
          <Box>{ticket.level_name}</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'flex-end'
          }}
        >
          <Box sx={{ display: 'flex', width: '40px', height: '20px' }}>{checkFirstDiscount() > 0 && <TicketDiscount>{checkFirstDiscount() + '%'}</TicketDiscount>}</Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            {ticket.price !== checkFirstPrice() && <Box sx={{ fontSize: '14px', color: '#8a8a8a', textDecoration: 'line-through' }}>{ticket.price.toLocaleString()}원</Box>}
            <Box sx={{ fontSize: '22px' }} noDiscountPrice={ticket.price !== checkFirstPrice()}>
              {checkFirstPrice()?.toLocaleString()}원
            </Box>
          </Box>
        </Box>
      </TicketTitle>
      <Divider
        sx={{
          width: '100%',
          backgroundColor: '#8a8a8a'
        }}
      />
      <TicketFooter>
        <Box>견적 무제한</Box>
        <PrimaryColorBox>+ 지역설정 {ticket.ticket_level === 6 ? '무제한' : ticket.area_count + '개'}</PrimaryColorBox>
      </TicketFooter>
    </TicketBox>
  );
};

const AreaAndTicketModal = ({ open, close, title, chipData, setChipData, reflashTicketList }) => {
  // 견적 제출 버튼 클릭

  const { ticketList, getTicketList } = reservationPaymentStore();
  const [moreTicket, setMoreTicket] = useState(true);
  const [recommendTicket, setRecommendTicket] = useState({});
  const [selectedTicket, setSelectedTicket] = useState({});
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  // 저장된 노출 지역 불러오기
  const getSavedCity = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get('/v1/SP_p_partPlus_s1');
      console.log('파트너 지역 불러오기', res.data.data);
      if (res.data.data.length > 0) {
        const regionList = res.data.data?.map((item) => {
          return { region: item?.city, cd: item?.regionCode, nm: item?.regionName };
        });

        setChipData(regionList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSavedCity();
    getTicketList();
    setSelectedTicket({});
    setMoreTicket(true);
    // 모달을 열 때 filterTicket의 초기값 설정
    const initialFilterTicket = ticketList[1];
    setRecommendTicket(initialFilterTicket);
  }, [open]);

  useEffect(() => {
    if (chipData) {
      const filterTicket = ticketList.filter((ticket) => ticket?.area_count >= chipData.length);
      const recommendTicketInfo = ticketInfo.filter((item) => item.title === filterTicket.at(-1)?.level_name)[0];

      // filterTicket 배열의 마지막 요소를 추천 티켓으로 설정
      setRecommendTicket({ ...filterTicket.at(-1), ...recommendTicketInfo });
      // setSelectedTicket({ ...filterTicket.at(-1), ...recommendTicketInfo }); //자동선택
      setSelectedTicket({});
    }
  }, [chipData]);

  const handleSubmit = () => {
    if (selectedTicket?.ticket_level === undefined) {
      utils.dialog.alert('구독권을 선택해주세요.', '구독권을 선택해주세요.', 'error');
      return;
    }
    setShowPaymentModal(true);
  };

  const diamondTicket = ticketList.filter((item) => item?.ticket_level === 6)[0];

  const handleModalClose = () => {
    close();
  };

  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'787px'}
      height={'90vh'}
      slotFixedButton={
        <SubmitBtn type="button" onClick={handleSubmit} disabled={selectedTicket?.ticket_level === undefined}>
          구독
        </SubmitBtn>
      }
    >
      <Form action="#none">
        <Box
          sx={{
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <MainTitle>원하는 노출 지역을 설정해주세요.</MainTitle>
            <SubTitle>노출 지역이 넓을수록 고객을 찾는 범위가 넓어집니다.</SubTitle>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <MainTitle>노출 지역</MainTitle>
            <SubTitle
              sx={{
                color: 'var(--mint-color)'
              }}
            >
              선택한 지역 : {chipData?.length ?? 0}개
            </SubTitle>
          </Box>
          <SelectRegions2 chipData={chipData} setChipData={setChipData} />
          <MainTitle sx={{ width: '100%' }}>달인에게 맞는 구독권을 추천해드려요.</MainTitle>
          <SubTitle
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            월 구독료 · 부가세포함
          </SubTitle>

          {recommendTicket?.ticket_level !== 6 && <CustomTicket ticket={recommendTicket} areaText={`지역설정 ${recommendTicket?.area_count}개`} selectedTicket={selectedTicket} setSelectedTicket={setSelectedTicket} />}
          {diamondTicket && <CustomTicket ticket={diamondTicket} areaText={'지역설정 무제한'} selectedTicket={selectedTicket} setSelectedTicket={setSelectedTicket} />}
          {moreTicket ? (
            <MoreBtn
              onClick={() => {
                setMoreTicket(false);
              }}
              fullWidth
              variant="outlined"
            >
              구독권 접기
              <ArrowDropUpIcon
                sx={{
                  color: 'var(--primary-color)'
                }}
              />
            </MoreBtn>
          ) : (
            <MoreBtn
              onClick={() => {
                setMoreTicket(true);
              }}
              fullWidth
              variant="outlined"
            >
              구독권 전체보기
              <ArrowDropDownIcon
                sx={{
                  color: 'var(--primary-color)'
                }}
              />
            </MoreBtn>
          )}
          {moreTicket &&
            ticketList
              .filter((item) => {
                return item?.ticket_level !== 6 && item?.ticket_level !== recommendTicket?.ticket_level;
              })
              .map((ticket) => <CustomTicket key={ticket?.level_name} ticket={ticket} areaText={`지역설정 ${ticket?.area_count}개`} selectedTicket={selectedTicket} setSelectedTicket={setSelectedTicket} chipDataLength={chipData.length} />)}
        </Box>
      </Form>
      <TicketPaymentModal open={showPaymentModal} close={() => setShowPaymentModal(false)} title={'구독권 결제'} ticket={selectedTicket} reflashTicketList={reflashTicketList} parentsModalClose={handleModalClose} chipData={chipData} />
    </WindowFrame>
  );
};

export default AreaAndTicketModal;

import React, { useState, useEffect } from 'react';
import { Box, CategoryBox, BtnWrap, BtnMenu, ShowChecked, SelectedBtn, NoneChecked, CloseBtn, Menu, StyledLabel, StyledCheckbox, ResetBtn, TitleBox, SubTitleText, TitleText } from './CategoryElements';
import { ContentWrap } from 'components/Manage/Info/InfoElements';
import SelectBtn from 'components/Parts/Common/SelectBtn/SelectBtn';
import EmptyBox from 'components/Parts/Common/EmptyBox';
import Icon from 'components/Parts/Common/Icon';
import { Scrollbars } from 'react-custom-scrollbars-2';
import utils from 'modules/utils';
import { styled } from 'styled-components';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { SaveText } from './../../../InfoElements';

let ContentWrap2 = styled(ContentWrap)`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: 35px minmax(auto, 342px) minmax(140px, auto);
`;

const btnMenu = [
  {
    id: 'A',
    idx: 0,
    title: '홈리빙',
  },
  {
    id: 'B',
    idx: 1,
    title: '제품',
  },
  {
    id: 'C',
    idx: 2,
    title: '생활편의',
  },
];
const TabCategory = ({ setSave, partnerInfo }) => {
  const [categoryList, setCategoryList] = useState([]);

  const getCategoryLists = () => {
    const url = `/v1/SP_b_Category_s1`;
    utils.http.setMode('api');
    if (selectMenu >= 0) {
      utils.http
        .post(url, null, {
          category: '',
          // gubn: String.fromCharCode(65 + selectMenu),
          gubn: btnMenu.find((item) => item.idx === selectMenu).id,
        })
        .then((res) => {
          setCategoryList(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  };
  // 저장되어 있는 카테고리 항목 state
  const [savedCate, setSavedCate] = useState([]);

  // checkbox state
  const [checkList, setCheckList] = useState([]);
  const [cateId, setCateId] = useState([]);

  const onCheckedElement = (e) => {
    if (e.target.checked) {
      setCheckList((checkList) => [...checkList, e.target.value]);
      setCateId((cateId) => [...cateId, e.target.id]);
    } else {
      setCheckList(checkList.filter((choice) => choice !== e.target.value));
      setCateId(cateId.filter((choice) => choice !== e.target.id));
    }
  };

  const [selectMenu, setSelectMenu] = useState(-1);

  const resetCheckList = () => {
    setCheckList([]);
    setCateId([]);
  };

  const getSavedCate = () => {
    let list = [];
    if (categoryList.length > 0 && savedCate.length > 0) {
      list = categoryList.filter((item) => savedCate.includes(item.cateId.toString()));
    }
    for (let i = 0; i < list.length; i++) {
      if (!checkList.includes(list[i].cateName)) {
        setCheckList((checkList) => [...checkList, list[i].cateName]);
      }
      if (!cateId.includes(list[i].cateId.toString())) {
        setCateId((cateId) => [...cateId, list[i].cateId.toString()]);
      }
    }
  };

  // 저장 버튼 클릭했을 때 api 요청
  const onSave = async (getInfo) => {
    try {
      const data = {
        partCategory: String.fromCharCode(65 + selectMenu),
        partCategory2: cateId.join('_//'),
      };

      if (data.partCategory2 === '') {
        utils.dialog.alert('저장 실패!', '카테고리를 1개 이상 선택해 주세요.', 'error');
        return;
      }

      console.log(data);
      utils.http.setMode('api');
      const res = await utils.http.post('/v1/SP_p_partCompInfo_wi2', null, data);

      if (res.data.success) {
        utils.isFunction(getInfo) && getInfo();
        utils.dialog.alert('저장 완료!', '입력하신 내용이 저장되었습니다.<br />주기적으로 관리하여<br /> 우리 업체를 홍보해요!', 'success');
      } else {
        utils.dialog.alert('저장 실패!', '오류가 발생했습니다. 관리자에게 문의해 주세요.', 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoryLists();
    resetCheckList();
  }, [selectMenu]);

  useEffect(() => {
    if (partnerInfo.length > 0 && partnerInfo[0].partCategory2 && partnerInfo[0].partCategory2 !== '') {
      setSavedCate(partnerInfo[0].partCategory2.split(','));
    }

    if (partnerInfo.length > 0) {
      if (partnerInfo[0].partCategory === 'A') {
        setSelectMenu(0);
      } else if (partnerInfo[0].partCategory === 'B') {
        setSelectMenu(1);
      } else if (partnerInfo[0].partCategory === 'C') {
        setSelectMenu(2);
      } else {
        setSelectMenu(-1);
      }
    }
  }, [partnerInfo]);

  useEffect(() => {
    if (setSave) {
      setSave({ onSave });
    }
  }, [cateId, checkList]);

  useEffect(() => {
    getSavedCate();
  }, [categoryList, savedCate]);

  return (
    <ContentWrap2>
      <TitleBox>
        <TitleText>카테고리</TitleText>
        <SubTitleText>[대분류는 하나만 선택 가능합니다.]</SubTitleText>
      </TitleBox>
      <div style={{ display: 'flex', gap: '30px' }}>
        <BtnWrap>
          <Scrollbars className="scrollbar-container outside-scrollbar" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
            {btnMenu.map((menu) => (
              <BtnMenu key={menu.id}>
                <SelectBtn
                  menu={menu.title}
                  size="small"
                  idx={menu.idx}
                  select={selectMenu}
                  setSelect={(selectMenu) => {
                    setSavedCate([]);
                    setSelectMenu(selectMenu);
                  }}
                />
              </BtnMenu>
            ))}
          </Scrollbars>
        </BtnWrap>

        <CategoryBox>
          <Scrollbars className="scrollbar-container outside-scrollbar" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
            <Menu>
              {categoryList.map((item, index) => (
                <React.Fragment key={item.cateGubn + item.cateId}>
                  <StyledLabel htmlFor={item.cateId} className={checkList.includes(item.cateName) && 'selected'}>
                    <section>
                      <span>{item.cateName}</span>
                      <StyledCheckbox
                        value={item.cateName}
                        onChange={(e) => {
                          onCheckedElement(e);
                        }}
                        id={item.cateId}
                        checklist={checkList}
                        checked={checkList.includes(item.cateName) ? true : false}
                      />
                    </section>
                  </StyledLabel>
                </React.Fragment>
              ))}
            </Menu>
          </Scrollbars>
        </CategoryBox>
      </div>

      {checkList.length === 0 ? (
        <NoneChecked>
          <EmptyBox message="카테고리를 선택해 주세요." />
        </NoneChecked>
      ) : (
        <ShowChecked>
          <Scrollbars className="scrollbar-container outside-scrollbar" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
            {checkList.map((li, index) => (
              <SelectedBtn key={index + li}>
                {`${btnMenu[selectMenu].title}>${li}`}
                <CloseBtn
                  id={cateId[index]}
                  onClick={() => {
                    setCheckList(checkList.filter((x) => x !== li));
                    cateId.splice(index, 1);
                    setCateId([...cateId]);
                  }}
                >
                  <CloseRoundedIcon sx={{ color: '#fff' }} />
                </CloseBtn>
              </SelectedBtn>
            ))}
          </Scrollbars>
          <ResetBtn onClick={resetCheckList}>
            설정초기화
            <RefreshOutlinedIcon />
          </ResetBtn>
        </ShowChecked>
      )}
      <SaveText>
        <div>※ 정보 입력 및 수정 시, 저장버튼을 눌러주세요</div>
      </SaveText>
    </ContentWrap2>
  );
};

export default TabCategory;

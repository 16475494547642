import { Card, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import KakaoPay from './../../assets/payment/kakao.png';
import NaverPay from './../../assets/payment/naver_white.png';
import TossPay from './../../assets/payment/logo-toss-pay-white.png';

const CustomCard = styled(Card)({
  textAlign: 'center',
  padding: '20px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  padding: '10px 40px',
  border: '2px solid #37465b',
  color: '#fff',
  fontSize: '20px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',
  '&:hover': {
    border: '2px solid #7e6eff',
  },
});

const PaymentSelect = ({ selectedPayment, setSelectedPayment }) => {
  return (
    <Grid2 container spacing={2}>
      <Grid2 md={12}>
        <CustomCard
          sx={{
            border: selectedPayment === 1 ? '2px solid #7e6eff' : '',
          }}
          onClick={() => setSelectedPayment(1)}
        >
          <img src={KakaoPay} alt="kakao" style={{ height: '32px' }} />
          카카오페이
        </CustomCard>
      </Grid2>
      <Grid2 md={12}>
        <CustomCard
          sx={{
            border: selectedPayment === 2 ? '2px solid #7e6eff' : '',
          }}
          onClick={() => setSelectedPayment(2)}
        >
          <img src={TossPay} alt="toss" style={{ height: '24px' }} />
          토스페이
        </CustomCard>
      </Grid2>
    </Grid2>
  );
};

export default PaymentSelect;

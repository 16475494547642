import React from 'react';
import { StyledLabel, StyledInput, StyledP, StyledDiv, StyledBtn } from './CheckBoxElements';

const CheckBox = (props) => {
  const { text, name, showBtn, btnText, modalOpen, allChecked, changeHandle, checkList, between } = props;
  const allCheckItem = allChecked ? true : false;
  const onChangeHandle = (e) => {
    allCheckItem ? allChecked(e) : changeHandle(e);
  };
  return (
    <StyledLabel
      style={{
        width: between ? '100%' : 'auto',
      }}
    >
      <StyledInput type="checkbox" id={name} name={name} onChange={onChangeHandle} checked={allCheckItem ? (checkList.length === 4 ? true : false) : checkList.includes(name) ? true : false} />
      <StyledDiv
        style={{
          width: between ? '100%' : 'auto',
          justifyContent: between ? 'space-between' : 'flex-start',
        }}
      >
        <StyledP>{text}</StyledP>
        {showBtn ? (
          <StyledBtn onClick={modalOpen} type="button">
            {btnText}
          </StyledBtn>
        ) : (
          <></>
        )}
      </StyledDiv>
    </StyledLabel>
  );
};

export default CheckBox;

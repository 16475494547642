import styled from 'styled-components';

export const Btn = styled.button`
  width: 100%;
  height: 81px;
  border-radius: 20px;
  border: solid 1px transparent;
  background-color: #37465b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #fff;
  padding: 30px 30px 30px 34px;

  &:hover,
  &.selected {
    border: 1px solid #5affe2;
    color: #5affe2;
  }
  &:hover .icon {
    color: #5affe2;
  }
  &.selected .icon {
    color: #5affe2;
  }
`;

export const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  svg {
    font-size: 30px;
  }
`;

import React, { useState } from 'react';
import { ContentWrap } from '../../InfoElements';
import { TermsWrap, TermsLists, ShowBtn, ReceiveInfo } from './PrivacyElements';
import ToggleSwitch from 'components/Parts/Common/ToggleSwitch/ToggleSwitch';
import { BdWhiteText, TitleText } from 'style';
import Modal from 'components/RegisterForm/ShowLawModal/ShowLawModal';
import { useMediaQuery } from 'react-responsive';

const TabTerms = ({ termsLists }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const [lawGubn, setLawGubn] = useState(5);
  const [lawTitle, setLawTitle] = useState('');

  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <ContentWrap style={{ height: isTablet ? '280px' : '580px' }}>
      <Modal open={modal} close={closeModal} header={lawTitle} gubn={lawGubn} />
      <TermsWrap>
        {termsLists.map((terms, index) => (
          <TermsLists key={`${terms}-${index}`}>
            <BdWhiteText>{terms.title}</BdWhiteText>
            <ShowBtn
              type="button"
              onClick={() => {
                openModal();
                setLawGubn(terms.lawGubn);
                setLawTitle(terms.title);
              }}
            >
              보기
            </ShowBtn>
          </TermsLists>
        ))}
        {/* <hr />
        <TitleText style={{ marginBottom: "28px" }}>
          이벤트 및 혜택에 대한 다양한 정보를 받으실 수 있어요.
        </TitleText>
        <ReceiveInfo>
          <div>
            <BdWhiteText>SMS 수신 동의</BdWhiteText>
            <ToggleSwitch
              isChecked={isChecked1}
              setIsChecked={setIsChecked1}
              id="toggleBtn1"
            />
          </div>
          <div>
            <BdWhiteText>메일 수신 동의</BdWhiteText>
            <ToggleSwitch
              isChecked={isChecked2}
              setIsChecked={setIsChecked2}
              id="toggleBtn2"
            />
          </div>
        </ReceiveInfo>
         */}
      </TermsWrap>
    </ContentWrap>
  );
};

export default TabTerms;

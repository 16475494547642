import styled from 'styled-components';

export const OpenModalBg = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #10141c;
`;

export const CloseModalBg = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
`;

export const Section = styled.section`
  position: relative;
  width: 730px;
  height: ${(props) => props.height};
  margin: 0 auto;
  padding: ${(props) => props.padding};
  border: 1px solid #717378f0;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #2b2f44;
  overflow: hidden;
  box-shadow: 0 0 4px #504f4f96 inset;

  .header-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  //1024px
  @media all and (max-width: 1024px) {
    width: 500px;
  }

  /* 480px이하일때 배경색 변경 */
  @media all and (max-width: 480px) {
    width: 100vw;
    height: 100vh !important;
    border-radius: 0;
    padding: 0;
    background-color: #10141c;
  }
`;

export const HeaderText = styled.p`
  width: 100%;
  height: fit-content;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  line-height: 48px;
  letter-spacing: normal;
  white-space: pre-line;

  @media all and (max-width: 1024px) {
    font-size: 30px;
  }
  @media all and (max-width: 480px) {
    font-size: 22px !important;
  }
`;

export const LineHeaderText = styled.p`
  display: inline-block;
  font-size: 42px;
  font-weight: bold;
  color: #fff;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: -7px;
    bottom: 3px;
    width: 100%;
    padding: 5px 7px;
    background-color: #7e6eff;
    z-index: -1;
  }

  @media all and (max-width: 1024px) {
    font-size: 30px;
  }

  @media all and (max-width: 480px) {
    font-size: 22px;
  }
`;

export const BtnWrap = styled.div`
  width: 100%;
  height: auto;
  text-align: right;
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 30px;
  width: 30px;
  font-size: 21px;
  text-align: center;
  color: #fff;
  background-color: transparent;
  cursor: pointer;

  .icon {
    font-size: 45px;
  }
`;

export const Main = styled.main`
  width: 100%;
  height: calc(100% - 129px);
`;

export const MainContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;

  @media all and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .input-box {
    width: 100%;
    height: auto;
    margin-top: 35px;
    display: flex;
    flex-direction: column;

    .id-input {
      width: 100%;
      height: 50px;
      margin-top: 20px;
      border-radius: 5px;
      background-color: #fff;
      font-size: 20px;
      color: #10141c;
      padding: 5px 15px;

      &::placeholder {
        color: #8a8a8a;
        font-size: 18px;
      }

      &:focus::placeholder {
        visibility: hidden;
      }
    }

    .verify-box {
      margin-top: 3px;
      margin-left: 15px;
      width: 100%;
      line-height: 1;
      color: #eb4141;
      font-size: 15px;
      font-weight: normal;
    }

    .next-btn {
      width: 100%;
      height: 50px;
      margin-top: 40px;
      border-radius: 5px;
      background-color: #7e6eff;
      color: #fff;
      font-size: 26px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: #6959de;
      }
    }

    .change-btn {
      position: absolute;
      bottom: 60px;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: calc(100% - 140px);
      height: 50px;
      margin-top: 20px;
      border-radius: 5px;
      background-color: #7e6eff;
      color: #fff;
      font-size: 26px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: #6959de;
      }
    }
  }
`;

export const MainContentText = styled.p`
  color: #fff;
  font-size: 26px;
  font-weight: normal;

  @media all and (max-width: 1024px) {
    font-size: 18px;
  }

  @media all and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 383px;

  @media all and (max-width: 1024px) {
    height: 300px;
  }
`;

export const VerifyBtn = styled.button`
  width: 100%;
  height: 70px;
  background-color: #7e6eff;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #6959de;
  }
  @media all and (max-width: 1024px) {
    height: 48px;
    font-size: 20px;
  }

  @media all and (max-width: 480px) {
    height: 50px;
    font-size: 18px !important;
  }
`;

export const Label = styled.label`
  position: relative;
  width: 100%;
  height: auto;

  .pw-input {
    margin-top: 15px;
    width: 93%;
    height: 50px;
    font-size: 20px;
    color: #10141c;
    padding: 5px 15px;
    font-weight: normal;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &::placeholder {
      color: #8a8a8a;
      font-size: 18px;
    }

    &:focus::placeholder {
      visibility: hidden;
    }
  }

  span {
    display: flex;
    align-items: center;
    width: 7%;
    height: 50px;
    position: absolute;
    padding: 0;
    top: 15px;
    right: 0;
    background: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: left;

    .icon {
      font-size: 32px;
      color: #eb4141;
    }
  }
`;

export const SelectBtnWrap = styled.div`
  height: calc(100% - 196px);
  display: flex;
  flex-direction: column;
  gap: 26px;

  @media all and (max-width: 480px) {
    flex-direction: row;
    gap: 10px;
  }
`;

export const BtnImage = styled.div`
  width: 81px;
  height: 88px;
  object-fit: cover;

  @media all and (max-width: 1024px) {
    height: 60px;
    img {
      height: 100%;
    }
  }

  @media all and (max-width: 480px) {
    width: 80px;
    height: 80px;
  }
`;

export const FindBtn = styled.button`
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  height: 110px;
  padding: 17px 27px 17px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  text-align: left;
  cursor: pointer;
  transition: all 0.1s ease;

  &:hover {
    background-color: #f7f7f7fa;
  }

  @media all and (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
    height: 220px;
    width: 100%;
    padding: 10px;
    justify-content: space-around;
  }
`;

export const BtnText = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 480px) {
    width: 100%;
    align-items: center;
  }
`;

export const SubText = styled.span`
  font-size: 18px;
  font-weight: normal;
  color: #777;

  @media all and (max-width: 1024px) {
    font-size: 14px;
  }

  @media all and (max-width: 480px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const ArrowWrap = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Arrow = styled.div`
  width: 14px;
  height: 24px;
  background: transparent;
  cursor: pointer;
`;

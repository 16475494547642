import React, { useState } from 'react';
import { TheadBtn, Table, Tbody, TbodyRow, TbodyCol } from './TableElements';
import { Scrollbars } from 'react-custom-scrollbars-2';
import EmptyBox from 'components/Parts/Common/EmptyBox';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const LinkText = styled(Link)`
  font-size: 18px;
  font-weight: normal;
  color: #fff;
  text-align: center;

  &:hover {
    color: #5affe2;
  }
`;

const HomeEstimateTable = (props) => {
  const { head1, head2, rows1, rows2, activeBtn, setActiveBtn } = props;
  // 버튼 state
  // 클릭하지 않았을 때 버튼 스타일
  const btnStyle = {
    color: '#fff',
    display: 'none',
  };
  // 버튼을 클릭했을 때 스타일
  const activeBtnStyle = {
    color: '#5affe2',
    display: 'block',
  };
  return (
    <div
      className="home-estimate-table"
      style={{
        marginTop: '20px',
      }}
    >
      <TheadBtn onClick={() => setActiveBtn('left')} btnstyle={activeBtn === 'left' ? activeBtnStyle : btnStyle}>
        {head1}
      </TheadBtn>
      <TheadBtn onClick={() => setActiveBtn('right')} btnstyle={activeBtn === 'right' ? activeBtnStyle : btnStyle}>
        {head2}
      </TheadBtn>
      <Scrollbars className="scrollbar-container" style={{ height: 'calc(100% - 40px)' }} renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
        {/* activeBtn === 'left'일 경우 leftBtnRows 데이터, activeBtn === 'right'일 경우 rightBtnRows 데이터를 보여 줌 */}
        {activeBtn === 'left' && rows1.length > 0 ? (
          <Table>
            <Tbody>
              {rows1.map((row, index) => (
                <TbodyRow key={'left-table' + index}>
                  {/* 제목 */}
                  <TbodyCol>
                    <LinkText to={`/manage/history/estimateList/received/${row.workDate}/${row.sqenNumb}`}>{row.reqSubject}</LinkText>
                  </TbodyCol>
                  {/* 작성 날짜 */}
                  <TbodyCol>{row.ftDt}</TbodyCol>
                </TbodyRow>
              ))}
            </Tbody>
          </Table>
        ) : activeBtn === 'left' && rows1.length === 0 ? (
          <EmptyBox message="목록이 존재하지 않습니다." />
        ) : activeBtn === 'right' && rows2.length > 0 ? (
          <Table>
            <Tbody>
              {rows2.map((row, index) => (
                <TbodyRow key={'rows' + index}>
                  {/* 제목 */}
                  <TbodyCol>
                    <LinkText to={`/manage/history/emergencyList/received/${row.workDate}/${row.sqenNumb}`}>{row.emerSubject}</LinkText>
                  </TbodyCol>
                  {/* 작성 날짜 */}
                  <TbodyCol>{row.ftDt}</TbodyCol>
                </TbodyRow>
              ))}
            </Tbody>
          </Table>
        ) : (
          <EmptyBox message="목록이 존재하지 않습니다." />
        )}
      </Scrollbars>
    </div>
  );
};

export default HomeEstimateTable;

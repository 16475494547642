import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';
import Lists from '../../../Parts/Common/TabLists/Lists';
import { Container, Contents, Item, NickName, SentDate, TextWrap } from '../../../Parts/Common/TabLists/ViewListsElements';

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import JobPostingModal from 'components/Parts/Common/Modal/JobPostingModal';
import FilterBtn from '../../../Parts/Common/FilterBtn/FilterBtn';
import SmallInfoModal from '../../../Parts/Common/MenuInfo/SmallInfoModal';
import PreviewImage from '../../../Parts/Common/PreviewImage';
import ProfileImage from '../../../Parts/Common/ProfileImage';
import SearchInput from '../../../Parts/Common/SearchInput/SearchInput';
import DetailContent from '../../../Parts/Common/TabLists/DetailContent';
import GrabImageList from '../../../Parts/Common/Uploader/GrabImageList';
import { SaveBtn, TopSection } from '../../Info/InfoElements';
import ApplyLists from './ApplyLists';

const Base = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  > * {
    flex: 0 0 auto;
  }
  .job-section {
    height: 100%;
    width: 100%;
    margin-top: 30px;
    flex: 1 1 100%;

    .job-item {
      display: grid;
      padding: 16px 28px 16px 16px;
      gap: 19px;
      grid-template-columns: 80px calc(100% - 109px);
      position: relative;
      .preview-box {
        width: 64px;
        height: 64px;
      }
      .job-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        div {
          line-height: 1;
        }
        .nick {
          margin-bottom: 9px;
          flex-shrink: 0;
        }
        .content {
          flex-shrink: 0;
        }
        .bottom {
          margin-top: auto;
          flex-shrink: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          & > span {
            justify-content: flex-start;
          }
        }
      }
      & > svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 24px;
        transition: 0.5s;
        color: #fff;
      }
      &:hover > svg,
      &.selected > svg {
        color: #5affe2;
      }
    }
  }
  .view-container {
    .view-header {
      display: grid;
      grid-template-columns: auto 100px;
      grid-template-rows: auto auto;
      padding: 30px;
      gap: 10px;
      border-bottom: solid 1px #10141c;

      .subject {
        grid-row: 1;
        font-size: 22px;
        color: #10141c;
        line-height: 1;
        margin-bottom: 8px;
        font-weight: bold;
      }
      .job-status {
        grid-row: 1 / span 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          padding: 6px 19px;
          border-radius: 15px;

          font-size: 20px;
          color: #fff;
          line-height: 1;
        }
      }
      .job-status.status1 span {
        background-color: #212838;
      }
      .job-status.status1 span {
        background-color: #7e6eff;
      }

      .job-status.status2 span {
        background-color: #8a8a8a;
      }
      .job-status.status0 span {
        background-color: #7e6eff;
      }

      .part-info {
        grid-row: 2;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .preview-box {
          width: 60px;
          height: 60px;
        }
        .info {
          display: flex;
          flex-direction: column;

          margin-left: 15px;
          .nick {
            font-size: 20px;
            color: #10141c;
            line-height: 1;
            margin-bottom: 9px;
          }
          .job-date {
            color: #8a8a8a;
            font-size: 18px;
            line-height: 1;
          }
        }
      }
    }
    .view-main {
      padding: 30px 30px 0 30px;
      .job-content {
        color: #10141c;
        font-size: 20px;
        white-space: pre-wrap;
      }
      .job-images {
        margin-top: 30px;
      }
      .modify-delete {
        display: flex;
        justify-content: flex-end;
        line-height: 1;
        gap: 5px;
        color: #8a8a8a;
        font-size: 20px;
        padding: 10px 0 20px;
        .rb-button {
          padding: 0;
          margin: 0;
          line-height: 1;
          color: #8a8a8a;
          font-size: 18px;
          cursor: pointer;
          background: transparent;
        }
      }
    }
    .post-info-box {
      border-radius: 10px;
      border: solid 1px #ddd;
      background-color: #fff;
      margin-top: 30px;
      margin-bottom: 30px;
      overflow: hidden;
      dl {
        padding: 0 30px;
        display: grid;
        grid-template-columns: auto auto;
        dt,
        dd {
          display: flex;
          align-items: center;
          font-size: 20px;
        }
        dd {
          justify-content: flex-end;
        }
      }
      .info-pay {
        background-color: #37465b;
        dt,
        dd {
          height: 50px;
          color: #fff;
          font-weight: bold;
        }
      }
      .info-etc {
        padding: 10px 30px;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
        gap: 10px;
        dt,
        dd {
          height: auto;
        }
      }
    }
  }
`;
export const ResultTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  .totalLength {
    width: 30%;
    height: 30px;
    font-size: 18px;
    margin-bottom: 15px;
  }
`;

const JobPosting = () => {
  const [filterList, setFilterList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [searchWord, setSearchWord] = useState('');
  const [jobView, setJobView] = useState({});
  const [jobImages, setJobImages] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [jobComplete, setJobComplete] = useState(false);
  const [gubn, setGubn] = useState(0);

  const navigate = useNavigate();
  const { workDate, sqenNumb } = useParams();

  const modalStyle = {
    width: '501px',
    height: 'auto'
  };
  const infoModalData = '달인과 함께 할 인턴을 찾을 수 있는 맞춤 채용 서비스입니다.';

  // 구인공고 modal
  const [jobPostingModal, setJobPostingModal] = useState({
    open: false,
    postData: {},
    workDate: '',
    sqenNumb: '',
    title: ''
  });
  const openJobPosting = (title, jobView = {}, images = []) => {
    setJobPostingModal({
      open: true,
      postData: jobView,
      workDate: jobView.work_date,
      sqenNumb: jobView.sqen_numb,
      images,
      title
    });
  };
  const closeJobPosting = () => {
    setJobPostingModal({
      open: false,
      postData: {},
      workDate: '',
      sqenNumb: '',
      title: ''
    });
  };

  const getFilterList = () => {
    utils.http.setMode('api');
    utils.http.get(`/v1/SP_b_Common_Code_SI/13`).then(({ data }) => {
      setFilterList(() => data?.data);
    });
  };

  const updateJobList = function (gubn, next) {
    utils.http.setMode('api');
    utils.http.get(`/v1/SP_p_partChatInternJobList_S1/${gubn}`).then(({ data }) => {
      setJobList(() => data?.data);

      if (utils.isEmpty(jobView)) {
        let item = data.data.filter((x) => x.work_date === workDate && x.sqen_numb === sqenNumb);
        if (item.length > 0) {
          updateJobDetail(item[0]);
          updateImagesList(item[0]);
        }
      }

      updateSearchList(searchWord);
      utils.isFunction(next) && next(data?.data);
    });
  };
  const updateSearchList = function (searchWord) {
    setJobList((jobList) => {
      setSearchList(() => {
        if (searchWord === '') return jobList;
        else {
          return jobList.filter((x) => x.job_subject.includes(searchWord) || x.job_content.includes(searchWord));
        }
      });
      return jobList;
    });
  };
  const isSearched = function () {
    return searchWord !== '';
  };
  const updateJobDetail = function (job) {
    setJobView(job);

    utils.http.setMode('api');
    utils.http.get(`/v1/SP_I_internJobPostingItem_s1/${job.work_date}/${job.sqen_numb}`).then(({ data }) => {
      console.log('업데이트', data.data);
      if (data?.data.length > 0) {
        setJobView(() => Object.assign({}, job, data?.data[0]));
      }
    });
    updateApplicantList(job);
    updateComplete(job);
  };
  const updateApplicantList = function (job) {
    utils.http.get(`/v1/SP_I_internJobPostingItem_s2/${job.work_date}/${job.sqen_numb}`).then(({ data }) => {
      setApplicantList(() => data?.data);
    });
  };
  const updateComplete = function (job) {
    utils.http.get(`/v1/SP_I_internJobPostingItem_s6/${job.work_date}/${job.sqen_numb}`).then(({ data }) => {
      console.log(data?.data[0].jobGubn === 2);
      setJobComplete(() => data?.data[0].jobGubn === 2);
    });
  };
  const updateImagesList = function (job) {
    utils.http.setMode('api');
    utils.http.get(`/v1/SP_I_internJobPostingItem_s3/${job.work_date}/${job.sqen_numb}`).then(({ data }) => {
      if (data?.data.length > 0) {
        setJobImages(() => data.data.map((x) => x.fileName));
      } else {
        setJobImages([]);
      }
    });
  };
  const insertContents = function () {
    openJobPosting('구인공고');
  };
  const updateContents = function () {
    openJobPosting('구인수정', jobView, jobImages);
  };
  const deleteContents = async function (workDate, sqenNumb) {
    if (await utils.dialog.confirm(' 정말 삭제하시겠습니까?', '', 'question', '', '삭제')) {
      utils.http
        .post(`/v1/SP_I_internJobPosting_D1`, null, {
          workDate,
          sqenNumb
        })
        .then(async ({ data }) => {
          if (await utils.dialog.alert('삭제 완료', '인턴 공고를 삭제하였습니다.', 'success')) {
            setJobView({});
            updateJobList(gubn);
          }
        });
    }
  };

  const refreshDetail = () => {
    let item = jobList.filter((x) => x.work_date === workDate && x.sqen_numb === sqenNumb);
    if (item.length > 0) {
      updateJobDetail(item[0]);
    }
  };

  useEffect(() => {
    if (utils.isEmpty(jobView)) {
      navigate(`/manage/intern/jobPosting`);
    } else {
      navigate(`/manage/intern/jobPosting/${jobView.work_date}/${jobView.sqen_numb}`);
    }
  }, [jobView]);

  useEffect(() => {
    getFilterList();
  }, []);

  useEffect(() => {
    updateJobList(gubn);
  }, [gubn]);

  // useEffect(() => {
  //   let item = jobList.filter((x) => x.work_date === workDate && x.sqen_numb === sqenNumb);
  //   if (item.length > 0) {

  //     updateJobDetail(item[0]);
  //     updateImagesList(item[0]);
  //   }

  //   if (!workDate || !sqenNumb) setJobView({});
  // }, [workDate, sqenNumb]);

  return (
    <Base>
      <JobPostingModal
        open={jobPostingModal.open}
        close={closeJobPosting}
        title={jobPostingModal.title}
        workDate={jobPostingModal.workDate}
        sqenNumb={jobPostingModal.sqenNumb}
        postData={jobPostingModal.postData}
        images={jobPostingModal.images}
        afterSubmit={(workDate, sqenNumb) => {
          if (jobPostingModal.postData) {
            updateJobList(gubn, (list) => {
              let item = list.filter((x) => x.work_date === workDate && x.sqen_numb === sqenNumb);
              if (item.length > 0) {
                updateJobDetail(item[0]);
                updateImagesList(item[0]);
              }
            });
          } else {
            setGubn(0);
            if (gubn === 0) updateJobList(0);
            navigate(`/manage/intern/jobPosting/${workDate}/${sqenNumb}`);
          }
        }}
      />
      <TopSection>
        <SmallInfoModal menu="구인내역" infoModalData={infoModalData} style={modalStyle} />
        <SaveBtn type="button" onClick={insertContents}>
          구인공고
        </SaveBtn>
      </TopSection>
      <Container className={'job-section'}>
        <Lists
          title={'공고 목록'}
          empty={jobList.length === 0 && gubn === 0}
          emptyMessage={
            <>
              등록 된 구인 공고가 없습니다.
              <br />
              구인을 등록해 인턴을 모집해 보세요.
            </>
          }
          noSearch={(isSearched() || gubn !== 0) && searchList.length === 0}
          noSearchMessage={isSearched() ? <>검색된 구인 공고가 없습니다.</> : gubn === 1 ? <>매칭중인 구인 공고가 없습니다.</> : <>마감된 구인 공고가 없습니다.</>}
          slotFilter={
            <>
              <SearchInput
                inputVal={searchWord}
                setInputVal={setSearchWord}
                onSearch={() => {
                  updateSearchList(searchWord);
                }}
              />
              <ResultTop>
                <div className="totalLength">전체 {utils.format.number(searchList.length)}건</div>
                <FilterBtn
                  select={gubn}
                  setSelect={setGubn}
                  list={filterList}
                  style={{
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                />
              </ResultTop>
            </>
          }
          slotList={searchList.map((job) => (
            <Item
              key={'joblist' + job.work_date + job.sqen_numb}
              className={'job-item ' + (job.work_date + job.sqen_numb === jobView.work_date + jobView.sqen_numb && 'selected')}
              onClick={() => {
                updateJobDetail(job);
                updateImagesList(job);
              }}
            >
              <section className={'preview-box'}>
                <PreviewImage image={job.fileName} shape={'rect'} />
              </section>
              <TextWrap className={'job-info'}>
                <NickName className={'nick'}>{job.job_subject}</NickName>
                <Contents className={'content'}>{job.job_content}</Contents>
                <div className={'bottom'}>
                  <SentDate>{job.ftDt}</SentDate>
                </div>
              </TextWrap>
              <ArrowForwardIosRoundedIcon />
            </Item>
          ))}
        />
        {(jobList.length > 0 || gubn !== 0) && (
          <DetailContent
            title={'공고 상세 내용'}
            empty={utils.isNull(jobView.work_date) || utils.isNull(jobView.sqen_numb)}
            emptyMessage={'구인 공고를 선택해 주세요.'}
            adFitCode="DAN-HQF4edgV6gxmWbYP"
            scrollBar={true}
            slotContent={
              <section className={'view-container'}>
                <header className={'view-header'}>
                  <section className={'subject'}>{jobView.job_subject}</section>

                  <section className={'job-status status' + jobView.jobGubn}>
                    <span>{jobView.jobGubnName}</span>
                  </section>

                  <section className={'part-info'}>
                    <section className={'preview-box'}>
                      <ProfileImage partProfile={1} kind={'partner'} id={utils.user('id')} alwaysRefresh={true}></ProfileImage>
                    </section>
                    <section className={'info'}>
                      <div className={'nick'}>{jobView.partName}</div>
                      <div className={'job-date'}>{jobView.dateValue}</div>
                    </section>
                  </section>
                </header>
                <main className={'view-main'}>
                  <p className={'job-content'}>{jobView.job_content}</p>
                  {jobImages.length > 0 && <GrabImageList className={'job-images'} imageList={jobImages}></GrabImageList>}
                  <section className={'post-info-box'}>
                    <dl className={'info-pay'}>
                      <dt>급여</dt>
                      <dd>{isNaN(jobView.jobMony) ? jobView.jobMony : utils.format.number(jobView.jobMony) + '원'}</dd>
                    </dl>
                    <dl className={'info-etc'}>
                      <dt>필요기술</dt>
                      <dd>{jobView.jobSkill ?? ''}</dd>
                      <dt>주소</dt>
                      <dd>{(jobView.jobAddr2 ?? '') + ' ' + (jobView.jobAddr3 ?? '')}</dd>
                      <dt>근무일자</dt>
                      <dd>{jobView.jobStartDate && (jobView.jobStartDate === jobView.jobEndDate ? jobView.jobStartDate ?? '' : (jobView.jobStartDate ?? '') + ' ~ ' + (jobView.jobEndDate ?? ''))}</dd>
                    </dl>
                  </section>
                  <div className={'modify-delete'}>
                    {jobView.jobGubn === 1 && applicantList.length === 0 && (
                      <>
                        <button type={'button'} className={'rb-button'} onClick={updateContents}>
                          수정
                        </button>
                        <span>|</span>
                      </>
                    )}
                    <button
                      type={'button'}
                      className={'rb-button'}
                      onClick={() => {
                        deleteContents(jobView.work_date, jobView.sqen_numb);
                      }}
                    >
                      삭제
                    </button>
                  </div>
                </main>
                <ApplyLists
                  applicantList={applicantList}
                  complete={jobComplete}
                  afterSelect={() => {
                    updateApplicantList(jobView);
                  }}
                  workDate={jobView.work_date}
                  sqenNumb={jobView.sqen_numb}
                  refreshDetail={refreshDetail}
                />
              </section>
            }
          />
        )}
      </Container>
    </Base>
  );
};

export default JobPosting;

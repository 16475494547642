import { Box, Card, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import utils from 'modules/utils';
import ModalComponent from '../Parts/Common/Modal/ModalComponent';

const ImageUploadCard = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  cursor: 'pointer',
  color: '#a1a1a1',
  outline: 'none',
  fontSize: '20px',
  fontWeight: 'bold',
  transition: 'border .1s ease-in-out',
  border: '2px dashed #c4c4c4',
  '&:hover': {
    border: '2px dashed var(--mint-color)',
  },
});

const ImageCard = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  border: '1px solid #c4c4c4',
  backgroundColor: '#ccc',
  color: '#a1a1a1',
  outline: 'none',
  fontWeight: 'bold',
  transition: 'border .1s ease-in-out',
  '&:hover': {
    border: '2px solid var(--mint-color)',
    backgroundColor: '#c4c4c4',
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const MultiImageUpload = ({ name, maxImage, imageArr, setImageArr, setDeleteImageArr, disabledButton, accept, uploadText, isWhite, width, height }) => {
  const [open, setOpen] = useState(false);

  const [modalImage, setModalImage] = useState('');
  const [showPreviewImages, setShowPreviewImages] = useState([]);

  const handleImageUpload = (event) => {
    if (accept) {
      const removeSpaceAccept = accept.replace(/\s+/g, '');
      const acceptArr = removeSpaceAccept.split(',');

      const acceptFlag = acceptArr.some((item) => {
        return event.target.files && event.target.files[0].type.includes(item);
      });
      console.log('size', event.target.files[0].size);
      if (!acceptFlag || event.target.files[0].size > 20000000) {
        utils.dialog.alert('', `이미지: 20MB이하의 JPG, JPEG, PNG 파일만 등록 가능합니다.`, 'warning');
        return;
      }
    }

    //최대 maxImage개수 까지만 업로드 가능하도록
    const selectedFiles = Array.from(event.target.files || []);
    if (imageArr.length + selectedFiles.length > ~~maxImage) {
      utils.dialog.alert('', `이미지는 최대 ${maxImage}개까지 업로드 가능합니다.`, 'warning');
      return;
    }
    const newImageArr = selectedFiles.map((file) => {
      const newFile = new File([file], !!name ? `${name}_${file.name}` : file.name, { type: file.type });
      return {
        serverFileName: '',
        previewUrl: URL.createObjectURL(file),
        localFlag: true,
        file: newFile,
      };
    });
    setImageArr([...imageArr, ...newImageArr]);
  };

  const handleDeleteImage = (e, image) => {
    e.stopPropagation(); //버블링

    //  해당 이미지 객체 제거 후 localFlag가 false 면 삭제목록에 추가
    const newImageArr = imageArr.filter((item) => item.previewUrl !== image.previewUrl);

    if (!image.localFlag) {
      // 서버
      setDeleteImageArr((prevArr) => [...prevArr, image]);
    }
    setImageArr(newImageArr);
  };

  const handleShowImageModal = (e, src) => {
    setModalImage(src);
    setOpen(true);
  };

  const maxCheck = (e) => {
    if (maxImage && imageArr.length >= ~~maxImage) {
      e.preventDefault();
      utils.dialog.alert('', `최대 ${maxImage}개 까지 업로드가 가능합니다.`, 'warning');
    }
  };

  useEffect(() => {
    //input file 초기화
    const input = document.querySelector(`#image-upload${name}`);
    input.value = '';
    setShowPreviewImages(imageArr);
  }, [imageArr]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '50px',
        }}
      >
        <Box>
          <input accept={accept ? accept : 'image/*'} id={`image-upload${name}`} multiple type="file" style={{ display: 'none' }} onChange={handleImageUpload} max={maxImage ?? 1} disabled={disabledButton} onClick={maxCheck} />
          <label htmlFor={`image-upload${name}`}>
            <ImageUploadCard
              sx={{
                backgroundColor: isWhite ? '#fff' : '#212838',
                width: width ? width : '120px',
                height: height ? height : '120px',
              }}
            >
              <PhotoCameraOutlinedIcon sx={{ fontSize: '40px' }} />
              {imageArr.length + ' / ' + (maxImage ?? 1)}
            </ImageUploadCard>
          </label>
        </Box>
        <Box
          sx={{
            // grid를 사용하여 5개씩 한줄에 보여줍니다.
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gap: '30px',
            width: '400px',
          }}
        >
          {showPreviewImages.length > 0 &&
            showPreviewImages.map((image, index) => (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                }}
              >
                {!disabledButton && (
                  <RemoveCircleRoundedIcon
                    color="error"
                    sx={{
                      position: 'absolute',
                      top: '0px',
                      right: '0px',
                      cursor: 'pointer',
                      fontSize: '28px',
                      zIndex: 5,
                      '&:hover': {
                        color: 'var(--primary-main)',
                      },
                    }}
                    onClick={(e) => handleDeleteImage(e, image)}
                  />
                )}
                <ImageCard
                  key={index}
                  sx={{
                    width: width ? width : '120px',
                    height: height ? height : '120px',
                  }}
                  onClick={(e) => handleShowImageModal(e, image.previewUrl)}
                >
                  <img src={image.previewUrl} alt="" />
                </ImageCard>
              </Box>
            ))}
        </Box>
      </Box>
      {/* 이미지 업로드 텍스트가 있을 경우 */}
      {uploadText && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '14px',
            color: '#a1a1a1',
          }}
        >
          {uploadText.map((text, index) => (
            <Box key={index}>• {text}</Box>
          ))}
        </Box>
      )}
      <ModalComponent width={'600px'} open={open} setOpen={setOpen}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ccc',
            borderRadius: '10px',
            padding: '20px',
            '& img': {
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            },
          }}
        >
          <img src={modalImage} alt="" />
        </Box>
      </ModalComponent>
    </Box>
  );
};

export default MultiImageUpload;

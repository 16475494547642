import utils from "modules/utils";
let format = {
    number(number){
        return (number+"").replace(/(\d)(?=(?:\d{3})+(?!\d))/g,'$1,');
    },
    date_std(date){
        date = utils.date.moment(date);
        if(utils.isString(date)) date = date.replaceAll("-","/");
        const d = new Date(date);
        return `${d.getFullYear()}-${utils.fillZero(d.getMonth()+1,2)}-${utils.fillZero(d.getDate(),2)}`;
    },
    date_kor(date){
        date = utils.date.moment(date);
        const d = new Date(date);
        return `${d.getFullYear()}년 ${d.getMonth()+1}월 ${d.getDate()}일`;
    },
    time_std(time){
        time = utils.date.moment(time);
        const d = new Date(time);
        return `${utils.fillZero(d.getHours(),2)}:${utils.fillZero(d.getMinutes(),2)}`;
    },
    time_kor(time, ampm){
        time = utils.date.moment(time);
        const d = new Date(time);
        if(ampm){
            return `${d.getHours() < 12 ? "오전 " : "오후 "} ${d.getHours() % 12 === 0 ? 12 : (d.getHours() % 12)}시 ${d.getMinutes()}분`
        } else {
            return `${d.getHours()}시 ${d.getMinutes()}분`;
        }
    },
    time_hm(time){
        return `${time.split(':')[0]}시 ${time.split(':')[1]}분`;
    },
    time_hms(time){
        return `${time.split(':')[0]}시 ${time.split(':')[1]}분 ${time.split(':')[2]}초`;
    },
    time_hour(time){
        if(time < 60) {
            return `${time%60}분`;
        } else if(time%60 === 0){
            return `${Math.floor(time/60)}시간`;
        } else {
            return `${Math.floor(time/60)}시간 ${time%60}분`;
        }
    },
    extractNumber(number, option=[]){
        let sValue = /[^0-9]/g;
        if(option){
            if(option.includes("minus") && option.includes("point")) sValue = /[^0-9-.]/g;
            else if(option.includes("minus") && !option.includes("point")) sValue = /[^0-9-]/g;
            else if(!option.includes("minus") && option.includes("point")) sValue = /[^0-9.]/g;
        }
        let tmpValue = String(number).replaceAll(sValue, '').trim();
        if(option.includes("minus") && tmpValue.length>0) tmpValue = tmpValue[0] + tmpValue.substring(1).replaceAll("-", "");
        if(option.includes("format") && tmpValue.length>0) tmpValue = this.number(tmpValue);
        return tmpValue;
    },
    inputCheck(target, option=[]){
        target.value = this.extractNumber(target.value, option);
    },
    date(date){
        date = this.extractNumber(date).substring(0, 8);
        let printDate = '';

        switch(date.length){
            case 4:
                return `${printDate}${date.substring(0, 4)}`;
            case 5: case 6:
                return `${printDate}${date.substring(0, 4)}-${date.substring(4)}`;
            case 7: case 8:
                return `${printDate}${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6)}`;
            default:
                return `${printDate}${date}`;
        }
    },
    contact(number){
        number = this.extractNumber(number);
        let printNum = '', areaCode=number.substring(0, 3);
        if(number.length > 3) {
            switch (areaCode){
                case "010": case "011": case "012": case "016": case "017": case "018": case "019":
                case "050": case "060": case "070": case "080":
                    printNum = areaCode+"-";
                    number = number.substring(3, number.length);
                    break;
                default:
                    printNum = number.substring(0, 3) + ") ";
                    number = number.substring(3, number.length);
                    break;
            }
        }

        switch(number.length){
            case 4:
                return `${printNum}${number.substring(0, 4)}`;
            case 6: case 5:
                return `${printNum}${number.substring(0, 4)}-${number.substring(4, number.length)}`;
            case 7: case 8:
                if(areaCode !== '010' && number.length === 7) return `${printNum}${number.substring(0, 3)}-${number.substring(3, 3+4)}`;
                return `${printNum}${number.substring(0, 4)}-${number.substring(4, 4+4)}`;
            default:
                return `${printNum}${number}`;
        }
    },
    isContact(contact){
        let regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        return regPhone.test(contact);
    },
    isMobile(mobile){
        return "010|011|017|018|019".includes(mobile.substring(0, 3)) && this.isContact(mobile);
    },
    isEmail(email){
        let regEmail = /^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
        return regEmail.test(email);
    },
    maskContact(contact){
        let split = this.contact(contact).split('-');
        if(split.length === 3){
            return `${split[0]}-${'*'.repeat(split[1].length)}-${split[2]}`;
        } else {
            let split2 = split[0].split(' ');
            return `${split2[0]} ${'*'.repeat(split2[1].length)}-${split[1]}`;
        }
    },
    maskEmail(email){
        let split = email.split('@');
        return `${split[0].substring(0, 2)}${'*'.repeat(split[0].length-2)}@${split[1]}`;
    },
    maskName(name){
        let originStr = name;
        let strLength;

        if(utils.isEmpty(originStr)){
            return originStr;
        }
        strLength = originStr.length;

        if(strLength < 3){
            return originStr[0]+'*';
        }else {
            return originStr[0]+"*".repeat(strLength-2)+originStr.slice(-1);
        }
    },
    others(list, unit){
        if(utils.isNull(unit)) unit = "명";
        return list[0]+(list.length>1?"외 "+(list.length-1)+unit:"")
    }

}
Object.freeze(format);
export default format;
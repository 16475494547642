import CheckIcon from '@mui/icons-material/Check';
import { Box, Button } from '@mui/material';
import { partInfoStore } from 'modules/store/store';
import utils from 'modules/utils';
import { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate } from 'react-router-dom';
import { Body, Header, Lists } from './SoodalPlusElements';

const CityLists = ({ town, district, setDistrict, selectedRegion, setOpen }) => {
  const { partInfo, getPartInfo } = partInfoStore();
  const navigate = useNavigate();

  // 지역개수에 맞춰 알림창 띄우기 함수
  const handleShowAlert = (item) => {
    const filterDistrict = district.filter((region) => region?.region !== item[0]?.region);
    const selectedCity = Array.isArray(item) ? [...filterDistrict, ...item] : [...district, item];
    const selectedCityLength = selectedCity?.length;
    const partnerAreaCount = partInfo?.areaCount;
    const alertMessage = `현재 선택 가능한 지역 설정 개수는 ${partnerAreaCount}개 입니다.`;

    console.log('selectedCity', selectedCity);

    if (selectedCityLength > partnerAreaCount) {
      // 구독권 구매 지역초과
      utils.dialog.confirm('', alertMessage, 'warning', '', '지역 다시 선택', '구독 내역 확인').then((res) => {
        if (res === false) {
          navigate('/manage/ticket/purchaseHistory');
        }
      });
      return;
    }

    setDistrict(selectedCity);
  };

  // 시/군/구 선택 함수
  const handlePushCity = (item, index) => {
    const removeWhole = town.filter((city) => city.nm !== '전체');
    const filterDistrict = district.filter((region) => region?.region === item?.region);
    if (item.nm === '전체') {
      // 해당 시/도의 시군구 중 하나라도 chipData에 포함되어 있으면 해당 시/도의 모든 시군구를 chipData에서 제거
      if (filterDistrict.length >= removeWhole.length) {
        setDistrict(district.filter((region) => region?.region !== item?.region));
        return;
      }
      handleShowAlert(removeWhole);
      return;
    }

    // 아이템이 이미 chipData에 포함되어 있으면 제거
    if (district.filter((region) => region.cd === item.cd).length > 0) {
      setDistrict(district.filter((region) => region.cd !== item.cd));
      return;
    }

    // 아이템을 chipData에 추가
    handleShowAlert(item);
  };

  useEffect(() => {
    getPartInfo();
  }, []);

  return (
    <Lists>
      <Header style={{ borderLeft: 'none', borderTopRightRadius: '5px' }}>시/군/구</Header>
      <Body>
        <Scrollbars className="scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              flexWrap: 'wrap',
            }}
          >
            {town.length > 0 &&
              town.map((item, index) => {
                return (
                  <Button
                    key={index}
                    onClick={() => handlePushCity(item, index)}
                    size="small"
                    variant={'outlined'}
                    color={item.nm === '전체' ? (selectedRegion?.count === district.filter((region) => region?.region === item?.region).length ? 'info' : 'secondary') : district.filter((region) => region.cd === item.cd).length > 0 ? 'info' : 'secondary'}
                    sx={{
                      height: '38px',
                      borderRadius: '0',
                      fontSize: '21px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: item.nm === '전체' ? (selectedRegion?.count === district.filter((region) => region?.region === item?.region).length ? 'var(--mint-color)' : '#fff') : district.filter((region) => region.cd === item.cd).length > 0 ? 'var(--mint-color)' : '#fff',
                      // icon font size
                      '& .MuiSvgIcon-root': {
                        fontSize: '2rem',
                      },
                    }}
                    endIcon={item.nm === '전체' ? selectedRegion?.count === district.filter((region) => region?.region === item?.region).length ? <CheckIcon /> : null : district.filter((region) => region.cd === item.cd).length > 0 ? <CheckIcon /> : null}
                  >
                    {item.nm}
                  </Button>
                );
              })}
          </Box>
        </Scrollbars>
      </Body>
    </Lists>
  );
};

export default CityLists;

import React, { useState, useEffect } from 'react';
import { Content, ContentHeader, HeaderLeft, ProfileImg, ContentTitle, ContentSubInfo, Category, WritedDate, ContentMain, Post, ImageWrap, MainText } from './EmergencyListElements';
import utils from 'modules/utils';
import ProfileImage from '../../../Parts/Common/ProfileImage';
import ReportBlockButtons from '../../../Parts/Common/ReportBlockButtons';
import GrabImageList from '../../../Parts/Common/Uploader/GrabImageList';
import styled from 'styled-components';
const EMContent = styled(Content)`
  .view-container {
    .view-header {
      display: grid;
      grid-template-columns: auto 140px;
      padding: 30px;
      gap: 10px;
      border-bottom: solid 1px #10141c;
      .status {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          padding: 6px 19px;
          border-radius: 15px;
          font-size: 20px;
          color: #fff;
          line-height: 1;
        }
      }
      .status.status1 span {
        background-color: #212838;
      }
      .status.status3 span {
        background-color: #7e6eff;
      }
      .status.status4 span {
        background-color: #ff4b4b;
      }
      .part-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .preview-box {
          width: 60px;
          height: 60px;
        }
        .info {
          display: flex;
          flex-direction: column;

          margin-left: 15px;
          .nick {
            font-size: 20px;
            color: #10141c;
            line-height: 1;
            margin-bottom: 9px;
          }
          .job-date {
            color: #8a8a8a;
            font-size: 18px;
            line-height: 1;
          }
        }
      }
    }
    .view-main {
      padding: 30px;
      > * {
        line-height: 1;
      }
      .category {
        color: #8a8a8a;
        font-size: 20px;
      }
      .subject {
        color: #10141c;
        font-size: 22px;
        margin-top: 13px;
        font-weight: bold;
      }
      .content {
        color: #8a8a8a;
        font-size: 22px;
        margin-top: 22px;
      }
      .images {
        margin-top: 21px;
      }
      .address {
        color: #10141c;
        font-size: 20px;
        margin-top: 23px;
      }
    }
  }
`;

const SentEmergencyContent = ({ emergencyView, refreshList, myPost, toBottom, lat, lon }) => {
  const imgURL = process.env.REACT_APP_IMAGE_VIEW_URL;
  const [detailData, setDetailData] = useState({});
  const [filterList, setFilterList] = useState([]);

  const getFilterList = () => {
    utils.http.setMode('api');
    utils.http.get(`/v1/SP_b_Common_Code_SI/30`).then(({ data }) => {
      setFilterList(() => data?.data);
    });
  };

  const getDetailEmergency = () => {
    const url = `/v2/SP_p_partEmergencyViewV2_S2/${emergencyView.workDate}/${emergencyView.sqenNumb}/${lon}/${lat}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        console.log('🚀 ~ file: SentEmergencyContent.jsx:120 ~ .then ~ res:', res);
        if (res.data && res.data.data) {
          setDetailData(Object.assign({}, emergencyView, res.data.data[0]));
        } else {
          setDetailData({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 긴급출동 이미지 조회
  const [emergencyFile, setEmergencyFile] = useState([]);
  const getEmergencyImages = () => {
    utils.http.setMode('api');
    utils.http
      .get(`/v1/SP_r_Emergency_s3/${emergencyView.workDate}/${emergencyView.sqenNumb}`)
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          setEmergencyFile(res.data.data);
        } else {
          setEmergencyFile([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFilterList();
  }, []);

  useEffect(() => {
    if (filterList.length > 0) {
      getDetailEmergency();
      getEmergencyImages();
    }
  }, [emergencyView, filterList]);

  return (
    <>
      {myPost ? (
        <EMContent>
          <section className={'view-container'}>
            <header className={'view-header'}>
              <section className={'part-info'}>
                <section className={'preview-box'}>
                  <ProfileImage kind={'user'} id={detailData.userId} partProfile={1} />
                </section>
                <section className={'info'}>
                  <div className={'nick'}>{detailData.userName}</div>
                  <div className={'job-date'}>{detailData.ftDt}</div>
                </section>
              </section>
              <section className={'status status' + detailData.emerGubn}>
                <span>{filterList.filter((x) => ~~x?.cd === detailData.emerGubn)[0]?.nm}</span>
              </section>
            </header>
            <main className={'view-main'}>
              <p className={'category'}>{detailData.cateName}</p>
              <p className={'subject'}>{detailData.emerSubject}</p>
              <p className={'content'}>{detailData.emerContent}</p>
              {emergencyFile.length > 0 && <GrabImageList className={'images'} imageList={emergencyFile}></GrabImageList>}
              <p className={'address'}>주소:{detailData.emerAddr2}</p>
              <ReportBlockButtons
                gubn={'PartEmergencyView'}
                userId={detailData.userId}
                sqenNumb={detailData.sqenNumb}
                workDate={detailData.workDate}
                headNumb={''}
                userNick={detailData.userName}
                afterAction={() => {
                  refreshList();
                }}
              />
            </main>
          </section>
        </EMContent>
      ) : (
        <Content>
          <ContentHeader
            style={{
              height: '140px',
            }}
          >
            <HeaderLeft style={{ width: '100%', gap: '8px' }}>
              <ContentTitle>{detailData.emerSubject}</ContentTitle>
              <ContentSubInfo>
                <ProfileImg>
                  <ProfileImage partProfile={1} kind={'user'} id={detailData.userId} alwaysRefresh={true}></ProfileImage>
                </ProfileImg>
                <Category>{detailData.userName}</Category>
                <WritedDate>{detailData.ftDt}</WritedDate>
              </ContentSubInfo>
              <div>카테고리 : {detailData.cateName}</div>
            </HeaderLeft>
          </ContentHeader>
          <ContentMain>
            <Post>
              {emergencyFile.length > 0 ? (
                emergencyFile.map((img, index) => {
                  return (
                    <ImageWrap key={`file${index}-${img?.workDate}${img?.sqenNumb}`}>
                      <img src={`${imgURL}${img?.fileName}`} alt="" style={{ maxWidth: '100%' }} />
                    </ImageWrap>
                  );
                })
              ) : (
                <></>
              )}
              <MainText>{detailData.emerContent}</MainText>
              <div
                style={{
                  padding: '4px 16px',
                  borderRadius: '4px',
                  backgroundColor: lat !== '0.0' && lon !== '0.0' ? 'var(--primary-color)' : 'var(--secondary-color)',
                  color: '#fff',
                  fontSize: '16px',
                  width: 'fit-content',
                  marginTop: '10px',
                }}
              >
                고객과의 거리 {lat !== '0.0' && lon !== '0.0' ? detailData?.distance : '-'}km
              </div>
              <MainText>주소: {detailData.emerAddr2}</MainText>
            </Post>
            <ReportBlockButtons
              gubn={'EmergencyReceive'}
              userId={detailData.userId}
              sqenNumb={detailData.sqenNumb}
              workDate={detailData.workDate}
              headNumb={''}
              userNick={detailData.userName}
              afterAction={() => {
                refreshList();
              }}
            />
          </ContentMain>
        </Content>
      )}
    </>
  );
};

export default SentEmergencyContent;

import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from 'styled-components';
import CategoryModal from './../Modal/CategoryModal';

const ChipBox = styled.div`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  /* margin-top: 10px; */
  background-color: #fff;
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100%;
  max-height: 140px;
  overflow-y: auto;
  cursor: pointer;
  .empty-text {
    color: #8a8a8a;
    font-size: 20px;
  }
`;

const CategorySelect = ({ chipData, setChipData }) => {
  const [open, setOpen] = React.useState(false);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  return (
    <>
      <ChipBox onClick={() => setOpen(true)} className="scrollbar-container2">
        {chipData.length > 0 ? (
          chipData.map((data) => {
            return (
              <Chip
                key={data.key}
                label={data.label}
                onDelete={handleDelete(data)}
                sx={{
                  backgroundColor: '#7e6eff',
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: '500',
                  cursor: 'pointer',
                  '& .MuiChip-deleteIcon': {
                    color: '#fff',
                    '&:hover': {
                      color: '#333',
                    },
                  },
                }}
              />
            );
          })
        ) : (
          <span className="empty-text">서비스를 선택하려면 클릭해주세요.</span>
        )}
      </ChipBox>
      <CategoryModal open={open} setOpen={setOpen} chipData={chipData} setChipData={setChipData} title="서비스" />
    </>
  );
};

export default CategorySelect;

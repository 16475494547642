import styled, { keyframes } from 'styled-components';

export const modalShow = keyframes`
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
`;

export const modalBgShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const OpenModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgb(31 31 31 / 30%);
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: ${modalBgShow} 0.3s;
`;

export const CloseModal = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Section = styled.section`
  width: 794px;
  height: 88vh;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #10141c;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: ${modalShow} 0.3s;
  overflow: hidden;

  //모바일
  @media all and (max-width: 480px) {
    width: 100vw;
    height: 80vh;
    border-radius: 0;
  }
`;

export const ModalHeader = styled.header`
  position: relative;
  height: 95px;
  padding: 38px 49px 30px 51px;
  background-color: #10141c;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  //태블릿
  @media screen and (max-width: 1024px) {
    height: 80px;
    padding: 30px 40px 20px 40px;
    font-size: 20px;
  }

  //모바일
  @media all and (max-width: 480px) {
    padding: 20px;
    font-size: 20px;
    height: 60px;
  }
`;

export const CloseBtn = styled.button`
  width: 26px;
  height: 26px;
  background-color: transparent;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    width: 20px;
    height: 20px;
    Img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Main = styled.main`
  width: 100%;
  height: 90%;
  padding: 30px 51px;
  border-top: 1px solid #dee2e6;

  //모바일
  @media all and (max-width: 480px) {
    padding: 20px;
  }
`;

export const TextBoard = styled.div`
  padding: 30px 20px 30px 30px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  font-size: 20px;
  border-radius: 8px;
  color: #000;
`;

export const Footer = styled.footer`
  padding: 12px 16px;
  text-align: right;
`;

export const CloseTextBtn = styled.button`
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
  border: none;
  outline: none;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
`;

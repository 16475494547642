import styled from 'styled-components';

export const IconSection = styled.div`
  display: flex;
  width: 100%;
  background-color: #f3f3f3;
  gap: 90px;
  padding: 8px 20px;
  align-content: center;
  justify-content: space-around;
  border-radius: 5px;
`;
export const DetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;
export const ViewCount = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #8a8a8a;
  font-size: 14px;
`;
export const HeaderTitle = styled.div`
  font-size: 22px;
  color: #10141c;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const CustomTextWrap = styled.div`
  width: calc(100% - 220px);
  height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;
export const SectionBox = styled.div`
  position: relative;
  min-width: calc(100% - 20px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ItemRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: #8a8a8a;
`;
export const MintText = styled.div`
  color: var(--mint-color);
  font-weight: bold;
  font-size: 16px;
`;
export const ViewCountBox = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
`;
export const ItemImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 70px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const ReviewBase = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  > * {
    flex: 0 0 auto;
  }
  .review-section {
    height: 100%;
    width: 100%;
    flex: 1 1 100%;

    .review-item {
      display: grid;
      padding: 16px;
      gap: 19px;
      grid-template-columns: 80px calc(100% - 100px);
      position: relative;
      & > .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 24px;
        transition: 0.5s;
      }
      &:hover > .icon,
      &.selected > .icon {
        color: #5affe2;
      }
    }
  }
  .view-container {
    .view-header {
      display: grid;
      grid-template-columns: 80px auto 110px;
      padding: 18px 30px;
      gap: 11px;
      border-bottom: solid 1px #10141c;
      .profile-box {
        width: 80px;
        height: 80px;
      }
      .review-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .nick {
          font-size: 22px;
          color: #10141c;
          line-height: 1;
          margin-bottom: 8px;
        }
      }
      .review-date {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #10141c;
        font-size: 20px;
      }
    }
    .view-main {
      padding: 30px;
      .review-content {
        color: #10141c;
        font-size: 22px;
        white-space: pre-line;
        margin-bottom: 20px;
      }
      .review-images {
        margin-top: 30px;
      }
    }
  }
`;

export const DeniedSection = styled.div`
  background-color: #fceded;
  margin: 20px;
  padding: 12px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  color: #777777;
  font-size: 18px;
`;
export const DeniedHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DeniedTitle = styled.div`
  color: #eb0b0b;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1.2rem;
`;
export const DeniedContent = styled.div`
  color: #191919;
  margin: 10px 0;
  padding: 16px;
  border-radius: 10px;
  background-color: #fff;
  white-space: pre-line;
`;

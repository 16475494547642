import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Button, Card, Chip, IconButton, Stack, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { MintText } from 'components/Parts/Common/Modal/AreaAndTicketModal';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import utils from '../../modules/utils/index';
const ChipBox = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '40px',
  display: 'flex',
  borderRadius: '4px',
  padding: '4px',
  maxHeight: '80px',
  overflowY: 'auto',
  // props disabled가 true면 hover가 안먹음
  '&:hover': {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.mode === 'dark' ? '#fff' : '#333'}`,
  },
}));

const CategoryTitle = styled(Box)(({ theme }) => ({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px 8px',
  fontWeight: 'bold',
  color: '#fff',
}));

const SelectRegions = ({ chipData, setChipData, disabled }) => {
  // mui로 multi select 구현하기

  const [regionList, setRegionList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [open, setOpen] = useState(false);
  const [groupedChipData, setGroupedChipData] = useState([]);

  const cardRef = useRef(null);
  const inputRef = useRef(null);

  const selectMainMenu = (mainMenu) => {
    setSelectedRegion(mainMenu);
    const url = `/v1/SP_b_city_s1/${mainMenu?.nm}`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      const List = res.data.data.map((item) => {
        return { region: mainMenu?.nm, ...item };
      });
      setCityList(List);
    });
  };

  const getRegionList = () => {
    const url = `/v2/SP_b_city_s2`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      setRegionList(res.data.data);
    });
  };

  const handlePushCity = (item, index) => {
    // item.nm === '전체' 이면 해당 지역의 전체를 포함 모든 시군구를 chipData에 담는다.
    // item.cd가 chipData에 없으면 chipData에 담는다.
    // item.cd가 chipData에 있으면 chipData에서 제거한다.
    if (item.nm === '전체') {
      const filterChipData = chipData.filter((region) => region?.region !== item?.region);
      const removeWhole = cityList.filter((city) => city.nm !== '전체');
      // 해당 지역의 전체가 chipData에 포함되어 있으면 chipData에서 제거한다.
      // 해당 지역의 전체를 포함 모든 시군구를 chipData에 담는다.
      if (chipData.filter((region) => region?.region === item?.region).length >= removeWhole.length) {
        setChipData(filterChipData);
      } else {
        // 전체 제외한 나머지만 넣기
        setChipData([...filterChipData, ...removeWhole]);
      }
    } else {
      if (chipData.filter((region) => region.cd === item.cd).length > 0) {
        setChipData(chipData.filter((region) => region.cd !== item.cd));
      } else {
        setChipData(chipData.concat(item));
      }
    }
  };
  const handleReset = () => {
    setChipData([]);
  };

  const handleRegionSelectionClick = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (cardRef.current && !cardRef.current.contains(e.target) && !inputRef.current.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    // chipData에서 region이 같은 것만 배열로 만들어서 하나씩 표시하기 reduce 사용하기
    const grouped = chipData.reduce((acc, cur) => {
      const region = cur?.region;
      if (!acc[region]) {
        acc[region] = [];
      }
      acc[region].push(cur);
      return acc;
    }, {});
    setGroupedChipData(Object.values(grouped));
  }, [chipData]);

  useEffect(() => {
    getRegionList();
  }, []);

  useEffect(() => {
    if (open) {
      window.addEventListener('mousedown', handleOutsideClick);
    } else {
      window.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [open]);

  const removeAllRegion = (region) => {
    setChipData(chipData.filter((item) => item.region !== region));
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
      }}
    >
      {/* 지역이 담기는 창 */}
      <ChipBox
        className="custom_scrollbar"
        sx={{
          border: open ? '1px solid var(--primary-color)' : '1px solid var(--border-color)',
          backgroundColor: disabled ? 'var(--disabled-color)' : 'var(--input-color)',
          paddingRight: '32px',
        }}
        onClick={handleRegionSelectionClick}
        disabled={disabled}
        ref={inputRef}
      >
        {chipData.length === 0 && (
          <Box
            sx={{
              marginLeft: '4px',
              display: 'flex',
              alignItems: 'center',
              color: '#000',
            }}
          >
            지역을 선택해주세요.
          </Box>
        )}
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '4px' }}>
          {/* 해당지역에 chip형태로 담김 */}
          {groupedChipData.map((item, index) => {
            return (
              <Chip
                key={index}
                label={`${item[0].region} ${item[0].nm} ${item.length > 1 ? '외 ' + (item.length - 1) : ''}`}
                onDelete={() => {
                  removeAllRegion(item[0].region);
                }}
                color="primary"
              />
            );
          })}
        </Stack>
        {!disabled && (
          <IconButton
            sx={{
              padding: '0px',
              // 아이콘 회전
              transition: 'transform 0.3s ease',
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              position: 'absolute',
              top: '8px',
              right: '8px',
              zIndex: '3',
            }}
            onClick={handleRegionSelectionClick}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        )}
      </ChipBox>
      {open && (
        <Card
          sx={{
            position: 'absolute',
            zIndex: '5',
            width: '100%',
            backgroundColor: '#212838',
            color: '#fff',
          }}
          ref={cardRef}
        >
          <Grid2 container>
            <Grid2
              lg={6}
              sx={{
                border: '1px solid #8a8a8a',
              }}
            >
              <CategoryTitle>시 / 도</CategoryTitle>
              <Box
                sx={{
                  display: 'grid',
                  maxHeight: '180px',
                  overflowY: 'auto',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  flexWrap: 'wrap',
                }}
              >
                {regionList.map((item, index) => (
                  <Button
                    id={item.cd}
                    key={index}
                    onClick={() => selectMainMenu(item)}
                    variant={'outlined'}
                    size="small"
                    color={selectedRegion?.cd === item?.cd ? 'info' : 'secondary'}
                    sx={{
                      height: '30px',
                      borderRadius: '0',
                      color: selectedRegion?.cd === item?.cd ? 'var(--mint-color)' : 'var(--text-color)',
                    }}
                    fullWidth
                  >
                    {item.cd}
                  </Button>
                ))}
              </Box>
            </Grid2>
            <Grid2
              lg={6}
              sx={{
                border: '1px solid #8a8a8a',
              }}
            >
              <CategoryTitle>시 / 군 / 구</CategoryTitle>
              <Scrollbars
                className="custom_scrollbar"
                renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
                style={{
                  height: '180px',
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    flexWrap: 'wrap',
                  }}
                >
                  {cityList.length > 0 &&
                    cityList.map((item, index) => (
                      <Button
                        key={index}
                        onClick={() => handlePushCity(item, index)}
                        size="small"
                        variant={'outlined'}
                        color={item.nm === '전체' ? (selectedRegion?.count === chipData.filter((region) => region.region === item.region).length ? 'info' : 'secondary') : chipData.filter((region) => region.cd === item.cd).length > 0 ? 'info' : 'secondary'}
                        sx={{
                          height: '30px',
                          borderRadius: '0',
                          color: item.nm === '전체' ? (selectedRegion?.count === chipData.filter((region) => region.region === item.region).length ? 'var(--mint-color)' : 'var(--text-color)') : chipData.filter((region) => region.cd === item.cd).length > 0 ? 'var(--mint-color)' : 'var(--text-color)',
                        }}
                      >
                        {item.nm}
                      </Button>
                    ))}
                </Box>
              </Scrollbars>
            </Grid2>
            <Grid2 lg={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px',
                  padding: '6px 10px',
                }}
              >
                {groupedChipData.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                  >
                    <Box>
                      선택한 지역 <MintText>{groupedChipData.length}</MintText>개
                    </Box>
                    <Button size={'small'} color="info" endIcon={<ReplayIcon />} onClick={handleReset}>
                      전체 지역 초기화
                    </Button>
                  </Box>
                )}
                <Scrollbars
                  className="custom_scrollbar"
                  renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
                  style={{
                    height: '88px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '6px',
                    }}
                  >
                    {groupedChipData.map((group, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: '6px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                          }}
                        >
                          <Chip
                            label={group[0].region}
                            color="info"
                            onDelete={() => {
                              removeAllRegion(group[0].region);
                            }}
                          />
                          <ArrowForwardIosIcon
                            sx={{
                              color: 'var(--mint-color)',
                              fontSize: '16px',
                            }}
                          />
                        </Box>
                        {group.length === regionList.find((region) => region.nm === group[0].region)?.count ? (
                          <Chip
                            label="전체"
                            onDelete={() => {
                              removeAllRegion(group[0].region);
                            }}
                            color="info"
                            variant="outlined"
                            sx={{
                              color: 'var(--text-color)',
                            }}
                          />
                        ) : (
                          <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '6px' }}>
                            {group.map((item) => {
                              return (
                                <Chip
                                  key={item.cd}
                                  label={item.nm}
                                  onDelete={() => {
                                    setChipData(chipData.filter((region) => region.cd !== item.cd));
                                  }}
                                  color="info"
                                  variant="outlined"
                                  sx={{
                                    color: 'var(--text-color)',
                                  }}
                                />
                              );
                            })}
                          </Stack>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Scrollbars>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
              >
                <Button variant="contained" color="info" onClick={closeModal} size="small">
                  지역설정
                </Button>
              </Box>
            </Grid2>
          </Grid2>
        </Card>
      )}
    </Box>
  );
};

export default SelectRegions;

import React from 'react';
import { Lists, Header, Body, Region, RegionListBox } from './SoodalPlusElements';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
const RegionLists = ({ list, selectedRegion, setSelectedRegion }) => {
  return (
    <Lists>
      <Header style={{ borderTopLeftRadius: '5px' }}>시/도</Header>
      {list.length > 0 && (
        <Body>
          <Scrollbars className="scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
            <RegionListBox>
              {list.length > 0 &&
                list.map((item, index) => (
                  <Region
                    key={item.cd}
                    type="button"
                    onClick={() => {
                      setSelectedRegion(item);
                    }}
                    className={selectedRegion?.nm === item.nm && 'selected'}
                  >
                    <span>{item.cd}</span>
                    {!!selectedRegion && <ChevronRightRoundedIcon />}
                  </Region>
                ))}
            </RegionListBox>
          </Scrollbars>
        </Body>
      )}
    </Lists>
  );
};

export default RegionLists;

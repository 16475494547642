import React, { useState, useEffect } from 'react';
import { CloseModal, OpenModal, Section, ModalHeader, CloseBtn, Main, TextBoard } from './ShowLawModalElements';
import closeIcon from 'assets/modal-icon/close.png';
import utils from 'modules/utils';

const ShowLawModal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, header, gubn } = props;

  const [law, setLaw] = useState(null);
  // 이용 약관 동의 내용 가져오기
  const getLaw = async () => {
    utils.http.setMode('api');
    try {
      const res = await utils.http.get(`/v1/SP_m_law_s1/${gubn}`);
      if (res.data.data && res.data.data.length > 0) {
        setLaw(res.data.data[0].content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLaw();
  }, [open]);
  return (
    <>
      {open ? (
        <OpenModal>
          <Section>
            <ModalHeader>
              {header}
              <CloseBtn onClick={close}>
                <img src={closeIcon} alt="" />
              </CloseBtn>
            </ModalHeader>
            <Main>
              <TextBoard>
                {/* <Scrollbars className="scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
                  {law}
                  </Scrollbars> */}
                <textarea
                  className="scrollbar-container2"
                  style={{
                    width: '100%',
                    height: '100%',
                    fontSize: '14px',
                    resize: 'none',
                  }}
                  type="text"
                  value={law}
                  readOnly
                />
              </TextBoard>
            </Main>
          </Section>
        </OpenModal>
      ) : (
        <CloseModal></CloseModal>
      )}
    </>
  );
};

export default ShowLawModal;

import React, { useEffect } from 'react';
import { Table, Thead, TheadRow, TheadCol, Tbody, TbodyRow, TbodyCol } from './TableElements';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

const LinkText = styled(Link)`
  font-size: 18px;
  font-weight: normal;
  color: #fff;
  text-align: center;

  &:hover {
    color: #5affe2;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

const HomeNoticeTable = ({ notices }) => {
  useEffect(() => {}, [notices]);
  return (
    <div className="home-notice-table">
      <Scrollbars
        className="scrollbar-container"
        renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
        style={{
          marginTop: '20px',
        }}
      >
        <Table>
          <Thead>
            <TheadRow>
              <TheadCol>분류</TheadCol>
              <TheadCol>제목</TheadCol>
              <TheadCol>등록일</TheadCol>
            </TheadRow>
          </Thead>
          <Tbody>
            {notices.map((row) => (
              <TbodyRow key={`home-notice-${row.sqenNumb}${row.workDate}`}>
                <TbodyCol>{row.noti_auth_name}</TbodyCol>
                <TbodyCol>
                  <LinkText to={`/manage/news/notice/${row.workDate}/${row.sqenNumb}`}>{row.notiSubject}</LinkText>
                </TbodyCol>
                <TbodyCol>{row.ftDt}</TbodyCol>
              </TbodyRow>
            ))}
          </Tbody>
        </Table>
      </Scrollbars>
    </div>
  );
};

export default HomeNoticeTable;

import styled from 'styled-components';

export const Container = styled.div`
  width: 723px;
  height: 208px;
`;

export const BannerTab = styled.div`
  width: 100%;
  position: relative;
`;

export const SlideContainer = styled.div`
  position: relative;
  width: 723px;
  height: 208px;
  overflow: hidden;
  border-radius: 10px;
`;

export const SlideControl = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 40%;
  z-index: 20;
  padding: 0 30px;
`;

export const ControlPrevBtn = styled.button`
  cursor: pointer;
  display: inline-block;
  z-index: 10;
  background: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
`;

export const ControlNextBtn = styled.button`
  cursor: pointer;
  display: inline-block;
  z-index: 10;
  background: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
`;

export const SlideImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
`;

export const EventSlideImg = styled.img`
  width: 100%;
  height: 120px;
  border-radius: 10px;
  cursor: pointer;
`;

export const ImgLink = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import WindowFrame from './WindowFrame';
import React, { useEffect, useState } from 'react';
import { Form, InputList, ReviewInputList, SubmitBtn } from './ModalElements';
import { BdWhiteText } from 'style';
import utils from 'modules/utils';
import { styled } from 'styled-components';
import CustomMuiSelect from '../../Common/CustomSelect/CustomMuiSelect';
import Rating from '@mui/material/Rating';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import FirstFalse from '../../../../assets/rating/1_false.png';
import FirstTrue from '../../../../assets/rating/1_true.png';
import SecondFalse from '../../../../assets/rating/2_false.png';
import SecondTrue from '../../../../assets/rating/2_true.png';
import ThirdFalse from '../../../../assets/rating/3_false.png';
import ThirdTrue from '../../../../assets/rating/3_true.png';
import { Divider } from 'components/Manage/Ticket/TicketList/TicketListElements';
import MultiImageUpload from 'components/FileUpload/MultiImageUpload';

const GrayText = styled.div`
  font-size: 14px;
  color: #8a8a8a;
`;

const GrayBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  cursor: pointer;
`;

const PrograssRating = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #8a8a8a;
`;

/*
 workDate: 일자키
sqenNumb: 순번키
direcSubject: 제목
direcContent: 내용
delKey: 이미지 삭제 키
files: 파일
dir = ‘direct’ 
 */

const progressRatingList = [
  {
    id: 0,
    trueImg: FirstTrue,
    falseImg: FirstFalse,
    label: '아쉬웠수달',
  },
  {
    id: 1,
    trueImg: SecondTrue,
    falseImg: SecondFalse,
    label: '잘했수달',
  },
  {
    id: 2,
    trueImg: ThirdTrue,
    falseImg: ThirdFalse,
    label: '만족했수달',
  },
];

const ReviewModal = ({ open, close, title, afterAction, refreshList }) => {
  const formData = new FormData();
  const [dataObj, setDataObj] = useState({
    sqenNumb: '',
    workDate: '',
    content: '',
  });

  //화면에 출력되는 이미지
  const [showImages, setShowImages] = useState([]);
  const [delKey, setDelKey] = useState([]); // 삭제할 이미지 이름

  const [userId, setUserId] = useState('');
  const [userNickList, setUserNickList] = useState([]);

  const [starRating, setStarRating] = useState(0);
  const [progressRating, setProgressRating] = useState(2);

  // 견적 제출 버튼 클릭
  const submitSoodalBoard = async (e) => {
    e.preventDefault();
    let stringDelKey = '';
    if (delKey.length > 0) {
      delKey.forEach((item) => {
        stringDelKey += item.serverFileName + '_//';
      });
    }
    // 입력 안된 값이 있으면 alert로 안내
    if (userId === '') {
      utils.dialog.alert('작성자 없음!', '작성자를 선택해 주세요.', 'warning');
    } else if (!starRating) {
      utils.dialog.alert('별점 없음!', '별점을 선택해 주세요.', 'warning');
    } else if (progressRating === (null || undefined)) {
      utils.dialog.alert('만족도 없음!', '만족도를 선택해 주세요.', 'warning');
    } else if (dataObj.content?.trim() === '') {
      utils.dialog.alert('리뷰내용 없음!', '리뷰내용을 입력해 주세요.', 'warning');
    } else {
      // 이미지 파일
      showImages.forEach((image) => {
        if (image.localFlag) {
          formData.append('files', image.file);
        }
      });

      formData.append('sqenNumb', dataObj.sqenNumb);
      formData.append('workDate', dataObj.workDate);
      formData.append('gubn', 'I');
      formData.append('partId', utils.user('id'));
      formData.append('partrText', dataObj.content);
      formData.append('partrScore', starRating);
      formData.append('partrfeel', progressRating);
      formData.append('payWorkDate', '');
      formData.append('paySqenNumb', '');
      formData.append('imageCount', showImages.length);
      formData.append('userId', userId);
      formData.append('delKey', stringDelKey);

      utils.http.setMode('api');
      window.xxx = formData;

      const url = '/v2/SP_r_partner_review_I2';
      const res = await utils.http.postFile(url, formData);
      console.log('submitSoodalBoard', res);

      if (res.data.success && res.data.data.length > 0) {
        console.log(res.data);
        utils.dialog.alert('리뷰 등록 완료!', `작성하신 리뷰가 등록되었습니다!`, 'success').then(() => {
          refreshList();
          utils.isFunction(close) && close();
          utils.isFunction(afterAction) && afterAction(res.data);
        });
      } else {
        if (res?.data?.message.includes('욕설')) {
          utils.dialog.alert('비속어 금지!', res.data.message, 'error');
          return;
        }
        utils.dialog.alert('리뷰 등록 실패!', '리뷰 등록에 실패했습니다!<br />관리자에게 문의해 주세요.', 'error');
      }
    }
  };

  const getUserList = () => {
    const url = `/v2/SP_b_User_infoAdmin_s1`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data.success && res.data.data.length > 0) {
        let userList = res.data.data;
        userList.forEach((item) => {
          item.value = item.user_id;
          item.label = item.userNick;
        });
        setUserNickList(userList);
      }
    });
  };

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    // 등록일때
    if (open) {
      setDataObj({
        sqenNumb: '',
        workDate: '',
        content: '',
      });
      setShowImages([]);
    }
    if (!open) {
      setShowImages([]);
      setDelKey([]);
    }
  }, [open]);

  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'578px'}
      height={'850px'}
      slotFixedButton={
        <SubmitBtn type="button" onClick={submitSoodalBoard}>
          완료
        </SubmitBtn>
      }
    >
      <Form action="#none">
        <ReviewInputList sx={{ gap: '7px' }}>
          <BdWhiteText>작성자</BdWhiteText>
          <GrayText>임의로 지정된 작성자를 선택해 주세요.</GrayText>
          <CustomMuiSelect selected={userId} setSelected={setUserId} label="작성자를 선택해 주세요." optionList={userNickList} />
        </ReviewInputList>
        <Divider />
        <ReviewInputList>
          <BdWhiteText>달인과는 어떠셨나요?</BdWhiteText>
          <GrayText>별점을 체크해 주세요.</GrayText>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Rating
              name="simple-controlled"
              value={starRating}
              onChange={(event, newValue) => {
                setStarRating(newValue);
              }}
              size="large"
              icon={<StarRateRoundedIcon fontSize="inherit" />}
              emptyIcon={<StarRateRoundedIcon fontSize="inherit" />}
              sx={{
                '& .MuiRating-iconFilled': {
                  color: 'var(--mint-color)',
                },
                '& .MuiRating-iconHover': {
                  color: 'var(--mint-color)',
                },
                '& .MuiRating-iconEmpty': {
                  color: '#cccccc',
                },
                // 아이콘 사이즈
                '& .MuiSvgIcon-root': {
                  width: '50px',
                  height: '50px',
                },
              }}
            />
          </div>
        </ReviewInputList>
        <Divider />
        <InputList>
          <BdWhiteText>진행과정은 만족하셨나요?</BdWhiteText>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            {progressRatingList.map((item, index) => (
              <PrograssRating key={item.id}>
                <GrayBackground
                  onClick={() => {
                    setProgressRating(item.id);
                  }}
                >
                  <img src={progressRating === item.id ? item.trueImg : item.falseImg} alt="" style={{ width: '70px', height: '70px' }} />
                </GrayBackground>
                <div>{item.label}</div>
              </PrograssRating>
            ))}
          </div>
        </InputList>
        <InputList>
          <div className={'custom-scroll-text-area'} style={{ height: '100px' }}>
            <textarea
              style={{
                fontSize: '16px',
              }}
              placeholder={`수리에 대한 솔직한 리뷰를 남겨주세요.`}
              required
              onChange={(e) => {
                setDataObj({
                  ...dataObj,
                  content: e.target.value,
                });
              }}
              value={dataObj.content}
            />
          </div>
        </InputList>
        <InputList>
          <BdWhiteText>사진</BdWhiteText>
          {/* <Uploader maxImage={5} showImages={showImages} setShowImages={setShowImages} setFile={setFile} file={file} setDelKey={setDelKey} /> */}
          <MultiImageUpload accept={'image/jpg, image/jpeg, image/png'} name="qnaModal" imageArr={showImages} setImageArr={setShowImages} setDeleteImageArr={setDelKey} isWhite={true} maxImage={5} width={'80px'} height={'80px'} />
          <div
            style={{
              fontSize: '12px',
              color: '#8a8a8a',
            }}
          >
            <div>
              솔직하게 작성하신 리뷰는 수리를 고민하시는 분들에게 큰 도움이 됩니다.
              <br />
              하지만 허위 리뷰, 욕설, 타인비방글 등 선량한 파트너나 제3자의 권리를 침해하는 글들은 서비스 이용 약관이나 관련 법률에 따라 제재를 받을 수 있는 점 참고해 주세요.
            </div>
          </div>
        </InputList>
      </Form>
    </WindowFrame>
  );
};

export default ReviewModal;

import React from 'react';
import { ContentTop, IconWrap, SmallModal, InfoText, HeadingText } from './MenuInfoElements';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const SmallInfoModal2 = ({ infoModalData, style }) => {
  return (
    <>
      <ContentTop style={{ width: 'auto' }}>
        <IconWrap style={{ marginLeft: '10px' }}>
          <AiOutlineExclamationCircle color="var(--mint-color)" style={{ cursor: 'pointer' }} />
          <SmallModal
            style={{
              zIndex: '9999',
              height: 'auto',
              padding: '10px',
            }}
            width={style.width}
            height={style.height}
          >
            <InfoText
              style={{
                width: '100%',
                height: '100%',
                fontSize: '22px',
                padding: '10px',
                lineHeight: '1.5',
              }}
            >
              {infoModalData}
            </InfoText>
          </SmallModal>
        </IconWrap>
      </ContentTop>
    </>
  );
};

export default SmallInfoModal2;

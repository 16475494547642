// CryptoJS 모듈을 가져옵니다.
import CryptoJS from 'crypto-js';

// 기타 유틸리티 모듈을 가져옵니다.
import utils from '../utils';

// 설정 객체를 초기화합니다.
let config = {
  secretKey: process.env.REACT_APP_SECRET_KEY, // 비밀 키
  defaultIV: process.env.REACT_APP_SECRET_IV, // 기본 초기화 벡터
  keyRound: process.env.REACT_APP_SECRET_KEY_ROUND, // 키 생성 라운드
  ivRound: process.env.REACT_APP_SECRET_IV_ROUND, // 초기화 벡터 생성 라운드
};

// 기본 암호화 구성을 정의하는 함수
const defaultEncryptConfig = (msg, enc_config) => {
  // enc_config이 null 또는 undefined인 경우 기본 설정을 사용합니다.
  if (utils.isNull(enc_config)) enc_config = {};
  if (utils.isNull(enc_config.salt)) enc_config.salt = 'abdescrwe4r234df21231232354'; // 솔트
  if (utils.isNull(enc_config.iv)) enc_config.iv = config.defaultIV; // 초기화 벡터
  if (utils.isNull(enc_config.keyLength)) enc_config.keyLength = 256; // 키 길이

  // 암호화에 필요한 매개변수를 배열로 반환합니다.
  return [String(msg), enc_config.iv, enc_config.keyLength, enc_config.salt];
};

// 암호화 관련 유틸리티를 정의하는 객체
let cryptoUtils = {
  // 설정 객체를 설정합니다.
  setConfig(configObj) {
    config = configObj;
  },

  // 해시 함수
  hash(msg, outputLength, iterations) {
    msg = String(msg);
    if (utils.isNull(outputLength)) outputLength = 256;
    if (utils.isNull(iterations) || iterations <= 0) iterations = 1;
    let output = CryptoJS.SHA3(msg, { outputLength });
    for (let i = 1; i < iterations; i++) {
      output = CryptoJS.SHA3(output, { outputLength });
    }
    return output.toString();
  },

  // 키 생성 함수
  createKey(msg, salt, outputLength, iterations) {
    if (utils.isNull(salt)) salt = this.salt(256);
    if (utils.isNull(outputLength)) outputLength = 256;
    if (utils.isNull(iterations)) iterations = 11;

    return {
      salt,
      outputLength,
      iterations,
      key: CryptoJS.PBKDF2(msg, salt, {
        keySize: outputLength / 32,
        iterations,
      }).toString(),
    };
  },

  // 인증서 생성 함수
  certificate(msg, salt, iterations) {
    if (utils.isNull(salt)) salt = this.salt(128);
    if (utils.isNull(iterations)) iterations = this.randInt(1, 32);

    return {
      salt,
      iterations,
      crypt: this.createKey(msg, salt, 256, iterations).key,
    };
  },

  // 문자열 암호화 함수
  encrypt(plainStr, encryptConfig) {
    let iv, keyLength, salt;
    [plainStr, iv, keyLength, salt] = defaultEncryptConfig(plainStr, encryptConfig);

    let key = CryptoJS.enc.Hex.parse(this.hash(config.secretKey + salt, keyLength, config.keyRound));
    iv = CryptoJS.enc.Hex.parse(this.hash(iv, 128, config.ivRound));

    return CryptoJS.AES.encrypt(plainStr, key, { iv }).toString();
  },

  // 문자열 복호화 함수
  decrypt(cipherStr, encryptConfig) {
    let iv, keyLength, salt;
    [cipherStr, iv, keyLength, salt] = defaultEncryptConfig(cipherStr, encryptConfig);

    let key = CryptoJS.enc.Hex.parse(this.hash(config.secretKey + salt, keyLength, config.keyRound));
    iv = CryptoJS.enc.Hex.parse(this.hash(iv, 128, config.ivRound));

    return CryptoJS.AES.decrypt(cipherStr, key, { iv }).toString(CryptoJS.enc.Utf8);
  },

  // 객체 암호화 함수
  encryptObject(planeObj, encryptConfig) {
    return this.encrypt(utils.toJSON(planeObj), encryptConfig);
  },

  // 객체 복호화 함수
  decryptObject(cipherObj, encryptConfig) {
    return utils.fromJSON(this.decrypt(cipherObj, encryptConfig));
  },

  // 버퍼 암호화 함수
  encryptBuffer(planeBuf, encType, encryptConfig) {
    if (utils.isNull(encType)) encType = 'base64';
    return this.encryptObject(
      {
        encType,
        bufferString: planeBuf.toString(encType),
      },
      encryptConfig
    );
  },

  // 버퍼 복호화 함수
  decryptBuffer(cipherBuf, encType, encryptConfig) {
    let decObj = this.decryptObject(cipherBuf, encryptConfig);
    if (utils.isNull(encType)) encType = decObj.encType;
    return Buffer.from(decObj.bufferString, encType);
  },

  // 임의의 정수 생성 함수
  randInt(...range) {
    let min = 0,
      max = 1000000000000000;
    if (range.length > 2) range.length = 2;

    switch (range.length) {
      case 1:
        max = range[0];
        break;
      case 2:
        min = range[0];
        max = range[1];
        break;
    }
    max++;
    return Math.floor(Math.random() * (max - min) + min);
  },

  // 솔트 생성 함수
  salt(outputLength) {
    if (utils.isNull(outputLength)) outputLength = 256;
    return CryptoJS.lib.WordArray.random(outputLength / 8).toString();
  },
};

// cryptoUtils 객체를 불변하게 만듭니다.
Object.freeze(cryptoUtils);

// cryptoUtils 객체를 내보냅니다.
export default cryptoUtils;

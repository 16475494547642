import checkIcon from 'assets/checkbox-icon/colorCheck.png';
import { styled } from 'styled-components';

export const CustomBox = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  border-radius: 20px;
  background-color: #37465b;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  /* gap: 22px; */
  padding: 28px 30px;

  @media screen and (max-width: 1024px) {
    gap: 14px;
  }
`;

export const ListBox = styled.div`
  width: 100%;
  height: calc(100% - 118px);
  background-color: #212838;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
export const MyTicketBox = styled.div`
  width: 100%;
  height: 68px;
  background-color: #212838;
  border-radius: 5px;
  display: flex;
  border: solid 1px #8a8a8a;
  margin-top: 8px;
`;

export const SwitchBox = styled.div`
  display: flex;
  align-items: center;
`;
export const RegionBox = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
`;

export const Bottom = styled.div`
  width: 100%;
  height: 40%;
  border: solid 1px #8a8a8a;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .show-checked-items {
    width: 100%;
    height: 100%;
    padding: 12px 20px 7px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .checked-item {
      width: auto;
      height: 31px;
      padding: 0px 14px;
      border-radius: 15px;
      border: solid 1px #5affe2;
      background-color: transparent;
      color: #fff;
      font-size: 20px;
      font-weight: normal;
      cursor: pointer;
      margin: 0 20px 20px 0;
      display: inline-flex;
      align-items: center;
      gap: 3px;

      .close-btn {
        background: transparent;
        cursor: pointer;
        line-height: 1;
        padding-top: 4px;

        .icon {
          line-height: 1;
          font-size: 23px;
        }
      }
    }
  }

  .btn-box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;

    .reset-btn {
      width: auto;
      height: auto;
      background-color: transparent;
      color: #fff;
      font-size: 17.5px;
      font-weight: normal;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 10px;
      margin-right: 10px;

      svg {
        font-size: 24px;
        line-height: 1;
        color: #fff;
      }
    }
  }

  .save-btn {
    width: 11%;
    height: 36px;
    border-radius: 5px;
    background-color: #5affe2;
    text-align: center;
    color: #212838;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 12px;
    margin-left: 15px;
  }

  @media screen and (max-width: 1024px) {
    .show-checked-items {
      .checked-item {
        font-size: 14px;
        padding: 0px 10px;
        height: 24px;
        margin: 0 10px 10px 0;
      }
    }
    .btn-box {
      .reset-btn {
        font-size: 14px;
        gap: 2px;

        svg {
          font-size: 18px;
        }
      }
    }
    .save-btn {
      padding: 0 10px;
      width: auto;
      font-size: 14px;
      height: 30px;
    }
  }
`;

export const Lists = styled.div`
  width: 50%;
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  height: 41px;
  padding: 6px 0;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border: solid 1px #8a8a8a;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const Body = styled.div`
  width: 100%;
  height: calc(100% - 41px);
  border: 1px solid #8a8a8a;
  border-top: none;
  border-bottom: none;
`;

/* RegionLists Part */

export const RegionListBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;

  @media screen and (max-width: 1024px) {
    flex-direction: row;
  }
`;

export const Region = styled.button`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #8a8a8a;
  padding: 0 20px;
  font-size: 21px;
  line-height: 1;
  font-weight: normal;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  text-align: left;
  flex-wrap: nowrap;

  &:hover {
    border: solid 1px #5affe2;
    color: #5affe2;
  }
  svg {
    font-size: 30px;
    display: none;
  }
  &:hover svg {
    color: #5affe2;
  }
  &.selected {
    border: solid 1px #5affe2;
    color: #5affe2;

    svg {
      color: #5affe2;
    }
  }
  &.selected svg {
    display: block;
  }
  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    width: 32%;
    font-size: 16px;
    padding: 0 10px;
    svg {
      font-size: 23px;
    }
  }
`;
export const CityList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;

// SoodalPuls
export const TicketIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
`;

/* cityLists Part */
export const CityItem = styled.div`
  height: 49px;
  display: inline-block;

  button {
    text-align: left;
    padding: 8px 25px 6px 30px;
    cursor: pointer;
    background: transparent;
    font-size: 21px;
    font-weight: normal;
    color: #fff;
  }

  label {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid #8a8a8a;
    flex-wrap: nowrap;

    &:hover,
    &.selected {
      border: 1px solid #5affe2;
    }
    &:hover span {
      color: #5affe2;
    }
    &.selected span {
      color: #5affe2;
    }
    span {
      padding: 0;
      color: #fff;
      font-size: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  input[type='checkbox'] {
    appearance: none;
    width: 21px;
    height: 15px;

    &:hover {
      cursor: pointer;
    }

    &:checked {
      background: center url(${checkIcon}) no-repeat;
      border: none;
    }

    &:checked ~ span {
      color: #fff;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 40px;
    label {
      span {
        font-size: 16px;
      }
    }
  }
`;

import React, { useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // 플러그인
import interactionPlugin from '@fullcalendar/interaction'; // 플러그인
import listPlugin from '@fullcalendar/list'; // "list" 플러그인을 추가
import ko from '@fullcalendar/core/locales/ko';
import './Calendar.scss';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';

const DefaultCalendar = ({ twoWeek, height, calendarRef, events, handleSelectRange, handleSelectEvent, handleDatesSet, closeDay }) => {
  const isSmallPC = useMediaQuery({ query: '(max-width: 1024px)' });

  const dayCellContent = (arg) => {
    if (closeDay && closeDay.includes(arg.date.getDay())) {
      return (
        <div aria-label={arg.dayNumberText} id={arg.domId} className="fc-daygrid-day-number close-day">
          휴무
        </div>
      );
    } else {
      return (
        <div aria-label={arg.dayNumberText} id={arg.domId} className="fc-daygrid-day-number">
          {arg.dayNumberText}
        </div>
      );
    }
  };

  useEffect(() => {}, [closeDay]);

  return (
    <FullCalendar
      editable={false}
      selectable={twoWeek ? false : true}
      selectMirror={twoWeek ? false : true}
      dayMaxEvents={twoWeek ? false : true}
      height={height ? height : '200px'}
      rerenderDelay={10}
      allDayMaintainDuration
      eventResizableFromStart
      dayCellContent={twoWeek ? undefined : dayCellContent}
      dayCellClassNames={(arg) => {
        if (closeDay && closeDay.includes(arg.date.getDay())) {
          return 'close-day';
        }
      }}
      eventClassNames={(arg) => {
        if (twoWeek) return 'remove-cursor';
      }}
      ref={calendarRef}
      initialDate={dayjs().toDate()}
      dayMaxEventRows={3}
      events={events}
      select={handleSelectRange}
      eventClick={twoWeek ? undefined : handleSelectEvent}
      plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
      eventBackgroundColor="var(--mint-color)"
      eventDisplay="list-item"
      eventLimit={true}
      moreLinkText={(n) => `+${n}개 더보기`}
      datesSet={handleDatesSet}
      locale={ko}
      headerToolbar={
        twoWeek
          ? false
          : {
              left: 'title',
              right: 'prev,next today',
            }
      }
      // "dayGridWeek" 뷰를 2주씩 설정
      initialView={twoWeek ? 'dayGridWeek' : 'dayGridMonth'}
      views={{
        dayGridWeek: {
          type: 'dayGrid',
          duration: { weeks: 1 }, // 2주씩 설정
        },
      }}
    />
  );
};

export default DefaultCalendar;

import { estimateModal } from 'Data/data';
import MultiImageUpload from 'components/FileUpload/MultiImageUpload';
import { imageToImageArr } from 'modules/Upload/imageUpload';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { BdWhiteText } from 'style';
import styled from 'styled-components';
import CategorySelect from './../CustomSelect/CategorySelect';
import { FixedText, Form, Input, InputClearBtn, InputList, InputMoney, SubmitBtn } from './ModalElements';
import WindowFrame from './WindowFrame';

const CateName = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const GrayText = styled.div`
  font-size: 14px;
  color: #8a8a8a;
`;

const AutoEstimateModal = ({ open, close, title, post, nickName, afterAction, isEdit, modalData, imageList }) => {
  // 견적 금액 state
  const [inputMoney, setInputMoney] = useState('');
  const [isCommunicating, setIsCommunicating] = useState(false);

  // x 버튼 클릭 시 견적 금액 초기화
  const onClearValue = () => {
    setInputMoney('');
  };

  const editCategory = modalData?.cate_sub_id;

  // 견적 내용 state
  const [estimateContent, setEstimateContent] = useState('');

  //화면에 출력되는 이미지
  const [showImages, setShowImages] = useState([]);
  const [delShowImages, setDelShowImages] = useState([]);
  const [cateChip, setCateChip] = useState([]);

  // 견적 제출 버튼 클릭
  const submitEstimate = async (e) => {
    if (isCommunicating) return;
    e.preventDefault();
    if (!isEdit && cateChip.length === 0) {
      utils.dialog.alert('카테고리 선택', '카테고리를 선택해주세요.', 'warning');
      return;
    }
    // 입력 안된 값이 있으면 alert로 안내
    if (inputMoney === '' || parseInt(inputMoney) === 0 || estimateContent?.trim() === '') {
      utils.dialog.alert('견적서 제출 실패!', '입력되지 않은 내용이 있습니다.<br />견적서를 다시 확인해 주세요.', 'warning');
    } else {
      const formData = new FormData();
      // 이미지 파일
      showImages.forEach((image) => {
        if (image.localFlag) {
          formData.append('files', image.file);
        }
      });
      let subCate = '';
      cateChip.forEach((chip) => {
        subCate += chip.key + '_//';
      });

      let delKey = '';
      delShowImages.forEach((image) => {
        delKey += image.serverFileName + '_//';
      });

      formData.append('subCateId', isEdit ? editCategory : subCate); // 카테고리
      formData.append('subMony', utils.format.extractNumber(inputMoney)); // 견적 금액
      formData.append('subText', estimateContent); // 견적 내용
      formData.append('workDate', isEdit ? modalData?.work_date : '');
      formData.append('sqenNumb', isEdit ? modalData?.sqen_numb : '');
      formData.append('delKey', isEdit ? delKey : '');

      const url = '/v2/SP_p_partCategorySub_i1';
      utils.http.setMode('api');
      setIsCommunicating(true);
      utils.http.postFile(url, formData).then((res) => {
        if (res.data.success) {
          setIsCommunicating(false);
          utils.dialog.alert('견적서 제출 성공!', '견적서가 제출되었습니다.', 'success');
          afterAction();
          close();
        } else {
          setIsCommunicating(false);
          console.log(res.data);
          utils.dialog.alert('견적서 제출 실패!', res.data.message, 'error');
        }
      });
    }
  };

  useEffect(() => {
    setCateChip([]);
    setInputMoney('');
    setShowImages([]);
    setEstimateContent('');
    if (isEdit) {
      setInputMoney(modalData.part_sub_mony);
      setEstimateContent(modalData.part_sub_text);
      setShowImages(imageToImageArr(imageList));
    }
  }, [open]);

  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'787px'}
      height={'761px'}
      slotFixedButton={
        <SubmitBtn type="button" onClick={submitEstimate}>
          등록
        </SubmitBtn>
      }
    >
      <Form action="#none">
        <InputList>
          <FlexBox>
            <BdWhiteText>서비스</BdWhiteText>
            <GrayText>자동견적에 등록할 서비스를 선택해주세요.</GrayText>
          </FlexBox>
          {isEdit ? <CateName type="text">{modalData.cate_name + ' > ' + modalData.cate_sub_name}</CateName> : <CategorySelect chipData={cateChip} setChipData={setCateChip} />}
        </InputList>
        <InputList>
          <FlexBox>
            <BdWhiteText>사진</BdWhiteText>
            <GrayText>고객이 참고할 만한 이미지를 등록해 주세요.</GrayText>
          </FlexBox>
          {/* <Uploader maxImage={5} showImages={showImages} setShowImages={setShowImages} setFile={setFile} file={file} /> */}
          <MultiImageUpload accept={'image/jpg, image/jpeg, image/png'} name="qnaModal" imageArr={showImages} setImageArr={setShowImages} setDeleteImageArr={setDelShowImages} isWhite={true} maxImage={5} width={'80px'} height={'80px'} />
          <div
            style={{
              fontSize: '14px',
              color: '#fff'
            }}
          >
            <div>※ 이미지: 20MB이하의 JPG, JPEG, PNG 파일 등록 가능</div>
            <div>※ 최대 5장 등록 가능합니다.</div>
          </div>
        </InputList>
        <InputList>
          <FlexBox>
            <BdWhiteText>{estimateModal[1].title}</BdWhiteText>
            <GrayText>고객이 이해할 수 있도록 상세하게 작성해주세요.</GrayText>
          </FlexBox>
          <div className={'custom-scroll-text-area'} style={{ height: '160px' }}>
            <textarea
              placeholder={`진행 서비스의 상세 내용을 입력해주세요.\n또한, 방문상담이 필요하시다면 출장비를 입력해주세요.\n\n-옵션\n-추가 비용\n-출장 비용\n-재료에따른 견적차이 등 `}
              required
              onInput={(e) => {
                setEstimateContent(e.target.value);
              }}
              value={estimateContent}
            ></textarea>
          </div>
        </InputList>
        <InputList>
          <FlexBox>
            <BdWhiteText>최소금액</BdWhiteText>
            <GrayText>최소 금액과 방문상담시, 출장비를 입력해주세요.</GrayText>
          </FlexBox>
          <InputMoney>
            <Input
              type="text"
              onInput={(e) => {
                const 최대1억 = 100000000;
                e.preventDefault();
                if (parseInt(e.target.value.replace(/,/g, '')) <= 최대1억) {
                  setInputMoney(utils.format.extractNumber(e.target.value, ['format']));
                } else if (e.target.value === '') {
                  setInputMoney('');
                } else if (parseInt(e.target.value.replace(/,/g, '')) > 최대1억) {
                  setInputMoney(utils.format.extractNumber(최대1억, ['format']));
                }
              }}
              value={inputMoney}
              required
              placeholder="견적금액(원)을 입력해 주세요. ex)1,000,000"
            />
            <div style={{ width: '15%', paddingLeft: '4px' }}>
              <FixedText>원 부터~</FixedText>
            </div>
            <div style={{ width: '5%', textAlign: 'center' }}>
              <InputClearBtn type="button" onClick={onClearValue}>
                <IoCloseOutline color="#fff" />
              </InputClearBtn>
            </div>
          </InputMoney>
        </InputList>
        <div>
          <GrayText>· 자동 견적 발송 시, 해당 견적서는 가격 협의가 필요하다는 점을 고객에게 안내됩니다.</GrayText>
          <GrayText>· 카테고리에 맞는 진행 서비스를 모두 견적 설명해 주신다면, 고객에게 다양한 수리 서비스를 안내하실 수 있습니다.</GrayText>
        </div>
      </Form>
    </WindowFrame>
  );
};

export default AutoEstimateModal;

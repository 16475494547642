import React, { useEffect, useState } from 'react';
import { ContentWrap } from 'components/Manage/Info/InfoElements';
import { WtNomalSpan } from 'style';
import styled from 'styled-components';
import CustomSelect from 'components/Parts/Common/CustomSelect/CustomSelect';
import CustomMuiSelect from 'components/Parts/Common/CustomSelect/CustomMuiSelect';
import utils from 'modules/utils';

const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TitleText = styled.div`
  width: 200px;
  font-size: 24px;
  font-weight: bold;
  color: #e9e9e9;

  @media screen and (max-width: 1024px) {
    width: 150px;
    font-size: 16px;
  }
`;

const DaysBox = styled.div`
  //3개씩 보여주기
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;
`;

const DayBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => (!props?.checked ? '#ffffff' : '#212838')};
  color: ${(props) => (!props.checked ? '#8a8a8a' : '#ffffff')};
  border: 1px solid ${(props) => (!props?.checked ? '#ffffff' : 'var(--mint-color)')};
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;

  @media screen and (max-width: 1024px) {
    padding: 4px;
    font-size: 16px;
  }
`;

const customStyle = {
  selectOptions: { width: '100%', top: '38px' },
  selectBox: {
    width: '100%',
  },
};

const TabReservation = ({ setSave }) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [dayList, setDayList] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [timeUnitList, setTimeUnitList] = useState([]);

  const [partnerInfo, setPartnerInfo] = useState([]);
  const getInfo = async () => {
    try {
      const url = `/v1/SP_p_partner_S1/${utils.user('id')}`;
      utils.http.setMode('api');
      const res = await utils.http.get(url);
      if (res.data.data && res.data.success) {
        setPartnerInfo(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCommenCode = () => {
    // 출퇴근시간 60
    // 시간단위 61
    // 휴무일 59
    const startEndUrl = `/v1/SP_b_Common_Code_SI/60`;
    const timeUrl = `/v1/SP_b_Common_Code_SI/61`;
    const dayUrl = `/v1/SP_b_Common_Code_SI/59`;

    console.log('partnerInfo', partnerInfo);

    utils.http.setMode('api');
    const startEndRes = utils.http.get(startEndUrl);
    const timeRes = utils.http.get(timeUrl);
    const dayRes = utils.http.get(dayUrl);

    Promise.all([startEndRes, timeRes, dayRes])
      .then((res) => {
        if (res[0].data.success && res[1].data.success && res[2].data.success) {
          let startEndList = res[0].data.data;
          let timeList = res[1].data.data;
          let dayList = res[2].data.data;

          console.log('timeList', timeList);

          startEndList.forEach((item) => {
            item.value = item.cd;
            item.label = item.nm;
          });
          timeList.forEach((item) => {
            item.value = item.cd;
            item.label = item.nm;
          });
          dayList.forEach((item) => {
            item.value = item.cd;
            item.label = item.nm;
          });

          setTimeUnitList(timeList);
          setTimeList(startEndList);
          setDayList(dayList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSave = async () => {
    if (utils.isEmpty(startTime) || utils.isEmpty(endTime) || utils.isEmpty(selectedTime)) {
      utils.dialog.alert('예약설정 입력!', '예약설정을 입력해 주세요.', 'warning');
      return;
    }

    let restDays = '';
    dayList.forEach((item) => {
      if (item.checked) {
        restDays += item.value + '_//';
      }
    });

    const url = `/v2/SP_p_partCompInfo_wi5`;
    const dataObj = {
      chulTime: startTime,
      toeTime: endTime,
      restDay: restDays,
      reserUnit: selectedTime,
    };

    utils.http.setMode('api');
    await utils.http.post(url, null, dataObj).then((res) => {
      if (res.data.success && res.data.message === '성공') {
        utils.dialog.alert('예약설정 저장!', '예약설정이 저장되었습니다.', 'success');
        if (!utils.user('useCalendar')) {
          const user = JSON.parse(localStorage.getItem('user'));
          user.useCalendar = true;
          localStorage.setItem('user', JSON.stringify(user));
        }
      } else {
        utils.dialog.alert('예약설정 저장 실패!', '예약설정 저장에 실패했습니다.', 'warning');
      }
    });
  };

  useEffect(() => {
    getInfo();
    getCommenCode();
  }, []);

  useEffect(() => {
    if (partnerInfo && dayList.length > 0 && timeList.length > 0 && timeUnitList.length > 0) {
      const info = partnerInfo[0];
      setStartTime(info?.part_chul_time);
      setEndTime(info?.part_toe_time);
      setSelectedTime(info?.part_reser_unit);
      // 요일에는 checked 속성 추가
      if (utils.isEmpty(info?.CloseDay)) {
        return;
      }
      let restDays = info?.CloseDay.split(',');
      dayList.forEach((item) => {
        if (restDays.includes(item?.value)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
    }
  }, [dayList, timeList, timeUnitList, partnerInfo]);

  useEffect(() => {
    if (setSave) {
      setSave({ onSave });
    }
  }, [startTime, endTime, selectedTime]);

  return (
    <ContentWrap className={'reference-image-box'}>
      <RowBox>
        <TitleText>영업시간</TitleText>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <CustomMuiSelect selected={startTime} setSelected={setStartTime} label="시작시간" optionList={timeList} />
          <WtNomalSpan style={{ width: '50px', textAlign: 'center', fontSize: '20px' }}>~</WtNomalSpan>
          <CustomMuiSelect selected={endTime} setSelected={setEndTime} label="종료시간" optionList={timeList} />
        </div>
      </RowBox>
      <RowBox>
        <TitleText>휴무일</TitleText>
        <DaysBox>
          {dayList.map((item, index) => (
            <DayBtn
              key={index}
              checked={item?.checked}
              onClick={() => {
                item.checked = !item?.checked;
              }}
            >
              {item?.label}요일
            </DayBtn>
          ))}
        </DaysBox>
      </RowBox>
      <RowBox>
        <TitleText>예약시간단위</TitleText>
        <CustomMuiSelect selected={selectedTime} setSelected={setSelectedTime} label="예약시간단위" optionList={timeUnitList} />
      </RowBox>
    </ContentWrap>
  );
};

export default TabReservation;

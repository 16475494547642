import { styled } from 'styled-components';

export const ProfileImgBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 8px;
`;

export const InfoBox = styled.div`
  width: 100%;
  height: calc(100% - 196px);
  margin-top: 37px;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .delete-account-btn {
      color: #fff;
      text-decoration: underline;
      font-size: 16px;
      font-weight: bold;
      width: 60px;
      height: 24px;
      background: transparent;
      float: right;
      cursor: pointer;
    }
  }
`;

export const InputLists = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  span {
    font-size: 24px;
    font-weight: bold;
    color: #e9e9e9;
    width: 140px;
  }

  .light-input {
    width: calc(100% - 140px);
    height: 41px;
    background-color: #fff;
    color: #8a8a8a;
    font-size: 20px;
    padding: 0 12px;
    border-radius: 5px;
    border: solid 1px #8a8a8a;

    &::placeholder {
      color: #8a8a8a;
    }
  }

  .dark-input {
    width: calc(100% - 140px);
    height: 41px;
    background-color: #212838;
    color: #8a8a8a;
    font-size: 20px;
    padding: 0 12px;
    border-radius: 5px;
    border: solid 1px #8a8a8a;

    &::placeholder {
      color: #8a8a8a;
    }
  }

  .isButton-input {
    width: calc(100% - 350px);
    height: 41px;
    background-color: #fff;
    color: #8a8a8a;
    font-size: 20px;
    padding: 0 12px;
    border-radius: 5px;
    border: solid 1px #8a8a8a;
    margin-right: 14px;

    &::placeholder {
      color: #8a8a8a;
    }
  }

  .modify-privacy-btn {
    width: 196px;
    height: 41px;
    border-radius: 5px;
    background-color: #7e6eff;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    span {
      width: 120px;
      font-size: 16px;
    }

    .light-input {
      width: calc(100% - 120px);
      height: 30px;
      font-size: 14px;
    }
    .dark-input {
      width: calc(100% - 120px);
      height: 30px;
      font-size: 14px;
    }

    .modify-privacy-btn {
      width: 140px;
      height: 30px;
      font-size: 14px;
    }
  }
`;

/* TabTerms */

export const TermsWrap = styled.div`
  width: 100%;
  height: auto;

  hr {
    margin-bottom: 28px;
  }
`;

export const TermsLists = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 28px;

  &:last-child {
    margin-bottom: 29px;
  }
`;

export const ShowBtn = styled.button`
  width: 112px;
  height: 40px;
  float: right;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  background-color: #7e6eff;
  font-size: 20px;
  font-weight: bold;

  @media screen and (max-width: 1024px) {
    width: 80px;
    height: 30px;
    font-size: 14px;
  }
`;

export const ReceiveInfo = styled.div`
  width: 100%;
  height: auto;

  div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

import { useEffect, useRef } from 'react';

export default function useIntersectionObserver(callback, options = {}) {
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback();
          }
        });
      },
      { ...options }
    );

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [callback, options]);

  const observe = (element) => {
    if (observer.current) {
      observer.current.observe(element);
    }
  };

  const unobserve = (element) => {
    if (observer.current) {
      observer.current.unobserve(element);
    }
  };

  return { observe, unobserve };
}

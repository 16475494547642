import date from './utils.date'; // 날짜 관련 유틸리티 모듈을 가져옵니다.
import array from './utils.array'; // 배열 관련 유틸리티 모듈을 가져옵니다.
import format from './utils.format'; // 포맷 관련 유틸리티 모듈을 가져옵니다.
import constant from './utils.const'; // 상수 관련 유틸리티 모듈을 가져옵니다.
import http from './utils.http'; // HTTP 요청 관련 유틸리티 모듈을 가져옵니다.
import common from './utils.common'; // 일반적인 유틸리티 모듈을 가져옵니다.
import event from './utils.event'; // 이벤트 관련 유틸리티 모듈을 가져옵니다.
import file from './utils.file'; // 파일 관련 유틸리티 모듈을 가져옵니다.
import storage from './utils.storage'; // 스토리지 관련 유틸리티 모듈을 가져옵니다.
import dialog from './utils.dialog'; // 대화 상자 관련 유틸리티 모듈을 가져옵니다.
import socket from './utils.socket'; // 소켓 관련 유틸리티 모듈을 가져옵니다.
import user from './utils.user'; // 사용자 관련 유틸리티 모듈을 가져옵니다.
import valid from './utils.valid'; // 유효성 검사 관련 유틸리티 모듈을 가져옵니다.
import route from './utils.route'; // 라우팅 관련 유틸리티 모듈을 가져옵니다.
import push from './utils.push'; // 푸시 관련 유틸리티 모듈을 가져옵니다.

// common 모듈과 다른 유틸리티 모듈들을 병합하여 utils 객체를 생성합니다.
const utils = Object.assign({}, common, {
  date,
  array,
  format,
  constant,
  http,
  event,
  file,
  storage,
  dialog,
  const: constant,
  socket,
  user,
  valid,
  route,
  push,
});

// utils 객체를 불변하게 만듭니다.
Object.freeze(utils);

// utils 객체를 내보냅니다.
export default utils;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuItem } from '../../../Data/data';
import { Side, Menu, MenuPoint, MenuItem, MenuBtn, DropDown, SubMenu, SubMenuItem, ItemTitle, Footer, FooterTitle, FooterRow, FooterLabel, FooterContent, FooterBody, CopyRight } from './SidebarElements';
import { Scrollbars } from 'react-custom-scrollbars-2';
import QnAModal from '../Common/Modal/QnAModal';
import { menuStore } from './../../../modules/store/store';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import utils from '../../../modules/utils';
import styled from 'styled-components';
import ShowLawModal from './../../RegisterForm/ShowLawModal/ShowLawModal';

const NoReadCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  height: 20px;
  min-width: 20px;
  font-size: 12px;
  border-radius: 10px;
  color: #fff;

  background: #ff585e;
  margin-left: 10px;
  padding: 0 7px;
  &.nomargin {
    margin-left: 0;
    margin-right: 10px;
  }
`;

const FooterUl = styled.ul`
  list-style-type: '- ';
  padding: 0 7px;
  margin-top: 10px;
  color: #fff;
`;
const FooterLi = styled.li`
  font-size: 12px;
`;

const FooterBtns = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  button {
    padding: 0;
    border: none;
    background: none;
    color: #7f6eff;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Sidebar = () => {
  const { setMenuOpen } = menuStore();
  const [selected, setSelected] = useState('홈');
  const [selectSubmenu, setSelectSubmenu] = useState(null);
  const [menuPointTop, setMenuPointTop] = useState(65);
  const [showDetail, setShowDetail] = useState(false);
  const [openLaw, setOpenLaw] = useState(false);
  const [lawTitle, setLawTitle] = useState('이용약관');
  const [lawGubn, setLawGubn] = useState('5');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let selected = '';
    if (['/manage', '/manage/', '/manage/home'].includes(location.pathname)) {
      selected = '홈';
      navigate('/manage/home');
    } else if (location.pathname.startsWith('/manage/news/notice') || location.pathname.startsWith('/manage/news/faqList') || location.pathname.startsWith('/manage/news/qna') || location.pathname.startsWith('/manage/news/event') || location.pathname.startsWith('/manage/news/questionList')) {
      selected = '수달소식';
    } else if (location.pathname.startsWith('/manage/info/partnerInfo') || location.pathname.startsWith('/manage/info/businessInfo') || location.pathname.startsWith('/manage/info/soodalPlus') || location.pathname.startsWith('/manage/info/privacy')) {
      selected = '필수정보';
    } else if (
      location.pathname.startsWith('/manage/history/estimateList') ||
      location.pathname.startsWith('/manage/history/chat') ||
      location.pathname.startsWith('/manage/history/estimate') ||
      location.pathname.startsWith('/manage/history/emergency') ||
      location.pathname.startsWith('/manage/history/soodalBoard') ||
      location.pathname.startsWith('/manage/history/review') ||
      location.pathname.startsWith('/manage/history/autoEstimateList') ||
      location.pathname.startsWith('/manage/history/emergencyList')
    ) {
      selected = '달인내역';
    } else if (location.pathname.startsWith('/manage/transaction/salesHistory') || location.pathname.startsWith('/manage/transaction/withdrawManage')) {
      selected = '거래내역';
    } else if (location.pathname.startsWith('/manage/intern/jobPosting') || location.pathname.startsWith('/manage/intern/intern')) {
      selected = '달인인턴';
    } else if (location.pathname.startsWith('/manage/ticket/ticketList') || location.pathname.startsWith('/manage/ticket/purchaseHistory')) {
      selected = '구독권';
    }
    setSelected(selected);
    setSelectSubmenu(location.pathname.split('/')[3]);
  }, [location.pathname]);
  let returnPoint = () => {
    setMenuPointTop(menuItem.findIndex((menu) => menu.title === selected) * 50 + 22);
  };
  let returnPointTimer = 0;
  useEffect(() => {
    returnPoint();
  }, [selected]);

  //시작시 일반채팅, 채팅목록 카운트 로딩
  useEffect(() => {
    updateChatCount('chat');
    updateChatCount('intern');
  }, []);
  const [openQnA, setOpenQnA] = useState(false);
  const [chatNormalCnt, setChatNormalCnt] = useState(0);
  const [chatInternCnt, setChatInternCnt] = useState(0);

  //채팅 카운트 백엔드 호출 이벤트
  utils.event.on('loadChatCount', (mode, data) => {
    if (mode === 'home') {
      utils.event.emit('homeNewChat', chatNormalCnt > 0);
    } else {
      updateChatCount(mode, data);
    }
  });

  //채팅 카운트가 바뀌면 홈화면 New 표시 이벤트 발생
  useEffect(() => {
    utils.event.emit('homeNewChat', chatNormalCnt > 0);
  }, [chatNormalCnt]);

  function updateChatCount(mode, data) {
    utils.http.setMode('api');
    if (mode === 'chat') {
      if (data) {
        setChatNormalCnt(utils.array.sum(data.map((x) => x.rcdRead)));
      } else {
        utils.http.get('/v1/SP_p_partChatList_S1').then(({ data }) => {
          if (data?.data.length > 0) {
            setChatNormalCnt(utils.array.sum(data.data.map((x) => x.rcdRead)));
          } else {
            setChatNormalCnt(0);
          }
        });
      }
    } else if ('intern') {
      if (data) {
        setChatInternCnt(utils.array.sum(data.map((x) => x.rcdRead)));
      } else {
        utils.http.get('/v1/SP_p_partChatInternList_S1').then(({ data }) => {
          if (data?.data.length > 0) {
            setChatInternCnt(utils.array.sum(data.data.map((x) => x.rcdRead)));
          } else {
            setChatInternCnt(0);
          }
        });
      }
    }
  }

  return (
    <Side>
      {/* menu */}
      <Scrollbars className="scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
        <Menu>
          <MenuPoint
            style={{
              top: menuPointTop + 'px',
            }}
          />
          {menuItem.map((item, index) => (
            <MenuBtn
              key={item.title + index}
              style={{
                background: 'transparent',
                outline: 'none',
                border: 'none',
              }}
            >
              <DropDown>
                <MenuItem
                  onClick={() => {
                    setSelected(item.title);
                    if (item.subMenuLink) setSelectSubmenu(item.subMenuLink[0].split('/')[0]);
                    navigate(`/manage/${item.to}${item.subMenuLink ? `/${item.subMenuLink[0]}` : ''}`);
                  }}
                  onMouseEnter={(e) => {
                    clearTimeout(returnPointTimer);
                    setMenuPointTop(e.target.offsetTop);
                  }}
                  onMouseLeave={(e) => {
                    returnPointTimer = setTimeout(() => {
                      returnPoint();
                    }, 500);
                  }}
                  className={selected === item.title ? 'itemActive' : null}
                >
                  <item.icon sx={{ marginRight: '20px', fontSize: '28px' }} />
                  <ItemTitle>{item.title}</ItemTitle>
                  {item.to === 'history' && chatNormalCnt > 0 && <NoReadCount className={'nomargin'}>N</NoReadCount>}
                  {item.to === 'intern' && chatInternCnt > 0 && <NoReadCount className={'nomargin'}>N</NoReadCount>}
                </MenuItem>
                {item.dropMenu && (
                  <SubMenu
                    className={selected === item.title ? 'activated' : ''}
                    style={{
                      height: selected === item.title ? item.dropMenu.length * 28 + 12 + 'px' : '0',
                    }}
                  >
                    {item.dropMenu.map((menu, index) => (
                      <SubMenuItem
                        key={menu}
                        to={`/manage/${item.to}/${item.subMenuLink[index]}`}
                        className={selectSubmenu === item.subMenuLink[index].split('/')[0] ? 'selected' : null}
                        onClick={() => {
                          setMenuOpen(false);
                          setSelectSubmenu(item.subMenuLink[index].split('/')[0]);
                        }}
                      >
                        <span>{menu}</span>
                        {item.to === 'history' && menu === '구인내역' && chatNormalCnt > 0 && <NoReadCount>{utils.format.number(chatNormalCnt)}</NoReadCount>}
                        {item.to === 'intern' && menu === '구인내역' && chatInternCnt > 0 && <NoReadCount>{utils.format.number(chatInternCnt)}</NoReadCount>}
                      </SubMenuItem>
                    ))}
                  </SubMenu>
                )}
              </DropDown>
            </MenuBtn>
          ))}
        </Menu>
      </Scrollbars>
      <Footer>
        <FooterTitle
          onClick={() => {
            setShowDetail(!showDetail);
          }}
        >
          (주)알파시스템
          <ExpandLessRoundedIcon
            sx={{
              transition: 'all 0.3s ease',
              transform: showDetail ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </FooterTitle>
        {showDetail && (
          <FooterBody>
            <FooterRow>
              <FooterLabel>대표</FooterLabel>
              <FooterContent>윤대건</FooterContent>
            </FooterRow>
            <FooterRow>
              <FooterLabel>고객센터</FooterLabel>
              <FooterContent>
                <div>1566-4921/010-8839-5073</div>
                <div>(상담시간:평일 10:00~18:00)</div>
              </FooterContent>
            </FooterRow>
            <FooterRow>
              <FooterLabel>사업자등록번호</FooterLabel>
              <FooterContent>609-87-01980</FooterContent>
            </FooterRow>
            <FooterRow>
              <FooterLabel>통신판매업신고번호</FooterLabel>
              <FooterContent>제2021-대구수성구-1494호</FooterContent>
            </FooterRow>
            <FooterRow>
              <FooterLabel>주소</FooterLabel>
              <FooterContent>대구광역시 수성구 알파시티1로 30, 8층 802호</FooterContent>
            </FooterRow>
            <FooterUl>
              <FooterLi>사이트에서 결제되는 상품에 대한 배송,환불,민원등의 책임은 (주)알파시스템에서 진행합니다.</FooterLi>
              <FooterLi>민원담당자: 우성현 및 010-8839-5073</FooterLi>
            </FooterUl>
          </FooterBody>
        )}
        <FooterBtns>
          <button
            onClick={() => {
              setLawGubn('5');
              setLawTitle('이용약관');
              setOpenLaw(true);
            }}
          >
            이용약관
          </button>
          <button
            onClick={() => {
              setLawGubn('1');
              setLawTitle('개인정보처리방침');
              setOpenLaw(true);
            }}
          >
            개인정보처리방침
          </button>
          <button
            onClick={() => {
              window.open('https://www.ftc.go.kr/www/biz/bizCommList.do?key=5375', '_blank');
            }}
          >
            사업자정보 확인
          </button>
        </FooterBtns>
        <CopyRight>Copyright(c) 2023 수달. All Rights Reserved.</CopyRight>
      </Footer>
      <QnAModal
        open={openQnA}
        close={() => {
          setOpenQnA(false);
        }}
        title="1:1 문의하기"
      />
      <ShowLawModal open={openLaw} close={() => setOpenLaw(false)} header={lawTitle} gubn={lawGubn} />
    </Side>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { ViewTable, TableContainer, Table, Thead, BorderTheadRow, Tbody, TbodyRow, Subject } from 'components/Parts/Table/TableElements';
import { BdWhiteText } from 'style';
import { Scrollbars } from 'react-custom-scrollbars-2';
import utils from 'modules/utils';
import { styled } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

const TheadCol = styled.th`
  width: ${(props) => props.width};
  height: 50px;
`;

const TbodyCol = styled.td`
  height: 50px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    height: 40px;
  }
`;
const NoticeTable = ({ theadTitles, setClickedItem, clickedItem }) => {
  const navigate = useNavigate();
  const { workDate, sqenNumb } = useParams();

  // 공지사항 목록 저장할 state
  const [notices, setNotices] = useState([]);
  // 공지사항 목록 조회
  const getNotices = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_m_Notice_S1/1`);
      //console.log(res.data);
      if (res.data.data.length > 0) {
        setNotices(res.data.data.slice(0, res.data.data.length));
        if (utils.isEmpty(clickedItem)) {
          let item = res.data.data.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
          if (item.length > 0) setClickedItem(item[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickItem = (value) => {
    setClickedItem(value);
  };
  useEffect(() => {
    if (utils.isEmpty(clickedItem)) {
      navigate(`/manage/news/notice`);
    } else {
      navigate(`/manage/news/notice/${clickedItem.workDate}/${clickedItem.sqenNumb}`);
    }
  }, [clickedItem]);
  useEffect(() => {
    getNotices();
  }, []);

  useEffect(() => {
    let item = notices.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
    if (item.length > 0) setClickedItem(item[0]);

    if (!workDate || !sqenNumb) setClickedItem(null);
  }, [workDate, sqenNumb]);

  return (
    <>
      {notices.length > 0 && (
        <ViewTable>
          <BdWhiteText>수달이 전해주는 다양한 소식을 확인해 주세요.</BdWhiteText>
          <Scrollbars
            className="scrollbar-container"
            style={{
              width: 'calc(100% + 60px)',
              height: 'calc(100% - 62px)',
              marginLeft: '-30px',
              borderBottom: '1px solid #ddd',
            }}
            renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
          >
            <TableContainer>
              <Table>
                <Thead>
                  <BorderTheadRow>
                    <TheadCol className="fixedHeader" width="13%">
                      <span className="th-border">{theadTitles[0]}</span>
                    </TheadCol>
                    <TheadCol className="fixedHeader" width="58%">
                      <span className="th-border">{theadTitles[1]}</span>
                    </TheadCol>
                    <TheadCol className="fixedHeader" width="29%">
                      <span className="th-border">{theadTitles[2]}</span>
                    </TheadCol>
                  </BorderTheadRow>
                </Thead>

                <Tbody>
                  {notices.map((row, index) => (
                    <TbodyRow style={{ fontSize: '20px' }} key={`notice-${row.sqenNumb}${row.workDate}`} index={index.toString()}>
                      <TbodyCol>{row.noti_auth_name}</TbodyCol>
                      <TbodyCol>
                        <Subject type="button" onClick={() => onClickItem(row)}>
                          {row.notiSubject}
                        </Subject>
                      </TbodyCol>
                      <TbodyCol>{row.ftDt}</TbodyCol>
                    </TbodyRow>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Scrollbars>
        </ViewTable>
      )}
    </>
  );
};

export default NoticeTable;

import SafePhoneNumb from 'components/Tooltip/SafePhoneNumb';
import chat from 'modules/chat';
import utils from 'modules/utils';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EstimateModal from '../../../../Parts/Common/Modal/EstimateModal';
import ProfileImage from '../../../../Parts/Common/ProfileImage';
import DetailContent from '../../../../Parts/Common/TabLists/DetailContent';
import Lists from '../../../../Parts/Common/TabLists/Lists';
import { ChatItem, ChatPreview, Container, NickName, SendBtn, SentDate } from '../../../../Parts/Common/TabLists/ViewListsElements';
import ChatRoom from '../ChatRoom/ChatRoom';

const TabChat = () => {
  const navigate = useNavigate();
  let { workDate, sqenNumb } = useParams();

  const [chatList, setChatList] = useState([]);
  const [chatRoom, setChatRoom] = useState({});
  const [chatSend, setChatSend] = useState(null);
  const [chatLeft, setChatLeft] = useState(false);

  const [openEstimate, setOpenEstimate] = useState(null);

  // 안심번호 툴팁
  const [open, setOpen] = useState(false);
  const [userPhone, setUserPhone] = useState('');

  const getUserPhone = (userId) => {
    if (!userId) return;
    const url = `/v1/getsafeNumber/${userId}`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data.success) {
        setUserPhone(res.data.data[0]?.safeNumber);
      }
    });
  };

  utils.event.on('pushChat', (pushWorkDate, pushSqenNumb, pushMessage) => {
    updateChatList();
  });

  const countChatList = function (chkWorkDate, chkSqenNumb, mode, message) {
    let updateCount = false;
    let updatedList = chatList.map((chat) => {
      if (chat.workDate === chkWorkDate && chat.sqenNumb === chkSqenNumb) {
        updateCount = true;
        if (mode === 'read') chat.rcdRead = 0;
      }
      return chat;
    });
    setChatList(updatedList);
    //채팅 카운팅 이벤트 발생
    utils.event.emit('loadChatCount', 'chat', updatedList);
    return updateCount;
  };

  const updateChatList = function () {
    utils.http.setMode('api');
    utils.http.get('/v1/SP_p_partChatList_S1').then(({ data }) => {
      if (data?.data.length > 0) {
        if (workDate && sqenNumb) {
          if (utils.isEmpty(chatRoom)) {
            let item = data?.data.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
            if (item.length > 0) setChatRoom(item[0]);
          } else {
            if (data.data[0].workDate === workDate && data.data[0].sqenNumb === sqenNumb) {
              data.data[0].rcdRead = 0;
            }
          }
        }
        setChatList(() => data.data);
        //채팅 카운팅 이벤트 발생
        utils.event.emit('loadChatCount', 'chat', data.data);
      } else {
        setChatList([]);
      }
    });
  };
  useEffect(() => {
    if (utils.isNotEmpty(chatRoom)) {
      navigate(`/manage/history/chat/${chatRoom.workDate}/${chatRoom.sqenNumb}`);
    }
  }, [chatRoom]);

  useEffect(() => {
    updateChatList();
    if (!workDate || !sqenNumb) setChatRoom({});
  }, []);

  useEffect(() => {
    let item = chatList.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
    if (item.length > 0) setChatRoom(item[0]);

    if (!workDate || !sqenNumb) setChatRoom({});
    chat.closeAll();
  }, [workDate, sqenNumb]);

  // 카카오 에드핏
  const scriptElement = useRef(null);
  useLayoutEffect(() => {
    setTimeout(() => {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://t1.daumcdn.net/kas/static/ba.min.js');
      script.setAttribute('charset', 'utf-8');

      script.setAttribute('async', 'true');
      scriptElement.current?.appendChild(script);
    }, 100);
  }, []);

  return (
    <Container>
      <Lists
        title={'견적상담'}
        empty={chatList.length === 0}
        emptyMessage={'고객과 주고 받은 채팅 내역이 없습니다.'}
        infoModalData={'고객과 대화하며 “견적 제출” 버튼을 눌러 견적 전달이 가능합니다.'}
        slotList={chatList.map((chat, idx) => (
          <ChatItem
            key={'chatlist' + chat.workDate + chat.sqenNumb}
            onClick={() => {
              setChatRoom(chat);
            }}
            className={chatRoom.workDate + chatRoom.sqenNumb === chat.workDate + chat.sqenNumb && 'selected'}
          >
            <section className={'profile-box'}>
              <ProfileImage width={'60px'} height={'60px'} kind={'user'} id={chat.userId} alwaysRefresh={true} partProfile={1} />
            </section>
            <NickName>{chat.partName}</NickName>
            <SentDate>{chat.ftDt}</SentDate>
            <ChatPreview>
              <section className={'preview'}>{chat.rcdContent}</section>
              <section className={'no-read-section'}>{chat.rcdRead > 0 && <div className={'no-read-count'}>{chat.rcdRead}</div>}</section>
            </ChatPreview>
          </ChatItem>
        ))}
      />
      {chatList.length > 0 && (
        <DetailContent
          title={
            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <div>{chatRoom.partName + '님과의 대화'}</div>
              {!chatLeft && <SafePhoneNumb userId={chatRoom.userId} />}
            </div>
          }
          adFitCode="DAN-EPSYxPYBqnnsBInH"
          userId={chatRoom.userId}
          empty={utils.isNull(chatRoom.workDate) || utils.isNull(chatRoom.sqenNumb)}
          emptyMessage={'채팅 목록에서 대화를 선택해 주세요.'}
          slotTitleRight={
            <div style={{ display: 'flex', gap: '8px' }}>
              <SendBtn
                onClick={() => {
                  const chatCode = chatRoom.workDate + chatRoom.sqenNumb;
                  const chatObj = { userId: utils.user('id'), message: '', Type: 'reviewRequest', headNumb: '' };
                  chat.send(chatCode, chatObj);
                }}
                disabled={chatLeft}
                style={{ backgroundColor: '#1B2335' }}
              >
                리뷰 요청
              </SendBtn>
              <SendBtn
                onClick={() => {
                  setOpenEstimate(true);
                }}
                disabled={chatLeft}
              >
                견적 제출
              </SendBtn>
            </div>
          }
          slotContent={
            <ChatRoom
              chatInfo={chatRoom}
              updateList={updateChatList}
              countChatList={countChatList}
              exitChat={() => {
                setChatRoom({});
                navigate(`/manage/history/chat`);
                updateChatList();
              }}
              sendChat={setChatSend}
              chatKind={'달인내역 채팅'}
              kind={'chat'}
              chatLeft={chatLeft}
              setChatLeft={setChatLeft}
            ></ChatRoom>
          }
        />
      )}
      <EstimateModal
        open={openEstimate}
        close={() => {
          setOpenEstimate(false);
        }}
        title="견적서 작성"
        nickName={chatRoom.partName}
        post={chatRoom}
        afterAction={(data) => {
          if (data.success) {
            utils.isFunction(chatSend?.chatEstimate) && chatSend.chatEstimate(data.data[0].headNumb);
          }
        }}
        kind="chat"
      />
    </Container>
  );
};

export default TabChat;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Side = styled.div`
  display: grid;
  position: relative;
  height: 100%;
  background-color: #10141c;
  justify-content: space-between;
  grid-template-columns: 1fr;
  grid-template-rows: auto 250px;

  @media all and (max-width: 1440px) {
    width: 300px;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  padding: 25px 25px 0;
  position: relative;
`;

export const MenuPoint = styled.div`
  position: absolute;
  width: 10px;
  height: 50px;
  right: 0;
  top: 65px;
  //border-radius: 10px;
  transition: all 0.3s;
  background-color: #7f6eff;
  border-radius: 10px 0 0 10px;
`;

export const MenuBtn = styled.button`
  outline: none;
  background: transparent;
  border: none;
  text-align: left;
  height: auto;
`;

export const DropDown = styled.div`
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
`;

export const SubMenu = styled.div`
  display: ${(props) => props.display};
  width: 211px;
  background-color: transparent;
  //border-radius: 10px;
  padding-left: 57px;
  overflow: hidden;
  transition: all 0.2s;

  &.activated {
    padding: 6px 0 6px 57px;
    background-color: #7e6eff;
    //transition: all 0.4s;
  }
`;

export const SubMenuItem = styled(Link)`
  font-size: 18px;
  font-weight: normal;
  color: #fff;
  height: 28px;
  line-height: 1;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  
  width: 100%;

  &:hover,
  &.selected {
    color: #10141c;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  width: 211px;
  height: 42px;
  padding: 0 25px;
  //border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: #7f6eff;
  }
  .icon {
    font-size: 18px;
    font-weight: 400;
    transform: scale(1.3);
    margin-right: 12px;
  }
`;

export const ItemTitle = styled.span`
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  transform: translateY(-1px);
`;

export const Footer = styled.div`
  padding: 0 10px;
  /* height: 165px;
  flex: 0 0 165px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const FooterTitle = styled.p`
  display: flex;
  align-items: center;
  color: #59ffe2;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
`;

export const QnABtn = styled.button`
  background: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
`;

export const FooterMenu = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  gap: 10px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  button,
  div {
    line-height: 1;
  }
`;

export const FooterBody = styled.div`
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const FooterRow = styled.div`
  display: flex;
  gap: 10px;
  font-size: 12px;
  margin-bottom: 4px;
  color: #fff;
`;

export const FooterLabel = styled.div`
  display: flex;
  min-width: 92px;
  gap: 10px;
  font-weight: normal;
  line-height: 1;
  color: #808080;
`;

export const FooterContent = styled.div`
  font-weight: normal;
  line-height: 1.2;
`;

export const CopyRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #808080;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  margin-top: 4px;
  margin-bottom: 10px;
`;

import React, { useEffect } from 'react';
import { OpenModalBg, Section, CloseBtn, LineHeaderText, Main, MainContent, Label } from './ModalElements';
import { TitleText, BdWhiteText } from 'style';
import utils from 'modules/utils';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomPassword from 'components/ControllerInput/CustomPassword';
import * as yup from 'yup';

const schema = yup.object().shape({
  newPw: yup
    .string()
    .required('신규 비밀번호를 입력해 주세요.')
    .matches(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/, '비밀번호는 영문자, 숫자, 특수문자를 조합한 최소 8자로 입력해 주세요.'),
  checkPw: yup
    .string()
    .required('신규 비밀번호를 입력해 주세요.')
    .oneOf([yup.ref('newPw'), null], '비밀번호가 일치하지 않습니다.'),
});

const modalStyle = {
  height: '628px',
  padding: '60px 70px',
  position: 'relative',
};

const InputPwModal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, id } = props;

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      newPw: '',
      checkPw: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const { newPw: pw, checkPw } = data;

    if (!utils.valid.isNullPassword(pw)) {
      utils.dialog.alert('비밀번호 입력!', '비밀번호를 입력해 주세요.', 'warning');
    } else if (!utils.valid.isNullPassword(checkPw)) {
      utils.dialog.alert('비밀번호 확인 입력!', '비밀번호 확인란에 비밀번호를<br />입력해 주세요.', 'warning');
    } else if (!utils.valid.isPasswordValid(pw)) {
      utils.dialog.alert('비밀번호 입력 확인!', '비밀번호는 영문자, 숫자, 특수문자를 조합한<br />최소 8자로 입력해 주세요.', 'warning');
    } else if (pw !== checkPw) {
      utils.dialog.alert('비밀번호 입력 확인!', '비밀번호 확인란이 일치하지 않습니다.', 'warning');
    } else {
      try {
        const data = {
          id,
          password: pw,
          newPassword: checkPw,
        };
        console.log(data);
        utils.http.setMode('api');
        const res = await utils.http.post('/v1/SP_m_searchUserpw_U1', null, data);

        if (res.data.data && res.data.success) {
          utils.dialog.alert('비밀번호 변경 성공!', '비밀번호가 정상적으로<br />변경되었습니다.', 'success').then(() => {
            close();
          });
        } else {
          utils.dialog.alert('비밀번호 변경 실패!', '오류가 발생했습니다. 고객센터로 문의해 주세요.', 'error');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  return (
    <>
      {open && (
        <OpenModalBg>
          <Section style={modalStyle}>
            <CloseBtn onClick={close} type="button" style={{ position: 'absolute' }}>
              <CloseRoundedIcon sx={{ color: '#fff', fontSize: '45px' }} />
            </CloseBtn>
            <LineHeaderText>비밀번호 찾기</LineHeaderText>
            <form style={{ width: '100%', height: 'calc(100% - 60px)' }} onSubmit={handleSubmit(onSubmit)} noValidate>
              <MainContent>
                <TitleText style={{ fontSize: '30px' }}>새로운 비밀번호를 입력해주세요.</TitleText>
                <div className="input-box">
                  <BdWhiteText>신규 비밀번호</BdWhiteText>
                  <CustomPassword name="newPw" required control={control} />
                </div>
                <div className="input-box" style={{ marginTop: '20px' }}>
                  <BdWhiteText>신규 비밀번호 확인</BdWhiteText>
                  <CustomPassword
                    name="checkPw"
                    control={control}
                    required
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit(onSubmit)(e);
                      }
                    }}
                  />
                  <button className="change-btn" type="submit">
                    변경하기
                  </button>
                </div>
              </MainContent>
            </form>
          </Section>
        </OpenModalBg>
      )}
    </>
  );
};

export default InputPwModal;

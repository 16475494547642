import React from "react";
import styled from "styled-components";
import utils from "modules/utils";
const ItemEach = styled.section`  
  margin-bottom: 5px;
  padding: 8px 30px !important;
  &:last-child{
    margin-bottom: 0;
  }
  table{
    width: 100%;
    border-spacing: 0 8px;
    border-collapse: separate;
    tr{
      th{
        vertical-align: top;
        width: 90px;
        .head-title{
          width: 100%;
          height: 30px;
          border-radius: 15px;
          background-color: #37465b;
          font-size: 17px;
          color: #fff;
          
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
        }
      }
      td{
        padding: 0 16px;

        font-size: 18px;
        text-align: left;
        color: #10141c;
        
        line-height: 1.1;
      }
      th, td{
      }
      &:last-child td, &:last-child th {
      }
    }
    
    
  }
`
const InternProfileItemBox = ({info}) => {
    return (
        <ItemEach className={"box-partition"}>
            <table>
                <tbody>
                {info.map(row=>(
                    <tr key={utils.toJSON(row)}>
                        <th><span className={"head-title"}>{row.title}</span></th>
                        <td>{row.content}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </ItemEach>
    );
};

export default InternProfileItemBox;

import styled from 'styled-components';
import utils from 'modules/utils';
import DaumPostcode from 'react-daum-postcode';
import { useEffect } from 'react';
import { OpenModal } from './ModalElements';

const FindAddressForm = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  .postmodal {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const UserBlockModal = ({ open, close, title, afterAction }) => {
  const complete = (data) => {
    utils.isFunction(afterAction) && afterAction(data.address, data.zonecode);
    utils.isFunction(close) && close();
  };
  useEffect(() => {
    if (open) {
    }
  }, [open]);
  return (
    <>
      {open && (
        <OpenModal
          onClick={() => {
            utils.isFunction(close) && close();
          }}
        >
          <FindAddressForm>
            <DaumPostcode
              style={{
                height: '600px',
                maxHeight: '90vh',
                width: '800px',
                overflow: 'hidden'
              }}
              className="postmodal"
              onComplete={complete}
            />
          </FindAddressForm>
        </OpenModal>
      )}
    </>
  );
};

export default UserBlockModal;

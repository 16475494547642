import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import utils from '../../../../modules/utils';
import { BdWhiteText } from '../../../../style';
import { Form, InfoMessage, InfoWrap, InputList, LightInput, SubmitBtn, WhiteDot } from './ModalElements';
import WindowFrame from './WindowFrame';

const removeComma = (str) => {
  return parseInt(str.replace(/,/g, ''));
};

const EmergencyModal = ({ open, close, title, post, afterAction }) => {
  const navigate = useNavigate();

  // 긴급 출동 input state
  const [inputCost, setInputCost] = useState('');
  const [inputTime, setInputTime] = useState('');

  // 긴급 출동 전송 버튼 클릭
  const onSubmitEmergency = async (e) => {
    e.preventDefault();
    if (inputCost === '' || removeComma(inputCost) === 0 || inputTime === '' || removeComma(inputTime) === 0) {
      utils.dialog.alert('전송 실패!', '입력되지 않은 내용이 있습니다.<br />긴급 출동 신청서를 다시 확인해 주세요.', 'warning');
      return;
    }
    console.log('inputCost', removeComma(inputCost));
    console.log('inputTime', removeComma(inputTime));
    if (removeComma(inputCost) < 10000) {
      utils.dialog.alert('전송 실패!', '출장비는 10,000원 이상으로 입력해 주세요.', 'warning');
      return;
    }
    if (removeComma(inputTime) < 10) {
      utils.dialog.alert('전송 실패!', '도착 예상시간은 10분 이상으로 입력해 주세요.', 'warning');
      return;
    }
    try {
      const url = `/v1/SP_p_partEmergencyView_i1`;
      const data = {
        mony: utils.format.extractNumber(inputCost),
        sqenNumb: post.sqenNumb,
        time: utils.format.extractNumber(inputTime),
        workDate: post.workDate,
      };
      console.log(data);
      utils.http.setMode('api');
      const res = await utils.http.post(url, null, data);

      if (res.data.success) {
        // 매칭 성공
        await utils.dialog.alert('출동 견적 전달!', '고객님의 결정을 기다리는 중입니다.', 'success');
      } else {
        // 매칭 실패
        await utils.dialog.alert('매칭 실패!', res.data.message, 'error');
      }
      utils.isFunction(close) && close();
      utils.isFunction(afterAction) && afterAction();
      navigate('/manage/history/emergencyList/sent');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (open) {
      setInputCost('');
      setInputTime('');
    }
  }, [open]);

  return (
    <WindowFrame open={open} close={close} title={title} width={'616px'} height={'500px'}>
      <Form action="#none">
        <InputList>
          <BdWhiteText style={{ display: 'block' }}>출장비</BdWhiteText>
          <LightInput
            type="text"
            onInput={(e) => {
              e.preventDefault();
              if (parseInt(e.target.value.replace(/,/g, '')) <= 50000) {
                setInputCost(utils.format.extractNumber(e.target.value, ['format']));
              } else if (e.target.value === '') {
                setInputCost('');
              } else if (parseInt(e.target.value.replace(/,/g, '')) > 50000) {
                setInputCost('50000');
              }
            }}
            value={inputCost}
            required
            placeholder="최소 10000 ~ 최대 50,000원"
            style={{ textAlign: 'right', fontSize: '20px' }}
          />
          <BdWhiteText style={{ display: 'block' }}>도착 예상시간</BdWhiteText>
          <LightInput
            type="text"
            onInput={(e) => {
              e.preventDefault();
              // setInputTime(utils.format.extractNumber(e.target.value));
              // 최대 60까지만 입력 가능
              if (parseInt(e.target.value.replace(/,/g, '')) <= 60) {
                setInputTime(utils.format.extractNumber(e.target.value, ['format']));
              } else if (e.target.value === '') {
                setInputTime('');
              } else if (parseInt(e.target.value.replace(/,/g, '')) > 60) {
                setInputTime('60');
              }
            }}
            value={inputTime}
            required
            placeholder="최소 10분 ~ 최대 60분"
            style={{ textAlign: 'right', fontSize: '20px' }}
          />
        </InputList>
        <InfoWrap>
          <InfoMessage>
            <WhiteDot />
            출장비와 추가 비용은 별도로 안내바랍니다.
          </InfoMessage>
          <InfoMessage>
            <WhiteDot />
            도착 예상시간은 최대한 맞춰주시길 바랍니다.
          </InfoMessage>
        </InfoWrap>
      </Form>
      <SubmitBtn type="button" onClick={onSubmitEmergency}>
        전송
      </SubmitBtn>
    </WindowFrame>
  );
};

export default EmergencyModal;

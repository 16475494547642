import React from 'react';
import { StyledLabel, StyledInput, StyledP } from './CheckBoxElements';

const SingleCheckBox = (props) => {
  const { text, name, checked, onChangeHandle } = props;
  return (
    <StyledLabel>
      <StyledInput type="checkbox" id={name} name={name} checked={checked} onChange={onChangeHandle} />
      <StyledP htmlFor={name}>{text}</StyledP>
    </StyledLabel>
  );
};

export default SingleCheckBox;

import { Divider, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import utils from 'modules/utils';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { TitleText } from 'style';
import { styled } from 'styled-components';
import { Form, SubmitBtn, TextArea } from './ModalElements';
import WindowFrame from './WindowFrame';

const QNA_CODE = '20';

const OutlineBtn = styled.button`
  width: 60px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid var(--violet-color);
  color: var(--violet-color);
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
  &:hover {
    border: 1px solid var(--violet-dark-color);
    color: var(--violet-dark-color);
  }
`;

const ContainedBtn = styled.button`
  width: 60px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--violet-color);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  &:hover {
    background-color: var(--violet-dark-color);
  }
`;

const SubTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const SubGrayTitle = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  color: #8a8a8a;
`;

const FavoritePhraseModal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, title, setCustomText } = props;

  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  const [radioList, setRadioList] = React.useState([]);

  const [value, setValue] = React.useState(null);
  const [favoriteText, setFavoriteText] = React.useState('');
  const partId = utils.user('id') ?? '';

  const handleChange = (event) => {
    // event.target.value = work_date + '_//' + sqen_numb
    // radioList에서 work_date와 sqen_numb가 같은 것을 찾아서 setValue에 넣어준다.

    const work_date = event.target.value.split('_//')[0];
    const sqen_numb = event.target.value.split('_//')[1];
    const findItem = radioList.find((item) => item.work_date === work_date && item.sqen_numb === sqen_numb);
    console.log('findItem', findItem);
    setValue(findItem);
  };

  const navigate = useNavigate();

  const getFavoritePhrase = () => {
    const url = `/v2/SP_r_partnerUseWord_s1/${partId}`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data.data.length > 0) {
        console.log(res.data.data);
        setRadioList(res.data.data);
      } else {
        setRadioList([]);
      }
    });
  };

  const appendFavoritePhrase = () => {
    if (utils.isEmpty(favoriteText)) {
      utils.dialog.alert('등록할 문구를 입력해 주세요.', '', 'warning');
      return;
    }

    const url = `/v2/SP_r_partnerUseWord_I1`;
    const dataObj = {
      partId: partId,
      content: favoriteText,
    };
    utils.http.setMode('api');
    utils.http.post(url, null, dataObj).then((res) => {
      if (res.data.success) {
        utils.dialog.alert('등록되었습니다.', '', 'success');
        getFavoritePhrase();
        setFavoriteText('');
      } else {
        utils.dialog.alert('등록에 실패하였습니다.', '', 'warning');
      }
    });
  };

  const deleteFavoritePhrase = () => {
    if (utils.isEmpty(value)) {
      utils.dialog.alert('삭제할 문구를 선택해 주세요.', '삭제할 문구를 선택해 주세요.', 'warning');
      return;
    }
    const url = `/v2/SP_r_partnerUseWord_D1`;
    const dataObj = {
      workDate: value.work_date,
      sqenNumb: value.sqen_numb,
    };
    utils.http.setMode('api');
    utils.http.post(url, null, dataObj).then((res) => {
      if (res.data.success) {
        utils.dialog.alert('삭제되었습니다.', '', 'success');
        setValue(null);
        getFavoritePhrase();
      } else {
        utils.dialog.alert('삭제에 실패하였습니다.', '', 'warning');
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // value가 비어있으면 utils.dialog.alert 띄우기
    if (utils.isEmpty(value)) {
      utils.dialog.alert('문구를 선택해 주세요.', '문구를 선택해 주세요.', 'warning');
      return;
    }
    setCustomText(value.word);
    close();
  };

  useEffect(() => {
    open && getFavoritePhrase();
  }, [open]);

  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'866px'}
      height={'700px'}
      slotFixedButton={
        <SubmitBtn style={{ height: '45px', padding: '0' }} type="button" onClick={onSubmit}>
          확인
        </SubmitBtn>
      }
    >
      <Form style={{ gap: 0 }}>
        <TitleText style={{ fontWeight: 'normal' }}>
          <strong style={{ color: 'var(--mint-color)' }}>자주 쓰는 문구</strong>를 사용해 주세요.
        </TitleText>
        <div className="qna-scroll-area" style={{ height: 'auto' }}>
          <div>
            <SubTitle>문구내용</SubTitle>
            <SubGrayTitle>자주 쓰는 문구를 등록해 주세요.</SubGrayTitle>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <TextArea
                placeholder={`취소사유를 직접 입력해 주세요.`}
                style={{
                  height: '188px',
                  fontSize: isTablet ? '16px' : '18px',
                  padding: '15px 14px',
                  borderRadius: '5px',
                }}
                value={favoriteText}
                onChange={(e) => setFavoriteText(e.target.value)}
              />
              <div style={{ marginTop: '10px' }}>
                <OutlineBtn type="button" onClick={() => setFavoriteText('')}>
                  취소
                </OutlineBtn>
                <ContainedBtn type="button" onClick={appendFavoritePhrase}>
                  등록
                </ContainedBtn>
              </div>
            </div>
          </div>
          <Divider sx={{ backgroundColor: '#fff', margin: '20px 0' }} />
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <div>
                <SubTitle>문구목록</SubTitle>
                <SubGrayTitle>사용할 문구를 선택해 주세요.</SubGrayTitle>
              </div>
              {utils.isNotEmpty(value) && (
                <button
                  style={{
                    color: 'var(--mint-color)',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}
                  type="button"
                  onClick={deleteFavoritePhrase}
                >
                  삭제
                </button>
              )}
            </div>
            {radioList.length === 0 && (
              <div
                style={{
                  color: '#fff',
                  textAlign: 'center',
                  backgroundColor: '#212838',
                  padding: '10px',
                  borderRadius: '4px',
                }}
              >
                <SubTitle style={{ fontSize: '16px', marginBottom: '4px' }}>등록된 문구가 없습니다.</SubTitle>
                <SubGrayTitle style={{ fontSize: '16px', marginBottom: '0' }}>상단 문구 내용에 글을 작성하고</SubGrayTitle>
                <SubGrayTitle style={{ fontSize: '16px', marginBottom: '0' }}>[등록] 버튼을 눌러주세요.</SubGrayTitle>
              </div>
            )}
            <FormControl>
              <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
                {radioList.map((item, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={item.work_date + '_//' + item.sqen_numb}
                      checked={value === item}
                      control={
                        <Radio
                          sx={{
                            color: '#fff',
                            '&.Mui-checked': {
                              color: '#7e6eff',
                            },
                          }}
                        />
                      }
                      label={item.word}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Form>
    </WindowFrame>
  );
};

export default FavoritePhraseModal;

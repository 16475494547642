import MultiImageUpload from 'components/FileUpload/MultiImageUpload';
import CustomMuiSelect from 'components/Parts/Common/CustomSelect/CustomMuiSelect';
import { imageToImageArr2 } from 'modules/Upload/imageUpload';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { BdWhiteText, TitleText } from 'style';
import { styled } from 'styled-components';
import DateInput from '../DateInput/DateInput';
import SingleCheckBox from '../forms/CheckBox/SingleCheckBox';
import FindAddressModal from './FindAddressModal';
import { Form, InputList, SubmitBtn, TemrsBox, TermsText } from './ModalElements';
import WindowFrame from './WindowFrame';

const customStyle = {
  selectOptions: { width: '100%', top: '38px' },
  selectBox: {
    width: 'calc(100% - 99px)'
  }
};

const List = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .bd-white-text {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    width: 163px;
    flex: 0 0 163px;
    height: 36px;
    align-self: flex-start;
  }

  .part-pay-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 10px;
  }

  .date-box {
    width: 286px;
    height: 36px;
    font-size: 18px;
    font-weight: normal;
    border-radius: 5px;
    overflow: hidden;
    .react-datepicker {
      transform: translateX(-25px);
    }
  }

  .address-box {
    width: calc(100% - 163px);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .zip-code-box {
      display: flex;
      align-items: center;
      gap: 11px;
    }
    .search-btn {
      width: 128px;
      height: 36px;
      border-radius: 5px;
      background-color: #7e6eff;
      color: #fff;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
    }
  }
`;
const FormJob = styled(Form)`
  .jobposting-modal-input {
    width: 100%;
    background: #fff;
    height: 36px;
    font-size: 20px;
    color: #10141c;
    border: solid 1px #8a8a8a;
    border-radius: 5px;
    padding: 0 17px 3px;
    line-height: 1;
    &.input-frame {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &.input-frame > input {
      font-size: 20px;
      line-height: 1;
      color: #10141c;
      height: 100%;
      flex: 1 1 100%;
    }
    &.input-frame > div {
      flex: 0 0 auto;
    }
    &.input-frame > input.number {
      text-align: right;
    }
    &::placeholder,
    &.input-frame > input::placeholder {
      color: #8a8a8a;
      font-size: 20px;
      font-weight: normal;
      text-align: left;
    }

    &:focus::placeholder,
    &.input-frame > input:focus::placeholder {
      visibility: hidden;
    }
  }
  .uploader {
    label {
      background-color: #212838;
      color: #fff;
      border: none;
      img {
        filter: brightness(100000%);
      }
    }
  }
`;

const moneyToNumber = (money) => {
  // 콤마(,) 제거 후 숫자로 변환
  return parseInt(money.replace(/,/g, ''));
};

const imageServer = process.env.REACT_APP_IMAGE_VIEW_URL;

// date 1달 더하기
const addMonth = (date) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + 1);
  return newDate;
};

const JobPostingModal = ({ open, close, title, workDate, sqenNumb, postData, images, afterSubmit }) => {
  const [cateOption, setCateOption] = useState([]);
  const [selected, setSelected] = useState('');
  const [isCommunicating, setIsCommunicating] = useState(false);

  //화면에 출력되는 이미지
  const [showImages, setShowImages] = useState([]);
  const [delKey, setDelKey] = useState([]);

  const [openPostModal, setOpenPostModal] = useState(false);

  //약관
  const [law, setLaw] = useState('');
  const getLaw = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_m_law_s1/1`);
      if (res.data.data) {
        setLaw(res.data.data[0].content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 개인정보 수집/동의 체크박스
  const [isChecked, setIsChecked] = useState(false);
  const onChangeChecked = () => {
    setIsChecked(!isChecked);
  };

  const isModifing = function () {
    return !(utils.isEmpty(postData) || utils.isEmpty(workDate) || utils.isEmpty(sqenNumb));
  };

  const saveAction = async function () {
    if (isCommunicating) return;
    const cateGubn = cateOption?.find((item) => item.cateId === selected)?.cateGubn;

    if (postingData.end_dt < postingData.start_dt) {
      await utils.dialog.alert('일자 확인 필요', '시작일이 종료일보다 늦을수는 없습니다.', 'error');
    } else if (postingData.mony_gubn === 0 && utils.isEmpty(postingData.mony)) {
      await utils.dialog.alert('급여 확인 필요', '급여가 입력되지 않았습니다.', 'error');
    } else if (postingData.mony_gubn === 0 && moneyToNumber(postingData.mony) === 0) {
      await utils.dialog.alert('급여 확인 필요', '급여는 0보다 높은 금액을 입력해주세요', 'error');
    } else if (utils.isEmpty(selected)) {
      await utils.dialog.alert('카테고리 확인 필요', '카테고리가 선택되지 않았습니다.', 'error');
    } else if (utils.isEmpty(postingData.skill)) {
      await utils.dialog.alert('필요기술 확인 필요', '필요기술이 입력되지 않았습니다.', 'error');
    } else if (utils.isEmpty(postingData.addr1) || utils.isEmpty(postingData.addr2)) {
      await utils.dialog.alert('주소 확인 필요', '주소가 입력되지 않았습니다.', 'error');
    } else if (utils.isEmpty(postingData.subject?.trim())) {
      await utils.dialog.alert('구인 정보 제목', '제목이 입력되지 않았습니다.', 'error');
    } else if (utils.isEmpty(postingData.content?.trim())) {
      await utils.dialog.alert('구인 정보 내용', '내용이 입력되지 않았습니다.', 'error');
    } else if (!isChecked) {
      await utils.dialog.alert('개인정보 수집 및 이용 미동의', '개인정보 수집 및 이용 동의가<br />체크되어 있지 않습니다.', 'warning');
    } else {
      if (await utils.dialog.confirm(title, '구인 정보를 ' + (isModifing() ? '수정' : '등록') + ' 하시겠습니까?', 'question')) {
        try {
          const formData = new FormData();

          if (postingData.mony_gubn === 1) postingData.mony = '0';

          for (let item in postingData) {
            if (item === 'category') {
              formData.append(item, cateGubn);
            } else if (item === 'category2') {
              formData.append(item, selected);
            } else {
              formData.append(item, postingData[item]);
            }
          }

          formData.append('work_date', workDate);
          formData.append('sqen_numb', sqenNumb);
          // 이미지 파일
          showImages.forEach((image) => {
            if (image.localFlag) {
              formData.append('files', image.file);
            }
          });
          formData.append('imageCount', showImages.length);
          let delKeyStr = '';
          delKey.forEach((image) => {
            delKeyStr += image.serverFileName + '_//';
          });
          formData.append('delKey', delKeyStr);
          setIsCommunicating(true);
          utils.http.setMode('api');
          const res = await utils.http.postFile('/v1/SP_I_internJobPosting_i1', formData);
          if (res.data.success && res.data.message === '성공') {
            setIsCommunicating(false);
            utils.dialog.alert('저장 완료!', '입력하신 내용이 정상적으로<br />저장되었습니다.', 'success').then(() => {
              utils.isFunction(close) && close();
              utils.isFunction(afterSubmit) && afterSubmit(res.data.data[0].workDate, res.data.data[0].sqenNumb);
            });
          } else if (res.data.message) {
            setIsCommunicating(false);
            utils.dialog.alert('등록 실패!', res.data.message, 'error');
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  if (!isModifing()) {
    postData = {};
    workDate = '';
    sqenNumb = '';
  }
  // selected 와 cateId 같은 객체의 cateGubn
  const cateGubn = cateOption?.find((item) => item.cateId === selected)?.cateGubn;

  const defaultData = {
    category: cateGubn ?? '',
    category2: selected ?? '',
    start_dt: utils.date.today(),
    end_dt: utils.date.today(),
    mony: '',
    mony_gubn: 0,
    skill: '',
    addr1: '',
    addr2: '',
    addr3: '',
    addr_gubn: 0,

    subject: '',
    content: ''
  };

  const [postingData, setPostingData] = useState(defaultData);

  const openFindAddress = () => {
    setOpenPostModal(!postingData.addr_gubn && true);
  };
  const closeFindAddress = () => {
    setOpenPostModal(false);
  };
  //v1/SP_p_partner_S1/${utils.user('id')}
  const [defaultAddr, setDefaultAddr] = useState({});
  const getDefaultAddr = function () {
    utils.http.setMode('api');
    utils.http.get(`/v1/SP_p_partner_S1/${utils.user('id')}`).then(({ data }) => {
      if (data?.data.length > 0) {
        setDefaultAddr({
          addr1: data.data[0].partAddr1,
          addr2: data.data[0].partAddr2,
          addr3: data.data[0].partAddr3
        });
      } else {
        setDefaultAddr({
          addr1: '',
          addr2: '',
          addr3: ''
        });
      }
    });
  };

  const getCategory = () => {
    const url = `/v1/SP_b_Category_s1`;
    const data = {
      category: '',
      gubn: 'allCate'
    };

    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      if (res.data.success) {
        res.data.data.forEach((item) => {
          item.gubn = item.cateGubn;
          item.value = item.cateId;
          item.label = item.cateName;
        });
        setCateOption(res.data.data);
      }
    });
  };

  useEffect(() => {
    if (open) {
      if (utils.isEmpty(postData) === false) {
        setPostingData({
          category: postData.jobCategory,
          category2: postData.jobCategory2,
          start_dt: postData.jobStartDate,
          end_dt: postData.jobEndDate,
          mony: isNaN(postData.jobMony) ? '' : postData.jobMony,
          mony_gubn: isNaN(postData.jobMony) ? 1 : 0,
          skill: postData.jobSkill,
          addr1: postData.jobAddr1,
          addr2: postData.jobAddr2,
          addr3: postData.jobAddr3,
          addr_gubn: postData.jobAddrGubn,
          subject: postData.job_subject,
          content: postData.job_content
        });
        setSelected(postData.jobCategory2);
        setShowImages(imageToImageArr2(images));
      } else {
        setSelected('');
        setPostingData(defaultData);
        setShowImages([]);
      }
      getDefaultAddr();
      setDelKey([]);
      setIsChecked(false);
    }
  }, [open]);

  useEffect(() => {
    getLaw();
    getCategory();
  }, []);

  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'867px'}
      height={'805px'}
      slotFixedButton={
        <SubmitBtn type="button" onClick={saveAction} disabled={!isChecked}>
          {isModifing() ? '수정' : '구인등록'}
        </SubmitBtn>
      }
    >
      <FormJob action="#none" style={{ height: '629px' }}>
        <List>
          <div className="bd-white-text ">
            <span>카테고리</span>
          </div>
          <CustomMuiSelect selected={selected} setSelected={setSelected} label="카테고리를 선택해 주세요." optionList={cateOption} />
        </List>
        <InputList>
          <BdWhiteText>사진</BdWhiteText>
          {/* <Uploader maxImage={5} showImages={showImages} setShowImages={setShowImages} setFile={setFile} file={file} setDelKey={setDelKey} /> */}
          <MultiImageUpload accept={'image/jpg, image/jpeg, image/png'} name="qnaModal" imageArr={showImages} setImageArr={setShowImages} setDeleteImageArr={setDelKey} isWhite={true} maxImage={5} width={'80px'} height={'80px'} />
          <div
            style={{
              fontSize: '14px',
              color: '#fff'
            }}
          >
            <div>※ 이미지: 20MB이하의 JPG, JPEG, PNG 파일 등록 가능</div>
            <div>※ 최대 5장 등록 가능합니다.</div>
          </div>
        </InputList>
        <List>
          <div className="bd-white-text ">
            <span>필요기술</span>
          </div>
          <input
            className="jobposting-modal-input"
            type="text"
            onInput={(e) => {
              setPostingData((postingData) => Object.assign({}, postingData, { skill: e.target.value }));
            }}
            value={postingData.skill ?? ''}
            placeholder="필요 기술을 작성해 주세요 (40글자 이내)"
            maxLength={40}
          />
        </List>
        <List>
          <div className="bd-white-text ">
            <span>급여</span>
          </div>
          <section className={'part-pay-box'}>
            <div className="jobposting-modal-input input-frame">
              {postingData.mony_gubn === 0 ? (
                <>
                  <input
                    className={'number'}
                    type="text"
                    onInput={(e) => {
                      e.preventDefault();

                      setPostingData((postingData) =>
                        Object.assign({}, postingData, {
                          mony: utils.format.extractNumber(e.target.value)
                        })
                      );
                    }}
                    maxLength={11}
                    value={utils.format.extractNumber(postingData.mony, ['format'])}
                    placeholder="금액을 입력해 주세요."
                  />
                  <div>원</div>
                </>
              ) : (
                <div>협의 후 결정</div>
              )}
            </div>

            <SingleCheckBox
              text="협의 후 결정"
              name="salary"
              checked={postingData.mony_gubn === 1}
              onChangeHandle={(e) => {
                setPostingData({
                  ...postingData,
                  mony_gubn: e.target.checked ? 1 : 0
                });
              }}
            />
          </section>
        </List>
        <List>
          <div className="bd-white-text ">
            <span>근무일자</span>
          </div>
          <div className="date-box">
            <DateInput
              dateFormat="yyyy.MM.dd"
              value={utils.date.toDate(postingData.start_dt)}
              onChange={(date) => {
                setPostingData((postingData) =>
                  Object.assign({}, postingData, {
                    start_dt: utils.format.date_std(date),
                    end_dt: utils.format.date_std(date)
                  })
                );
              }}
              minDate={utils.date.toDate(utils.date.today())}
              maxDate={addMonth(utils.date.today())}
            />
          </div>
          <TitleText style={{ width: '30px', textAlign: 'center' }}>~</TitleText>
          <div className="date-box">
            <DateInput
              dateFormat="yyyy.MM.dd"
              value={utils.date.toDate(postingData.end_dt)}
              onChange={(date) => {
                setPostingData((postingData) =>
                  Object.assign({}, postingData, {
                    end_dt: utils.format.date_std(date)
                  })
                );
              }}
              minDate={utils.date.toDate(postingData.start_dt)}
              maxDate={addMonth(utils.date.toDate(postingData.start_dt))}
            />
          </div>
        </List>
        <List>
          <div className="bd-white-text ">
            <span>근무장소</span>
          </div>
          <div className="address-box">
            <div className={'zip-code-box'}>
              <input className="jobposting-modal-input" type="text" style={{ width: 'calc(100% - 139px)' }} readOnly={true} onClick={openFindAddress} placeholder="우편번호" value={postingData.addr1 ?? ''} />
              <button className="search-btn" type={'button'} onClick={openFindAddress}>
                주소 검색
              </button>
            </div>
            <input className="jobposting-modal-input" type="text" readOnly={true} onClick={openFindAddress} placeholder="주소" value={postingData.addr2 ?? ''} />
            <input
              className="jobposting-modal-input"
              type="text"
              placeholder="상세 주소"
              readOnly={postingData.addr_gubn === 1}
              value={postingData.addr3 ?? ''}
              onInput={(e) => {
                console.log(postingData);
                setPostingData((postingData) => Object.assign({}, postingData, { addr3: e.target.value }));
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SingleCheckBox
                text="달인 등록 주소와 동일"
                name="addressCheck"
                checked={postingData.addr_gubn === 1}
                onChangeHandle={(e) => {
                  setPostingData((postingData) => {
                    postingData.addr_gubn = e.target.checked ? 1 : 0;
                    console.log(postingData, defaultAddr);
                    if (postingData.addr_gubn) {
                      return { ...postingData, ...defaultAddr };
                    } else {
                      return {
                        ...postingData,
                        addr1: '',
                        addr2: '',
                        addr3: ''
                      };
                    }
                  });
                }}
              />
            </div>
          </div>
        </List>
        <InputList>
          <BdWhiteText>제목</BdWhiteText>
          <input
            className="jobposting-modal-input"
            type="text"
            onInput={(e) => {
              setPostingData((postingData) => Object.assign({}, postingData, { subject: e.target.value }));
            }}
            value={postingData.subject ?? ''}
            placeholder="제목을 입력해 주세요."
            maxLength={40}
          />
        </InputList>
        <InputList>
          <BdWhiteText>내용</BdWhiteText>
          <div className={'custom-scroll-text-area'} style={{ height: '160px' }}>
            <textarea
              placeholder={`구인내용을 상세하게 작성해 주세요!`}
              onInput={(e) => {
                setPostingData((postingData) => Object.assign({}, postingData, { content: e.target.value }));
              }}
              value={postingData.content ?? ''}
            ></textarea>
          </div>
        </InputList>

        <InputList>
          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">개인정보 수집 및 이용 동의서</BdWhiteText>
            <TemrsBox>
              <Scrollbars className="scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
                <TermsText>{law}</TermsText>
              </Scrollbars>
            </TemrsBox>
          </div>
          <div className="qna-checkBox">
            <SingleCheckBox text="(필수)개인정보 수집 및 이용에 동의합니다." name="qna-checkBox" checked={isChecked} onChangeHandle={onChangeChecked} />
          </div>
        </InputList>
      </FormJob>
      <FindAddressModal
        open={openPostModal}
        close={closeFindAddress}
        title={'주소 검색'}
        afterAction={(address, zonecode) => {
          setPostingData({ ...postingData, addr1: zonecode, addr2: address });
        }}
      />
    </WindowFrame>
  );
};

export default JobPostingModal;

let navigate
let route = {
    setNavigate(useNav) {
        navigate = useNav;
    },
    navigate(route) {
        navigate(route)
    },
};
Object.freeze(route);
export default route;

import React, { useState, useRef, useEffect } from 'react';
import { SelectBox, Label, SelectOptions, Option } from './CustomSelectElements';
import { Scrollbars } from 'react-custom-scrollbars-2';

const CustomSelect = (props) => {
  const { options, placeHolder, setSelected, customStyle, choiced } = props;
  const [currentValue, setCurrentValue] = useState(placeHolder);
  const [showOptions, setShowOptions] = useState(false);
  const handleOnChangeSelectValue = (e) => {
    const { innerText } = e.target;
    setCurrentValue(innerText);
  };
  // select ref
  const selectRef = useRef();
  const handleClickOutside = (e) => {
    if (selectRef && !selectRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOptions]);

  useEffect(() => {
    if (placeHolder === undefined) {
      setCurrentValue(choiced || options[0].label);
    }
  }, []);

  return (
    <SelectBox onClick={() => setShowOptions((prev) => !prev)} ref={selectRef} style={customStyle && customStyle.selectBox}>
      <Label color={isSelected.toString()} style={customStyle.label && customStyle.label}>
        {currentValue}
      </Label>
      <SelectOptions show={showOptions.toString()} style={customStyle && customStyle.selectOptions} border={showOptions ? '1px solid #8a8a8a' : 'none'}>
        <Scrollbars className="scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
          {options.map((option, index) => (
            <Option
              key={`select-${option.label}${index}`}
              onClick={(e) => {
                handleOnChangeSelectValue(e);
                setSelected(option.value);
                console.log(option.value);
                setIsSelected(true);
              }}
              value={option.value}
            >
              {option.label}
            </Option>
          ))}
        </Scrollbars>
      </SelectOptions>
    </SelectBox>
  );
};
export default CustomSelect;

import styled from 'styled-components';
import arrowUpIcon from 'assets/arrowUp.png';
import arrowDownIcon from 'assets/arrowDown.png';

export const QnATable = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #37465b;
  border-radius: 20px;
  padding: 26px 0 30px 0;
`;

export const TableHeader = styled.div`
  width: 100%;
  padding: 0 30px 30px 30px;

  @media screen and (max-width: 1024px) {
    padding: 0 30px 20px 30px;
  }
`;

export const TableBody = styled.div`
  width: 100%;
  height: calc(100% - 122px);
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
`;

export const ArrowIcon = styled.button`
  width: 24px;
  height: 14px;
  margin-top: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s;
  background-image: url(${arrowDownIcon});

  @media screen and (max-width: 1024px) {
    background-size: 16px;
    background-repeat: no-repeat;
  }
`;

export const Detail = styled.details`
  background-color: #212838;
  width: 100%;

  &:nth-child(odd) {
    background-color: transparent;
  }

  &:nth-child(even) {
    background-color: #212838;
  }
  &[open] ${ArrowIcon} {
    background-image: url(${arrowUpIcon});
  }
`;

export const Summary = styled.summary`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
  height: 50px;
  font-size: 22px;
  font-weight: normal;
  color: #fff;
  cursor: pointer;

  &::marker {
    font-size: 0;
  }

  @media screen and (max-width: 1024px) {
    height: 40px;
    font-size: 16px;
  }
`;

export const StatBtn = styled.button`
  width: 90px;
  height: 25px;
  padding: 0 10px;
  border-radius: 20px;
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
  margin-right: 15px;
  &.stat-waiting {
    background-color: #ddd;
    color: #10141c;
  }
  &.stat-complete {
    background-color: #7e6eff;
    color: #fff;
  }

  @media screen and (max-width: 1024px) {
    width: 80px;
    height: 20px;
    font-size: 14px;
  }
`;

export const Wrap = styled.div`
  float: right;
  height: 34px;
  width: auto;
  display: flex;
  align-items: center;
  gap: 30px;
`;
export const DateBox = styled.span`
  color: #fff;
  font-size: 18px;
  font-weight: normal;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const AnswerWrap = styled.div`
  width: 100%;
  background-color: #37465b;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-top: 1px solid #ddd;
`;

export const Content = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: normal;
  color: #fff;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const ContentImage = styled.div`
  margin: 15px 0;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  grid-auto-rows: 60px;
  gap: 15px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export const Answer = styled.div`
  border-radius: 10px;
  background-color: #fff;
  padding: 30px;

  .answer-wrap {
    font-size: 20px;
    font-weight: normal;
    color: #10141c;
    white-space: pre-line;
  }

  @media screen and (max-width: 1024px) {
    padding: 20px;
    .answer-wrap {
      font-size: 16px;
    }
  }
`;

export const NoneAnswer = styled.p`
  font-size: 20px;
  font-weight: normal;
  color: #8a8a8a;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const NoneResult = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  font-size: 230px;
  color: #d8d8d8;
`;

export const NoneMessage = styled.p`
  color: #d8d8d8;
  font-size: 25px;
`;

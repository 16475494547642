import utils from 'modules/utils';
import { useEffect, useState } from 'react';

import styled from 'styled-components';
import Lists from '../../../Parts/Common/TabLists/Lists';
import { Container, Contents, Item, NickName, SentDate, TextWrap } from '../../../Parts/Common/TabLists/ViewListsElements';

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import ReviewModal from 'components/Parts/Common/Modal/ReviewModal';
import Rating from '../../../Parts/Common/Rating/Rating';
import ResultTop from '../../../Parts/Common/ResultTop';
import DetailContent from '../../../Parts/Common/TabLists/DetailContent';
import GrabImageList from '../../../Parts/Common/Uploader/GrabImageList';
import ProfileImage from './../../../Parts/Common/ProfileImage';
import { WriteButton } from './../../News/QuestionList/Results';
const ReviewBase = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  > * {
    flex: 0 0 auto;
  }
  .review-section {
    height: 100%;
    width: 100%;
    flex: 1 1 100%;

    .review-item {
      display: grid;
      padding: 16px 28px 16px 16px;
      gap: 19px;
      grid-template-columns: 80px calc(100% - 109px);
      position: relative;
      .review-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        div {
          line-height: 1;
        }
        .nick {
          margin-bottom: 9px;
          flex-shrink: 0;
        }
        .content {
          flex-shrink: 0;
        }
        .bottom {
          margin-top: 10px;
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      & > svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 24px;
        transition: 0.5s;
        color: #fff;
      }
      &:hover > svg,
      &.selected > svg {
        color: #5affe2;
      }
    }
  }
  .view-container {
    .view-header {
      display: grid;
      grid-template-columns: 80px auto 110px;
      padding: 18px 30px;
      gap: 11px;
      border-bottom: solid 1px #10141c;
      .profile-box {
        width: 80px;
        height: 80px;
      }
      .review-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .nick {
          font-size: 22px;
          color: #10141c;
          line-height: 1;
          margin-bottom: 8px;
        }
      }
      .review-date {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #10141c;
        font-size: 20px;
      }
    }
    .view-main {
      padding: 30px;
      .review-content {
        color: #10141c;
        font-size: 22px;
      }
      .review-images {
        margin-top: 30px;
      }
    }
    .view-footer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      border-top: solid 1px #10141c;
      padding: 30px;
      .user-nick {
        font-size: 16px;
        font-weight: bold;
      }
      .comment-text {
        font-size: 16px;
        color: #10141c;
      }
    }
  }
`;

const Review = () => {
  const [reviewList, setReviewList] = useState([]);
  const [reviewView, setReviewView] = useState({});
  const [reviewImages, setReviewImages] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [chatMsg, setChatMsg] = useState('');
  const [reviewModalOpen, setReviewModalOpen] = useState(false);

  const [prevReview, setPrevReview] = useState({
    workDate: '',
    sqenNumb: '',
  });

  const modalStyle = {
    width: '501px',
    height: 'auto',
  };
  let infoModalData = '수리가 끝났다면, 고객에게 후기를 요청해 보세요! 고객이 작성한 후기글 확인이 가능합니다.';

  let orderList = ['최신순', '평점순'];
  const [order, setOrder] = useState(orderList[0]);

  const updateReviewList = function () {
    console.log('order!!!!!!!!!!!!!!!', order);

    const conversionOrderList = order === '최신순' ? 0 : 1;

    utils.http.setMode('api');
    utils.http.get(`/v1/SP_p_partnerReview_S2/${utils.user('id')}/0/${conversionOrderList}`).then(({ data }) => {
      console.log('@@@@', data?.data);
      setReviewCount(data?.data[0]?.myReviewCount ?? 0);
      setReviewList(() => data?.data);
    });
  };
  const updateImagesList = function (review) {
    const images = [];
    const imgList = Object.keys(review).filter((x) => x.startsWith('file'));
    for (let i = 1; i <= imgList.length; i++) {
      images.push(review['file' + i]);
    }
    setReviewImages(images);
  };

  const sendMsg = () => {
    const url = `/v2/SP_p_partnerReviewAnswer_I1`;
    const data = {
      workDate: reviewView.workDate,
      sqenNumb: reviewView.sqenNumb,
      partId: utils.user('id'),
      partaText: chatMsg,
    };

    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      if (res.data.success) {
        setChatMsg('');
        setPrevReview({
          workDate: reviewView.workDate,
          sqenNumb: reviewView.sqenNumb,
        });
        updateReviewList();
      } else {
        utils.dialog.alert('댓글 게시 실패!', res.data.message, 'warning');
        setChatMsg('');
        updateReviewList();
      }
    });
  };

  const handleDeleteComment = () => {
    const url = `/v2/SP_p_partnerReviewAnswer_D1`;
    const data = {
      workDate: reviewView.workDate,
      sqenNumb: reviewView.sqenNumb,
      headNumb: reviewView.answerHeadNumb,
    };
    utils.dialog.confirm('댓글 삭제', '댓글을 삭제하시겠습니까?', 'warning', '', '삭제').then((res) => {
      if (res) {
        utils.http.setMode('api');
        utils.http.post(url, null, data).then((res) => {
          if (res.data.success) {
            updateReviewList();
          } else {
            utils.dialog.alert('댓글 삭제 실패!', res.data.message, 'warning').then((res) => {
              updateReviewList();
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    updateReviewList();
  }, [order]);

  useEffect(() => {
    if (prevReview.workDate !== '' && prevReview.sqenNumb !== '') {
      reviewList.forEach((review) => {
        if (prevReview.workDate === review.workDate && prevReview.sqenNumb === review.sqenNumb) {
          alert('삭제된 게시글입니다');
          setReviewView(review);
          updateImagesList(review);
        }
      });
    }
  }, [reviewList]);

  return (
    <ReviewBase>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <SmallInfoModal menu="리뷰목록" style={modalStyle} infoModalData={infoModalData} />
        <WriteButton
          onClick={() => {
            setReviewModalOpen(true);
          }}
          style={{ width: '200px' }}
          disabled={reviewCount >= 5}
        >
          <EditNoteOutlinedIcon />
          리뷰쓰기({reviewCount}/5)
        </WriteButton>
      </div>
      <Container className={'review-section'}>
        <Lists
          title={''}
          empty={reviewList.length === 0}
          infoModalData={infoModalData}
          emptyMessage={
            <>
              등록 된 후기글이 없습니다.
              <br />
              수리 완료 후, 고객에게 리뷰를 요청해 보세요!
            </>
          }
          slotFilter={
            <>
              <ResultTop total={utils.format.number(reviewList.length)} order={order} setOrder={setOrder} orderList={orderList} />
            </>
          }
          slotList={reviewList.map((review) => (
            <Item
              key={'reviewlist' + review.workDate + review.sqenNumb}
              className={'review-item ' + (reviewView.workDate + reviewView.sqenNumb === review.workDate + review.sqenNumb && 'selected')}
              onClick={() => {
                setReviewView(review);
                updateImagesList(review);
              }}
            >
              <section className={'profile-box'}>
                <ProfileImage width={'60px'} height={'60px'} kind={'user'} id={review.userId} alwaysRefresh={true} partProfile={1} />
              </section>
              <TextWrap className={'review-info'}>
                <NickName className={'nick'}>{review.userNick}</NickName>
                <Contents className={'content'}>{review.partrText}</Contents>
                <div className={'bottom'}>
                  <Rating score={review.partrScore} />
                  <SentDate>{review.ftDt}</SentDate>
                </div>
              </TextWrap>
              <ArrowForwardIosRoundedIcon />
            </Item>
          ))}
        />

        {reviewList.length > 0 && (
          <DetailContent
            title={'상세 내용'}
            empty={utils.isNull(reviewView.workDate) || utils.isNull(reviewView.sqenNumb)}
            emptyMessage={'목록에서 후기글을 선택해 주세요.'}
            adFitCode="DAN-pzz46frvVgAlr9ld"
            inputBox={
              <div
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  width: '100%',
                  height: '80px',
                  backgroundColor: '#fff',
                  borderTop: '1px solid #ddd',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                }}
              >
                <section
                  className={'chat-input-box'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div className={'input-area'}>
                    <textarea
                      placeholder={!utils.isNull(reviewView.del_dt) ? '댓글은 최초 한번만 입력가능합니다.' : '답글을 입력해 주세요.'}
                      // 크기 고정
                      style={{
                        resize: 'none',
                      }}
                      value={chatMsg}
                      onInput={(e) => {
                        setChatMsg(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.keyCode === utils.const.keyCode.ENTER) {
                          e.preventDefault();
                          if (chatMsg?.trim()?.length > 0) {
                            sendMsg();
                          }
                        }
                      }}
                      disabled={!utils.isNull(reviewView.del_dt)}
                    />
                    <button
                      type={'button'}
                      className={'send-button'}
                      onClick={() => {
                        if (chatMsg?.trim()?.length > 0) {
                          sendMsg();
                        }
                      }}
                    >
                      <ArrowUpwardRoundedIcon sx={{ color: '#fff' }} />
                    </button>
                  </div>
                </section>
              </div>
            }
            slotContent={
              <section
                className={'view-container scrollbar-container2'}
                style={{
                  // 500px 넘어가면 스크롤 생기게
                  maxHeight: '480px',
                  overflowY: 'auto',
                }}
              >
                <header className={'view-header'}>
                  {/* <section className={'profile-box'}> */}
                  <div style={{ width: '60px', height: '60px' }}>
                    <ProfileImage width={'60px'} height={'60px'} kind={'user'} id={reviewView.userId} alwaysRefresh={true} partProfile={1} />
                  </div>
                  {/* </section> */}
                  <section className={'review-info'}>
                    <div className={'nick'}>{reviewView.userNick}</div>
                    <Rating score={reviewView.partrScore} />
                  </section>
                  <section className={'review-date'}>{reviewView.ftDt}</section>
                </header>
                <main className={'view-main'}>
                  <p className={'review-content'}>{reviewView.partrText}</p>
                  {reviewImages.length > 0 && <GrabImageList className={'review-images'} imageList={reviewImages}></GrabImageList>}
                </main>
                <footer className={'view-footer'} style={{ marginBottom: '70px' }}>
                  {!reviewView?.del_dt && reviewView?.parta_text?.length > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        height: '70px',
                        gap: '10px',
                      }}
                    >
                      <ProfileImage width={'40px'} height={'40px'} kind={'partner'} id={reviewView.part_id} shape={'circle'} alwaysRefresh={true} partProfile={1} />
                      <div>
                        <div className="user-nick">{reviewView.part_name}</div>
                        <div className="comment-text">{reviewView.parta_text}</div>
                        {reviewView.part_id === utils.user('id') && (
                          <button style={{ color: '#8a8a8a' }} onClick={handleDeleteComment}>
                            삭제
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </footer>
              </section>
            }
          />
        )}
      </Container>
      <ReviewModal
        open={reviewModalOpen}
        close={() => {
          setReviewModalOpen(false);
        }}
        title={'리뷰쓰기'}
        afterAction={updateReviewList}
        refreshList={updateReviewList}
      />
    </ReviewBase>
  );
};

export default Review;

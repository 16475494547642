import React from 'react';
import { styled } from 'styled-components';
import MobileHeader from 'components/Parts/Top/TopNav/MobileHeader';

const MobilePage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const MobileBody = styled.div`
  width: 100vw;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #1b2335;
`;

const MobileFooter = styled.div`
  width: 100vw;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b2335;
  padding: 0 20px;
  // 자식 버튼
  button {
    margin-top: 0 !important;
  }
`;

const MobileModalBasic = (props) => {
  const { title, footer, children } = props;

  return (
    <MobilePage>
      <MobileHeader title={title} />
      <MobileBody>{children}</MobileBody>
      <MobileFooter>{footer}</MobileFooter>
    </MobilePage>
  );
};

export default MobileModalBasic;

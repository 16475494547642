import React, { useState } from 'react';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import { TopSection, TopLists, Main, ReqBtn, DateBox } from './TransactionElements';
import HistoryTable from 'components/Parts/Common/HistoryTable/HistoryTable';
import CustomSelect from 'components/Parts/Common/CustomSelect/CustomSelect';
import DateInput from 'components/Parts/Common/DateInput/DateInput';
import { NormalWhiteSpan } from 'style';
import EmptyBox from 'components/Parts/Common/EmptyBox';
import utils from 'modules/utils';
import moment from 'moment';

const SalesHistory = () => {
  const modalStyle = {
    width: '536px',
    height: '105px',
  };
  const infoModalData = <>결제 완료 후, 매출의 세부 내용을 확인할 수 있습니다. 단, 만나서 결제하기의 경우 포함되지 않습니다.</>;

  // select option
  const options = [
    { value: 0, label: '월간매출' },
    { value: 1, label: '연간매출' },
  ];

  const customStyle = {
    selectOptions: {
      height: '64px',
      top: '39px',
    },
    selectBox: {
      width: '100%',
    },
    label: {
      color: '#10141c',
    },
  };
  // 유형 select box value state
  const [selected, setSelected] = useState(0);
  // datepicker date format state
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [reqResult, setReqResult] = useState([]);

  const getSales = async () => {
    try {
      let ftdt = moment(startDate).format('YYYY-MM-DD');
      let etdt = moment(endDate).format('YYYY-MM-DD');
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_p_partnerSale_S3/${selected}/${ftdt}/${etdt}`);
      // console.log(res.data);
      if (res.data.data) {
        setReqResult(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SmallInfoModal menu="매출조회" style={modalStyle} infoModalData={infoModalData} />
      <TopSection>
        <TopLists>
          <div style={{ fontSize: '20px' }}>
            <CustomSelect options={options} setSelected={setSelected} customStyle={customStyle} />
          </div>
          <div style={{ display: 'flex' }}>
            <DateBox>
              <DateInput dateFormat={selected === 0 ? 'yyyy-MM' : 'yyyy'} value={startDate} onChange={setStartDate} />
            </DateBox>
            <NormalWhiteSpan style={{ width: '43px', textAlign: 'center', lineHeight: '40px' }}>~</NormalWhiteSpan>
            <DateBox>
              <DateInput dateFormat={selected === 0 ? 'yyyy-MM' : 'yyyy'} value={endDate} onChange={setEndDate} />
            </DateBox>
          </div>
          <ReqBtn type="button" onClick={getSales}>
            매출조회
          </ReqBtn>
        </TopLists>
      </TopSection>
      {reqResult.length > 0 ? (
        <Main>
          <HistoryTable result={reqResult} />
        </Main>
      ) : (
        <Main style={{ height: '40%' }}>
          <EmptyBox message="매출 내역이 없습니다" />
        </Main>
      )}
    </>
  );
};

export default SalesHistory;

import React, { useEffect } from 'react';
import { DetailContentWrap, DialogWrap } from './ViewListsElements';
import { TitleText } from 'style';
import { Scrollbars } from 'react-custom-scrollbars-2';
import EmptyBox from '../EmptyBox';
import Icon from 'components/Parts/Common/Icon';
import './ChatRoom.scss';
import utils from './../../../../modules/utils/index';
import { useParams } from 'react-router-dom';
import { commentStore, partInfoStore } from 'modules/store/store';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import dialog from './../../../../modules/utils/utils.dialog';
const DetailContent2 = ({ scrollBar, slotContent, questionView, refreshList }) => {
  const [chatMsg, setChatMsg] = React.useState('');
  const [images, setImages] = React.useState([]);
  const [like, setLike] = React.useState(false);

  const { workDate, sqenNumb } = useParams();

  const userId = utils.user('id');

  const { kind } = useParams();

  const { commentParent, myLike } = commentStore();

  const scrollbarsRef = React.useRef(null);

  // const { parentNumb } = commentStore();

  const imageSelector = React.useRef(null);

  const { getPartInfo, partInfo } = partInfoStore();

  const sendMsg = () => {
    if (chatMsg.trim() === '') {
      return;
    }
    if (!utils.user('nick') || utils.user('nick') === '') {
      return utils.dialog.alert('닉네임을 설정해주세요.', '', 'warning');
    }

    if (!partInfo?.partName || partInfo?.partName === '') {
      return utils.dialog.alert('달인 정보를 등록해주세요.', '', 'warning');
    }

    const userNick = commentParent.userNick;
    let parentNumb = commentParent.headNumb ?? '';

    console.log('parentNumb', parentNumb);

    if (!chatMsg.includes(`@${userNick}`)) {
      parentNumb = '';
    }

    const questionUrl = `/v2/SP_c_questionItemV2_I1`;
    const communicationUrl = `/v2/SP_c_communicationItemV2_I1`;
    const questionData = {
      workDate: questionView.workDate, // 일자키
      sqenNumb: questionView.sqenNumb, // 순번키
      parentNumb: parentNumb, // 대댓글키
      quesAnswer: chatMsg, // 댓글
      appGubn: 'partner',
    };
    const communicationData = {
      workDate: questionView.workDate, // 일자키
      sqenNumb: questionView.sqenNumb, // 순번키
      parentNumb: parentNumb, // 대댓글키
      showContent: chatMsg, // 댓글
      appGubn: 'partner',
    };

    const url = kind === 'question' ? questionUrl : communicationUrl;
    const data = kind === 'question' ? questionData : communicationData;

    console.log('data', data);

    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      console.log('res @@@@@@', res);
      if (res.data.success) {
        setChatMsg('');
        refreshList();

        // 스크롤바 맨 아래로
        setTimeout(() => {
          if (scrollbarsRef.current) {
            scrollbarsRef.current.scrollToBottom();
          }
        }, 100);
      } else {
        utils.dialog.alert('', res.data.message, 'warning').then((res) => {
          refreshList();
        });
      }
    });
  };

  useEffect(() => {
    getPartInfo();
  }, []);

  useEffect(() => {
    setChatMsg('');
    // 스크롤바 맨 위로
    // if (scrollbarsRef.current) {
    //   scrollbarsRef.current.scrollToTop();
    // }
  }, [questionView]);

  useEffect(() => {
    setLike(myLike?.myLike === userId);
  }, [myLike]);

  useEffect(() => {
    if (Object.keys(commentParent).length > 0) {
      setChatMsg(`@${commentParent.userNick} `);
    }
  }, [commentParent]);

  const handleClickLike = () => {
    const url = `/v1/SP_c_communicationItem_Like_I1`;
    const data = {
      workDate: questionView.workDate, // 일자키
      sqenNumb: questionView.sqenNumb, // 순번키
    };
    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      console.log('res', res);

      if (res.data.success) {
        setLike(!like);
      } else {
        console.log(res.data.message);
      }
    });
  };

  useEffect(() => {
    // 스크롤바 맨 위로 부드럽게
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToTop();
    }
  }, [workDate, sqenNumb]);

  return (
    <DetailContentWrap className={'detail-content-wrap'}>
      {Object.keys(questionView).length > 0 ? (
        <>
          <DialogWrap
            style={{
              position: 'relative',
            }}
          >
            {slotContent &&
              (scrollBar ? (
                <Scrollbars ref={scrollbarsRef} className="detail-content scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
                  {slotContent}
                </Scrollbars>
              ) : (
                slotContent
              ))}
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                height: '80px',
                backgroundColor: '#fff',
                borderTop: '1px solid #ddd',
              }}
            >
              {/* <section
                style={{
                  position: 'absolute',
                  top: '-120px',
                  left: '0',
                  width: '100%',
                  height: '120px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#00000080',
                }}
              > */}
              {/* 이미지 preview */}
              {/* </section> */}

              <section
                className={'chat-input-box'}
                style={
                  kind !== 'question'
                    ? {
                        alignItems: 'center',
                        gridTemplateColumns: 'repeat(1, 36px) auto',
                      }
                    : {
                        display: 'flex',
                        alignItems: 'center',
                      }
                }
              >
                {kind !== 'question' && (
                  <button
                    type={'button'}
                    className={'image-add-button'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={handleClickLike}
                  >
                    {like ? (
                      <FavoriteRoundedIcon
                        sx={{
                          fontSize: '32px',
                          color: '#ff1744',
                        }}
                      />
                    ) : (
                      <FavoriteBorderRoundedIcon
                        sx={{
                          fontSize: '32px',
                          color: '#8a8a8a',
                        }}
                      />
                    )}
                  </button>
                )}
                <div className={'input-area'}>
                  <input
                    type={'file'}
                    multiple={false}
                    ref={imageSelector}
                    accept={'image/jpg, image/jpeg, image/png'}
                    onChange={(e) => {
                      // if (e.target.files.length > 0) {
                      //   let images = [];
                      //   for (let idx = 0; idx < e.target.files.length; idx++) {
                      //     images.push(e.target.files[idx]);
                      //   }
                      // }
                    }}
                  />
                  <input
                    type="text"
                    placeholder="댓글을 입력해 주세요."
                    // 크기 고정
                    style={{
                      resize: 'none',
                    }}
                    value={chatMsg}
                    onInput={(e) => {
                      setChatMsg(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter' || e.keyCode === utils.const.keyCode.ENTER) {
                        sendMsg();
                      }
                    }}
                  />
                  <button
                    type={'button'}
                    className={'send-button'}
                    onClick={() => {
                      sendMsg();
                    }}
                  >
                    <ArrowUpwardRoundedIcon />
                  </button>
                </div>
              </section>
            </div>
          </DialogWrap>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#8a8a8a',
          }}
        >
          {kind === 'question' ? '질문해요 목록에서 질문을 선택해주세요.' : '솜씨자랑 목록에서 솜씨자랑을 선택해주세요.'}
        </div>
      )}
    </DetailContentWrap>
  );
};

export default DetailContent2;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'material-symbols/rounded.css';
import App from './App';
import utils from './modules/utils';
const root = ReactDOM.createRoot(document.getElementById('root'));

// 로그인 확인 함수를 호출하여 로그인 여부를 확인합니다.
utils.user.initLoginCheck().then(async (isLogin) => {
  if (isLogin) {
    // 이미 로그인되어 있는 경우
    if (['/', '/login'].includes(window.location.pathname)) {
      // 현재 경로가 '/' 또는 '/login'인 경우, '/manage/home'으로 리다이렉트합니다.
      window.location.href = '/manage/home';
    } else {
      // 그렇지 않은 경우, 앱을 렌더링합니다.
      root.render(<App />);
    }
  } else if (window.location.pathname.toLowerCase() !== '/login' && window.location.pathname.toLowerCase() !== '/register') {
    // 로그인되어 있지 않고, 현재 경로가 '/login' 또는 '/register'가 아닌 경우
    if (utils.user.isAutoLogin() && (await utils.user.login(utils.user.storedInfo('id'), utils.user.storedInfo('pass')))) {
      // 자동 로그인이 가능하고, 저장된 정보를 사용하여 로그인이 성공한 경우, '/manage/home'으로 리다이렉트합니다.
      window.location.href = '/manage/home';
    } else {
      window.location.href = '/login';
    }
  } else {
    root.render(<App />);
  }
});

import MultiImageUpload from 'components/FileUpload/MultiImageUpload';
import CustomSelect from 'components/Parts/Common/CustomSelect/CustomSelect';
import SingleCheckBox from 'components/Parts/Common/forms/CheckBox/SingleCheckBox';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { BdWhiteText, TitleText } from 'style';
import { qnaStore } from '../../../../modules/store/store';
import { Form, LightInput, SubmitBtn, TemrsBox, TermsText, TextArea } from './ModalElements';
import WindowFrame from './WindowFrame';

const QNA_CODE = '20';

const QnAModal = (props) => {
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const navigate = useNavigate();
  const location = useLocation();
  const { setQnaRefreshToogle } = qnaStore();

  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, title } = props;

  // 유형 select box value state
  const [selected, setSelected] = useState('');

  const [qnaOption, setQnaOption] = useState([]);
  const [isCommunicating, setIsCommunicating] = useState(false);

  // 제목 input value state
  const [subject, setSubject] = useState('');
  // 내용 textarea value state
  const [content, setContent] = useState('');
  // 화면에 출력되는 이미지
  const [images, setImages] = useState([]);
  const [delImages, setDelImages] = useState([]);

  const [law, setLaw] = useState('');
  const getLaw = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_m_law_s1/1`);
      if (res.data.data) {
        setLaw(res.data.data[0].content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const customStyle = {
    selectOptions: { width: '100%', top: '38px' },
    selectBox: {
      width: isTablet ? '100%' : 'calc(100% - 99px)',
      fontSize: isTablet ? '16px' : '18px',
    },
  };

  // 개인정보 수집/동의 체크박스
  const [isChecked, setIsChecked] = useState(false);
  const onChangeChecked = () => {
    setIsChecked(!isChecked);
  };

  const onSubmit = async (e) => {
    console.log('isCommunicating', isCommunicating);

    if (isCommunicating) return;
    e.preventDefault();
    if (selected === '' || subject?.trim() === '' || content?.trim() === '') {
      utils.dialog.alert('입력 정보 누락!', '입력되지 않은 정보가 있습니다!<br />입력하신 정보를 다시 확인해 주세요.', 'warning');
    } else if (!isChecked) {
      utils.dialog.alert('개인정보 수집 및 이용 미동의', '개인정보 수집 및 이용 동의가<br />체크되어 있지 않습니다.', 'warning');
    } else {
      try {
        //request로 보내야할 데이터를 formData에 넣어서 보냄
        const formData = new FormData();
        images.forEach((image) => {
          if (image.localFlag) {
            formData.append('files', image.file);
          }
        });
        formData.append('imageCount', images.length);
        formData.append('appGubn', 'partner');
        formData.append('qaCategory', selected);
        formData.append('qaContent', content);
        formData.append('qaSubject', subject);
        formData.append('delKey', '');
        //console.log(formData.getAll("files"));
        //console.log(formData.getAll("files"));
        utils.http.setMode('api');
        setIsCommunicating(true);
        const res = await utils.http.postFile(`/v2/SP_m_PersonalInquiry_I2`, formData);
        if (res.data.success && res.data.data.length > 0) {
          setIsCommunicating(false);
          // 문의완료 alert 띄우기
          utils.dialog.alert('문의 완료!', '작성하신 문의가 접수되었습니다!<br />내용 확인 후, 신속히 답변드리겠습니다.', 'success').then((res) => (res && location.pathname === '/manage/news/faqList/qna' ? setQnaRefreshToogle((prev) => !prev) : navigate('/manage/news/faqList/qna')));
          close();
        } else {
          setIsCommunicating(false);
          if (res?.data?.message.includes('욕설')) {
            utils.dialog.alert('비속어 금지!', res.data.message, 'error');
            return;
          }
          utils.dialog.alert('문의 실패!', '문의를 접수하는데 실패했습니다!<br />관리자에게 문의해 주세요.', 'warning');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // 공통코드 조회 옵션가져오기
  const getCommonCode = async () => {
    const url = `/v1/SP_b_Common_Code_SI/${QNA_CODE}`;
    utils.http.setMode('api');
    const res = await utils.http.get(url);
    if (res.data.success) {
      // cd를 value로, nm을 label로 변환
      res.data.data.forEach((item) => {
        item.value = item.cd;
        item.label = item.nm;
      });
      res.data.data = res.data.data.filter((item) => item.label !== '전체');
      setQnaOption(res.data.data);
    }
  };

  useEffect(() => {
    getLaw();
    getCommonCode();
  }, []);

  useEffect(() => {
    setSelected('');
    setSubject('');
    setContent('');
    setImages([]);
    setDelImages([]);
    setIsChecked(false);
  }, [open]);

  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'866px'}
      height={'100vh'}
      slotFixedButton={
        <SubmitBtn style={{ height: '45px', padding: '0' }} type="button" onClick={onSubmit}>
          문의
        </SubmitBtn>
      }
    >
      <Form action="#none" style={{ gap: 0, marginTop: '20px' }}>
        <TitleText style={{ fontWeight: 'normal' }}>
          수달에 궁금하신 점을 남겨주시면 빠르게 답변드리겠습니다.
          <br />
          {`작성하신 글은 "고객센터 > 1:1문의"에서 확인 가능합니다.`}
        </TitleText>
        <div className="qna-scroll-area">
          <div className="modal-input-justify">
            <BdWhiteText className="input-title">유형</BdWhiteText>
            <CustomSelect options={qnaOption} placeHolder="문의 유형을 선택해 주세요." setSelected={setSelected} customStyle={customStyle} />
          </div>
          <div className="modal-input-justify">
            <BdWhiteText className="input-title">제목</BdWhiteText>
            <LightInput
              type="text"
              id="subject-input"
              required
              value={subject}
              placeholder="제목을 입력하세요."
              text_align="left"
              style={{
                textAlign: 'left',
                height: '39px',
                fontSize: isTablet ? '16px' : '18px',
                width: isTablet ? '100%' : 'calc(100% - 99px)',
                padding: '1px 14px',
              }}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>

          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">내용</BdWhiteText>
            <TextArea
              placeholder={`문의 내용을 작성해주세요.\nex) 견적서는 어떻게 수정하나요?`}
              style={{
                height: '188px',
                fontSize: isTablet ? '16px' : '18px',
                padding: '15px 14px',
                borderRadius: '5px',
              }}
              onChange={(e) => setContent(e.target.value)}
              maxLength={2000}
            />
          </div>
          <div className="scrollbar-innerContent">
            {/* <Uploader maxImage={5} showImages={images} setShowImages={setImages} setFile={setFile} file={file} /> */}
            <MultiImageUpload accept={'image/jpg, image/jpeg, image/png'} name="qnaModal" imageArr={images} setImageArr={setImages} setDeleteImageArr={setDelImages} isWhite={true} maxImage={5} width={'80px'} height={'80px'} />
          </div>
          <div className="scrollbar-innerContent">
            <BdWhiteText className="input-title">개인정보 수집 및 이용 동의서</BdWhiteText>
            <TemrsBox>
              <Scrollbars className="scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
                <TermsText>{law}</TermsText>
              </Scrollbars>
            </TemrsBox>
          </div>
          <div className="qna-checkBox">
            <SingleCheckBox text="개인정보 수집 및 이용 동의" name="qna-checkBox" checked={isChecked} onChangeHandle={onChangeChecked} />
          </div>
        </div>
      </Form>
    </WindowFrame>
  );
};

export default QnAModal;

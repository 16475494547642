import styled from 'styled-components';

/************** Table 공통 **************/
export const TableContainer = styled.div`
  width: calc(100% + ${(props) => props.padding} * 2);
  margin-left: -${(props) => props.padding};
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const Thead = styled.thead`
  background-color: #212838;
  border: none;
`;

export const TheadRow = styled.tr`
  font-family: 'Noto Sans KR';
  font-size: 20px;
  font-weight: bold;
  color: #fff;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const TheadCol = styled.th`
  width: ${(props) => props.width};
`;

export const Tbody = styled.tbody`
  width: 100%;
`;

export const TbodyRow = styled.tr`
  font-family: 'Noto Sans KR';
  font-size: 18px;
  font-weight: normal;
  color: #fff;
  border: none;

  &:nth-child(odd) {
    background-color: transparent;
  }

  &:nth-child(even) {
    background-color: #212838;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const TbodyCol = styled.td`
  height: 40px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BtnContainer = styled.div`
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-top: 30px;
`;

export const TheadBtn = styled.button`
  background-color: #212838;
  outline: none;
  border: none;
  padding: 6px 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: ${(props) => props.btnstyle.color};
  cursor: pointer;
  width: 50%;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: ${(props) => props.btnstyle.display};
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    width: 100%;
    padding-top: 3px;
    background: #5affe2;
    z-index: -1;
  }

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
`;

/************** Notice Table **************/
export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 100%;
  gap: 30px;
`;

export const ViewTable = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #37465b;
  padding: 14px 30px;
`;

export const ViewDetail = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #37465b;
  padding: 20px;
`;

export const BorderTheadRow = styled.tr`
  font-family: 'Noto Sans KR';
  font-size: 20px;
  height: 50px;
  font-weight: bold;
  color: #fff;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const Subject = styled.button`
  background: transparent;
  cursor: pointer;
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: #5affe2;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const NoticeTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const NoticeContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 30px;
`;

export const ContentImg = styled.img`
  width: 100%;
  cursor: pointer;
`;

export const ContentText = styled.p`
  width: 100%;
  color: #212838;
  font-size: 20px;
  font-weight: normal;
  white-space: pre-wrap;
  padding: 15px 0 30px 0;
`;

export const NoneContentText = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8a8a8a;
  font-size: 20px;
  font-weight: bold;
`;
export const PreviewImg = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

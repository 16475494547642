import { TicketListHeader } from 'components/Manage/Ticket/TicketList/TicketList';
import { Card } from 'components/Manage/Ticket/TicketList/TicketListElements';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';
import ChangeAreaAndTicketModal from 'components/Parts/Common/Modal/ChangeAreaAndTicketModal';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import utils from 'modules/utils';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { styled } from 'styled-components';
import CanclePaymentModal from './../../../Parts/Common/Modal/CanclePaymentModal';
import { Title } from './../TicketList/TicketListElements';

const ticketInfo = [
  {
    title: 'DIAMOND',
    icon: 'D',
    content1: '• 견적 개수 제한 없음',
    content2: '• 지역 설정 개수 제한 없음',
    color: '#161377',
  },
  {
    title: 'PLATINUM',
    icon: 'P',
    content1: '• 견적 개수 제한 없음',
    content2: '• 지역 설정 25개 가능',
    color: '#e01949',
  },
  {
    title: 'GOLD',
    icon: 'G',
    content1: '• 견적 개수 제한 없음',
    content2: '• 지역 설정 14개 가능',
    color: '#fdc23e',
  },
  {
    title: 'SILVER',
    icon: 'S',
    content1: '• 견적 개수 제한 없음',
    content2: '• 지역 설정 7개 가능',
    color: '#aeaeae',
  },
  {
    title: 'BRONZE',
    icon: 'B',
    content1: '• 견적 개수 제한 없음',
    content2: '• 지역 설정 4개 가능',
    color: '#b29d91',
  },
  {
    title: 'IRON',
    icon: 'I',
    content1: '• 견적 개수 제한 없음',
    content2: '• 지역 설정 1개 가능',
    color: '#10141c',
  },
];

const modalStyle = {
  width: '536px',
  height: '105px',
};

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  border-radius: 10px;
  background-color: #1b2335;
  font-size: 20px;

  & thead {
    border-bottom: 6px solid #3d4a61;
    & tr th {
      font-size: 20px;
      height: 44px;
      border: 1px solid #3d4a61;
    }
  }

  & tr td {
    height: 60px;
    border: 1px solid #3d4a61;
    padding: 0 10px;
  }
`;

const TableBox = styled.div`
  height: 620px;
  overflow-y: auto;
`;

const TicketIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const TicketTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  font-weight: bold;
  & div {
    display: flex;
    align-items: center;
  }
`;

const TicketButton = styled.button`
  width: 77px;
  padding: 4px 10px;
  border-radius: 3px;
  background-color: var(--mint-color);
  color: #000;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`;
const ChangeTicketButton = styled.button`
  width: 77px;
  padding: 4px 0;
  border-radius: 3px;
  border: 1px solid var(--mint-color);
  color: var(--mint-color);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  line-height: 1.5;

  //모바일
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const ModalRow = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;

  //모바일
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const ModalRowLabel = styled.div`
  width: 180px;

  //모바일
  @media screen and (max-width: 480px) {
    width: 120px;
  }
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #fff;
`;

const TicketLabel = styled.div`
  padding: 2px 14px;
  border-radius: 20px;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px;
`;

const CardRow = styled.div`
  display: flex;
  align-items: center;
`;

const CardRowLabel = styled.div`
  width: 100px;
`;

/**
 * nowGubn: 0 전체 결제내역조회, 2 현재이용중 조회,
 */
const NOW_GUBN = '0';

const AmPmFormater = (date) => {
  if (date === null) return '';
  return dayjs(date).locale('ko').format('YYYY.MM.DD');
};

const infoModalData = (
  <>
    <div>구매한 구독권 내역을 확인 할 수 있으며, 예약중인 구독권 해지 신청 가능합니다.</div>
  </>
);
const PurchaseHistory = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState(null);

  // 일주일 구독해지 모달
  const [weekModalOpen, setWeekModalOpen] = useState(false);
  // 구독권 변경 모달
  const [changeModalOpen, setChangeModalOpen] = useState(false);

  // 이전 chipData
  const [prevChipData, setPrevChipData] = useState([]);

  const getRowData = () => {
    const url = `/v1/SP_b_user_ticket_payment_s1/${NOW_GUBN}`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      console.log('getRowData', res.data.data);
      setRowData(res.data.data);
    });
  };

  const [rowData, setRowData] = useState([]);

  // 일주일 구독해지 모달
  const handleShowWeekModal = (row) => {
    setSelectedRow(row);

    if (row.useCount === 0) {
      setWeekModalOpen(true);
      return;
    }
    // useCount가 0이 아니면 경고 메시지를 표시합니다.
    utils.dialog.alert('해지 불가능', '해지 신청이 불가능합니다.<br />고객센터(1566-4921)로 문의해주세요.', 'error');
  };
  const [chipData, setChipData] = useState([]);
  // 정기결제 예약 구독권 변경 모달
  const handleShowChangeModal = (row) => {
    setSelectedRow(row);
    setChangeModalOpen(true);
  };

  useEffect(() => {
    getRowData();
  }, []);

  // 일주일 구독해지
  const handleWeekCancelTicket = () => {
    const url = `/v1/cancelOrder`;
    const data = {
      imp_uid: selectedRow.imp_uid,
    };
    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      console.log('handleWeekCancelTicket', res.data.data);
      if (res.data.success) {
        utils.dialog.alert('해지 완료!', '구독중인 구독권이 해지되었습니다.', 'success');
        setWeekModalOpen(false);
      } else {
        utils.dialog.alert('해지 불가능', '해지 신청이 불가능합니다.<br />고객센터(1566-4921)로 문의해주세요.', 'error');
        setWeekModalOpen(false);
      }
      getRowData();
    });
  };

  // 해지하기
  const handleCancelTicket = () => {
    const url = `/v1/delUserSchedule`;
    utils.http.setMode('api');
    utils.http.post(url, null, {}).then((res) => {
      console.log('handleCancelTicket', res.data.data);
      if (res.data.success) {
        utils.dialog.alert('해지 완료!', '구독중인 구독권이 해지되었습니다.', 'success');
      }
      getRowData();
    });
  };

  // 예약된 내노출지역 불러오기
  const getReservedCity = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get('/v1/SP_p_partPlusReservation_s1');
      console.log('파트너 지역 불러오기', res.data.data);
      if (res.data.data.length > 0) {
        const regionList = res.data.data?.map((item) => {
          return { region: item.city, cd: item.regionCode, nm: item.regionName };
        });
        setChipData(regionList);
        setPrevChipData(regionList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReservedCity();
  }, []);

  return (
    <>
      <TicketListHeader>
        <SmallInfoModal menu="구독내역" style={modalStyle} infoModalData={infoModalData} />
        {isMobile && (
          <button
            style={{
              border: '1px solid var(--mint-color)',
              backgroundColor: '#00000000',
              color: 'var(--mint-color)',
            }}
            onClick={() => navigate('/manage/ticket/ticketList')}
          >
            구독권
          </button>
        )}
      </TicketListHeader>
      <Title>구독중인 구독권 내역을 확인해 주세요!</Title>
      {isMobile ? (
        <CardList>
          {rowData.map((row, index) => {
            const rowTicketInfo = ticketInfo.find((item) => {
              return item.title === row.level_name.toUpperCase();
            });
            return (
              <Card key={row.ft_dt + index}>
                <CardHeader>
                  <TicketTitle>
                    <TicketIcon color={rowTicketInfo?.color}>{rowTicketInfo?.icon}</TicketIcon>
                    <div>{row.level_name.toUpperCase()}</div>
                    <TicketLabel
                      style={{
                        backgroundColor: row.nowTicket === '구독중' ? 'var(--mint-color)' : row.nowTicket === '취소' ? '#8a8a8a' : '#212838',
                        color: row.nowTicket === '구독중' ? '#000' : '#fff',
                      }}
                    >
                      {row.nowTicket ?? '만료'}
                    </TicketLabel>
                  </TicketTitle>
                </CardHeader>
                <CardBody>
                  <CardRow>
                    <CardRowLabel>결제금액</CardRowLabel>
                    <div>{row.price.toLocaleString() ?? '-'}</div>
                  </CardRow>
                  <CardRow>
                    <CardRowLabel>구매일</CardRowLabel>
                    <div>{dayjs(row.ft_dt).format('YYYY.MM.DD') ?? '-'}</div>
                  </CardRow>
                  <CardRow>
                    <CardRowLabel>구독기간</CardRowLabel>
                    <div>{row.st_dt && row.et_dt ? AmPmFormater(row.st_dt) + ' ~ ' + AmPmFormater(row.et_dt) : '-'}</div>
                  </CardRow>
                  <CardRow>
                    <CardRowLabel>비고</CardRowLabel>
                    <div>{row.memo ?? '-'}</div>
                  </CardRow>
                </CardBody>
              </Card>
            );
          })}
        </CardList>
      ) : (
        <Card>
          <TableBox className="scrollbar-container2">
            <Table>
              <thead>
                <tr>
                  <th width="5%">구분</th>
                  <th width="10%">구독권</th>
                  <th width="10%">결제금액</th>
                  <th width="10%">구매일</th>
                  <th width="15%">구독기간</th>
                </tr>
              </thead>
              <tbody>
                {rowData.map((row, index) => {
                  const rowTicketInfo = ticketInfo.find((item) => {
                    return item.title === row.level_name.toUpperCase();
                  });
                  return (
                    <tr key={row.ft_dt + index} style={{ color: row.nowTicket === '만료' ? '#8a8a8a' : '' }}>
                      <td>{row.nowTicket ?? '만료'}</td>
                      <td align="left">
                        <TicketTitle>
                          <div>
                            <TicketIcon color={rowTicketInfo?.color}>{rowTicketInfo?.icon}</TicketIcon>
                            <div>{row.level_name.toUpperCase()}</div>
                          </div>
                          {row.nowTicket === '예약중' && (
                            <>
                              <ChangeTicketButton onClick={() => handleShowChangeModal(row)}>변경/해지</ChangeTicketButton>
                            </>
                          )}
                          {/* 해지버튼을 눌렀을때 경고 메세지가 나오도록 useCount가 0일때 모달띄우고, 0이 아닐때 Alert */}
                          {row.nowTicket === '구독중' && <TicketButton onClick={() => handleShowWeekModal(row)}>해지</TicketButton>}
                        </TicketTitle>
                      </td>
                      <td>{row.price.toLocaleString()}원</td>
                      {row.nowTicket === '예약중' ? <td style={{ fontSize: '19px' }}>{dayjs(row.memo).format('YYYY.MM.DD')} 예정</td> : <td>{dayjs(row.ft_dt).format('YYYY.MM.DD')}</td>}
                      {/* YYYY.MM.DD 오전 12:00 */}
                      <td>
                        <div>{row.st_dt && row.et_dt ? AmPmFormater(row.st_dt) + ' ~ ' + AmPmFormater(row.et_dt) : ''}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableBox>
        </Card>
      )}
      <CanclePaymentModal
        open={weekModalOpen}
        close={() => {
          setWeekModalOpen(false);
        }}
        secondBtn={
          <div
            style={{
              width: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              color: '#fff',
              fontSize: '16px',
              lineHeight: '1.43',
              borderBottom: '1px solid #fff',
              cursor: 'pointer',
            }}
            onClick={() => {
              utils.dialog.confirm('정말 해지하시겠습니까?', '', 'question').then((res) => {
                if (res) {
                  handleWeekCancelTicket();
                }
              });
            }}
          >
            해지 신청
          </div>
        }
      >
        <ModalBody>
          <div>
            <i className="dialog material-symbols-rounded" style={{ color: '#ff4b4b', fontSize: '100px' }}>
              error
            </i>
          </div>
          <ModalTitle>정말 구독권을 해지하시나요?</ModalTitle>
          <ModalTitle>현재 이용중인 해택들이 모두 사라집니다.</ModalTitle>
          <Card
            style={{
              marginTop: '20px',
            }}
          >
            <ModalRow>
              <ModalRowLabel>구독 중인 구독권</ModalRowLabel>
              <div>{selectedRow?.level_name.toUpperCase()}</div>
            </ModalRow>
            <ModalRow>
              <ModalRowLabel>구독 기간</ModalRowLabel>
              <div>{dayjs(selectedRow?.ft_dt).format('YYYY.MM.DD') + ' ~ ' + selectedRow?.memo}</div>
            </ModalRow>
          </Card>
        </ModalBody>
      </CanclePaymentModal>
      {/* 구독권 변경 모달 */}
      {changeModalOpen && <ChangeAreaAndTicketModal open={changeModalOpen} close={() => setChangeModalOpen(false)} title="구독권 변경" chipData={chipData} setChipData={setChipData} refreshList={getRowData} prevChipData={prevChipData} handleCancelTicket={handleCancelTicket} getReservedCity={getReservedCity} />}
    </>
  );
};

export default PurchaseHistory;

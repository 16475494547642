import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Base } from '../FrameElements';
import JobPosting from './JobPosting/JobPosting';
import TabIntern from '../../Manage/History/WorkList/Tabs/Intern';

const Intern = () => {
  return (
    <Base>
      <Routes>
        <Route path="jobPosting" element={<JobPosting />} />
        <Route path="jobPosting/:workDate/:sqenNumb" element={<JobPosting />} />
        <Route path="intern" element={<TabIntern />} />
        <Route path="intern/:internId" element={<TabIntern />} />
        <Route path="intern/:workDate/:sqenNumb" element={<TabIntern />} />
      </Routes>
    </Base>
  );
};

export default Intern;

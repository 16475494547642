import React from 'react';
import { ContentTop, IconWrap, BigModal, InfoHeader, InfoContent, HeadingText, WhiteDot } from './MenuInfoElements';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const BigInfoModal = ({ infoModalData, menu, style }) => {
  return (
    <>
      <ContentTop>
        <HeadingText>{menu}</HeadingText>
        <IconWrap>
          <AiOutlineExclamationCircle color="#7e6eff" style={{ cursor: 'pointer' }} />
          <BigModal width={style.width} height={style.height}>
            {infoModalData.map((item, index) => (
              <div key={`info-${menu}${index}`}>
                <InfoHeader>
                  <WhiteDot style={{ margin: '5px 5px 5px 0' }} />
                  {item.title}
                </InfoHeader>
                <InfoContent style={{ marginBottom: '20px' }}>{item.descript}</InfoContent>
              </div>
            ))}
          </BigModal>
        </IconWrap>
      </ContentTop>
    </>
  );
};

export default BigInfoModal;

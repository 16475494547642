import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ProfileImage from '../../../../Parts/Common/ProfileImage';
import './ChatRoom.scss';

import imgEstimate from 'assets/chat-room/estimate.jpg';
import imgPayComplete from 'assets/chat-room/paycomplete.jpg';
import imgReservationCancel from 'assets/chat-room/reservation-cancel.jpg';
import imgReservation from 'assets/chat-room/reservation.png';
import imgReviewRequest from 'assets/chat-room/reviewRequest.jpg';

import chat from 'modules/chat';
import utils from 'modules/utils';

import ImageViewModal from 'components/Parts/Common/Modal/ImageViewModal';
import CustomCalendar from '../../../../CalendarPage';
import EmptyBox from '../../../../Parts/Common/EmptyBox';
import EstimateViewModal from '../../../../Parts/Common/Modal/EstimateViewModal';
import InternProfileModal from '../../../../Parts/Common/Modal/InternProfileModal';
import UserBlockModal from '../../../../Parts/Common/Modal/UserBlockModal';
import UserReportModal from '../../../../Parts/Common/Modal/UserReportModal';
import ModalComponent from './../../../../Parts/Common/Modal/ModalComponent';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import BlockIcon from '@mui/icons-material/Block';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';

const EstimateCard = ({ chat, chatInfo, moveBottom, setEstimateInfo, setImageList, chatImageList, setImageStart, setCaledarModal }) => {
  const [open, setOpen] = useState(false);
  console.log('채팅전송', chat);

  return (
    <section className={'chat-view-' + (chat.userId === utils.user('id') ? 'self' : 'other')}>
      <div className={'profile-div'}>
        <ProfileImage partProfile={1} kind={'user'} id={chat.userId} alwaysRefresh={true} />
      </div>
      <div className={'dialog-div'}>
        <div className={'user-name'}>{chatInfo.partName}</div>
        {chat.rcdType === 'message' && <div className={'chat-content'}>{chat.rcdContent}</div>}
        {chat.rcdType === 'payComplete' && (
          <div className={'chat-action'}>
            <img src={imgPayComplete} alt={''} onLoad={moveBottom} />
            <div className={'inform'}>
              고객님과의 거래가 완료되었습니다.
              <br />
              고객님이 작성한 소중한 리뷰를 확인해 보세요!
            </div>
          </div>
        )}
        {chat.rcdType === 'reviewRequest' && (
          <div className={'chat-action'}>
            <img src={imgReviewRequest} alt={''} onLoad={moveBottom} />
            <div className={'inform'}>{chatInfo.partName}님, 고객님의 소중한 의견 한마디 부탁드리겠습니다. 감사합니다!</div>
          </div>
        )}
        {chat.rcdType === 'estimate' && (
          <div className={'chat-action'}>
            <img src={imgEstimate} alt={''} onLoad={moveBottom} />
            <div className={'inform'}>
              {chatInfo.partName}님,
              <br />
              따끈한 견적서가 도착했어요. 지금 확인해 볼까요?
              <button
                type={'button'}
                className={'estimate-view'}
                onClick={() => {
                  setEstimateInfo(() => ({
                    open: true,
                    workDate: chat.workDate,
                    sqenNumb: chat.sqenNumb,
                    headNumb: chat.headNumb
                  }));
                }}
              >
                견적서 보기
              </button>
            </div>
          </div>
        )}
        {chat.rcdType === 'chatImage' && (
          <div
            className={'chat-image'}
            onClick={() => {
              setImageList(chatImageList().map((x) => x.fileName));
              setImageStart(chat.fileName);
            }}
          >
            <img src={process.env.REACT_APP_IMAGE_VIEW_URL + chat.fileName} alt={''} onLoad={moveBottom} />
          </div>
        )}
        {chat.rcdType === 'reservation' && (
          <div className={'chat-action'}>
            <img src={imgReservation} alt={''} onLoad={moveBottom} />
            <div className={'inform'}>
              <div>
                예약이 완료되었어요.
                <br />
                예약 조정이 필요한 경우 취소될 수 있어요.
              </div>
              <button
                type={'button'}
                className={'estimate-view'}
                onClick={() => {
                  setCaledarModal(true);
                }}
              >
                달력 보기
              </button>
            </div>
          </div>
        )}
        {chat.rcdType === 'reservationCancel' && (
          <div className={'chat-action'}>
            <img src={imgReservationCancel} alt={''} onLoad={moveBottom} />
            <div className={'inform'}>
              <div>
                예약이 취소되었어요.
                <br />
                아쉽지만 다음에 이용해주세요.
              </div>
              <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px', color: '#8a8a8a' }}>{chat.rcdContent}</div>
            </div>
          </div>
        )}
      </div>
      <div className={'time-div'}>
        <div className={'chat-time'}>{chat.ftDt}</div>
      </div>
    </section>
  );
};

const ChatRoom = ({ chatInfo, updateList, countChatList, exitChat, sendChat, chatKind, kind, chatLeft, setChatLeft }) => {
  const [chatData, setChatData] = useState([]);
  const [chatMenu, setChatMenu] = useState(false);
  const [chatPhotos, setChatPhotos] = useState(false);
  const [chatMsg, setChatMsg] = useState('');

  const [imageList, setImageList] = useState([]);
  const [imageStart, setImageStart] = useState(null);

  const [openReport, setOpenReport] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [estimateInfo, setEstimateInfo] = useState({ open: false });
  const [resumeInfo, setResumeInfo] = useState({ open: false });
  const [calendalModal, setCaledarModal] = useState(false);

  let chatDateInfo = null;
  let scrollMode = 'instant';
  let chatCode = chatInfo.workDate + chatInfo.sqenNumb;
  const messageEndRef = useRef();
  const imageSelector = useRef();
  const send = function (message, Type = 'message', headNumb = '') {
    console.log({ userId: utils.user('id'), message, Type, headNumb });
    chat.send(chatCode, { userId: utils.user('id'), message, Type, headNumb });
  };
  const connect = function () {
    chat.connect(chatCode, (message) => {
      let data = utils.fromJSON(message?.data);
      if (data?.success && data?.data.length > 0) {
        console.log('onMessage', data);
        setChatData((chatData) => chatData.concat(data?.data));
        utils.isFunction(updateList) && updateList();
        //상대방 메시지 읽기처리
        if (data?.data[0] && data.data[0].userId !== utils.user('id')) {
          utils.http.setMode('api');
          utils.http.get(`/v1/SP_r_Chat_R1/${data.data[0].workDate}/${data.data[0].sqenNumb}`).then(() => {});
        }
      }
    });
  };

  const chatImageList = () => {
    const reverseChat = utils.deepCopy(chatData.filter((x) => x.rcdType === 'chatImage')).reverse();
    return reverseChat;
  };

  const chatImageAlbum = function () {
    const reverseChatData = utils.deepCopy(chatData.filter((x) => x.rcdType === 'chatImage')).reverse();
    // reveseChatData를 ftDtDatetime 을 기준으로 묶어서 2중 배열로 만든다.
    // e.g [{ftDt: ftDtDatetime, images: [{fileName: fileName, ...}, ...]}, ...]
    const chatImageList = [];
    let chatImage = [];
    let ftDtDatetime = '';
    reverseChatData.forEach((chat) => {
      if (ftDtDatetime !== chat.ftDtDatetime) {
        ftDtDatetime = chat.ftDtDatetime;
        chatImage = [];
        chatImageList.push({ ftDt: ftDtDatetime, images: chatImage });
      }
      chatImage.push(chat);
    });
    console.log('chatImageList', chatImageList);

    return chatImageList;
    // return utils.deepCopy(chatData.filter((x) => x.rcdType === 'chatImage')).reverse();
  };
  const sendMsg = function () {
    if (utils.isNotEmpty(chatMsg)) {
      send(chatMsg);
      setChatMsg('');
      updateList();
    }
  };
  const sendImg = function (images) {
    console.log({
      files: images,
      rcdContent: '',
      sqenNumb: chatInfo.sqenNumb,
      workDate: chatInfo.workDate
    });
    utils.http.setMode('api');
    utils.http
      .postFile('/v1/SP_r_ChatImage_i1', {
        files: images,
        rcdContent: '',
        sqenNumb: chatInfo.sqenNumb,
        workDate: chatInfo.workDate
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          res.data.data.forEach((message) => {
            send('', message.rcdType, message.headNumb);
          });
        }
        //imageSelector.current.value = "";
      });
  };
  const exitChatRoom = async () => {
    if (await utils.dialog.confirm('채팅방 나가기', chatInfo.partName + '님과 대화를 종료하시겠습니까?', 'question')) {
      utils.http.get(`/v1/SP_r_ChatList_D2/${chatInfo.workDate}/${chatInfo.sqenNumb}`).then((res) => {
        if (res.data.success) {
          res.data.data.forEach((message) => {
            send('', 'chatNotice', message.headNumb);
          });
          chat.close(chatCode);
          utils.isFunction(exitChat) && exitChat();
          updateList();
        } else {
          utils.dialog.alert('채팅방 나가기 오류!', '오류가 발생했습니다. 다시 시도해 주세요.', 'error');
        }
      });
    }
  };
  const moveBottom = () => {
    messageEndRef.current.scrollIntoView({
      block: 'end',
      behavior: scrollMode
    });
    scrollMode = 'smooth';
  };

  useEffect(() => {
    if (chatInfo.workDate && chatInfo.sqenNumb) {
      utils.http.setMode('api');
      utils.http.get(`/v1/SP_r_Chat_S1/${chatInfo.workDate}/${chatInfo.sqenNumb}`).then(({ data }) => {
        chatDateInfo = null;
        setChatData(data?.data.reverse());

        connect();
      });

      utils.isFunction(countChatList) && countChatList(chatInfo.workDate, chatInfo.sqenNumb, 'read');

      setChatMsg('');
      setChatMenu(false);
      setChatPhotos(false);

      scrollMode = 'instant';

      utils.isFunction(sendChat) &&
        sendChat({
          chatEstimate(headNumb) {
            send('', 'chatEstimate', headNumb);
          }
        });
      //인턴인경우 이력서 확인
      if (kind === 'intern') {
        utils.http.get(`/v1/SP_I_internProfileCheck_S1/${chatInfo.userId}`).then(({ data }) => {
          setResumeInfo({
            open: false,
            internId: chatInfo.userId,
            isResume: data?.data.length > 0
          });
        });
      }
    }
  }, [chatInfo]);

  useEffect(() => {
    moveBottom();
    setChatLeft(chatData.at(-1)?.rcdType === 'chatLeft');
    //setChatLeft(chatData.at(-1)?.rcdType === "payComplete")
  }, [chatData]);
  return (
    <section className={'chat-room-container'}>
      <section className={'chat-room-box'}>
        <Scrollbars className="chat-view-box scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
          <div className={'chat-view'}>
            {chatData.map((chat) => (
              <React.Fragment key={'chatroom' + chat.workDate + chat.sqenNumb + chat.headNumb}>
                {chatDateInfo !== chat.ftDtDatetime && <section className={'chat-date'}>{chat.ftDtDatetime}</section>}
                {(chatDateInfo = chat.ftDtDatetime) && ''}
                {['chatNotice', 'chatLeft'].includes(chat.rcdType) ? <section className={'chat-notice'}>{chat.rcdContent}</section> : <EstimateCard chat={chat} chatInfo={chatInfo} moveBottom={moveBottom} setEstimateInfo={setEstimateInfo} setImageList={setImageList} chatImageList={chatImageList} setImageStart={setImageStart} setCaledarModal={setCaledarModal} />}
              </React.Fragment>
            ))}
          </div>
          <span ref={messageEndRef}></span>
        </Scrollbars>
        <section className={'chat-input-box'}>
          <button
            type={'button'}
            className={'menu-button'}
            title={'메뉴'}
            onClick={(e) => {
              setChatMenu(true);
              setChatPhotos(false);
            }}
          >
            <MenuRoundedIcon />
          </button>
          <button
            type={'button'}
            className={'image-add-button'}
            title={'이미지 추가'}
            onClick={(e) => {
              imageSelector.current.click();
            }}
            disabled={chatLeft}
          >
            <AddRoundedIcon />
          </button>

          <div className={'input-area'}>
            <input
              type={'file'}
              multiple={false}
              ref={imageSelector}
              accept={'image/jpg, image/jpeg, image/png'}
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  let images = [];
                  for (let idx = 0; idx < e.target.files.length; idx++) {
                    images.push(e.target.files[idx]);
                  }
                  sendImg(images[0]);
                }
              }}
            />
            <input
              type={'text'}
              placeholder={chatLeft ? '상대방이 채팅방을 나갔습니다.' : '메시지를 입력해 주세요.'}
              value={chatLeft ? '' : chatMsg}
              onInput={(e) => {
                setChatMsg(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === utils.const.keyCode.ENTER) {
                  sendMsg();
                }
              }}
              disabled={chatLeft}
            />
            <button
              type={'button'}
              className={'send-button'}
              onClick={() => {
                sendMsg();
              }}
              disabled={chatLeft}
            >
              <ArrowUpwardRoundedIcon />
            </button>
          </div>
        </section>
      </section>
      <div className={'top-gradient'}></div>

      <section
        className={'chat-menu-back ' + (chatMenu ? 'open' : 'close')}
        onClick={(e) => {
          if (e.target.className.includes('chat-menu-back')) setChatMenu(false);
        }}
      ></section>
      <section className={'chat-menu-box ' + (chatMenu ? 'open' : 'close')}>
        <ul className={'menu-list chat-menu'}>
          <li
            onClick={() => {
              setChatPhotos((view) => !view);
            }}
          >
            <InsertPhotoOutlinedIcon />
            <span className={'label'}>사진</span>
            <button type={'button'} className={'more'}>
              <ChevronRightRoundedIcon />
            </button>
          </li>
          {kind === 'intern' && (
            <li
              onClick={() => {
                if (resumeInfo.isResume) {
                  // setResumeInfo((resumeInfo) => Object.assign({}, resumeInfo, { open: true }));
                  //위에 코드를 사용하면 resumeInfo.isResume 값이 false로 변경되어서 아래와 같이 사용
                  setResumeInfo({ ...resumeInfo, open: true });
                } else {
                  utils.dialog.alert('이력서 없음', chatInfo.partName + '님의 이력서가 없습니다.', 'warning');
                }
              }}
            >
              <ContactPageOutlinedIcon />
              <span className={'label'}>이력서</span>
            </li>
          )}
          <li
            onClick={() => {
              setOpenReport(true);
            }}
          >
            <WbTwilightIcon />
            <span className={'label'}>신고하기</span>
          </li>
          <li
            onClick={() => {
              setOpenBlock(true);
            }}
          >
            <BlockIcon />
            <span className={'label'}>차단하기</span>
          </li>
        </ul>
        <ul className={'menu-list chat-out'}>
          <li onClick={exitChatRoom}>
            <LogoutIcon />
            <span className={'label'}>채팅방 나가기</span>
          </li>
        </ul>
      </section>
      <section className={'chat-menu-photos ' + (chatMenu && chatPhotos ? 'open' : 'close')}>
        {chatImageAlbum().length > 0 ? (
          <Scrollbars className="chat-photo-box scrollbar-container outside-scrollbar" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
            <section className={'photo-box'}>
              {chatImageAlbum().map((date) => (
                <div className="date-box">
                  <div className="date-title">{date?.ftDt}</div>
                  <div className="image-box">
                    {date?.images.map((image) => (
                      <div
                        className={'photo'}
                        key={'chatimages' + image.workDate + image.sqenNumb + image.headNumb}
                        onClick={() => {
                          setImageList(chatImageList().map((x) => x.fileName));
                          setImageStart(image.fileName);
                        }}
                      >
                        <img src={process.env.REACT_APP_IMAGE_VIEW_URL + image.fileName} alt={''} />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </section>
          </Scrollbars>
        ) : (
          <EmptyBox style={{ paddingBottom: '85px' }}>주고받은 사진이 없습니다.</EmptyBox>
        )}
        <button
          type={'button'}
          className={'close'}
          onClick={() => {
            setChatPhotos(false);
          }}
        >
          <CloseRoundedIcon />
        </button>
      </section>
      <ImageViewModal
        open={imageList.length > 0 && imageStart !== null}
        close={() => {
          setImageList([]);
          setImageStart(null);
        }}
        title="사진"
        imageList={imageList}
        imageStart={imageStart}
      />
      <UserReportModal
        open={openReport}
        close={() => {
          setOpenReport(false);
        }}
        title="신고"
        gubn={'SearchChat'}
        head={''}
        part={''}
        sqen={chatInfo.sqenNumb}
        work={chatInfo.workDate}
        nick={chatInfo.partName}
      />
      <UserBlockModal
        open={openBlock}
        close={() => {
          setOpenBlock(false);
        }}
        title="차단"
        userId={chatInfo.userId}
        nick={chatInfo.partName}
        afterAction={() => {
          utils.isFunction(updateList) && updateList();
          utils.isFunction(exitChat) && exitChat();
        }}
      />
      <EstimateViewModal
        open={estimateInfo.open}
        close={() => {
          setEstimateInfo({ open: false });
        }}
        title="견적서"
        estimateInfo={estimateInfo}
        kind={'chat'}
      />
      <InternProfileModal
        open={resumeInfo.open}
        close={() => {
          setResumeInfo({ ...resumeInfo, open: false });
        }}
        title={chatInfo.partName}
        userId={chatInfo.userId}
      />
      <ModalComponent
        open={calendalModal}
        setOpen={() => {
          setCaledarModal(false);
        }}
      >
        <CustomCalendar />
      </ModalComponent>
    </section>
  );
};

export default ChatRoom;

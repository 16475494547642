import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 97px);
  display: flex;
  gap: 30px;
  margin-top: 30px;
`;

export const TopSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BtnMenu = styled.div`
  width: 34.5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 1024px) {
    width: 20%;
    gap: 20px;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 34.5% - 30px);
  height: auto;
  background-color: #37465b;
  border-radius: 20px;
  gap: 24px;
  padding: 29px 30px;
  transition: all 0.3s ease;
  &.reference-image-box {
    align-self: flex-start;
    max-height: max(525px, calc(100vh - 316px));
  }

  @media screen and (max-width: 1024px) {
    padding: 20px;
    width: calc(100% - 20% - 20px);
  }
`;

export const SaveText = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  > div {
    font-size: 16px;
    font-weight: bold;
    color: #ccc;
  }
`;

export const SaveBtn = styled.button`
  width: auto;
  height: 40px;
  border-radius: 5px;
  background-color: #5affe2;
  color: #212838;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 20px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
  line-height: 1;

  .icon {
    color: #212838;
    font-size: 25px;
    line-height: 1;
  }

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    padding: 4px 10px;
    height: auto;
    .icon {
      font-size: 18px;
    }
  }
`;

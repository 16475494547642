import { OpenModal, Section2, ModalHeader2, CloseBtn, Main } from './ModalElements';
import { IoMdClose } from 'react-icons/io';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useMediaQuery } from 'react-responsive';

const WindowFrame2 = ({ width, height, modalHeaderStyle, modalBodyStyle, open, close, title, children, scroll, slotFixedButton }) => {
  scroll = scroll ?? true;

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  return (
    <>
      {open && (
        <OpenModal>
          <Section2 width={isMobile ? '100%' : width} height={isMobile ? '100%' : height}>
            <ModalHeader2 style={modalHeaderStyle && modalHeaderStyle}>
              {title}
              <CloseBtn onClick={close}>
                <IoMdClose size="40" style={{ color: '#fff' }} />
              </CloseBtn>
            </ModalHeader2>
            <section className={'main-section'} style={modalBodyStyle && modalBodyStyle.main_section}>
              {scroll ? (
                <Scrollbars className="modal-main modal-main-scrollbar scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
                  <Main>{children}</Main>
                </Scrollbars>
              ) : (
                <Main className={'modal-main'} style={modalBodyStyle && modalBodyStyle.modal_main}>
                  {children}
                </Main>
              )}
              {slotFixedButton && (
                <div className={'fixed-button'} style={modalBodyStyle && modalBodyStyle.fixed_button}>
                  {slotFixedButton}
                </div>
              )}
            </section>
          </Section2>
        </OpenModal>
      )}
    </>
  );
};

export default WindowFrame2;

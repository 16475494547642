import Lists from '../../../../Parts/Common/TabLists/Lists';
import DetailContent from '../../../../Parts/Common/TabLists/DetailContent';
import { Container, ChatItem, ChatPreview, NickName, SendBtn, SentDate } from '../../../../Parts/Common/TabLists/ViewListsElements';
import React, { useEffect, useState } from 'react';
import utils from 'modules/utils';
import chat from 'modules/chat';
import ProfileImage from '../../../../Parts/Common/ProfileImage';
import ChatRoom from '../ChatRoom/ChatRoom';
import { useNavigate, useParams } from 'react-router-dom';
import SmallInfoModal from 'components/Parts/Common/MenuInfo/SmallInfoModal';

const modalStyle = {
  width: '501px',
  height: 'auto',
};
let infoModalData = '수리가 끝났다면, 고객에게 후기를 요청해 보세요! 고객이 작성한 후기글 확인이 가능합니다.';

const TabIntern = () => {
  const navigate = useNavigate();
  const { workDate, sqenNumb } = useParams();

  const [chatList, setChatList] = useState([]);
  const [chatRoom, setChatRoom] = useState({});
  const [chatSend, setChatSend] = useState(null);
  const [chatLeft, setChatLeft] = useState(false);

  utils.event.on('pushInternChat', (pushWorkDate, pushSqenNumb, pushMessage) => {
    updateChatList();
  });
  const countChatList = function (chkWorkDate, chkSqenNumb, mode, message) {
    let updateCount = false;
    let updatedList = chatList.map((chat) => {
      if (chat.workDate === chkWorkDate && chat.sqenNumb === chkSqenNumb) {
        updateCount = true;
        if (mode === 'read') chat.rcdRead = 0;
      }
      return chat;
    });
    setChatList(updatedList);
    //채팅 카운팅 이벤트 발생
    utils.event.emit('loadChatCount', 'intern', updatedList);
    return updateCount;
  };

  const updateChatList = function () {
    utils.http.setMode('api');
    utils.http.get('/v1/SP_p_partChatInternList_S1').then(({ data }) => {
      if (data?.data.length > 0) {
        if (workDate && sqenNumb) {
          if (utils.isEmpty(chatRoom)) {
            let item = data?.data.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
            if (item.length > 0) setChatRoom(item[0]);
          } else {
            if (data.data[0].workDate === workDate && data.data[0].sqenNumb === sqenNumb) {
              data.data[0].rcdRead = 0;
            }
          }
        }
        setChatList(() => data.data);
        //채팅 카운팅 이벤트 발생
        utils.event.emit('loadChatCount', 'intern', data.data);
      } else {
        setChatList([]);
      }
    });
  };
  useEffect(() => {
    if (utils.isNotEmpty(chatRoom)) {
      navigate(`/manage/intern/intern/${chatRoom.workDate}/${chatRoom.sqenNumb}`);
    }
  }, [chatRoom]);
  useEffect(() => {
    updateChatList();
    if (!workDate || !sqenNumb) setChatRoom({});
  }, []);
  useEffect(() => {
    let item = chatList.filter((x) => x.workDate === workDate && x.sqenNumb === sqenNumb);
    if (item.length > 0) setChatRoom(item[0]);

    if (!workDate || !sqenNumb) setChatRoom({});
    chat.closeAll();
  }, [workDate, sqenNumb]);
  return (
    <Container>
      <Lists
        title={'채팅'}
        empty={chatList.length === 0}
        emptyMessage={'인턴과 주고 받은 채팅 내역이 없습니다.'}
        infoModalData={'등록한 구인 내역에서 지원자의 이력서를 보고 선택했다면, 채팅으로 대화를 할 수 있습니다.'}
        slotList={chatList.map((chat, idx) => (
          <ChatItem
            key={'chatlist' + chat.workDate + chat.sqenNumb}
            onClick={() => {
              setChatRoom(chat);
            }}
            className={chatRoom.workDate + chatRoom.sqenNumb === chat.workDate + chat.sqenNumb && 'selected'}
          >
            <section className={'profile-box'}>
              <ProfileImage partProfile={1} width={'60px'} height={'60px'} kind={'user'} id={chat.userId}></ProfileImage>
            </section>
            <NickName>{chat.partName}</NickName>
            <SentDate>{chat.ftDt}</SentDate>
            <ChatPreview>
              <section className={'preview'}>{chat.rcdContent}</section>
              <section className={'no-read-section'}>{chat.rcdRead > 0 && <div className={'no-read-count'}>{chat.rcdRead}</div>}</section>
            </ChatPreview>
          </ChatItem>
        ))}
      />
      {chatList.length > 0 && (
        <DetailContent
          title={chatRoom.partName + '님과의 대화'}
          userId={chatRoom.userId}
          empty={utils.isNull(chatRoom.workDate) || utils.isNull(chatRoom.sqenNumb)}
          emptyMessage={'채팅 목록에서 대화를 선택해 주세요.'}
          adFitCode="DAN-dVfxUR7iUxss2eOB"
          slotContent={
            <ChatRoom
              chatInfo={chatRoom}
              updateList={updateChatList}
              countChatList={countChatList}
              exitChat={() => {
                setChatRoom({});
                navigate(`/manage/intern/intern`);
                updateChatList();
              }}
              sendChat={setChatSend}
              chatKind={'달인인턴 채팅'}
              kind={'intern'}
              chatLeft={chatLeft}
              setChatLeft={setChatLeft}
            ></ChatRoom>
          }
        />
      )}
    </Container>
  );
};

export default TabIntern;

import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { partInfoStore } from 'modules/store/store';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import utils from './../../../../modules/utils/index';
import PopupModal from './PopupModal';

const imgURL = process.env.REACT_APP_IMAGE_VIEW_URL;

const EventPopup = () => {
  const [open, setOpen] = useState(false);
  const [hasCookies, setHasCookies] = useCookies();
  const [popupData, setPopupData] = useState([]);
  const { partInfo, getPartInfo } = partInfoStore();
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: false,
  };

  const handleCheckBoxClick = () => {
    const expires = dayjs().add(1, 'day').toDate();
    setHasCookies('eventPopup', true, { expires });
    setOpen(false);
  };

  const getPopupData = () => {
    const url = `/v1/SP_h_Home_event_S1/partnerWeb`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data.data.length > 0) {
          console.log('@@@@popup data:', res.data.data);
          setPopupData(res.data.data);
          hasCookies.eventPopup ? setOpen(false) : setOpen(true);
          return;
        }
        setPopupData([]);
        setOpen(false);
      })
      .catch((error) => {
        console.error('Error fetching popup data:', error);
      });
  };

  useEffect(() => {
    getPopupData();
    getPartInfo().then(() => {
      if (!partInfo?.partnerFirstTicket || hasCookies.eventPopup || partInfo.partnerFirstTicket === 'N') {
        return;
      }
    });
  }, []);

  return (
    <PopupModal
      open={open}
      setOpen={setOpen}
      width={'400px'}
      height={'420px'}
      PopupFooter={
        <Box
          sx={{
            position: 'absolute',
            bottom: '16px',
            right: '16px',
            zIndex: 1,
            fontSize: '14px',
            color: '#fff',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={handleCheckBoxClick}
        >
          오늘 하루 보지 않기
        </Box>
      }
    >
      <Slider {...settings}>
        {popupData.map((item) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            key={item?.sqenNumb}
            onClick={() => {
              navigate('/manage/ticket/ticketList');
            }}
          >
            <img src={`${imgURL}${item?.file1}`} alt={item?.title} style={{ width: '100%', height: 'auto' }} />
          </Box>
        ))}
      </Slider>
    </PopupModal>
  );
};

export default EventPopup;

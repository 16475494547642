import styled from 'styled-components';

export const FileUpload = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  align-items: center;
  gap: 30px;
  grid-template-columns: 100px auto;
  grid-template-rows: 100px;
  .file-list {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 15px;
  }
`;

export const FileLabel = styled.label`
  width: 80px;
  height: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: solid 1px #8a8a8a;
  background-color: #fff;
  cursor: pointer;
  border-radius: 10px;
  color: #10141c;
  font-size: 18px;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const ImgWrapper = styled.div`
  width: 80px;
  height: 80px;
  flex: 0 0 100px;
  border-radius: 10px;
  border: solid 1px #8a8a8a;
  background: #fff;
  overflow: hidden;
  position: relative;

  .icons {
    font-size: 40px;
  }
`;

export const DeleteBtn = styled.button`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  font-size: 20px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
`;

import utils from 'modules/utils';

let validCheck = {
  isIdValid(id) {
    if (id !== '') {
      return true;
    } else {
      return false;
    }
  },
  isNullPassword(pw) {
    if (pw !== '') {
      return true;
    } else {
      return false;
    }
  },
  isPasswordValid(pw) {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    return passwordRegex.test(pw);
  },
  isSamePassword(pw, checkPw) {
    if (pw === checkPw) {
      return true;
    } else {
      return false;
    }
  },
  isNameValid(name) {
    if (name !== '') {
      return true;
    } else {
      return false;
    }
  },
  isNickValid(nick) {
    if (nick !== '') {
      return true;
    } else {
      return false;
    }
  },
  isNullPhoneNumber(phone) {
    if (phone !== '') {
      return true;
    } else {
      return false;
    }
  },
  isPhoneNumberValid(phone) {
    const phoneRegex = /^01([0|1|6|7|8|9])(\d{3,4})(\d{4})$/;

    return phoneRegex.test(phone);
  },
  isNullEmail(email) {
    if (email !== '') {
      return true;
    } else {
      return false;
    }
  },
  isEmailValid(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    console.log('emailRegex.test(email) : ', email, emailRegex.test(email));

    return emailRegex.test(email);
  },
};
Object.freeze(validCheck);
export default validCheck;

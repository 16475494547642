import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';
import styled from 'styled-components';
const RatingBox = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  display: inline-flex;
  flex-wrap: nowrap;
  width: auto;
  gap: ${(props) => props.gap}px;
  height: ${(props) => props.height}px;
  color: #7e6eff;
`;

const Rating = ({ score, size, gap }) => {
  size = size ?? 19;
  gap = gap ?? 3;
  score = parseFloat(score);
  const frontDecimal = Math.floor(score);
  const backDecimal = Math.floor((score - frontDecimal) * 10);

  let starIcon = [];
  for (let i = 0; i < frontDecimal; i++) {
    starIcon.push(<BsStarFill color="#7e6eff" size={size} key={`BsStarFill${i}`} />);
  }
  if (backDecimal) {
    for (let j = 0; j < 1; j++) {
      starIcon.push(<BsStarHalf color="#7e6eff" size={size} key={`BsStarHalf${j}`} />);
    }
  }
  for (let k = 0; k < 5 - Math.round(score); k++) {
    starIcon.push(<BsStar color="#7e6eff" size={size} key={`BsStar${k}`} />);
  }
  return (
    <RatingBox height={size} gap={gap}>
      {starIcon}
      {score.toFixed(1)}
    </RatingBox>
  );
};

export default Rating;

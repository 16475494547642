import React, { useState, useEffect } from 'react';
import ImageUpload from './ImageUpload1';
import { ContentWrap, SaveText } from 'components/Manage/Info/InfoElements';
import { styled } from 'styled-components';
import { WhiteDot } from 'style';
import utils from 'modules/utils';
import { imageToImageArr } from 'modules/Upload/imageUpload';

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyleDiv = styled.div`
  font-size: 20px;
  line-height: 1;
  font-weight: normal;
  color: #e9e9e9;

  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

const TabPromotion = ({ setSave }) => {
  const [partnerTopImages, setPartnerTopImage] = useState([]);
  const [partnerShowImages, setPartnerShowImage] = useState([]);
  const [delPartnerTopImages, setDelPartnerTopImage] = useState([]);
  const [delPartnerShowImages, setDelPartnerShowImage] = useState([]);

  const getImages = async () => {
    try {
      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_p_partCompInfo_S2/${utils.user('id')}`);
      console.log('data', res.data.data);
      if (res.data.data && res.data.data.length > 0) {
        let images = { partnerTopImage: [], partnerShowImage: [] };
        for (let kind in images) {
          images[kind] = res.data.data.filter((obj) => obj.dir === kind).slice(0, 5);
        }
        setPartnerTopImage(imageToImageArr(images.partnerTopImage));
        setPartnerShowImage(imageToImageArr(images.partnerShowImage));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 저장 버튼 클릭했을 때 api 요청
  const onSave = async () => {
    try {
      const formData = new FormData();
      partnerTopImages.forEach((image) => {
        if (image.localFlag) {
          formData.append('files', image.file);
        }
      });
      partnerShowImages.forEach((image) => {
        if (image.localFlag) {
          formData.append('files', image.file);
        }
      });
      let delKey = '';
      delPartnerTopImages.forEach((image) => {
        delKey += image.serverFileName + '_//';
      });
      delPartnerShowImages.forEach((image) => {
        delKey += image.serverFileName + '_//';
      });
      formData.append('delKey', delKey);

      utils.http.setMode('api');
      await utils.http.postFile('/v1/SP_p_partCompInfo_wi4', formData).then((res) => {
        if (res.status === 200) {
          utils.dialog.alert('저장 완료!', '입력하신 내용이 저장되었습니다.<br />주기적으로 관리하여<br /> 우리 업체를 홍보해요!', 'success');
        } else {
          utils.dialog.alert('저장 실패!', '오류가 발생했습니다. 관리자에게 문의해 주세요.', 'error');
        }
        setPartnerShowImage([]);
        setPartnerTopImage([]);
        setDelPartnerShowImage([]);
        setDelPartnerTopImage([]);
        getImages();
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (setSave) {
      setSave({ onSave });
    }
    //console.log("promotion", imgFile, imgDelKey);
  }, [partnerTopImages, partnerShowImages, delPartnerTopImages, delPartnerShowImages]);

  useEffect(() => {
    getImages();
  }, []);
  return (
    <ContentWrap>
      <Div>
        <ImageUpload title="상단사진" subTitle="달인 정보 메인 상단에 슬라이드로 보여지는 이미지입니다." tabMenu="promotion" setImgDelKey={setDelPartnerTopImage} setImgFile={setPartnerTopImage} images={partnerTopImages} kind="partnerTopImage" />
        <ImageUpload title="홍보사진" subTitle="달인 정보 아래 공간에 우리 가게만의 장점, 혜택 등을 홍보할 수 있습니다." tabMenu="promotion" setImgDelKey={setDelPartnerShowImage} setImgFile={setPartnerShowImage} images={partnerShowImages} kind="partnerShowImage" />
        <div>
          <StyleDiv>
            <WhiteDot style={{ margin: '0 5px 5px' }} />
            이미지: 가로 800px, 20MB이하의 JPG, JPEG, PNG 파일 등록 가능
          </StyleDiv>
          <StyleDiv>
            <WhiteDot style={{ margin: '0 5px 5px' }} />
            최대 5개 업로드 가능
          </StyleDiv>
        </div>
      </Div>
      <SaveText>
        <div>※ 정보 입력 및 수정 시, 저장버튼을 눌러주세요</div>
      </SaveText>
    </ContentWrap>
  );
};

export default TabPromotion;

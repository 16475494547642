import styled from 'styled-components';

export const BtnWrap = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
`;

export const Btn = styled.button`
  padding: 0px 10px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  border-radius: 5px;
  border: solid 1px #7e6eff;
  background-color: ${(props) => props.bg_color};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #7e6eff;
  }
`;

//import common from "./utils.common";
const storage = {
  local(key, value) {
    if (value === undefined) {
      return JSON.parse(localStorage.getItem(key));
    } else if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  },
  session(key, value) {
    if (value === undefined) {
      return JSON.parse(sessionStorage.getItem(key));
    } else if (value === null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  },
  temp(value) {
    let key = '__temp__1239919';
    if (value === undefined) {
      let data = JSON.parse(localStorage.getItem(key));
      localStorage.removeItem(key);
      return data;
    } else if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  },
};
Object.freeze(storage);
export default storage;

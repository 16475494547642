import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Base } from '../FrameElements';
import SalesHistory from './SalesHistory';
import WithdrawManage from './WithdrawManage';

const Transaction = () => {
  return (
    <Base>
      <Routes>
        <Route path="salesHistory" element={<SalesHistory />} />
        <Route path="withdrawManage" element={<WithdrawManage />} />
      </Routes>
    </Base>
  );
};

export default Transaction;

import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal } from '@mui/material';

const PopupModal = ({ open, setOpen, children, PopupFooter, width, height }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width ?? '50%',
          height: height ?? '50%',
          color: '#fff',
          boxShadow: 24,
          borderRadius: '10px',
          bgcolor: '#212838',
          // focus 없애기
          outline: 'none',
          overflow: 'hidden',
        }}
      >
        <IconButton
          onClick={handleClose}
          color="error"
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            zIndex: 1,
            color: '#fff',
            // 그림자
          }}
        >
          <Close
            sx={{
              fontSize: '30px',
              fontWeight: 'bold',
            }}
          />
        </IconButton>
        {children}
        {PopupFooter && PopupFooter}
      </Box>
    </Modal>
  );
};

export default PopupModal;

import React, { useState, useEffect, useRef } from 'react';
import './Calendar.scss';
import { styled } from 'styled-components';
import dayjs from 'dayjs';
import { Divider } from '@mui/material';
import utils from 'modules/utils';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DefaultCalendar from './DefaultCalendar';
import { set } from 'date-fns';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Card } from 'components/Manage/Ticket/TicketList/TicketListElements';
import CancleReservationModal from './Parts/Common/Modal/CancleReservationModal';
import FavoritePhraseModal from './Parts/Common/Modal/FavoritePhraseModal';
import { red } from '@mui/material/colors';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitBtn } from './Manage/News/QuestionList/QuestionListElements';

// 스타일드 컴포넌트로 스타일 정의
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: #fff;
`;

const CalendarWrap = styled.div`
  width: 100%;
  height: 660px;
  padding: 30px;
  background-color: #37465b;
  border-radius: 20px;
`;

const ScheduleBox = styled.div`
  min-width: 430px;
  width: 430px;
  height: 660px;
  border-radius: 20px;
  padding: 30px;
  background-color: #37465b;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ScheduleTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const ScrollContainer = styled.div`
  height: 200px;
  width: 100%;
  overflow: auto;
  padding: 4px 0;
`;

const ScheduleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const TimeBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: bold;
`;

const ReserveBtn = styled.button`
  background-color: var(--mint-color);
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
`;

const TimeCard = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #0b0e14;
  color: #fff;
  border: 1px solid #8a8a8a;
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  /* props flag가 2일때만 pointer */
  cursor: ${(props) => (props.flag === 1 ? 'pointer' : 'default')};
  &:hover {
    border: ${(props) => (props.flag === 1 ? '1px solid var(--mint-color)' : '1px solid #8a8a8a')};
    svg {
      color: ${(props) => (props.flag === 1 ? 'var(--mint-color)' : '#8a8a8a')};
    }
  }
`;

const TimeCardRow = styled.div`
  display: flex;
  align-items: center;
`;

const ViewBtn = styled.button`
  color: var(--mint-color);
  font-size: 14px;
  font-weight: bold;
`;

const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const CardTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8a8a8a;
`;

const TimeCardRowLabel = styled.div`
  color: #8a8a8a;
  width: 90px;
`;

const CloseDayText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8a8a8a;
  font-size: 24px;
  font-weight: bold;
`;

const CancleCard = (item) => (
  <div>
    <TimeCardRow>
      <TimeCardRowLabel>예약자</TimeCardRowLabel>
      <div>{item?.user_name}</div>
    </TimeCardRow>
    <TimeCardRow>
      <TimeCardRowLabel>예약날짜</TimeCardRowLabel>
      <div>{item?.reser_day}</div>
    </TimeCardRow>
    <TimeCardRow>
      <TimeCardRowLabel>예약시간</TimeCardRowLabel>
      <div>{item?.reser_time}</div>
    </TimeCardRow>
  </div>
);

const EmptyCard = (item) => (
  <CardBody>
    <CardTitle>예약 정보가 없습니다.</CardTitle>
    <CardContent>
      <div>예약을 마감하고 싶다면</div>
      <div>[예약마감] 버튼을 눌러주세요.</div>
    </CardContent>
  </CardBody>
);

const DeadLineCard = (item) => (
  <CardBody>
    <CardTitle>예약 마감 되었습니다.</CardTitle>
    <CardContent>
      <div>예약마감을 취소하고 싶다면</div>
      <div>[마감취소]버튼을 눌러주세요.</div>
    </CardContent>
  </CardBody>
);

const CustomCalendar = () => {
  const [closeDay, setCloseDay] = useState([]);

  const [schedule, setSchedule] = useState([]);
  const [selectTime, setSelectTime] = useState('');
  const [FullCalendarMonth, setFullCalendarMonth] = useState(dayjs().format('YYYY-MM'));

  const [open, setOpen] = useState(false);

  const [events, setEvents] = useState([]);
  const [onlyUserEvents, setOnlyUserEvents] = useState([]);
  const [viewEvnets, setViewEvents] = useState([]);

  const calendarRef = useRef(null);
  const [selectDate, setSelectDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectDateIsCloseDay, setSelectDateIsCloseDay] = useState(false); // 선택한 날짜가 휴무일인지 여부
  const [modalInfo, setModalInfo] = useState(null);

  const navigate = useNavigate();
  const { workDate, sqenNumb } = useParams();

  const modalClose = () => {
    setOpen(false);
  };
  const getCloseDay = async () => {
    const url = `/v1/SP_p_partner_S1/${utils.user('id')}`;
    utils.http.setMode('api');
    const res = await utils.http.get(url);
    if (res.data.data && res.data.success) {
      console.log('getCloseDay : ', res.data.data[0].CloseDay);
      setCloseDay(res.data.data[0].CloseDay);
    }
  };
  const getDaySchedule = (selectDate) => {
    const url = `/v2/SP_r_ChatReservation_S1/${utils.user('id')}/${selectDate}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data.data && res.data.success) {
          console.log('getDaySchedule : ', res.data.data);
          const data = res.data.data;
          // data에서 user_id가 존재하고 빈문자가 아닌경우 만 필터링
          const filterData = data.filter((item) => utils.isNotEmpty(item.user_id));
          const onlyUser = filterData.filter((item) => item.user_id !== utils.user('id'));

          setSchedule(data);
          setOnlyUserEvents(onlyUser);
          setViewEvents(onlyUser);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getMonthSchedule = () => {
    const url = `/v2/SP_r_ChatReservation_S2/${utils.user('id')}/${FullCalendarMonth}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data.data && res.data.success) {
          console.log('getMonthSchedule :', res.data.data);
          const data = res.data.data.map((item) => {
            return {
              title: item.user_id === 'soodal' ? '마감' : '예약',
              date: item.reser_day,
              backgroundColor: item.user_id === 'soodal' ? '#8a8a8a' : 'var(--mint-color)',
              extendedProps: {
                ...item,
              },
            };
          });
          console.log('data', data);
          setEvents(data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const setCloseDayBoolean = (day) => {
    if (closeDay.includes(day)) {
      setSelectDateIsCloseDay(true);
    } else {
      setSelectDateIsCloseDay(false);
    }
  };

  const handleSelectRange = (selectInfo) => {
    const day = selectInfo.start.getDay();
    setSelectDate(selectInfo.startStr);
    setSelectTime('');

    getDaySchedule(selectInfo.startStr);
    // 휴무일이면
    setCloseDayBoolean(day);
  };

  const handleSelectEvent = (selectInfo) => {
    const eventDay = selectInfo.event.start.getDay();

    setSelectDate(selectInfo.event.startStr);
    setSelectTime('');
    getDaySchedule(selectInfo.event.startStr);

    // 휴무일이면
    setCloseDayBoolean(eventDay);
  };

  const handleDatesSet = (selectInfo) => {
    console.log('selectInfo', selectInfo);
    const regex = /(\d{4})년\s(\d{1,2})월/;
    const result = selectInfo.view.title.match(regex);
    const year = result[1];
    const month = result[2].length === 1 ? `0${result[2]}` : result[2];
    const fullMonth = `${year}-${month}`;

    console.log('fullMonth', fullMonth);
    setFullCalendarMonth(fullMonth);
  };

  const refreshCalendar = () => {
    // 월별조회
    // 만약 선택한 날짜와 시간이 있다면 해당 날짜와 시간도 조회
    getMonthSchedule();
    if (utils.isNotEmpty(selectDate)) {
      getDaySchedule(selectDate);
    }
    let prevTiem = selectTime;
    if (utils.isNotEmpty(selectTime)) {
      setSelectTime('');
    }
    setTimeout(() => {
      setSelectTime(prevTiem);
    }, 100);
  };

  // 예약 마감
  const handleReserveClose = (item, flag) => {
    console.log('handleReserveClose : ', item);
    const url = `/v2/SP_r_ChatReservation_I2`;
    const data = {
      workDate: item.work_date,
      sqenNumb: item.sqen_numb,
      reserGubn: flag === 0 ? '0' : '1',
    };
    utils.http.setMode('api');
    utils.http
      .post(url, null, data)
      .then((res) => {
        if (res.data.success) {
          utils.dialog.alert(flag === 0 ? '예약이 마감되었습니다.' : '예약마감이 취소되었습니다.', '', 'success').then(() => {
            refreshCalendar();
          });
        } else {
          utils.dialog.alert('예약마감에 실패하였습니다.', '', 'warning');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getAllViewList = () => {
    setViewEvents(onlyUserEvents);
    setSelectTime('');
  };

  const MoveToChatRoom = (e, item) => {
    e.stopPropagation();
    console.log('MoveToChatRoom : ', item);
    if (utils.isEmpty(item?.chatWorkDate) || utils.isEmpty(item?.chatSqenNumb)) return;
    if (utils.isNotEmpty(workDate) || utils.isNotEmpty(sqenNumb)) return;
    navigate(`/manage/history/chat/${item?.chatWorkDate}/${item?.chatSqenNumb}`);
  };

  useEffect(() => {
    getMonthSchedule();
  }, [FullCalendarMonth]);

  useEffect(() => {
    if (selectTime === '') return;
    const filterData = schedule.filter((item) => item.reser_time === selectTime.reser_time);
    setViewEvents(filterData);
  }, [selectTime]);

  useEffect(() => {
    const calendarFlag = utils.user('useCalendar');
    if (!calendarFlag) {
      utils.dialog.alert('수달캘린더를 사용하시려면 [달인정보 -> 예약설정]을 저장해주세요', '', 'warning').then(() => {
        navigate('/manage/info/partnerInfo');
      });
    }
  });

  useEffect(() => {
    getCloseDay();
  }, []);

  return (
    <Container>
      <CalendarWrap>
        <DefaultCalendar height={'600px'} calendarRef={calendarRef} events={events} handleSelectRange={handleSelectRange} handleSelectEvent={handleSelectEvent} handleDatesSet={handleDatesSet} closeDay={closeDay} />
      </CalendarWrap>
      <ScheduleBox>
        <div style={{ fontSize: '22px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
          <div>일정</div>
          <div>{selectDate}</div>
        </div>

        <Divider sx={{ bgcolor: '#fff' }} />
        <div>
          <ScheduleTitle>예약시간</ScheduleTitle>
          <ScrollContainer className="scrollbar-container2">
            {selectDateIsCloseDay ? (
              <CloseDayText>휴무일입니다.</CloseDayText>
            ) : (
              <Grid container md={12} spacing={1}>
                {schedule.map((item, index) => {
                  const backgroundColor = utils.isNotEmpty(item?.user_id) ? (item?.user_id === 'soodal' ? '#8a8a8a' : '#0b0e14') : '#37465b00';
                  return (
                    <Grid
                      md={2.4}
                      key={index}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: backgroundColor,
                          color: item?.deadline ? '#565656' : '#fff',
                          border: `1px solid ${selectTime.reser_time === item.reser_time ? 'var(--mint-color)' : '#8a8a8a'}`,
                          cursor: 'pointer',
                          width: '100%',
                          padding: '8px 0',
                          borderRadius: '10px',
                        }}
                        onClick={() => {
                          setSelectTime(item);
                        }}
                      >
                        {item?.reser_time}
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </ScrollContainer>
        </div>
        <Divider sx={{ bgcolor: '#fff' }} />
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <ScheduleTitle>예약자 정보</ScheduleTitle>
            {viewEvnets.length <= 1 && <ViewBtn onClick={getAllViewList}>전체보기</ViewBtn>}
          </div>
          <ScrollContainer className="scrollbar-container2">
            {utils.isNotEmpty(selectTime) && (
              <ScheduleHeader>
                <TimeBox>
                  <div>•</div>
                  <div>{selectTime?.reser_time}</div>
                </TimeBox>
              </ScheduleHeader>
            )}
            {onlyUserEvents.length > 0 || selectTime !== '' ? (
              viewEvnets.map((item, index) => {
                // 0: 예약없음, 1: 예약완료, 2: 예약마감
                const flag = utils.isNotEmpty(item?.user_id) ? (item?.user_id === 'soodal' ? 2 : 1) : 0;
                return (
                  <TimeCard key={index} flag={flag} onClick={(e) => MoveToChatRoom(e, item)}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {flag === 0 && EmptyCard(item)}
                      {flag === 1 && CancleCard(item)}
                      {flag === 2 && DeadLineCard(item)}
                      {flag === 1 && !!item?.chatWorkDate && !!item?.chatSqenNumb && <ArrowForwardIosRoundedIcon />}
                    </div>
                    {
                      //현재 시간보다 이전 시간이면 버튼 비활성화
                      !dayjs(`${item.reser_day} ${item.reser_time}`).isBefore(dayjs(), 'time') && (
                        <SubmitBtn
                          style={{
                            height: '30px',
                            marginTop: '10px',
                            width: '100%',
                            backgroundColor: flag === 2 ? '#8a8a8a' : '#7e6eff',
                          }}
                          flag={flag}
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (flag === 1) {
                              setModalInfo(item);
                              setOpen(true);
                            } else if (flag === 0 || flag === 2) {
                              handleReserveClose(item, flag);
                            }
                          }}
                        >
                          {flag === 0 && '예약마감'}
                          {flag === 1 && '예약취소'}
                          {flag === 2 && '마감취소'}
                        </SubmitBtn>
                      )
                    }
                  </TimeCard>
                );
              })
            ) : (
              <TimeCard flag={0}>
                <div
                  style={{
                    color: 'var(--mint-color)',
                    fontSize: '18px',
                    marginBottom: '10px',
                  }}
                >
                  {selectDate}
                </div>
                <div>예약 정보가 없습니다.</div>
              </TimeCard>
            )}
          </ScrollContainer>
        </div>
      </ScheduleBox>
      <CancleReservationModal open={open} close={modalClose} title={'예약취소'} modalInfo={modalInfo} refresh={refreshCalendar} />
    </Container>
  );
};

export default CustomCalendar;

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Divider, styled } from '@mui/material';
import SelectRegions from 'components/Area/SelectRegions2';
import { ticketInfo } from 'components/Manage/Ticket/TicketList/TicketList';
import { partInfoStore, reservationPaymentStore } from 'modules/store/store';
import { useEffect, useState } from 'react';
import utils from './../../../../modules/utils/index';
import CanclePaymentModal from './CanclePaymentModal';
import ChangePaymentModal from './ChangePaymentModel';
import { Form, SubmitBtn } from './ModalElements';
import { MainTitle, MoreBtn, PrimaryColorBox, SubTitle, TicketBox, TicketDiscount, TicketFooter, TicketIcon, TicketTitle } from './TicketModalElements';
import WindowFrame from './WindowFrame';

const ModalBody = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
`;

const ModalTitle = styled(Box)`
  font-size: 20px;
  line-height: 1.5;

  //모바일
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const CustomTicket = ({ ticket, selectedTicket, setSelectedTicket, chipDataLength, prevChipData }) => {
  /**
   * nowGubn: 0 전체 결제내역조회, 2 현재이용중 조회,
   */
  const { nextPartInfo } = reservationPaymentStore();
  const { partInfo } = partInfoStore();
  const handleClick = async () => {
    if (ticket?.ticket_level === nextPartInfo?.nextLevel) {
      utils.dialog.alert('이미 예약 중인 구독권입니다.', '다른 구독권으로 예약하시겠습니까?', 'warning');
      return;
    }
    if (chipDataLength > ticket.area_count) {
      utils.dialog.alert('노출 지역을 다시 설정해주세요.', '선택한 지역이 구독권의 지역설정 개수보다 많습니다.', 'error');
      return;
    }
    setSelectedTicket(ticket);
  };

  const checkFirstPrice = () => {
    if (partInfo.partnerFirstTicket === 'Y') {
      return ticket.first_sale_price;
    } else {
      return ticket.sale_price;
    }
  };

  const checkFirstDiscount = () => {
    if (partInfo.partnerFirstTicket === 'Y') {
      return ticket.first_discount;
    } else {
      return ticket.discount;
    }
  };

  return (
    <TicketBox
      onClick={handleClick}
      sx={{
        opacity: selectedTicket?.ticket_level === undefined ? 1 : selectedTicket?.ticket_level === ticket?.ticket_level ? 1 : 0.5,
      }}
    >
      <TicketTitle>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            fontSize: '20px',
          }}
        >
          {ticketInfo
            .filter((item) => item.title === ticket?.level_name)
            .map((param, index) => (
              <TicketIcon key={index} sx={{ backgroundColor: param.color }}>
                {param?.icon}
              </TicketIcon>
            ))}
          <Box>{ticket.level_name}</Box>
          {ticket?.ticket_level === nextPartInfo?.nextLevel && (
            <Box
              sx={{
                color: 'var(--primary-color)',
              }}
            >
              · 예약중
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Box sx={{ display: 'flex', width: '40px', height: '20px' }}>{checkFirstDiscount() > 0 && <TicketDiscount>{checkFirstDiscount() + '%'}</TicketDiscount>}</Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {ticket.price !== checkFirstPrice() && <Box sx={{ fontSize: '14px', color: '#8a8a8a', textDecoration: 'line-through' }}>{ticket.price.toLocaleString()}원</Box>}
            <Box sx={{ fontSize: '22px' }} noDiscountPrice={ticket.price !== checkFirstPrice()}>
              {checkFirstPrice()?.toLocaleString()}원
            </Box>
          </Box>
        </Box>
      </TicketTitle>
      <Divider
        sx={{
          width: '100%',
          backgroundColor: '#8a8a8a',
        }}
      />
      <TicketFooter>
        <Box>견적 무제한</Box>
        <PrimaryColorBox>+ 지역설정 {ticket.ticket_level === 6 ? '무제한' : ticket.area_count + '개'}</PrimaryColorBox>
      </TicketFooter>
    </TicketBox>
  );
};

const ChangeAreaAndTicketModal = ({ open, close, title, chipData, setChipData, refreshList, prevChipData, handleCancelTicket, getReservedCity }) => {
  const { ticketList, getTicketList } = reservationPaymentStore();
  const [moreTicket, setMoreTicket] = useState(true);
  const [recommendTicket, setRecommendTicket] = useState({});
  const [selectedTicket, setSelectedTicket] = useState({});
  const [showTicketPaymentModal, setShowTicketPaymentModal] = useState(false);
  const [customerUid, setCustomerUid] = useState(null);
  const [cancleModalOpen, cancleSetModalOpen] = useState(false);
  const [reserArea, setReserArea] = useState([]);
  // 유저 지금 쓰고있는 카드 조회
  const getUserCustomerID = () => {
    const url = `/v1/SP_b_user_card_s2`;
    utils.http.setMode('api');
    utils.http.get(url).then((response) => {
      console.log('getUserCustomerID', response.data.data[0].customer_uid);
      setCustomerUid(response.data.data[0].customer_uid);
    });
  };
  const handleChange = () => {
    setShowTicketPaymentModal(true);
  };

  // 변경하기 재확인 후 변경
  const handleChangeTicket = () => {
    const url = `/v1/setIamportScheduleChange`;
    const data = {
      part_level: selectedTicket?.level_name,
      customer_uid: customerUid,
    };
    console.log('selectedTicket', selectedTicket);

    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      if (res.data.success) {
        utils.dialog.alert('구독권 변경', '구독권 변경이 완료되었습니다.', 'success').then((res) => {
          res && close();
          refreshList();
        });
        console.log('구독권 변경 성공', res);
      } else {
        utils.dialog.alert('구독권 변경', '구독권 변경이 실패되었습니다.', 'warning');
        console.log('구독권 변경 실패', res);
      }
    });
  };

  const savePartAreaReservation = () => {
    const url = `/v1/SP_p_partPlusReservation_i1`;
    const data = {
      partArea: chipData?.map((x) => x?.cd).join('_//'),
    };
    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      setReserArea(res.data.data);
      console.log('savePartAreaReservation', res);
    });
  };
  // 해지하기
  const CancleTicketConfirm = async () => {
    await utils.dialog.confirm('정말 해지하시겠습니까?', '', 'question').then((res) => {
      if (res) {
        handleCancelTicket();
      }
    });
  };

  useEffect(() => {
    getReservedCity();
    getTicketList();
    getUserCustomerID();
    setSelectedTicket({});
    setMoreTicket(true);
  }, [open]);

  const diamondTicket = ticketList.filter((item) => item?.ticket_level === 6)[0];

  useEffect(() => {
    const filterTicket = ticketList.filter((ticket) => ticket?.area_count >= chipData?.length);
    const recommendTicketInfo = ticketInfo.filter((item) => item?.title === filterTicket.at(-1)?.level_name)[0];

    setRecommendTicket({ ...filterTicket.at(-1), ...recommendTicketInfo });
    // setSelectedTicket({ ...filterTicket.at(-1), ...recommendTicketInfo }); // 자동선택
    setSelectedTicket({});
  }, [chipData]);
  return (
    <WindowFrame
      open={open}
      close={close}
      title={title}
      width={'49.1875rem'}
      height={'90vh'}
      slotFixedButton={
        <SubmitBtn type="button" onClick={handleChange} disabled={selectedTicket?.ticket_level === undefined}>
          변경
        </SubmitBtn>
      }
    >
      <Form action="#none">
        <Box
          sx={{
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '.625rem',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MainTitle>원하는 노출 지역을 설정해주세요.</MainTitle>
            <SubTitle>노출 지역이 넓을수록 고객을 찾는 범위가 넓어집니다.</SubTitle>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <MainTitle>노출 지역</MainTitle>
            <SubTitle
              sx={{
                color: 'var(--mint-color)',
              }}
            >
              선택한 지역 : {chipData?.length ?? 0}개
            </SubTitle>
          </Box>
          <SelectRegions chipData={chipData} setChipData={setChipData} />
          {console.log('chipData', chipData)}
          <MainTitle sx={{ width: '100%' }}>변경할 구독권을 선택해주세요.</MainTitle>
          <SubTitle
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            월 구독료 · 부가세포함
          </SubTitle>
          {recommendTicket?.ticket_level !== 6 && <CustomTicket ticket={recommendTicket} areaText={`지역설정 ${recommendTicket?.area_count}개`} selectedTicket={selectedTicket} setSelectedTicket={setSelectedTicket} chipData={chipData} prevChipData={prevChipData} />}
          {diamondTicket && <CustomTicket ticket={diamondTicket} areaText={'지역설정 무제한'} selectedTicket={selectedTicket} setSelectedTicket={setSelectedTicket} />}
          {moreTicket ? (
            <MoreBtn
              onClick={() => {
                setMoreTicket(false);
              }}
              fullWidth
              variant="outlined"
            >
              구독권 접기
              <ArrowDropUpIcon
                sx={{
                  color: 'var(--primary-color)',
                }}
              />
            </MoreBtn>
          ) : (
            <MoreBtn
              onClick={() => {
                setMoreTicket(true);
              }}
              fullWidth
              variant="outlined"
            >
              구독권 전체보기
              <ArrowDropDownIcon
                sx={{
                  color: 'var(--primary-color)',
                }}
              />
            </MoreBtn>
          )}
          {moreTicket &&
            ticketList
              .filter((item) => {
                return item?.ticket_level !== 6 && item?.ticket_level !== recommendTicket?.ticket_level;
              })
              .map((ticket) => <CustomTicket ticket={ticket} areaText={`지역설정 ${ticket?.area_count}개`} selectedTicket={selectedTicket} setSelectedTicket={setSelectedTicket} chipDataLength={chipData?.length} chipData={chipData} prevChipData={prevChipData} />)}
        </Box>
        <div
          style={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: '#fff',
            fontSize: '16px',
            cursor: 'pointer',
          }}
          onClick={() => {
            cancleSetModalOpen(true);
          }}
        >
          <span style={{ borderBottom: '1px solid #fff' }}>해지하기</span>
        </div>
      </Form>

      <ChangePaymentModal open={showTicketPaymentModal} close={() => setShowTicketPaymentModal(false)} title="구독권 변경" handleChangeTicket={handleChangeTicket} savePartAreaReservation={savePartAreaReservation}>
        <ModalBody>
          <div>
            <i className="dialog material-symbols-rounded" style={{ color: '#ff4b4b', fontSize: '100px' }}>
              error
            </i>
          </div>
          <ModalTitle>정말 구독권을 변경하시나요?</ModalTitle>
          <ModalTitle>현재 이용중인 해택들이 모두 변경됩니다.</ModalTitle>
        </ModalBody>
      </ChangePaymentModal>

      {/* 구독권 해지 모달 */}
      <CanclePaymentModal
        open={cancleModalOpen}
        close={() => {
          cancleSetModalOpen(false);
        }}
        secondBtn={
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              color: '#fff',
              fontSize: '23px',
              fontWeight: 'bold',
              border: 'solid 2px #7e6eff',
              borderRadius: '5px',
              padding: '5px 0',
              marginTop: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              CancleTicketConfirm();
            }}
          >
            해지 신청
          </div>
        }
      >
        <ModalBody>
          <div>
            <i className="dialog material-symbols-rounded" style={{ color: '#ff4b4b', fontSize: '100px' }}>
              error
            </i>
          </div>
          <ModalTitle>정말 구독권을 해지하시나요?</ModalTitle>
          <ModalTitle>현재 이용중인 해택들이 모두 사라집니다.</ModalTitle>
        </ModalBody>
      </CanclePaymentModal>
    </WindowFrame>
  );
};

export default ChangeAreaAndTicketModal;

import React, { useState } from 'react';
import { DetailBox, SearchOption, WithdrawListBox } from './TransactionElements';
import { TitleText, WtNomalSpan } from 'style';
import CustomSelect from 'components/Parts/Common/CustomSelect/CustomSelect';
import DateInput from 'components/Parts/Common/DateInput/DateInput';
import WithdrawApplyList from './WithdrawApplyList';
import EmptyBox from 'components/Parts/Common/EmptyBox';
import { Scrollbars } from 'react-custom-scrollbars-2';
import utils from 'modules/utils';
import moment from 'moment';

const Details = ({ getStatus }) => {
  const options = [
    { value: 9, label: '전체' },
    { value: 0, label: '진행중' },
    { value: 1, label: '완료' },
    { value: 2, label: '출금거절' },
  ];
  const customStyle = {
    selectOptions: {
      height: '95px',
      top: '38px',
    },
    selectBox: {
      width: '100%',
    },
    label: {
      color: '#10141c',
    },
  };
  // select box value state
  const [selected, setSelected] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [applyList, setApplyList] = useState([]);

  const getApplyList = async () => {
    try {
      if (getStatus) {
        getStatus();
      }
      let ftdt = moment(startDate).format('YYYY-MM-DD');
      let etdt = moment(endDate).format('YYYY-MM-DD');

      utils.http.setMode('api');
      const res = await utils.http.get(`/v1/SP_p_partnerSale_S4/${selected}/${ftdt}/${etdt}`);

      // console.log(res.data.data);
      if (res.data.data && res.data.data.length > 0) {
        setApplyList(res.data.data);
      } else {
        setApplyList([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DetailBox>
      <TitleText style={{ marginBottom: '30px' }}>출금 신청 내역</TitleText>
      <SearchOption>
        <div className="select-box">
          <CustomSelect options={options} customStyle={customStyle} choiced={options[1].label} setSelected={setSelected} />
        </div>
        <div className="select-date">
          <DateInput dateFormat="yyyy-MM-dd" value={startDate} onChange={setStartDate} />
        </div>
        <WtNomalSpan style={{ width: '43px', textAlign: 'center', fontSize: '20px' }}>~</WtNomalSpan>
        <div className="select-date" style={{ marginRight: '30px' }}>
          <DateInput dateFormat="yyyy-MM-dd" value={endDate} onChange={setEndDate} />
        </div>
        <button type="button" className="inquiry-btn" onClick={getApplyList}>
          조회
        </button>
      </SearchOption>
      <WtNomalSpan style={{ display: 'block', marginTop: '30px' }}>전체 {applyList.length}건</WtNomalSpan>
      <WithdrawListBox>
        {applyList.length > 0 ? (
          <Scrollbars
            className="scrollbar-container"
            renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <WithdrawApplyList applyList={applyList} />
          </Scrollbars>
        ) : (
          <EmptyBox>출금 신청 내역이 없습니다.</EmptyBox>
        )}
      </WithdrawListBox>
    </DetailBox>
  );
};

export default Details;

import React, { useState, useEffect } from 'react';
import Lists from '../../../Parts/Common/TabLists/Lists';
import DetailContent from '../../../Parts/Common/TabLists/DetailContent';
import SearchInput from '../../../Parts/Common/SearchInput/SearchInput';
import utils from '../../../../modules/utils';
import { Container } from '../../../Parts/Common/TabLists/ViewListsElements';
import TabButton from '../../../Parts/Common/TabButton/TabButton';
import EmergencyModal from '../../../Parts/Common/Modal/EmergencyModal';
import { infoModalEmergency } from '../../../../Data/data';
import BigInfoModal from '../../../Parts/Common/MenuInfo/BiginfoModal';
import styled from 'styled-components';
import SentEmergencyContent from './SentEmergencyContent';
import { useNavigate, useParams } from 'react-router-dom';
import SentEmergencyList from './SentEmergencyList';
import ReceivedEmergencyList from './ReceivedEmergencyList';
import FilterBtn from '../../../Parts/Common/FilterBtn/FilterBtn';
import SafePhoneNumb from 'components/Tooltip/SafePhoneNumb';

const ContainerWrap = styled(Container)`
  padding-top: 20px;
  height: calc(100% - 37px);
  .list-wrap {
    width: 37%;
  }
  .detail-content-wrap {
    width: 63%;
  }
`;

export const ResultTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  .totalLength {
    width: 30%;
    height: 30px;
    font-size: 18px;
    margin-bottom: 15px;
  }
`;
// 위도, 경도 받아오기
let lat = '0.0';
let lon = '0.0';
navigator.geolocation.getCurrentPosition((position) => {
  lat = position.coords.latitude ?? '0.0';
  lon = position.coords.longitude ?? '0.0';
});

const EmergencyMain = () => {
  const navigate = useNavigate();
  const { kind, workDate, sqenNumb } = useParams();

  const modalStyle = {
    width: '659px',
    height: '140px',
  };

  //보낸견적 필터
  const [gubn, setGubn] = useState(0);
  const [filterList, setFilterList] = useState([]);

  const getFilterList = () => {
    utils.http.setMode('api');
    utils.http.get(`/v1/SP_b_Common_Code_SI/30`).then(({ data }) => {
      setFilterList(() => data?.data);
    });
  };

  useEffect(() => {
    getFilterList();
  }, []);

  // 버튼 state
  const [tabMenu, setTabMenu] = useState(kind);
  const [emergencyList, setEmergencyList] = useState([]);
  const [emergencyView, setEmergencyView] = useState({});
  const [searchWord, setSearchWord] = useState('');
  const [emergencyDetail, setEmergencyDetail] = useState({});

  // 긴급출동 modal 창 열기/닫기
  const [emergencyModal, setEmergencyModal] = useState(false);
  const openEmergencyModal = () => {
    setEmergencyModal(true);
  };
  const closeEmergencyModal = () => {
    setEmergencyModal(false);
  };

  const menuName = function () {
    return kind === 'received' ? '출동 요청' : '출동 견적';
  };

  const updateSentEmergencyList = (searchWord, gubn) => {
    const url = `/v1/SP_p_partChatEmergencyList_S1/${gubn}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data && res.data.data) {
          updateSearchList(searchWord, res.data.data);
          updateView(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateReceivedEmergencyList = (searchWord, yvalue, xvalue) => {
    const url = `/v2/SP_p_partEmergencyListV2_S1`;
    const data = {
      value: searchWord,
      xvalue,
      yvalue,
    };
    utils.http.setMode('api');
    utils.http
      .post(url, null, data)
      .then((res) => {
        if (res.data && res.data.data) {
          updateSearchList(searchWord, res.data.data);
          updateView(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateSearchList = function (searchWord, searchList) {
    if (searchWord === '') {
      setEmergencyList(searchList);
    } else {
      setEmergencyList(searchList.filter((x) => x.subject.includes(searchWord) || x.content.includes(searchWord)));
    }
  };

  const updateView = function (list) {
    if (workDate && sqenNumb) {
      let item = list.filter((x) => x?.workDate === workDate && x?.sqenNumb === sqenNumb);
      if (item.length > 0) {
        setEmergencyView(item[0]);
        getDetailEmergency(item[0].workDate, item[0].sqenNumb);
      } else setEmergencyView({});
    } else {
      setEmergencyView({});
    }
  };

  const getDetailEmergency = (workDate, sqenNumb) => {
    const url = `/v1/SP_p_partEmergencyView_S2/${workDate}/${sqenNumb}`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data && res.data.data) {
        console.log('@@@@@@@@', res.data.data[0]);
        setEmergencyDetail(res.data.data[0]);
      } else {
        setEmergencyDetail({});
      }
    });
  };

  const updateList = function (searchWord) {
    if (kind === 'received') {
      updateReceivedEmergencyList(searchWord, lat, lon);
    } else {
      updateSentEmergencyList(searchWord, gubn);
    }
  };
  const refreshList = function () {
    updateList(searchWord);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (tabMenu === kind) {
      updateView(emergencyList);
    }
  }, [workDate, sqenNumb]);

  useEffect(() => {
    setSearchWord('');
    setTabMenu(kind);
    updateList('');
  }, [kind]);

  useEffect(() => {
    if (filterList.length > 0 && kind === 'sent') {
      updateSentEmergencyList(searchWord, gubn);
    }
  }, [gubn]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <BigInfoModal infoModalData={infoModalEmergency} menu="긴급출동 목록" style={modalStyle} />
      </div>
      <ContainerWrap>
        <Lists
          noSearch={emergencyList.length === 0}
          noSearchMessage={searchWord ? `검색된 ${menuName()} 목록이 없습니다.` : `새로운 ${menuName()} 목록이 없습니다.`}
          slotFilter={
            <>
              <TabButton
                leftBtn="출동 요청"
                rightBtn="출동 견적"
                tabMenu={tabMenu}
                setTabMenu={(tabMenu) => {
                  navigate(`/manage/history/emergencyList/${tabMenu}`);
                }}
              />
              <SearchInput
                inputVal={searchWord}
                setInputVal={setSearchWord}
                onSearch={() => {
                  updateList(searchWord);
                }}
              />
              <ResultTop>
                <div className="totalLength">전체 {utils.format.number(emergencyList.length)}건</div>
                {kind === 'sent' && (
                  <FilterBtn
                    setSelect={setGubn}
                    select={gubn}
                    list={filterList}
                    style={{
                      width: '70%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  />
                )}
              </ResultTop>
            </>
          }
          slotList={
            tabMenu === 'received' ? (
              <ReceivedEmergencyList
                emergencyView={emergencyView}
                setEmergencyView={(emergencyView) => {
                  navigate(`/manage/history/emergencyList/received/${emergencyView?.workDate}/${emergencyView?.sqenNumb}`);
                }}
                emergencyList={emergencyList}
                openEmergencyModal={openEmergencyModal}
                lat={lat}
                lon={lon}
              />
            ) : (
              <SentEmergencyList
                emergencyView={emergencyView}
                setEmergencyView={(emergencyView) => {
                  navigate(`/manage/history/emergencyList/sent/${emergencyView?.workDate}/${emergencyView?.sqenNumb}`);
                }}
                emergencyList={emergencyList}
              />
            )
          }
        />
        <DetailContent
          title={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div>견적 내용</div>
              {/* emerGubn = 3 : 매칭성공 */}
              {emergencyDetail?.emerGubn === 3 && <SafePhoneNumb userId={emergencyDetail?.userId} />}
            </div>
          }
          adFitCode="DAN-J5Fsr0qT42VO9eaP"
          scrollBar={true}
          empty={utils.isEmpty(emergencyView)}
          emptyMessage={menuName() + ' 목록에서 견적을 선택해 주세요.'}
          slotContent={<SentEmergencyContent emergencyView={emergencyView} refreshList={refreshList} myPost={tabMenu === 'sent'} lat={lat} lon={lon} />}
        />
        <EmergencyModal open={emergencyModal} close={closeEmergencyModal} title="긴급출동" post={emergencyView} afterAction={() => {}} />
      </ContainerWrap>
    </>
  );
};

export default EmergencyMain;

import Rating from 'components/Parts/Common/Rating/Rating';
import utils from 'modules/utils';
import { useEffect, useRef, useState } from 'react';
import { NomalFont, StyleH1, StyleH3 } from 'style';
import EstimateViewModal from '../../../Parts/Common/Modal/EstimateViewModal';
import ProfileImage from '../../../Parts/Common/ProfileImage';
import ReportBlockButtons from '../../../Parts/Common/ReportBlockButtons';
import { Amount, AuthorInfo, AuthorInfoWrap, Category, Content, ContentBottom, ContentHeader, ContentMain, ContentSubInfo, ContentTitle, DeleteEstimate, DisableBtn, HeaderLeft, HeaderRight, ImageWrap, InfoContent, InfoTitle, Line, MainText, PartnerInfo, Post, RatingWrap, ShowEstimate, Stat, SubmitedList, SubmitedListHead, WritedDate } from './EstimateListElements';

const ReceivedEstimateContent = ({ estimateView, refreshList, toBottom }) => {
  const [detailData, setDetailData] = useState({});
  // 상세 보기 하단 견적 정보 state
  const [detailBottom, setDetailBottom] = useState([]);
  const getDetailEstimate = () => {
    const url = `/v1/SP_r_Request_s1/${estimateView.workDate}/${estimateView.sqenNumb}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        if (res.data && res.data.data) {
          console.log('🚀 ~ file: EstimateContent.jsx ~ line 23 ~ .then ~ res.data.data', res.data.data);
          setDetailData(Object.assign({}, estimateView, res.data.data[0]));
        } else {
          setDetailData({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 요청 목록 클릭 시 상세 하단 견적 정보 조회
  const getEstimateBottom = (toBottom) => {
    const url = `/v1/SP_r_Request_s2/${estimateView.workDate}/${estimateView.sqenNumb}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        console.log('🚀 ~ file: EstimateContent.jsx:67 ~ .then ~ res:', res);
        if (res.data?.data) {
          if (toBottom) res.data.data.toBottom = true;
          setDetailBottom(res.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const imgURL = process.env.REACT_APP_IMAGE_VIEW_URL;

  // 견적 받기 이미지 조회
  const [estimateFile, setEstimateFile] = useState([]);
  const getEstimateImages = () => {
    utils.http.setMode('api');
    utils.http
      .get(`/v1/SP_r_Request_s4/${estimateView.workDate}/${estimateView.sqenNumb}`)
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          setEstimateFile(res.data.data);
        } else {
          setEstimateFile([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 마감 유무 확인하는 함수
  const checkDeadline = async (workDate, sqenNumb) => {
    const url = `/v1/SP_r_Request_s6/${workDate}/${sqenNumb}`;
    let result = false;

    utils.http.setMode('api');
    await utils.http
      .get(url)
      .then((res) => {
        if (res.data.data[0].reqGubn === 2) {
          utils.dialog.alert('이미 마감된 견적입니다.', '', 'warning');
          refreshList();
          result = false;
        } else {
          result = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };

  // 견적 삭제 버튼 클릭 했을 때 api로 삭제 요청
  const deleteEstimate = async (workDate, sqenNumb, headNumb) => {
    await checkDeadline(workDate, sqenNumb).then(async (res) => {
      if (res && (await utils.dialog.confirm('정말 삭제하시겠습니까?', '', 'question'))) {
        try {
          const url = `/v1/SP_p_partRequestView_d1/${workDate}/${sqenNumb}/${headNumb}`;
          utils.http.setMode('api');
          const res = await utils.http.get(url);

          if (res.data.success) {
            utils.dialog.alert('삭제 완료!', '삭제가 완료되었습니다.', 'success');
          } else {
            utils.dialog.alert('삭제 실패!', res.data.message, 'error');
          }
        } catch (error) {
          console.log(error);
        }
        refreshList();
      }
    });
  };

  // 견적서 진행 현황 버튼 스타일
  const [statStyle, setStatStyle] = useState({
    backgroundColor: '#7e6eff',
    color: '#fff',
  });
  const getStatus = () => {
    console.log('detailData', detailData);

    if (detailData.reqGubnName === '견적중') {
      setStatStyle({ ...statStyle, backgroundColor: '#7e6eff', color: '#fff' });
    } else if (detailData.reqGubnName === '대기') {
      setStatStyle({ ...statStyle, backgroundColor: '#37465b', color: '#fff' });
    } else {
      setStatStyle({ ...statStyle, backgroundColor: '#ddd', color: '#8a8a8a' });
    }
  };

  // 하단 견적 정보에서 본인이 작성한 글인지 체크
  const [showBottom, setShowBottom] = useState([]);
  const isShowBottom = () => {
    const updatedDetailBottom = detailBottom.map((item) => {
      // 각 item 객체의 partId와 userId를 비교하여 활성화 여부를 판단합니다.
      const isButtonEnabled = item.partId === utils.user('id');

      // item 객체에 버튼 활성화 여부를 추가합니다.
      return {
        ...item, // 기존 item 속성을 그대로 유지
        isButtonEnabled, // 버튼 활성화 여부 추가
      };
    });

    setShowBottom(updatedDetailBottom);
    // detailBottom.map((item) => (item.partId === utils.user("id")))
    // if (detailBottom[0].partId === utils.user("id")) {
    //   setShowBottom(true);
    // } else {
    //   setShowBottom(false);
    // }
  };

  useEffect(() => {
    // isFileName이 true인 경우에만 이미지 조회
    getDetailEstimate();
    getEstimateImages();
    getStatus();
    getEstimateBottom(toBottom);

    let scrollSection = document.querySelector('.detail-content.scrollbar-container div:nth-child(1)');
    if (scrollSection) scrollSection.scrollTop = 0;
  }, [estimateView]);

  const bottomEndRef = useRef();
  useEffect(() => {
    if (detailBottom.length > 0) {
      isShowBottom();
    }
    if (detailBottom.toBottom) {
      setTimeout(() => {
        bottomEndRef.current.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [detailBottom]);

  const [estimateInfo, setEstimateInfo] = useState({ open: false });

  return (
    <>
      <Content>
        <ContentHeader>
          <HeaderLeft>
            <ContentTitle>{detailData.reqSubject}</ContentTitle>
            <ContentSubInfo>
              <Category>{detailData.cateName}</Category>
              <WritedDate>{detailData.ftDt}</WritedDate>
            </ContentSubInfo>
          </HeaderLeft>
          <HeaderRight>
            <Stat style={statStyle}>{detailData.reqGubnName}</Stat>
          </HeaderRight>
        </ContentHeader>
        <ContentMain>
          <Post>
            {estimateFile.length > 0 ? (
              estimateFile.map((img, index) => (
                <ImageWrap key={`file${index}-${img.sqenNumb}${img.workDate}`}>
                  <img src={`${imgURL}${img.fileName}`} alt="" style={{ maxWidth: '100%' }} />
                </ImageWrap>
              ))
            ) : (
              <></>
            )}
            <MainText>{detailData.reqContent}</MainText>
            <AuthorInfoWrap>
              <AuthorInfo>
                <InfoTitle>서비스</InfoTitle>
                <InfoContent>{detailData.cateName2}</InfoContent>
              </AuthorInfo>
              <AuthorInfo>
                <InfoTitle>주소</InfoTitle>
                <InfoContent>{detailData.reqAddr2}</InfoContent>
              </AuthorInfo>
              <AuthorInfo>
                <InfoTitle>진행희망일</InfoTitle>
                <InfoContent>{detailData.reqWantDate === 1 ? detailData.reqDate : '날짜미정'}</InfoContent>
              </AuthorInfo>
            </AuthorInfoWrap>

            <ReportBlockButtons
              gubn={'PartRequestView'}
              userId={detailData.userId}
              sqenNumb={detailData.sqenNumb}
              workDate={detailData.workDate}
              headNumb={''}
              userNick={detailData.userName}
              afterAction={() => {
                refreshList();
              }}
            />

            <Line />
            <ContentBottom>
              <StyleH3 style={{ marginBottom: '20px' }}>총 제출 견적서 {detailBottom.length}건</StyleH3>
              {detailBottom.length > 0 ? (
                <>
                  {showBottom.map((item, index) => {
                    return (
                      <SubmitedList key={`detail-${index}${item.sqenNumb}${item.workDate}`}>
                        <SubmitedListHead
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <PartnerInfo>
                            <ProfileImage width={'60px'} height={'60px'} kind={'partner'} id={item.partId} alwaysRefresh={true} partProfile={item.partProfile} />
                            <div>
                              <NomalFont
                                style={{
                                  padding: '3px 0',
                                  display: 'block',
                                }}
                              >
                                {detailData.reqGubn === 1 ? item.partName : item.partName.substring(0, 1) + '*'.repeat(item.partName.length - 1)}
                              </NomalFont>
                              {item.partId === utils.user('id') && <StyleH1 style={{ fontSize: '24px' }}>{utils.format.number(item.reqdMony)}원 부터~</StyleH1>}
                            </div>
                          </PartnerInfo>
                          <Amount>
                            <RatingWrap>
                              <Rating score={item.partReview} />
                            </RatingWrap>
                          </Amount>
                        </SubmitedListHead>
                        {item.isButtonEnabled && detailData.reqGubn === 1 ? (
                          <>
                            <div style={{ display: 'flex', gap: '35px' }}>
                              <ShowEstimate
                                type="button"
                                onClick={() => {
                                  setEstimateInfo({
                                    open: true,
                                    workDate: item.workDate,
                                    sqenNumb: item.sqenNumb,
                                    headNumb: item.headNumb,
                                    partId: item.partId,
                                  });
                                }}
                              >
                                견적서보기
                              </ShowEstimate>
                              <DeleteEstimate
                                type="button"
                                onClick={() => {
                                  deleteEstimate(item.workDate, item.sqenNumb, item.headNumb);
                                }}
                              >
                                삭제
                              </DeleteEstimate>
                            </div>
                          </>
                        ) : item.isButtonEnabled && detailData.reqGubn !== 1 ? (
                          <>
                            <DisableBtn>견적서가 마감되었습니다.</DisableBtn>
                          </>
                        ) : (
                          <>
                            <DisableBtn>고객과 견적서를 작성한 달인만 확인이 가능합니다.</DisableBtn>
                          </>
                        )}
                      </SubmitedList>
                    );
                  })}
                  <div ref={bottomEndRef}></div>
                </>
              ) : (
                <></>
              )}
            </ContentBottom>
          </Post>
        </ContentMain>
        <EstimateViewModal
          open={estimateInfo.open}
          close={() => {
            setEstimateInfo({ open: false });
          }}
          title="견적서"
          estimateInfo={estimateInfo}
          kind={'estimate'}
        />
      </Content>
    </>
  );
};

export default ReceivedEstimateContent;

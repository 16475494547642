import React, { useEffect, useState } from 'react';
import WindowFrame from './WindowFrame';
import { SubmitBtn } from './CheckPwModal';
import Chip from '@mui/material/Chip';
import { styled } from 'styled-components';
import utils from 'modules/utils';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

const ChipBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  background-color: #212838;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  height: 250px;
`;

const EmptyText = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: 500;
`;

const MenuTitle = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #555;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #212838;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #555;
`;

const MenuSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  &:first-child {
    border-right: 1px solid #555;
  }
`;

const ResetButtonBox = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResetButton = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #555;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--mint-color);
    color: var(--mint-color);
  }
`;

const MenuBody1 = styled.div`
  /* 한줄에 3개씩 보여주기 한줄은 40px*/
  width: 100%;
  height: 200px;
  overflow-y: auto;
  padding: 10px;
  button {
    width: 25%;
    height: 40px;
    border: 1px solid #555;
    background-color: #212838;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      border: 1px solid var(--mint-color);
      color: var(--mint-color);
    }
    &.active {
      border: 1px solid var(--mint-color);
      background-color: #212838;
      color: var(--mint-color);
    }
  }
  @media screen and (max-width: 1024px) {
    button {
      width: 50%;
    }
  }
`;

const MenuBody2 = styled.div`
  width: 100%;
  height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 10px;

  .button-box {
    width: 50%;
    height: 40px;
  }

  button {
    width: 100%;
    height: 100%;
    border: 1px solid #555;
    background-color: #212838;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    &:hover {
      border: 1px solid var(--mint-color);
      color: var(--mint-color);
    }
    &.active {
      border: 1px solid var(--mint-color);
      background-color: #212838;
      color: var(--mint-color);
    }
    &:disabled {
      color: #8b8b8b;
      cursor: not-allowed;
      &:hover {
        border: 1px solid #555;
        color: #8b8b8b;
      }
    }
  }

  //1024px 이하
  @media screen and (max-width: 1024px) {
    .button-box {
      width: 100%;
    }
  }
`;

const ChipBody = styled.div`
  height: 180px;
  overflow-y: auto;
  display: flex;
  flex-direction: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;
`;

const CategoryModal = ({ open, setOpen, title, chipData, setChipData }) => {
  const [cateMenuList2, setCateMenuList2] = useState([]);
  const [cateMenuList3, setCateMenuList3] = useState([]);
  const [selectedCate2, setSelectedCate2] = useState(null);
  const [userCateList, setUserCateList] = useState([]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const onSubmit = () => {
    setOpen(false);
  };

  const getReviewList = function () {
    const url = `/v2/SP_p_partCategorySub_s1/2`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data.success) {
        console.log('getReviewList', res.data.data);
        setUserCateList(res.data.data);
      }
    });
  };

  const getCateList2 = () => {
    const url = `/v2/SP_p_partCategoryMySub_s1`;
    utils.http.setMode('api');
    utils.http.get(url).then((res) => {
      if (res.data.success) {
        console.log('getCateList2', res.data.data);
        setCateMenuList2(res.data.data);
      }
    });
  };

  const getCateList3 = () => {
    const url = `/v2/SP_b_Category_sub_s1`;
    const data = {
      cateId: selectedCate2?.partCategory2,
    };
    utils.http.setMode('api');
    utils.http.post(url, null, data).then((res) => {
      console.log('getCateList3@@@@', chipData);
      if (res.data.success) {
        // res.data.data 배열과 userCateList 배열을 비교해서 중복되는 cate_sub_id가 있으면 제거 후 배열
        const filteredCateList = res.data.data.filter((cate) => {
          return userCateList.filter((userCate) => userCate.cate_sub_id === cate.cate_sub_id).length === 0;
        });

        setCateMenuList3(filteredCateList);
      }
    });
  };

  const resetChips = () => {
    setChipData([]);
  };

  useEffect(() => {
    getReviewList();
    getCateList2();
  }, []);

  useEffect(() => {
    if (open) {
      setSelectedCate2(null);
      setCateMenuList3([]);
    }
  }, [open]);

  useEffect(() => {
    if (selectedCate2) {
      getCateList3();
    }
  }, [selectedCate2]);

  return (
    <WindowFrame
      open={open}
      close={() => setOpen(false)}
      title={title}
      width={'1200px'}
      height={'100%'}
      scroll={0}
      slotFixedButton={
        <SubmitBtn
          type="button"
          onClick={() => {
            onSubmit();
          }}
        >
          저장
        </SubmitBtn>
      }
    >
      <div>
        <MenuContainer>
          <MenuSection>
            <MenuTitle>카테고리</MenuTitle>
            <MenuBody1 className="scrollbar-container2">
              {cateMenuList2.map((cate) => {
                return (
                  <button
                    key={cate.partCategory2}
                    className={selectedCate2?.partCategory2 === cate.partCategory2 ? 'active' : ''}
                    type="button"
                    onClick={() => {
                      setSelectedCate2(cate);
                    }}
                  >
                    {cate.partCateName}
                  </button>
                );
              })}
            </MenuBody1>
          </MenuSection>

          <MenuSection>
            <MenuTitle>서비스</MenuTitle>
            <MenuBody2 className="scrollbar-container2">
              {cateMenuList3.length > 0 ? (
                cateMenuList3.map((cate, key) => {
                  return (
                    <div className="button-box" key={cate.cate_sub_id}>
                      <button
                        key={cate.cate_sub_id + cate.cate_sub_name}
                        className={chipData.filter((chip) => chip.key === cate.cate_sub_id).length > 0 ? 'active' : ''}
                        type="button"
                        onClick={() => {
                          // chipData에 현재 클릭한 카테고리와 다른 parent를 가진 카테고리가 하나라도 있으면 chipData에서 모두 제거 후 현재 카테고리 추가
                          if (chipData.filter((chip) => chip.parent !== cate.cate_id).length > 0) {
                            setChipData([
                              ...chipData.filter((chip) => chip.parent === cate.cate_id),
                              {
                                key: cate.cate_sub_id,
                                label: selectedCate2.partCateName + ' > ' + cate.cate_sub_name,
                                parent: cate.cate_id,
                              },
                            ]);
                            return;
                          }

                          if (chipData.filter((chip) => chip.key === cate.cate_sub_id).length > 0) {
                            setChipData(chipData.filter((chip) => chip.key !== cate.cate_sub_id));
                            return;
                          }
                          setChipData([
                            ...chipData,
                            {
                              key: cate.cate_sub_id,
                              label: selectedCate2.partCateName + ' > ' + cate.cate_sub_name,
                              parent: cate.cate_id,
                            },
                          ]);
                        }}
                        disabled={cate.selected}
                      >
                        <div>{cate.cate_sub_name}</div>
                        {chipData.filter((chip) => chip.key === cate.cate_sub_id).length > 0 && <CheckRoundedIcon />}
                      </button>
                    </div>
                  );
                })
              ) : (
                // selectedCate2 가 없으면 서비스를 선택 해주세요. 있으면 서비스를 모두 등록했습니다.
                <EmptyText>{selectedCate2 ? '서비스를 모두 등록했습니다.' : '서비스를 선택 해주세요.'}</EmptyText>
              )}
            </MenuBody2>
          </MenuSection>
        </MenuContainer>

        <ChipBox onClick={() => setOpen(true)}>
          <ResetButtonBox>
            <EmptyText>{chipData.length === 0 && '서비스를 선택해주세요.'}</EmptyText>
            <ResetButton onClick={resetChips}>
              <div>설정초기화</div>
              <RefreshRoundedIcon />
            </ResetButton>
          </ResetButtonBox>
          <ChipBody className="scrollbar-container2">
            {chipData.map((data) => {
              return (
                <Chip
                  key={data.key}
                  label={data.label}
                  size="large"
                  onDelete={handleDelete(data)}
                  sx={{
                    '& .MuiChip-label': {
                      color: '#fff',
                    },
                    '& .MuiChip-deleteIcon': {
                      color: '#fff',
                      '&:hover': {
                        color: 'var(--mint-color)',
                      },
                    },
                    border: '1px solid var(--mint-color)',
                    fontSize: '18px',
                  }}
                />
              );
            })}
          </ChipBody>
          {/* 초기화버튼 만들기 */}
        </ChipBox>
      </div>
    </WindowFrame>
  );
};

export default CategoryModal;

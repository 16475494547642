import styled from 'styled-components';

export const DropDown = styled.div`
  position: relative;
  display: inline-block;
`;

/*=============== 프로필 부분 ================ */
export const Profile = styled.div`
  display: flex;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    width: 34px;
    height: 34px;
  }
`;

export const ProfileSubmenu = styled.ul`
  display: ${(props) => props.display};
  width: 208px;
  border-radius: 10px;
  background-color: #10141c;
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 10;
  padding: 6px 25px;
`;

export const SubmenuList = styled.li`
  display: flex;
  margin: 13px 0;
  cursor: pointer;
  color: #fff;
  line-height: 1;

  align-items: center;

  &:hover span,
  &:hover svg {
    color: #5affe2;
  }
  & svg {
    font-size: 20px;
  }
`;

export const Menu = styled.span`
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  margin-left: 14px;
`;

/*=============== 알림창 부분 ================ */
export const Mark = styled.span`
  position: absolute;
  top: -5px;
  right: -2px;
  height: 10px;
  width: 10px;
  background-color: #ef1818;
  border-radius: 50%;
  display: inline-block;
`;

export const AlertButton = styled.button`
  cursor: pointer;
  background: transparent;
  text-align: center;
  position: relative;
  svg {
    font-size: 48px;
    color: #fff;
  }

  @media screen and (max-width: 1024px) {
    width: 32px;
    height: 32px;
    svg {
      font-size: 32px;
    }
  }
`;

export const AlertSubmenu = styled.div`
  display: ${(props) => props.display};
  width: 496px;
  height: ${(props) => props.height}px;
  border-radius: 10px;
  background-color: #10141c;
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 10;
  padding: 30px;

  min-height: 185px;
  max-height: 80vh;

  grid-template-rows: 20px auto;
  grid-template-columns: 1fr;
  gap: 29px;

  @media screen and (max-width: 1024px) {
    width: 380px;
    padding: 20px;
  }
`;
export const TopTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
`;

export const ListWrap = styled.ul`
  width: 100%;
`;

export const AlertMenuList = styled.li`
  height: 76px;
  border-radius: 10px;
  background-color: #37465b;
  transition: background-color 0.5s;
  padding: 15px;

  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;

  margin-bottom: 15px;

  &:hover {
    background-color: #4f5a6b;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const AlertContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: normal;
  color: #fff;

  svg {
    font-size: 24px;
    flex: 0 0 24px;
    margin-right: 10px;
  }
  span {
    flex: 1 1 100%;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .new-icon {
    border-radius: 50%;
    flex: 0 0 20px;

    width: 20px;
    height: 20px;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 1;

    margin-left: 5px;

    padding: 0 1px 1px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ef1818;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    .notice-icon {
      font-size: 22px;
    }
    .new-icon {
      width: 16px;
      height: 16px;
      font-size: 11px;
    }
  }
`;

export const AlertDate = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: #8a8a8a;
  text-align: right;
  width: 100%;

  line-height: 1;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const NoneAlertMessage = styled.p`
  font-size: 18px;
  font-weight: normal;
  color: #8a8a8a;
`;

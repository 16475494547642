import React, { useState, useEffect } from 'react';
import { OpenModalBg, CloseModalBg, Section, BtnWrap, HeaderText, LineHeaderText, Main, MainContent, MainContentText, ImgContainer, VerifyBtn, SelectBtnWrap, BtnImage, FindBtn, BtnText, SubText, ArrowWrap, Arrow } from './ModalElements';
import { StyleH1 } from '../../../style';
import { CloseBtn } from '../../RegisterForm/ShowLawModal/ShowLawModalElements';
import InputIdModal from './InputIdModal';
import Image from 'assets/modal-image/modal_soodal.png';
import closeIcon from 'assets/modal-icon/close.png';
import AuthImage1 from '../../../assets/9.png';
import AuthImage2 from '../../../assets/8.png';
import ArrowImg from '../../../assets/10.png';

import portOne from 'modules/portOne';
import utils from 'modules/utils';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

window.portOne = portOne;

/**
 * 테스트 전화번호
 * 김재훈 01051308445
 * 이동규 01075580169
 * 우성현 01082873443
 * 황진우 01034110550
 */

const testPhone = ['01051308445', '01075580169', '01082873443', '01034110550'];

const Modal = (props) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, mode } = props;
  const [header, setHeader] = useState('');
  useEffect(() => {
    if (mode === 'register') {
      setHeader(`본인 인증을 진행해 주세요.`);
    } else if (mode === 'findId') {
      setHeader('아이디 찾기');
    } else {
      setHeader('비밀번호 찾기');
    }
  }, []);
  const verification = function (kind, next) {
    portOne['verify' + utils.capitalize(kind)](
      async (rsp) => {
        let certInfo = await portOne.certInfo(rsp.imp_uid);
        if (utils.isEmpty(certInfo)) {
          utils.dialog.alert('인증정보 없음', '인증정보를 가져올 수 없습니다.<br>다시 시도해 주세요.', 'error');
        } else {
          utils.isFunction(next) && next(certInfo);
        }
      },
      (rsp) => {}
    );
  };

  //가입유무 체크
  const checkUser = async (certInfo) => {
    if (testPhone.includes(certInfo.phone)) {
      console.log('테스트 전화번호입니다.');
      portOne.setCertInfo(certInfo);
      navigate('/register');
      return;
    }
    const url = `/v1/SP_m_joinCheck_s2/${certInfo?.name}/${certInfo?.phone}`;
    utils.http.setMode('api');
    utils.http
      .get(url)
      .then((res) => {
        const userId = res?.data?.data[0]?.userId;
        if (res?.data?.data?.length > 0 && userId) {
          utils.dialog.alert('이미 가입된 회원입니다.', `아이디 : ${userId}<br/> 로그인 페이지로 이동합니다.`, 'warning', '', '로그인하기').then(() => {
            close();
          });
        } else {
          portOne.setCertInfo(certInfo);
          navigate('/register');
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const verifyMove = function (kind, path) {
    verification(kind, (certInfo) => {
      console.log('certInfo', certInfo);
      checkUser(certInfo);
    });
  };

  const modalStyle = {
    height: mode === 'register' ? 'auto' : '540px',
    padding: mode === 'register' ? (isMobile ? '30px' : '41px 52px 52px 63px') : isMobile ? '30px' : '39px 52px 54px 72px',
  };

  const openFinding = function (kind) {
    verification(kind, (certInfo) => {
      if (mode === 'findId') {
        findId(certInfo);
      } else if (mode === 'findPw') {
        openFindPassword(certInfo);
      }
    });
  };
  const findId = function ({ name, phone }) {
    utils.http.setMode('api');
    utils.http.get(`/v1/SP_m_searchUserId_S1/${name}/${phone}`).then(({ data }) => {
      if (data?.data.length > 0 && data?.data[0].userId) {
        utils.dialog.alert('아이디 찾기 완료!', `입력한 정보로 조회된 아이디는<br><strong class="finded-id">${data.data[0].userId}</strong>입니다.`, 'success', '', '로그인하기').then(() => {
          close();
        });
      } else {
        utils.dialog.alert('아이디 찾기 실패!', `입력한 정보로 조회된 아이디가 없습니다.`, 'warning');
      }
    });
  };

  const [findPass, setFindPass] = useState({ open: false, certInfo: {} });
  const openFindPassword = (certInfo) => {
    setFindPass({ open: true, certInfo });
  };
  const closeFindPassword = () => {
    setFindPass({ open: false, certInfo: {} });
    close();
  };
  return (
    <>
      {open ? (
        <OpenModalBg>
          <InputIdModal open={findPass.open} certInfo={findPass.certInfo} close={closeFindPassword} />
          <Section style={modalStyle}>
            <BtnWrap>
              <CloseBtn onClick={close} type="button">
                <img src={closeIcon} alt="" />
              </CloseBtn>
            </BtnWrap>
            {mode === 'register' ? (
              <>
                <HeaderText>{header}</HeaderText>
                <Main>
                  <MainContent>
                    <MainContentText>{props.children}</MainContentText>
                    <ImgContainer>
                      <img src={Image} alt="" />
                    </ImgContainer>
                    <VerifyBtn
                      onClick={() => {
                        verifyMove('danal', '/register');
                      }}
                    >
                      본인 인증
                    </VerifyBtn>
                  </MainContent>
                </Main>
              </>
            ) : (
              <>
                <LineHeaderText>{header}</LineHeaderText>
                <MainContentText style={{ margin: '30px 0' }}>{props.children}</MainContentText>
                <SelectBtnWrap>
                  <FindBtn
                    type="button"
                    onClick={() => {
                      openFinding('inicis');
                    }}
                  >
                    <BtnImage>
                      <img src={AuthImage1} alt="" />
                    </BtnImage>
                    <BtnText>
                      <StyleH1>간편인증으로 찾기</StyleH1>
                      <SubText>
                        통합 인증으로
                        {isMobile ? <br /> : ' '}
                        간편하게 인증해요
                      </SubText>
                    </BtnText>
                    {!isMobile && (
                      <ArrowWrap>
                        <Arrow>
                          <img src={ArrowImg} alt="" />
                        </Arrow>
                      </ArrowWrap>
                    )}
                  </FindBtn>
                  <FindBtn
                    onClick={() => {
                      openFinding('danal');
                    }}
                  >
                    <BtnImage>
                      <img src={AuthImage2} alt="" />
                    </BtnImage>
                    <BtnText>
                      <StyleH1>휴대폰 번호로 찾기</StyleH1>
                      <SubText>
                        번호 입력하고
                        {isMobile ? <br /> : ' '}
                        문자로 인증해요
                      </SubText>
                    </BtnText>
                    {!isMobile && (
                      <ArrowWrap>
                        <Arrow>
                          <img src={ArrowImg} alt="" />
                        </Arrow>
                      </ArrowWrap>
                    )}
                  </FindBtn>
                </SelectBtnWrap>
              </>
            )}
          </Section>
        </OpenModalBg>
      ) : (
        <CloseModalBg></CloseModalBg>
      )}
    </>
  );
};

export default Modal;

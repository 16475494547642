import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import UserBlockModal from './Modal/UserBlockModal';
import UserReportModal from './Modal/UserReportModal';

const ReportBlocks = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 1;
  gap: 5px;
  color: #8a8a8a;
  font-size: 20px;
  padding: 0;
  .rb-button {
    padding: 0;
    margin: 0;
    line-height: 1;
    color: #8a8a8a;
    font-size: 20px;
    cursor: pointer;
    background: transparent;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const ReportBlockButtons = ({ gubn, userId, partNumb, sqenNumb, workDate, headNumb, userNick, afterAction, small }) => {
  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)',
  });
  const [openReport, setOpenReport] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);

  return (
    <>
      <ReportBlocks>
        <div>
          <button
            type={'button'}
            className={'rb-button'}
            onClick={() => {
              setOpenReport(true);
            }}
            style={{
              fontSize: isTablet ? (small ? '12px' : '16px') : small ? '16px' : '20px',
            }}
          >
            [신고]
          </button>
          {/* <span>|</span> */}
          <button
            type={'button'}
            className={'rb-button'}
            onClick={() => {
              setOpenBlock(true);
            }}
            style={{
              fontSize: isTablet ? (small ? '12px' : '16px') : small ? '16px' : '20px',
            }}
          >
            [차단]
          </button>
        </div>
      </ReportBlocks>
      <UserReportModal
        open={openReport}
        close={() => {
          setOpenReport(false);
        }}
        title="신고"
        gubn={gubn}
        head={headNumb}
        part={partNumb}
        sqen={sqenNumb}
        work={workDate}
        nick={userNick}
        afterAction={afterAction}
      />
      <UserBlockModal
        open={openBlock}
        close={() => {
          setOpenBlock(false);
        }}
        title="차단"
        userId={userId}
        nick={userNick}
        afterAction={afterAction}
      />
    </>
  );
};

export default ReportBlockButtons;

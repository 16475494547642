import { OpenModal, Section, ModalHeader, CloseBtn, Main } from './ModalElements';
import { IoMdClose } from 'react-icons/io';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const WindowFrame = ({ width, height, modalHeaderStyle, modalBodyStyle, open, close, title, children, scroll, slotFixedButton }) => {
  scroll = scroll ?? true;
  const handleBackdropClick = (e) => {
    close();
  };
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  useEffect(() => {
    if (isMobile && open) {
      // .main-content-scroll > div 가 overflow hidden이 되도록
      document.querySelector('.main-content-scroll > div').style.overflow = 'hidden !important';
    } else {
      document.querySelector('.main-content-scroll > div').style.overflow = 'auto';
    }
  }, [open]);

  return (
    <>
      {open && (
        <OpenModal onClick={handleBackdropClick}>
          <Section width={width} height={height} onClick={(e) => e.stopPropagation()}>
            <ModalHeader style={modalHeaderStyle && modalHeaderStyle}>
              {title}
              <CloseBtn onClick={close}>
                <IoMdClose size="40" style={{ color: '#fff' }} />
              </CloseBtn>
            </ModalHeader>
            <section className={'main-section'} style={modalBodyStyle && modalBodyStyle.main_section}>
              {scroll ? (
                <Scrollbars className="modal-main modal-main-scrollbar scrollbar-container" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}>
                  <Main>{children}</Main>
                </Scrollbars>
              ) : (
                <Main className={'modal-main'} style={modalBodyStyle && modalBodyStyle.modal_main}>
                  {children}
                </Main>
              )}
              {slotFixedButton && (
                <div className={'fixed-button'} style={modalBodyStyle && modalBodyStyle.fixed_button}>
                  {slotFixedButton}
                </div>
              )}
            </section>
          </Section>
        </OpenModal>
      )}
    </>
  );
};

export default WindowFrame;

/**
 * 공통 유틸리티 함수 모음
 * @type {Object}
 */
const common = {
  /**
   * HTML 태그를 이스케이프합니다.
   * @param {string} str - 이스케이프할 문자열
   * @returns {string} 이스케이프된 문자열
   */
  escapeHTML(str) {
    str = str.replace(/&amp;/g, '&');
    str = str.replace(/&lt;/g, '<');
    str = str.replace(/&gt;/g, '>');
    return str;
  },
  /**
   * HTML 태그를 삭제합니다.
   * @param {string} string - HTML 태그를 삭제할 문자열
   * @returns {string} HTML 태그가 삭제된 문자열
   */
  deleteHTML(string) {
    let objStrip = new RegExp();
    objStrip = /[<][^>]*[>]/gi;
    return string.replace(objStrip, '');
  },
  /**
   * 숫자를 추출합니다.
   * @param {number} num - 숫자를 추출할 대상
   * @returns {number} 추출된 숫자
   */
  extractNumber(num) {
    return Number(String(num).replace(/[^-0-9]/g, ''));
  },
  /**
   * 개행 문자를 <br> 태그로 변환합니다.
   * @param {string} text - 변환할 문자열
   * @returns {string} 변환된 문자열
   */
  nl2br(text) {
    return text?.replaceAll('\n', '<br>');
  },
  /**
   * 숫자 앞에 0을 채워 지정된 길이를 만듭니다.
   * @param {number} number - 0을 채울 숫자
   * @param {number} length - 만들고자 하는 길이
   * @returns {string} 0이 채워진 문자열
   */
  fillZero(number, length) {
    number = number + '';
    let num_length = number.length;
    for (let i = 0; i < length - num_length; i++) {
      number = '0' + number;
    }
    return number;
  },
  /**
   * 객체가 null 또는 undefined인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} null 또는 undefined인 경우 true, 그렇지 않으면 false
   */
  isNull(obj) {
    return obj === null || obj === undefined;
  },
  /**
   * 객체가 비어있는지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 비어있는 경우 true, 그렇지 않으면 false
   */
  isEmpty(obj) {
    return this.isNull(obj) || obj === '' || typeof obj === 'undefined' || (this.isArray(obj) && obj.length === 0) || (this.isObject(obj) && Object.keys(obj).length === 0 && !(obj instanceof Date));
  },
  /**
   * 객체가 비어있지 않은지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 비어있지 않은 경우 true, 그렇지 않으면 false
   */
  isNotEmpty(obj) {
    return this.isEmpty(obj) === false;
  },
  /**
   * 객체가 에러인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 에러인 경우 true, 그렇지 않으면 false
   */
  isError(obj) {
    return obj.error;
  },
  /**
   * 객체가 정상인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 정상인 경우 true, 그렇지 않으면 false
   */
  isNormal(obj) {
    return this.isError(obj) === false;
  },
  /**
   * 객체가 정의되었는지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 정의된 경우 true, 그렇지 않으면 false
   */
  isDefine(obj) {
    return obj !== null && obj !== undefined;
  },
  /**
   * 객체가 함수인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 함수인 경우 true, 그렇지 않으면 false
   */
  isFunction(obj) {
    return typeof obj === 'function';
  },
  /**
   * 객체가 객체인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 객체인 경우 true, 그렇지 않으면 false
   */
  isObject(obj) {
    return typeof obj === 'object' && this.isDefine(obj) && !this.isArray(obj);
  },
  /**
   * 객체가 배열인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 배열인 경우 true, 그렇지 않으면 false
   */
  isArray(obj) {
    return Array.isArray(obj);
  },
  /**
   * 객체가 문자열인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 문자열인 경우 true, 그렇지 않으면 false
   */
  isString(obj) {
    return typeof obj === 'string';
  },
  /**
   * 객체가 숫자인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 숫자인 경우 true, 그렇지 않으면 false
   */
  isNumber(obj) {
    return this.isNotEmpty(obj) && isFinite(obj) && obj !== false && obj !== true && obj !== null && !this.isDate(obj);
  },
  /**
   * 객체가 날짜인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} 날짜인 경우 true, 그렇지 않으면 false
   */
  isDate(obj) {
    return obj instanceof Date;
  },
  /**
   * 문자열이 날짜 형식인지 확인합니다.
   * @param {string} str - 확인할 문자열
   * @returns {boolean} 날짜 형식인 경우 true, 그렇지 않으면 false
   */
  isDateString(str) {
    return this.isString(str) && str.length === 10 && isFinite(new Date(str).getTime()) && this.isNumber(str.substring(0, 4)) && str.substring(4, 5) === '-' && this.isNumber(str.substring(5, 7)) && str.substring(7, 8) === '-' && this.isNumber(str.substring(8, 10));
  },
  /**
   * 객체가 NaN인지 확인합니다.
   * @param {Object} obj - 확인할 객체
   * @returns {boolean} NaN인 경우 true, 그렇지 않으면 false
   */
  isNaN(obj) {
    return !this.isNumber(obj);
  },
  /**
   * 문자열에 특수문자가 포함되어 있는지 확인합니다.
   * @param {string} str - 확인할 문자열
   * @returns {boolean} 특수문자가 포함되어 있는 경우 true, 그렇지 않으면 false
   */
  isSpecialChar(str) {
    return /[~!@#$%^&*()_+|<>?:{}]/.test(str);
  },
  /**
   * 문자열이 한글인지 확인합니다.
   * @param {string} str - 확인할 문자열
   * @returns {boolean} 한글인 경우 true, 그렇지 않으면 false
   */
  isKorean(str) {
    return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(str);
  },
  /**
   * 객체를 깊은 복사합니다.
   * @param {Object} obj - 복사할 객체
   * @returns {Object} 복사된 객체
   */
  deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  /**
   * 객체를 JSON 문자열로 변환합니다.
   * @param {Object} obj - 변환할 객체
   * @returns {string} JSON 문자열
   */
  toJSON(obj) {
    if (this.isNull(obj)) return '';
    else return JSON.stringify(obj);
  },
  /**
   * JSON 문자열을 객체로 변환합니다.
   * @param {string} obj - 변환할 JSON 문자열
   * @returns {Object} 변환된 객체
   */
  fromJSON(obj) {
    if (this.isNull(obj)) return null;
    else return JSON.parse(obj);
  },
  /**
   * 객체를 Base64 문자열로 변환합니다.
   * @param {Object} obj - 변환할 객체
   * @returns {string} Base64 문자열
   */
  toBase64(obj) {
    if (this.isObject(obj)) obj = this.toJSON(obj);
    return btoa(obj);
  },
  /**
   * Base64 문자열을 객체로 변환합니다.
   * @param {string} obj - 변환할 Base64 문자열
   * @param {boolean} json - JSON으로 변환할지 여부
   * @returns {Object} 변환된 객체
   */
  fromBase64(obj, json) {
    obj = atob(obj);
    if (json) obj = this.fromJSON(obj);
    return obj;
  },
  /**
   * 데이터를 문자열로 변환합니다.
   * @param {Object} param0 - 변환할 데이터
   * @returns {string} 변환된 문자열
   */
  dataString({ mimetype, data }) {
    return `data:${mimetype};base64,${data}`;
  },
  /**
   * 랜덤한 숫자를 생성합니다.
   * @param {number} from - 시작 범위
   * @param {number} to - 끝 범위
   * @returns {number} 생성된 랜덤 숫자
   */
  rand(from, to) {
    to++;
    return Math.floor(Math.random() * (to - from)) + from;
  },
  /**
   * 랜덤한 코드를 생성합니다.
   * @param {number} length - 생성할 코드의 길이
   * @returns {string} 생성된 랜덤 코드
   */
  randCode(length) {
    let code = '';
    for (let i = 0; i < length; i++) {
      let r = this.rand(0, 61);
      if (r < 10) code += r;
      else if (r < 36) code += String.fromCharCode(r + 55);
      else code += String.fromCharCode(r + 61);
    }
    return code;
  },
  /**
   * 고유한 값을 생성합니다.
   * @returns {string} 생성된 고유 값
   */
  unique() {
    return this.randCode(6) + Date.now() + this.randCode(5);
  },
  /**
   * 랜덤한 색상을 생성합니다.
   * @param {Array} duplicate - 중복을 피할 색상 배열
   * @returns {string} 생성된 랜덤 색상
   */
  randColor(duplicate) {
    duplicate = duplicate ?? [];
    const color = '#' + ((Math.random() * 0xffffff) << 0).toString(16);
    return duplicate.includes(color) ? this.randColor(duplicate) : color;
  },
  /**
   * 문자열의 첫 글자를 대문자로 변환합니다.
   * @param {string} str - 변환할 문자열
   * @returns {string} 변환된 문자열
   */
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  },
  /**
   * 문자열의 바이트 길이를 반환합니다.
   * @param {string} str - 바이트 길이를 확인할 문자열
   * @returns {number} 문자열의 바이트 길이
   */
  byteLength(str = '') {
    str = str.toString();
    let byte = 0;
    for (let i = 0; i < str.length; i++) {
      const code = str.charCodeAt(i);

      if (code > 255) {
        byte += 2;
      } else {
        byte += 1;
      }
    }
    return byte;
  },
  /**
   * 지정된 범위의 배열을 생성합니다.
   * @param {number} first - 시작 범위
   * @param {number} end - 끝 범위
   * @param {number} step - 증가 단계
   * @returns {Array} 생성된 배열
   */
  range(first, end, step = 1) {
    let array = [];
    if (end === undefined) {
      end = first;
      first = 0;
    }
    for (let i = first; i < end; i += step) {
      array.push(i);
    }
    return array;
  },
};
Object.freeze(common);
export default common;

import React from 'react';
import { BtnContainer, TabBtn } from './TabButtonElements';

const TabButton = (props) => {
  const { leftBtn, rightBtn, tabMenu, setTabMenu } = props;

  // 클릭하지 않았을 때 버튼 스타일
  const btnStyle = {
    color: '#fff',
    display: 'none',
  };

  // 버튼을 클릭했을 때 스타일
  const activeBtnStyle = {
    color: '#5affe2',
    display: 'block',
  };
  return (
    <BtnContainer>
      <TabBtn
        onClick={() => setTabMenu('received')}
        btnstyle={tabMenu === 'received' ? activeBtnStyle : btnStyle}
        style={
          tabMenu === 'received'
            ? {
                borderBottom: '3px solid #5affe2',
              }
            : {}
        }
      >
        {leftBtn}
      </TabBtn>
      <TabBtn
        onClick={() => setTabMenu('sent')}
        btnstyle={tabMenu === 'sent' ? activeBtnStyle : btnStyle}
        style={
          tabMenu === 'sent'
            ? {
                borderBottom: '3px solid #5affe2',
              }
            : {}
        }
      >
        {rightBtn}
      </TabBtn>
    </BtnContainer>
  );
};

export default TabButton;

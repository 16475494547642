import { JobPostingStore } from 'modules/store/store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import utils from '../../../../modules/utils';
import InternProfileModal from '../../../Parts/Common/Modal/InternProfileModal';
import { SubmitBtn } from '../../../Parts/Common/Modal/ModalElements';
import ProfileImage from '../../../Parts/Common/ProfileImage';

const Box = styled.div`
  width: 100%;
  height: auto;
  background-color: #ddd;
  border-top: 1px solid #8a8a8a;
  padding: 0 30px 30px 30px;

  .total {
    padding: 29px 0;
    font-size: 20px;
    color: #10141c;
    font-weight: bold;
    line-height: 1;
  }
  .list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 30px;
    .applicant {
      width: 100%;
      border-radius: 10px;
      border: solid 1px #ddd;
      background-color: #fff;
      padding: 30px;

      display: grid;
      grid-template-columns: 60px auto 95px;
      grid-template-rows: 60px 40px;
      gap: 30px 20px;
      .user-name {
        align-self: center;
        color: #10141c;
        font-size: 22px;
        font-weight: bold;
      }
      .status {
        align-self: center;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        border-radius: 15px;
        height: 30px;
        background-color: #8a8a8a;

        display: flex;
        align-items: center;
        justify-content: center;
      }
      .status.complete {
        background-color: #7e6eff;
      }
      .button-box {
        grid-column: 1 / span 3;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        column-gap: 27px;
        button {
          margin-top: 0;
        }
        button.invert {
          color: #7e6eff;
          background: #fff;
          border: 1px solid #7e6eff;
        }
      }
      * {
        line-height: 1;
      }
    }
  }
`;

const ApplyLists = ({ applicantList, workDate, sqenNumb, complete, afterSelect, refreshDetail }) => {
  const { setInternId } = JobPostingStore();
  const [deadLine, setDeadLine] = useState(false);

  const navigate = useNavigate();
  const [resumeInfo, setResumeInfo] = useState({ open: false });
  const startChat = async function (internId, reqHead_Numb, reqSqen_Numb, reqWork_Date) {
    const user_Auth = '1';
    try {
      utils.http.setMode('api');
      const res = await utils.http.post('/v1/SP_r_ChatList_i2', null, { internId, reqHead_Numb, reqSqen_Numb, reqWork_Date, user_Auth });
      console.log(res);
      if (res.data.success && res.data.data.length > 0) {
        navigate(`/manage/intern/intern/${res.data.data[0].workDate}/${res.data.data[0].sqenNumb}`);
      } else {
        utils.dialog.alert('인턴 채팅 오류!', '오류가 발생했습니다. 관리자에게 문의해 주세요.', 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 채팅하기 버튼 클릭 여부
  const insertChatLog = async () => {
    const url = `/v2/SP_r_chatLog_I1`;
    const data = {
      screenGubn: 'centerIntern',
    };
    utils.http.setMode('api');
    await utils.http.post(url, null, data).then((res) => {
      console.log('@@@@@@', res);
    });
  };

  // 채팅방 존재 유무 확인
  // SP_r_ChatList_i3 호출하여 결과값의 workDate, sqenNumb가 공백이 아닐경우 /v1/SP_r_ChatList_i2 그대로 진행
  // 공백일 경우 채팅방으로 이동 internId를 가져가야함
  const checkChat = async function (internId) {
    const user_Auth = 1;
    if (await utils.dialog.confirm('인턴 채팅', '인턴과의 채팅을 진행하시겠습니까?', 'question', '', '채팅하기')) {
      insertChatLog();
      try {
        utils.http.setMode('api');
        const res = await utils.http.post('/v1/SP_r_ChatList_i3', null, { partId: internId, user_Auth });
        console.log(res);
        const resWorkDate = res.data.data[0].workDate;
        const resSqenNumb = res.data.data[0].sqenNumb;
        console.log('resWorkDate', resWorkDate);
        if (!res.data.success) {
          utils.dialog.alert('인턴 채팅 오류!', '오류가 발생했습니다. 관리자에게 문의해 주세요.', 'error');
          return;
        }

        if (resWorkDate !== '' && resSqenNumb !== '') {
          startChat(internId, '', resSqenNumb, resWorkDate);
        } else {
          // '채팅방 없음!`, `채팅방을 생성합니다.<br/> 채팅방 생성 시 구독권이 차감됩니다.`, 'info', '', '채팅방 생성하기'
          utils.dialog.confirm('채팅방 없음!', '채팅방을 생성합니다.<br/> 채팅방 생성 시 구독권이 차감됩니다.', 'info', '', '생성하기').then((res) => {
            if (res) {
              startChat(internId, '', '', '');
            }
            return;
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeadlineBtn = async () => {
    const url = `/v1/SP_I_internJobPostingItem_I3`;
    const data = {
      workDate,
      sqenNumb,
    };
    console.log('handleDeadlineBtn', data);

    // confirm
    await utils.dialog.confirm('매칭마감', '매칭을 마감하시겠습니까?', 'question', '', '매칭마감').then(async (result) => {
      if (result) {
        const res = await utils.http.post(url, null, data);
        if (res.data.success) {
          utils.dialog.alert('매칭마감', '매칭을 마감하였습니다.', 'success').then(() => {
            refreshDetail();
            getDeadline();
          });
        } else {
          utils.dialog.alert('매칭마감', '매칭마감에 실패하였습니다.', 'error');
        }
      }
    });
  };

  // 마감유무 가져오기
  const getDeadline = async () => {
    const url = `/v1/SP_I_internJobPostingItem_s6/${workDate}/${sqenNumb}`;
    const res = await utils.http.get(url);
    if (res.data.success) {
      setDeadLine(res.data.data[0].jobGubn === 1 ? true : false);
    }
  };

  useEffect(() => {
    getDeadline();
    setInternId('');
  }, [workDate, sqenNumb]);

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className={'total'}>총 구인신청 {applicantList.length}건</div>
        <button
          onClick={handleDeadlineBtn}
          style={{
            width: '100px',
            height: '40px',
            borderRadius: '10px',
            backgroundColor: deadLine ? '#7E6EFF' : '#8A8A8A',
            color: deadLine ? '#fff' : '#fff',
            fontSize: '20px',
            fontWeight: 'bold',
            border: 'none',
            cursor: 'pointer',
            // disabled
          }}
          disabled={!deadLine}
        >
          매칭마감
        </button>
      </div>
      {/* TODO: 매칭중, 매칭마감 버튼 필요 */}
      {applicantList.length > 0 && (
        <div className="list">
          {applicantList.map((applicant) => (
            <div className="applicant" key={applicant.userId}>
              <ProfileImage partProfile={1} kind={'user'} id={applicant.userId} />
              <div className={'user-name'}>{applicant.userName}</div>
              <div className={'status ' + (applicant.gubn === '3' && 'complete')}>{applicant.gubn === '3' ? '선택완료' : applicant.gubnName}</div>
              {applicant.gubn === '3' ? (
                <div className={'button-box'}>
                  <SubmitBtn
                    type="button"
                    onClick={() => {
                      setResumeInfo({
                        open: true,
                        userName: applicant.userName,
                        userId: applicant.userId,
                      });
                    }}
                  >
                    이력서 보기
                  </SubmitBtn>
                  <SubmitBtn
                    type="button"
                    className={'invert'}
                    onClick={() => {
                      checkChat(applicant.userId);
                    }}
                  >
                    채팅
                  </SubmitBtn>
                </div>
              ) : (
                <div className={'button-box'}>
                  <SubmitBtn
                    type="button"
                    onClick={() => {
                      setResumeInfo({
                        open: true,
                        userName: applicant.userName,
                        userId: applicant.userId,
                        gubn: applicant.gubn,
                      });
                    }}
                  >
                    이력서 보기
                  </SubmitBtn>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <InternProfileModal
        open={resumeInfo.open}
        close={() => {
          setResumeInfo({ open: false });
        }}
        title={resumeInfo.userName}
        userId={resumeInfo.userId}
        disabled={resumeInfo.gubn !== '1'}
        select={
          !complete &&
          (() => {
            utils.http
              .post(`/v1/SP_I_internJobPostingItem_I2`, null, {
                workDate,
                sqenNumb,
                userId: resumeInfo.userId,
              })
              .then(async ({ data }) => {
                if (data.success) {
                  if (await utils.dialog.alert('인턴 선택', resumeInfo.userName + '님을 인턴으로 선택하였습니다.', 'success')) {
                    utils.isFunction(afterSelect) && afterSelect();
                  }
                } else {
                  utils.dialog.alert('인턴 선택', data.message, 'error');
                }
              });
          })
        }
      />
    </Box>
  );
};

export default ApplyLists;

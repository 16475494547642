const icon = ({name, className, style, children}) => {
    name = name ?? children
    return (
        <i className={"icon material-symbols-rounded "+(className??"")} style={style}>
            {(name ?? "").replaceAll(" ", "_").toLowerCase()}
        </i>
    );
};

export default icon;

import styled from 'styled-components';

export const ContentTop = styled.div`
  width: fit-content;
  background-color: transparent;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    margin-left: 10px;
  }
`;

export const BigModal = styled.div`
  position: absolute;
  display: none;
  top: 7px;
  left: 45px;
  z-index: 10;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 15px 25px 0 25px;
  border-radius: 10px;
  background-color: #10141c;
`;

export const SmallModal = styled.div`
  position: absolute;
  display: none;
  top: 7px;
  left: 45px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 29px 29px 28px 29px;
  border-radius: 10px;
  background-color: #10141c;
  font-size: 22px;
  font-weight: normal;
  color: #fff;
`;

export const InfoText = styled.p`
  font-size: 22px;
  line-height: 23px;
  font-weight: normal;
  color: #fff;
`;

export const InfoHeader = styled.h1`
  font-size: 22px;
  font-weight: normal;
  color: #fff;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
`;

export const InfoContent = styled.p`
  font-size: 22px;
  font-weight: normal;
  color: #fff;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  margin-left: 18px;
  height: 35px;
  font-size: 34px;
  position: relative;

  &:hover ${BigModal} {
    display: block;
  }
  &:hover ${SmallModal} {
    display: block;
  }

  /* 모바일 */
  @media screen and (max-width: 1024px) {
    margin-left: 10px;
    height: 30px;
    font-size: 30px;
  }
`;

export const HeadingText = styled.p`
  display: inline-block;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: -7px;
    bottom: 0;
    width: 100%;
    padding: 6px 7px;
    background: #7f6eff;
    z-index: -1;
  }

  //480px
  @media screen and (max-width: 1024px) {
    font-size: 18px;
    &::before {
      content: '';
      position: absolute;
      left: -7px;
      bottom: 0;
      width: 100%;
      padding: 3px 7px;
      background: #7f6eff;
      z-index: -1;
    }
  }
`;

export const WhiteDot = styled.div`
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
  margin: 0px 10px 5px;
`;

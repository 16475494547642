import React from 'react';
import { TitleText } from 'style';
import MultiImageUpload from 'components/FileUpload/MultiImageUpload';

const ImageUpload = ({ title, setImgDelKey, setImages, images, kind, maxImage }) => {
  // 달인정보 이미지 조회
  console.log('maxImage: ' + maxImage);
  return (
    <>
      <TitleText>{title}</TitleText>
      <section className={'image-box-scroll'}>
        <MultiImageUpload name={kind} maxImage={maxImage && maxImage} imageArr={images} setImageArr={setImages} setDeleteImageArr={setImgDelKey} disabledButton={false} accept="image/jpg, image/jpeg, image/png" />
      </section>
    </>
  );
};

export default ImageUpload;

import React, { useState } from 'react';
import { Container, Wrapper } from '../../style';
import { Content, HeadingText, LeftSection, RightSection, HeadingWrap, LogoWrap, ImgWrap, FormWrap, Logo, Form, InputWrap, PwInputBox, WarningMessage, InputTitle, LightInput, DarkInput, EmailInput, StyledSpan, SelectBox, CheckBoxWrap, HalfDiv, CheckIdBtn, RegisterBtn, Label } from './RegisterFormElements';
import logo from 'assets/뉴수달로고.png';
import CheckBox from '../Parts/Common/forms/CheckBox/CheckBox';
import Modal from './ShowLawModal/ShowLawModal';
import CustomSelect from 'components/Parts/Common/CustomSelect/CustomSelect';
import Icon from 'components/Parts/Common/Icon';
import utils from 'modules/utils';
import portOne from 'modules/portOne';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useMediaQuery } from 'react-responsive';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const RegisterForm = () => {
  const isSmallPC = useMediaQuery({ query: '(max-width: 1440px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const navigate = useNavigate();
  const termsAndConsitions = ['모두 동의합니다.', '(필수)만14세 이상', '(필수)수달앱 이용약관 동의', '(필수)개인정보처리방침 동의', '(선택)마케팅 정보 수신 동의'];

  const [options, setOptions] = useState([]);
  const customStyle = {
    selectOptions: {
      height: '141px',
      top: '38px',
    },
    selectBox: {
      padding: isSmallPC ? '' : '1.5px 12px',
      height: isTablet ? '32px' : 'auto',
      width: '100%',
      fontSize: isTablet ? '14px' : '18px',
    },
  };

  const getEmailCategory = async () => {
    try {
      utils.http.setMode('api');

      const res = await utils.http.get(`/v1/SP_b_Common_Code_SI/10`);
      if (res.data.data && res.data.data.length > 0) {
        setOptions(() => res.data.data.map((item) => ({ value: item.nm, label: item.nm })));
        setOptions((options) => [...options, { value: '직접입력', label: '직접입력' }]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // input value state
  const [inputValue, setInputValue] = useState({
    id: '',
    password: '',
    passwordCheck: '',
    name: '',
    nickname: '',
    phone: '',
    email: '',
    userEmail: '',
    recommender: '',
  });

  // 이메일 select box value state
  const [selected, setSelected] = useState('');
  const [domain, setDomain] = useState('');

  // modal 창 열기/닫기
  const [useLawModal, setUseLawModal] = useState(false);

  const openUseLawModal = () => {
    setUseLawModal(true);
  };
  const closeUseLawModal = () => {
    setUseLawModal(false);
  };

  const [privacyLawModal, setPrivacyLawModal] = useState(false);
  const openPrivacyLawModal = () => {
    setPrivacyLawModal(true);
  };
  const closePrivacyLawModal = () => {
    setPrivacyLawModal(false);
  };

  // checkbox state
  const [checkList, setCheckList] = useState([]);

  // checkbox 모두 체크
  const onCheckedAll = (e) => {
    e.target.checked ? setCheckList(['age', 'terms of use', 'privacy processing', 'receive information']) : setCheckList([]);
  };

  // checkbox 개별 체크
  const onCheckedElement = (e) => {
    e.target.checked ? setCheckList([...checkList, e.target.name]) : setCheckList(checkList.filter((choice) => choice !== e.target.name));
    console.log(checkList);
  };
  // 필수 체크 항목
  const essential = ['age', 'terms of use', 'privacy processing'];
  const [userAgree, setUserAgree] = useState(0);
  const [useID, setUseID] = useState(false);

  // 아이디 중복체크 버튼 클릭
  const checkId = async (e) => {
    e.preventDefault();
    utils.http.setMode('api');
    if (inputValue.id === '') {
      // alert로 아이디 입력하라고 하기
      utils.dialog.alert('아이디 입력!', '아이디가 입력되어있지 않습니다.', 'warning');
    } else {
      const res = await utils.http.get(`/v1/SP_m_joinCheck_s1/${inputValue.id}`);
      if (res.data.success) {
        utils.dialog.alert(`${inputValue.id}은(는) 사용 가능한 아이디입니다.`, '', 'info');
        setUseID(true);
      } else {
        utils.dialog.alert('아이디 사용 불가능!', res.data.message, 'error');
        setInputValue({
          ...inputValue,
          id: '',
        });
      }
    }
  };
  //
  const [pwMsg, setPwMsg] = useState('');
  const [showPwMsg, setShowPwMsg] = useState('none');
  // 비밀번호 유효성 검사
  const passwordCheck = () => {
    if (!utils.valid.isPasswordValid(inputValue.password)) {
      setPwMsg('비밀번호는 영문자, 숫자, 특수문자를 조합한 최소 8자로 입력해 주세요.');
      setShowPwMsg('block');
    } else {
      setPwMsg('');
      setShowPwMsg('none');
    }
    isSamePassword();
  };

  const [pwCheckMsg, setPwCheckMsg] = useState('');
  const [showPwCheckMsg, setShowPwCheckMsg] = useState('none');
  // 비밀번호, 비밀번호 확인 같은지 확인
  const isSamePassword = () => {
    if (!utils.valid.isSamePassword(inputValue.password, inputValue.passwordCheck) && inputValue.passwordCheck !== '') {
      setPwCheckMsg('입력하신 비밀번호가 일치하지 않습니다.');
      setShowPwCheckMsg('block');
    } else {
      setPwCheckMsg('');
      setShowPwCheckMsg('none');
    }
  };

  const Join = async () => {
    utils.http.setMode('api');
    const data = {
      userAgree1: userAgree[0],
      userAgree2: userAgree[1],
      userAgree3: userAgree[2],
      userAgree4: 1,
      userAgree5: 1,
      userAgree6: 1,
      userAgree7: 1,
      userAgree8: userAgree[3],
      userAgree9: 1,
      userAuth: 1,
      userBirthDay: inputValue.userBirthDay,
      userEmail: inputValue.userEmail,
      userHp: inputValue.phone,
      userId: inputValue.id,
      userName: inputValue.name,
      userNickName: inputValue.nickname,
      userPassword: inputValue.password,
      userRecommend: inputValue.recommender,
    };

    try {
      const res = await utils.http.post(`/v2/SP_m_join_i1/`, null, data);
      if (res.data.success) {
        utils.dialog.alert('회원가입 완료!', '회원가입이 완료되었습니다.', 'success').then(() => {
          utils.user.login(inputValue.id, inputValue.password, false).then(() => {
            navigate('/manage/info/partnerInfo');
          });
        });
      } else {
        utils.dialog.alert('회원가입 실패!', res.data.message, 'error');
      }

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.userEmail.includes('@')) {
      setInputValue({
        ...inputValue,
        userEmail: inputValue.email + '@' + domain,
      });
    }
    console.log('domain');

    // 유효성 검사
    if (!utils.valid.isIdValid(inputValue.id)) {
      utils.dialog.alert('아이디 입력!', '아이디를 입력해주세요.', 'warning');
    } else if (!useID) {
      utils.dialog.alert('중복확인 체크!', '중복확인을 체크해주세요.', 'warning');
    } else if (!utils.valid.isNullPassword(inputValue.password)) {
      utils.dialog.alert('비밀번호 입력!', '비밀번호를 입력해주세요.', 'warning');
    } else if (!utils.valid.isPasswordValid(inputValue.password)) {
      utils.dialog.alert('비밀번호 입력!', '비밀번호는 영문자, 숫자, 특수문자를<br />조합한 최소 8자로 입력해 주세요.', 'warning');
    } else if (!utils.valid.isSamePassword(inputValue.password, inputValue.passwordCheck)) {
      utils.dialog.alert('비밀번호 확인 불일치!', '비밀번호와 비밀번호 확인이<br />일치하지 않습니다.', 'warning');
    } else if (!utils.valid.isNameValid(inputValue.name)) {
      utils.dialog.alert('이름 입력!', '이름을 입력해주세요.', 'warning');
    } else if (!utils.valid.isNickValid(inputValue.nickname)) {
      utils.dialog.alert('닉네임 입력!', '닉네임을 입력해주세요.', 'warning');
    } else if (!utils.valid.isNullPhoneNumber(inputValue.phone)) {
      utils.dialog.alert('전화번호 입력!', '전화번호를 입력해주세요.', 'warning');
    } else if (!utils.valid.isPhoneNumberValid(inputValue.phone)) {
      utils.dialog.alert('유효하지않은 전화번호!', '전화번호를 올바른 형식으로 작성해주세요.', 'warning');
    } else if (!utils.valid.isNullEmail(inputValue.userEmail)) {
      utils.dialog.alert('이메일 입력!', '이메일을 입력해주세요.', 'warning');
    } else if (!utils.valid.isEmailValid(inputValue.userEmail)) {
      utils.dialog.alert('유효하지않은 이메일!', '이메일을 올바른 형식으로 입력해주세요.', 'warning').then((res) => {
        if (res) {
          setInputValue({
            ...inputValue,
            userEmail: '',
          });
          setSelected('선택하세요.');
          setDomain('');
        }
      });
    } else if (checkList.filter((x) => essential.includes(x)).length < essential.length) {
      utils.dialog.alert('필수 약관 동의!', '필수 약관 항목에 동의해 주세요.', 'warning');
    } else {
      Join();
    }
  };

  useEffect(() => {
    getEmailCategory();
    let certInfo = portOne.getCertInfo();

    if (utils.isEmpty(certInfo)) {
      utils.dialog.alert('인증정보 없음', '인증 후 회원가입이 가능합니다.', 'warning').then(() => {
        navigate('/login');
      });
    } else {
      setInputValue({
        ...inputValue,
        name: certInfo.name,
        phone: certInfo.phone,
        userBirthDay: certInfo.birthday,
      });
      portOne.delCertInfo();
    }
  }, []);

  const changeEmailDomain = function (selected, domain) {
    if (selected === '직접입력') {
      setInputValue({
        ...inputValue,
        userEmail: `${inputValue.email}@${domain}`,
      });
    } else {
      setInputValue({
        ...inputValue,
        userEmail: `${inputValue.email}@${selected}`,
      });
    }
  };

  useEffect(() => {
    if (checkList.length === essential.length + 1) {
      setUserAgree([1, 1, 1, 1]);
    } else if (checkList.filter((x) => essential.includes(x)).length === essential.length) {
      setUserAgree([1, 1, 1, 0]);
    }
  }, [checkList]);

  return (
    <Container>
      <Wrapper>
        <Content>
          {!isSmallPC && (
            <LeftSection>
              <HeadingWrap>
                <HeadingText>회원가입</HeadingText>
              </HeadingWrap>
              <LogoWrap>
                <ImgWrap
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate('/login')}
                >
                  <Logo src={logo} alt="" />
                </ImgWrap>
              </LogoWrap>
            </LeftSection>
          )}
          <RightSection>
            <FormWrap>
              <Form>
                <Scrollbars className="scrollbar-container outside-scrollbar main-form-scroll" renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />} renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" />}>
                  {isSmallPC && (
                    <HeadingText
                      style={{
                        fontSize: '24px',
                        marginBottom: '30px',
                      }}
                    >
                      회원가입
                    </HeadingText>
                  )}
                  <InputWrap>
                    <InputTitle>아이디</InputTitle>
                    {isMobile && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          gap: '10px',
                        }}
                      >
                        <LightInput
                          type="text"
                          placeholder="아이디를 입력해주세요."
                          style={{ width: 'calc(80% - 70px)' }}
                          value={inputValue.id}
                          onChange={(e) => {
                            setUseID(false);
                            setInputValue({ ...inputValue, id: e.target.value.trim() });
                          }}
                          onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.key === 'Enter') {
                              checkId(e);
                            }
                          }}
                        />
                        <CheckIdBtn type="button" onClick={checkId}>
                          중복확인
                        </CheckIdBtn>
                      </div>
                    )}
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          gap: '10px',
                        }}
                      >
                        {!isMobile && (
                          <LightInput
                            type="text"
                            placeholder="아이디를 입력해주세요."
                            style={{ width: 'calc(80% - 200px)' }}
                            value={inputValue.id}
                            onChange={(e) => {
                              setUseID(false);
                              setInputValue({ ...inputValue, id: e.target.value.trim() });
                            }}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') {
                                checkId(e);
                              }
                            }}
                          />
                        )}
                        {!isMobile && (
                          <CheckIdBtn type="button" onClick={checkId}>
                            중복확인
                          </CheckIdBtn>
                        )}
                      </div>
                      {useID && (
                        <div
                          style={{
                            color: 'var(--mint-color)',
                            marginLeft: '10px',
                          }}
                        >
                          사용 가능한 아이디입니다.
                        </div>
                      )}
                    </div>
                  </InputWrap>

                  <InputWrap>
                    <InputTitle>비밀번호</InputTitle>
                    <PwInputBox>
                      <Label>
                        <input
                          type="password"
                          placeholder="비밀번호를 입력해주세요."
                          onChange={(e) =>
                            setInputValue({
                              ...inputValue,
                              password: e.target.value,
                            })
                          }
                          onKeyUp={passwordCheck}
                        />
                        <span>
                          <PriorityHighIcon sx={{ display: showPwMsg }} />
                        </span>
                      </Label>
                      <WarningMessage style={{ display: showPwMsg }}>{pwMsg}</WarningMessage>
                    </PwInputBox>
                  </InputWrap>

                  <InputWrap>
                    <InputTitle>비밀번호 확인</InputTitle>
                    <PwInputBox>
                      <Label>
                        <input
                          type="password"
                          placeholder="비밀번호를 다시 입력해주세요."
                          onChange={(e) =>
                            setInputValue({
                              ...inputValue,
                              passwordCheck: e.target.value,
                            })
                          }
                          onKeyUp={isSamePassword}
                        />
                        <span>
                          <PriorityHighIcon sx={{ display: showPwCheckMsg }} />
                        </span>
                      </Label>
                      <WarningMessage style={{ display: showPwCheckMsg }}>{pwCheckMsg}</WarningMessage>
                    </PwInputBox>
                  </InputWrap>
                  <InputWrap>
                    <InputTitle>이름</InputTitle>
                    <DarkInput type="text" readOnly={true} value={inputValue.name} />
                  </InputWrap>
                  <InputWrap>
                    <InputTitle>닉네임</InputTitle>
                    <LightInput
                      type="text"
                      placeholder="닉네임을 입력해주세요.(공백없이 한글, 영문, 숫자만 입력)"
                      value={inputValue.nickname}
                      onChange={(e) =>
                        setInputValue({
                          ...inputValue,
                          nickname: e.target.value.trim(),
                        })
                      }
                    />
                  </InputWrap>
                  <InputWrap>
                    <InputTitle>전화번호</InputTitle>
                    <DarkInput type="text" readOnly={true} value={utils.format.contact(inputValue.phone)} />
                  </InputWrap>
                  <InputWrap>
                    <InputTitle>이메일</InputTitle>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <EmailInput
                        type="text"
                        placeholder="soodal"
                        className={'email-id'}
                        onChange={(e) => {
                          setInputValue({
                            ...inputValue,
                            email: e.target.value.trim(),
                            userEmail: e.target.value.trim() + (domain ? '@' + domain : ''),
                          });
                          console.log(inputValue);
                        }}
                        value={inputValue.email}
                      />
                      <StyledSpan>@</StyledSpan>
                      <EmailInput
                        type="text"
                        placeholder="naver.com"
                        className={'email-domain'}
                        value={selected === '직접입력' ? domain : selected}
                        onChange={(e) => {
                          changeEmailDomain(selected, e.target.value);
                          console.log('inputDomain', e.target.value);

                          setDomain(e.target.value.trim());
                        }}
                        readOnly={selected === '직접입력' ? false : true}
                      />
                      <SelectBox>
                        {options.length > 0 && (
                          <CustomSelect
                            options={options}
                            placeHolder="선택하세요."
                            setSelected={(selected) => {
                              changeEmailDomain(selected, selected === '직접입력' ? '' : selected);
                              setSelected(selected);
                            }}
                            customStyle={customStyle}
                          />
                        )}
                      </SelectBox>
                    </div>
                  </InputWrap>
                  <InputWrap>
                    <InputTitle>추천인 ID</InputTitle>
                    <LightInput
                      type="text"
                      placeholder="추천인ID를 입력해주세요."
                      value={inputValue.recommender}
                      onChange={(e) =>
                        setInputValue({
                          ...inputValue,
                          recommender: e.target.value.trim(),
                        })
                      }
                    />
                  </InputWrap>
                  <CheckBoxWrap style={{ margin: '15px 0' }}>
                    <CheckBox text={termsAndConsitions[0]} style={{ fontWeight: 'bold' }} name="all" allChecked={(e) => onCheckedAll(e)} checkList={checkList} />
                  </CheckBoxWrap>
                  <CheckBoxWrap style={{ marginBottom: '7px' }}>
                    <HalfDiv>
                      <CheckBox text={termsAndConsitions[1]} name="age" changeHandle={(e) => onCheckedElement(e)} checkList={checkList} />
                    </HalfDiv>
                    <HalfDiv>
                      <CheckBox text={termsAndConsitions[2]} name="terms of use" showBtn={true} btnText="[보기]" modalOpen={openUseLawModal} changeHandle={(e) => onCheckedElement(e)} checkList={checkList} />
                      <Modal open={useLawModal} close={closeUseLawModal} header="수달 이용약관 동의" gubn={parseInt('5')} />
                    </HalfDiv>
                  </CheckBoxWrap>
                  <CheckBoxWrap>
                    <HalfDiv>
                      <CheckBox text={termsAndConsitions[3]} name="privacy processing" showBtn={true} btnText="[보기]" modalOpen={openPrivacyLawModal} changeHandle={(e) => onCheckedElement(e)} checkList={checkList} />
                      <Modal open={privacyLawModal} close={closePrivacyLawModal} header="수달 이용약관 동의" gubn={parseInt('1')} />
                    </HalfDiv>
                    <HalfDiv>
                      <CheckBox text={termsAndConsitions[4]} name="receive information" changeHandle={(e) => onCheckedElement(e)} checkList={checkList} />
                    </HalfDiv>
                  </CheckBoxWrap>
                </Scrollbars>
                <RegisterBtn type="button" onClick={onSubmit}>
                  회원가입
                </RegisterBtn>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <button
                    style={{
                      marginTop: '4px',
                      fontSize: '16px',
                      color: '#fff',
                    }}
                    type="button"
                    onClick={() => navigate('/login')}
                  >
                    로그인화면으로 이동
                  </button>
                </div>
              </Form>
            </FormWrap>
          </RightSection>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default RegisterForm;

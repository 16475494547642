import React from 'react';
import WindowFrame2 from './WindowFrame2';
import { SubmitBtn } from './CheckPwModal';
import { useMediaQuery } from 'react-responsive';

const modalHeaderStyle = {
  borderBottom: 'none',
};
const modalBodyStyle = {
  main_section: { padding: '30px 50px 50px 50px' },
  modal_main: { padding: 0 },
  fixed_button: { padding: 0 },
};

const CanclePaymentModal = (props) => {
  const { open, close, children, secondBtn } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  return (
    <WindowFrame2
      open={open}
      close={close}
      width={'650px'}
      height={'470px'}
      scroll={0}
      modalBodyStyle={
        isMobile
          ? {
              ...modalBodyStyle,
              main_section: {
                padding: '40px 0px',
              },
            }
          : modalBodyStyle
      }
      modalHeaderStyle={modalHeaderStyle}
      slotFixedButton={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: isMobile ? 'center' : 'flex-end',
          }}
        >
          <SubmitBtn
            style={{
              width: isMobile ? '90%' : '100%',
              height: '40px',
            }}
            type="button"
            onClick={close}
          >
            구독권 유지하기
          </SubmitBtn>
          {secondBtn && secondBtn}
        </div>
      }
    >
      {children}
    </WindowFrame2>
  );
};

export default CanclePaymentModal;

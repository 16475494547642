import { Input, SearchBtn, SearchBox } from './SearchInputElements';
import Icon from '../Icon';
import SearchIcon from '@mui/icons-material/Search';
const SearchInput = ({ inputVal, setInputVal, onSearch, id }) => {
  const onSearchEnter = (e) => {
    if (e.key === 'ENTER' || e.keyCode === 13) {
      onSearch();
    }
  };
  return (
    <SearchBox>
      <Input
        type="text"
        name="searchInput"
        id={id}
        placeholder="키워드를 입력해 주세요."
        onInput={(e) => setInputVal(e.target.value)}
        value={inputVal}
        onKeyDown={(e) => {
          onSearchEnter(e);
        }}
      />
      <SearchBtn type="button" onClick={onSearch}>
        <SearchIcon />
      </SearchBtn>
    </SearchBox>
  );
};

export default SearchInput;

import styled from 'styled-components';
import { Bottom } from './../../FrameElements';

export const CardList = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  //480
  @media screen and (max-width: 480px) {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  /* qhd */
  @media screen and (min-width: 2000px) {
    gap: 10px;
  }
`;

export const Card = styled.div`
  overflow: hidden;
  position: relative;
  background: #37465b;
  border-radius: 10px;
  font-size: 17px;
  width: 100%;
  padding: 12px;
  color: #fff;
  text-align: left;
  margin-bottom: 20px;
  p {
    color: #59ffe2;
  }
  //480
  @media screen and (max-width: 480px) {
    font-size: 14px;
    width: 90vw;
  }
`;
export const CardMain = styled.div`
  position: relative;
  background: #37465b;
  border-radius: 10px;
  font-size: 17px;
  width: 100%;
  height: 272px;
  color: #fff;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: #59ffe2;
  }
  &.active {
    border: 2px solid #59ffe2;
  }
  //480
  @media screen and (max-width: 480px) {
    font-size: 14px;
    width: 90vw;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: bold;
  align-items: center;
  &.content2 {
    font-size: 26px;
    color: #59ffe2;
  }
`;

export const CardIcon = styled.div`
  position: absolute;
  top: -36.5px;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: solid 3px #fff;
  font-size: 35px;
  background-color: #e01949;
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: ${(props) => (props.noDiscountPrice ? '40px' : '0')};
  bottom: ${(props) => (props.noDiscountPrice ? '12px' : '0')};
  top: ${(props) => (props.noDiscountPrice ? '' : '0')};
  left: ${(props) => (props.noDiscountPrice ? '' : '0')};
  justify-content: ${(props) => (props.noDiscountPrice ? '' : 'center')};
  font-size: 24px;
  font-weight: bold;
`;

export const CostPrice = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  font-size: 20px;
  color: #8a8a8a;
  p {
    color: #8a8a8a;
  }
`;

export const CardTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  flex-direction: column;
  margin-top: 48px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #fff;
  margin: 10px 0;
  &.content2 {
    width: 100%;
    position: absolute;
    height: 2px;
    background-color: #8a8a8a;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
  color: #fff;

  //480
  @media screen and (max-width: 480px) {
    width: 100%;
    text-align: start;
    font-size: 14px;
  }
`;

export const CardButton = styled.div`
  position: absolute;
  bottom: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* align-items: center; */
  background: transparent;
  background-color: #070c15;
  color: #fff;
  font-size: 18px;
  margin-top: 30px;
  padding: 10px 6px;
  width: 100%;
  height: 84px;
  font-weight: normal;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const CardFlag = styled.div`
  display: flex;
  color: #59ffe2;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Triangle = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  color: #10141c;
  font-size: 14px;
  z-index: 1;
  font-weight: bold;
  line-height: 30px;
`;
export const TriangleTitle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-top: 30px solid var(--mint-color);
  border-bottom: 30px solid transparent;
  border-right: 30px solid var(--mint-color);
`;
export const Discount = styled.div`
  position: absolute;
  display: flex;
  width: 48px;
  height: 32px;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 20px;
  background-color: var(--mint-color);
  color: #10141c;
  font-weight: bold;
  font-size: 13px;
  border-left: 25px solid transparent; /* 왼쪽 대각선 */
  border-right: 25px solid transparent; /* 오른쪽 대각선 */
  border-bottom: 10px solid black; /* 밑변 */
`;
